import React, { useEffect, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom';
import { connect } from 'react-redux';
import debounce from 'lodash.debounce'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch, faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import cn from './TopNavBar.module.css'
import { BackArrow } from '../assets/icons/BackArrow';
import { Row } from './Row'
import { Error } from './Error'
import { BUTTON_TYPES, Button } from './Button'
import { Input, INPUT_SIZES } from './Input';
import { DarkMode } from '../assets/icons/DarkMode'
import { LightMode } from '../assets/icons/LightMode'
import store from '../store';
import { changeColorScheme, removeGlobalError, setStartOfSearch } from '../store/actions';
import { workspaceTypeCheck } from '../utilities/workspaceType';
import { TimedNotificationsConnected } from './TimedNotification';

const pathNamesWithBack = [
    'account/',
    'rule/',
    'search-results',
    'playbook/',
    'generative-pdf/'
]

const search = debounce((history, value) => {
    history.replace(`/search-results/${value}`)
}, 250)

export const TopNavBar = ({ user, customPathLabel, colorScheme, dispatch, errors, pathLabel }) => {
    const location = useLocation()
    const { pathname } = location
    const history = useHistory()
    const shouldHaveBack = pathNamesWithBack.find(el => pathname.includes(el))

    const [searchOn, setSearchOn] = useState()

    const switchToDarkMode = () => dispatch(changeColorScheme('DARK'))
    const switchToLightMode = () => dispatch(changeColorScheme('LIGHT'))

    const goBack = () => {
        if (store.getState()?.startUrl && searchOn && !!searchOn.length) {
            history.replace(store.getState()?.startUrl)
        } else {
            history.goBack()
        }
    }

    const handleSearchType = value => {
        setSearchOn(value)
        if (!history.location.pathname.includes('/search-results')) {
            // meaning we've just started searching
            store.dispatch(setStartOfSearch(history.location.pathname))
        }
        if (value.length >= 2) {
            search(history, value)
        }
    }

    useEffect(() => {
        if (!!user) {
            if (user.impersonating) {
                const impersonationOverlay = window.document.createElement('div')
                impersonationOverlay.id = 'impersonationOverlay'
                window.document.getElementById('root').appendChild(impersonationOverlay)
            } else {
                const overlay = window.document.getElementById('impersonationOverlay')
                if (overlay) {
                    overlay.parentNode.removeChild(overlay)
                }
            }
        }
    }, [user])

    const clearSearch = () => {
        if (searchOn && !!searchOn.length && store.getState()?.startUrl) {
            handleSearchType('')
            history.replace(store.getState()?.startUrl)
            store.dispatch(setStartOfSearch(null))
        }
    }

    const linkMessage = (str) => {
        const arr = str.match(/\[https?:\/\/[a-zA-Z0-9_./%-]+?\]/g);
        if (!arr || arr.length <= 0) {
            return str;
        }
        let newStr = str;
        const elArr = [];
        for (const match of arr) {
            const url = match.substr(1, match.length - 2);
            const aEl = (<a href={url} target='_blank' rel='noreferrer'>{url}</a>);
            const pos = newStr.indexOf(match);
            const headStr = newStr.substr(0, pos);
            elArr.push(headStr);
            elArr.push(aEl);
            newStr = newStr.substr(pos + match.length);
        };
        if (newStr.length > 0) {
            elArr.push(newStr);
        }
        return (<div>{elArr.map(el => el)}</div>);
    }

    useEffect(() => {
        setSearchOn(pathname.includes('/search-results/') ? pathname.replace('/search-results/', '') : '')
    }, [location])

    return (
        <>
            {errors.map((el, i) => (
                <Error>
                    <Row spaceBetween fullWidth>
                        <div className={cn.globalErrorMessagePreWrap}>
                            {linkMessage(el.message)}
                        </div>
                        <Row noMargin>
                            {el.button && (
                                <Button
                                    onClick={() => {
                                        store.dispatch(removeGlobalError(i))
                                        history.push(el.button.path)
                                    }}
                                >
                                    {el.button.label}
                                </Button>
                            )}
                            <FontAwesomeIcon
                                icon={faTimesCircle}
                                size={'2x'}
                                className={cn.closeGlobalErrorButton}
                                onClick={() => store.dispatch(removeGlobalError(i))}
                            />
                        </Row>
                    </Row>
                </Error>
            ))}
            <TimedNotificationsConnected />
            <nav className={`${cn.header} ${workspaceTypeCheck(['CALLYPSO']) ? cn.crm : ''}`}>
                {shouldHaveBack && (
                    <div className={cn.backButton} onClick={goBack}>
                        <BackArrow fill="currentColor" />
                    </div>
                )}
                <div className={`${cn.pathLabel}`} data-test-id='top-nav-bar-wrapper'>
                    {pathLabel.includes('{{PLACEHOLDER}}') ? (
                        <>
                            <div className={cn.firstHalf} data-test-id='path-label'>
                                {pathLabel.split('{{PLACEHOLDER}}')[0]}
                            </div>
                            <div className={cn.secondHalf} data-test-id='custom-path-label'>
                                {customPathLabel}
                            </div>
                        </>
                    ) : (
                        <div data-test-id='path-label'>
                            {pathLabel}
                        </div>
                    )}
                </div>
                <div className={cn.searchWrapper}>
                    <Input
                        size={INPUT_SIZES.DEFAULT}
                        placeholder='Search...'
                        testId={'global-search-id'}
                        value={searchOn}
                        onChange={e => handleSearchType(e.target.value)}
                        icon={<FontAwesomeIcon icon={faSearch} />}
                        rightIcon={searchOn ? <FontAwesomeIcon
                            icon={faTimesCircle}
                            onClick={clearSearch}
                            className={cn.clearSearchButton}
                        /> : null}
                        fullWidth
                    />
                </div>
                {colorScheme === 'DARK' ? (
                    <Button
                        type={BUTTON_TYPES.ICON_AND_TEXT}
                        customIcon={<DarkMode />}
                        onClick={switchToLightMode}
                    >
                        Dark mode
                    </Button>
                ) : (
                    <Button
                        type={BUTTON_TYPES.ICON_AND_TEXT}
                        customIcon={<LightMode />}
                        onClick={switchToDarkMode}
                    >
                        Light mode
                    </Button>
                )}
                <div id='top-nav-bar-portal' className={cn.topNavBarPortal} />
            </nav>
        </>
    )
}

export const mapStateToProps = (state, ownProps) => {
    return {
        ...state,
        ...ownProps
    }
}

export const TopNavBarConnected = connect(mapStateToProps)(TopNavBar)
