import React, { useEffect, useState } from 'react'
import { faTrashAlt } from '@fortawesome/free-solid-svg-icons'
// import cn from './CustomGraph.module.css'
import { ViewContainer } from '../../../components/ViewContainer'
import { Hr } from '../../../components/Hr'
import { SubHeader } from '../../../components/SubHeader'
import { Row } from '../../../components/Row'
import { Label } from '../../../components/Label'
import { Input } from '../../../components/Input'
import { Dropdown } from '../../../components/Dropdown'
import { makeRequest } from '../../../utilities/endpoints'
import { URLS } from '../../../config'
import { Nbsp } from '../../../components/Nbsp'
import { Checkbox } from '../../../components/Checkbox'
import { BUTTON_TYPES, Button } from '../../../components/Button'
import { PopoverGithubPicker } from '../../../components/PopoverGithubPicker'
import { SelectWrapperConnected } from '../../../components/SelectWrapper'

export const CustomGraph = () => {
    const [graphs, setGraphs] = useState([])

    const [newGraphLabel, setNewGraphLabel] = useState()
    const [newGraphObject, setNewGraphObject] = useState()
    const [newGraphDateProperty, setNewGraphDateProperty] = useState([])

    const [newGraphProperties, setNewGraphProperties] = useState([])
    const [newGraphFilter, setNewGraphFilter] = useState([])

    const [newGraphObjectProperties, setNewGraphObjectProperties] = useState({})

    useEffect(() => {
        loadGraphs()
    }, [])

    useEffect(() => {
        if (!!newGraphObject && !newGraphObjectProperties[newGraphObject]) {
            getDescription(newGraphObject)
        }
    }, [newGraphObject])

    const getDescription = object => {
        if (!!newGraphObjectProperties[object]?.length) {
            return
        }
        setNewGraphObjectProperties({
            ...newGraphObjectProperties,
            [object]: []
        })
        makeRequest.get(`${URLS.main}/describe/${object}`)
            .then(res => {
                setNewGraphObjectProperties({
                    ...newGraphObjectProperties,
                    [object]: res.data?.fields || res.data
                })
            })
    }

    const deleteCustomGraph = id => {
        makeRequest.delete(`${URLS.main}/custom-graph/${id}`)
            .then(loadGraphs)
    }

    const loadGraphs = () => {
        makeRequest.get(`${URLS.main}/custom-graphs`)
            .then(res => {
                setGraphs(res.data)
            })
    }

    const saveNewGraph = () => {
        makeRequest.post(`${URLS.main}/custom-graph`, {
            customGraph: {
                label: newGraphLabel,
                object: newGraphObject,
                dateFilterProperty: newGraphDateProperty.value,
                filter: newGraphFilter
            },
            customGraphProperties: newGraphProperties.filter(el => !!el.format && !!el.property).map(el => ({
                format: el.format,
                divideByBookOfBusinessCash: el.divideByBookOfBusinessCash,
                divideByBookOfBusinessCount: el.divideByBookOfBusinessCount,
                property: el.property.value,
                label: el.property.label,
                color: el.color,
                divideByProperty: el.splitByThisProperty,
            })),
            customGraphFilter: newGraphFilter
        })
            .then(loadGraphs)
    }

    return (
        <ViewContainer collapsible title={'Custom Home Graphs'}>
            <SubHeader>Custom Graphs</SubHeader>
            {graphs.map(({ customGraph, customGraphProperties }) => (
                <>
                    <Row fullWidth spaceBetween>
                        <Row noMargin>
                            <Label>Label</Label>
                            <div>{customGraph.label}</div>
                        </Row>
                        <Button
                            type={BUTTON_TYPES.ICON}
                            onClick={() => deleteCustomGraph(customGraph.id)}
                            icon={faTrashAlt}
                        />
                    </Row>
                    <Row>
                        <Label>Object</Label>
                        <div>{customGraph.object}</div>
                    </Row>
                    <Row>
                        <Label>Date Property</Label>
                        {/* <div>{newGraphObjectProperties[customGraph?.object]?.find(el => el.value === customGraph?.dateFilterProperty)?.label}</div> */}
                        <div>{customGraph?.dateFilterProperty}</div>
                    </Row>
                    <br />
                    <Row noMargin>
                        <Label>Filter</Label>
                        <div>{customGraph.filter}</div>
                    </Row>
                    <br/>
                    <Label>Properties</Label>
                    {customGraphProperties.map(property => (
                        <>
                            <Row>
                                <div>{property.label}</div>
                                <Nbsp />
                                <div>{property.format}</div>
                            </Row>
                            {property.divideByCash && (
                                <Checkbox
                                    label='Divide by total book of business (Monetary Value)'
                                    checked
                                    disabled
                                />
                            )}
                            {property.divideByCount && (
                                <Checkbox
                                    label='Divide by total book of business (Number of Accounts)'
                                    checked
                                    disabled
                                />
                            )}
                        </>
                    ))}
                    <Hr />
                    <br />
                </>
            ))}
            <Hr />
            <SubHeader>New Custom Graph</SubHeader>
            <Row>
                <Label>Label</Label>
                <Input
                    onChange={e => setNewGraphLabel(e.target.value)}
                    value={newGraphLabel}
                />
            </Row>
            <Row>
                <Label>Object</Label>
                <Dropdown
                    values={[
                        { label: '' },
                        { value: 'Account', label: 'Account' },
                        { value: 'Opportunity', label: 'Opportunity' },
                    ]}
                    value={newGraphObject}
                    onChange={e => {
                        setNewGraphObject(e.target.value)
                    }}
                />
            </Row>
            <Row>
                <Label>Date Property</Label>
                <SelectWrapperConnected
                    onChange={setNewGraphDateProperty}
                    value={newGraphDateProperty}
                    options={newGraphObjectProperties[newGraphObject] || []}
                />
            </Row>
            {newGraphProperties.map((property, i) => {
                return <>
                    <Row>
                        <Label>Property</Label>
                        <SelectWrapperConnected
                            onChange={data => {
                                setNewGraphProperties(newGraphProperties.map((el, innerI) => {
                                    if (innerI === i) {
                                        return {
                                            ...el,
                                            property: data
                                        }
                                    }
                                    return el
                                }))
                            }}
                            value={property.property}
                            options={newGraphObjectProperties[newGraphObject] || []}
                        />
                        <Nbsp />
                        <Label>Format</Label>
                        <Dropdown
                            values={[
                                { label: '-' },
                                { value: 'SUM_CASH', label: 'Monetary Sum of the entries' },
                                { value: 'SUM_TOTAL', label: 'Numerical Sum of the entries' },
                                { value: 'COUNT', label: 'Count of the entries' },
                            ]}
                            value={property.value}
                            onChange={e => setNewGraphProperties(newGraphProperties.map((el, innerI) => {
                                if (innerI === i) {
                                    return {
                                        ...el,
                                        format: e.target.value
                                    }
                                }
                                return el
                            }))}
                        />
                        <Nbsp />
                        <PopoverGithubPicker
                            color={property.color || undefined}
                            // onClose={hideColorPicker}
                            onChange={color => setNewGraphProperties(newGraphProperties.map((el, innerI) => {
                                if (innerI === i) {
                                    return {
                                        ...el,
                                        color
                                    }
                                }
                                return el
                            }))}
                        />
                    </Row>
                    {newGraphObject && newGraphObject === 'Account' ? (
                        <>
                            <Row>
                                <Checkbox
                                    label='Divide by total book of business (Monetary Value)'
                                    onClick={() => {
                                        setNewGraphProperties(newGraphProperties.map((el, innerI) => {
                                            if (innerI === i) {
                                                return {
                                                    ...el,
                                                    divideByBookOfBusinessCash: !el.divideByBookOfBusinessCash
                                                }
                                            }
                                            return el
                                        }))
                                    }}
                                    checked={property.divideByBookOfBusinessCash}
                                />
                            </Row>
                            <Row>
                                <Checkbox
                                    label='Divide by total book of business (Number of Accounts)'
                                    onClick={() => {
                                        setNewGraphProperties(newGraphProperties.map((el, innerI) => {
                                            if (innerI === i) {
                                                return {
                                                    ...el,
                                                    divideByBookOfBusinessCount: !el.divideByBookOfBusinessCount
                                                }
                                            }
                                            return el
                                        }))
                                    }}
                                    checked={property.divideByBookOfBusinessCount}
                                />
                            </Row>
                        </>
                    ) : (
                        <Row>
                            <Checkbox
                                label='Split by this property'
                                onClick={() => {
                                    setNewGraphProperties(newGraphProperties.map((el, innerI) => {
                                        if (innerI === i) {
                                            return {
                                                ...el,
                                                splitByThisProperty: !el.splitByThisProperty
                                            }
                                        }
                                        return el
                                    }))
                                }}
                            />
                        </Row>
                    )}
                    <Hr />
                    <br />
                </>
            })}
            <Button
                onClick={() => setNewGraphProperties([...newGraphProperties, {}])}
            >
                Add Another Property
            </Button>

            <br />
            <Row>
                <Label>Additional Filter Query</Label>
                <Input
                    value={newGraphFilter}
                    onChange={e => setNewGraphFilter(e.target.value)}
                />
            </Row>
            <br />
            <br />
            <br />

            <Button
                onClick={saveNewGraph}
            >
                Save New Custom Graph
            </Button>
        </ViewContainer>
    )
}