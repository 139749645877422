import React, { useEffect, useState, useCallback } from 'react'
import { useHistory } from "react-router-dom";
import debounce from 'lodash.debounce'
import { faCheckToSlot, faLink, faMapPin, faPenSquare, faRectangleTimes } from '@fortawesome/free-solid-svg-icons';
import cn from './GenerativePDF.module.css'
import { makeRequest } from '../../utilities/endpoints'
import { URLS } from '../../config'
import { ViewContainer } from '../../components/ViewContainer';
import { LOADER_SIZES, LOADER_TYPES, Loader } from '../../components/Loader';
import { Input } from '../../components/Input';
import { Row } from '../../components/Row';
import { BUTTON_TYPES, Button } from '../../components/Button';
import { Table } from '../../components/Table';
import { Nbsp, NBSP_SIZES } from '../../components/Nbsp';
import { permissionCheck } from '../../utilities/permissionCheck';

export const GenerativePDF = () => {
    const history = useHistory()
    const [files, setFiles] = useState([])
    const [loaded, setLoaded] = useState(false)
    const [nextPageToken, setNextPageToken] = useState(false)
    const [searchValue, setSearchValue] = useState()
    const [pinnedFiles, setPinnedFiles] = useState([])

    const getDocFiles = (pageToken) => {
        setLoaded(false)
        makeRequest.get(`${URLS.main}/generative-pdf/list-files${!!pageToken ? `/${pageToken}` : ''}`)
            .then(res => {
                setFiles(res.data?.files)
                setNextPageToken(res.data.nextPageToken)
                setLoaded(true)
                // TODO add handler for missing permissions!
            })
    }

    const getDocFilesSearched = term => {
        setLoaded(false)
        makeRequest.get(`${URLS.main}/generative-pdf/list-files/search/${term}`)
            .then(res => {
                setFiles(res.data?.files)
                setNextPageToken(res.data.nextPageToken)
                setLoaded(true)
                // TODO add handler for missing permissions!
            })
    }

    const gotoEditFile = (id, name) => history.push(`/generative-pdf/edit/${id}/${name}`)

    const gotoUseFile = (id, name) => history.push(`/generative-pdf/use/${id}/${name}`)

    useEffect(() => {
        loadPinnedFiles()
        getDocFiles()
    }, [])

    const search = text => {
        if (!!text?.length) {
            getDocFilesSearched(text)
        } else {
            getDocFiles()
        }
    }

    const searchDebounce = useCallback(
        debounce(search, 250),
        []
    );

    const unpinDocument = (id) => {
        makeRequest.post(`${URLS.main}/generative-pdf/pinned`, {
            files: JSON.stringify(pinnedFiles.filter(el => el.id !== id))
        }).then(loadPinnedFiles)
    }

    const pinDocument = (id, name, webViewLink) => {
        makeRequest.post(`${URLS.main}/generative-pdf/pinned`, {
            files: JSON.stringify([...pinnedFiles, { id, name, webViewLink }])
        }).then(loadPinnedFiles)
    }

    const loadPinnedFiles = () => {
        makeRequest.get(`${URLS.main}/generative-pdf/pinned`)
            .then(res => setPinnedFiles(res.data?.files || []))
    }

    if (!loaded) {
        return <Loader size={LOADER_SIZES.BIG} type={LOADER_TYPES.SPINNER} />
    }

    return (
        <ViewContainer>
            <Row fullWidth>
                <Input
                    smaller
                    fullWidth
                    value={searchValue}
                    placeholder='Filter'
                    onChange={e => {
                        searchDebounce(e.target.value)
                        setSearchValue(e.target.value)
                    }}
                />
                <Nbsp size={NBSP_SIZES.BIGGER} />
                <Button
                    type={BUTTON_TYPES.ICON_AND_TEXT}
                    icon={faLink}
                    className={cn.linkButton}
                    onClick={() => history.push('/generative-pdf/generating')}
                >
                    generated and generating files
                </Button>
            </Row>
            {!!pinnedFiles?.length && (
                <>
                    <Table
                        headers={[
                            'Pinned Documents from your workspace administrators',
                            'Actions'
                        ]}
                        customStyles={{
                            1: {
                                width: 120
                            }
                        }}
                        data={pinnedFiles.map(el => ([
                            <Button
                                type={BUTTON_TYPES.TRANSPARENT}
                                onClick={() => gotoUseFile(el.id, el.name)}
                                data-tooltip-id='default'
                                data-tooltip-content='Use this file'
                            >
                                {el.name}
                            </Button>,
                            <Row noMargin spaceBetween fullWidth>
                                {permissionCheck('GENERATIVE_PDF', "UPDATE") ? (
                                    <Button
                                        type={BUTTON_TYPES.SMALLER_ICON}
                                        icon={faRectangleTimes}
                                        data-tooltip-id='default'
                                        data-tooltip-content='Unpin for other users'
                                        onClick={() => unpinDocument(el.id)}
                                    />
                                ) : null}
                                <Button
                                    type={BUTTON_TYPES.SMALLER_ICON}
                                    icon={faLink}
                                    data-tooltip-id='default'
                                    data-tooltip-content='Link to the File'
                                    onClick={() => window.open(el.webViewLink, '_blank')}
                                />
                                <Button
                                    type={BUTTON_TYPES.SMALLER_ICON}
                                    icon={faPenSquare}
                                    data-tooltip-id='default'
                                    data-tooltip-content='Edit File'
                                    onClick={() => gotoEditFile(el.id, el.name)}
                                />
                                <Button
                                    type={BUTTON_TYPES.SMALLER_ICON}
                                    icon={faCheckToSlot}
                                    data-tooltip-id='default'
                                    data-tooltip-content='Select File'
                                    onClick={() => gotoUseFile(el.id, el.name)}
                                />
                            </Row>
                        ]))}
                    />
                    <br />
                </>
            )}
            <Table
                headers={[
                    'Document Name',
                    'Last Edit',
                    'Actions'
                ]}
                customStyles={{
                    2: {
                        width: 120
                    }
                }}
                data={files.map(el => ([
                    <Button
                        type={BUTTON_TYPES.TRANSPARENT}
                        onClick={() => gotoUseFile(el.id, el.name)}
                        data-tooltip-id='default'
                        data-tooltip-content='Use this file'
                    >
                        {el.name}
                    </Button>,
                    <Button
                        type={BUTTON_TYPES.TRANSPARENT}
                        onClick={() => gotoEditFile(el.id, el.name)}
                        data-tooltip-id='default'
                        data-tooltip-content='Edit this file'
                    >
                        {el.modifiedTime}
                    </Button>,
                    <Row noMargin spaceBetween fullWidth>
                        {permissionCheck('GENERATIVE_PDF', "UPDATE") ? (
                            <Button
                                type={BUTTON_TYPES.SMALLER_ICON}
                                icon={faMapPin}
                                data-tooltip-id='default'
                                data-tooltip-content='Pin for other users'
                                onClick={() => pinDocument(el.id, el.name, el.webViewLink)}
                            />
                        ) : null}
                        <Button
                            type={BUTTON_TYPES.SMALLER_ICON}
                            icon={faLink}
                            data-tooltip-id='default'
                            data-tooltip-content='Link to the File'
                            onClick={() => window.open(el.webViewLink, '_blank')}
                        />
                        <Button
                            type={BUTTON_TYPES.SMALLER_ICON}
                            icon={faPenSquare}
                            data-tooltip-id='default'
                            data-tooltip-content='Edit File'
                            onClick={() => gotoEditFile(el.id, el.name)}
                        />
                        <Button
                            type={BUTTON_TYPES.SMALLER_ICON}
                            icon={faCheckToSlot}
                            data-tooltip-id='default'
                            data-tooltip-content='Select File'
                            onClick={() => gotoUseFile(el.id, el.name)}
                        />
                    </Row>
                ]))}
            />
            {!!nextPageToken && (
                <Row fullWidth>
                    <Button
                        onClick={() => getDocFiles(nextPageToken)}
                    >
                        Next Page
                    </Button>
                </Row>
            )}
        </ViewContainer>
    )
}
