import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom';
import { connect } from 'react-redux';
import debounce from 'lodash.debounce';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowsLeftRightToLine } from '@fortawesome/free-solid-svg-icons';
import cn from './Whitespacing.module.css'
import { Table } from '../../../components/Table'
import { Dropdown } from '../../../components/Dropdown'
import { Label } from '../../../components/Label'
import { Hr } from '../../../components/Hr'
import { Row } from '../../../components/Row'
import { ViewContainer } from '../../../components/ViewContainer'
import { URLS } from '../../../config';
import { formatLargeSums } from '../../../utilities/currency';
import { makeRequest } from '../../../utilities/endpoints';
import { Button } from '../../../components/Button';
import { Heading } from '../../../components/Heading';
import { Modal } from '../../../components/Modal';
import { Tabs } from '../../../components/Tabs';
import { SubHeader } from '../../../components/SubHeader';
import { Checkbox } from '../../../components/Checkbox';
import { Nbsp } from '../../../components/Nbsp';
import { Salesforce } from '../../../assets/icons/Salesforce';
import { Div, DIV_ELEMENT_TYPE } from './components/Div';
import { EmptyTableElement, EMPTY_TABLE_ELEMENT_TYPE } from './components/EmptyTableElement';
import { SidebarOpportunity } from './components/SidebarOpportunity';
import { workspaceTypeCheck } from '../../../utilities/workspaceType'
import { translateCRMLabel } from '../../../utilities/translateCRMLabels';

export const Whitespacing = ({ user }) => {
    const { accountId } = useParams();
    const [products, setProducts] = useState(null)
    const [selectedSidebarTab, setSelectedSidebarTab] = useState(0)
    const [opportunities, setOpportunities] = useState(null)
    const [tableData, setTableData] = useState(null)
    const [peoplePerDivisions, setPeoplePerDivisions] = useState()
    const [divisions, setDivisions] = useState([])
    const [closedOpportunitiesFilter, setClosedOpportunitiesFilter] = useState(null)
    const [showingActiveOpportunities, setShowingActiveOpportunities] = useState(false)
    const [opportunityToUpdate, setOpportunityToUpdate] = useState(null)
    const [departmentForOpportunity, setDepartmentForOpportunity] = useState(null)
    const [oldTableData, setOldTableData] = useState(null)
    const [accountDetails, setAccountDetails] = useState(null)
    const [cellsForMassChange, setCellsForMassChange] = useState([])

    const debouncedSave = debounce((data) => save(data), 250)

    const save = (data) => {
        const cleanedTableData = data.map((row, rowIndex) => row.map((cell, columnIndex) => ({
            value: typeof cell.value !== 'string' ? '' : cell.value,
            type: cell.type,
            externalId: cell.externalId,
            externalIdType: cell.externalIdType,
            division: cell.division,
            product: cell.product,
            columnIndex,
            rowIndex,
        }))).flat()
        makeRequest.put(`${URLS.main}/whitespace-planning/${accountId}`, cleanedTableData)
    }

    const getProducts = () => {
        makeRequest.get(`${URLS.main}/products`)
            .then(res => {
                if (res && res.data) {
                    setProducts(res.data)
                }
            })
    }

    const getAccountDetails = () => {
        makeRequest.get(`${URLS.main}/account/${accountId}`)
            .then(res => {
                if (res && res.data) {
                    setAccountDetails(res.data)
                }
            })
    }

    const getDivisionDescriptions = () => {
        makeRequest.get(`${URLS.main}/account-mapping/divisionDescriptions`)
            .then(res => {
                if (res && res.data) {
                    setDivisions(res.data)
                }
            })
    }

    const getPeoplePerDivision = () => {
        makeRequest.get(`${URLS.main}/account/${accountId}/peoplePerDivision`)
            .then(res => {
                if (res && res.data) {
                    setPeoplePerDivisions(res.data)
                }
            })
    }

    const getOpportunities = (opportunitiesFilter = closedOpportunitiesFilter, tableData) => {
        makeRequest.get(`${URLS.main}/whitespace-planning/opportunities/${accountId}/${opportunitiesFilter}`)
            .then(res => {
                if (res && res.data) {
                    const updatedOpportunities = res.data.opportunities.map(el => ({
                        ...el,
                        // eslint-disable-next-line eqeqeq
                        division: res.data.opportunitiesToDivisions.find(divisionRel => divisionRel.opportunityId == el.Id)?.divisionName
                    }))
                    setOpportunities(updatedOpportunities)
                    if (tableData) {
                        setTableData(updateTableDataWithOpps(tableData, updatedOpportunities))
                    }
                }
            })
    }

    const getTableData = () => {
        makeRequest.get(`${URLS.main}/whitespace-planning/${accountId}`)
            .then(res => {
                if (res && res.data) {
                    const columnData = []
                    res.data.forEach(el => {
                        if (!columnData[el.rowIndex]) {
                            columnData[el.rowIndex] = []
                        }
                        columnData[el.rowIndex][el.columnIndex] = {
                            type: el.type,
                            value: el.data,
                            externalId: el.externalId,
                            externalIdType: el.externalIdType,
                            division: el.division,
                            product: el.product,

                        }
                    })
                    setOldTableData(columnData)
                }
            })
    }

    const setOpportunityToDivision = () => {
        makeRequest.put(`${URLS.main}/whitespace-planning/opportunities/setDivision`, {
            opportunityId: opportunityToUpdate.Id,
            divisionName: departmentForOpportunity,
            accountId: accountId
        })
        closeAttachOpportunityToDivisionModal()
    }

    useEffect(() => {
        getProducts()
        getOpportunities()
        getPeoplePerDivision()
        getDivisionDescriptions()
        getTableData()
        getAccountDetails()
    }, [])

    const addColumn = (index) => {
        const newTableData = tableData.map((el) => {
            while (el.length < index) {
                el.push({
                    type: EMPTY_TABLE_ELEMENT_TYPE,
                })
            }
            el.splice(index, 0, {
                type: EMPTY_TABLE_ELEMENT_TYPE,
                value: ''
            })
            return el
        })
        setTableData(newTableData)
        debouncedSave(newTableData)
    }

    const addRow = (index) => {
        const newTableData = tableData.slice(0)
        newTableData.splice(index, 0, new Array(getNumberOfColumns()).fill({
            type: EMPTY_TABLE_ELEMENT_TYPE,
            value: ''
        }))
        setTableData(newTableData)
        debouncedSave(newTableData)
    }

    const deleteColumn = index => {
        deleteColumns([index])
    }

    const deleteColumns = indexes => {
        const newTableData = tableData.map(el => el.filter((_el, innerIndex) => !indexes.includes(innerIndex)))
        setTableData(newTableData)
        debouncedSave(newTableData)
    }

    const deleteRow = index => {
        const newTableData = tableData.filter((_el, i) => i !== index)
        setTableData(newTableData)
        debouncedSave(newTableData)
    }

    // eslint-disable-next-line
    const getNumberOfRows = () => {
        return tableData[0].length
    }

    const getNumberOfColumns = () => {
        return (tableData || []).map(el => el.length).sort((a, b) => b - a)[0]
    }

    const emptyCell = {
        type: EMPTY_TABLE_ELEMENT_TYPE,
    }

    const getMissingProducts = (opportunity) => {
        if (opportunity.OpportunityLineItems) {
            const associatedProducts = [...new Set(opportunity.OpportunityLineItems.records.map(el => el.Product2Id))]
            const activeProducts = [...new Set([...products.map(el => el.Id)])]
            const missingProducts = []
            associatedProducts.forEach(associatedProduct => {
                if (!activeProducts.includes(associatedProduct)) {
                    missingProducts.push(associatedProduct)
                }
            })
            return missingProducts
        }
        return []
    }

    const getInactiveProducts = opportunity => {
        if (opportunity.OpportunityLineItems) {
            const associatedProducts = [...new Set(opportunity.OpportunityLineItems.records.map(el => el.Product2Id))]
            return associatedProducts.filter(el => getColumnOfProduct(el) === -1)
        }
        return []
    }

    const createDivisionRow = division => {
        return [
            {
                type: DIV_ELEMENT_TYPE,
                value: `${division.name} ${getDivisionCountString(division.key)}`,
                undeletable: true,
                division: true,
                externalIdType: 'department',
                externalId: division.key
            },
            emptyCell,
            emptyCell,
            ...products.map((product) => {
                return [
                    {
                        type: DIV_ELEMENT_TYPE,
                        value: null,
                        undeletable: true,
                        externalId: product.Id,
                        externalIdType: 'product'
                    },
                    emptyCell
                ]
            }).flat(),
        ]
    }

    useEffect(() => {
        if (opportunities && products && oldTableData && accountDetails && !tableData && !!divisions.length) {
            const maxWidthFromOldData = (oldTableData || []).sort((a, b) => b.length - a.length)[0]?.length
            const defaultMaxWidth = Math.max(products.length * 2 + 2, 4)
            const expectedMaxWidth = maxWidthFromOldData ? Math.max(maxWidthFromOldData, defaultMaxWidth) : defaultMaxWidth

            const newTableData = [
                [
                    {
                        type: EMPTY_TABLE_ELEMENT_TYPE,
                        value: `Client:`
                    },
                    {
                        type: EMPTY_TABLE_ELEMENT_TYPE,
                        value: accountDetails.name,
                    },
                    ...(new Array(expectedMaxWidth - 1).fill(0).map(() => emptyCell))
                ],
                [
                    emptyCell,
                    {
                        type: EMPTY_TABLE_ELEMENT_TYPE,
                        value: `Case Study`
                    },
                    {
                        type: EMPTY_TABLE_ELEMENT_TYPE,
                        value: 'Existing Use Case / Products'
                    },
                    {
                        type: EMPTY_TABLE_ELEMENT_TYPE,
                        value: `White Space ${translateCRMLabel('Opportunities')}`
                    },
                    ...(new Array(expectedMaxWidth - 3).fill(0).map(() => (
                        {
                            type: EMPTY_TABLE_ELEMENT_TYPE,
                        }
                    )))
                ],
                [
                    emptyCell,
                    emptyCell,
                    emptyCell,
                    ...products.map(el => [
                        {
                            type: DIV_ELEMENT_TYPE,
                            value: el.Name,
                            undeletable: true,
                            product: true,
                            externalIdType: 'product',
                            externalId: el.Id
                        },
                        {
                            type: EMPTY_TABLE_ELEMENT_TYPE,
                            value: 'Potential',
                        },
                    ]).flat(),
                ],
                ...divisions.map(createDivisionRow)
            ]

            const oldTableDataDepartments = oldTableData.flat().filter(el => !!el.division)

            if (!!oldTableData.length) {
                const oldTableDataDepartmentsIds = oldTableDataDepartments.map(el => el.externalId)
                divisions.forEach(newDepartment => {
                    if (!oldTableDataDepartmentsIds.includes(newDepartment?.key?.toString())) {
                        const lastOldTableDataDepartmentsRow = oldTableData.slice(0).reverse().find(el => el.map(el => !!el.division).includes(true))
                        oldTableData.push(lastOldTableDataDepartmentsRow.map(el => ({
                            ...el,
                            value: el.division ? newDepartment.name : '',
                            externalId: el.division ? newDepartment?.key?.toString() : el.externalId,
                        })))
                    }
                })
                const newTableDataDepartmentsIds = divisions.map(el => el?.key?.toString())
                const updatedOldTableData = oldTableData.filter(row => {
                    const department = row.find(el => !!el.division)
                    if (!department) {
                        return true
                    }
                    if (newTableDataDepartmentsIds.includes(department.externalId)) {
                        return true
                    }
                    return false
                })
                const parsedData = updatedOldTableData.map((row, rowIndex) => row.map((cell, columnIndex) => {
                    const uneditable = isDivisionRow(rowIndex, updatedOldTableData) && isProductColumn(columnIndex, updatedOldTableData)
                    const division = divisions.find(el => parseInt(el.key) === parseInt(cell.externalId))
                    if (cell.division && division) {
                        cell.value = `${division.name} ${getDivisionCountString(division.key)}`
                    }
                    return {
                        ...cell,
                        type: uneditable ? DIV_ELEMENT_TYPE : updatedOldTableData[rowIndex][columnIndex].type,
                        undeletable: updatedOldTableData[rowIndex][columnIndex].type === DIV_ELEMENT_TYPE,
                        missingProduct: cell.externalIdType === 'product' && !products.map(el => el.Id.toString()).includes(cell.externalId.toString()),
                    }
                }))
                setTableData(updateTableDataWithOpps(parsedData, opportunities))
            } else {
                const parsedData = newTableData.map(el => el.map(el => {
                    if (!el.type && !el.value) {
                        return emptyCell
                    }
                    return el
                })).map((row, rowIndex) => row.map((cell, columnIndex) => {
                    const uneditable = isDivisionRow(rowIndex, newTableData) && isProductColumn(columnIndex, newTableData)
                    if (uneditable) {
                        return {
                            ...cell,
                            type: DIV_ELEMENT_TYPE,
                        }
                    }
                    return cell
                }))
                setTableData(updateTableDataWithOpps(parsedData, opportunities))
            }
        }
    }, [opportunities, products, tableData, accountDetails, oldTableData, divisions])

    const getTableDataColumn = index => {
        return (tableData || []).slice(0).map(el => el.filter((_el, i) => i === index)).flat()
    }

    const getTableDataRow = index => {
        return (tableData || []).slice(0)[index]
    }

    const hasUndeletableInColumn = index => {
        return getTableDataColumn(index).find(el => !!el.undeletable)
    }

    const hasUndeletableInRow = index => {
        return getTableDataRow(index).find(el => !!el.undeletable)
    }

    const closeAttachOpportunityToDivisionModal = () => {
        setOpportunityToUpdate(null)
        setDepartmentForOpportunity(null)
    }

    const getRowOfDivision = (division, tableDataToCheck = tableData) => tableDataToCheck.findIndex(row => parseInt(row[0]?.externalId) === parseInt(division?.key))

    const getRowOfProducts = (tableDataToCheck = tableData) => {
        const defaultProductsRow = 2
        const productsRow = tableDataToCheck.findIndex(row => !!row.find(el => !!el.product))
        return productsRow !== -1 ? productsRow : defaultProductsRow
    }

    const isProductColumn = (columnIndex, tableDataToCheck = tableData) => {
        const indexes = []
        tableDataToCheck.forEach(row => {
            row.forEach((el, i) => {
                if (el.product) {
                    indexes.push(i)
                }
            })
        })
        return indexes.includes(columnIndex)
    }

    const isDivisionRow = (rowIndex, tableDataToCheck = tableData) => {
        return !!tableDataToCheck[rowIndex].find(cell => cell.division)
    }

    const getColumnOfProduct = (productId, tableDataToCheck = tableData) => {
        let index = -1;
        tableDataToCheck.forEach(row => {
            const tempIndex = row.findIndex(cell => {
                // eslint-disable-next-line
                if (cell.externalId == productId && ['product', 'productFamily'].includes(cell.externalIdType)) {
                    return true
                }
                return false
            })
            if (tempIndex !== -1) {
                index = tempIndex
            }
        })
        return index
    }

    const addProductColumn = product => {
        const productRow = getRowOfProducts()
        const newIndex = getNumberOfColumns()
        let newTableData = tableData.map((el, rowIndex) => {
            if (productRow === rowIndex) {
                el.splice(newIndex, 0, {
                    type: DIV_ELEMENT_TYPE,
                    value: product.Name || product.name,
                    undeletable: true,
                    product: true,
                    externalId: product.Id || product.id,
                    externalIdType: product.Id ? 'product' : 'productFamily'
                })
            } else if (isDivisionRow(rowIndex, tableData)) {
                el.splice(newIndex, 0, {
                    type: DIV_ELEMENT_TYPE,
                    value: null,
                    undeletable: true,
                    externalId: product.Id,
                    externalIdType: 'product'
                })
            } else {
                el.splice(newIndex, 0, {
                    type: EMPTY_TABLE_ELEMENT_TYPE,
                    value: '',
                })
            }
            return el
        })

        newTableData = tableData.map((el, rowIndex) => {
            if (productRow === rowIndex) {
                el.splice(newIndex + 1, 0, {
                    type: EMPTY_TABLE_ELEMENT_TYPE,
                    value: 'Potential',
                })
            } else {
                el.splice(newIndex + 1, 0, {
                    type: EMPTY_TABLE_ELEMENT_TYPE,
                    value: ''
                })
            }
            return el
        })

        const updatedTableDataWithOpps = updateTableDataWithOpps(newTableData, opportunities)
        setTableData(updatedTableDataWithOpps)
        debouncedSave(updatedTableDataWithOpps)
    }

    const getDivisionCountString = division => {
        return `(${peoplePerDivisions ? peoplePerDivisions.peoplePerDivision?.find(el => parseInt(el.departmentId) === parseInt(division))?.employees || 0 : 0})`
    }

    const updateTableDataWithOpps = (tableData = tableData, opportunities = opportunities) => {
        const tempTableData = tableData.map((row) => row.map((cell) => {
            if (cell.isOpportunity) {
                return {
                    type: DIV_ELEMENT_TYPE,
                    undeletable: true,
                    product: true,
                }
            }
            return cell
        }))

        opportunities.forEach(opportunity => {
            if (
                opportunity
                && opportunity.OpportunityLineItems
                && opportunity.OpportunityLineItems.records
                && !!opportunity.OpportunityLineItems.records.length
                && opportunity.division
            ) {
                opportunity.OpportunityLineItems.records.forEach(product => {
                    if (products.find(el => el.Id === product.Product2Id)) {
                        const productColumnIndex = getColumnOfProduct(product.Product2Id, tableData)
                        if (productColumnIndex === -1) {
                            return
                        }
                        const division = divisions.find(el => parseInt(el.key) === parseInt(opportunity.division))
                        const divisionRowIndex = getRowOfDivision(division, tableData)

                        const productCell = tempTableData[divisionRowIndex] && tempTableData[divisionRowIndex][productColumnIndex]
                        if (productCell) {
                            tempTableData[divisionRowIndex][productColumnIndex] = {
                                ...productCell,
                                undeletable: true,
                                type: DIV_ELEMENT_TYPE,
                                value: productCell?.numberOfOppsInTheCell > 0 ? (
                                    <>
                                        {productCell.value}
                                        <Nbsp />
                                        <span
                                            onClick={workspaceTypeCheck(['SFDC', 'SFDC_SANDBOX']) ? () => window.open(`${user.sfUrl}/${opportunity?.Id}`) : null}
                                            data-tooltip-id='default'
                                            data-tooltip-content={opportunity.Name}
                                        >
                                            {formatLargeSums('$', product.TotalPrice)}
                                        </span>
                                    </>
                                ) : (
                                    <span
                                        onClick={workspaceTypeCheck(['SFDC', 'SFDC_SANDBOX']) ? () => window.open(`${user.sfUrl}/${opportunity?.Id}`) : null}
                                        data-tooltip-id='default'
                                        data-tooltip-content={opportunity.Name}
                                    >
                                        {formatLargeSums('$', product.TotalPrice)}
                                    </span>
                                ),
                                numberOfOppsInTheCell: productCell?.numberOfOppsInTheCell ? productCell.numberOfOppsInTheCell + 1 : 1,
                                isOpportunity: true,
                                style: { color: opportunity.IsClosed && 'green', cursor: 'pointer' },
                            }
                        }
                    }
                })
            }
        })
        return tempTableData
    }

    const onMassChange = data => {
        setCellsForMassChange(data)
    }

    return (
        <ViewContainer
            xScroll={true}
        >
            <div className={cn.wrapper}>

                <Row noMargin>
                    <Heading>White Space Plan for "{peoplePerDivisions?.accountName}"</Heading>
                </Row>
                <Row
                    noAligning
                    className={cn.whitespacingWrapper}
                >
                    {
                        showingActiveOpportunities && (
                            <div className={cn.opportunitiesSidebarShown}>
                                <Tabs
                                    selected={selectedSidebarTab}
                                    options={[
                                        'Opportunities',
                                        'Products'
                                    ]}
                                    onChange={setSelectedSidebarTab}
                                />
                                {selectedSidebarTab === 0 ? (
                                    <>
                                        Closed Opportunities
                                        <Hr />
                                        <div className={cn.closedOpportunitiesFilterWrapper}>
                                            <Label>Filter for:</Label>
                                            <Dropdown
                                                fullWidth
                                                value={closedOpportunitiesFilter}
                                                onChange={e => {
                                                    setClosedOpportunitiesFilter(e.target.value)
                                                    setOpportunities(null)
                                                    const updatedTableData = tableData.map(row => row.map(cell => {
                                                        if (cell.isOpportunity) {
                                                            return emptyCell
                                                        }
                                                        return cell
                                                    }))
                                                    getOpportunities(e.target.value, updatedTableData)
                                                    setTableData(updatedTableData)
                                                }}
                                                values={[
                                                    {
                                                        value: 'year',
                                                        label: 'Less than a year ago',
                                                    },
                                                    {
                                                        value: 'six months',
                                                        label: 'Less than 6 months ago',
                                                    },
                                                    {
                                                        value: 'three months',
                                                        label: 'Less than 3 months ago',
                                                    },
                                                    {
                                                        value: 'month',
                                                        label: 'Less than a month ago',
                                                    },
                                                ]}
                                            />
                                        </div>
                                        <br />
                                        {opportunities && opportunities.filter(el => !!el.IsClosed).map(el => {
                                            return (
                                                <SidebarOpportunity
                                                    {...el}
                                                    inactive={!el.OpportunityLineItems?.records.length || getMissingProducts(el).length === el.OpportunityLineItems?.records.length}
                                                    sfUrl={user.sfUrl}
                                                    missingProducts={!!getMissingProducts(el).length}
                                                    inactiveProducts={!!getInactiveProducts(el).length}
                                                    setOpportunityToUpdate={() => {
                                                        setOpportunityToUpdate(el)
                                                        setDepartmentForOpportunity(divisions[0]?.key)
                                                    }}
                                                />
                                            )
                                        })}
                                        <br />
                                        <br />
                                        Active Opportunities
                                        <Hr />
                                        <br />
                                        {opportunities && opportunities.filter(el => !el.IsClosed).map(el => {
                                            return (
                                                <SidebarOpportunity
                                                    {...el}
                                                    inactive={!el.OpportunityLineItems?.records.length || getMissingProducts(el).length === el.OpportunityLineItems?.records.length}
                                                    sfUrl={user.sfUrl}
                                                    missingProducts={!!getMissingProducts(el).length}
                                                    inactiveProducts={!!getInactiveProducts(el).length}
                                                    setOpportunityToUpdate={() => {
                                                        setOpportunityToUpdate(el)
                                                        setDepartmentForOpportunity(divisions[0]?.key)
                                                    }}
                                                />
                                            )
                                        })}
                                    </>
                                ) : (
                                    <Row noMargin spaceBetween className={cn.productsSidebarWrapper} fullWidth>
                                        <Row noMargin className={cn.productsSidebar} fullWidth>
                                            <SubHeader>Products</SubHeader>
                                            {products.map(product => (
                                                <Row spaceBetween fullWidth>
                                                    <Row noMargin>
                                                        <Checkbox
                                                            defaultChecked={getColumnOfProduct(product.Id) !== -1}
                                                            onClick={() => {
                                                                const columnIndex = getColumnOfProduct(product.Id)
                                                                if (columnIndex !== -1) {
                                                                    deleteColumn(columnIndex)
                                                                    deleteColumns([columnIndex, columnIndex + 1])
                                                                } else {
                                                                    addProductColumn(product)
                                                                }
                                                            }}
                                                        />
                                                        <div>{product.Name}</div>
                                                    </Row>
                                                    <Salesforce
                                                        fill={'var(--link-color-main)'}
                                                        width="24px"
                                                        height='100%'
                                                        className={cn.pointer}
                                                        onClick={() => window.open(`${user.sfUrl}/${product.Id}`)}
                                                    />
                                                </Row>
                                            ))}
                                        </Row>
                                    </Row>
                                )}
                            </div>
                        )
                    }
                    <div className={cn.opportunitiesSidebar}>
                        <div className={cn.sidebarBorder} onClick={() => setShowingActiveOpportunities(!showingActiveOpportunities)}>
                            <FontAwesomeIcon icon={faArrowsLeftRightToLine} />
                        </div>
                    </div>
                    <div className={cn.tableWrapper}>
                        <Table
                            noPadding
                            featureName={'whitespace planning'}
                            objectId={accountId}
                            resizible
                            saveSizeUpdates
                            className={cn.whitespacingTable}
                            autogenerateHeaders={getNumberOfColumns() || 1}
                            onMassChange={onMassChange}
                            customStyles={{
                                0: {
                                    position: 'sticky',
                                    left: 0,
                                    top: 0,
                                    background: 'white',
                                    zIndex: 10
                                }
                            }}
                            data={!tableData ? [] : tableData.map((column, rowIndex) => column.map((rowCell, columnIndex) => {
                                if (rowCell[0] && rowCell[0].type) {
                                    return rowCell[0].value
                                }
                                switch (rowCell.type) {
                                case DIV_ELEMENT_TYPE:
                                    return (
                                        <Div
                                            isOpportunity={rowCell.isOpportunity}
                                            missingProduct={rowCell.missingProduct}
                                            style={rowCell.style}
                                            onClick={rowCell.onClick}
                                        >
                                            {rowCell.value}
                                        </Div>
                                    )
                                default:
                                    return <EmptyTableElement
                                        key={rowIndex + columnIndex}
                                        addColumn={() => addColumn(columnIndex + 1)}
                                        addRow={() => addRow(rowIndex + 1)}
                                        deleteColumn={hasUndeletableInColumn(columnIndex) ? null : () => deleteColumn(columnIndex)}
                                        deleteRow={hasUndeletableInRow(rowIndex) ? null : () => deleteRow(rowIndex)}
                                        columnIndex={columnIndex}
                                        rowIndex={rowIndex}
                                        value={rowCell.value}
                                        onChange={(value) => {
                                            const tableDataCopy = tableData.map((el, rowIndexInner) => el.map((el, columnIndexInner) => {
                                                if (rowIndexInner === rowIndex && columnIndexInner === columnIndex) {
                                                    return ({
                                                        ...el,
                                                        value: value
                                                    })
                                                }
                                                if (
                                                    cellsForMassChange.length > 1
                                                    && cellsForMassChange.find(el => el.row === rowIndexInner && el.column === columnIndexInner)
                                                ) {
                                                    return {
                                                        ...el,
                                                        value: value
                                                    }
                                                }
                                                return el
                                            }))

                                            debouncedSave(tableDataCopy)
                                            setTableData(tableDataCopy)
                                        }}
                                    />
                                }
                            }))}
                        />
                    </div>
                </Row>
                {opportunityToUpdate && (
                    <Modal
                        header={`Set '${opportunityToUpdate.Name}' to a department`}
                        onClose={closeAttachOpportunityToDivisionModal}
                        buttons={
                            <Row spaceBetween fullWidth>
                                <Button
                                    onClick={closeAttachOpportunityToDivisionModal}
                                >
                                    Canel
                                </Button>
                                <Button
                                    onClick={() => {
                                        setOpportunityToDivision()
                                        const updatedOpportunities = opportunities.map(el => {
                                            if (el.Id === opportunityToUpdate.Id) {
                                                return {
                                                    ...el,
                                                    division: departmentForOpportunity
                                                }
                                            }
                                            return el
                                        })
                                        setOpportunities(updatedOpportunities)
                                        const updatedTableDataWithOpps = updateTableDataWithOpps(tableData, updatedOpportunities)
                                        setTableData(updatedTableDataWithOpps)
                                        debouncedSave(updatedTableDataWithOpps)
                                    }}
                                >
                                    Update
                                </Button>
                            </Row>
                        }
                    >
                        <Dropdown
                            fullWidth
                            onChange={e => setDepartmentForOpportunity(e.target.value)}
                            value={departmentForOpportunity || opportunities.find(el => el.Id === opportunityToUpdate.Id)?.division}
                            values={
                                divisions.map(division => ({
                                    value: division.key,
                                    label: `${division.name} ${getDivisionCountString(division.key)}`,
                                }))
                            }
                        />
                    </Modal>
                )}
            </div>
        </ViewContainer>
    )
}

export const mapStateToProps = (state) => {
    return {
        user: state.user,
    }
}

export const WhitespacingConnected = connect(mapStateToProps)(Whitespacing)
