import React from 'react'
import cn from './DropdownToggleIndicator.module.css'

export const DropdownToggleIndicator = ({ flipped, className, ...rest }) => (
    <div className={`${cn.dropdownToggleIndicator} ${flipped ? cn.flip : ''} ${className}`} {...rest}>
        <svg width="8" height="5" viewBox="0 0 8 5" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M7 1L4 4L1 1" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>

    </div>
)
