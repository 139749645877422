import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import cn from './Button.module.css'

export const BUTTON_TYPES = {
    TRANSPARENT: 1,
    ICON: 2,
    TRANSPARENT_ICON: 3,
    PADDED_ICON: 4,
    SMALL_ICON: 5,
    SMALLER_ICON: 6,
    ICON_AND_TEXT: 7,
}

const getClassFromType = (type) => {
    switch (type) {
    case BUTTON_TYPES.TRANSPARENT:
        return cn.transparent
    case BUTTON_TYPES.ICON:
        return cn.icon
    case BUTTON_TYPES.TRANSPARENT_ICON:
        return cn.transparentIcon
    case BUTTON_TYPES.PADDED_ICON:
        return cn.paddedIcon
    case BUTTON_TYPES.SMALL_ICON:
        return cn.smallIcon
    case BUTTON_TYPES.SMALLER_ICON:
        return cn.smallerIcon
    case BUTTON_TYPES.ICON_AND_TEXT:
        return cn.iconAndText
    default:
        break;
    }
}

export const Button = ({
    type = BUTTON_TYPES.PRIMARY,
    children,
    size,
    className,
    icon,
    customIcon,
    testId,
    fullWidth,
    clip,
    ...rest
}) => (
    <button
        data-test-id={testId || `BUTTON ${children}`}
        className={`
            ${cn.button}
            ${getClassFromType(type)}
            ${fullWidth ? cn.fullWidth : ''}
            ${clip ? cn.clip : ''}
            ${className}
        `}
        {...rest}
    >
        {!!icon ? <FontAwesomeIcon icon={icon} /> : null}
        {!!customIcon ? customIcon : null}
        {type === BUTTON_TYPES.ICON_AND_TEXT ? <div className={cn.iconSpacing} /> : null}
        {children}
    </button>
)
