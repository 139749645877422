import React, { useEffect, useState } from 'react'
import { useParams } from "react-router-dom";
import debounce from 'lodash.debounce';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { ViewContainer } from '../../components/ViewContainer';
import { SubHeader } from '../../components/SubHeader';
import { Row } from '../../components/Row';
import { SelectWrapper } from '../../components/SelectWrapper';
import { Hr } from '../../components/Hr';
import cn from './GenerativePDFFile.module.css'
import { URLS } from '../../config';
import { makeRequest } from '../../utilities/endpoints';
import { Nbsp } from '../../components/Nbsp';
import { Label } from '../../components/Label';
import { Input } from '../../components/Input';
import { isFreshdeskConnected } from '../../utilities/isFreshdeskConnected';

const GENERAL_TYPE = ['datetime', 'me'];


export const GenerativePDFFile = () => {
    const { id } = useParams();
    const [crmObjProps, setCrmObjProps] = useState({});
    const [crmObjPropsFiltered, setCrmObjPropsFiltered] = useState({});
    const [freshdeskObjProps, setFreshdeskObjProps] = useState();
    const [generalObjProps, setGeneralObjProps] = useState({});
    const [generalObjPropsFiltered, setGeneralObjPropsFiltered] = useState({});
    const [generativePropSearch, setGenerativePropSearch] = useState()
    const [copyingMessage, setCopyingMessage] = useState()
    const [freshdeskFilterPriority, setFreshdeskFilterPriority] = useState()
    const [freshdeskFilterStatus, setFreshdeskFilterStatus] = useState()
    const [freshdeskFilterType, setFreshdeskFilterType] = useState()
    const [freshdeskSelectedColumns, setFreshdeskSelectedColumns] = useState()
    const [freshdeskColumnLabels, setFreshdeskColumnLabels] = useState()
    const [freshdeskPlaceholder, setFreshdeskPlaceholder] = useState()

    const getGeneralSourceObjProps = async () => {
        const tmpGeneralObjProps = { ...generalObjProps };
        for (const datasource of GENERAL_TYPE) {
            const res = await makeRequest.get(`${URLS.main}/dataFetcher/variables/${datasource}`);
            tmpGeneralObjProps[datasource] = res.data;
        }

        setGeneralObjProps({ ...tmpGeneralObjProps });
        setGeneralObjPropsFiltered({ ...tmpGeneralObjProps })
    }

    const getFreshdeskProps = async () => {
        const res = await makeRequest.get(`${URLS.main}/dataFetcher/variables/freshdesk`);

        setFreshdeskObjProps({ ...res.data })
    }

    const getCRMObjProps = async () => {
        const tmpCRMObjProps = { ...crmObjProps };

        let res = null;

        let crmRes = null;

        try {
            crmRes = await makeRequest.get(`${URLS.main}/dataFetcher/variables/crm`);
        } catch (err) {
            console.error(err);
        }

        if (!crmRes.data) {
            console.info("no data returned from crm datasource");
            return false;
        }

        const crmObjs = crmRes.data;
        for (const key of Object.keys(crmObjs)) {
            if (!crmObjProps[key]) {
                if (Object.prototype.hasOwnProperty.call(crmObjs[key], 'redirectSource')) {
                    try {
                        res = await makeRequest.get(`${URLS.main}${crmObjs[key].redirectSource}`);
                    } catch (err) {
                        console.error(err);
                    }
                    if (res && res.status === 200) {
                        if (key === 'Account') {
                            tmpCRMObjProps[key] = {
                                'name': 'Account',
                                'label': 'Account',
                                'reducer': crmObjs.Account.fields[0].reducer,
                                'fields': [...res.data.fields]
                            };

                            res.data.objects.forEach((obj) => {
                                if (!tmpCRMObjProps[obj.name]) {
                                    tmpCRMObjProps[obj.name] = { ...obj }
                                }
                            });
                        } else {
                            tmpCRMObjProps[key] = {
                                'name': key,
                                'label': key,
                                'reducer': crmObjs[key].fields[0].reducer,
                                'fields': [...res.data]
                            };
                        }
                    }
                } else {
                    tmpCRMObjProps[key] = { ...crmObjs[key] };
                }
            }
        };
        setCrmObjProps({ ...tmpCRMObjProps });
        setCrmObjPropsFiltered({ ...tmpCRMObjProps })
    }

    useEffect(() => {
        getCRMObjProps();
        getGeneralSourceObjProps();
        isFreshdeskConnected().then(getFreshdeskProps)
    }, []);

    const onGenerativePropSearchChange = e => {
        setGenerativePropSearch(e.target.value)
        debouncedSearchChange(e.target.value)
    }

    const debouncedSearchChange = debounce((generativePropSearch) => {
        if (!generativePropSearch?.length) {
            setCrmObjPropsFiltered({ ...crmObjProps })
            setGeneralObjPropsFiltered({ ...generalObjProps })
        } else {
            const tempCrmObjProps = { ...crmObjProps }
            const tempGeneralObjProps = { ...generalObjProps }

            Object.keys(tempCrmObjProps).forEach(key => {
                const { fields } = tempCrmObjProps[key]
                const filteredFields = fields.filter(el => el.label.toLowerCase().includes(generativePropSearch.toLowerCase()))
                if (!filteredFields?.length) {
                    delete tempCrmObjProps[key]
                } else {
                    tempCrmObjProps[key].fields = filteredFields
                }
            })

            Object.keys(tempGeneralObjProps).forEach(key => {
                Object.keys(tempGeneralObjProps[key]).forEach(innerKey => {
                    const { fields } = tempGeneralObjProps[key][innerKey]
                    const filteredFields = fields.filter(el => el.label.toLowerCase().includes(generativePropSearch.toLowerCase()))
                    if (!filteredFields?.length) {
                        delete tempGeneralObjProps[key][innerKey]
                    } else {
                        tempGeneralObjProps[key][innerKey].fields = filteredFields
                    }
                })
            })

            setCrmObjPropsFiltered({ ...tempCrmObjProps })
            setGeneralObjPropsFiltered({ ...tempGeneralObjProps })
        }
    }, 250)

    const selectedValue = data => {
        setGenerativePropSearch(data)
        navigator.clipboard.writeText(data)
            .then(() => {
                setCopyingMessage(`${data}   was copied.`);
                setTimeout(() => setCopyingMessage(null), 5000)
            })
            .catch((e) => {
                if (
                    String(e) === "DOMException: Document is not focused."
                    || String(e) === "NotAllowedError: Document is not focused."
                    || String(e) === "NotAllowedError: Clipboard write was blocked due to lack of user activation."
                ) {
                    setCopyingMessage('Click on page to start copying');
                    setTimeout(() => setCopyingMessage(null), 5000)
                    return false;
                }
            });
    }

    const freshdeskChange = property => {
        const totalData = {
            freshdeskSelectedColumns,
            freshdeskFilterPriority,
            freshdeskFilterStatus,
            freshdeskFilterType,
            freshdeskColumnLabels,
            freshdeskPlaceholder,
            ...property
        }
        const columns = totalData?.freshdeskSelectedColumns?.map(el => el.value)
        const priorities = totalData?.freshdeskFilterPriority?.map(el => el.value)
        const statuses = totalData?.freshdeskFilterStatus?.map(el => el.value)
        const types = totalData?.freshdeskFilterType?.map(el => el.value)

        const prioritiesString = !!priorities?.length ? `priorities-${priorities.join(',')}` : ''
        const statusesString = !!statuses?.length ? `${prioritiesString ? ';' : ''}statuses-${statuses.join(',')}` : ''
        const typesString = !!types?.length ? `${prioritiesString || statusesString ? ';' : ''}types-${types.join(',')}` : ''

        const columnLabels = !!totalData?.freshdeskColumnLabels?.length ? `;labels-${totalData?.freshdeskColumnLabels}` : ''

        const placeholder = !!totalData?.freshdeskPlaceholder?.length ? `;placeholder-${totalData?.freshdeskPlaceholder}` : ''
        selectedValue(`{{freshdesk:${columns}.filter-${prioritiesString}${statusesString}${typesString}${columnLabels}${placeholder}}}`)
    }

    return (
        <ViewContainer>
            <Row noMargin className={cn.googleDoc}>
                <Row noMargin flexGrow columnFlex fullHeight className={cn.iframeWrapper}>
                    <div className={cn.explanation}>
                        If you are unable to edit the document despite having permissions to do so, ensure you are using a browser that allows third party cookies and they are enabled.
                    </div>
                    <iframe
                        title={`Google Doc - ${id}`}
                        src={`https://docs.google.com/document/d/${id}/edit?embedded=true&pli=1`}
                    />
                </Row>
                <div className={cn.options}>
                    {!!copyingMessage && (
                        <div className={cn.notification}>
                            {copyingMessage}
                        </div>
                    )}
                    <SubHeader>
                        Options
                    </SubHeader>
                    <br />
                    <Input
                        fullWidth
                        value={generativePropSearch}
                        onChange={onGenerativePropSearchChange}
                        placeholder={'Search and Filter the options underneath'}
                    />
                    {!!freshdeskObjProps ? (
                        <>
                            <br />
                            <Hr />
                            <br />
                            <SubHeader>
                                Freshdesk
                            </SubHeader>
                            <br />
                            <Label>
                                Table Columns
                            </Label>
                            <SelectWrapper
                                isMulti
                                smaller
                                options={freshdeskObjProps?.fields?.map(el => ({
                                    ...el,
                                    value: el.name
                                }))}
                                value={freshdeskSelectedColumns}
                                onChange={data => {
                                    freshdeskChange({ freshdeskSelectedColumns: data })
                                    setFreshdeskSelectedColumns(data)
                                }}
                            />
                            <br />
                            <Label>
                                Overwrite Columns
                            </Label>
                            <Input
                                fullWidth
                                placeholder={'Use comma separated list'}
                                value={freshdeskColumnLabels}
                                onChange={e => {
                                    freshdeskChange({ freshdeskColumnLabel: e.target.value })
                                    setFreshdeskColumnLabels(e.target.value)
                                }}
                            />
                            <br />
                            <Label>
                                Placeholder if no data
                            </Label>
                            <Input
                                fullWidth
                                value={freshdeskPlaceholder}
                                onChange={e => {
                                    freshdeskChange({ freshdeskPlaceholder: e.target.value })
                                    setFreshdeskPlaceholder(e.target.value)
                                }}
                            />
                            <br />
                            <Label>
                                Filters
                            </Label>

                            <br />
                            <br />
                            <Row
                                noMargin
                                fullWidth
                                spaceBetween
                                dataTip={`
                                    If you select multiple types, they will be combined by an OR condition.
                                    For example - Tickets which type is a 'Question' OR 'Refund'
                                `}
                            >
                                <Label>
                                    Type Equal to
                                </Label>
                                <FontAwesomeIcon
                                    icon={faInfoCircle}
                                />
                            </Row>
                            <SelectWrapper
                                isMulti
                                smaller
                                options={freshdeskObjProps?.types}
                                value={freshdeskFilterType}
                                onChange={data => {
                                    freshdeskChange({ freshdeskFilterType: data })
                                    setFreshdeskFilterType(data)
                                }}
                            />

                            <br />
                            <br />
                            <Row
                                noMargin
                                fullWidth
                                spaceBetween
                                dataTip={`
                                    If you select multiple statuses, they will be combined by an OR condition.
                                    For example - Tickets which status is a 'Closed' OR 'Resolved'
                                `}
                            >
                                <Label>
                                    Status Equal to
                                </Label>
                                <FontAwesomeIcon
                                    icon={faInfoCircle}
                                />
                            </Row>
                            <SelectWrapper
                                isMulti
                                smaller
                                options={freshdeskObjProps?.statuses}
                                value={freshdeskFilterStatus}
                                onChange={data => {
                                    freshdeskChange({ freshdeskFilterStatus: data })
                                    setFreshdeskFilterStatus(data)
                                }}
                            />

                            <br />
                            <br />
                            <Row
                                noMargin
                                fullWidth
                                spaceBetween
                                dataTip={`
                                    If you select multiple priorities, they will be combined by an OR condition.
                                    For example - Tickets which status is a 'Urgent' OR 'Critical'
                                `}
                            >
                                <Label>
                                    Priority Equal to
                                </Label>
                                <FontAwesomeIcon
                                    icon={faInfoCircle}
                                />
                            </Row>
                            <SelectWrapper
                                isMulti
                                smaller
                                options={freshdeskObjProps?.priorities}
                                value={freshdeskFilterPriority}
                                onChange={data => {
                                    freshdeskChange({ freshdeskFilterPriority: data })
                                    setFreshdeskFilterPriority(data)
                                }}
                            />
                        </>
                    ) : null}
                    <br />
                    <Hr />
                    <br />
                    <SubHeader>
                        Standard Fields
                    </SubHeader>
                    {Object.keys(generalObjPropsFiltered).map(key => (
                        <ViewContainer collapsible title={key} key={key}>
                            {Object.keys(generalObjPropsFiltered[key]).map(innerKey => (
                                <>
                                    <Row noMargin key={innerKey}>
                                        {generalObjPropsFiltered[key][innerKey].label}
                                    </Row>
                                    {generalObjPropsFiltered[key][innerKey].fields.map(el => (
                                        <Row
                                            noMargin
                                            key={`${innerKey}-${el.value}`}
                                            data-tooltip-id='default'
                                            data-tooltip-content='Click to Copy'
                                            className={cn.optionForSelection}
                                            onClick={() => {
                                                selectedValue(`{{${key}:${innerKey}.${el.value}}}`)
                                            }}
                                        >
                                            <Nbsp />
                                            <div>
                                                {el.label}
                                            </div>
                                        </Row>
                                    ))}
                                </>
                            ))}
                        </ViewContainer>
                    ))}
                    <Hr />
                    <br />
                    <SubHeader>
                        Salesforce Fields
                    </SubHeader>
                    {
                        Object.keys(crmObjPropsFiltered).map(crmObject => (
                            <ViewContainer collapsible title={crmObject} key={crmObject}>
                                {
                                    crmObjPropsFiltered[crmObject].fields.map(field => {
                                        if (crmObject === 'Account') {
                                            return (
                                                <Row
                                                    noMargin
                                                    key={field.value}
                                                    data-tooltip-id='default'
                                                    data-tooltip-content='Click to Copy'
                                                    className={cn.optionForSelection}
                                                    onClick={() => selectedValue(`{{crm:${crmObjPropsFiltered[crmObject].name}.${field.value}}}`)}
                                                >
                                                    <Nbsp />
                                                    <div>
                                                        {field.label}
                                                    </div>
                                                </Row>
                                            )
                                        }
                                        return crmObjPropsFiltered?.Opportunity?.reducer?.values?.map(reducer => (
                                            <Row
                                                noMargin
                                                key={`${field.value} ${reducer.value}`}
                                                data-tooltip-id='default'
                                                data-tooltip-content='Click to Copy'
                                                className={cn.optionForSelection}
                                                onClick={() => selectedValue(`{{crm:${crmObjPropsFiltered[crmObject].name}.${field.value}:${reducer.value}}}`)}
                                            >
                                                <Nbsp />
                                                <div>
                                                    {field.label} as {reducer.label}
                                                </div>
                                            </Row>
                                        ))
                                    })
                                }
                            </ViewContainer>
                        ))
                    }
                </div>
            </Row>
        </ViewContainer>
    )
}
