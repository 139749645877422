import React from 'react'
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import cn from './CalendarInput.module.css'
import { Input } from './Input';
import { Calendar } from '../assets/icons/Calendar';

export const renderDayContents = (day, date) => {
    const updatedDate = new Date(date).getDate()
    return <span data-test-id={`DATE_PICKER_DAY:${updatedDate}`}>{updatedDate}</span>;
};

export const CalendarInput = ({ value, onChange, smallerMargin, startDate=(new Date()).toISOString(), className, disabled, ...rest }) => {
    const selectedDate = value === null ? startDate : value;
    return (
        <DatePicker
            disabled={disabled}
            className={`${cn.datePickerWrapper} ${className || ''}`}
            selected={new Date(selectedDate)}
            onChange={onChange}
            showPopperArrow={false}
            popperClassName={`${cn.popper} ${smallerMargin ? cn.smallerMargin : ''}`}
            renderDayContents={renderDayContents}
            customInput={<div className={cn.input}>
                <Input fullWidth value={value} {...rest} disabled={disabled} testId={`CALENDAR-INPUT`} />
                <div className={cn.calendarIcon}>
                    <Calendar fill="#A0A4AA" />
                </div>
            </div>}
            dayClassName={cn.day}
        />
    )
}
