import React, { useEffect, useState } from 'react'
import { Line } from 'react-chartjs-2'
import { connect } from 'react-redux'
import moment from 'moment'
import cn from './HealthScore.module.css'
import { Loader, LOADER_SIZES, LOADER_TYPES } from './Loader'
import { ProductUsageElement } from './ProductUsageElement'
import { MODAL_SIZES, Modal } from './Modal'
import { TrendingLine } from '../assets/icons/TrendingLine'
import { makeRequest } from '../utilities/endpoints'
import { URLS } from '../config'

export const HealthScore = ({ colorScheme, data, accountName }) => {
    const [showGraph, setShowGraph] = useState(false)
    const [riskSettings, setRiskSettings] = useState({ red: 0, green: 0 })
    const [previousAccountName, setPreviousAccountName] = useState()
    const [loaded, setLoaded] = useState()

    const borderColor = colorScheme === 'DARK' ? '#FFFFFF' : '#111311'

    useEffect(() => {
        if (previousAccountName !== accountName && showGraph) {
            setPreviousAccountName(accountName)
            setLoaded(false)
            makeRequest.get(`${URLS.main}/risk/formula-parts`)
                .then(res => {
                    setRiskSettings(res?.data?.formulaSettings)
                    setLoaded(true)
                })
        }
    }, [showGraph, accountName, previousAccountName])

    const graphConfig = {
        labels: null,
        datasets: [
            {
                data: !!data?.historicalData?.length ? data.historicalData.map(el => el.score) : [],
                fill: false,
                borderColor,
                tension: 0.1,
                label: `Risk over the last ${moment().diff(moment(!!data?.historicalData && data?.historicalData[0]?.timestamp), 'weeks')} weeks`
            },
            {
                data: Array(52).fill(riskSettings?.red),
                fill: false,
                borderColor: '#e10000',
                label: `Red Treshhold`
            },
            {
                data: Array(52).fill(riskSettings?.green),
                fill: false,
                borderColor: '#3eb984',
                label: `Green Treshhold`
            }
        ],
        fill: false,
        tension: 0.1,
    }

    const graphOptions = {
        type: 'line',
        indexAxis: 'x',
        maintainAspectRatio: false,
        plugins: {
            tooltip: {
                enabled: true
            }
        },
        scales: {
            y: {
                min: Math.min(...graphConfig.datasets[0].data, riskSettings?.red, riskSettings?.green, 0),
                max: Math.max(...graphConfig.datasets[0].data, riskSettings?.red, riskSettings?.green, 10) + 1
            }
        },
    };

    const insufficientData = data.historicalData?.length <= 1

    return <div
        className={`${cn.healthScore} ${cn.pointer}`}
        data-tooltip-id='default'
        data-tooltip-content={insufficientData ? 'Insufficient Data' : undefined}
        onClick={() => {
            if (!insufficientData) {
                setShowGraph(true)
            }
        }}
    >
        <ProductUsageElement data={data.color} />
        <div className={cn.text}>{data.score}</div>
        {!data.historicalData?.length ? null : data.historicalData[data.historicalData.length - 2]?.score <= data.score ? (
            <TrendingLine fill={'#A0FFBB'} />
        ) : (
            <div className={cn.flipped}>
                <TrendingLine fill={'#CB3D2C'} />
            </div>
        )}
        {showGraph && (
            <Modal
                minHeight
                minWidth
                header={`Risk Trend for '${accountName}'`}
                onClose={(e) => {
                    setShowGraph(false)
                    try {
                        // stop propagation, to avoid the event bubbling up to the parent node and triggering the click to open the modal
                        e.stopPropagation()
                    } catch {
                        // do nothing, it means it was closed via the esc button
                    }
                }}
                size={MODAL_SIZES.BIG}
            >
                <div className={cn.lineWrapper}>
                    {loaded ? (
                        <Line
                            data={{
                                ...graphConfig,
                                labels: [...new Set(data.historicalData.map(el => moment(el.timestamp).format('MM.DD.YYYY')))],
                            }}
                            options={graphOptions}
                        />
                    ) : (
                        <Loader type={LOADER_TYPES.SPINNER} size={LOADER_SIZES.BIG} />
                    )}
                </div>
            </Modal>
        )}
    </div>
}

export const mapStateToProps = (state, ownProps) => {
    return {
        ...state,
        ...ownProps
    }
}

export const HealthScoreConnected = connect(mapStateToProps)(HealthScore)