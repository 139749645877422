import React, { useEffect, useRef, useState } from 'react'
import { connect } from 'react-redux'
import { useParams } from 'react-router-dom'
import { SketchPicker } from "react-color";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { accountMapContactRoleIcons } from '../../components/accountMapContactRolesSettings'
import { Button } from '../../components/Button'
import { Heading } from '../../components/Heading'
import { Label } from '../../components/Label'
import { TextArea } from '../../components/TextArea'
import { Error } from '../../components/Error'
import cn from './Workspace.module.css'
import { makeRequest } from '../../utilities/endpoints'
import { URLS } from '../../config'
import { ViewContainer } from '../../components/ViewContainer'
import { Input } from '../../components/Input'
import { Row } from '../../components/Row'
import { Dropdown } from '../../components/Dropdown'
import { useInterval } from '../../customHooks/useInterval'
import { Loader, LOADER_SIZES, LOADER_TYPES } from '../../components/Loader';
import { PermissionsPerGroupConnected } from './components/PermissionsPerGroup'
import { Hr } from '../../components/Hr'
import { PermissionCheck } from '../../components/PermissionCheck'
import { WorkspaceTypeCheck } from '../../components/WorkspaceTypeCheck'
import { getUserPermissions, permissionCheck } from '../../utilities/permissionCheck'
import { Nbsp } from '../../components/Nbsp'
import { Checkbox } from '../../components/Checkbox'
import { useOutsideClickHandler } from '../../customHooks/useOutsideClickHandler'
import { workspaceTypeCheck } from '../../utilities/workspaceType'
import { Tableau } from './components/integrations/Tableau';
import { Redshift } from './components/integrations/Redshift';
import { Risk } from './components/Risk';
import { GeneralSettings } from './components/GeneralSettings';
import { Zendesk } from './components/integrations/Zendesk';
import { translateCRMLabel } from '../../utilities/translateCRMLabels';
import { CustomGraph } from './components/CustomGraph';
import { Freshdesk } from './components/integrations/Freshdesk';
import { AccountView } from './components/AccountView';
import { Reports } from './components/Reports';
import { Teams } from './components/Teams';

const defaultAccountSizes = {
    smb: 1,
    mid: 100,
    ent: 1001
}

export const Workspace = () => {
    getUserPermissions()
    const { scrollSection } = useParams()
    const [userEmails, setUserEmails] = useState('')
    const [usersInTheWorkspace, setUsersInTheWorkspace] = useState([])
    const [usersInTheWorkspaceLoaded, setUsersInTheWorkspaceLoaded] = useState(false)
    const [numberOfLicenses, setNumberOfLicenses] = useState(null)
    const [licenseReachedError, setLicenseReachedError] = useState(false)
    const [errorMsg, setErrorMsg] = useState(false)
    const [sfConnectionData, setSFConnectionData] = useState({})
    const [hubspotConnectionData, setHubspotConnectionData] = useState({})

    const [products, setProducts] = useState([])
    // eslint-disable-next-line
    const [productsLoaded, setProductsLoaded] = useState(false)
    const [checkForSFDCData, setCheckForSFDCData] = useState(false)
    const [settings, setSettings] = useState({
        accountSizeLimitsSMB: defaultAccountSizes.smb,
        accountSizeLimitsMID: defaultAccountSizes.mid,
        accountSizeLimitsENT: defaultAccountSizes.ent,
    })
    const [settingsLoaded, setSettingsLoaded] = useState(false);
    const [opportunityStages, setOpportunityStages] = useState({ types: [], stages: [] })
    const [oppSettingsButtonEnabled, setOppSettingsButtonEnabled] = useState(true)
    const [durationOptions, setDurationOptions] = useState([])

    const [permissionOptions, setPermissionOptions] = useState({})
    const [permissionGroups, setPermissionGroups] = useState([])
    const [permissionsError, setPermissionsError] = useState(null)

    const [departments, setDepartments] = useState([])
    const [newDepartmentName, setNewDepartmentName] = useState()
    const [newDepartmentKeywords, setNewDepartmentKeywords] = useState()
    const [departmentUsageSetting, setDepartmentUsageSetting] = useState()
    const [newDepartmentColor, setNewDepartmentColor] = useState('#FFFFFF')

    const [newContactRoleName, setNewContactRoleName] = useState()
    const [newContactRoleIcon, setNewContactRoleIcon] = useState()
    const [accountMapContactRoleUsageSetting, setAccountMapContactRoleUsageSetting] = useState(true)
    const [contactRoles, setContactRoles] = useState([])
    const [showDepartmentColorPicker, setShowDepartmentColorPicker] = useState(false)
    const [customAccountFields, setCustomAccountFields] = useState([])
    const [standardAccountFields, setStandardAccountFields] = useState([])

    const [globalWhiteSpaceColumns, setGlobalWhiteSpaceColumns] = useState([])
    const [newGlobalWhiteSpaceColumn, setNewGlobalWhiteSpaceColumn] = useState({ type: 'Checkbox' })

    const productFaimiliesRef = useRef()
    const accountTemplatesRef = useRef();
    const opportunityOptionsRef = useRef();
    const contactRolesRef = useRef()
    const departmentsPickerRef = useRef()

    useOutsideClickHandler(departmentsPickerRef, () => setShowDepartmentColorPicker(false))


    const getUsersToBeAdded = () => userEmails.split('\n').map(el => el.trim()).filter(el => !!el)

    const addUsers = () => {
        makeRequest.post(`${URLS.main}/workspace/add/accountManagers`, {
            emails: getUsersToBeAdded()
        }, 3)
            .then((res) => {
                getUsers()
                if (res.data && res.data.success === false && res.data.emails.length > 0) {
                    setErrorMsg(`User[s] already exists: ${res.data.emails.join(', ')}`);
                    return false;
                }
                setErrorMsg(false);
            })
            .catch((err) => {
                setErrorMsg(err.response?.data?.message?.sqlMessage || err.response?.data?.message || false);
            });
        setUserEmails('')
    }

    const getDurationOptions = () => {
        makeRequest.get(`${URLS.main}/opportunities/durationOptions`, {}, true)
            .then(res => {
                setDurationOptions(res.data)
            })
    }

    const getPermissionOptions = () => {
        makeRequest.get(`${URLS.main}/permissions/options`, {}, true)
            .then(res => {
                setPermissionOptions(res.data)
            })
    }

    const getPermissionGroups = () => {
        makeRequest.get(`${URLS.main}/permissions/groups`, {}, true)
            .then(res => {
                setPermissionGroups(res.data)
            })
    }

    const setInvitationEmails = e => {
        setUserEmails(e.target.value)
        const remainingLicenses = numberOfLicenses - usersInTheWorkspace.length
        if (getUsersToBeAdded().length > remainingLicenses) {
            setLicenseReachedError(remainingLicenses)
        } else {
            setLicenseReachedError(false)
        }
    }

    const getOpportunityStages = () => {
        makeRequest.get(`${URLS.main}/opportunities/stages`, {}, true)
            .then(res => {
                if (res && res.status === 200) {
                    setOpportunityStages(res.data)
                }
            })
    }

    const getUsers = () => {
        setUsersInTheWorkspaceLoaded(false);
        makeRequest.get(`${URLS.main}/workspace/users`, {}, true)
            .then(res => {
                if (res && res.data && res.data.success) {
                    setUsersInTheWorkspace(res.data.users)
                }
                setUsersInTheWorkspaceLoaded(true);
            }).catch(error => {
                console.error(error);
                setUsersInTheWorkspaceLoaded(true);
            });
    }

    const getSettings = () => {
        setSettingsLoaded(false);
        makeRequest.get(`${URLS.main}/settings`, {}, true)
            .then(res => {
                if (res && res.data) {
                    const obj = {}
                    res.data.forEach(setting => {
                        obj[setting.name] = setting.value
                        if (setting.name === 'useBoth') {
                            obj[setting.name] = setting.value === 'true'
                        }
                    })
                    setSettings(obj)
                    setAccountMapContactRoleUsageSetting(res.data.find(el => el.name === "accountMapContactRoleUsageSetting")?.value !== '0')
                }
                setSettingsLoaded(true);
            }).catch(error => {
                console.error(error);
                setSettingsLoaded(true);
            });
    }

    const loadSFDCConnectionData = () => {
        makeRequest.get(`${URLS.main}/sfConnectionOptions`, {}, true)
            .then(res => {
                if (res && res.data && res.data.sfCredentials) {
                    setSFConnectionData(res.data.sfCredentials)
                }
            })
    }

    const loadHubspotConnectionData = () => {
        makeRequest.get(`${URLS.main}/hubspotConnectionOptions`)
            .then(res => {
                if (res && res.data && res.data.hubspotCredentials) {
                    setHubspotConnectionData(res.data.hubspotCredentials)
                }
            })
    }

    useEffect(() => {
        loadSFDCConnectionData();
        loadHubspotConnectionData();
        makeRequest.get(`${URLS.main}/workspace/licenses`, {}, true)
            .then(res => {
                if (res && res.data && res.data.success) {
                    setNumberOfLicenses(res.data.licenses)
                }
            })
        getUsers()
        getOpportunityStages()
        getSettings()
        getDurationOptions()
        getPermissionOptions()
        getPermissionGroups()
        getProducts()
        getDepartments()
        getContactRoles()
        getCustomAccountFields()
        loadGlobalWhitespaceColumns()
    }, [])

    useEffect(() => {
        const loaded = !!usersInTheWorkspace.length && !!permissionGroups.length
        if (scrollSection && loaded) {
            if (scrollSection === 'productFamilies' && productFaimiliesRef?.current) {
                setTimeout(() => {
                    // use effect takes another render to update the scrollRef, but the usual JS setTimeout 0 workaround works
                    productFaimiliesRef.current && productFaimiliesRef.current.scrollIntoView({
                        behavior: 'smooth'
                    })
                }, 0);
            }
        }
    }, [scrollSection, productFaimiliesRef, usersInTheWorkspace, permissionGroups])


    useEffect(() => {
        const loaded = !!usersInTheWorkspace.length && !!permissionGroups.length
        if (scrollSection && loaded) {
            if (scrollSection === 'opportunityOptions' && opportunityOptionsRef?.current) {
                setTimeout(() => {
                    // use effect takes another render to update the scrollRef, but the usual JS setTimeout 0 workaround works
                    opportunityOptionsRef.current && opportunityOptionsRef.current.scrollIntoView({
                        behavior: 'smooth'
                    })
                }, 0);
            }
        }
    }, [scrollSection, opportunityOptionsRef, usersInTheWorkspace, permissionGroups])

    useEffect(() => {
        const loaded = !!contactRoles.length
        if (scrollSection && loaded) {
            if (scrollSection === 'contactRoles' && contactRolesRef?.current) {
                setTimeout(() => {
                    // use effect takes another render to update the scrollRef, but the usual JS setTimeout 0 workaround works
                    contactRolesRef.current && contactRolesRef.current.scrollIntoView({
                        behavior: 'smooth'
                    })
                }, 0);
            }
        }
    })

    const getCustomAccountFields = () => {
        makeRequest.get(`${URLS.main}/describe/Account`, {}, true)
            .then(res => {
                if (workspaceTypeCheck(['CALLYPSO'])) {
                    setCustomAccountFields(res.data?.fields)
                } else {
                    setStandardAccountFields(res.data?.fields?.filter(el => !el.value.includes('__c')))
                    setCustomAccountFields(res.data?.fields?.filter(el => el.value.includes('__c') || el.value === 'Type'))
                }
            })
    }

    const getProducts = () => {
        setProductsLoaded(false);
        makeRequest.get(`${URLS.main}/products`, {}, true)
            .then((res) => {
                setProducts(res.data)
                setProductsLoaded(true);
            }).catch(error => {
                console.error(error);
                setProductsLoaded(true);
            });
    }

    const hasSFDCConnection = () => {
        return sfConnectionData.sfAccessToken && sfConnectionData.sfRefreshToken && sfConnectionData.sfUserId
    }

    const hasHubspotConnection = () => {
        return hubspotConnectionData.hubspotAccessToken && hubspotConnectionData.hubspotRefreshToken
    }

    useInterval(() => {
        if (hasSFDCConnection()) {
            setCheckForSFDCData(false)
        } else {
            loadSFDCConnectionData()
        }
    }, checkForSFDCData ? 1.5 * 1000 : null)

    const connectToSFDC = () => {
        setCheckForSFDCData(true)
        window.open(`${URLS.main}/oauth2/auth`)
    }

    const connectToHubspot = () => {
        window.location.url(`${URLS.main}/hubspot-install`)
    }

    const disconnectSFDC = () => {
        setSFConnectionData({})
        makeRequest.delete(`${URLS.main}/settings/sfdc`)
    }

    const disconnectHubspot = () => {
        setSFConnectionData({})
        makeRequest.delete(`${URLS.main}/settings/hubspot`)
    }

    const removeUser = userId => {
        makeRequest.delete(`${URLS.main}/workspace/${userId}`)
            .then(getUsers)
    }

    const saveOpportunitiesOptions = () => {
        disableOppSettingsSaveButton()
        makeRequest.post(`${URLS.main}/settings`, {
            ...settings
        })
            .then(enableOppSettingsSaveButton)
    }

    const enableOppSettingsSaveButton = () => {
        setOppSettingsButtonEnabled(true)
    }

    const disableOppSettingsSaveButton = () => {
        setOppSettingsButtonEnabled(false)
    }


    useEffect(() => {
        const loaded = !!usersInTheWorkspace.length && !!permissionGroups.length
        if (loaded && scrollSection && scrollSection === 'planTemplates' && accountTemplatesRef?.current) {
            setTimeout(() => {
                // use effect takes another render to update the scrollRef, but the usual JS setTimeout 0 workaround works
                accountTemplatesRef.current && accountTemplatesRef.current.scrollIntoView({
                    behavior: 'smooth'
                })
            }, 0);
        }
    }, [accountTemplatesRef, scrollSection, usersInTheWorkspace, permissionGroups]);

    const onGroupAdded = name => {
        makeRequest.post(`${URLS.main}/permissions/group/${name}`)
            .then(res => {
                setPermissionGroups([...permissionGroups, {
                    name: name,
                    id: res.data.newGroupId,
                    features: [],
                    defaultGroup: 0,
                }])
            })
    }

    const onRemoveGroup = id => {
        const currentPermissionGroups = permissionGroups.slice()
        makeRequest.delete(`${URLS.main}/permissions/group/${id}`)
            .catch(error => {
                setPermissionsError(error?.response?.data?.message)
                setPermissionGroups(currentPermissionGroups)
            })
        setPermissionGroups(permissionGroups.filter(el => el.id !== id))
    }

    const onUserGroupChange = (userId, groupId) => {
        makeRequest.put(`${URLS.main}/permissions/${userId}/${groupId}`)
        setUsersInTheWorkspace(usersInTheWorkspace.map(el => ({
            ...el,
            permissionGroup: el.id === userId ? groupId : el.permissionGroup
        })))
    }

    const onGroupPermissionChange = (groupId, permission, level, checked) => {
        setPermissionGroups(permissionGroups.map(el => {
            const features = el.features
            if (el.id === groupId) {
                if (checked) {
                    return {
                        ...el,
                        features: [
                            ...features,
                            {
                                feature: permission,
                                level: permissionOptions.levels[level],
                                groupId
                            }
                        ]
                    }
                }
                return {
                    ...el,
                    features: features.filter(el => el.feature !== permission || el.level !== permissionOptions.levels[level])
                }
            }
            return el
        }))

        makeRequest.put(`${URLS.main}/permissions/group/update/${groupId}`, {
            level,
            permission,
            checked,
        })
    }

    const getDepartments = () => {
        makeRequest.get(`${URLS.main}/departments`, {}, true)
            .then(res => {
                setDepartments(res?.data?.departments)
                setDepartmentUsageSetting(res?.data?.departmentUsageSetting !== '0')
            })
    }

    const addNewDepartment = () => {
        makeRequest.post(`${URLS.main}/departments`, {
            name: newDepartmentName,
            keywords: newDepartmentKeywords,
            color: newDepartmentColor
        })
            .then(getDepartments)
        setNewDepartmentName('')
        setNewDepartmentKeywords('')
    }

    const deleteCustomDepartment = id => {
        makeRequest.delete(`${URLS.main}/departments/${id}`)
            .then(getDepartments)
    }

    const getContactRoles = () => {
        makeRequest.get(`${URLS.main}/accountMapContactRoles`, {}, true)
            .then(res => {
                setContactRoles(res.data)
            })
    }

    const addNewContatRole = () => {
        setNewContactRoleIcon(undefined)
        setNewContactRoleName('')
        makeRequest.post(`${URLS.main}/accountMapContactRoles`, {
            name: newContactRoleName,
            icon: newContactRoleIcon
        })
            .then(getContactRoles)
    }

    const deleteContactRole = id => {
        makeRequest.delete(`${URLS.main}/accountMapContactRoles/${id}`)
            .then(getContactRoles)
    }

    const onChangeColorForDepartment = ({ hex }, id) => {
        if (id) {
            makeRequest.put(`${URLS.main}/departments/${id}`, { color: hex })
            setDepartments(departments.map(el => ({
                ...el,
                color: el.key === id ? hex : el.color
            })))
        } else {
            setNewDepartmentColor(hex)
        }
    }

    const loadGlobalWhitespaceColumns = () => {
        makeRequest.get(`${URLS.main}/global-white-space-planning-columns`, {}, true)
            .then(res => {
                if (res && res.data) {
                    setGlobalWhiteSpaceColumns(res.data)
                }
            })
    }

    const saveNewGlobalWhitespaceColumn = () => {
        makeRequest.post(`${URLS.main}/global-white-space-planning-columns`, newGlobalWhiteSpaceColumn)
            .then(loadGlobalWhitespaceColumns)
    }

    const deleteGlobalWhiteSpaceColumn = (id) => {
        makeRequest.delete(`${URLS.main}/global-white-space-planning-columns/${id}`, newGlobalWhiteSpaceColumn)
            .then(loadGlobalWhitespaceColumns)
    }

    const emptyOption = { label: '', value: '' }
    return (
        <div className={cn.settings}>
            <ViewContainer collapsible title={'Workspace members'} initiallyCollapsed={false}>
                {permissionsError && (
                    <Error>
                        <Row spaceBetween noMargin>
                            <div>
                                {permissionsError}
                            </div>
                            <Button
                                onClick={() => setPermissionsError(null)}
                            >
                                Dismiss
                            </Button>
                        </Row>
                    </Error>
                )}
                {!usersInTheWorkspaceLoaded && (
                    <Loader size={LOADER_SIZES.EXTRA_BIG} type={LOADER_TYPES.SPINNER} className={cn.loader} />
                )}
                {usersInTheWorkspaceLoaded && (
                    <PermissionsPerGroupConnected
                        groups={permissionGroups}
                        features={permissionOptions.features}
                        levels={permissionOptions.levels}
                        users={usersInTheWorkspace}
                        onGroupPermissionChange={onGroupPermissionChange}
                        onGroupAdded={onGroupAdded}
                        onRemoveGroup={onRemoveGroup}
                        onUserGroupChange={onUserGroupChange}
                        onRemoveUser={removeUser}
                    />
                )}
                <Hr />
                <div className={cn.userAddingWrapper}>
                    <Label>Emails</Label>
                    <Nbsp />
                    <TextArea
                        value={userEmails}
                        onChange={setInvitationEmails}
                        placeholder="Please use new lines to separate the email addresses."
                    />
                </div>
                <div className={cn.buttonWrapper}>
                    <Error shown={!!licenseReachedError || !!errorMsg}>
                        {!!licenseReachedError && (
                            <>
                                You are adding more users than the amount of licenses you have. Please remove
                                {' '}
                                {licenseReachedError}
                                {' '}
                                emails
                            </>
                        )}
                        {errorMsg}
                    </Error>
                    <Button className={cn.userAddingButton} disabled={!getUsersToBeAdded().length || licenseReachedError} onClick={addUsers}>Add Users</Button>
                </div>
            </ViewContainer>
            <WorkspaceTypeCheck requiredTypes={['SFDC', 'SFDC_SANDBOX', 'HUBSPOT']}>
                <PermissionCheck requiredPermissions={[['SETTINGS', 'UPDATE']]}>
                    <Teams
                        users={usersInTheWorkspace}
                    />
                </PermissionCheck>
            </WorkspaceTypeCheck>
            <WorkspaceTypeCheck requiredTypes={['SFDC', 'SFDC_SANDBOX']}>
                <PermissionCheck requiredPermissions={[['SETTINGS', 'UPDATE']]}>
                    <ViewContainer>
                        <Row noMargin fullWidth spaceBetween>
                            <Button
                                disabled={hasSFDCConnection()}
                                onClick={connectToSFDC}
                            >
                                {hasSFDCConnection() ? 'Callypso has been connected to your SFDC instance' : 'Connect Callypso to your SFDC'}
                            </Button>
                            {hasSFDCConnection() && (
                                <Button
                                    onClick={disconnectSFDC}
                                >
                                    Remove SFDC Connection
                                </Button>
                            )}
                        </Row>
                    </ViewContainer>
                </PermissionCheck>
            </WorkspaceTypeCheck>
            <WorkspaceTypeCheck requiredTypes={['HUBSPOT']}>
                <PermissionCheck requiredPermissions={[['SETTINGS', 'UPDATE']]}>
                    <ViewContainer>
                        <Row noMargin fullWidth spaceBetween>
                            <Button
                                disabled={hasHubspotConnection()}
                                onClick={connectToHubspot}
                            >
                                {hasHubspotConnection() ? 'Callypso has been connected to your Hubspot instance' : 'Connect Callypso to your Hubspot'}
                            </Button>
                            {hasHubspotConnection() && (
                                <Button
                                    onClick={disconnectHubspot}
                                >
                                    Remove Hubspot Connection
                                </Button>
                            )}
                        </Row>
                    </ViewContainer>
                </PermissionCheck>
            </WorkspaceTypeCheck>
            <ViewContainer collapsible title={'Integrations'}>
                <Tableau products={products} />
                <Redshift products={products} />
                <Zendesk />
                <Freshdesk fields={[...customAccountFields, ...standardAccountFields]} />
            </ViewContainer>
            <div ref={opportunityOptionsRef} />
            <ViewContainer collapsible title={`${translateCRMLabel('Opportunity')} options`}>
                <br />
                {!settingsLoaded && (
                    <Loader size={LOADER_SIZES.EXTRA_BIG} type={LOADER_TYPES.SPINNER} className={cn.loader} />
                )}
                {settingsLoaded && (
                    <>
                        <Row>
                            <Label>
                                {translateCRMLabel('Opportunity')} type that indicates renewal
                            </Label>
                            <Nbsp />
                            <Dropdown
                                values={[emptyOption, ...opportunityStages.types]}
                                value={settings.renewalType}
                                disabled={!permissionCheck('SETTINGS', 'UPDATE')}
                                onChange={e => setSettings({
                                    ...settings,
                                    renewalType: e.target.value
                                })}
                            />
                            <Label className={cn.oppOptionsDetails}>
                                If this is skipped, Callypso will try to calculate it on it's own
                            </Label>
                            <Nbsp />
                        </Row>
                        <Row>
                            <Label>
                                {translateCRMLabel('Opportunity')} type that indicates cross sell
                            </Label>
                            <Nbsp />
                            <Dropdown
                                values={[emptyOption, ...opportunityStages.types]}
                                value={settings.crossSellType}
                                disabled={!permissionCheck('SETTINGS', 'UPDATE')}
                                onChange={e => setSettings({
                                    ...settings,
                                    crossSellType: e.target.value
                                })}
                            />
                            <Label className={cn.oppOptionsDetails}>
                                If this is skipped, Callypso will try to calculate it on it's own
                            </Label>
                            <Nbsp />
                        </Row>
                        <Row>
                            <Label>
                                {translateCRMLabel('Opportunity')} type that indicates up sell
                            </Label>
                            <Nbsp />
                            <Dropdown
                                values={[emptyOption, ...opportunityStages.types]}
                                value={settings.upSellType}
                                disabled={!permissionCheck('SETTINGS', 'UPDATE')}
                                onChange={e => setSettings({
                                    ...settings,
                                    upSellType: e.target.value
                                })}
                            />
                            <Label className={cn.oppOptionsDetails}>
                                If this is skipped, Callypso will try to calculate it on it's own
                            </Label>
                            <Nbsp />
                        </Row>
                        <Row>
                            <Label>
                                {translateCRMLabel('Opportunity')} type that indicates expansion
                            </Label>
                            <Nbsp />
                            <Dropdown
                                values={[emptyOption, ...opportunityStages.types]}
                                value={settings.expansionType}
                                disabled={!permissionCheck('SETTINGS', 'UPDATE')}
                                onChange={e => setSettings({
                                    ...settings,
                                    expansionType: e.target.value
                                })}
                            />
                            <Label className={cn.oppOptionsDetails}>
                                If this is skipped, Callypso will try to calculate it on it's own
                            </Label>
                            <Nbsp />
                        </Row>
                        <br />
                        <hr />
                        <br />
                        <WorkspaceTypeCheck requiredTypes={['SFDC', 'SFDC_SANDBOX']}>
                            <>
                                <Row>
                                    <Label>
                                        Property indicating the duration of the {translateCRMLabel('opportunity')}
                                    </Label>
                                    <Nbsp />
                                    <Dropdown
                                        values={[emptyOption, ...durationOptions]}
                                        value={settings.durationOption}
                                        disabled={!permissionCheck('SETTINGS', 'UPDATE')}
                                        onChange={e => setSettings({
                                            ...settings,
                                            durationOption: e.target.value
                                        })}
                                    />
                                </Row>
                                <br />
                                <hr />
                                <br />
                            </>
                        </WorkspaceTypeCheck>
                        <WorkspaceTypeCheck requiredTypes={['SFDC', 'SFDC_SANDBOX', 'HUBSPOT']}>
                            <>
                                <Row>
                                    <Label>
                                        Default type for the {translateCRMLabel('Opportunity')} Management
                                    </Label>
                                    <Nbsp />
                                    <Dropdown
                                        values={[emptyOption, ...opportunityStages.types]}
                                        value={settings.defaultOpportunityManagementType}
                                        disabled={!permissionCheck('SETTINGS', 'UPDATE')}
                                        onChange={e => setSettings({
                                            ...settings,
                                            defaultOpportunityManagementType: e.target.value
                                        })}
                                    />
                                    <Label className={cn.oppOptionsDetails}>
                                        This filter can be updated on the {translateCRMLabel('Opportunities')} page as well, this is just the default value. If not set, Callypso will list all opportunities by default.
                                    </Label>
                                    <Nbsp />
                                </Row>
                                <br />
                                <hr />
                                <br />
                            </>
                        </WorkspaceTypeCheck>
                        <Button
                            onClick={saveOpportunitiesOptions}
                            disabled={!oppSettingsButtonEnabled || !permissionCheck('SETTINGS', 'UPDATE')}
                        >
                            Save
                        </Button>
                    </>
                )}
            </ViewContainer>

            <PermissionCheck requiredPermissions={[['GLOBAL_WHITE_SPACE_PLANNING', 'UPDATE']]}>
                <ViewContainer collapsible title='Global White Space Plan Settings'>
                    <Heading>Custom Columns</Heading>
                    {globalWhiteSpaceColumns.map(el => (
                        <>
                            <Row spaceBetween>
                                <div>{el.label}</div>
                                <Nbsp />
                                <div>{el.type}</div>
                                {el.type === 'Dropdown' && (
                                    <div>{el.options.split('\n').map(el => <div>{el}</div>)}</div>
                                )}
                                <Nbsp />
                                <Button
                                    onClick={() => deleteGlobalWhiteSpaceColumn(el.id)}
                                >
                                    DELETE
                                </Button>
                            </Row>
                            <Hr />
                        </>
                    ))}
                    <Row spaceBetween>
                        <Input
                            value={newGlobalWhiteSpaceColumn.label}
                            onChange={e => setNewGlobalWhiteSpaceColumn({ ...newGlobalWhiteSpaceColumn, label: e.target.value })}
                        />
                        <Nbsp />
                        <Dropdown
                            value={newGlobalWhiteSpaceColumn.type}
                            values={[
                                { label: 'Checkbox', value: 'Checkbox' },
                                { label: 'Dropdown', value: 'Dropdown' },
                            ]}
                            onChange={e => setNewGlobalWhiteSpaceColumn({ ...newGlobalWhiteSpaceColumn, type: e.target.value })}
                        />
                        {newGlobalWhiteSpaceColumn.type === 'Dropdown' && (
                            <>
                                <Nbsp />
                                <TextArea
                                    value={newGlobalWhiteSpaceColumn.options}
                                    onChange={e => setNewGlobalWhiteSpaceColumn({ ...newGlobalWhiteSpaceColumn, options: e.target.value })}
                                    placeholder={'Place each value on a new line'}
                                />
                            </>
                        )}
                        <Nbsp />
                        <Button onClick={saveNewGlobalWhitespaceColumn}>
                            Add
                        </Button>
                    </Row>
                    {/* <Heading>Product Families</Heading>
                    <SubHeader>Products</SubHeader>
                    {!productsLoaded && (
                        <Loader size={LOADER_SIZES.EXTRA_BIG} type={LOADER_TYPES.SPINNER} className={cn.loader} />
                    )}
                    {productsLoaded && products.map(product => (
                        <Row spaceBetween>
                            <div>{product.Name}</div>
                            <WorkspaceTypeCheck requiredTypes={['SFDC', 'SFDC_SANDBOX']}>
                                <Button
                                    onClick={() => window.open(`${user.sfUrl}/${product.Id}`)}
                                >
                                    View in SalesForce
                                </Button>
                            </WorkspaceTypeCheck>
                        </Row>
                    ))}
                    <SubHeader>
                        <Row noMargin spaceBetween>
                            <div>
                                Product Families
                            </div>
                            {productFamiliesLoaded && (
                                <Button
                                    onClick={() => {
                                        setShowModalForProductFamily(true)
                                        setProductFamilyData({})
                                    }}
                                >
                                    + NEW
                                </Button>
                            )}
                        </Row>
                    </SubHeader>
                    {!productFamiliesLoaded && (
                        <Loader size={LOADER_SIZES.EXTRA_BIG} type={LOADER_TYPES.SPINNER} className={cn.loader} />
                    )} */}
                    {/* {productFamiliesLoaded && productFamilies.map(productFamily => (
                        <Row spaceBetween>
                            <div>{productFamily.name}</div>
                            <Row noMargin>
                                <Button
                                    onClick={() => {
                                        setProductFamilyData(productFamily)
                                        setShowModalForProductFamily(true)
                                    }}
                                >
                                    Edit
                                </Button>
                                <Nbsp />
                                <Button
                                    onClick={() => setProductFamilyToBeDeleted(productFamily)}
                                >
                                    Delete
                                </Button>
                            </Row>
                        </Row>
                    ))} */}
                    <Row>
                        <Label>Select a custom field for how many potential buyers are in the account</Label>
                        <Nbsp />
                        <Dropdown
                            values={[emptyOption, ...(customAccountFields || [])]}
                            value={settings.potentialBuyersCRM}
                            disabled={!permissionCheck('SETTINGS', 'UPDATE')}
                            onChange={e => {
                                const updatedSettings = {
                                    ...settings,
                                    potentialBuyersCRM: e.target.value
                                }
                                setSettings(updatedSettings)
                                makeRequest.post(`${URLS.main}/settings`, updatedSettings)
                            }}
                        />
                    </Row>
                    <WorkspaceTypeCheck requiredTypes={['CALLYPSO']}>
                        <Row>
                            <Label>Select a custom field for how employees are in the account</Label>
                            <Nbsp />
                            <Dropdown
                                values={[emptyOption, ...(customAccountFields || [])]}
                                value={settings.allEmployeesCRM}
                                disabled={!permissionCheck('SETTINGS', 'UPDATE')}
                                onChange={e => {
                                    const updatedSettings = {
                                        ...settings,
                                        allEmployeesCRM: e.target.value
                                    }
                                    setSettings(updatedSettings)
                                    makeRequest.post(`${URLS.main}/settings`, updatedSettings)
                                }}
                            />
                        </Row>
                    </WorkspaceTypeCheck>
                </ViewContainer>
            </PermissionCheck>
            <PermissionCheck requiredPermissions={[['RISK_MANAGEMENT', 'UPDATE']]}>
                <Risk
                    divideSetting={settings?.riskDivideSetting}
                    onDivideSettingChange={e => {
                        const updatedSettings = {
                            ...settings,
                            riskDivideSetting: e.target.value
                        }
                        setSettings(updatedSettings)
                        makeRequest.post(`${URLS.main}/settings`, updatedSettings)
                    }}
                />
            </PermissionCheck>

            <CustomGraph />

            <ViewContainer collapsible title='Keywords and colors for departments'>
                {departments.map(department => (
                    <Row noAligning>
                        <Input disabled value={department.name} className={cn.departmentLabel} />
                        <Nbsp />
                        <Nbsp />
                        <Nbsp />
                        <TextArea disabled value={(department.titleMatches || []).join(', ')} />
                        {department.common === 0 && (
                            <>
                                <Nbsp />
                                <Nbsp />
                                <Nbsp />
                                <Button
                                    onClick={() => deleteCustomDepartment(department.key)}
                                >
                                    Delete
                                </Button>
                            </>
                        )}
                        <Nbsp />
                        <Nbsp />
                        <Nbsp />
                        {showDepartmentColorPicker === department.key ? (
                            <div ref={departmentsPickerRef} >
                                <SketchPicker
                                    className={cn.departmentsPicker}
                                    color={department.color || '#FFFFFF'}
                                    onChange={data => {
                                        onChangeColorForDepartment(data, department.key)
                                        setShowDepartmentColorPicker(false)
                                    }}
                                />
                            </div>
                        ) : (
                            <div
                                className={cn.departmentColorPickerIdentificator}
                                style={{ backgroundColor: department.color }}
                                onClick={() => {
                                    if (!department.common) {
                                        setShowDepartmentColorPicker(department.key)
                                    }
                                }}
                            />
                        )}
                        <Nbsp />
                        <Nbsp />
                        <Nbsp />
                        <Checkbox
                            alignTop
                            label={'Potential Buyers'}
                            // eslint-disable-next-line eqeqeq
                            checked={settings[`DEPARTMENT_${department.key}`] !== undefined ? settings[`DEPARTMENT_${department.key}`] == 1 : true}
                            onClick={() => {
                                // eslint-disable-next-line eqeqeq
                                const oldState = settings[`DEPARTMENT_${department.key}`] !== undefined ? settings[`DEPARTMENT_${department.key}`] == 1 : true
                                const updatedSettings = {
                                    ...settings,
                                    [`DEPARTMENT_${department.key}`]: !oldState
                                }
                                setSettings(updatedSettings)
                                makeRequest.post(`${URLS.main}/settings`, updatedSettings)
                            }}
                        />
                        {department.common === 0 && (
                            <>
                                <Nbsp />
                                <Nbsp />
                                <Nbsp />
                                <Button
                                    onClick={() => deleteCustomDepartment(department.key)}
                                >
                                    Delete
                                </Button>
                            </>
                        )}
                    </Row>
                ))}
                <Row noAligning>
                    <Input
                        placeholder='Department name'
                        value={newDepartmentName}
                        className={cn.departmentLabel}
                        onChange={e => setNewDepartmentName(e.target.value)}
                    />
                    <Nbsp />
                    <Nbsp />
                    <Nbsp />
                    <TextArea
                        placeholder='Please use new lines to separate each keyword'
                        value={newDepartmentKeywords}
                        onChange={e => setNewDepartmentKeywords(e.target.value)}
                    />
                    <Nbsp />
                    <Nbsp />
                    <div ref={departmentsPickerRef} >
                        <SketchPicker
                            className={cn.departmentsPicker}
                            color={newDepartmentColor || '#FFFFFF'}
                            onChange={data => {
                                onChangeColorForDepartment(data)
                                setShowDepartmentColorPicker(false)
                            }}
                        />
                    </div>
                    <Nbsp />
                    <Nbsp />
                    <Button onClick={addNewDepartment} className={cn.addNewDepartmentButton}>
                        Add new department
                    </Button>
                </Row>
                <Checkbox
                    checked={departmentUsageSetting}
                    onClick={() => {
                        setDepartmentUsageSetting(!departmentUsageSetting)
                        const updatedSettings = {
                            ...settings,
                            departmentUsageSetting: !departmentUsageSetting
                        }
                        setSettings(updatedSettings)
                        makeRequest.post(`${URLS.main}/settings`, updatedSettings)
                    }}
                    label={'Use both custom departments and predefined ones'}
                />
            </ViewContainer>

            <ViewContainer ref={contactRolesRef} title={`${translateCRMLabel('Account')} Map Contact Role/Type(s)`} collapsible>
                {contactRoles.map(contactRole => (
                    <Row>
                        <Input disabled value={contactRole.name} className={cn.grow} />
                        <Nbsp />
                        <Nbsp />
                        <Nbsp />
                        <div className={`${cn.iconWrapper} ${cn.selected}`}>
                            <FontAwesomeIcon icon={accountMapContactRoleIcons.find(el => el.iconName === contactRole.icon)} />
                        </div>
                        {contactRole.common === 0 && (
                            <>
                                <Nbsp />
                                <Nbsp />
                                <Nbsp />
                                <Button
                                    onClick={() => deleteContactRole(contactRole.id)}
                                >
                                    Delete
                                </Button>
                            </>
                        )}
                    </Row>
                ))}
                <Row>
                    <Input
                        placeholder='Contact Role/Type'
                        value={newContactRoleName}
                        onChange={e => setNewContactRoleName(e.target.value)}
                        className={cn.contactRoleName}
                    />
                    <Nbsp />
                    <Nbsp />
                    <Nbsp />
                    <div>
                        <span>Role/Type icon <i>Click to select any</i></span>
                        <div className={cn.contactRoleTypeIconsWrapper}>
                            {accountMapContactRoleIcons.map(el => (
                                <div onClick={() => setNewContactRoleIcon(el.iconName)} className={`${cn.iconWrapper} ${el.iconName === newContactRoleIcon ? cn.selected : ''}`}>
                                    <FontAwesomeIcon icon={el} />
                                </div>
                            ))}
                        </div>
                    </div>
                </Row>
                <Checkbox
                    checked={accountMapContactRoleUsageSetting}
                    onClick={() => {
                        setAccountMapContactRoleUsageSetting(!accountMapContactRoleUsageSetting)
                        const updatedSettings = {
                            ...settings,
                            accountMapContactRoleUsageSetting: !accountMapContactRoleUsageSetting
                        }
                        setSettings(updatedSettings)
                        makeRequest.post(`${URLS.main}/settings`, updatedSettings)
                    }}
                    label={'Use both custom account map contact role/type(s) and predefined ones'}
                />
                <br />
                <br />
                <Button onClick={addNewContatRole} disabled={!newContactRoleName || !newContactRoleIcon}>
                    Add new Role/Type(s)
                </Button>
            </ViewContainer>

            <WorkspaceTypeCheck requiredTypes={['SFDC', 'SFDC_SANDBOX']}>
                <GeneralSettings
                    customAccountFields={customAccountFields}
                    settings={settings}
                    onSettingChange={(settings) => {
                        setSettings(settings)
                        makeRequest.post(`${URLS.main}/settings`, { ...settings })
                    }}
                />
            </WorkspaceTypeCheck>
            <WorkspaceTypeCheck requiredTypes={['SFDC', 'SFDC_SANDBOX']}>
                <Reports
                    settings={settings}
                    onSettingChange={async (settings) => {
                        setSettings(settings)
                        await makeRequest.post(`${URLS.main}/settings`, { ...settings })
                    }}
                />
            </WorkspaceTypeCheck>
            <AccountView
                settings={settings}
                onSettingChange={async (settings, callback) => {
                    setSettings(settings)
                    await makeRequest.post(`${URLS.main}/settings`, { ...settings })
                    callback && callback()
                }}
                accountFields={[
                    ...standardAccountFields,
                    ...customAccountFields
                ]}
            />
            <br />
        </div>
    )
}

export const mapStateToProps = (state) => {
    return {
        user: state.user,
    }
}

export const WorkspaceConnected = connect(mapStateToProps)(Workspace)
