import React, { useRef } from 'react'
import ReactDOM from 'react-dom'
import { Times } from '../assets/icons/Times'
import { useOutsideClickHandler } from '../customHooks/useOutsideClickHandler'
import cn from './Modal.module.css'

export const MODAL_SIZES = {
    STANDARD: 1,
    SLIGHTLY_BIGGER: 2,
    BIG: 3,
}

const getSizeClass = size => {
    switch (size) {
    case MODAL_SIZES.BIG:
        return cn.modalBig
    case MODAL_SIZES.SLIGHTLY_BIGGER:
        return cn.modalSlightlyBigger
    default:
        return cn.modal
    }
}

export const Modal = ({
    header,
    size,
    children,
    buttons,
    onClose,
    minHeight,
    minWidth,
    minHeightSmall,
    className,
    sizerClassName,
    contentClassName,

    onClick,
    onMouseDown,
    onMouseUp,
    onMouseMove,
}) => {
    const wrapperRef = useRef(null)

    useOutsideClickHandler(wrapperRef, () => onClose())
    return ReactDOM.createPortal(
        (
            <div
                className={`${cn.modalWrapper} ${className}`}
                onClick={onClick}
                onMouseMove={onMouseMove}
                onMouseUp={onMouseUp}
                onMouseDown={e => {
                    const numberOfModals = document.querySelectorAll(`.${cn.modalWrapper}`).length
                    if (numberOfModals > 1) {
                        e.stopPropagation()
                    }
                    onMouseDown && onMouseDown(e)
                }}
            >
                <div
                    className={`
                        ${getSizeClass(size)}
                        ${minHeight ? cn.minHeight : ''}
                        ${minWidth ? cn.minWidth : ''}
                        ${minHeightSmall ? cn.minHeightSmall : ''}
                        ${sizerClassName}
                    `}
                    ref={wrapperRef}
                >
                    <div className={cn.headerRow}>
                        <div className={cn.headerLabel}>
                            {header}
                        </div>
                        <div className={cn.closeButton} onClick={onClose}>
                            <Times fill="#A0A4AA" />
                        </div>
                    </div>
                    <div className={`${cn.content} ${contentClassName}`}>
                        {children}
                    </div>
                    <div className={cn.buttons}>
                        {buttons}
                    </div>
                </div>
            </div>
        ),
        document.getElementById('root')
    );
}
