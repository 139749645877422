import React, { useState } from 'react'
import { Input } from '../Input'
import { SlateEditor } from '../SlateEditor';
import { convertNoteText, convertSimpleToRichText } from '../../utilities/slateUtils';
import { NoteAttachments } from './NoteAttachments';
import cn from './NotesCRUDWrapper.module.css'
import { workspaceTypeCheck } from '../../utilities/workspaceType';
import { Row } from '../Row';
import { BUTTON_TYPES, Button } from '../Button';
import { makeRequest } from '../../utilities/endpoints';
import { URLS } from '../../config';
import { SelectWrapperConnected } from '../SelectWrapper';

export const NotesCRUDWrapper = ({
    noteData: passedNoteData,
    selectedNoteContact: passedSelectedNtoeContact,
    accountId,
    onSavedNewNote,
    contacts,
    getContactLabel,
    scrollRef,
    noteToBeEditted,
    onCancelNewNote,
    parentType
}) => {
    const [selectedNoteContact, setSelectedNoteContact] = useState(passedSelectedNtoeContact)
    const [noteData, setNoteData] = useState(passedNoteData || {})
    const [disableNoteSave, setDisableNoteSave] = useState()

    const saveNewNote = () => {
        setDisableNoteSave(true)
        const data = {
            ...noteData
        }
        if (parentType === 'contact') {
            data.parentId = selectedNoteContact.value
        } else {
            data.parentId = accountId
        }

        if (parentType) {
            data.parentType === parentType
        }

        data.richTextDescription = data.description
        data.description = convertNoteText(data.description)
        const headers = !data?.attachments?.length ? undefined : {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        }
        makeRequest.post(`${URLS.main}/notes`, data, headers)
            .then(() => {
                cancelNewNote()
                onSavedNewNote()
                setDisableNoteSave(false)
            })
    }
    const cancelNewNote = () => {
        setSelectedNoteContact(undefined)
        setNoteData({})
        onCancelNewNote()
    }

    const updateNote = (passedData) => {
        const data = {
            ...(passedData || noteData)
        }

        if (!data.parentId) {
            if (parentType === 'contact') {
                data.parentId = selectedNoteContact.value
            } else {
                data.parentId = accountId
            }
        }

        const headers = !data?.addAttachments?.length ? undefined : {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        }

        data.richTextDescription = data.description
        data.description = convertNoteText(data.description)

        makeRequest.put(`${URLS.main}/notes/${passedData ? passedData.id : data.id}`, data, headers)
            .then(() => {
                cancelNewNote()
                onSavedNewNote()
            })
        setNoteData({})
    }

    return (
        <div className={cn.noteWrapper}>
            {parentType === 'contact' && (
                <>
                    <SelectWrapperConnected
                        placeholder="Select a Contact for the note"
                        options={contacts.map(el => ({
                            label: getContactLabel(el),
                            value: el.Id
                        }))}
                        value={selectedNoteContact}
                        onChange={setSelectedNoteContact}
                    />
                    <br />
                </>
            )}
            {!workspaceTypeCheck(['HUBSPOT']) && (
                <>
                    <Input
                        fullWidth
                        value={noteData?.title}
                        onChange={e => setNoteData({ ...noteData, title: e.target.value })}
                    />
                    <br />
                </>
            )}

            <SlateEditor
                forwardedRef={scrollRef}
                fullWidth
                value={noteToBeEditted ? noteData?.rich ? (noteData?.description || noteData?.body) : convertSimpleToRichText((noteData?.description || noteData?.body)) : (noteData?.description || noteData?.body)}
                onChange={e => {
                    setNoteData({ ...noteData, description: e })
                }}
            />
            {workspaceTypeCheck(['HUBSPOT']) && (
                <>
                    <br />
                    <Input
                        fullWidth
                        type='file'
                        multiple
                        value={noteData?.attachmentsData}
                        onChange={e => {
                            const data = {
                                ...noteData,
                                attachmentsData: e.target.value,
                            }
                            if (!!noteToBeEditted) {
                                data.addAttachments = !!e.target?.files?.length ? e.target.files : null
                            } else {
                                data.attachments = !!e.target?.files?.length ? e.target.files : null
                            }
                            setNoteData(data)
                        }}
                    />
                    {!!noteToBeEditted && (
                        <NoteAttachments
                            note={noteData}
                        />
                    )}
                    <br />
                </>
            )}
            <Row spaceBetween>
                <Button
                    type={BUTTON_TYPES.TEXT}
                    onClick={cancelNewNote}
                >
                    Cancel
                </Button>
                <Button
                    disabled={disableNoteSave || (!workspaceTypeCheck(['HUBSPOT']) && !noteData.title) || !noteData.description || (parentType === 'contact' && !selectedNoteContact?.value)}
                    type={BUTTON_TYPES.TEXT}
                    onClick={() => (!!noteToBeEditted ? updateNote() : saveNewNote())}
                >
                    {noteToBeEditted ? 'Update' : 'Add'}
                </Button>
            </Row>
        </div>
    )
}