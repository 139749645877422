import React, { useState } from 'react'
import { faTrashAlt } from '@fortawesome/free-solid-svg-icons'
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd'
import cn from './AccountView.module.css'
import { ViewContainer } from '../../../components/ViewContainer'
import { Row } from '../../../components/Row'
import { Label } from '../../../components/Label'
import { Nbsp } from '../../../components/Nbsp'
import { SelectWrapperConnected } from '../../../components/SelectWrapper'
import { BUTTON_TYPES, Button } from '../../../components/Button'
import { Hr } from '../../../components/Hr'

export const AccountView = ({ accountFields, settings = {}, onSettingChange }) => {
    const [newAccountFieldToBeAdded, setNewAccountFieldToBeAdded] = useState()
    const [settingsUpdated, setSettingsUpdated] = useState(true)
    const accountView = !!settings?.accountView?.length ? JSON.parse(settings.accountView).map((el, i) => ({ ...el, position: i })) : []

    const addNewField = () => {
        setSettingsUpdated(false)
        onSettingChange({
            ...settings,
            accountView: JSON.stringify([
                ...accountView,
                {
                    field: newAccountFieldToBeAdded?.value,
                    label: newAccountFieldToBeAdded?.label,
                    type: newAccountFieldToBeAdded?.type,
                    position: settings?.accountView?.length || 0
                }
            ])
        }, () => {
            setSettingsUpdated(true)
            setNewAccountFieldToBeAdded(null)
        })
    }

    const deleteField = (index) => {
        setSettingsUpdated(false)
        onSettingChange({
            ...settings,
            accountView: JSON.stringify(accountView.filter((_el, i) => i !== index))
        }, () => {
            setSettingsUpdated(true)
            setNewAccountFieldToBeAdded(null)
        })
    }

    const onDragEnd = (element) => {
        const elementData = accountView.find((_el, i) => i === element?.source?.index)
        let updatedAccountView = accountView.filter((_el, i) => i !== element?.source?.index)
        updatedAccountView.splice(element?.destination?.index, 0, elementData)
        updatedAccountView = updatedAccountView.map((el, i) => ({
            ...el,
            position: i,
        }))

        setSettingsUpdated(false)
        onSettingChange({
            ...settings,
            accountView: JSON.stringify(updatedAccountView)
        }, () => {
            setSettingsUpdated(true)
            setNewAccountFieldToBeAdded(null)
        })
    }

    return (
        <ViewContainer collapsible title={'Account Details Settings'}>
            <div>
                Add or edit any fields you'd want to see on the account details view
            </div>
            <Hr />
            <br />
            <br />
            <DragDropContext
                onDragEnd={onDragEnd}
            >
                <Droppable
                    droppableId={'1'}
                    ignoreContainerClipping={true}
                >
                    {(provided, snapshot) => {
                        return (
                            <div
                                {...provided.droppableProps}
                                ref={provided.innerRef}
                                className={`${cn.columnContainer} ${snapshot.isDraggingOver && cn.draggingOver}`}
                            >
                                {accountView.sort((a, b) => a.position - b.position).map((accountField, index) => {
                                    return (
                                        <Draggable
                                            key={accountField.field}
                                            draggableId={accountField.field}
                                            index={index}
                                        >
                                            {(provided/* , snapshot */) => {
                                                return (
                                                    <div
                                                        className={cn.draggableField}
                                                        ref={provided.innerRef}
                                                        {...provided.draggableProps}
                                                        {...provided.dragHandleProps}
                                                        style={provided.draggableProps.style}
                                                    >
                                                        <Row fullWidth spaceBetween>
                                                            <Label>
                                                                {accountField?.label} / {accountField?.field}
                                                            </Label>
                                                            <Button
                                                                disabled={!settingsUpdated}
                                                                type={BUTTON_TYPES.SMALL_ICON}
                                                                icon={faTrashAlt}
                                                                onClick={() => deleteField(index)}
                                                            />
                                                        </Row>
                                                    </div>
                                                )
                                            }}
                                        </Draggable>
                                    )
                                })}
                                {provided.placeholder}
                            </div>
                        )
                    }}
                </Droppable>
            </DragDropContext>
            <Row fullWidth spaceBetween>
                <Row noMargin>
                    <Label>Field</Label>
                    <Nbsp />
                    <SelectWrapperConnected
                        options={accountFields.filter(el => !accountView.map(el => el.field).includes(el.value))}
                        value={newAccountFieldToBeAdded}
                        onChange={setNewAccountFieldToBeAdded}
                    />
                </Row>
                <Button
                    disabled={!settingsUpdated || !newAccountFieldToBeAdded}
                    onClick={addNewField}
                >
                    Add
                </Button>
            </Row>
        </ViewContainer>
    )
}