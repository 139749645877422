import React from 'react';
import ReactDOM from 'react-dom';
import axios from 'axios'
import { config } from "@fortawesome/fontawesome-svg-core";
import 'react-tooltip/dist/react-tooltip.css'
import '@fortawesome/fontawesome-svg-core/styles.css'
import 'normalize.css';
import './index.css';
import store, { persistor } from './store';
import reportWebVitals from './reportWebVitals';
import { Routing } from './Routing';
import { isCRMEmbedded } from './utilities/crmEmbed';

config.autoAddCss = false;
axios.defaults.withCredentials = true

ReactDOM.render(
    <Routing store={store} persistor={persistor} />,
    document.getElementById('root')
);

if (isCRMEmbedded()) {
    axios.defaults.headers.common['x-salesforce-embedded'] = true
    document.getElementById('root').className = 'downscaled'
}

const scaleForSmallScreens = () => {
    const idealSize = 1900
    // based on screenshots from Michael and other people resolutions (aim is to fit 10 entries for risk and accounts, and plays, etc)
    // also based more loosly on the ideal resolution from figma previews
    if (window.innerWidth < idealSize) {
        document.body.style.zoom = window.innerWidth / idealSize
    } else {
        document.body.style.zoom = 'unset'
    }
}

window.onresize = scaleForSmallScreens
scaleForSmallScreens()

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
