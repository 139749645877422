import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux'
import moment from 'moment'
import { Doughnut, Bar } from 'react-chartjs-2';
import cn from './Metrics.module.css';
import ddcn from './DrillDown.module.css';
import { Row } from '../../components/Row';
import { Dropdown } from '../../components/Dropdown';
import { ViewContainer } from '../../components/ViewContainer';
import { Heading } from '../../components/Heading';
import { MoneyStatistic } from './components/MoneyStatistic';
import { StatsSummaryRow } from '../../components/StatsSummaryRow';
import { Label } from '../../components/Label';
import { makeRequest } from '../../utilities/endpoints'
import { formatLargeSums } from '../../utilities/currency';
import { URLS } from '../../config';
import { ComplexDropdown } from '../../components/ComplexDropdown';
import { Loader, LOADER_SIZES, LOADER_TYPES } from '../../components/Loader';
import { Modal, MODAL_SIZES } from '../../components/Modal';
import { TableExpand } from '../../components/TableExpand'
import { dateFormat } from '../../utilities/dateFormat'
import { getUserPermissions } from '../../utilities/permissionCheck';
import { workspaceTypeCheck } from '../../utilities/workspaceType';
import { WorkspaceTypeCheck } from '../../components/WorkspaceTypeCheck';
import { translateCRMLabel } from '../../utilities/translateCRMLabels';
import { getUserCrmId } from '../../utilities/userCrmId';

const doughnutData = {
    datasets: [
        {
            data: [12, 7],
            backgroundColor: [
                '#E10000',
                '#3EB984',
            ],
            borderColor: [
                '#FFFFFF',
                '#FFFFFF',
            ],
            borderWidth: 5,
            cutout: '80%',
        },
    ],
};

const doughnutOptions = {
    plugins: {
        legend: {
            display: false
        },
        tooltip: {
            enabled: false
        },
        circleArrow: false
    },
}

const data2 = {
    labels: ['Cross Sell', 'Up Sell', 'Renewal'],
    datasets: [
        {
            borderRadius: 4,
            label: '% of Opportunities in Criteria',
            data: [22, 79, 84],
            backgroundColor: 'rgba(37, 213, 161, 1)',
        },
        {
            borderRadius: 4,
            label: '% of Total Opportunities',
            data: [100, 100, 100],
            backgroundColor: 'rgba(37, 213, 161, 0.1)',
        },
    ],
};

const options = {
    indexAxis: 'y',
    plugins: {
        legend: {
            display: false
        },
        tooltip: {
            enabled: true
        },
        circleArrow: false
    },
    scales: {
        yAxes: {
            stacked: true,
        },
    },
};

const data3 = {
    labels: ['Cross Sell', 'Up Sell', 'Renewal'],
    datasets: [
        {
            borderRadius: 4,
            label: 'This Month',
            data: [4, 2, 6],
            backgroundColor: 'rgba(18, 104, 251, 1)',
        },
        {
            borderRadius: 4,
            label: 'Last Month',
            data: [3, 4, 9],
            backgroundColor: 'rgba(225, 235, 250, 1)',
        },
    ],
};

const options3 = {
    indexAxis: 'y',
    maintainAspectRatio: false,
    scales: {
        x: {
            suggestedMax: 5,
        },
    },
    plugins: {
        tooltip: {
            enabled: true
        },
        circleArrow: false
    },
};

export const metricsDataIndexMap = {
    'totalRenewalAmount': 'renewedWithActiveProductsAllRenewed',
    'totalCrossSellAmount': 'crosssoldWithActiveProductsAllCrosssold',
    'totalUpSellAmount': 'upsoldWithActiveProductsAllUpsold',

    'totalRenewalAmountOld': 'renewedOldWithActiveProductsAllRenewed',
    'totalCrossSellAmountOld': 'crosssoldOldWithActiveProductsAllCrosssold',
    'totalUpSellAmountOld': 'upsoldOldWithActiveProductsAllUpsold',

    'totalOpenPipelineRenewalAmount': 'renewedOpenedAll',
    'totalOpenPipelineCrossSellAmount': 'crosssoldOpenedAll',
    'totalOpenPipelineUpSellAmount': 'upsoldOpenedAll',

    'totalOpenPipelineRenewalAmountOld': 'renewedOldOpenedAllRenewedOpened',
    'totalOpenPipelineCrossSellAmountOld': 'crosssoldOldOpenedAllCrosssoldOpened',
    'totalOpenPipelineUpSellAmountOld': 'upsoldOldOpenedAllUpsoldOpened',

    'totalPipelineOpenedRenewalAmount': 'renewedStillOpenedAllRenewedOpened',
    'totalPipelineOpenedCrossSellAmount': 'crosssoldStillOpenedAllcrosssoldOpened',
    'totalPipelineOpenedUpSellAmount': 'upsoldStillOpenedAllUpsoldOpened',

    'totalPipelineOpenedRenewalAmountOld': 'renewedOldStillOpenedAllRenewedOpened',
    'totalPipelineOpenedCrossSellAmountOld': 'crosssoldOldStillOpenedAllcrosssoldOpened',
    'totalPipelineOpenedUpSellAmountOld': 'upsoldOldStillOpenedAllUpsoldOpened'
};


const accountInfo = `${translateCRMLabel('Account')} Info`;
const accountInfoTabs = ['churned', 'up for renewal', `renewed ${translateCRMLabel('accounts')}`];
const accountInfoTabDataMap = {
    'churned': ['churnedAccounts'],
    'up for renewal': ['openedForRenewalAccounts', 'accountsWithoutOpps'],
    [`renewed ${translateCRMLabel('accounts')}`]: ['renewedAccounts']
};

const accountInfoDrillDownOpps = {
    'churnedAccounts': ['renewedFailed', 'expired'],
    'openedForRenewalAccounts': ['renewedStillOpenedAllRenewedOpened'],
    'renewedAccounts': ['renewedOpportunities'],
};

// const opportunityStatTypeTabs = ['opportunitiesOpened', 'opportunitiesClosed', 'opportunityWin']
const opportunityStatTypeTabs = ['opportunitiesOpened', 'opportunitiesClosed', 'opportunityWin']
const opportunityStatTypeTabDataTypes = {
    'opportunitiesOpened': ['crosssoldOpenedAll', 'upsoldOpenedAll', 'renewedOpenedAll'],
    'opportunitiesClosed': ['crossoldOpportunities', 'upsoldOpportunities', 'renewedOpportunities'],
    'opportunityWin': ['crossoldOpportunities', 'upsoldOpportunities', 'renewedOpportunities']
}

const accountInfoHeaders = ['name', 'close date', 'license expiry date', 'license status', 'license value'];
const renewal = 'up for renewal';
const accountInfoHeadersRenewal = ['name', 'renewal date', 'license status', 'license value'];
const accountInfoSubHeaders = ['name', 'close date', 'end date', 'amount'];
const accountInfoSubHeadersRenewal = ['name', 'renewal date', 'amount'];

const opportunityHeaders = [translateCRMLabel('Opportunity'), translateCRMLabel('Account'), 'Stage', 'Close Date', 'Amount', 'Owner'];

const modalData = {
    headers: [],
    data: {},
    customStyles: { 0: { color: 'var(--text-color-main)' } },
    extHeaders: [],
    extData: {}
}

const dateGreater = (dt1, dt2) => {
    if (!dt1) {
        return false;
    }
    if (!dt2) {
        return true;
    }

    return new Date(dt1).getTime() > new Date(dt2).getTime();
}


export const Metrics = ({ user }) => {
    getUserPermissions()
    const [metricsData, setMetricsData] = useState({});
    const [metricsDataSummary, setMetricsDataSummary] = useState({});
    const [selectedPeriod, setSelectedPeriod] = useState({
        start: moment().startOf('month').valueOf(),
        end: moment().endOf('month').valueOf(),
        label: 'This Month'
    });
    const [requestSent, setRequestSent] = useState(false);
    const [usersInTheWorkspace, setUsersInTheWorkspace] = useState([]);
    const [teams, setTeams] = useState([])
    const [selectedUser, setSelectedUser] = useState(null);
    const [modalState, setModalState] = useState(false);
    const [modalStateType, setModalStateType] = useState("account info");
    const [modalStateTypeOverride, setModalStateTypeOverride] = useState(null);
    const [accountInfoTab, setAccountInfoTab] = useState(accountInfoTabs[0]);
    const [modalStateData, setModalStateData] = useState({ ...modalData });
    const [alertsCreated, setAlertsCreated] = useState(0);
    const [alertsCompleted, setAlertsCompleted] = useState(0);
    const [alertsCompletedOnTime, setAlertsCompletedOnTime] = useState(0);
    const [alertsOverdue, setAlertsOverdue] = useState(0);
    const [alertsCompletedOverdue, setAlertsCompletedOverdue] = useState(0);
    const [alertsPushedBack, setAlertsPushedBack] = useState(0);
    const [alertsReassigned, setAlertsReassigned] = useState(0);
    const [opportunityTypeTabs, setOpportunityTypeTabs] = useState([]);

    const [pipelines, setPipelines] = useState([])
    const [selectedPipeline, setSelectedPipeline] = useState(null)


    const changeTab = (e) => {
        setAccountInfoTab(e.target.innerHTML);
        if (opportunityStatTypeTabs.includes(modalStateTypeOverride) || opportunityStatTypeTabs.includes(modalStateType)) {
            setAccountInfoTab(e.target.innerHTML);
        } else {
            toModalStateDataAccount(e.target.innerHTML);
        }
    }

    const getLabelBasedOnPeriod = () => {
        if (selectedPeriod.label.includes('This')) {
            return selectedPeriod.label.replace('This', 'Last')
        }
        if (selectedPeriod.label.includes('Last')) {
            return `Two ${selectedPeriod.label.replace('Last', '')} ago`
        }
        if (selectedPeriod.label.includes('Next')) {
            return selectedPeriod.label.replace('Next', 'This')
        }
        if (selectedPeriod.label.includes('Custom')) {
            return 'Previous Custom Period'
        }
        return selectedPeriod.label
    }

    const getUsers = () => {
        makeRequest.get(`${URLS.main}/workspace/users`)
            .then(res => {
                if (res && res.data && res.data.success) {
                    setUsersInTheWorkspace(res.data.users)
                }
            })
    }

    const getTeams = () => {
        makeRequest.get(`${URLS.main}/teams`)
            .then(res => {
                if (res && res.data) {
                    setTeams(res.data.teams)
                }
            })
    }

    useEffect(() => {
        if (opportunityStatTypeTabs.includes(modalStateTypeOverride) || opportunityStatTypeTabs.includes(modalStateType)) {
            toModalStateDataOppStats(opportunityStatTypeTabDataTypes[modalStateType]);
        }
    }, [accountInfoTab]);

    useEffect(() => {
        if (workspaceTypeCheck(['HUBSPOT'])) {
            getPipelines()
        }
        getUsers()
        getTeams()
    }, [])


    const processMetricsDataSummary = (data) => {
        const summary = {};

        summary.totalNumberOfAccounts = data.accounts ? Object.keys(data.accounts).length : 0;

        if (data.accountStats && Object.keys(data.accountStats).length) {
            Object.keys(data.accountStats).forEach((ind) => {
                summary[ind] = data.accountStats[ind].length;
            });
        }

        if (data.opportunityStats && Object.keys(data.opportunityStats).length) {
            Object.keys(data.opportunityStats).forEach((ind) => {
                summary[ind] = data.opportunityStats[ind].length;
            });

            Object.keys(metricsDataIndexMap).forEach((el) => {
                summary[el] = data.opportunityStats[metricsDataIndexMap[el]].map((ind) => data.opportunities[ind].Amount).reduce((a, b) => a + b, 0);
            });
        }

        setMetricsDataSummary(summary);
    }

    const retrieveMetricsData = () => {
        setRequestSent(true)
        makeRequest.get(`${URLS.main}/metrics/${selectedPeriod.start}/${selectedPeriod.end}/${selectedUser}/false`, {
            headers: {
                pipeline: selectedPipeline
            }
        })
            .then(res => {
                if (res && res.status === 200) {
                    setMetricsData(res.data)
                    processMetricsDataSummary(res.data)
                    setRequestSent(false)
                }
            })
    }

    const getPipelines = () => {
        makeRequest.get(`${URLS.main}/pipelines`)
            .then(res => {
                setPipelines(res.data.map(el => ({
                    label: el.label,
                    value: el.id,
                })))
            })
    }

    const alertStats = () => {
        makeRequest.get(`${URLS.main}/dataFetcher/variable/cal/tasks/created/${selectedPeriod.start}/${selectedPeriod.end}/count`)
            .then(res => {
                if (res.status === 200) {
                    setAlertsCreated(res.data);
                }
            });
        makeRequest.get(`${URLS.main}/dataFetcher/variable/cal/tasks/completed/${selectedPeriod.start}/${selectedPeriod.end}/count`)
            .then(res => {
                if (res.status === 200) {
                    setAlertsCompleted(res.data);
                }
            });
        makeRequest.get(`${URLS.main}/dataFetcher/variable/cal/tasks/completed_on_time/${selectedPeriod.start}/${selectedPeriod.end}/count`)
            .then(res => {
                if (res.status === 200) {
                    setAlertsCompletedOnTime(res.data);
                }
            });
        makeRequest.get(`${URLS.main}/dataFetcher/variable/cal/tasks/overdue/${selectedPeriod.start}/${selectedPeriod.end}/count`)
            .then(res => {
                if (res.status === 200) {
                    setAlertsOverdue(res.data);
                }
            });
        makeRequest.get(`${URLS.main}/dataFetcher/variable/cal/tasks/pushed_back/${selectedPeriod.start}/${selectedPeriod.end}/count`)
            .then(res => {
                if (res.status === 200) {
                    setAlertsPushedBack(res.data);
                }
            });
        makeRequest.get(`${URLS.main}/dataFetcher/variable/cal/tasks/reassigned/${selectedPeriod.start}/${selectedPeriod.end}/count`)
            .then(res => {
                if (res.status === 200) {
                    setAlertsReassigned(res.data);
                }
            });
        makeRequest.get(`${URLS.main}/dataFetcher/variable/cal/tasks/completed_while_overdue/${selectedPeriod.start}/${selectedPeriod.end}/count`)
            .then(res => {
                if (res.status === 200) {
                    setAlertsCompletedOverdue(res.data);
                }
            });
    }

    useEffect(() => {
        retrieveMetricsData();
        setModalStateData({ ...modalData });
        alertStats();
    }, [selectedPeriod, selectedUser, selectedPipeline])

    // removed due to pentester exploits
    // const goToDetailedView = () => {
    //     history.push('/metrics-detailed')
    // }


    const toModalStateDataAccount = (dataType=accountInfoTabs[0]) => {
        const localState = { ...modalData };
        const curData = {};
        const curSubData = {};

        let oppStatsList = [];
        const header = dataType === renewal ? accountInfoHeadersRenewal : accountInfoHeaders;
        const extHeader = dataType === renewal ? accountInfoSubHeadersRenewal : accountInfoSubHeaders;
        accountInfoTabDataMap[dataType].forEach((accountType) => {
            metricsData.accountStats[accountType].forEach(accountId => {
                if (metricsData.accounts[accountId]) {
                    curData[accountId] = {};
                    curData[accountId][header[0]]= metricsData.accounts[accountId].Name;
                    curData[accountId][header[1]]= '';
                    curData[accountId][header[2]]= '';
                    curData[accountId][header[3]]= 'None';
                    curData[accountId][header[4]]= formatLargeSums('$', 0);
                }
            });
            if (accountInfoDrillDownOpps[accountType]) {
                oppStatsList = [...new Set([...oppStatsList, ...accountInfoDrillDownOpps[accountType]])];
            }
        });

        oppStatsList.forEach(stat => {
            metricsData.opportunityStats[stat].forEach(oppId => {
                if (
                    metricsData.opportunities[oppId]
                    && metricsData.opportunities[oppId].AccountId
                    && curData[metricsData.opportunities[oppId].AccountId]
                ) {
                    if (dateGreater(dateFormat(metricsData.opportunities[oppId].endDate), curData[metricsData.opportunities[oppId].AccountId]['license expiry date'])) {
                        if (dataType === renewal) {
                            curData[metricsData.opportunities[oppId].AccountId][header[1]] = dateFormat(metricsData.opportunities[oppId].CloseDate);
                            curData[metricsData.opportunities[oppId].AccountId][header[2]] = metricsData.opportunities[oppId].StageName;
                            curData[metricsData.opportunities[oppId].AccountId][header[3]] = metricsData.opportunities[oppId].Amount !== null ? formatLargeSums('$', metricsData.opportunities[oppId].Amount) : formatLargeSums('$', 0);
                        } else {
                            curData[metricsData.opportunities[oppId].AccountId][header[1]] = dateFormat(metricsData.opportunities[oppId].CloseDate);
                            curData[metricsData.opportunities[oppId].AccountId][header[2]] = dateFormat(metricsData.opportunities[oppId].endDate);
                            curData[metricsData.opportunities[oppId].AccountId][header[3]] = metricsData.opportunities[oppId].StageName;
                            curData[metricsData.opportunities[oppId].AccountId][header[4]] = metricsData.opportunities[oppId].Amount !== null ? formatLargeSums('$', metricsData.opportunities[oppId].Amount) : formatLargeSums('$', 0);
                        }
                    }

                    if (!curSubData[metricsData.opportunities[oppId].AccountId]) {
                        curSubData[metricsData.opportunities[oppId].AccountId] = [];
                    }

                    const tempSub= {};
                    if (dataType === renewal) {
                        tempSub.sfUrlPath = metricsData.opportunities[oppId].attributes && metricsData.opportunities[oppId].attributes.url ? metricsData.opportunities[oppId].attributes.url : null;
                        tempSub[extHeader[0]] = metricsData.opportunities[oppId].Name;
                        tempSub[extHeader[1]] = dateFormat(metricsData.opportunities[oppId].CloseDate);
                        tempSub[extHeader[2]] = metricsData.opportunities[oppId].Amount !== null ? formatLargeSums('$', metricsData.opportunities[oppId].Amount) : formatLargeSums('$', 0);
                    } else {
                        tempSub.sfUrlPath = metricsData.opportunities[oppId].attributes && metricsData.opportunities[oppId].attributes.url ? metricsData.opportunities[oppId].attributes.url : null;
                        tempSub[extHeader[0]] = metricsData.opportunities[oppId].Name;
                        tempSub[extHeader[1]] = dateFormat(metricsData.opportunities[oppId].CloseDate);
                        tempSub[extHeader[2]] = dateFormat(metricsData.opportunities[oppId].endDate);
                        tempSub[extHeader[3]] = metricsData.opportunities[oppId].Amount !== null ? formatLargeSums('$', metricsData.opportunities[oppId].Amount) : formatLargeSums('$', 0);
                    }
                    curSubData[metricsData.opportunities[oppId].AccountId].push(tempSub);
                }
            });
        });
        localState.headers = header.slice(0);
        localState.extHeaders = extHeader.slice(0);
        localState.data = curData;
        localState.extData = curSubData;
        setModalStateData({ ...localState });
    };


    const toModalStateDataOpp = (dataType=[]) => {
        let subHeadersFilled = false;
        const localState = { ...modalData };
        localState.headers = [...opportunityHeaders];
        localState.data = {};
        localState.extData = {};
        localState.extHeaders = [];
        dataType.forEach(arr => {
            metricsData.opportunityStats[arr].forEach(id => {
                localState.data[id] = {};
                localState.data[id][opportunityHeaders[0]] = metricsData.opportunities[id].Name;
                localState.data[id][opportunityHeaders[1]] = metricsData.opportunities[id].Account ? metricsData.opportunities[id].Account.Name : null;
                localState.data[id][opportunityHeaders[2]] = metricsData.opportunities[id].StageName;
                localState.data[id][opportunityHeaders[3]] = dateFormat(metricsData.opportunities[id].CloseDate);
                localState.data[id][opportunityHeaders[4]] = metricsData.opportunities[id].Amount ? formatLargeSums('$', metricsData.opportunities[id].Amount) : formatLargeSums('$', 0);
                localState.data[id][opportunityHeaders[5]] = metricsData.opportunities[id].Owner ? metricsData.opportunities[id].Owner.Name : null;
                localState.data[id].sfUrlPath = metricsData.opportunities[id].attributes && metricsData.opportunities[id].attributes.url ? metricsData.opportunities[id].attributes.url : null;
                localState.extData[id] = [{}];
                metricsData.opportunityCustomFields.forEach(colRow => {
                    localState.extData[id][0][colRow.label] = metricsData.opportunities[id][colRow.value];
                    if (!subHeadersFilled) {
                        localState.extHeaders.push(colRow.label);
                    }
                });
                subHeadersFilled = true;
            });
        });
        if (Object.keys(localState.data).length <= 0) {
            localState.headers = ['no data'];
            localState.data = { 0: { 'no data': 'no data' } };
            localState.extHeaders = ['no data'];
            localState.extData = { 0: [{ 'no data': 'no data' }] };
        }
        setModalStateData({ ...localState });
    };

    const toModalStateDataOppStats = (dataType=[]) => {
        const tmpOppStatTabs = [];
        let subHeadersFilled = false;
        const localState = { ...modalData };
        localState.headers = [...opportunityHeaders];
        localState.data = {};
        localState.extData = {};
        localState.extHeaders = [];


        dataType.forEach(arr => {
            metricsData?.opportunityStats[arr]?.forEach(id => {
                const rowType = (metricsData?.opportunities
                    && Object.prototype.hasOwnProperty.call(metricsData.opportunities, id)
                    && metricsData?.opportunities[id]?.Type)
                    ? metricsData.opportunities[id].Type : 'None';
                if (!tmpOppStatTabs.includes(rowType)) {
                    tmpOppStatTabs.push(rowType);
                }
                if (accountInfoTab === rowType) {
                    localState.data[id] = {};
                    localState.data[id][opportunityHeaders[0]] = metricsData.opportunities[id].Name;
                    localState.data[id][opportunityHeaders[1]] = metricsData.opportunities[id].Account ? metricsData.opportunities[id].Account.Name : null;
                    localState.data[id][opportunityHeaders[2]] = metricsData.opportunities[id].StageName;
                    localState.data[id][opportunityHeaders[3]] = dateFormat(metricsData.opportunities[id].CloseDate);
                    localState.data[id][opportunityHeaders[4]] = metricsData.opportunities[id].Amount ? formatLargeSums('$', metricsData.opportunities[id].Amount) : formatLargeSums('$', 0);
                    localState.data[id][opportunityHeaders[5]] = metricsData.opportunities[id].Owner ? metricsData.opportunities[id].Owner.Name : null;
                    localState.data[id].sfUrlPath = metricsData.opportunities[id].attributes && metricsData.opportunities[id].attributes.url ? metricsData.opportunities[id].attributes.url : null;
                    localState.extData[id] = [{}];
                    metricsData.opportunityCustomFields.forEach(colRow => {
                        localState.extData[id][0][colRow.label] = metricsData.opportunities[id][colRow.value];
                        if (!subHeadersFilled) {
                            localState.extHeaders.push(colRow.label);
                        }
                    });
                    subHeadersFilled = true;
                }
            })
        });
        setOpportunityTypeTabs([...tmpOppStatTabs]);
        if (tmpOppStatTabs.length >= 1 && !tmpOppStatTabs.includes(accountInfoTab)) {
            setAccountInfoTab(tmpOppStatTabs[0]);
        }
        if (Object.keys(localState.data).length <= 0) {
            localState.headers = ['no data'];
            localState.data = { 0: { 'no data': 'no data' } };
            localState.extHeaders = ['no data'];
            localState.extData = { 0: [{ 'no data': 'no data' }] };
        }
        setModalStateData({ ...localState });
    }

    const drillDown = (type, dataType=[], typeOverride=null) => {
        if (Object.keys(metricsData).length >= 1) {
            setModalState(true);
            setModalStateType(type);

            if (type === accountInfo) {
                setAccountInfoTab(dataType)
                toModalStateDataAccount(dataType);
            } else if (opportunityStatTypeTabs.includes(typeOverride) || opportunityStatTypeTabs.includes(type)) {
                setModalStateTypeOverride(typeOverride);
                toModalStateDataOppStats(opportunityStatTypeTabDataTypes[type]);
            } else {
                toModalStateDataOpp(dataType);
            }
        }
    }

    return (
        <div>
            <Row className={`${cn.selectableConfig} ${cn.selectableConfigSticky}`}>
                <div>
                    <Label>
                        Level
                    </Label>
                    <Dropdown
                        value={selectedUser}
                        onChange={e => setSelectedUser(e.target.value)}
                        values={[
                            {
                                value: 'null',
                                label: 'Company Wide'
                            },
                            ...teams.map(team => ({
                                value: team.users.map(el => getUserCrmId(el)).filter(el => !!el).join(','),
                                label: team.name,
                            })).filter(el => !!el?.value),
                            ...usersInTheWorkspace.filter(el => !!el.sfUserId).map(el => ({
                                value: getUserCrmId(el),
                                label: el.fullName
                            })).filter(el => !!el)
                        ]}
                    />
                </div>
                <div>
                    <Label>
                        Period Of Time
                    </Label>
                    <ComplexDropdown
                        value={selectedPeriod}
                        onChange={setSelectedPeriod}
                    />
                </div>
                <WorkspaceTypeCheck requiredType={'HUBSPOT'}>
                    <div>
                        <Label>Pipeline</Label>
                        <Dropdown
                            fullWidth
                            className={cn.topNavigationDropdown}
                            value={selectedPipeline}
                            onChange={e => setSelectedPipeline(e.target.value)}
                            values={[{ label: 'All', value: null }, ...pipelines]}
                        />
                    </div>
                </WorkspaceTypeCheck>
            </Row>
            {requestSent
                ? <Loader size={LOADER_SIZES.EXTRA_BIG} type={LOADER_TYPES.SPINNER} className={cn.loader} />
                : (
                    <>
                        <Row noAligning wrap>
                            <div className={cn.smaller}>
                                <ViewContainer>
                                    <Heading noOffset>{translateCRMLabel('Accounts')} Info</Heading>
                                    <div className={`${cn.doughnutWrapper} ${ddcn.drillDownPointer}`} onClick={() => { drillDown(accountInfo, 'churned'); }}>
                                        <Doughnut
                                            data={{
                                                ...doughnutData,
                                                datasets: [
                                                    {
                                                        ...doughnutData.datasets[0],
                                                        data: [metricsDataSummary.churnedAccounts, metricsDataSummary.renewedAccounts || 1],
                                                    },
                                                ],
                                            }}
                                            options={doughnutOptions}
                                        />
                                        <div className={cn.accountInfo}>
                                            <div className={cn.numberOfAccounts}>
                                                {metricsDataSummary.totalNumberOfAccounts}
                                            </div>
                                            <div className={cn.numberOfAccountsLabel}>
                                                Total Number of
                                                <br />
                                                {translateCRMLabel('Accounts')}
                                            </div>
                                        </div>
                                    </div>
                                    <div className={`${cn.accountsChurnedSummary} ${ddcn.drillDownPointer}`}>
                                        <div onClick={() => { drillDown(accountInfo, 'churned'); }}>
                                            <Row>
                                                <div className={cn.numberOfAccountsChurnedLabel}>
                                                    Number of {translateCRMLabel('Accounts')} churned
                                                    {' '}
                                                    {selectedPeriod.label}
                                                </div>
                                                <div className={cn.numberOfAccountsChurnedStat}>
                                                    {metricsDataSummary.churnedAccounts}
                                                </div>
                                            </Row>
                                            <StatsSummaryRow
                                                value={metricsDataSummary.churnedAccounts}
                                                oldValue={metricsDataSummary.churnedAccountsOld}
                                                inverted
                                                oldPeriodLabel={getLabelBasedOnPeriod()}
                                            />
                                        </div>
                                        <div onClick={() => { drillDown(accountInfo, 'up for renewal'); }}>
                                            <Row>
                                                <div className={cn.numberOfAccountsChurnedLabel}>
                                                    Number of {translateCRMLabel('Accounts')} Up for Renewal
                                                    {' '}
                                                    {selectedPeriod.label}
                                                </div>
                                                <div className={cn.numberOfAccountsChurnedStat}>
                                                    {metricsDataSummary.openedForRenewalAccounts + metricsDataSummary.accountsWithoutOpps}
                                                </div>
                                            </Row>
                                            <StatsSummaryRow
                                                value={metricsDataSummary.openedForRenewalAccounts + metricsDataSummary.accountsWithoutOpps}
                                                oldValue={metricsDataSummary.openedForRenewalAccountsOld + metricsDataSummary.accountsWithoutOppsOld}
                                                oldPeriodLabel={getLabelBasedOnPeriod()}
                                            />
                                        </div>
                                        <div onClick={() => { drillDown(accountInfo, `renewed ${translateCRMLabel('accounts')}`); }}>
                                            <Row>
                                                <div className={cn.numberOfAccountsChurnedLabel}>
                                                    Number of {translateCRMLabel('Accounts')} Renewed
                                                    {' '}
                                                    {selectedPeriod.label}
                                                </div>
                                                <div className={cn.numberOfAccountsChurnedStat}>
                                                    {metricsDataSummary.renewedAccounts}
                                                </div>
                                            </Row>
                                            <StatsSummaryRow
                                                value={metricsDataSummary.renewedAccounts}
                                                oldValue={metricsDataSummary.renewedAccountsOld}
                                                oldPeriodLabel={getLabelBasedOnPeriod()}
                                            />
                                        </div>
                                    </div>
                                </ViewContainer>
                            </div>
                            <div className={cn.bigger}>
                                <ViewContainer>
                                    <Heading noOffset>Money Statistics</Heading>
                                    <div className={`${cn.moneyStatisticsContainer} ${cn.firstRow}`}>
                                        <MoneyStatistic
                                            value={metricsDataSummary.totalRenewalAmount + metricsDataSummary.totalCrossSellAmount + metricsDataSummary.totalUpSellAmount}
                                            oldValue={metricsDataSummary.totalRenewalAmountOld + metricsDataSummary.totalCrossSellAmountOld + metricsDataSummary.totalUpSellAmountOld}
                                            label='Total book of business'
                                            oldPeriodLabel={getLabelBasedOnPeriod()}
                                            onClick={() => { drillDown('Total book of business', [metricsDataIndexMap.totalRenewalAmount, metricsDataIndexMap.totalCrossSellAmount, metricsDataIndexMap.totalUpSellAmount]) }}
                                        />
                                        <MoneyStatistic
                                            value={metricsDataSummary.totalRenewalAmount}
                                            oldValue={metricsDataSummary.totalRenewalAmountOld}
                                            label='Total value (won) of renewals'
                                            oldPeriodLabel={getLabelBasedOnPeriod()}
                                            onClick={() => { drillDown('Total value (won) of renewals', [metricsDataIndexMap.totalRenewalAmount]) }}
                                        />
                                        <MoneyStatistic
                                            value={metricsDataSummary.totalCrossSellAmount}
                                            oldValue={metricsDataSummary.totalCrossSellAmountOld}
                                            label='Total value (won) of cross sell'
                                            oldPeriodLabel={getLabelBasedOnPeriod()}
                                            onClick={() => { drillDown('Total value (won) of cross sell', [metricsDataIndexMap.totalCrossSellAmount]) }}
                                        />
                                        <MoneyStatistic
                                            value={metricsDataSummary.totalUpSellAmount}
                                            oldValue={metricsDataSummary.totalUpSellAmountOld}
                                            label='Total value (won) of up sell'
                                            oldPeriodLabel={getLabelBasedOnPeriod()}
                                            onClick={() => { drillDown('Total value (won) of up sells', [metricsDataIndexMap.totalUpSellAmount]) }}
                                        />
                                    </div>
                                    <div className={`${cn.moneyStatisticsContainer} ${cn.firstRow}`}>
                                        <MoneyStatistic
                                            value={metricsDataSummary.totalOpenPipelineRenewalAmount + metricsDataSummary.totalOpenPipelineCrossSellAmount + metricsDataSummary.totalOpenPipelineUpSellAmount}
                                            oldValue={metricsDataSummary.totalOpenPipelineRenewalAmountOld + metricsDataSummary.totalOpenPipelineCrossSellAmountOld + metricsDataSummary.totalOpenPipelineUpSellAmountOld}
                                            label='Total pipeline created value'
                                            oldPeriodLabel={getLabelBasedOnPeriod()}
                                            onClick={() => { drillDown('Total pipeline created value', [metricsDataIndexMap.totalOpenPipelineRenewalAmount, metricsDataIndexMap.totalOpenPipelineCrossSellAmount, metricsDataIndexMap.totalOpenPipelineUpSellAmount]) }}
                                        />
                                        <MoneyStatistic
                                            value={metricsDataSummary.totalOpenPipelineRenewalAmount}
                                            oldValue={metricsDataSummary.totalOpenPipelineRenewalAmountOld}
                                            label='pipeline created value of renewals'
                                            oldPeriodLabel={getLabelBasedOnPeriod()}
                                            onClick={() => { drillDown(' pipeline created value of renewals', [metricsDataIndexMap.totalOpenPipelineRenewalAmount]) }}
                                        />
                                        <MoneyStatistic
                                            value={metricsDataSummary.totalOpenPipelineCrossSellAmount}
                                            oldValue={metricsDataSummary.totalOpenPipelineCrossSellAmountOld}
                                            label='pipeline created value of cross sells'
                                            oldPeriodLabel={getLabelBasedOnPeriod()}
                                            onClick={() => { drillDown('pipeline created value of cross sell', [metricsDataIndexMap.totalOpenPipelineCrossSellAmount]) }}
                                        />
                                        <MoneyStatistic
                                            value={metricsDataSummary.totalOpenPipelineUpSellAmount}
                                            oldValue={metricsDataSummary.totalOpenPipelineUpSellAmountOld}
                                            label='pipeline created value of up sell'
                                            oldPeriodLabel={getLabelBasedOnPeriod()}
                                            onClick={() => { drillDown('pipeline created value of up sell', [metricsDataIndexMap.totalOpenPipelineUpSellAmount]) }}
                                        />
                                    </div>
                                    <div className={`${cn.moneyStatisticsContainer} ${cn.firstRow}`}>
                                        <MoneyStatistic
                                            value={metricsDataSummary.totalPipelineOpenedRenewalAmount + metricsDataSummary.totalPipelineOpenedCrossSellAmount + metricsDataSummary.totalPipelineOpenedUpSellAmount}
                                            oldValue={metricsDataSummary.totalPipelineOpenedRenewalAmountOld + metricsDataSummary.totalPipelineOpenedCrossSellAmountOld + metricsDataSummary.totalPipelineOpenedUpSellAmountOld}
                                            label='Total value of open opportunities'
                                            oldPeriodLabel={getLabelBasedOnPeriod()}
                                            onClick={() => { drillDown('Total value of open opportunities', [metricsDataIndexMap.totalPipelineOpenedRenewalAmount, metricsDataIndexMap.totalPipelineOpenedCrossSellAmount, metricsDataIndexMap.totalPipelineOpenedUpSellAmount]) }}
                                        />
                                        <MoneyStatistic
                                            value={metricsDataSummary.totalPipelineOpenedRenewalAmount}
                                            oldValue={metricsDataSummary.totalPipelineOpenedRenewalAmountOld}
                                            label='Value of open renewal opportunities'
                                            oldPeriodLabel={getLabelBasedOnPeriod()}
                                            onClick={() => { drillDown('Value of open renewal opportunities', [metricsDataIndexMap.totalPipelineOpenedRenewalAmount]) }}
                                        />
                                        <MoneyStatistic
                                            value={metricsDataSummary.totalPipelineOpenedCrossSellAmount}
                                            oldValue={metricsDataSummary.totalPipelineOpenedCrossSellAmountOld}
                                            label='Value of open cross sell opportunities'
                                            oldPeriodLabel={getLabelBasedOnPeriod()}
                                            onClick={() => { drillDown('Value of open cross sell opportunities', [metricsDataIndexMap.totalPipelineOpenedCrossSellAmount]) }}
                                        />
                                        <MoneyStatistic
                                            value={metricsDataSummary.totalPipelineOpenedUpSellAmount}
                                            oldValue={metricsDataSummary.totalPipelineOpenedUpSellAmountOld}
                                            label='Value of open up sell opportunities'
                                            oldPeriodLabel={getLabelBasedOnPeriod()}
                                            onClick={() => { drillDown('Value of open up sell opportunities', [metricsDataIndexMap.totalPipelineOpenedUpSellAmount]) }}
                                        />
                                    </div>
                                </ViewContainer>
                            </div>
                        </Row>
                        <ViewContainer>
                            <Heading>
                                Opportunities
                            </Heading>
                            <Row noAligning className={cn.oppsDataColWrapper} wrap>
                                <div className={`${cn.oppsDataCol} ${ddcn.drillDownPointer}`} onClick={() => { drillDown(`opportunitiesOpened`, ['crosssoldOpenedAll', 'upsoldOpenedAll', 'renewedOpenedAll'], `Number of opportunities opened ${selectedPeriod.label}`); }}>
                                    <div className={cn.oppsLabel}>
                                        Number of opportunities opened
                                        {' '}
                                        {selectedPeriod.label}
                                    </div>
                                    <div className={cn.oppsRateGraphWrapper}>
                                        <Bar
                                            data={{
                                                ...data3,
                                                datasets: [
                                                    {
                                                        ...data3.datasets[0],
                                                        label: selectedPeriod.label,
                                                        data: [metricsDataSummary.crosssoldOpenedAll, metricsDataSummary.upsoldOpenedAll, metricsDataSummary.renewedOpenedAll],
                                                    },
                                                    {
                                                        ...data3.datasets[1],
                                                        label: getLabelBasedOnPeriod(),
                                                        data: [metricsDataSummary.crosssoldOldOpenedAll, metricsDataSummary.upsoldOldOpenedAll, metricsDataSummary.renewedOldOpenedAll],
                                                    },
                                                ],
                                            }}
                                            options={options3}
                                        />
                                    </div>
                                </div>
                                <div className={`${cn.oppsDataCol} ${ddcn.drillDownPointer}`} onClick={() => { drillDown('Close Rate', ['crossoldOpportunities', 'upsoldOpportunities', 'renewedOpportunities'], 'opportunitiesClosed'); }}>
                                    <div className={cn.oppsLabel}>
                                        Close Rate
                                    </div>
                                    <div className={cn.winRateGraphWrapper}>
                                        <Bar
                                            data={{
                                                ...data2,
                                                datasets: [
                                                    {
                                                        ...data2.datasets[0],
                                                        data: [
                                                            metricsDataSummary.crossoldOpportunities ? metricsDataSummary.crossoldOpportunities / metricsDataSummary.crosssoldOpenedAll * 100 : 0,
                                                            metricsDataSummary.upsoldOpportunities ? metricsDataSummary.upsoldOpportunities / metricsDataSummary.upsoldOpenedAll * 100 : 0,
                                                            metricsDataSummary.renewedOpportunities ? metricsDataSummary.renewedOpportunities / metricsDataSummary.renewedOpenedAll * 100 : 0,
                                                        ],
                                                    },
                                                    {
                                                        ...data2.datasets[1]
                                                    }
                                                ],
                                            }}
                                            options={options}
                                        />
                                    </div>
                                </div>

                                <div className={`${cn.oppsDataCol} ${ddcn.drillDownPointer}`} onClick={() => { drillDown('Win Rate', ['crossoldOpportunities', 'upsoldOpportunities', 'renewedOpportunities'], 'opportunityWin'); }}>
                                    <div className={cn.oppsLabel}>
                                        Win Rate
                                    </div>
                                    <div className={cn.winRateGraphWrapper}>
                                        <Bar
                                            data={{
                                                ...data2,
                                                datasets: [
                                                    {
                                                        ...data2.datasets[0],
                                                        data: [
                                                            metricsDataSummary.crossoldOpportunities ? metricsDataSummary.crossoldOpportunities / (metricsDataSummary.crossoldOpportunities + metricsDataSummary.crossoldFailed) * 100 : 0,
                                                            metricsDataSummary.upsoldOpportunities ? metricsDataSummary.upsoldOpportunities / (metricsDataSummary.upsoldOpportunities + metricsDataSummary.upsoldFailed) * 100 : 0,
                                                            metricsDataSummary.renewedOpportunities ? metricsDataSummary.renewedOpportunities / (metricsDataSummary.renewedOpportunities + metricsDataSummary.renewedFailed) * 100 : 0,
                                                        ],
                                                    },
                                                    {
                                                        ...data2.datasets[1]
                                                    }
                                                ],
                                            }}
                                            options={options}
                                        />
                                    </div>
                                </div>
                            </Row>
                        </ViewContainer>

                        <ViewContainer>
                            <Heading>
                                Plays
                            </Heading>
                            <Row noAligning className={cn.oppsDataColWrapper} wrap>
                                <div className={`${cn.oppsDataCol} ${ddcn.drillDownPointer}`}>
                                    <div className={cn.oppsLabel}>
                                        Plays Stats
                                    </div>
                                    {(Number.isInteger(parseInt(alertsCreated))
                                        || Number.isInteger(parseInt(alertsCompleted))
                                        || Number.isInteger(parseInt(alertsCompletedOnTime))
                                        || Number.isInteger(parseInt(alertsOverdue))
                                        || Number.isInteger(parseInt(alertsCompletedOverdue))
                                        || Number.isInteger(parseInt(alertsPushedBack))
                                        || Number.isInteger(parseInt(alertsReassigned)))
                                    && (
                                        <div className={cn.oppsRateGraphWrapper}>
                                            <Bar
                                                data={{
                                                    labels: ['Created', 'Completed', 'Completed On Time', 'Overdue', 'Completed While Overdue', 'Pushed Back', 'Reassigned'],
                                                    datasets: [
                                                        {
                                                            borderRadius: 4,
                                                            label: 'This Month',
                                                            data: [alertsCreated, alertsCompleted, alertsCompletedOnTime, alertsOverdue, alertsCompletedOverdue, alertsPushedBack, alertsReassigned],
                                                            backgroundColor: [
                                                                'rgba(255, 99, 132, 0.75)',
                                                                'rgba(54, 162, 235, 0.75)',
                                                                'rgba(255, 206, 86, 0.75)',
                                                                'rgba(75, 192, 192, 0.75)',
                                                                'rgba(153, 102, 255, 0.75)',
                                                                'rgba(255, 159, 64, 0.75)',
                                                                'rgba(37, 213, 161, 1)'
                                                            ],
                                                        },
                                                    ],
                                                }}
                                                options={options}
                                            />
                                        </div>
                                    )}
                                </div>
                            </Row>
                        </ViewContainer>
                        {modalState && (
                            <Modal
                                minHeight={true}
                                onClose={() => { setModalState(false); setAccountInfoTab(''); setModalStateType(null); setModalStateTypeOverride(null); }}
                                header={modalStateTypeOverride || modalStateType}
                                size={MODAL_SIZES.BIG}
                            >
                                <div className={ddcn.drillDownModalWrap} >
                                    <div className={ddcn.drillDownModalTabs}>
                                        { modalStateType === accountInfo
                                            && accountInfoTabs.map((tabName) => {
                                                if (accountInfoTab === tabName) {
                                                    return (<div className={ddcn.drillDownModalTabSelect} >{tabName}</div>)
                                                }
                                                return (<div className={ddcn.drillDownModalTabNorm} onClick={changeTab} >{tabName}</div>)
                                            }) }
                                        { (opportunityStatTypeTabs.includes(modalStateTypeOverride) || opportunityStatTypeTabs.includes(modalStateType))
                                            && opportunityTypeTabs.map(tabName => {
                                                if (accountInfoTab === tabName || (accountInfoTab === 'None' && tabName === '')) {
                                                    return (<div className={ddcn.drillDownModalTabSelect} >{tabName}</div>)
                                                }
                                                return (<div className={ddcn.drillDownModalTabNorm} onClick={changeTab} >{tabName}</div>)
                                            }) }
                                    </div>
                                    <div className={ddcn.drillDownModalMain}>
                                        <div className={ddcn.drillDownModalMainWrap}>
                                            { modalStateData && modalStateData.headers.length > 0 && (
                                                <TableExpand
                                                    headers={modalStateData.headers}
                                                    data={modalStateData.data}
                                                    customStyles={modalStateData.customStyles}
                                                    extHeaders={modalStateData.extHeaders}
                                                    extData={modalStateData.extData}
                                                    tab={accountInfoTab}
                                                    user={user}
                                                    type={modalStateType}
                                                    accountInfo={accountInfo}
                                                />
                                            )}
                                            { modalStateData.headers.length <= 0 && (
                                                <Loader size={LOADER_SIZES.EXTRA_BIG} type={LOADER_TYPES.SPINNER} className={cn.smLoader} />
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </Modal>
                        )}
                    </>
                )}
        </div>
    );
}

export const mapStateToProps = (state, ownProps) => {
    return {
        user: state.user,
        ...ownProps
    }
}

export const MetricsConnected = connect(mapStateToProps)(Metrics)
