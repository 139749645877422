/* eslint-disable */
import React, { useEffect, useRef, useState } from 'react';
import debounce from 'lodash.debounce'
import cn from './Playbook.module.css'
import { ViewContainer } from '../../../components/ViewContainer';
import { BUTTON_TYPES, Button } from '../../../components/Button';
import { Row } from '../../../components/Row';

const ZOOM_SCALE_STEP = 0.01;
const LINE_WIDTH = 2;
const CELL_WIDTH = 150;
const CELL_HEIGHT = 70;
const CELL_TOP_OFFSET = 30;
const CELL_LEFT_OFFSET = 5;

let currentScale = 1
let scaleInversed = 1

let lines = []

let boundingLeftStart = 0
let boundingTopStart = 0

let overlapsDuringPositioning = 0
let lastOverlaps = {}


function makeid(length) {
    let result = '';
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const charactersLength = characters.length;
    let counter = 0;
    while (counter < length) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
        counter += 1;
    }
    return result;
}

const SimpleCell = ({
    x,
    y,
    id,
    onRemoved,
    addOne,
    addTwo,
    isFirst,
    deleteChildren,
    hasChildren,
}) => (
    <div
        id={id}
        className={cn.simpleCell}
        style={{
            top: y,
            left: x,
        }}
        onDoubleClick={() => !isFirst && onRemoved()}
    >
        {!hasChildren && (
            <Row fullWidth spaceBetween>
                <Button
                    type={BUTTON_TYPES.TEXT}
                    onClick={addOne}
                >
                    Add 1
                </Button>
                <Button
                    type={BUTTON_TYPES.TEXT}
                    onClick={addTwo}
                >
                    Add 2
                </Button>
            </Row>
        )}

        <Row fullWidth spaceBetween>
            <Button
                type={BUTTON_TYPES.TEXT_SMALLEST}
                onClick={() => !isFirst && onRemoved()}
            >
                Delete
            </Button>
            {hasChildren && (
                <Button
                    type={BUTTON_TYPES.TEXT_SMALLEST}
                    onClick={deleteChildren}
                >
                    Delete Children
                </Button>
            )}
        </Row>
    </div>
)

const SimpleGrid = () => {
    const [cellToDelete, setCellToDelete] = useState()

    const cellWidth = 150
    const cellHeight = 70

    const gridRef = useRef()
    const canvasRef = useRef()

    const [cells, setCells] = useState([
        {
            x: 0,
            y: 0,
            id: makeid(12),
            isFirst: true
        }
    ])

    useEffect(() => {
        if (!!cellToDelete) {
            deleteChildren(cellToDelete)
            setCellToDelete(null)
        }
    }, [cellToDelete])


    // SETUPS
    useEffect(() => {
        if (gridRef && gridRef.current) {
            resizeCanvas()

            window.addEventListener('resize', () => {
                debouncedResize()
            })

            updateBoundings()
        }
    }, [gridRef])

    useEffect(() => {
        lines = []
        cells.forEach(cell => {
            if (cell.parentId) {
                calculateLine(cells.find(el => el.id === cell.parentId), cell, false, false)
            }
        })
    }, [cells])

    const clearLines = () => {
        // eslint-disable-next-line
        canvasRef.current.width = canvasRef.current.width
    }

    const updateBoundings = () => {
        boundingLeftStart = gridRef.current.getBoundingClientRect().x
        boundingTopStart = gridRef.current.getBoundingClientRect().y
    }


    const convertDomCoordinatesToCanvas = ({ x, y }) => ({
        x: (x - boundingLeftStart),
        y: (y - boundingTopStart),
    })

    const drawLines = (lines) => {
        const context = canvasRef.current.getContext("2d");

        lines.forEach((line, lineIndex) => {
            if (line.skipLine) return

            const { lineData } = line
            context.beginPath();
            // context.lineCap = 'round'
            // context.lineJoin = 'round'

            const visiblePath = new window.Path2D()
            const hitAreaPath = new window.Path2D()

            const paths = [visiblePath, hitAreaPath]
            const radius = (CELL_TOP_OFFSET * currentScale) / 3

            paths.forEach((path, i) => {
                if (i === 0) {
                    context.lineWidth = LINE_WIDTH * currentScale;
                    context.strokeStyle = line.shortRoute ? '#33b679' : '#F08178';
                } else {
                    context.lineWidth = LINE_WIDTH * currentScale * 10;
                    context.strokeStyle = 'rgba(0,0,0,0)'
                }
                path.moveTo(lineData[0].x, lineData[0].y)
                // console.log('line line line', line)
                if (line.startingCellColumn === line.endingCellColumn) {
                    // offsets to make the right angle
                    path.lineTo(lineData[1].x, lineData[1].y)
                    path.lineTo(lineData[2].x, lineData[2].y)

                    // end target
                    path.lineTo(lineData[3].x, lineData[3].y)
                } else if (line.startingCellColumn < line.endingCellColumn) {
                    // offsets to make the right angle
                    path.lineTo(lineData[1].x, (lineData[1].y - radius))
                    path.arc(
                        (lineData[1].x + radius),
                        (lineData[1].y - radius),
                        radius,
                        (Math.PI / 180) * 180,
                        (Math.PI / 180) * 90,
                        true
                    )
                    path.lineTo((lineData[2].x - radius), lineData[2].y)
                    path.arc(
                        (lineData[2].x - radius),
                        (lineData[2].y + radius),
                        radius,
                        (Math.PI / 180) * -90,
                        (Math.PI / 180) * 0,
                        false
                    )

                    // end target
                    path.lineTo(lineData[3].x, lineData[3].y)
                } else {
                    // offsets to make the right angle
                    path.lineTo(lineData[1].x, (lineData[1].y - radius))
                    path.arc(
                        (lineData[1].x - radius),
                        (lineData[1].y - radius),
                        radius,
                        0,
                        (Math.PI / 180) * 90,
                        false
                    )
                    path.lineTo((lineData[2].x + radius), lineData[2].y)
                    path.arc(
                        (lineData[2].x + radius),
                        (lineData[2].y + radius),
                        radius,
                        (Math.PI / 180) * 270,
                        (Math.PI / 180) * 180,
                        true
                    )

                    // end target
                    path.lineTo(lineData[3].x, lineData[3].y)
                }
                context.stroke(path)
                path.closePath()
            })
            lines[lineIndex].path = hitAreaPath
        })
    }

    const getIntermediaryPoints = (start, end) => ([
        {
            x: start.x,
            y: start.y + CELL_HEIGHT * currentScale / 2 + (CELL_TOP_OFFSET * currentScale / 2)
        },
        {
            x: end.x,
            y: start.y + CELL_HEIGHT * currentScale / 2 + (CELL_TOP_OFFSET * currentScale / 2)
        },
    ])

    const getCellCenter = cell => {
        console.log('getCellCenter', cell, document.getElementById(cell.id))
        const { x, y } = convertDomCoordinatesToCanvas({
            x: document.getElementById(cell.id)?.getBoundingClientRect()?.x,
            y: document.getElementById(cell.id)?.getBoundingClientRect()?.y
        })
        return {
            x: x + cellWidth / 2,
            y: y + cellHeight / 2,
            cellId: cell.id,
        }
    }

    const isCellOnTopOfAnother = (a, b) => a?.y < b?.y

    const updateLinesForZoom = () => {
        lines = lines.map(line => {
            return calculateLine(
                cells.find(el => el.id === line.startingCellId),
                cells.find(el => el.id === line.endingCellId),
                true,
                line.shortRoute
            )
        })

        clearLines()
        drawLines(lines)
    }

    const calculateLine = (a, b, simplyReturn, shiftPressed) => {
        console.log('calculateLine', a, b)
        const aPoint = getCellCenter(a)
        const bPoint = getCellCenter(b)
        const isATop = isCellOnTopOfAnother(a, b)

        console.log('calculateLine part 2', aPoint, bPoint)

        if (!a || !b) {
            return
        }

        const startingPoint = isATop ? aPoint : bPoint
        const endingPoint = isATop ? bPoint : aPoint

        const startingCellId = isATop ? a.id : b.id
        const endingCellId = isATop ? b.id : a.id

        const { x: startX, y: startY } = startingPoint
        const { x: endX, y: endY } = endingPoint
        const [intermediaryPointA, intermediaryPointB] = getIntermediaryPoints(startingPoint, endingPoint)

        console.log('startingPoint endingPoint', startingPoint, endingPoint, intermediaryPointB, intermediaryPointA)

        const newLine = {
            startingCellId: startingCellId,
            endingCellId: endingCellId,
            shortRoute: shiftPressed,
            startingCellColumn: isATop ? a.x : b.x,
            endingCellColumn: isATop ? b.x : a.x,
            lineData: [
                { x: startX, y: startY },
                { x: intermediaryPointA.x, y: intermediaryPointA.y },
                { x: intermediaryPointB.x, y: intermediaryPointB.y },
                { x: endX, y: endY },
            ]
        }

        if (simplyReturn) {
            return newLine
        }

        saveLine(newLine)
        clearLines()
        drawLines(lines)
    }

    const saveLine = linePath => {
        lines.push(linePath)
    }

    const deleteCell = id => {
        setCells(cells.filter(el => el.id !== id))
        setCellToDelete(id)
    }

    const findCell = id => {
        console.log('findCell', id, cells)
        return cells.find(el => el.id === id)
    }

    const generateId = () => makeid(12)

    const hasChildren = id => !!cells.find(el => el.parentId === id)

    const getAllChildren = (parentIds, cellsToRemove = []) => {
        const newCellsToRemove = cells.filter(el => parentIds.includes(el.parentId))

        if (!newCellsToRemove?.length) {
            return cellsToRemove
        }
        return getAllChildren(newCellsToRemove.map(el => el.id), [...cellsToRemove, ...newCellsToRemove])
    }

    const deleteChildren = id => {
        const toRemove = getAllChildren(id).map(el => el.id)

        setCells(cells.filter(el => !toRemove.includes(el.id)))
    }

    const addOne = id => {
        const parent = findCell(id)

        if (!parent) return

        const parentsParent = findCell(parent.parentId)

        const y = parent.y + 2 // optionally add more height for a label!

        const updatedCells = [...cells]

        const newId = generateId()

        let newCell;

        if (parentsParent?.x < parent.x) {
            newCell = {
                y,
                parentId: parent.id,
                id: newId,
                right: true,
            }
        } else {
            newCell = {
                y,
                parentId: parent.id,
                id: newId,
                left: true,
            }
        }

        updatedCells.push(newCell)

        overlapsDuringPositioning = 0
        lastOverlaps = {}
        positionCells(updatedCells)

        setTimeout(() => {
            calculateLine(parent, newCell, false, false)
        }, 1000)
    }

    const getNumberOfRows = (cellsData = cells) => cellsData.sort((a, b) => b.y - a.y)[0]?.y

    const getMaximumWidth = (cellsData = cells) => {
        let count = 0
        const rows = getNumberOfRows(cellsData) / 2
        for (let i = 0; i <= rows; i++) {
            count = count * 2 + 1
        }
        return count
    }

    const addTwo = id => {
        const parent = findCell(id)

        if (!parent) return

        const y = parent.y + 2 // optionally add more height for a label!

        const newCells = [
            {
                left: true,
                y,
                parentId: parent.id,
                id: generateId(12)
            },
            {
                right: true,
                y,
                parentId: parent.id,
                id: generateId(12)
            }
        ]

        overlapsDuringPositioning = 0
        lastOverlaps = {}
        positionCells([...cells, ...newCells])
    }

    const overlappingCells = cellsData => {
        return cellsData.filter(cell => cellsData.find(innerCell => innerCell.x === cell.x && innerCell.y === cell.y && innerCell.id !== cell.id))
    }

    const findCommonParent = (targetCells, cells) => {
        if (targetCells.length < 2)
            return null

        console.log('overlappingCells findCommonParent', targetCells.map(el => el.parentId))
        if ([...new Set(targetCells.map(el => el.parentId))].length === 1) {
            return cells.find(el => el.id === targetCells[0].parentId)
        }

        return findCommonParent(targetCells.map(el => cells.find(innerEl => innerEl.id === el.parentId)), cells)
    }

    const positionCells = (updatedCells, spreadedOutMoreAbove) => {
        const maximumWidth = getMaximumWidth(updatedCells)
        const root = cells.find(el => el.isFirst)
        // root.x = Math.ceil(maximumWidth / 2)
        root.x = 0
        const positionedNodes = [
            root
        ]
        console.log('maximum width is ', maximumWidth, root, updatedCells)
        let overlapFound = false
        loop: while (positionedNodes.length < updatedCells.length) {
            const lastRowOfPositionedNodesY = positionedNodes.sort((a, b) => b.y - a.y)[0]?.y
            const lastRowOfPositionedNodes = positionedNodes.filter(el => el.y === lastRowOfPositionedNodesY)

            for (const node of lastRowOfPositionedNodes){
                const children = updatedCells.filter(el => el.parentId === node.id)
                console.log('children, children?', children)
                if (!!children?.length) {
                    const leftChild = children.find(el => !!el.left)
                    const rightChild = children.find(el => !!el.right)
                    const levelsDown = []

                    updatedCells.filter(el => el.y > node.y).forEach(el => !levelsDown.includes(el.y) && levelsDown.push(el.y))
                    if (!!leftChild && !!rightChild) {
                        const offset = !!spreadedOutMoreAbove && spreadedOutMoreAbove >= node.y ? overlapsDuringPositioning + 1 : 1
                        if (!!leftChild) {
                            positionedNodes.push({
                                ...leftChild,
                                x: node.x - offset,
                            })
                        }
                        if (!!rightChild) {
                            positionedNodes.push({
                                ...rightChild,
                                x: node.x + offset,
                            })
                        }

                        console.log('overlappingCells offset', spreadedOutMoreAbove, overlapsDuringPositioning, offset, leftChild, rightChild, node.x, positionedNodes[positionedNodes.length - 1], positionedNodes[positionedNodes.length - 2])
                    } else {
                        positionedNodes.push({
                            ...(leftChild || rightChild),
                            x: node.x,
                        })
                    }

                    const overlappingCellsFound = overlappingCells(positionedNodes)

                    if (!!overlappingCellsFound?.length) {
                        const commonParent = findCommonParent(overlappingCellsFound, positionedNodes)
                        console.log('overlappingCells 123321', overlapsDuringPositioning, spreadedOutMoreAbove, spreadedOutMoreAbove >= node.y, overlapFound, commonParent, commonParent.y !== spreadedOutMoreAbove, overlappingCells(positionedNodes))
                        if (
                            (commonParent.y !== spreadedOutMoreAbove)
                            || (
                                lastOverlaps?.x !== overlappingCellsFound[0].x && lastOverlaps?.y !== overlappingCellsFound[0].y
                            )
                        ) {
                            overlapsDuringPositioning++
                            overlapFound = commonParent.y 
                            lastOverlaps.x = overlappingCellsFound[0].x
                            lastOverlaps.y = overlappingCellsFound[0].y
                            break loop;
                        }                       
                    }
                }
            }
        }
        if (overlapFound) {
            return positionCells(updatedCells, overlapFound)
        }
        console.log('going on and on', spreadedOutMoreAbove)
        const minimumX = positionedNodes.sort((a, b) => a.x - b.x)[0]?.x

        const newCells = positionedNodes.map(el => ({
            ...el,
            x: el.x - minimumX
        }))

        setCells(newCells)

        // lines = []
        // setTimeout(() => {
        //     newCells.forEach(cell => {
        //         if (cell.parentId) {
        //             calculateLine(newCells.find(el => el.id === cell.parentId), cell, false, false)
        //         }
        //     })
        // }, 10)

        return newCells
    }

    const resizeCanvas = () => {
        canvasRef.current.width = gridRef.current.getBoundingClientRect().width
        canvasRef.current.height = gridRef.current.getBoundingClientRect().height

        drawLines(lines)
    }

    const debouncedResize = debounce(resizeCanvas, 250)


    return (
        <div className={cn.simpleGrid} ref={gridRef}>
            <canvas ref={canvasRef} id='simple-grid' />
            {cells.map(({ x, y, id, isFirst }) => (
                <SimpleCell
                    x={x * cellWidth}
                    y={y * cellHeight}
                    id={id}
                    key={id}
                    onRemoved={() => deleteCell(id)}
                    addOne={() => addOne(id)}
                    addTwo={() => addTwo(id)}
                    isFirst={isFirst}
                    deleteChildren={() => deleteChildren(id)}
                    hasChildren={hasChildren(id)}
                />
            ))}
        </div>
    )
}


export const Playbook3 = () => {
    return <ViewContainer>
        <SimpleGrid />
    </ViewContainer>
}
