import React, { useEffect, useState } from 'react'
import moment from 'moment'
import { faDownload } from '@fortawesome/free-solid-svg-icons'
import { ViewContainer } from '../../components/ViewContainer'
import { useInterval } from '../../customHooks/useInterval'
import { makeRequest } from '../../utilities/endpoints'
import { URLS } from '../../config'
import { Loader, LOADER_SIZES, LOADER_TYPES } from '../../components/Loader'
import { Row } from '../../components/Row'
import { Table } from '../../components/Table'
import { SubHeader } from '../../components/SubHeader'
import { Button, BUTTON_TYPES } from '../../components/Button'
import { Nbsp } from '../../components/Nbsp'

export const GenerativePDFQueue = () => {
    const [queues, setQueues] = useState(null)

    const checkQueues = () => {
        makeRequest.get(`${URLS.main}/generative-pdf`)
            .then(res => {
                setQueues(res.data.data || [])
            })
    }

    useEffect(checkQueues, [])

    const finishedQueues = queues?.filter(el => !!el.doneAt)
    const notFinishedQueues = queues?.filter(el => !el.doneAt)

    useInterval(() => {
        if (!!notFinishedQueues?.length) {
            checkQueues()
        }
    }, 3000)


    return <ViewContainer>
        {queues === null ? (
            <Loader size={LOADER_SIZES.BIG} type={LOADER_TYPES.SPINNER} />
        ) : (
            <>
                <SubHeader>
                    Generations in Progress
                </SubHeader>
                <Table
                    headers={[
                        'Started At',
                        'Started By',
                        'Document',
                        'Accounts',
                        'Progress'
                    ]}
                    sorted={true}
                    sortMask={[false, true, true, true, true]}
                    customStyles={{
                        0: {
                            width: 160,
                            maxWidth: 160,
                            minWidth: 160,
                        },
                        1: {
                            width: 160,
                            maxWidth: 160,
                            minWidth: 160,
                        },
                        4: {
                            width: 130,
                            maxWidth: 130,
                            minWidth: 130,
                        }
                    }}
                    data={notFinishedQueues.map(el => {
                        let current = 0
                        let total = 1
                        try {
                            current = JSON.parse(el.accountListProgress)?.length || 0
                        // eslint-disable-next-line no-empty
                        } catch (e) {}
                        try {
                            total = JSON.parse(el.accountList)?.length
                        // eslint-disable-next-line no-empty
                        } catch (e) {}
                        return [
                            moment(el.createdAt).format('YYYY-DD-MM HH:mm'),
                            el.userFullName,
                            el.fileName,
                            el.accountListNames.join(', '),
                            <Row noMargin>
                                <div>
                                    {`${Math.round(current / total * 100)}%`}
                                </div>
                                <Nbsp />
                                <Loader type={LOADER_TYPES.SPINNER} size={LOADER_SIZES.SMALL} />
                            </Row>
                        ]
                    })}
                />
                <br />
                <SubHeader>
                    Previous generations
                </SubHeader>
                <Table
                    headers={[
                        'Started At',
                        'Started By',
                        'Document',
                        'Accounts',
                        'Done at',
                        'Download'
                    ]}
                    sorted={true}
                    sortMask={[false, true, true, true, false, true]}
                    customStyles={{
                        0: {
                            width: 160,
                            maxWidth: 160,
                            minWidth: 160,
                        },
                        4: {
                            width: 160,
                            maxWidth: 160,
                            minWidth: 160,
                        },
                        5: {
                            width: 140,
                            maxWidth: 140,
                            minWidth: 140,
                        }
                    }}
                    data={finishedQueues.map(el => ([
                        moment(el.createdAt).format('YYYY-DD-MM HH:mm'),
                        el.userFullName,
                        el.fileName,
                        el.accountListNames.join(', '),
                        moment(el.doneAt).format('YYYY-DD-MM HH:mm'),
                        <Button
                            type={BUTTON_TYPES.TRANSPARENT}
                            onClick={() => window.open(`${URLS.main}/generative-pdf/download/${el.id}`, '_blank')}
                            icon={faDownload}
                        />
                    ]))}
                />
            </>
        )}
    </ViewContainer>
}