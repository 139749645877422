/* eslint-disable no-unused-vars */
import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCancel, faCheckCircle, faEllipsisV } from '@fortawesome/free-solid-svg-icons';
import cn from './Playbook.module.css'
import { ViewContainer } from '../../../components/ViewContainer';
import { Row } from '../../../components/Row';
import { Label } from '../../../components/Label';
import { Input } from '../../../components/Input';
import { SlateEditor } from '../../../components/SlateEditor';
import { SubHeader } from '../../../components/SubHeader';
import { NBSP_SIZES, Nbsp } from '../../../components/Nbsp';
import { MODAL_SIZES, Modal } from '../../../components/Modal';
import { Button } from '../../../components/Button';
import { convertSimpleToRichText } from '../../../utilities/slateUtils';
import { isJSON } from '../../../utilities/isJSON';
import { Checkbox } from '../../../components/Checkbox';


export const Playbook2 = ({ step }) => {
    const [name, setName] = useState('Onboarding Demo Playbook')
    const [description, setDescription] = useState('Simple playbook for onboarding customers')
    const [calendarCheckbox, setCalendarCheckbox] = useState(false)
    const [autoSendCheckbox, setAutoSendCheckbox] = useState(false)
    const [autoMoveOpp, setAutoMoveOpp] = useState(false)
    const [plays, setPlays] = useState([
        {
            title: 'Customer books CS Onboarding Call',
            description: 'Has the customer booked an onboarding call',
            custom: 'CALENDAR'
        },
        {
            title: 'Move opportunity in SFDC to \'Signed\'',
            description: '',
            custom: 'AUTO_MOVE_OPP',
            predefinedValue: true
        },
        {
            title: 'CSM Changes opp Onboarding Status in SFDC to \'Awaiting Onboarding Call\'',
            description: '',
            custom: 'AUTO_MOVE_OPP',
            predefinedValue: false
        },
        {
            title: 'CSM changes opp Onboarding Status in SFDC to \'Onboarding Completed\'',
            description: '',
            custom: 'AUTO_MOVE_OPP',
            predefinedValue: false
        },
        {
            title: 'Onboarding call happens',
            description: 'Describe the focus of the call in here for the CSM and AM',
            custom: 'CALENDAR'
        }
    ])
    const [requestSent, setRequestSent] = useState(false)
    const [showDummyData, setShowDummyData] = useState()
    const [emails, setEmails] = useState([
        {
            subject: `Hey {{Contact Name}}, we've noticed a dip in your usage of Callypso`,
            body: `Hey {{Contact Name}},
            
            We've noticed a dip in your usage of Callypso. Is there an issue you are experiencing? Anything we could help with?`
        },
        {
            subject: `Hi {{Contact Name}}`,
            body: `Hey {{Contact Name}},
            
            We've wanted to reach out to you in regards to your onboarding session {{custom details}}`,
            custom: 'AUTO_SEND'
        },
    ])

    const creating = true

    const sendAnEmail = (index) => {
        setShowDummyData({
            type: 'EMAILS',
            index,
        })
    }

    const manualPlay = (index) => {
        setShowDummyData({
            type: 'MANUAL_PLAY',
            index,
        })
    }

    const filledOutPlays = plays.filter(el => !!el.name && !!el.description && !!el.color && !!el.dueDateDays).length

    const disableCreate = requestSent || !name || !name.length || !description || !description.length || !filledOutPlays || filledOutPlays !== plays?.length;

    return (
        <>
            {!step && (
                <ViewContainer noGrow smallerMarginBottom>
                    <SubHeader>Customer Journey Details</SubHeader>
                    <Row noAligning fullWidth columnFlex>
                        <Label>Name</Label>
                        <Input fullWidth onChange={e => setName(e.target.value)} value={name} />
                    </Row>
                    <Label>Description</Label>{
                        creating ? (
                            <SlateEditor
                                fullWidth
                                value={isJSON(description) ? description : convertSimpleToRichText(description)}
                                key={'main-description-slate'}
                                onChange={setDescription}
                            />
                        ) : !!description && (
                            <SlateEditor
                                fullWidth
                                value={isJSON(description) ? description : convertSimpleToRichText(description)}
                                key={'main-description-slate'}
                                onChange={setDescription}
                            />
                        )
                    }
                </ViewContainer>
            )}
            <ViewContainer smallerMarginBottom>
                <div className={cn.sequenceWrapper}>
                    <div className={cn.sendEmail} id={cn['test-id-1']} onClick={() => sendAnEmail(0)}>
                        Contract gets signed
                        <FontAwesomeIcon className={cn.interactionIndicator} icon={faEllipsisV} />
                    </div>

                    <div id={cn.border1} />

                    <div className={cn.sendEmail} id={cn['test-id-2']} onClick={() => sendAnEmail(1)}>
                        Fill out account handover details <a href='callypso.co'>here</a>
                        <FontAwesomeIcon className={`${cn.interactionIndicator} ${cn.multiLine}`} icon={faEllipsisV} />
                    </div>

                    <div className={cn.sendEmail} id={cn['test-id-3']} onClick={() => sendAnEmail(1)}>
                        Send designated email to customer
                        <FontAwesomeIcon className={`${cn.interactionIndicator} ${cn.multiLine}`} icon={faEllipsisV} />
                    </div>

                    <div className={cn.sendEmail} id={cn['test-id-4']} onClick={() => manualPlay(1)}>
                        Move opportunity in SFDC to 'Signed'
                        <FontAwesomeIcon className={`${cn.interactionIndicator} ${cn.multiLine}`} icon={faEllipsisV} />
                    </div>
                    <div className={cn.manualTask} id={cn['test-id-5']} onClick={() => manualPlay(0)}>
                        CSM executes admin setup
                        <FontAwesomeIcon className={cn.interactionIndicator} icon={faEllipsisV} />
                    </div>


                    <div className={cn.manualTask} id={cn['test-id-6']} onClick={() => manualPlay(2)}>
                        <div>
                            CSM changes opp Onboarding Status in SFDC to 'Awaiting Onboarding Call'
                            <FontAwesomeIcon className={`${cn.interactionIndicator} ${cn.multiLine3}`} icon={faEllipsisV} />
                        </div>
                    </div>
                    <div id={cn.border2} />
                    <div id={cn.border3} />
                    <div className={cn.manualTask} id={cn['test-id-15']}>
                        <div>
                            Customer fills out pre-onboarding questionnaire
                            <FontAwesomeIcon className={cn.interactionIndicator} icon={faEllipsisV} />
                        </div>
                    </div>
                    <div id={cn.border18} />
                    <div className={`${cn.manualTask} ${cn.optional}`} id={cn['test-id-7']} onClick={() => manualPlay(1)}>
                        Customer books IT onboarding call
                        <FontAwesomeIcon className={`${cn.interactionIndicator} ${cn.multiLine}`} icon={faEllipsisV} />
                    </div>
                    <div id={cn.border4} />
                    <div className={`${cn.explanation} ${cn.positive}`} id={cn['test-id-29']}>
                        <FontAwesomeIcon icon={faCheckCircle} />
                        <div>
                            Yes
                        </div>
                    </div>
                    <div id={cn.border5} />

                    <div className={cn.manualTask} id={cn['test-id-8']} onClick={() => manualPlay(2)}>
                        Michael assigns AM [Breakdown In here]
                        <FontAwesomeIcon className={`${cn.interactionIndicator} ${cn.multiLine}`} icon={faEllipsisV} />
                    </div>
                    <div id={cn.border6} />
                    <div className={cn.manualTask} id={cn['test-id-16']} onClick={() => manualPlay(1)}>
                        Send designated email to customer with the AM cc-ed
                        <FontAwesomeIcon className={`${cn.interactionIndicator} ${cn.multiLine}`} icon={faEllipsisV} />
                    </div>

                    <div className={`${cn.explanation} ${cn.negative}`} id={cn['test-id-30']}>
                        <FontAwesomeIcon icon={faCancel} />
                        <div>
                            No
                        </div>
                    </div>
                    <div id={cn.border8} />
                    <div className={cn.manualTask} id={cn['test-id-10']} onClick={() => manualPlay(1)}>
                        Send designated email to customer
                        <FontAwesomeIcon className={`${cn.interactionIndicator} ${cn.multiLine}`} icon={faEllipsisV} />
                    </div>
                    <div id={cn.border7} />
                    <div className={cn.manualTask} id={cn['test-id-17']} onClick={() => manualPlay(0)}>
                        Customer books CS Onboarding Call?
                        <FontAwesomeIcon className={cn.interactionIndicator} icon={faEllipsisV} />
                    </div>
                    <div id={cn.border9} />
                    <div id={cn.border10} />
                    <div className={`${cn.explanation} ${cn.negative}`} id={cn['test-id-31']}>
                        <FontAwesomeIcon icon={faCancel} />
                        <div>
                            No
                        </div>
                    </div>
                    <div id={cn.border11} />
                    <div className={cn.manualTask} id={cn['test-id-23']} onClick={() => manualPlay(1)}>
                        CSM and AM follow up until call is scheduled
                        <FontAwesomeIcon className={`${cn.interactionIndicator} ${cn.multiLine}`} icon={faEllipsisV} />
                    </div>
                    <div className={`${cn.explanation} ${cn.positive} ${!!step ? cn.currentStep : ''}`} data-tooltip-id='default' data-tooltip-content={!!step ? 'Current Step' : undefined} id={cn['test-id-32']}>
                        <FontAwesomeIcon icon={faCheckCircle} />
                        <div>
                            Yes
                        </div>
                    </div>
                    <div id={cn.border12} />
                    <div className={cn.manualTask} id={cn['test-id-12']} onClick={() => manualPlay(1)}>
                        CSM adds AM to the call
                        <FontAwesomeIcon className={cn.interactionIndicator} icon={faEllipsisV} />
                    </div>
                    <div id={cn.border13} />
                    <div className={`${cn.manualTask}`} id={cn['test-id-21']} data-tooltip-id='default' data-tooltip-content={!!step ? 'Next Step' : undefined} onClick={() => manualPlay(4)}>
                        Onboarding call happens
                        <FontAwesomeIcon className={cn.interactionIndicator} icon={faEllipsisV} />
                    </div>
                    <div id={cn.border14} />
                    <div id={cn.border15} />
                    <div className={`${cn.explanation} ${cn.negative}`} id={cn['test-id-33']}>
                        <FontAwesomeIcon icon={faCancel} />
                        <div>
                            No
                        </div>
                    </div>
                    <div id={cn.border16} />
                    <div className={`${cn.explanation} ${cn.positive}`} id={cn['test-id-34']}>
                        <FontAwesomeIcon icon={faCheckCircle} />
                        <div>
                            Yes
                        </div>
                    </div>
                    <div id={cn.border17} />
                    <div className={cn.manualTask} id={cn['test-id-35']} onClick={() => manualPlay(1)}>
                        CSM and AM follow up until call is scheduled
                        <FontAwesomeIcon className={`${cn.interactionIndicator} ${cn.multiLine}`} icon={faEllipsisV} />
                    </div>


                    <div className={cn.manualTask} id={cn['test-id-22']} onClick={() => manualPlay(3)}>
                        CSM changes opp Onboarding Status in SFDC to 'Onboarding Completed'
                        <FontAwesomeIcon className={`${cn.interactionIndicator} ${cn.multiLine2}`} icon={faEllipsisV} />
                    </div>
                    <div className={cn.manualTask} id={cn['test-id-23']} onClick={() => manualPlay(1)}>
                        CSM and AM follow up until call is scheduled
                        <FontAwesomeIcon className={`${cn.interactionIndicator} ${cn.multiLine}`} icon={faEllipsisV} />
                    </div>
                </div>
            </ViewContainer>
            {!!showDummyData && (
                <Modal
                    size={MODAL_SIZES.BIG}
                    buttons={(
                        <>
                            <Button
                                fullWidth
                                onClick={() => setShowDummyData(false)}
                            >
                                Save
                            </Button>
                            <br />
                            <Button
                                fullWidth
                                onClick={() => setShowDummyData(false)}
                            >
                                Cancel
                            </Button>
                        </>
                    )}
                    header='Editing play step'
                    onClose={() => setShowDummyData(false)}
                >
                    {showDummyData?.type === 'EMAILS' && (
                        <>
                            <Row columnFlex fullWidth noAligning>
                                <Label className={cn.label}>Subject</Label>
                                <Input
                                    fullWidth
                                    value={emails[showDummyData?.index]?.subject}
                                    onChange={e => {
                                        setEmails(emails.map((email, index) => {
                                            if (index === showDummyData.index) {
                                                return {
                                                    ...email,
                                                    subject: e.target.value
                                                }
                                            }
                                            return email
                                        }))
                                    }}
                                />
                            </Row>
                            <Nbsp />
                            <Row columnFlex fullWidth noAligning>
                                <Label className={cn.label}>Body</Label>
                                <SlateEditor
                                    value={isJSON(emails[showDummyData.index].body) ? emails[showDummyData.index].body : convertSimpleToRichText(emails[showDummyData.index].body)}
                                    onChange={e => {
                                        setEmails(emails.map((email, index) => {
                                            if (index === showDummyData.index) {
                                                return {
                                                    ...email,
                                                    body: e
                                                }
                                            }
                                            return email
                                        }))
                                    }}
                                />
                                {emails[showDummyData.index].custom === 'AUTO_SEND' ? (
                                    <>
                                        <br />
                                        <Checkbox label={'Review Before the email is send'} checked={autoSendCheckbox} onClick={() => setAutoSendCheckbox(!autoSendCheckbox)} />
                                    </>
                                ) : null}
                            </Row>
                        </>
                    )}

                    {showDummyData?.type === 'MANUAL_PLAY' && (
                        <>
                            <Row columnFlex fullWidth noAligning>
                                <Label className={cn.label}>Play Title</Label>
                                <Input
                                    fullWidth
                                    value={plays[showDummyData?.index]?.title}
                                    onChange={e => {
                                        setPlays(plays.map((play, index) => {
                                            if (index === showDummyData.index) {
                                                return {
                                                    ...play,
                                                    title: e.target.value
                                                }
                                            }
                                            return play
                                        }))
                                    }}
                                />
                            </Row>
                            <Nbsp />
                            <Row columnFlex fullWidth noAligning>
                                <Label className={cn.label}>Play Description</Label>
                                <SlateEditor
                                    value={isJSON(plays[showDummyData.index].description) ? plays[showDummyData.index].description : convertSimpleToRichText(plays[showDummyData.index].description)}
                                    onChange={e => {
                                        setPlays(plays.map((play, index) => {
                                            if (index === showDummyData.index) {
                                                return {
                                                    ...play,
                                                    description: e
                                                }
                                            }
                                            return play
                                        }))
                                    }}
                                />
                            </Row>
                            {plays[showDummyData.index]?.custom === 'CALENDAR' ? (
                                <>
                                    <Checkbox checked={calendarCheckbox} label={!calendarCheckbox ? 'Manual' : 'Calendar Automated'} onClick={() => setCalendarCheckbox(!calendarCheckbox)} />
                                    {calendarCheckbox && (
                                        <Input fullWidth placeholder='Event includes following tag' />
                                    )}
                                </>
                            ) : null}
                            {plays[showDummyData.index]?.custom === 'AUTO_MOVE_OPP' ? (
                                <>
                                    <Checkbox checked={plays[showDummyData.index]?.predefinedValue} label={'Move opp automatically to a different stage'} onClick={() => setAutoMoveOpp(!autoMoveOpp)} />
                                    {calendarCheckbox && (
                                        <Input fullWidth placeholder='Event includes following tag' />
                                    )}
                                </>
                            ) : null}


                        </>
                    )}
                </Modal>
            )}

            {!step && (
                <Row endAlign>
                    <Button
                        disabled={disableCreate}
                    >
                        {creating ? 'Create' : 'Update'}
                    </Button>
                    <Nbsp size={NBSP_SIZES.BIG} />
                </Row>
            )}
        </>
    )
}
