import React, { useCallback, useRef, useState } from "react";
import { GithubPicker } from "react-color";
import cn from './PopoverGithubPicker.module.css'

import { useOutsideClickHandler } from '../customHooks/useOutsideClickHandler'
import { hexToRgbA } from "../utilities/colors";

export const PopoverGithubPicker = ({ color, colors, onChange, onClose, disabled, alignTowardsLeft }) => {
    const popover = useRef();
    const [isOpen, toggle] = useState(false);

    const close = useCallback(() => {
        toggle(false)
        onClose && onClose()
    }, []);
    useOutsideClickHandler(popover, close);

    return (
        <div className={`${cn.picker} ${alignTowardsLeft && cn.alignTowardsLeft}`}>
            <div
                data-test-id={`COLOR_PICKER`}
                className={`${cn.swatch} ${disabled ? cn.disabled : ''}`}
                style={{ border: `1px solid ${color}` }}
                onClick={(e) => {
                    if (!disabled) {
                        toggle(true)
                        e.preventDefault();
                        e.stopPropagation()
                    }
                }}
            >
                <div className={cn.partialColor} style={{ backgroundColor: hexToRgbA(color, 0.2) }}>
                    <div className={cn.internalColor} style={{ backgroundColor: color }} />
                </div>
            </div>

            {isOpen && (
                <div
                    className={cn.popover}
                    ref={popover}
                    onClick={e => {
                        e.preventDefault()
                        e.stopPropagation()
                    }}
                >
                    <GithubPicker
                        color={color}
                        colors={colors}
                        onChange={({ hex }) => {
                            onChange && onChange(hex)
                            close()
                        }}
                    />
                </div>
            )}
        </div>
    );
};
