import React, { useEffect, useState } from 'react'
import { createPortal } from 'react-dom'
import { connect } from 'react-redux'
import { faTimesCircle } from '@fortawesome/free-solid-svg-icons'
import { BUTTON_TYPES, Button } from './Button'
import cn from './TimedNotification.module.css'
import { Row } from './Row'
import store from '../store'
import { removeNotification } from '../store/actions'

const HEIGHT = 28;
const MARGIN = 8;

const TimedNotifications = ({ notifications }) => {
    return notifications.map((el, i) => <TimedNotification {...el} index={i} key={i} />)
}

export const TimedNotification = ({ message, id, index }) => {
    const [shown, setShown] = useState(true)
    const [clearFinished, setClearFinished] = useState(false)
    const [initialTimeout, setInitialTimeout] = useState(false)
    const mainParent = document.getElementById('root')

    useEffect(() => {
        if (!shown && !clearFinished) {
            setTimeout(() => {
                setClearFinished(true)
                store.dispatch(removeNotification(id))
                setTimeout(() => {
                    store.dispatch(removeNotification(id))
                }, 500)
            }, 500)
        }
    }, [shown, clearFinished])

    useEffect(() => {
        if (!initialTimeout) {
            setInitialTimeout(true)
            setTimeout(() => {
                setShown(false)
            }, 2500)
        }
    }, [initialTimeout])

    if (!shown && clearFinished) {
        return false
    }

    let top = '-200px'
    if (initialTimeout) {
        top = `${(index + 1) * (HEIGHT + MARGIN)}px`
    }

    if (!shown && !clearFinished) {
        top = '-200px'
    }

    return createPortal(
        <div
            className={`${cn.timedNotification}`}
            style={{ top }}
        >
            <Row noMargin className={cn.timedNotificationDetails}>
                {message}
                <Button type={BUTTON_TYPES.SMALLER_ICON} icon={faTimesCircle} onClick={() => setShown(false)} />
            </Row>
        </div>,
        mainParent
    )
}

export const mapStateToProps = (state) => {
    return {
        notifications: state.notifications,
    }
}

export const TimedNotificationsConnected = connect(mapStateToProps)(TimedNotifications)