import React from 'react'
import cn from './TopPart.module.css'
import { Details } from './Details'
import { MissionStatement } from './MissionStatement'
import { ProductTickets } from './ProductTickets'
import { NOTES_TYPES, Notes } from '../../../../components/Notes/Notes'

export const TAB_OPTIONS = {
    DETAILS: 0,
    MISSION_STATEMENT: 1,
    ACCOUNT_NOTES: 2,
    CONTACT_NOTES: 3,
    TICKETS: 4,
}

export const TopPart = ({ selectedTab, accountDetails, numberOfPeopleWorking, contacts }) => {
    let content = 0
    if (selectedTab === TAB_OPTIONS.DETAILS) {
        content = (
            <Details
                accountDetails={accountDetails}
                numberOfPeopleWorking={numberOfPeopleWorking}
            />
        )
    }

    if (selectedTab === TAB_OPTIONS.MISSION_STATEMENT) {
        content = <MissionStatement />
    }

    if (selectedTab === TAB_OPTIONS.ACCOUNT_NOTES) {
        content = <Notes
            type={NOTES_TYPES.ACCOUNT}
            contacts={contacts}
            id={accountDetails?.id}
            accountName={accountDetails?.name}
        />
    }

    if (selectedTab === TAB_OPTIONS.CONTACT_NOTES) {
        content = <Notes
            type={NOTES_TYPES.CONTACTS}
            accountName={accountDetails?.name}
            contacts={contacts}
            id={accountDetails?.id}
        />
    }

    if (selectedTab === TAB_OPTIONS.TICKETS) {
        content = <ProductTickets
            contacts={contacts}
            accountId={accountDetails?.id}
        />
    }

    return (
        <div className={cn.wrapper}>
            {content}
        </div>
    )
}