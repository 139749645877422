import React from 'react'
import store from '../store'
import cn from './Checkbox.module.css'

export const Checkbox = ({ checked, defaultChecked, label, onClick, disabled, onMouseDown, onMouseUp, alignTop, labelColor }) => {
    return (
        <div
            data-test-id={`CHECKBOX ${label}`}
            className={`
                ${cn.checkbox}
                ${disabled && cn.disabled}
                ${alignTop && cn.alignTop}
                ${labelColor && cn.labelColor}
                ${!!label && cn.hasLabel}
                ${!!checked && cn.checked}
            `}
            onClick={disabled ? undefined : onClick}
            onMouseDown={onMouseDown}
            onMouseUp={onMouseUp}
        >
            <label htmlFor={label}>{label}</label>
            <div className={cn.checkboxWrapper}>
                {checked && (
                    <svg
                        width="17"
                        height="17"
                        viewBox="0 0 17 17"
                        fill="none"
                        version="1.1"
                        id="svg1"
                    >
                        <path
                            d="M 7.1231,12.9444 3.56755,9.38889 4.82088,8.12667 7.1231,10.4289 12.9809,4.57111 14.2342,5.83333"
                            fill={store.getState().colorScheme === 'DARK' ? '#a0ffbb' : '#609970'}
                            id="path1"
                        />
                    </svg>
                )}
                <input type="checkbox" name={label} checked={checked} defaultChecked={defaultChecked} onChange={() => {}} />
            </div>
        </div>
    )
}