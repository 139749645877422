import React from 'react'
import cn from './Loader.module.css'

export const LOADER_SIZES = {
    SMALL: 1,
    BIG: 2,
    EXTRA_BIG: 3,
}

const getSizeClass = size => {
    switch (size) {
    case LOADER_SIZES.BIG:
        return cn.big
    case LOADER_SIZES.EXTRA_BIG:
        return cn.extraBig
    default:
        return cn.small
    }
}

export const LOADER_TYPES = {
    REGULAR: 1,
    SPINNER: 2,
}

export const Loader = ({ size = LOADER_SIZES.SMALL, type, className, smallerMargin }) => {
    if (type === LOADER_TYPES.SPINNER) {
        return (
            <div className={`${cn.spinner} ${smallerMargin ? cn.smallerMargin : ''} ${getSizeClass(size)} ${className}`} />
        )
    }
    return (
        <svg className={`${cn.loader} ${smallerMargin ? cn.smallerMargin : ''} ${getSizeClass(size)} ${className}`} style={{ animation: 'rotation 1s linear infinite' }} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
            <circle style={{ opacity: '0.25' }} cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="2" />
            <path style={{ opacity: '75' }} fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z" />
        </svg>
    )
}
