import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch } from '@fortawesome/free-solid-svg-icons'
import { Row } from '../../../../components/Row'
import { Checkbox } from '../../../../components/Checkbox'
import cn from './Sidebar.module.css'
import { Input } from '../../../../components/Input'
import { Button, BUTTON_TYPES } from '../../../../components/Button'
import { translateCRMLabel } from '../../../../utilities/translateCRMLabels'


const Contact = ({ contact, account, nested, onChange }) => {
    const { id, firstName, lastName, title, shown, Id, FirstName, LastName, Title } = (contact || {})

    const getNestedLevel = () => {
        switch (nested) {
        case 2:
            return cn.nested2x
        case 1:
            return cn.nested
        default:
            break;
        }
    }
    const getName = () => {
        if (account?.name) {
            return account.name
        }
        if (firstName || lastName) {
            return `${firstName} ${lastName}`
        }
        if (FirstName || LastName) {
            return `${FirstName} ${LastName}`
        }
    }

    return (
        <Row key={id || Id} className={getNestedLevel()}>
            <Checkbox
                onClick={() => onChange(contact)}
                checked={shown}
            />
            <div className={cn.contactDetails}>
                <div className={cn.name}>
                    {getName()}
                </div>
                <div className={cn.position}>
                    {title || Title}
                </div>
            </div>
        </Row>
    )
}

const Contacts = ({
    childAccounts,
    hiddenAccounts,
    hiddenDepartmentAccount,
    accountName,
    accountId,
    contacts,
    hasNestedAccounts,
    toggleChildAccount,
    toggleDepartmentContacts,
    filterContacts,
    onChange,
    parentAccount
}) => {
    if (!contacts || !contacts.length) {
        return (
            <div className={cn.noAccountsBlurb} key={'no-contacts'}>
                {`This ${translateCRMLabel('Account')} has no contacts`}
            </div>
        )
    }

    const contactsPerDepartment = {}
    contacts.forEach(contact => {
        if (!contactsPerDepartment[contact.divisionName]) {
            contactsPerDepartment[contact.divisionName] = []
        }
        contactsPerDepartment[contact.divisionName].push(contact)
    })

    let sortedContacts = []

    Object.keys(contactsPerDepartment).sort().forEach(key => {
        sortedContacts = [...sortedContacts, ...contactsPerDepartment[key]]
    })

    if (hasNestedAccounts) {
        return (
            <>
                <Row>
                    <Checkbox
                        checked={true}
                    />
                    <div className={cn.contactDetails}>
                        <div className={cn.name}>
                            {accountName}
                        </div>
                    </div>
                </Row>
                {Object.keys(contactsPerDepartment).map(department => {
                    if (!filterContacts(contacts.filter(el => el.divisionName === department && el?.Account?.Id === accountId)).length) {
                        return null
                    }

                    return (
                        <>
                            <Row className={cn.nested}>
                                <Checkbox
                                    onClick={() => toggleDepartmentContacts(accountId, department)}
                                    checked={!hiddenDepartmentAccount.includes(`${accountId}${department}`)}
                                />
                                <div className={cn.contactDetails}>
                                    <div className={cn.departmentName}>
                                        {department}
                                    </div>
                                </div>
                            </Row>
                            {filterContacts(contacts.filter(el => el.divisionName === department && el?.Account?.Id === accountId)).map(contact => (
                                <Contact
                                    nested={2}
                                    contact={contact}
                                    onChange={onChange}
                                />
                            ))}
                        </>
                    )
                })}

                {childAccounts.map(account => (
                    <>
                        <Row key={account.Id}>
                            <Checkbox
                                onClick={() => toggleChildAccount(account.Id)}
                                checked={!hiddenAccounts.includes(account.Id)}
                            />
                            <div className={cn.contactDetails}>
                                <div className={cn.accountName}>
                                    {account.Name}
                                </div>
                                <div className={cn.position}>
                                    <Link to={`/account/${account.Id}/map`}>Account Map</Link>
                                </div>
                            </div>
                        </Row>
                        {!hiddenAccounts.includes(account.Id) ? Object.keys(contactsPerDepartment).map(department => {
                            return (
                                <>
                                    <Row className={cn.nested}>
                                        <Checkbox
                                            onClick={() => toggleDepartmentContacts(account.Id, department)}
                                            checked={!hiddenDepartmentAccount.includes(`${account.Id}${department}`)}
                                        />
                                        <div className={cn.contactDetails}>
                                            <div className={cn.departmentName}>
                                                {department}
                                            </div>
                                        </div>
                                    </Row>
                                    {filterContacts(contacts.filter(el => el.divisionName === department && el?.Account?.Id === account.Id)).map(contact => (
                                        <Contact
                                            nested={2}
                                            contact={contact}
                                            onChange={onChange}
                                        />
                                    ))}
                                </>
                            )
                        }) : null}
                    </>
                ))}
            </>
        )
    }

    return (
        <>
            {parentAccount && (
                <div className={cn.parentAccountWrapper}>
                    <div>Parent: </div><Link to={`/account/${parentAccount.Id}/map`}>{parentAccount.Name}</Link>
                </div>
            )}
            {Object.keys(contactsPerDepartment).sort().map(department => {
                return (
                    <>
                        <Row>
                            <Checkbox
                                onClick={() => toggleDepartmentContacts(accountId, department)}
                                checked={!hiddenDepartmentAccount.includes(`${accountId}${department}`)}
                            />
                            <div className={cn.contactDetails}>
                                <div className={cn.departmentName}>
                                    {department}
                                </div>
                            </div>
                        </Row>
                        {filterContacts(contacts.filter(el => el.divisionName === department)).map(contact => (
                            <Contact
                                nested={1}
                                contact={contact}
                                onChange={() => onChange(contact)}
                            />
                        ))}
                    </>
                )
            })}
        </>
    )
}



export const Sidebar = ({ accountId, accountName, contacts, parentAccount, onChange, onAddNewContactClicked }) => {
    const [filterOn, setFilterOn] = useState(undefined)
    const [hiddenAccounts, setHiddenAccounts] = useState([])
    const [hiddenDepartmentAccount, setHiddenDepartmentAccount] = useState([])
    // eslint-disable-next-line
    const uniqueAccounts = [...new Set(contacts.map(el => el?.Account?.Id || el.accountId).filter(el => !!el && el != accountId))]
    const hasNestedAccounts = !!uniqueAccounts.length
    const childAccounts = []

    if (hasNestedAccounts) {
        contacts.forEach(contact => {
            // eslint-disable-next-line
            if (contact.Account && contact.Account?.Id != accountId && !childAccounts.map(el => el?.Id).includes(contact.Account?.Id)) {
                childAccounts.push(contact.Account)
            }
        })
    }

    const filterContacts = (contacts) => {
        return contacts.filter(contact => {
            if (!filterOn) {
                return true
            }

            return `${contact.firstName} ${contact.lastName}`.toLowerCase().includes(filterOn.toLowerCase())
                || contact.title?.toLowerCase().includes(filterOn)
                || contact.email?.toLowerCase().includes(filterOn)
        })
    }

    const toggleChildAccount = (accountId) => {
        // eslint-disable-next-line
        const contactsToUpdate = contacts.filter(el => el.accountId == accountId)        
        onChange(contactsToUpdate, hiddenAccounts.includes(accountId))

        if (hiddenAccounts.includes(accountId)) {
            setHiddenAccounts(hiddenAccounts.filter(el => el !== accountId))
        } else {
            setHiddenAccounts([...hiddenAccounts, accountId])
        }
    }

    const toggleDepartmentContacts = (accountId, department) => {
        // eslint-disable-next-line
        const contactsToUpdate = contacts.filter(el => (el.accountId == accountId || el?.Account?.Id === accountId) && el.divisionName === department)
        onChange(contactsToUpdate, hiddenDepartmentAccount.includes(`${accountId}${department}`))

        if (hiddenDepartmentAccount.includes(`${accountId}${department}`)) {
            setHiddenDepartmentAccount(hiddenDepartmentAccount.filter(el => el !== `${accountId}${department}`))
        } else {
            setHiddenDepartmentAccount([...hiddenDepartmentAccount, `${accountId}${department}`])
        }
    }

    return (
        <div className={cn.sidebar}>
            <div className={cn.search}>
                <Input
                    placeholder='Search'
                    value={filterOn}
                    onChange={e => setFilterOn(e.target.value)}
                    icon={<FontAwesomeIcon icon={faSearch} />}
                    fullWidth
                />
            </div>
            <div className={cn.grow}>
                <Contacts
                    childAccounts={childAccounts}
                    hiddenAccounts={hiddenAccounts}
                    hiddenDepartmentAccount={hiddenDepartmentAccount}
                    accountId={accountId}
                    accountName={accountName}
                    contacts={contacts}
                    hasNestedAccounts={hasNestedAccounts}
                    toggleChildAccount={toggleChildAccount}
                    toggleDepartmentContacts={toggleDepartmentContacts}
                    filterContacts={filterContacts}
                    onChange={onChange}
                    parentAccount={parentAccount}
                />
            </div>
            <Button type={BUTTON_TYPES.PRIMARY} onClick={onAddNewContactClicked} fullWidth>
                Add New Contact
            </Button>
        </div>
    )
}
