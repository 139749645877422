import React, { useCallback, useState, useRef, useEffect } from 'react'
import debounce from 'lodash.debounce'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSortUp, faSortDown } from '@fortawesome/free-solid-svg-icons'
import cn from './Table.module.css'
import { makeRequest } from '../utilities/endpoints'
import { URLS } from '../config'

let startX = null
let startingWidth = null
let resizeTarget = null
let targetIndex = null
let startingTableWidth = null

const dataType = (data) => {
    switch (typeof data) {
    case 'boolean':
        return data ? 'Yes' : 'No';
    default:
        return data;
    }
}

const dirFlip = {
    asc: 'desc',
    desc: 'asc'
}

const numberToCol = num => {
    let str = ''
    while (num > 0) {
        const char = (num-1) % 26
        num = Math.floor((num-1) / 26)
        str = `${String.fromCharCode(65 + char)} ${str}`
    }
    return str
}

export const Table = ({
    headers=[],
    autogenerateHeaders,
    data=[],
    className={},
    customStyles={},
    minWidth=null,
    sticky=true,
    sorted=false,
    sortedCol=null,
    sortDir=null,
    sortMask=[],
    sortCallBack=null,
    objectId,
    featureName,
    saveSizeUpdates,
    resizible,
    onMassChange,
    testId,
    allowCellOverflow=false,
    headerContextHandler,
    noPaddingHeader,
    noPaddingRightHeader,
    style,
    borderless
}) => {
    const [sizes, setSizes] = useState([])
    if (!!autogenerateHeaders) {
        headers = []
        for (let i = 0; i < autogenerateHeaders; i++) {
            headers.push(numberToCol(i + 1))
        }
    }
    const tableRef = useRef(null)
    // function so that when you're already sorted in one direction, click the arrow sorts it in the other way.
    const flipDir = (el, curCol, i, val, cb=null) => {
        let dir = val; // up or down

        if (curCol === i && sortDir) {
            dir = sortDir === val ? dirFlip[val] : null; // flips the direction if it's currently the same direciton
        }
        if (cb) {
            cb(el, i, dir);
        }
    }

    // the logic of showing which column shows arrows
    const showColArrowLogic = (elDir, curDir, sortColNum, colMask, curCol) => {
        // if no column specified, show both arrows to all columns
        if (curCol < colMask.length && colMask[curCol]) {
            return false;
        }

        if (sortColNum === null) {
            return true;
        }

        if (sortColNum !== curCol) {
            return true;
        }

        if (!curDir) {
            return true;
        }

        if (elDir === curDir) {
            return true;
        }
        return false;
    }

    const [clickedOnCells, setClickedOnCells] = useState([])

    const updateSize = () => {
        const columns = [...tableRef.current.querySelectorAll('th')]
        makeRequest.put(`${URLS.main}/tables/${featureName}/${objectId}`, {
            sizes: columns.map(el => el.offsetWidth)
        })
    }

    useEffect(() => {
        if (resizible && tableRef?.current) {
            getTableSize()
        }
    }, [tableRef, resizible])

    const updateSizeDebounce = useCallback(
        debounce(updateSize, 250),
        []
    );

    const getTableSize = () => {
        makeRequest.get(`${URLS.main}/tables/${featureName}/${objectId}`)
            .then(res => {
                if (res && res.data) {
                    const sizes = []

                    res.data.forEach(({ columnIndex, size }) => {
                        sizes[columnIndex] = size
                    })

                    setSizes(sizes)
                }
            })
    }

    useEffect(() => {
        if (resizible) {
            document.addEventListener('mousemove', e => {
                if (tableRef?.current && startX) {
                    // e.target.style.transform = `translateX(${e.clientX - startX}px)`;
                    const diff = e.clientX - startX
                    if (!startingTableWidth) {
                        startingTableWidth = tableRef.current.offsetWidth
                    }
                    if (!startingWidth) {
                        startingWidth = resizeTarget.offsetWidth
                    } else {
                        tableRef.current.style.width = `${parseInt(startingTableWidth) + diff}px`
                        tableRef.current.querySelectorAll('th')[targetIndex].style.width = `${parseInt(startingWidth) + diff}px`
                    }
                    if (saveSizeUpdates) {
                        updateSizeDebounce()
                    }
                }
            })
            document.addEventListener('mouseup', () => {
                startX = null
                startingWidth = null
                resizeTarget = null
                targetIndex = null
                startingTableWidth = null
            })
        }
    }, [])

    useEffect(() => {
        if (sizes && sizes.length && data && data.length && sizes.length < data[0].length) {
            const newSizes = [...sizes]
            while (data[0].length > newSizes.length) {
                newSizes.push(100)
            }
            setSizes(newSizes)
        }
    }, [sizes, data])

    return (
        <div style={style}>
            <table
                className={`
                    ${cn.dataTable}
                    ${resizible ? cn.resizible : ''}
                    ${sticky ? cn.dataTableSticky : ''}
                    ${sorted ? cn.sortable : ''}
                    ${borderless ? cn.borderless : ''}
                    ${className}
                `}
                ref={tableRef}
                data-test-id={testId || 'TABLE'}
            >
                <thead>
                    <tr data-test-id={`TABLE-ROW HEADER`}>
                        {headers.map((el, i) => {
                            let width = minWidth || 'auto'
                            if (sizes[i]) {
                                width = `${sizes[i]}px`
                            }
                            return (
                                <th
                                    key={i}
                                    className={`${noPaddingHeader ? cn.noPaddingHeader : null} ${noPaddingRightHeader ? cn.noPaddingRightHeader : null}`}
                                    onContextMenu={headerContextHandler ? e => headerContextHandler(i, e) : null}
                                    style={
                                        {
                                            ...(customStyles[i] || {}),
                                            ...(customStyles[i]?.th || {}),
                                            ...(resizible && !customStyles[i]?.fixedWidth ? {
                                                width
                                            } : {})
                                        }
                                    }
                                >
                                    <div className={cn.dataTableThWrap}>
                                        <div className={cn.dataTableThSubWrap}>
                                            {Array.isArray(el) ? (
                                                <>
                                                    <div className={cn.topPart}>
                                                        {el[0]}
                                                    </div>
                                                    <div className={cn.bottomPart}>
                                                        {el[1]}
                                                    </div>
                                                </>
                                            ) : el}
                                        </div>
                                        {(sorted || resizible) ? (
                                            <div className={cn.headerNonDataWrapper}>
                                                {sorted && (
                                                    <div className={cn.dataTableThArrowsBorder}>
                                                        <div className={cn.dataTableThArrows}>
                                                            { showColArrowLogic('desc', sortDir, sortedCol, sortMask, i) && (<div name='desc' onClick={() => { flipDir(el, sortedCol, i, 'desc', sortCallBack); }}><FontAwesomeIcon icon={faSortUp} /></div>)}
                                                            { showColArrowLogic('asc', sortDir, sortedCol, sortMask, i) && (<div name='asc' onClick={() => { flipDir(el, sortedCol, i, 'asc', sortCallBack); }}><FontAwesomeIcon icon={faSortDown} /></div>)}
                                                        </div>
                                                    </div>
                                                )}
                                                {resizible && (
                                                    <div
                                                        className={cn.resizeBorder}
                                                        onMouseDown={e => {
                                                            startX = e.clientX
                                                            resizeTarget = e.target.parentNode.parentNode.parentNode
                                                            targetIndex = i
                                                        }}
                                                    >
                                                        <div />
                                                    </div>
                                                )}
                                            </div>
                                        ) : null}
                                    </div>
                                </th>
                            )
                        })}
                    </tr>
                </thead>
                <tbody>
                    {data.map((dataRow, dataIndex) => (
                        <tr key={dataIndex} data-test-id={`TABLE-ROW DATA`}>
                            {dataRow.map((dataEl, i) => {
                                let width = minWidth || 'auto'
                                if (sizes[i]) {
                                    width = `${sizes[i]}px`
                                }
                                return (
                                    <td
                                        key={i}
                                        style={
                                            {
                                                ...(customStyles[i] || {}),
                                                ...(customStyles[i]?.td || {}),
                                                ...(resizible && !customStyles[i]?.fixedWidth ? {
                                                    width,
                                                } : {})
                                            }
                                        }
                                        className={`
                                            ${clickedOnCells.find(el => el.row === dataIndex && el.column === i) && clickedOnCells.length > 1 ? cn.multipleSelection : ''}
                                            ${allowCellOverflow ? '' : cn.dataTableCellHideOverflow}
                                        `}
                                        onClick={(e) => {
                                            let newClickedOnCells = [{
                                                row: dataIndex,
                                                column: i
                                            }]
                                            if (e.ctrlKey) {
                                                newClickedOnCells = [...clickedOnCells, {
                                                    row: dataIndex,
                                                    column: i
                                                }]
                                            }
                                            setClickedOnCells(newClickedOnCells)
                                            onMassChange && onMassChange(newClickedOnCells)
                                        }}
                                    >
                                        {dataType(dataEl)}
                                    </td>
                                )
                            })}
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    )
}
