import React from 'react'
import { Row } from './Row';
import { Label } from './Label';
import { Dropdown } from './Dropdown';
import { Input } from './Input';
import cn from './SFDCObjSelector.module.css'
import { SelectWrapperConnected } from './SelectWrapper';

export const SFDCObjSelector = ({
    paramsObj,
    inputValue,
    inputOnChange,
    inputPlaceHolder,
    objValue,
    objOnChange,
    fieldValue,
    fieldOnChange,
    formatValues=null,
    formatValue,
    formatOnChange,
    additionalAccountFormatValues=[],
    additionalObjectsFormatValues=[],
}) => {
    if (!paramsObj) {
        return (
            <div>&nbsp;</div>
        );
    }

    const dropDown = formatValues || objValue?.value === 'Account' ? [
        { label: 'Number', value: 'number' },
        { label: 'Percentage', value: 'percentage' },
        { label: 'Currency', value: 'currency' },
        { label: 'Text', value: 'text' },
        ...additionalAccountFormatValues
    ] : [
        { label: 'Sum of all values', value: 'SUM' },
        { label: 'Count of the objects', value: 'COUNT' },
        { label: 'Comma separated values', value: 'CSV' },
        ...additionalObjectsFormatValues
    ]

    return (
        <>
            {paramsObj.name?.show && (
                <Row className={paramsObj.name?.row?.className || cn.autoCompleteRow} columnFlex noAligning>
                    <Label className={paramsObj.name?.label?.className || cn.wideLabel}>{paramsObj.name?.label?.name || "Name"}</Label>
                    <Input placeholder={inputPlaceHolder || 'Name'} value={inputValue || ''} onChange={inputOnChange} />
                </Row>
            )}
            <Row className={paramsObj.obj?.row?.className || cn.autoCompleteRow} columnFlex noAligning>
                <Label className={paramsObj.obj?.label?.className || cn.wideLabel}>{paramsObj.obj?.label?.name || "Object to Add"}</Label>
                <SelectWrapperConnected
                    className={paramsObj.obj?.select?.className || cn.ruleDropdown}
                    onChange={objOnChange}
                    options={paramsObj.obj?.select?.options || []}
                    value={objValue || ''}
                    styles={paramsObj.obj?.select?.style || {}}
                />
            </Row>
            <Row className={paramsObj.field?.row?.className || ''} columnFlex noAligning>
                <Label className={paramsObj.field?.label?.className || cn.wideLabel}>{paramsObj?.field?.label?.name || "Field to Add"}</Label>
                <SelectWrapperConnected
                    onChange={fieldOnChange}
                    options={paramsObj.field?.select?.options || []}
                    value={fieldValue || ''}
                    styles={paramsObj.field?.select?.style || {}}
                />
            </Row>
            <Row className={paramsObj.format?.row?.className || ''} columnFlex noAligning>
                <Label className={paramsObj.format?.label?.className || cn.wideLabel}>{paramsObj.format?.label?.name || "Format as"}</Label>
                <Dropdown
                    fullWidth
                    values={dropDown || []}
                    value={formatValue || ''}
                    onChange={formatOnChange}
                />
            </Row>
        </>
    )
}
