import React, { useEffect, useState } from 'react'
import { Line } from 'react-chartjs-2'
import moment from 'moment'
import { makeRequest } from '../../../utilities/endpoints';
import { URLS } from '../../../config';
import { LOADER_SIZES, LOADER_TYPES, Loader } from '../../../components/Loader';

// taken directly from the source code -> https://github.com/casesandberg/react-color/blob/bc9a0e1dc5d11b06c511a8e02a95bd85c7129f4b/src/components/github/Github.js#L121
// last one has been replaced with white to reset the filter
const PICKER_COLORS = [
    '#B80000',
    '#DB3E00',
    '#FCCB00',
    '#008B02',
    '#006B76',
    '#1273DE',
    '#004DCF',
    '#5300EB',
    '#EB9694',
    '#FAD0C3',
    '#FEF3BD',
    '#C1E1C5',
    '#BEDADC',
    '#C4DEF6',
    '#BED3F3',
    '#FFFFFF'
]

const graphConfig = {
    labels: null,
    datasets: [{
        data: [65, 59, 80],
        fill: false,
        borderColor: 'rgb(75, 192, 192)',
        tension: 0.1
    }]
}

const graphOptions = {
    type: 'line',
    indexAxis: 'x',
    maintainAspectRatio: false,
    animation: false,
    plugins: {
        tooltip: {
            enabled: true,
            callbacks: {
                label: context => `${context.dataset.label || ''}: ${context.raw}%`
            }
        },
    },
    scales: {
        yAxes: {
            stacked: false,
        },
    },
};

export const RiskWorkspaceTrend = ({ trendData, trendDataConfig }) => {
    const [fallbackData, setFallbackData] = useState(false)
    const [requestSent, setRequestSent] = useState(false)
    useEffect(() => {
        if (!trendData && !!trendDataConfig && JSON.stringify(trendDataConfig) !== requestSent) {
            setRequestSent(JSON.stringify(trendDataConfig))
            makeRequest.get(`${URLS.main}/risk/workspace-trends/${trendDataConfig.columnId}/${trendDataConfig.trendUserId}/${trendDataConfig.timePeriod}/${trendDataConfig.formatting}/${trendDataConfig.aggregatedResult}/${trendDataConfig.timeFormat}/${trendDataConfig.filter}`)
                .then(res => {
                    setFallbackData(res.data)
                })
        }
    }, [trendData, trendDataConfig, requestSent])
    const data = trendData || fallbackData
    if (!data?.data) {
        return (
            <Loader type={LOADER_TYPES.SPINNER} size={LOADER_SIZES.BIG} />
        )
    }
    return (
        <Line
            data={{
                ...graphConfig,
                labels: [...new Set(data?.data?.map(el => Object.keys(el.data)).flat().map(el => moment(el * 1).format('DD/MM/YYYY')))],
                datasets: data?.data?.map(({ data, column }, i) => ({
                    label: column,
                    data: Object.values(data),
                    fill: false,
                    borderColor: PICKER_COLORS[i],
                    tension: 0.1,
                }))
            }}
            options={{
                ...graphOptions,
                scales: {
                    y: {
                        min: data?.min,
                        max: data?.max,
                        ticks: {
                            format: {
                                style: data?.format
                            }
                        }
                    }
                },
            }}
        />
    )
}