import { URLS } from "../config"
import { makeRequest } from "./endpoints"

export const isFreshdeskConnected = () => {
    return new Promise((resolve) => {
        makeRequest.get(`${URLS.main}/integrations/authenticationSettings/FRESHDESK`)
            .then(res => {
                if (res.data && res.data.hasConnection) {
                    resolve({
                        isConnected: true,
                        subdomain: res.data.subdomain,
                        isOwn: res.data.own
                    })
                } else {
                    resolve({
                        isConnected: false
                    })
                }
            })
    })
}