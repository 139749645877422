import React, { useEffect, useState } from 'react'
import moment from 'moment'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMasksTheater } from '@fortawesome/free-solid-svg-icons';
import { useHistory } from 'react-router-dom';
import { Row } from '../../components/Row'
import { SubHeader } from '../../components/SubHeader'
import { ViewContainer } from '../../components/ViewContainer'
import { Table } from '../../components/Table'
import { URLS } from '../../config'
import { makeRequest } from '../../utilities/endpoints'
import cn from './CallypsoAdmins.module.css'
import store from '../../store';
import { setUser } from '../../store/actions';

const tableLimitsHeader = [
    'WorkspaceId',
    'Name',
    'Datetime',
    'Daily API Requests',
    'Daily Generic Streaming Api Events',
    'Daily Streaming Api Events'
];

export const CallypsoAdmins = () => {
    const history = useHistory()
    const [workspacesTableLimits, setWorkspacesTableLimits] = useState([]);
    const [workspacesData, setWorkspacesData] = useState([])

    useEffect(() => {
        makeRequest.get(`${URLS.main}/callypso-admin/workspacesBreakdown`)
            .then(res => {
                if (res && res.data) {
                    setWorkspacesData(res.data)
                }
            });
        makeRequest.get(`${URLS.main}/workspaces/workspaceLimits`)
            .then(res => {
                if (res && res.data) {
                    setWorkspacesTableLimits(tableLimitsData({ ...res.data }));
                }
            });
    }, [])

    const impersonate = user => {
        makeRequest.get(`${URLS.main}/callypso-admin/impersonationDetails/${user.userId}`)
            .then(res => {
                if (res && res.data) {
                    document.cookie = res.data.cookie
                    store.dispatch(setUser({
                        ...res.data,
                        impersonating: true
                    }))
                    history.push('/')
                }
            })
    }

    const tableLimitsData = (workspaceData) => {
        if (!workspaceData || Object.keys(workspaceData).length <= 0) {
            return [];
        }

        const flat = [];
        Object.keys(workspaceData).forEach(workspaceId => {
            workspaceData[workspaceId].forEach(row => {
                if (row.jsonString) {
                    const json = JSON.parse(row.jsonString);
                    const apiRequest = (
                        <div className={cn.tableApiRequests}>
                            <div>{json.DailyApiRequests.Remaining}</div>
                            <div>{json.DailyApiRequests.Max}</div>
                        </div>
                    )
                    const genericStreamingApi = json?.DailyGenericStreamingApiEvents?.Callypso && (
                        <div className={cn.tableApiRequests}>
                            <div>{json?.DailyGenericStreamingApiEvents?.Callypso.Remaining}</div>
                            <div>{json?.DailyGenericStreamingApiEvents?.Callypso.Max}</div>
                        </div>
                    )
                    const streamingApi = json?.DailyStreamingApiEvents?.Callypso && (
                        <div className={cn.tableApiRequests}>
                            <div>{json?.DailyStreamingApiEvents?.Callypso.Remaining}</div>
                            <div>{json?.DailyStreamingApiEvents?.Callypso.Max}</div>
                        </div>
                    )
                    flat.push([row.id, row.name, moment(row.queriedDatetime).format('HH:mm MM/DD/YYYY'), apiRequest, genericStreamingApi, streamingApi]);
                } else {
                    flat.push([row.id, row.name, moment(row.queriedDatetime).format('HH:mm MM/DD/YYYY'), '', '', '']);
                }
            });
        });

        return flat;
    }

    const getType = workspace => {
        switch (workspace.type) {
        case 'SFDC_SANDBOX':
            return 'SFDC Sandbox'
        case 'CALLYPSO':
            return 'Callypso'
        default:
            return 'SFDC'
        }
    }

    return (<>
        {workspacesData.map((el, index) => (
            <ViewContainer>
                Env running on is AWS
                <SubHeader>
                    <Row spaceBetween>
                        <div>{el.name}</div>
                        <div>{getType(el)}</div>
                    </Row>
                </SubHeader>
                <br />
                Users:
                {el.users.map(user => (
                    <Row spaceBetween>
                        <Row noMargin>
                            <div className={cn.email}>
                                {user.email}
                            </div>
                            <div>
                                {user.role === 0 ? 'Admin' : 'Account Manager'}
                            </div>
                        </Row>
                        <div className={cn.impersonate} onClick={() => impersonate(user)}>
                            <FontAwesomeIcon icon={faMasksTheater} />
                        </div>
                    </Row>
                ))}
                {index===0 && (
                    <>
                        <br />
                        Limits:
                        {
                            <Table
                                headers={tableLimitsHeader}
                                data={workspacesTableLimits}
                                className={cn.tableApiRequestsTable}
                            />
                        }
                    </>
                )}
            </ViewContainer>
        ))}
    </>)
}
