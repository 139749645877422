import React from 'react'
export const Renewals = ({ fill }) => (
    <svg width="20" height="20" viewBox="0 0 20 20" fill={fill} xmlns="http://www.w3.org/2000/svg">
        <path d="M5.65342 14.1839C5.57034 14.0979 5.52437 13.9826 5.52541 13.8631C5.52645 13.7435 5.57441 13.6291 5.65898 13.5445C5.74354 13.4599 5.85794 13.412 5.97753 13.4109C6.09711 13.4099 6.21233 13.4559 6.29835 13.539C7 14.2406 7.89395 14.7184 8.86715 14.912C9.84035 15.1055 10.8491 15.0062 11.7658 14.6265C12.6826 14.2467 13.4661 13.6037 14.0174 12.7787C14.5687 11.9536 14.863 10.9837 14.863 9.99141C14.8684 9.8741 14.9188 9.76337 15.0037 9.6823C15.0887 9.60122 15.2016 9.55604 15.3191 9.55615C15.4365 9.55627 15.5494 9.60168 15.6342 9.68293C15.719 9.76417 15.7691 9.875 15.7743 9.99232C15.7742 11.165 15.4264 12.3113 14.7749 13.2863C14.1234 14.2613 13.1974 15.0212 12.114 15.4699C11.0306 15.9187 9.83845 16.0361 8.68833 15.8074C7.5382 15.5786 6.48174 15.0139 5.65251 14.1848" fill={fill}/>
        <path d="M12.8389 12.1952C12.7383 12.2623 12.6151 12.2867 12.4965 12.263C12.3778 12.2393 12.2735 12.1695 12.2063 12.0688C12.1392 11.9682 12.1148 11.845 12.1385 11.7264C12.1622 11.6077 12.232 11.5034 12.3327 11.4362L15.0693 9.61184C15.1191 9.57859 15.175 9.55549 15.2337 9.54385C15.2925 9.53221 15.353 9.53226 15.4117 9.54399C15.4705 9.55572 15.5263 9.57891 15.5761 9.61223C15.6259 9.64555 15.6686 9.68834 15.7019 9.73818C15.7351 9.78801 15.7582 9.8439 15.7699 9.90266C15.7815 9.96143 15.7815 10.0219 15.7697 10.0806C15.758 10.1394 15.7348 10.1952 15.7015 10.245C15.6682 10.2948 15.6254 10.3375 15.5755 10.3708L12.8389 12.1952Z" fill={fill}/>
        <path d="M17.523 12.4752C17.5583 12.525 17.5832 12.5813 17.5964 12.6409C17.6095 12.7005 17.6105 12.7621 17.5994 12.8221C17.5883 12.8821 17.5652 12.9393 17.5316 12.9902C17.498 13.0412 17.4545 13.0848 17.4037 13.1186C17.3529 13.1525 17.2959 13.1757 17.2359 13.1871C17.176 13.1985 17.1143 13.1977 17.0547 13.1848C16.995 13.172 16.9386 13.1472 16.8887 13.1122C16.8387 13.0771 16.7964 13.0323 16.764 12.9805L14.9396 10.2439C14.8768 10.1433 14.8557 10.0221 14.8807 9.90616C14.9057 9.79018 14.975 9.68852 15.0737 9.62276C15.1725 9.557 15.293 9.53232 15.4097 9.55394C15.5263 9.57557 15.63 9.6418 15.6986 9.73859L17.523 12.4752ZM13.9545 5.61727C14.04 5.70292 14.088 5.81902 14.0879 5.94006C14.0878 6.06109 14.0397 6.17713 13.954 6.26265C13.9116 6.305 13.8613 6.33858 13.8059 6.36147C13.7505 6.38437 13.6912 6.39613 13.6312 6.39609C13.5102 6.396 13.3942 6.34784 13.3086 6.2622C12.6069 5.56052 11.7129 5.0827 10.7396 4.88915C9.76632 4.69561 8.7575 4.79504 7.84073 5.17487C6.92395 5.5547 6.14041 6.19788 5.58919 7.02304C5.03797 7.84821 4.74384 8.81831 4.74399 9.81065C4.74387 9.93162 4.6957 10.0476 4.61008 10.133C4.52446 10.2185 4.4084 10.2664 4.28743 10.2663C4.16647 10.2662 4.0505 10.218 3.96505 10.1324C3.8796 10.0468 3.83167 9.93071 3.83179 9.80974C3.83168 8.6369 4.1794 7.49037 4.83097 6.51517C5.48254 5.53998 6.40868 4.77992 7.49226 4.33113C8.57585 3.88235 9.76818 3.76501 10.9185 3.99394C12.0687 4.22287 13.1253 4.7878 13.9545 5.61727Z" fill={fill}/>
        <path d="M6.76823 7.60598C6.81807 7.57274 6.87396 7.54964 6.93272 7.53799C6.99148 7.52635 7.05196 7.5264 7.1107 7.53813C7.16945 7.54986 7.22531 7.57305 7.27509 7.60637C7.32487 7.63969 7.3676 7.68248 7.40084 7.73232C7.43409 7.78215 7.45719 7.83804 7.46883 7.89681C7.48047 7.95557 7.48042 8.01605 7.46869 8.07479C7.45696 8.13353 7.43377 8.18939 7.40045 8.23917C7.36714 8.28895 7.32434 8.33169 7.2745 8.36493L4.53791 10.1893C4.48807 10.2226 4.43218 10.2457 4.37342 10.2573C4.31466 10.269 4.25418 10.2689 4.19543 10.2572C4.13669 10.2454 4.08083 10.2223 4.03105 10.1889C3.98127 10.1556 3.93854 10.1128 3.90529 10.063C3.87205 10.0132 3.84895 9.95726 3.83731 9.8985C3.82567 9.83974 3.82572 9.77926 3.83745 9.72052C3.84918 9.66177 3.87237 9.60592 3.90568 9.55613C3.939 9.50635 3.9818 9.46362 4.03163 9.43038L6.76823 7.60598Z" fill={fill}/>
        <path d="M2.08404 7.32587C2.04874 7.27609 2.02379 7.21974 2.01067 7.16014C1.99755 7.10055 1.99652 7.03893 2.00764 6.97893C2.01876 6.91893 2.04181 6.86177 2.07542 6.81084C2.10903 6.75991 2.15252 6.71624 2.20331 6.68242C2.2541 6.6486 2.31117 6.62531 2.37112 6.61394C2.43107 6.60257 2.4927 6.60335 2.55234 6.61623C2.61199 6.6291 2.66845 6.65382 2.71837 6.68891C2.7683 6.724 2.81067 6.76875 2.84299 6.82051L4.66739 9.55711C4.73022 9.65775 4.75137 9.77892 4.72633 9.8949C4.70129 10.0109 4.63205 10.1125 4.53329 10.1783C4.43453 10.2441 4.31403 10.2687 4.19737 10.2471C4.08071 10.2255 3.97707 10.1593 3.90844 10.0625L2.08404 7.32587Z" fill={fill}/>
    </svg>

)