import React, { useState } from 'react'
import cn from './TextArea.module.css'

export const TextArea = ({ className, forwardedRef, testId, ...rest }) => {
    const [focused, setFocused] = useState(false)
    return (
        <textarea
            onFocus={() => setFocused(true)}
            onBlur={() => setFocused(false)}
            className={`${cn.textarea} ${focused ? cn.focused : ''} ${className}`}
            ref={forwardedRef}
            data-test-id={testId || `TEXTAREA`}
            {...rest}
        />
    )
}
