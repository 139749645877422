export const getEnvironment = () => {
    switch (process.env.REACT_APP_API_URL) {
    case 'https://dev.callypso.co/api':
        return 'DEV'
    case 'https://app.callypso.co/api':
        return 'PROD'
    case 'http://localhost:3001':
        return 'LOCAL'
    }
}

export const environmentCheck = (environment) => {
    if (Array.isArray(environment)) {
        return environment.includes(getEnvironment())
    }
    return getEnvironment() === environment
}