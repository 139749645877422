export const comparisonOperators = {
    'EXACT_MATCH': 'EXACT_MATCH',
    'INCLUDES': 'INCLUDES',
    'DOESNT_INCLUDE': 'DOESNT_INCLUDE',
    'DIFFERENT_THAN': 'DIFFERENT_THAN',
    'STARTS_WITH': 'STARTS_WITH',
    'ENDS_WITH': 'ENDS_WITH',
    'BIGGER': '>',
    'BIGGER_OR_EQUAL': '>=',
    'SMALLER': '<',
    'SMALLER_OR_EQUAL': '<='
}