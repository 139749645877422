import React, { useState } from 'react'
import { slateToHtml } from "@slate-serializers/html";
import { Email } from '../../../../assets/icons/Email'
import { Button, BUTTON_TYPES } from '../../../../components/Button'
import cn from './SendEmailButton.module.css'
import { Modal } from '../../../../components/Modal'
import { Row } from '../../../../components/Row'
import { Label } from '../../../../components/Label'
import { Nbsp } from '../../../../components/Nbsp'
import { Input } from '../../../../components/Input'
import { SlateEditor } from '../../../../components/SlateEditor'
import { environmentCheck } from '../../../../utilities/environmentCheck';
import { makeRequest } from '../../../../utilities/endpoints'
import { URLS } from '../../../../config'
import { serialize } from '../../../../utilities/slateUtils';

export const SendEmailButton = ({ email }) => {
    const [emailToBeSent, setEmailToBeSent] = useState()
    const [to, setTo] = useState(`${email}`)
    const [cc, setCC] = useState()
    const [bcc, setBCC] = useState()
    const [subject, setSubject] = useState()
    const [body, setBody] = useState()

    const sendEmail = () => {
        let parsedBody = {}
        try {
            parsedBody = JSON.parse(body)
        // eslint-disable-next-line no-empty
        } catch {}

        makeRequest.post(`${URLS.main}/emails/send`, {
            to,
            cc,
            bcc,
            subject,
            body: parsedBody.length === 1 && parsedBody[0]?.children?.length === 1 ? serialize(body) : slateToHtml(parsedBody)
        })
        setCC(undefined)
        setBCC(undefined)
        setSubject(undefined)
        setBody(undefined)
        setEmailToBeSent(false)
    }
    return (
        <>
            <Button
                disabled={!email}
                type={BUTTON_TYPES.SMALL_ICON}
                onClick={() => {
                    if (environmentCheck(['LOCAL', 'DEV'])) {
                        setEmailToBeSent(email)
                    } else {
                        window.location.href = `mailto:${email}`
                    }
                }}
                className={email ? cn.active : cn.inactive}
            >
                <Email fill={'currentColor'} />
            </Button>
            {emailToBeSent && (
                <Modal
                    header={`Send an Email to ${email}`}
                    onClose={() => setEmailToBeSent(false)}
                    buttons={(
                        <Button
                            fullWidth
                            onClick={sendEmail}
                            disabled={!to || !subject || !body}
                        >
                            Send
                        </Button>
                    )}
                >
                    <Row fullWidth>
                        <Label className={cn.emailLabel}>
                            To
                        </Label>
                        <Nbsp />
                        <Input
                            fullWidth
                            onChange={e => setTo(e.target.value)}
                            value={to}
                        />
                    </Row>
                    <Row fullWidth>
                        <Label className={cn.emailLabel}>
                            Cc
                        </Label>
                        <Nbsp />
                        <Input
                            fullWidth
                            onChange={e => setCC(e.target.value)}
                            value={cc}
                        />
                    </Row>
                    <Row fullWidth>
                        <Label className={cn.emailLabel}>
                            Bcc
                        </Label>
                        <Nbsp />
                        <Input
                            fullWidth
                            onChange={e => setBCC(e.target.value)}
                            value={bcc}
                        />
                    </Row>
                    <Input
                        fullWidth
                        placeholder={'Subject'}
                        onChange={e => setSubject(e.target.value)}
                        value={subject}
                    />
                    <br />
                    <SlateEditor
                        onChange={setBody}
                        value={body}
                        fullWidth
                    />
                </Modal>
            )}
        </>
    )
}