import React, { useEffect, useState, useCallback } from 'react'
import { Link, useParams } from 'react-router-dom'
import { Item, Menu, Separator, useContextMenu } from 'react-contexify';
import moment from 'moment'
import debounce from 'lodash.debounce'
import 'react-contexify/dist/ReactContexify.css';
import cn from './GlobalWhitespacePlanning.module.css'
import { ViewContainer } from '../../components/ViewContainer'
import { Table } from '../../components/Table'
import { Pagination } from '../../components/Pagination'
import { Row } from '../../components/Row'
import { Modal } from '../../components/Modal'
import { BUTTON_TYPES, Button } from '../../components/Button'
import { makeRequest } from '../../utilities/endpoints'
import { URLS } from '../../config'
import { formatLargeSums } from '../../utilities/currency'
import { LOADER_SIZES, LOADER_TYPES, Loader } from '../../components/Loader'
import { Checkbox } from '../../components/Checkbox';
import { TriangleDown } from '../../components/TriangleDown';
import { Filter } from '../../components/Filter';
import { Input } from '../../components/Input';
import { Radio } from '../../components/Radio';
import { ProductUsageElement } from '../../components/ProductUsageElement';
import { UsageGraph } from '../accounts/account/components/UsageGraph';
import { AccountActions } from '../../components/AccountActions';
import { WhiteSpaceDropdown } from './components/WhiteSpaceDropdown';
import { EmptyTableElement } from '../risk/components/EmptyTableElement';
import { WhiteSpaceCheckbox } from './components/WhiteSpaceCheckbox';
import { NoteCell } from '../risk/components/NoteCell';
import { translateCRMLabel } from '../../utilities/translateCRMLabels';
import { getUserCrmId } from '../../utilities/userCrmId';

export const EMPTY_TABLE_ELEMENT_TYPE = 'EmptyTableElement'

const FilterModal = ({ previousFilter, userList, teams, onClose, onSave }) => {
    const PRODUCT_USAGE_OPTIONS = ['High', 'Medium', 'Low', 'No Data']
    const [filterOnUpSell, setFilterOnUpSell] = useState(previousFilter ? previousFilter.filterOnUpSell : true)
    const [filterOnCrossSell, setFilterOnCrossSell] = useState(previousFilter ? previousFilter.filterOnCrossSell : true)

    const [filterOnProductUsage, setFilterOnProductUsage] = useState(previousFilter ? previousFilter.filterOnProductUsage : null)
    const [filterOnAccountOwners, setFilterOnAccountOwners] = useState(previousFilter ? previousFilter.filterOnAccountOwners : null)

    const [orderByUpSellPotential, setOrderByUpSellPotential] = useState(previousFilter ? previousFilter.orderByUpSellPotential : null)
    const [orderByCrossSellPotential, setOrderByCrossSellPotential] = useState(previousFilter ? previousFilter.orderByCrossSellPotential : null)

    let disableSave = false
    if (!filterOnUpSell && !filterOnCrossSell) {
        disableSave = true
    }
    if (Array.isArray(filterOnProductUsage) && !filterOnProductUsage.length) {
        disableSave = true
    }

    if (Array.isArray(filterOnAccountOwners) && !filterOnAccountOwners.length) {
        disableSave = true
    }
    const usersListWithCRMIds = userList.map(el => ({
        ...el,
        crmId: getUserCrmId(el)
    })).filter(el => !!el.crmId)

    return (
        <Modal
            header='Filter and Sort Data'
            onClose={onClose}
            buttons={
                <>
                    <Button
                        fullWidth
                        disabled={disableSave}
                        onClick={() => {
                            onSave({
                                filterOnUpSell,
                                filterOnCrossSell,
                                filterOnProductUsage,
                                filterOnAccountOwners,
                                orderByUpSellPotential,
                                orderByCrossSellPotential
                            })
                        }}
                    >
                        Save
                    </Button>
                    <Button fullWidth onClick={onClose}>
                        Close
                    </Button>
                </>
            }
        >
            <div className={cn.filterWrapper}>
                <div className={cn.section}>
                    <div className={cn.label}>
                        Type Of Expansion Revenue
                    </div>
                    <Checkbox label='Up Sell' checked={filterOnUpSell} onClick={() => setFilterOnUpSell(!filterOnUpSell)} />
                    <Checkbox label='Cross Sell' checked={filterOnCrossSell} onClick={() => setFilterOnCrossSell(!filterOnCrossSell)} />
                </div>
                <div className={cn.section}>
                    <div className={cn.label}>
                        Product Usage
                    </div>
                    <Checkbox
                        label='All'
                        checked={filterOnProductUsage === null || filterOnProductUsage?.length === PRODUCT_USAGE_OPTIONS.length}
                        onClick={() => setFilterOnProductUsage(filterOnProductUsage === null || filterOnProductUsage?.length === PRODUCT_USAGE_OPTIONS.length ? [] : null)}
                    />
                    {PRODUCT_USAGE_OPTIONS.map(el => (
                        <Checkbox
                            label={el}
                            checked={filterOnProductUsage === null || filterOnProductUsage?.includes(el)}
                            onClick={() => {
                                const checked = filterOnProductUsage === null || filterOnProductUsage?.includes(el)
                                if (checked) {
                                    if (filterOnProductUsage === null) {
                                        setFilterOnProductUsage(PRODUCT_USAGE_OPTIONS.filter(internalEl => internalEl !== el))
                                    } else {
                                        setFilterOnProductUsage(filterOnProductUsage.filter(internalEl => internalEl !== el))
                                    }
                                } else {
                                    setFilterOnProductUsage([...filterOnProductUsage, el])
                                }
                            }}
                        />
                    ))}
                </div>
                <div className={cn.section}>
                    <div className={cn.label}>
                        {`${translateCRMLabel('Account')} Owners`}
                    </div>
                    <Checkbox
                        label={'All'}
                        checked={filterOnAccountOwners === null || filterOnAccountOwners?.length === usersListWithCRMIds?.length}
                        onClick={() => {
                            setFilterOnAccountOwners(filterOnAccountOwners === null || filterOnAccountOwners?.length === usersListWithCRMIds?.length ? [] : null)
                        }}
                    />
                    {teams.map(team => {
                        const teamUserIds = team.users.map(el => getUserCrmId(el)).filter(el => !!el)
                        const checked = !filterOnAccountOwners || teamUserIds?.filter(accountOwnerId => filterOnAccountOwners?.includes(accountOwnerId))?.length === teamUserIds.length
                        if (!teamUserIds.length) {
                            return null
                        }
                        return (
                            <Checkbox
                                label={team.name}
                                checked={checked}
                                onClick={() => {
                                    if (checked) {
                                        setFilterOnAccountOwners(filterOnAccountOwners.filter(crmId => !teamUserIds.includes(crmId)))
                                    } else {
                                        setFilterOnAccountOwners([...(filterOnAccountOwners || []), ...teamUserIds])
                                    }
                                }}
                            />
                        )
                    }).filter(el => !!el)}
                    {usersListWithCRMIds.map(el => {
                        return (
                            <Checkbox
                                label={el.fullName || el.email}
                                checked={filterOnAccountOwners === null || filterOnAccountOwners?.includes(el.crmId)}
                                onClick={() => {
                                    const checked = filterOnAccountOwners === null || filterOnAccountOwners?.includes(el.crmId)
                                    if (checked) {
                                        if (filterOnAccountOwners === null) {
                                            setFilterOnAccountOwners(usersListWithCRMIds.map(el => el.crmId).filter(internalEl => internalEl !== el.crmId))
                                        } else {
                                            setFilterOnAccountOwners(filterOnAccountOwners.filter(internalEl => internalEl !== el.crmId))
                                        }
                                    } else {
                                        setFilterOnAccountOwners([...filterOnAccountOwners, el.crmId])
                                    }
                                }}
                            />
                        )
                    })}
                </div>
                <div className={cn.section}>
                    <div className={cn.label}>
                        Order By
                    </div>
                    <Radio
                        label={'Realized Up Sell Potential'}
                        checked={orderByUpSellPotential === true}
                        onClick={() => {
                            setOrderByUpSellPotential(orderByUpSellPotential === true ? null : true)
                            setOrderByCrossSellPotential(null)
                        }}
                    />
                    <Radio
                        label={'Unrealized Up Sell Potential'}
                        checked={orderByUpSellPotential === false}
                        onClick={() => {
                            setOrderByUpSellPotential(orderByUpSellPotential === false ? null : false)
                            setOrderByCrossSellPotential(null)
                        }}
                    />
                    <Radio
                        label={'Realized Cross Sell Potential'}
                        checked={orderByCrossSellPotential === true}
                        onClick={() => {
                            setOrderByCrossSellPotential(orderByCrossSellPotential === true ? null : true)
                            setOrderByUpSellPotential(null)
                        }}
                    />
                    <Radio
                        label={'Unrealized Cross Sell Potential'}
                        checked={orderByCrossSellPotential === false}
                        onClick={() => {
                            setOrderByCrossSellPotential(orderByCrossSellPotential === false ? null : false)
                            setOrderByUpSellPotential(null)
                        }}
                    />
                </div>
            </div>
        </Modal>
    )
}

const OpportunitiesCurrent = ({ opportunities }) => {
    return (
        <div className={cn.opportunitiesCell}>
            {!!opportunities.length ? opportunities.map((opp) => (
                <Link to={`/opportunities-management/edit/${opp.Id}`} className={opp.IsClosed ? `${cn.closed} ${cn.opportunity}` : cn.opportunity}>
                    <div
                        className={cn.oppCurrent}
                        data-tooltip-id='default'
                        data-tooltip-content={`${opp.Name}${opp.StageName ? ` - ${opp.StageName}` : ''}${opp.Type ? ` - ${opp.Type}` : ''}${opp.NextStep ? `<br />  ${opp.NextStep}` : ''}${opp.quantity ? `<br />${opp.quantity} licenses` : ''}`}
                    >
                        {formatLargeSums('$', opp.price)}
                    </div>
                </Link>
            )) : <span className={cn.na}>N/A</span>}
        </div>
    )
}

const OpportunitiesCell = ({ data, upsell, crosssell, reloadData }) => {
    const [showEditModal, setShowEditModal] = useState(false)
    const [showRemoveModal, setShowRemoveModal] = useState(false)
    const [showDropdown, setShowDropdown] = useState(false)
    const [reason, setReason] = useState()
    const [value, setValue] = useState()

    let dataTip = 'The estimate is based on the detected number of buyer personas at the company and the price of the product'
    dataTip = data.potentialBuyers ? `${dataTip}.<br/>We have not been able to find buying personas at the Account.` : dataTip

    const closeModal = () => {
        setShowEditModal(false)
        setShowRemoveModal(false)
    }

    const resetOverride = () => {
        makeRequest.post(`${URLS.main}/global-white-space-planning-override`, {
            accountId: data.accountId,
            productId: data.productId,
            type: upsell ? 'UP_SELL' : crosssell ? 'CROSS_SELL' : null,
            reset: true,
        })
            .then(reloadData)
    }

    const manualOverride = () => {
        makeRequest.post(`${URLS.main}/global-white-space-planning-override`, {
            accountId: data.accountId,
            productId: data.productId,
            value: value || 0,
            reason: reason,
            type: upsell ? 'UP_SELL' : crosssell ? 'CROSS_SELL' : null
        })
            .then(reloadData)
        setReason(undefined)
        setValue(undefined)
        closeModal()
    }

    let potential = <span className={cn.na}>N/A</span>
    if (crosssell && !!data.potentialCrossSell) {
        potential = formatLargeSums('$', data.potentialCrossSell)
    }
    if (upsell && !!data.potentialUpsell) {
        potential = formatLargeSums('$', data.potentialUpsell)
    }

    if (data.override) {
        if (!!data.override.value) {
            potential = formatLargeSums('$', data.override.value)
        }
        dataTip = `${data.override.reason}<br/>${data.override.user} updated on ${moment(data.override.timestamp).format('MMMM DD, YYYY hh:mm a')}`
    }

    return (
        <>
            <div
                className={cn.opportunitiesCell}
                onClick={() => setShowDropdown(!showDropdown)}
            >
                <div className={cn.potentialLabel}>
                    {crosssell && 'Cross Sell'}
                    {upsell && 'Up Sell'}
                </div>
                <div className={`${cn.potential} ${data?.override ? cn.override : ''} ${!!data?.override && !data?.override?.value ? cn.strikeThrough : ''}`} data-tooltip-id='default' data-tooltip-content={dataTip}>
                    <span>
                        {potential}
                    </span>
                    <TriangleDown />
                </div>
                {showDropdown && <WhiteSpaceDropdown
                    options={[
                        data.override?.value !== 0 ? { value: 'REMOVE', label: 'Remove from the calculation' } : null,
                        { value: 'UPDATE', label: 'Set manual value' },
                        data.override ? { value: 'RESET', label: 'Add back to calculation' } : null,
                    ].filter(el => !!el)}
                    onChange={data => {
                        switch (data) {
                        case 'REMOVE':
                            setShowRemoveModal(true)
                            setShowDropdown(false)
                            break
                        case 'UPDATE':
                            setShowEditModal(true)
                            setShowDropdown(false)
                            break
                        case 'RESET':
                            resetOverride()
                            setShowDropdown(false)
                            break;
                        }
                    }}
                    defaultOpened
                    skipMainComponent
                />}
            </div>
            {(showEditModal || showRemoveModal) && (
                <Modal
                    header={'Indicate the reason for removing'}
                    onClose={closeModal}
                    buttons={
                        <>
                            <Button onClick={manualOverride} fullWidth>
                                Save
                            </Button>
                            <Button onClick={closeModal} fullWidth>
                                Cancel
                            </Button>
                        </>
                    }
                >
                    <Input placeholder='Explain the reason (optional)' fullWidth value={reason} onChange={e => setReason(e.target.value)} />
                    <br />
                    {showEditModal && <Input type='number' placeholder='Amount' fullWidth value={value} onChange={e => setValue(e.target.value)} />}
                </Modal>
            )}
        </>
    )
}

const MENU_ID = `MENU_ID_`

export const GlobalWhitespacePlanning = () => {
    const [tableData, setTableData] = useState([])
    const [showRequestedUpdateWarning, setShowRequestedUpdateWarning] = useState(false)
    const [loaded, setLoaded] = useState(false)
    const [totalPages, setTotalPages] = useState(0)
    const [totalNumberOfAccounts, setTotalNumberOfAccounts] = useState(0)
    const [userList, setUserList] = useState([]);
    const [teams, setTeams] = useState([]);
    const [lastCachedDate, setLastCachedDate] = useState();
    const [upSellPotential, setUpSellPotential] = useState();
    const [crossSellPotential, setCrossSellPotential] = useState();
    const [filterModalShown, setFilterModalShown] = useState(false)
    const [previousFilter, setPreviousFilter] = useState()
    const [customColumnOptions, setCustomColumnOptions] = useState([])
    const [showUsage, setShowUsage] = useState()

    const { page: routePage } = useParams()
    const page = parseInt(routePage) || 0;

    const requestUpdate = () => {
        setTableData([])
        makeRequest.get(`${URLS.main}/global-white-space-planning/break-cache`)
            .then(() => loadTable(page, previousFilter))
        setShowRequestedUpdateWarning(false)
    }

    const updateTableEdits = (data, columnIndex, add) => {
        const toBeSubmitted = []
        const parsable = data || tableData

        if (columnIndex && !add) {
            makeRequest.post(`${URLS.main}/global-white-space-planning`, {
                deletedColumn: columnIndex
            })
        } else {
            parsable.forEach((row, rowIndex) => {
                row.forEach((cell, columnIndex) => {
                    const customColumns = [
                        EMPTY_TABLE_ELEMENT_TYPE,
                        'Dropdown',
                        'Checkbox'
                    ]
                    if (customColumns.includes(cell.type) && (rowIndex || columnIndex)) {
                        toBeSubmitted.push({
                            columnIndex,
                            value: cell?.data?.value || cell.data || null,
                            accountId: row[0].externalId,
                            customColumnId: cell?.customColumnId || cell.data?.customColumnId,
                            type: cell.type
                        })
                    }
                })
            })
            makeRequest.post(`${URLS.main}/global-white-space-planning`, {
                data: toBeSubmitted,
                columnToBeAdded: add ? columnIndex : null
            })
        }
    }

    const addColumn = (columnIndex, data) => {
        const type = data?.type || EMPTY_TABLE_ELEMENT_TYPE
        const newData = tableData.map((row, rowIndex) => {
            let cellData;
            if (data?.type === 'Dropdown' && rowIndex !== 0) {
                cellData = {
                    options: (data.options?.split('\n') || []).map((el) => ({
                        label: el,
                        value: el,
                    }))
                }
            } else if (data?.type === 'Checkbox' && rowIndex !== 0) {
                cellData = {
                    value: false
                }
            } else if (data?.type) {
                cellData = data.label
            } else {
                cellData = ''
            }
            const updatedRow = row.slice(0)

            updatedRow.splice(columnIndex + 1, 0, {
                type,
                options: data?.options,
                data: cellData,
                customColumn: customColumnOptions.find(el => el.id === data?.id),
                customColumnId: data?.id,
            })
            return updatedRow
        })
        setTableData(newData)
        updateTableEditsDebounce(newData, columnIndex, true)
    }

    const deleteColumn = (columnIndex, data) => {
        const parsable = data || tableData
        const newData = parsable.map(row => {
            return row.filter((_el, i) => i !== columnIndex)
        })
        setTableData(newData)
        updateTableEditsDebounce(newData, columnIndex)
    }

    const updateTableEditsDebounce = useCallback(
        debounce(updateTableEdits, 250),
        []
    );

    const loadTable = (page, filter) => {
        setTableData([])
        makeRequest.get(`${URLS.main}/global-white-space-planning/${page}`, {
            headers: {
                filter: JSON.stringify(filter)
            }
        })
            .then(res => {
                setTableData(res.data.tableData)
                setTotalPages(res.data.totalPages)
                setTotalNumberOfAccounts(res.data.totalNumberOfAccounts)
                setLastCachedDate(res.data.lastCache)
                setUpSellPotential(res.data.totalPotentialUpSell)
                setCrossSellPotential(res.data.totalPotentialCrossSell)
            })
    }

    useEffect(() => {
        if (previousFilter !== undefined) {
            loadTable(page, previousFilter)
        }
    }, [previousFilter])

    const loadUsers = () => {
        makeRequest.get(`${URLS.main}/workspace/users`)
            .then(res => {
                if (res && res.data && res.data.success) {
                    setUserList(res.data.users);
                }
            })
            .catch(() => {});
    }

    const loadTeams = () => {
        makeRequest.get(`${URLS.main}/teams`)
            .then(res => {
                if (res && res.data) {
                    setTeams(res.data.teams);
                }
            })
            .catch(() => {});
    }

    const loadCustomColumnOptions = () => {
        makeRequest.get(`${URLS.main}/global-white-space-planning-columns`)
            .then(res => {
                setCustomColumnOptions(res.data)
            })
    }

    useEffect(() => {
        loadUsers()
        loadTeams()
        loadCustomColumnOptions()
    }, [])

    useEffect(() => {
        loadTable(page, previousFilter)
    }, [page])

    useEffect(() => {
        if (tableData?.length) {
            setLoaded(true)
        } else {
            setLoaded(false)
        }
    }, [tableData])

    const additionalStyles = {}

    if (tableData && tableData[1]) {
        tableData[1].forEach((el, i) => {
            if (el) {
                if (['Notes'].includes(el.type)) {
                    additionalStyles[i] = {
                        minWidth: '180px',
                        width: '180px',
                        fixedWidth: '180px'
                    }
                } else if (['Dropdown'].includes(el.type)) {
                    additionalStyles[i] = {
                        minWidth: '140px',
                        width: '140px',
                        fixedWidth: '140px',
                        td: {
                            overflow: 'visible',
                            padding: 0,
                        }
                    }
                } else if (['Opportunities_up_sell', 'Opportunities_cross_sell'].includes(el.type)) {
                    additionalStyles[i] = {
                        minWidth: '180px',
                        width: '180px',
                        fixedWidth: '180px',
                        td: {
                            overflow: 'visible'
                        }
                    }
                }
            }
        })
    }

    // useOutsideClickHandler(inputRef, () => {
    //     restoreParentZIndex()
    //     // inputRef?.current.blur()
    // });

    const increaseParentZIndex = () => {
        // inputRef.current.parentNode.parentNode.style.zIndex = 100000
    }

    const restoreParentZIndex = () => {
        // inputRef.current.parentNode.parentNode.style.zIndex = 10
    }

    const headerContextHandler = (index, event) => {
        const { show } = useContextMenu({
            id: `${MENU_ID}${index}`,
            index,
        });
        // const inputRef = useRef(null);

        event.preventDefault();
        show(event)
        increaseParentZIndex()
    }

    const filterOnUserSelection = data => {
        setPreviousFilter(data)
        hideFilterModal()
    }

    const showFilterModal = () => setFilterModalShown(true)
    const hideFilterModal = () => setFilterModalShown(false)


    const productIds = []
    const products = (tableData[0] || []).filter(el => {
        if (el.externalIdType === 'Product' && !productIds.includes(el.externalId)) {
            productIds.push(el.externalId)
            return true
        }
        return false
    }).map(el => ({ name: el.data.split('<br/>')[0], id: el.externalId }))

    return (
        <>
            <ViewContainer className={cn.wrapper}>
                <UsageGraph
                    shown={!!showUsage}
                    onClose={() => setShowUsage(false)}
                    accountId={showUsage}
                />
                {loaded ? (
                    <>
                        <Row className={cn.selectableConfigSticky} wrap spaceBetween>
                            <Row noMargin className={cn.accountNumberWrapper}>
                                <div>
                                    {totalNumberOfAccounts}
                                </div>
                                <div>
                                    Accounts
                                </div>
                                <div
                                    className={cn.cachedInfo}
                                    data-tooltip-id='default'
                                    data-tooltip-content='This indicates how old the information used for analysis of the risk is. It is refreshed every day. You can also click on it to request immediate update'
                                    onClick={() => setShowRequestedUpdateWarning(true)}
                                >
                                    <b>Last Updated Date</b>
                                    {' '}
                                    <u>{moment(lastCachedDate).format('HH:mm - Do MMMM YYYY')}</u>
                                </div>
                            </Row>
                            <Row noMargin>
                                <div
                                    className={cn.potentials}
                                    data-tooltip-id='default'
                                    data-tooltip-content={`
                                        Up Sell potential ${formatLargeSums('$', upSellPotential)}
                                        <br/>
                                        Cross Sell potential ${formatLargeSums('$', crossSellPotential)}
                                    `}
                                >
                                    <span>
                                        Total summary:
                                    </span>
                                    <span>
                                        {formatLargeSums('$', crossSellPotential + upSellPotential)}
                                    </span>
                                </div>
                                <Button
                                    type={BUTTON_TYPES.ICON}
                                    onClick={showFilterModal}
                                    customIcon={<Filter />}
                                />
                            </Row>
                        </Row>
                        <div className={cn.tableWrapper}>
                            {tableData[0]?.map((el, i) => {
                                const customColumn = ['EmptyTableElement', 'Dropdown', 'Checkbox'].includes(el.type)
                                if (el.data ==='Actions') {
                                    return null
                                }
                                return (
                                    <Menu id={`${MENU_ID}${i}`} className={cn.contextMenu}>
                                        {customColumn && i > 0 && (
                                            <>
                                                <Item
                                                    onClick={() => {
                                                        restoreParentZIndex()
                                                        deleteColumn(i)
                                                    }}
                                                >
                                                    Delete column
                                                </Item>
                                                <Separator />
                                            </>
                                        )}
                                        <Item
                                            onClick={() => {
                                                addColumn(i)
                                                restoreParentZIndex()
                                            }}
                                        >
                                            Add Colum to the right
                                        </Item>
                                        {customColumnOptions.map(el => (
                                            <>
                                                <Separator />
                                                <Item
                                                    onClick={() => {
                                                        addColumn(i, el)
                                                        restoreParentZIndex()
                                                    }}
                                                >
                                                    Add Custom column ({el.label}) to the right
                                                </Item>
                                            </>
                                        ))}
                                    </Menu>
                                )
                            })}
                            <Table
                                noPadding
                                featureName={'global whitespace planning'}
                                resizible
                                saveSizeUpdates
                                className={cn.whitespacingTable}
                                headerContextHandler={headerContextHandler}
                                headers={(tableData[0] || []).map((el, i) => {
                                    if (el.type === 'EmptyTableElement' && i !== 0) {
                                        return <EmptyTableElement
                                            value={el?.data}
                                            columnIndex={i}
                                            rowIndex={0}
                                            onChange={(data, column, row) => {
                                                const updatedTableData = tableData.slice(0)
                                                updatedTableData[row][column].data = data
                                                updateTableEditsDebounce(updatedTableData)
                                                setTableData(updatedTableData)
                                            }}
                                        />
                                    }
                                    return el.data.split('<br/>')
                                })}
                                minWidth={'100px'}
                                customStyles={{
                                    0: {
                                        position: 'sticky',
                                        left: 0,
                                        top: 0,
                                        zIndex: 10
                                    },
                                    [!!tableData[1]?.length ? tableData[0].length - 1 : -1]: {
                                        minWidth: '220px',
                                        maxWidth: '220px',
                                        width: '220px',
                                        fixedWidth: '220px'
                                    },
                                    ...additionalStyles
                                }}
                                data={tableData.slice(1).map((row, rowIndex) => row.map(({ data, type, externalId, dataTip, potentialBuyers }, columnIndex) => {
                                    const accountDetails = {
                                        id: row[0].externalId,
                                        name: row[0].data.split(' (')[0],
                                        expansionData: {
                                            products: [],
                                            additionalData: []
                                        }
                                    }
                                    products.forEach(product => {
                                        const associatedOpps = row.filter(el => el.type === 'Opportunities_current' && el.data.map(el => el.productId).includes(product.id)).map(el => el.data).flat()
                                        const usageData = row.find((el, i) => el.type === 'Usage' && tableData[0][i].externalIdType === 'Product' && tableData[0][i].externalId === product.id)?.data
                                        const upSellPotential = row.find(el => el.type === 'Opportunities_up_sell' && product.id === el.data?.productId)?.data?.potentialUpsell
                                        const crossSellPotential = row.find(el => el.type === 'Opportunities_cross_sell' && product.id === el.data?.productId)?.data?.potentialCrossSell
                                        let usageColor = null

                                        switch (usageData) {
                                        case 1:
                                        case 'RED':
                                            usageColor = 'RED'
                                            break
                                        case 2:
                                        case 'YELLOW':
                                            usageColor = 'YELLOW'
                                            break
                                        case 3:
                                        case 'GREEN':
                                            usageColor = 'GREEN'
                                            break
                                        default:
                                            usageColor = null
                                        }
                                        accountDetails.expansionData.products.push({
                                            ...product,
                                            currentSpend: associatedOpps.map(el => el.price).reduce((a, b) => a + b, 0),
                                            potentialUpsell: upSellPotential,
                                            potentialCrossSell: crossSellPotential,
                                            usageColor,
                                            usageData,
                                        })
                                    })

                                    switch (type) {
                                    case 'Account':
                                        return <Link
                                            data-tooltip-id='default'
                                            data-tooltip-content={dataTip}
                                            key={`${rowIndex} ${columnIndex} ${externalId}`}
                                            to={`/account/${externalId}`}
                                        >
                                            {data}
                                        </Link>
                                    case 'Usage':
                                        return <ProductUsageElement dataTip={dataTip} data={data} />
                                    case 'Actions':
                                        return <AccountActions
                                            noTooltip
                                            account={accountDetails}
                                            onDelete={requestUpdate}
                                            onShowUsage={() => setShowUsage(externalId)}
                                        />
                                    case 'Opportunities_current':
                                        return <OpportunitiesCurrent
                                            key={`${rowIndex} ${columnIndex} ${externalId}`}
                                            opportunities={data}
                                            dataTip={dataTip}
                                            potentialBuyers={potentialBuyers}
                                        />
                                    case 'Opportunities_up_sell':
                                        return <OpportunitiesCell
                                            reloadData={() => loadTable(page, previousFilter)}
                                            key={`${rowIndex} ${columnIndex} ${externalId}`}
                                            data={data}
                                            dataTip={dataTip}
                                            upsell={true}
                                            potentialBuyers={potentialBuyers}
                                        />
                                    case 'Opportunities_cross_sell':
                                        return <OpportunitiesCell
                                            reloadData={() => loadTable(page, previousFilter)}
                                            key={`${rowIndex} ${columnIndex} ${externalId}`}
                                            data={data}
                                            dataTip={dataTip}
                                            crosssell={true}
                                            potentialBuyers={potentialBuyers}
                                        />
                                    case 'Notes':
                                        return <NoteCell key={`${rowIndex} ${columnIndex} ${externalId}`} problems={data} />
                                    case 'Checkbox':
                                        return <WhiteSpaceCheckbox
                                            // eslint-disable-next-line eqeqeq
                                            checked={data?.value == 1}
                                            onClick={() => {
                                                const updatedTableData = tableData.slice(0)
                                                updatedTableData[rowIndex + 1][columnIndex].data.value = ![1, '1', true].includes(updatedTableData[rowIndex + 1][columnIndex].data?.value)
                                                updateTableEditsDebounce(updatedTableData)
                                                setTableData(updatedTableData)
                                            }}
                                        />
                                    case 'Dropdown':
                                        return <WhiteSpaceDropdown
                                            options={data.options}
                                            value={data.value}
                                            onChange={(newValue) => {
                                                const updatedTableData = tableData.slice(0)
                                                updatedTableData[rowIndex + 1][columnIndex].data.value = newValue
                                                updateTableEditsDebounce(updatedTableData)
                                                setTableData(updatedTableData)
                                            }}
                                        />
                                    case EMPTY_TABLE_ELEMENT_TYPE:
                                        return <EmptyTableElement
                                            value={data?.value}
                                            columnIndex={columnIndex}
                                            rowIndex={rowIndex + 1}
                                            addColumn={() => addColumn(tableData.slice(0), columnIndex)}
                                            deleteColumn={() => deleteColumn(tableData.slice(0), columnIndex)}
                                            onChange={(data, column, row) => {
                                                const updatedTableData = tableData.slice(0)
                                                updatedTableData[row][column].data = data
                                                updateTableEditsDebounce(updatedTableData)
                                                setTableData(updatedTableData)
                                            }}
                                        />
                                    default:
                                        return data
                                    }
                                }))}
                            />
                        </div>
                        <br />
                    </>
                ) : (
                    <Loader type={LOADER_TYPES.SPINNER} size={LOADER_SIZES.BIG} />
                )}
                {showRequestedUpdateWarning && (
                    <Modal
                        onClose={() => setShowRequestedUpdateWarning(false)}
                        header="Request immediate update"
                        buttons={(
                            <>
                                <Button
                                    fullWidth
                                    onClick={requestUpdate}
                                >
                                    Yes
                                </Button>
                                <Button
                                    fullWidth
                                    onClick={() => setShowRequestedUpdateWarning(false)}
                                >
                                    No
                                </Button>
                            </>
                        )}
                    >
                        Are you sure you want to request immediate update?
                        <br /><br />
                        If this is done too many time per day, it might use up the API requests to your CRM, which could lead to deteriorated performance and problems.
                    </Modal>
                )}
            </ViewContainer>
            {filterModalShown && (
                <FilterModal
                    previousFilter={previousFilter}
                    onClose={hideFilterModal}
                    userList={userList}
                    teams={teams}
                    onSave={filterOnUserSelection}
                />
            )}
            {!!loaded && <Pagination pages={totalPages} />}
        </>
    )
}