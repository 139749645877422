import React, { useEffect, useState, useCallback } from 'react'
import { connect } from 'react-redux';
import { Link, useParams } from 'react-router-dom'
import { Item, Menu, Separator, useContextMenu } from 'react-contexify';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment'
import debounce from 'lodash.debounce'
import 'react-contexify/dist/ReactContexify.css';
import { faChartColumn, faCheckToSlot, faDownload, faQuestion, faTrash, faTrashAlt } from '@fortawesome/free-solid-svg-icons'
import cn from './Risk.module.css'
import { ViewContainer } from '../../components/ViewContainer'
import { Table } from '../../components/Table'
import { Pagination } from '../../components/Pagination'
import { ROW_END_SPACINGS, ROW_SPACINGS, Row } from '../../components/Row'
import { MODAL_SIZES, Modal } from '../../components/Modal'
import { BUTTON_TYPES, Button } from '../../components/Button'
import { makeRequest } from '../../utilities/endpoints'
import { URLS } from '../../config'
import { formatLargeSums } from '../../utilities/currency'
import { LOADER_SIZES, LOADER_TYPES, Loader } from '../../components/Loader'
import { Checkbox } from '../../components/Checkbox';
import { Filter } from '../../components/Filter';
import { Radio } from '../../components/Radio';
import { Dropdown } from '../../components/Dropdown';
import { Label } from '../../components/Label';
import { SFDCObjSelector } from '../../components/SFDCObjSelector';
import { Input } from '../../components/Input';
import { NBSP_SIZES, Nbsp } from '../../components/Nbsp';
import { TextArea } from '../../components/TextArea';
import { HealthScoreConnected } from '../../components/HealthScore';
import { ProductUsageElement } from '../../components/ProductUsageElement';
import { workspaceTypeCheck } from '../../utilities/workspaceType';
import { UsageGraph } from '../accounts/account/components/UsageGraph';
import { permissionCheck } from '../../utilities/permissionCheck';
import { AccountActions } from '../../components/AccountActions';
import { SubHeader } from '../../components/SubHeader';
import { comparisonOperators } from '../../enums/comparisonOperators';
import { DateTime } from './components/DateTime';
import { DisabledBoolean } from './components/DisabledBoolean';
import { WhiteSpaceDropdown } from '../global-whitespace-planning/components/WhiteSpaceDropdown';
import { WhiteSpaceCheckbox } from '../global-whitespace-planning/components/WhiteSpaceCheckbox';
import { EmptyTableElement } from './components/EmptyTableElement';
import { NoteCell } from './components/NoteCell';
import { translateCRMLabel } from '../../utilities/translateCRMLabels';
import store from '../../store';
import { addGlobalError } from "../../store/actions";
import { environmentCheck } from '../../utilities/environmentCheck';
import { randInRange } from '../../utilities/random';
import { SelectWrapperConnected } from '../../components/SelectWrapper';
import { Tabs } from '../../components/Tabs';
import { RiskWorkspaceTrend } from './components/RiskWorkspaceTrend';
import { getUserCrmId } from '../../utilities/userCrmId';


const TREND_TIME_PERIODS = [
    { value: '30_DAYS', label: 'Last 30 Days' },
    { value: '2_MONTHS', label: 'Last 2 Months' },
    { value: '3_MONTHS', label: 'Last 3 Months' },
    { value: '6_MONTHS', label: 'Last 6 Months' },
    { value: 'YEAR', label: 'Last Year' },
]

const TIME_FORMATS = [
    { value: 'DAILY', label: 'Daily' },
    { value: 'WEEKLY', label: 'Weekly' },
    { value: 'MONTHLY', label: 'Monthly' },
    { value: 'QUARTERLY', label: 'Quarterly' },
    { value: 'YEAR', label: 'Yearly' },
]

export const EMPTY_TABLE_ELEMENT_TYPE = 'EmptyTableElement'

const manualColumnOptions = [
    { label: 'Checkbox', value: 'Checkbox' },
    { label: 'Dropdown', value: 'Dropdown' },
]

const callypsoColumnOptions = [
    { label: 'Meetings', value: 'MEETINGS' },
    { label: `${translateCRMLabel('Opportunity')} Expiring`, value: 'EXPIRING_OPPORTUNITY' },
    { label: `${translateCRMLabel('Opportunity')} Stalled`, value: 'STALLED_OPPORTUNITY' },
].filter(el => {
    if (workspaceTypeCheck('[HUBSPOT')) {
        return el.value !== 'EXPIRING_OPPORTUNITY'
    }
    return true
})

const productUsageColumnOptions = [
    { label: 'Overall Product Usage', value: 'PRODUCT_USAGE' },
]

const FilterModal = ({ previousFilter, userList, onClose, onSave, columns, teams }) => {
    const [filterOnGreen, setFilterOnGreen] = useState(previousFilter ? previousFilter.filterOnGreen : true)
    const [filterOnYellow, setFilterOnYellow] = useState(previousFilter ? previousFilter.filterOnYellow : true)
    const [filterOnRed, setFilterOnRed] = useState(previousFilter ? previousFilter.filterOnRed : true)
    const [filterOnAccountOwners, setFilterOnAccountOwners] = useState(previousFilter ? previousFilter.filterOnAccountOwners : null)

    const [orderBy, setOrderBy] = useState(previousFilter ? previousFilter.orderBy : null)

    let disableSave = false
    if (!filterOnGreen && !filterOnYellow && !filterOnRed) {
        disableSave = true
    }

    if (Array.isArray(filterOnAccountOwners) && !filterOnAccountOwners.length) {
        disableSave = true
    }
    const usersListWithCRMIds = userList.map(el => ({
        ...el,
        crmId: getUserCrmId(el)
    })).filter(el => !!el.crmId)

    return (
        <Modal
            header='Filter and Sort Data'
            onClose={onClose}
            buttons={
                <>
                    <Button
                        disabled={disableSave}
                        fullWidth
                        onClick={() => {
                            onSave({
                                filterOnGreen: filterOnGreen,
                                filterOnYellow: filterOnYellow,
                                filterOnRed: filterOnRed,
                                filterOnAccountOwners,
                                orderBy,
                            })
                        }}
                    >
                        Save
                    </Button>
                    <Button onClick={onClose} fullWidth type={BUTTON_TYPES.TRANSPARENT}>
                        Close
                    </Button>
                </>
            }
        >
            <div className={cn.filterWrapper}>
                <div className={cn.section}>
                    <div className={cn.label}>
                        Type of overall score
                    </div>
                    <Checkbox label='Green' checked={filterOnGreen} onClick={() => setFilterOnGreen(!filterOnGreen)} />
                    <Checkbox label='Yellow' checked={filterOnYellow} onClick={() => setFilterOnYellow(!filterOnYellow)} />
                    <Checkbox label='Red' checked={filterOnRed} onClick={() => setFilterOnRed(!filterOnRed)} />
                </div>
                <div className={cn.section}>
                    <div className={cn.label}>
                        {`${translateCRMLabel('Account')} Owners`}
                    </div>
                    <Checkbox
                        label={'All'}
                        checked={filterOnAccountOwners === null || filterOnAccountOwners?.length === usersListWithCRMIds?.length}
                        onClick={() => {
                            setFilterOnAccountOwners(filterOnAccountOwners === null || filterOnAccountOwners?.length === usersListWithCRMIds?.length ? [] : null)
                        }}
                    />
                    {teams.map(team => {
                        const teamUserIds = team.users.map(el => getUserCrmId(el)).filter(el => !!el)
                        const checked = !filterOnAccountOwners || teamUserIds?.filter(accountOwnerId => filterOnAccountOwners?.includes(accountOwnerId))?.length === teamUserIds.length
                        if (!teamUserIds.length) {
                            return null
                        }
                        return (
                            <Checkbox
                                label={team.name}
                                checked={checked}
                                onClick={() => {
                                    if (checked) {
                                        setFilterOnAccountOwners(filterOnAccountOwners.filter(crmId => !teamUserIds.includes(crmId)))
                                    } else {
                                        setFilterOnAccountOwners([...(filterOnAccountOwners || []), ...teamUserIds])
                                    }
                                }}
                            />
                        )
                    }).filter(el => !!el)}
                    {usersListWithCRMIds.map(el => {
                        return (
                            <Checkbox
                                label={el.fullName || el.email}
                                checked={filterOnAccountOwners === null || filterOnAccountOwners?.includes(el.crmId)}
                                onClick={() => {
                                    const checked = filterOnAccountOwners === null || filterOnAccountOwners?.includes(el.crmId)
                                    if (checked) {
                                        if (filterOnAccountOwners === null) {
                                            setFilterOnAccountOwners(usersListWithCRMIds.map(el => el.crmId).filter(internalEl => internalEl !== el.crmId))
                                        } else {
                                            setFilterOnAccountOwners(filterOnAccountOwners.filter(internalEl => internalEl !== el.crmId))
                                        }
                                    } else {
                                        setFilterOnAccountOwners([...filterOnAccountOwners, el.crmId])
                                    }
                                }}
                            />
                        )
                    })}
                </div>
                <div className={`${cn.section} ${cn.fullWidth}`}>
                    <div className={cn.label}>
                        Order By
                    </div>
                    {
                        columns.map(el => (
                            <Radio
                                label={el.data.replace('<br/>', ' ')}
                                checked={orderBy === el.customColumnId}
                                onClick={() => {
                                    setOrderBy(orderBy === el.customColumnId ? null : el.customColumnId)
                                }}
                            />
                        ))
                    }
                </div>
            </div>
        </Modal>
    )
}

const MENU_ID = `MENU_ID_`

const relationshipNames = {}

const standardRelationshipNames = {
    Opportunity: 'Opportunities',
    Contact: 'Contacts',
}

const AddModalMiddlePart = ({
    columnToBeAdded,
    setColumnToBeAdded,
    objectsFields,
    possibleObjects,
    getObjectProperties,
    products,
    setComparisons,
    csvUploadedData,
    checkForManualCSVUploads,
    onClose
}) => {
    const selectedObjectFields = objectsFields[columnToBeAdded?.objectName?.value]

    const [attachmentsData, setAttachmentsData] = useState()
    const [attachments, setAttachments] = useState()
    const [uploadLabel, setUploadLabel] = useState()
    const [showLoader, setShowLoader] = useState(false)

    const additionalAccountFormatOptions = [
        {
            label: 'Date',
            value: 'DATE',
        },
        {
            label: 'Date Time',
            value: 'DATE_TIME',
        },
        {
            label: 'Yes/No',
            value: 'YES_NO',
        },
        {
            label: 'Multiple',
            value: 'MULTIPLE',
        },
    ]

    const additionalObjectFormatOptions = [
        {
            label: 'Yes/No',
            value: 'YES_NO',
        },
        {
            label: 'Multiple',
            value: 'MULTIPLE',
        },
    ]


    const [zendeskStatusesToFilterOn, setZendeskStatusesToFilterOn] = useState()
    const [zendeskPrioritiesToFilterOn, setZendeskPrioritiesToFilterOn] = useState()
    const zendeskAdditionalPrioritiesToFilterOn = [
        { value: 'CREATED_30_DAYS', label: 'Created last 30 days' },
        { value: 'CREATED_3_MONTHS', label: 'Created last 3 months' },
        { value: 'CREATED_6_MONTHS', label: 'Created last 6 months' },

        { value: 'UPDATED_30_DAYS', label: 'Updated last 30 days' },
        { value: 'UPDATED_3_MONTHS', label: 'Updated last 3 months' },
        { value: 'UPDATED_6_MONTHS', label: 'Updated last 6 months' },
    ]

    const [freshdeskStatusesToFilterOn, setFreshdeskStatusesToFilterOn] = useState()
    const [freshdeskPrioritiesToFilterOn, setFreshdeskPrioritiesToFilterOn] = useState()
    const [freshdeskTypesToFilterOn, setFreshdeskTypesToFilterOn] = useState()

    const getZendeskFilterings = () => {
        makeRequest.get(`${URLS.main}/ticket-statuses`)
            .then(res => {
                setZendeskStatusesToFilterOn(res.data.statuses)
            })
        makeRequest.get(`${URLS.main}/ticket-priorities`)
            .then(res => {
                setZendeskPrioritiesToFilterOn(res.data.priorities)
            })
    }

    const getFreshdeskFilterings =() => {
        makeRequest.get(`${URLS.main}/ticket-statuses`)
            .then(res => {
                setFreshdeskStatusesToFilterOn(res.data.statuses)
            })
        makeRequest.get(`${URLS.main}/ticket-priorities`)
            .then(res => {
                setFreshdeskPrioritiesToFilterOn(res.data.priorities)
            })
        makeRequest.get(`${URLS.main}/ticket-types`)
            .then(res => {
                setFreshdeskTypesToFilterOn(res.data.types)
            })
    }
    useEffect(() => {
        if (
            columnToBeAdded.source === 'ZENDESK'
            && !zendeskStatusesToFilterOn?.length
            && !zendeskPrioritiesToFilterOn?.length
        ) {
            getZendeskFilterings()
        }
        if (
            columnToBeAdded.source === 'FRESHDESK'
            && !freshdeskStatusesToFilterOn?.length
            && !freshdeskPrioritiesToFilterOn?.length
            && !freshdeskTypesToFilterOn?.length
        ) {
            getFreshdeskFilterings()
        }
    }, [columnToBeAdded.source])

    const uploadCSV = () => {
        setShowLoader(true)
        const headers = {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        }
        makeRequest.post(`${URLS.main}/upload/csv`, {
            csvfile: attachments,
            uploadLabel,
        }, headers)
            .then(() => {
                checkForManualCSVUploads()
                    .then(() => {
                        setColumnToBeAdded({
                            source: uploadLabel
                        })
                        setTimeout(() => {
                            setShowLoader(false)
                        }, 1500)
                    })
            })
            .catch(() => {
                store.dispatch(addGlobalError({
                    message: 'There is an issue with uploading the file attachment. Please try with another one or reach for support.',
                }))
                onClose && onClose()
            })
    }

    if (showLoader) {
        return <Loader size={LOADER_SIZES.BIG} type={LOADER_TYPES.SPINNER} />
    }

    const csvUploadedOptions = csvUploadedData && csvUploadedData[columnToBeAdded?.source] ? csvUploadedData[columnToBeAdded?.source].map(el => ({
        value: el,
        label: el,
    })) : []

    switch (columnToBeAdded.source) {
    case 'SALESFORCE':
        return (
            <>
                <SFDCObjSelector
                    additionalAccountFormatValues={additionalAccountFormatOptions}
                    additionalObjectsFormatValues={additionalObjectFormatOptions}
                    paramsObj={{
                        obj: {
                            select: {
                                options: possibleObjects,
                            }
                        },
                        field: {
                            select: {
                                options: selectedObjectFields
                            }
                        }
                    }}
                    objValue={columnToBeAdded.objectName}
                    objOnChange={data => {
                        let additionalFields = {}
                        if (!objectsFields[data.value]) {
                            getObjectProperties(data.value)
                        }
                        if (data.value !== 'Account') {
                            additionalFields = {
                                format: 'SUM'
                            }
                        }
                        setColumnToBeAdded({
                            ...columnToBeAdded,
                            objectName: data,
                            fieldName: null,
                            ...additionalFields,
                            rules: [],
                        })
                    }}
                    fieldValue={columnToBeAdded.fieldName}
                    fieldOnChange={data => setColumnToBeAdded({
                        ...columnToBeAdded,
                        fieldName: data,
                        label: data.label
                    })}
                    formatValue={columnToBeAdded?.format}
                    formatOnChange={e => {
                        if (['MULTIPLE', 'YES_NO'].includes(e.target.value)) {
                            let additionalFields = {}
                            if (columnToBeAdded?.objectName?.value !== 'Account') {
                                additionalFields = {
                                    connectedObjectComparisor: 'SUM'
                                }
                            }
                            setColumnToBeAdded({
                                ...columnToBeAdded,
                                comparisor: 'EQUALS',
                                color: 'RED',
                                format: e.target.value,
                                ...additionalFields
                            })
                            setComparisons([{
                                comparedValue: '',
                                label: '',
                                comparisor: 'EQUALS',
                                color: 'Red'
                            }])
                        } else {
                            setColumnToBeAdded({
                                ...columnToBeAdded,
                                format: e.target.value
                            })
                        }
                    }}
                />
                <hr />
                {columnToBeAdded?.objectName?.value !== 'Account' && workspaceTypeCheck(['SFDC', 'SFDC_SANDBOX']) && (
                    <>
                        <br />
                        <Label>
                            You can filter properties from connected objects to display only what you are interested in
                            <br />
                            <i>i.e. a total sum of only the closed/lost opportunities</i>
                        </Label>
                        <br />
                        {(columnToBeAdded.rules || []).map((el, i) => {
                            const field = selectedObjectFields?.find(innerEl => innerEl?.value === el.fieldName)
                            return (
                                <Row rowSpacing={ROW_SPACINGS.SMALL} endSpacing={ROW_END_SPACINGS.LARGE}>
                                    <Dropdown
                                        className={cn.comparisonDropdown}
                                        onChange={e => {
                                            const immediateField = selectedObjectFields?.find(innerEl => innerEl.value === e.target.value)
                                            const additionalData = {}
                                            if (['datetime', 'date'].includes(immediateField?.type)) {
                                                additionalData.comparisor = 'LAST_ACTIVE_DAY_ONLY'
                                            }
                                            setColumnToBeAdded({
                                                ...columnToBeAdded,
                                                rules: [...columnToBeAdded.rules.map((innerEl, innerIndex) => {
                                                    if (i === innerIndex) {
                                                        return {
                                                            ...innerEl,
                                                            fieldName: e.target.value,
                                                            ...additionalData
                                                        }
                                                    }
                                                    return innerEl
                                                })],
                                            })
                                        }}
                                        value={el.fieldName}
                                        values={selectedObjectFields || []}
                                    />
                                    <Dropdown
                                        className={cn.comparisonDropdown}
                                        onChange={e => {
                                            setColumnToBeAdded({
                                                ...columnToBeAdded,
                                                rules: [...columnToBeAdded.rules.map((innerEl, innerIndex) => {
                                                    if (i === innerIndex) {
                                                        return {
                                                            ...innerEl,
                                                            comparisor: e.target.value
                                                        }
                                                    }
                                                    return innerEl
                                                })]
                                            })
                                        }}
                                        values={(() => {
                                            switch (field?.type) {
                                            case 'datetime':
                                            case 'date':
                                                return [
                                                    { label: 'Last Active Day only', value: 'LAST_ACTIVE_DAY_ONLY' },
                                                    { label: 'Last Month only', value: 'LAST_MONTH_ONLY' },
                                                    { label: 'This Month only', value: 'THIS_MONTH_ONLY' },
                                                    { label: 'Last 30 Days only', value: 'LAST_30_DAYS_ONLY' },
                                                ]
                                            default:
                                                return [
                                                    { label: 'Is exactly', value: comparisonOperators.EXACT_MATCH },
                                                    { label: 'Includes', value: comparisonOperators.INCLUDES },
                                                    { label: 'Different than', value: comparisonOperators.DIFFERENT_THAN },
                                                    { label: 'Starts With', value: comparisonOperators.STARTS_WITH },
                                                    { label: 'Ends With', value: comparisonOperators.ENDS_WITH },
                                                    { label: '>', value: comparisonOperators.BIGGER },
                                                    { label: '>=', value: comparisonOperators.BIGGER_OR_EQUAL },
                                                    { label: '<', value: comparisonOperators.SMALLER },
                                                    { label: '<=', value: comparisonOperators.SMALLER_OR_EQUAL },
                                                ]
                                            }
                                        })()}
                                        value={el.comparisor}
                                    />
                                    {
                                        !(['datetime', 'date'].includes(field?.type)) && (
                                            <Input
                                                placeholder=""
                                                value={el.objectValue}
                                                onChange={e => {
                                                    setColumnToBeAdded({
                                                        ...columnToBeAdded,
                                                        rules: [...columnToBeAdded.rules.map((innerEl, innerIndex) => {
                                                            if (i === innerIndex) {
                                                                return {
                                                                    ...innerEl,
                                                                    objectValue: e.target.value
                                                                }
                                                            }
                                                            return innerEl
                                                        })]
                                                    })
                                                }}
                                            />
                                        )
                                    }
                                    <div
                                        className={cn.deleteIcon}
                                        onClick={() => {
                                            setColumnToBeAdded({
                                                ...columnToBeAdded,
                                                rules: columnToBeAdded.rules.filter((el, innerIndex) => i !== innerIndex)
                                            })
                                        }}
                                    >
                                        <FontAwesomeIcon icon={faTrash} className={cn.deleteIcon} />
                                    </div>
                                </Row>
                            )
                        })}
                        <Button
                            onClick={() => {
                                setColumnToBeAdded({
                                    ...columnToBeAdded,
                                    rules: [...(columnToBeAdded.rules || []), {
                                        objectName: selectedObjectFields && selectedObjectFields[0] ? selectedObjectFields[0].value : 'Id',
                                        comparisor: comparisonOperators.EXACT_MATCH,
                                        objectValue: null
                                    }]
                                })
                            }}
                        >
                            Add a new comparison rule
                        </Button>
                        <br />
                        <br />
                        <hr />
                    </>
                )}

                <Row columnFlex noAligning>
                    <Label className={cn.sourceLabel}>Label (Optional)</Label>
                    <Input
                        fullWidth
                        value={columnToBeAdded.customLabel}
                        onChange={e => setColumnToBeAdded({ ...columnToBeAdded, customLabel: e.target.value })}
                    />
                </Row>
            </>
        )
    case 'MANUAL':
        return <>
            <Row columnFlex noAligning>
                <Label className={cn.sourceLabel}>Label</Label>
                <Input
                    fullWidth
                    value={columnToBeAdded.label}
                    onChange={e => setColumnToBeAdded({ ...columnToBeAdded, label: e.target.value })}
                />
            </Row>
            <Row columnFlex noAligning>
                <Label className={cn.sourceLabel}>Type</Label>
                <Dropdown
                    fullWidth
                    value={columnToBeAdded.format}
                    values={manualColumnOptions}
                    onChange={e => setColumnToBeAdded({
                        ...columnToBeAdded,
                        objectName: e.target.value,
                        fieldName: e.target.value,
                        format: e.target.value
                    })}
                />
            </Row>
            <Row columnFlex noAligning>
                {columnToBeAdded.format === 'Dropdown' && (
                    <>
                        <br />
                        <Label className={cn.sourceLabel}>Options</Label>
                        <TextArea
                            fullWidth
                            value={columnToBeAdded.dropdownOptions}
                            onChange={e => {
                                setColumnToBeAdded({ ...columnToBeAdded, options: e.target.value })
                            }}
                            placeholder={'Place each value on a new line'}
                        />
                    </>
                )}
            </Row>
        </>
    case 'UPLOAD':
        return <>
            <br />
            <div className={cn.csvExplanations}>Upload a .csv file bellow and we'll add it as another potential data source.</div>
            <div className={cn.csvExplanations}>Please make sure you have a column called AccountId or Id that will be used for mapping the data to the account. The check for this is case insensitive.</div>
            <br />
            <Input
                fullWidth
                type='file'
                accept=".csv"
                multiple={false}
                value={attachmentsData}
                onChange={e => {
                    setAttachmentsData(e.target.value)
                    setAttachments(!!e.target?.files?.length ? e.target.files : null)
                }}
            />
            <br />
            <Row columnFlex noAligning>
                <Label>
                    Label for the uploadedData
                </Label>
                <Input
                    fullWidth
                    value={uploadLabel}
                    onChange={e => setUploadLabel(e.target.value)}
                />
            </Row>
            <br />
            <Button
                disabled={!attachments || !attachmentsData || !uploadLabel?.length}
                onClick={uploadCSV}
            >
                Upload
            </Button>
        </>
    case 'CALLYPSO':
        return (
            <Row columnFlex noAligning>
                <Label className={cn.sourceLabel}>Type</Label>
                <Dropdown
                    fullWidth
                    value={columnToBeAdded.objectName}
                    values={callypsoColumnOptions}
                    onChange={e => {
                        setColumnToBeAdded({
                            ...columnToBeAdded,
                            objectName: e.target.value,
                            fieldName: e.target.value,
                            format: e.target.value
                        })
                    }}
                />
            </Row>
        )
    case 'PRODUCT_USAGE':
        return (
            <Row columnFlex noAligning>
                <Label className={cn.sourceLabel}>Product Usage</Label>
                <Dropdown
                    fullWidth
                    value={columnToBeAdded.objectName}
                    values={[...productUsageColumnOptions, ...products.map(el => ({
                        label: el.Name,
                        value: el.Id,
                    }))]}
                    onChange={e => {
                        setColumnToBeAdded({
                            ...columnToBeAdded,
                            fieldName: e.target.value === 'PRODUCT_USAGE' ? 'ALL' : products.find(el => el.Id === e.target.value)?.Name,
                            objectName: e.target.value,
                            format: 'PRODUCT_USAGE'
                        })
                    }}
                />
            </Row>
        )
    case 'ZENDESK':
        return (
            <>
                <Row columnFlex noAligning>
                    <Label>Filter on specific statuses</Label>
                    <SelectWrapperConnected
                        isMulti
                        onChange={data => {
                            setColumnToBeAdded({
                                ...columnToBeAdded,
                                rules: [
                                    ...(columnToBeAdded.rules || []).filter(el => el.fieldName !== 'custom_status_id'),
                                    ...data.map(el => ({
                                        fieldName: 'custom_status_id',
                                        objectValue: el.value
                                    }))
                                ]
                            })
                        }}
                        // eslint-disable-next-line eqeqeq
                        value={columnToBeAdded.rules?.map(el => zendeskStatusesToFilterOn?.find(innerEl => innerEl.value == el.objectValue))}
                        options={zendeskStatusesToFilterOn}
                    />
                </Row>
                <Row columnFlex noAligning>
                    <Label>Filter on specific priorities</Label>
                    <SelectWrapperConnected
                        isMulti
                        options={zendeskPrioritiesToFilterOn}
                        onChange={data => {
                            setColumnToBeAdded({
                                ...columnToBeAdded,
                                rules: [
                                    ...(columnToBeAdded.rules || []).filter(el => el.fieldName !== 'priority'),
                                    ...data.map(el => ({
                                        fieldName: 'priority',
                                        objectValue: el.value
                                    }))
                                ]
                            })
                        }}
                        value={columnToBeAdded.rules?.map(el => zendeskPrioritiesToFilterOn?.find(innerEl => innerEl.value === el.objectValue))}
                    />
                </Row>
                <Row columnFlex noAligning>
                    <Label>Additional filtering</Label>
                    <SelectWrapperConnected
                        isMulti
                        options={zendeskAdditionalPrioritiesToFilterOn}
                        onChange={data => {
                            setColumnToBeAdded({
                                ...columnToBeAdded,
                                rules: [
                                    ...(columnToBeAdded.rules || []).filter(el => el.fieldName !== 'additionalFiltering'),
                                    ...data.map(el => ({
                                        fieldName: 'additionalFiltering',
                                        objectValue: el.value
                                    }))
                                ]
                            })
                        }}
                        value={columnToBeAdded.rules?.map(el => zendeskAdditionalPrioritiesToFilterOn?.find(innerEl => innerEl.value === el.objectValue))}
                    />
                </Row>
                <Row columnFlex noAligning>
                    <Label className={cn.sourceLabel}>Label (Optional)</Label>
                    <Input
                        fullWidth
                        value={columnToBeAdded.customLabel}
                        onChange={e => setColumnToBeAdded({ ...columnToBeAdded, customLabel: e.target.value })}
                    />
                </Row>
            </>
        )
    case 'FRESHDESK':
        return (
            <>
                <Row columnFlex noAligning>
                    <Label>Filter on specific statuses</Label>
                    <SelectWrapperConnected
                        isMulti
                        onChange={data => {
                            setColumnToBeAdded({
                                ...columnToBeAdded,
                                rules: [
                                    ...(columnToBeAdded.rules || []).filter(el => el.fieldName !== 'custom_status_id'),
                                    ...data.map(el => ({
                                        fieldName: 'custom_status_id',
                                        objectValue: el.value
                                    }))
                                ]
                            })
                        }}
                        // eslint-disable-next-line eqeqeq
                        value={columnToBeAdded.rules?.map(el => freshdeskStatusesToFilterOn?.find(innerEl => innerEl.value == el.objectValue))}
                        options={freshdeskStatusesToFilterOn}
                    />
                </Row>
                <Row columnFlex noAligning>
                    <Label>Filter on specific priorities</Label>
                    <SelectWrapperConnected
                        isMulti
                        options={freshdeskPrioritiesToFilterOn}
                        onChange={data => {
                            setColumnToBeAdded({
                                ...columnToBeAdded,
                                rules: [
                                    ...(columnToBeAdded.rules || []).filter(el => el.fieldName !== 'priority'),
                                    ...data.map(el => ({
                                        fieldName: 'priority',
                                        objectValue: el.value
                                    }))
                                ]
                            })
                        }}
                        value={columnToBeAdded.rules?.map(el => freshdeskPrioritiesToFilterOn?.find(innerEl => innerEl.value === el.objectValue))}
                    />
                </Row>
                <Row columnFlex noAligning>
                    <Label>Filter on specific types</Label>
                    <SelectWrapperConnected
                        isMulti
                        options={freshdeskTypesToFilterOn}
                        onChange={data => {
                            setColumnToBeAdded({
                                ...columnToBeAdded,
                                rules: [
                                    ...(columnToBeAdded.rules || []).filter(el => el.fieldName !== 'type'),
                                    ...data.map(el => ({
                                        fieldName: 'type',
                                        objectValue: el.value
                                    }))
                                ]
                            })
                        }}
                        value={columnToBeAdded.rules?.map(el => freshdeskTypesToFilterOn?.find(innerEl => innerEl.value === el.objectValue))}
                    />
                </Row>
                <Row columnFlex noAligning>
                    <Label className={cn.sourceLabel}>Label (Optional)</Label>
                    <Input
                        fullWidth
                        value={columnToBeAdded.customLabel}
                        onChange={e => setColumnToBeAdded({ ...columnToBeAdded, customLabel: e.target.value })}
                    />
                </Row>
            </>
        )
    default:
        if (Object.keys(csvUploadedData).includes(columnToBeAdded.source)) {
            const csvDropdownFormatting = [
                { label: 'Sum of all values', value: 'SUM' },
                { label: 'Count of the objects', value: 'COUNT' },
                { label: 'Comma separated values', value: 'CSV' },
                ...additionalAccountFormatOptions
            ]


            return <>
                <Row columnFlex noAligning>
                    <Label className={cn.sourceLabel}>Field To Add</Label>
                    <SelectWrapperConnected
                        className={cn.ruleDropdown}
                        onChange={data => {
                            const obj = {
                                ...columnToBeAdded,
                                objectName: 'CSV_UPLOAD',
                                fieldName: data,
                                label: data.label,
                            }
                            if (!columnToBeAdded.format || columnToBeAdded.format === 'NUMBER') {
                                // number is added as a check just because it is the default one
                                obj.format = 'SUM'
                            }
                            setColumnToBeAdded(obj)
                        }}
                        options={csvUploadedOptions}
                        value={csvUploadedOptions.find(el => el.value === (columnToBeAdded.fieldName || columnToBeAdded?.label))}
                    />
                </Row>

                <Row columnFlex noAligning>
                    <Label className={cn.sourceLabel}>Format as</Label>
                    <Dropdown
                        fullWidth
                        values={csvDropdownFormatting}
                        value={columnToBeAdded?.format}
                        onChange={e => {
                            if (['MULTIPLE', 'YES_NO'].includes(e.target.value)) {
                                let additionalFields = {}
                                if (columnToBeAdded?.objectName?.value !== 'Account') {
                                    additionalFields = {
                                        connectedObjectComparisor: 'SUM'
                                    }
                                }
                                setColumnToBeAdded({
                                    ...columnToBeAdded,
                                    comparisor: 'EQUALS',
                                    color: 'RED',
                                    format: e.target.value,
                                    ...additionalFields
                                })
                                setComparisons([{
                                    comparedValue: '',
                                    label: '',
                                    comparisor: 'EQUALS',
                                    color: 'Red'
                                }])
                            } else {
                                setColumnToBeAdded({
                                    ...columnToBeAdded,
                                    format: e.target.value
                                })
                            }
                        }}
                    />
                </Row>
            </>
        }
        return null
    }
}

const AddColumnModal = ({
    onClose,
    onAdd,
    edit,
    onEdit,
    zendeskConnected,
    freshdeskConnected,
    csvUploadedData,
    checkForManualCSVUploads
}) => {
    const [comparisons, setComparisons] = useState(!!edit ? edit.customColumnComparisons : [{}])
    const [objectsFields, setObjectsFields] = useState({})
    const [products, setProducts] = useState([])
    const [possibleObjects, setPossibleObjects] = useState([
        { label: translateCRMLabel('Account'), value: 'Account' },
        { label: 'Contact', value: 'Contact' },
        { label: translateCRMLabel('Opportunity'), value: 'Opportunity' },
    ])

    const [objectName, setObjectName] = useState(!!edit ? possibleObjects?.find(el => el.value === edit.column.object) : {
        label: translateCRMLabel('Account'),
        value: 'Account'
    })

    const updateColumnToBeAdded = () => {
        return {
            source: !!edit ? edit.column.source : 'SALESFORCE',
            format: !!edit ? edit.column.format : 'NUMBER',
            objectName,
            fieldName: null,
            rules: !!edit ? edit.rules : undefined,
            label: !!edit ? edit.column.label : null,
            customLabel: !!edit ? edit.column.customLabel : null,
            dropdownOptions: !!edit && edit.column.format === 'Dropdown' ? edit.column.dropdownOptions : null
        }
    }

    useEffect(() => {
        setColumnToBeAdded({
            ...updateColumnToBeAdded(),
            fieldName: columnToBeAdded.fieldName
        })
    }, [objectName])

    const [columnToBeAdded, setColumnToBeAdded] = useState(updateColumnToBeAdded())

    useEffect(() => {
        if (!!edit && Object.keys(objectsFields).length) {
            setColumnToBeAdded({
                ...columnToBeAdded,
                fieldName: objectsFields[possibleObjects?.find(el => el.value === edit.column.object || el.value === edit.column.relationshipName)?.value]?.find(el => el.value === edit.column.field)
            })
        }
    }, [objectsFields, edit])

    useEffect(() => {
        makeRequest.get(`${URLS.main}/products`)
            .then((res) => {
                setProducts(res.data)
            }).catch(error => {
                console.error(error);
            });
    }, [])


    const getObjectProperties = objectName => {
        makeRequest.get(`${URLS.main}/describe/${objectName}`)
            .then(res => {
                if (res && res.status === 200) {
                    if (objectName === 'Account') {
                        setPossibleObjects([...possibleObjects, ...(res.data.objects || []).map(el => ({
                            label: el.label,
                            value: el.name
                        }))])
                        res.data.objects?.forEach(el => {
                            relationshipNames[el.name] = el.relationshipName
                        })
                        const objectsFieldsCopy = JSON.parse(JSON.stringify(objectsFields))
                        res.data.objects?.forEach(el => {
                            objectsFieldsCopy[el.name] = el.fields
                        })
                        objectsFieldsCopy.Account = res.data.fields

                        setObjectsFields(objectsFieldsCopy)
                    } else {
                        const newPossibleObjects = [...possibleObjects, {
                            label: workspaceTypeCheck(['HUBSPOT']) ? edit?.column?.object : objectName,
                            value: objectName
                        }]
                        if (!possibleObjects.find(el => el.value === objectName)) {
                            setPossibleObjects(newPossibleObjects)
                        }
                        setObjectsFields({
                            ...objectsFields,
                            [objectName]: res.data
                        })
                        if (!!edit && newPossibleObjects.find(el => el.value === edit.column.object || el.value === edit.column.relationshipName)) {
                            setObjectName(newPossibleObjects.find(el => el.value === edit.column.object || el.value === edit.column.relationshipName))
                        }
                    }
                }
            })
    }

    useEffect(() => {
        let exceptions = ['ZENDESK', 'FRESHDESK', 'PRODUCT_USAGE', 'CALLYPSO', 'MANUAL', 'UPLOAD']
        if (!!csvUploadedData) {
            exceptions = [...exceptions, ...Object.keys(csvUploadedData)]
        }
        if (exceptions.includes(objectName?.value) || exceptions.includes(edit?.column?.object) || exceptions.includes(edit?.column?.source)) {
            return
        }

        if (objectName?.value) {
            getObjectProperties(objectName?.value)
        } else if (workspaceTypeCheck(['HUBSPOT'])) {
            getObjectProperties(edit.column.relationshipName)
        } else {
            getObjectProperties(edit.column.object)
        }
    }, [])


    const addComparison = () => {
        setComparisons([...comparisons, {
            comparisor: 'EQUALS',
            color: 'RED'
        }])
    }

    return (
        <Modal
            size={MODAL_SIZES.SLIGHTLY_BIGGER}
            minHeight
            onClose={onClose}
            buttons={
                <Row
                    fullWidth
                    columnFlex
                >
                    <Button
                        fullWidth
                        disabled={columnToBeAdded?.source === 'UPLOAD'}
                        onClick={() => {
                            if (!!edit) {
                                onEdit({
                                    comparisons,
                                    column: {
                                        ...edit?.column,
                                        ...columnToBeAdded,
                                    }
                                })
                            } else {
                                onAdd({
                                    comparisons,
                                    columnToBeAdded,
                                })
                            }
                        }}
                    >
                        {!!edit ? 'Save' : 'Add'}
                    </Button>
                    <br />
                    <Button
                        fullWidth
                        onClick={onClose}
                    >
                        Cancel
                    </Button>
                </Row>
            }
            header={!!edit ? 'Editing Column' : 'Add a new Column'}
        >
            <Row noMargin columnFlex noAligning>
                <Label className={cn.sourceLabel}>Source</Label>
                <Dropdown
                    fullWidth
                    values={[
                        (workspaceTypeCheck(['CALLYPSO']) ? {
                            label: 'Callypso CRM',
                            value: 'CALLYPSO_CRM',
                        } : {
                            label: workspaceTypeCheck(['HUBSPOT']) ? 'Hubspot' : 'SalesForce',
                            value: 'SALESFORCE',
                        }),
                        {
                            label: 'Callypso',
                            value: 'CALLYPSO',
                        },
                        {
                            label: 'Manual',
                            value: 'MANUAL',
                        },
                        {
                            label: 'Product Usage',
                            value: 'PRODUCT_USAGE'
                        },
                        (zendeskConnected ? {
                            label: 'Zendesk',
                            value: 'ZENDESK'
                        } : null),
                        (freshdeskConnected ? {
                            label: 'Freshdesk',
                            value: 'FRESHDESK'
                        } : null),
                        {
                            label: 'Upload',
                            value: 'UPLOAD',
                        },
                        (!csvUploadedData ? null : Object.keys(csvUploadedData).map(el => ({
                            label: el,
                            value: el,
                        }))),
                    ].filter(el => !!el).flat()}
                    value={columnToBeAdded?.source}
                    onChange={e => {
                        let addedProps = {}
                        switch (e.target.value) {
                        case 'CALLYPSO':
                            addedProps = {
                                objectName: 'CONTACT_LEFT',
                                fieldName: 'CONTACT_LEFT',
                                format: 'CONTACT_LEFT'
                            }
                            break;
                        case 'MANUAL':
                            addedProps = {
                                objectName: 'Checkbox',
                                fieldName: 'Checkbox',
                                format: 'Checkbox'
                            }
                            break;
                        case 'PRODUCT_USAGE':
                            addedProps = {
                                fieldName: 'ALL',
                                objectName: 'PRODUCT_USAGE',
                                format: 'PRODUCT_USAGE'
                            }
                            break;
                        case 'ZENDESK':
                            addedProps = {
                                objectName: 'ZENDESK',
                                format: 'ZENDESK'
                            }
                            break;
                        case 'FRESHDESK':
                            addedProps = {
                                objectName: 'FRESHDESK',
                                format: 'FRESHDESK'
                            }
                            break;
                        }
                        setColumnToBeAdded({
                            ...columnToBeAdded,
                            ...addedProps,
                            source: e.target.value,
                        })
                    }}
                />
            </Row>
            <AddModalMiddlePart
                columnToBeAdded={columnToBeAdded}
                setColumnToBeAdded={setColumnToBeAdded}
                setComparisons={setComparisons}
                getObjectProperties={getObjectProperties}
                objectsFields={objectsFields}
                possibleObjects={possibleObjects}
                products={products}
                csvUploadedData={csvUploadedData}
                checkForManualCSVUploads={checkForManualCSVUploads}
                onClose={onClose}
            />
            {['MULTIPLE', 'YES_NO'].includes(columnToBeAdded?.format) && (
                <>
                    <Label>
                        Map object values to visual representation
                    </Label>
                    {comparisons.map((el, i) => (
                        <Row
                            noMargin
                            className={cn.comparisonRow}
                        >
                            {columnToBeAdded?.objectName?.value !== 'Account' && (
                                <>
                                    <Dropdown
                                        fullWidth
                                        values={[
                                            { label: 'Sum', value: 'SUM' },
                                            { label: 'Count', value: 'COUNT' },
                                            { label: 'Any of the Objects', value: 'ANY' },
                                            { label: 'None of the Objects', value: 'NONE' },
                                        ]}
                                        value={el.connectedObjectComparisor}
                                        onChange={e => {
                                            setComparisons(comparisons.map((innerEl, innerIndex) => {
                                                if (innerIndex === i) {
                                                    return {
                                                        ...innerEl,
                                                        connectedObjectComparisor: e.target.value
                                                    }
                                                }
                                                return innerEl
                                            }))
                                        }}
                                    />
                                    <Nbsp />
                                </>
                            )}
                            <Dropdown
                                fullWidth
                                values={[
                                    { label: 'Equals', value: 'EQUALS' },
                                    { label: 'Includes', value: 'INCLUDES' },
                                    { label: 'Doesn\'t Include', value: 'DOESNT_INCLUDES' },
                                    { label: 'Starts With', value: 'STARTS_WITH' },
                                    { label: 'Ends With', value: 'ENDS_WITH' },
                                    { label: '>', value: 'BIGGER_THAN' },
                                    { label: '<', value: 'LESSER_THAN' },
                                ]}
                                value={el.comparisor}
                                onChange={e => {
                                    setComparisons(comparisons.map((innerEl, innerIndex) => {
                                        if (innerIndex === i) {
                                            return {
                                                ...innerEl,
                                                comparisor: e.target.value
                                            }
                                        }
                                        return innerEl
                                    }))
                                }}
                            />
                            <Nbsp />
                            <Input
                                fullWidth
                                placeholder='to'
                                value={el.comparedValue}
                                onChange={e => {
                                    setComparisons(comparisons.map((innerEl, innerIndex) => {
                                        if (innerIndex === i) {
                                            return {
                                                ...innerEl,
                                                comparedValue: e.target.value
                                            }
                                        }
                                        return innerEl
                                    }))
                                }}
                            />
                            <Nbsp />
                            <Input
                                fullWidth
                                placeholder='label'
                                value={el.label}
                                onChange={e => {
                                    setComparisons(comparisons.map((innerEl, innerIndex) => {
                                        if (innerIndex === i) {
                                            return {
                                                ...innerEl,
                                                label: e.target.value
                                            }
                                        }
                                        return innerEl
                                    }))
                                }}
                            />
                            <Nbsp />
                            <Dropdown
                                fullWidth
                                values={
                                    columnToBeAdded?.format === 'YES_NO'
                                        ? [
                                            { label: 'Red', value: 'RED' },
                                            { label: 'Green', value: 'GREEN' },
                                        ]
                                        : [
                                            { label: 'Red', value: 'RED' },
                                            { label: 'Yellow', value: 'YELLOW' },
                                            { label: 'Green', value: 'GREEN' },
                                        ]
                                }
                                value={el.color}
                                onChange={e => {
                                    setComparisons(comparisons.map((innerEl, innerIndex) => {
                                        if (innerIndex === i) {
                                            return {
                                                ...innerEl,
                                                color: e.target.value
                                            }
                                        }
                                        return innerEl
                                    }))
                                }}
                            />
                            <Button
                                type={BUTTON_TYPES.ICON}
                                icon={faTrashAlt}
                                onClick={() => {
                                    setComparisons(comparisons.filter((_el, innerIndex) => innerIndex !== i))
                                }}
                            />
                        </Row>
                    ))}
                    <br/>
                    <Button onClick={addComparison}>
                        Add+
                    </Button>
                </>
            )}
        </Modal>
    )
}

// eslint-disable-next-line no-unused-vars
export const Risk = ({ user }) => {
    const [tableData, setTableData] = useState([])
    const [showRequestedUpdateWarning, setShowRequestedUpdateWarning] = useState(false)
    const [loaded, setLoaded] = useState(false)
    const [totalPages, setTotalPages] = useState(0)
    const [totalNumberOfAccounts, setTotalNumberOfAccounts] = useState(0)
    const [userList, setUserList] = useState([]);
    const [teams, setTeams] = useState([]);
    const [lastCachedDate, setLastCachedDate] = useState();
    const [filterModalShown, setFilterModalShown] = useState(false)
    const [previousFilter, setPreviousFilter] = useState(!!user?.sfUserId ? {
        filterOnAccountOwners: [user.sfUserId],
        filterOnGreen: true,
        filterOnYellow: true,
        filterOnRed: true,
    } : undefined)

    const [columnToBeEditted, setColumnToBeEditted] = useState(null)
    const [columnToBeAddedIndex, setColumnToBeAddedIndex] = useState()
    const [showColumnToBeAddedModal, setShowColumnToBeAddedModal] = useState(false)
    const [showUsage, setShowUsage] = useState(false)
    const [zendeskConnected, setZendeskConnected] = useState(false)
    const [freshdeskConnected, setFreshdeskConnected] = useState(false)
    const [csvUploadedData, setCSVUploadedData] = useState()
    const [riskFactors, setRiskFactors] = useState()
    const [showRiskFactorsLoader, setShowRiskFactorsLoader] = useState(false)
    const [accountFields, setAccountFields] = useState()

    const [trendUserId, setTrendUserId] = useState({ value: 'COMPANY_WIDE', label: 'Company Wide' })
    const [trendTimePeriod, setTrendTimePeriod] = useState(TREND_TIME_PERIODS[0])
    const [trendTimeFormat, setTrendTimeFormat] = useState(TIME_FORMATS[0])
    const [trendColumnId, setTrendColumnId] = useState()
    const [trendFilters, setTrendFilters] = useState([{}])
    const [trendFormat, setTrendFormat] = useState([])
    const [trendLabel, setTrendLabel] = useState('Custom Risk Report')
    const [trendData, setTrendData] = useState()
    const [workspaceTrendsShown, setWorkspaceTrendsShown] = useState(false)
    const [trendAggregate, setTrendAggregate] = useState(false)
    const [reportGenerationRequestSent, setReportGenerationRequestSent] = useState(false)
    const [savedTrendReports, setSavedTrendReports] = useState([])
    const [selectedRiskTrendTabs, setSelectedRiskTrendTabs] = useState(0)
    const [riskBeingUpdatedCurrently, setRiskBeingUpdatedCurrently] = useState(true)

    const { page: routePage } = useParams()
    const page = parseInt(routePage) || 0;

    const requestUpdate = () => {
        setTableData([])
        makeRequest.get(`${URLS.main}/risk/break-cache`)
            .then(() => loadTable(page, previousFilter))
        setShowRequestedUpdateWarning(false)
    }

    const updateTableEdits = (data, columnIndex, add) => {
        const toBeSubmitted = []

        if (columnIndex && !add) {
            makeRequest.post(`${URLS.main}/risk`, {
                deletedColumn: columnIndex
            })
        } else {
            const customColumns = [
                EMPTY_TABLE_ELEMENT_TYPE,
                'Dropdown',
                'Checkbox',
            ]
            if (customColumns.includes(data.type)) {
                toBeSubmitted.push({
                    columnIndex,
                    value: data.data.value,
                    accountId: data.externalId,
                    customColumnId: data.data.customColumnId,
                    type: data.type,
                    columnId: data.data.columnId,
                })
            }
            makeRequest.post(`${URLS.main}/risk`, {
                data: toBeSubmitted,
                // columnToBeAdded: add ? columnIndex : null
            })
        }
    }

    const editColumn = ({ comparisons, column }) => {
        if (column.objectName?.value) {
            column.object = column.objectName?.value
            delete column.objectName
        }
        if (column.fieldName?.value) {
            column.field = column.fieldName?.value
            delete column.fieldName
        }
        makeRequest.put(`${URLS.main}/risk-columns`, {
            ...column,
            relationshipName: column.relationshipName ? column.relationshipName : Object.keys(standardRelationshipNames).includes(column.object) ? standardRelationshipNames[column.object] : relationshipNames[column.object],
            comparisons,
            columnIndex: columnToBeAddedIndex,
        })
            .then(requestUpdate)
        setShowColumnToBeAddedModal(false)
        setColumnToBeAddedIndex(undefined)
    }

    const addColumn = ({ comparisons, columnToBeAdded }) => {
        setLoaded()
        columnToBeAdded.object = columnToBeAdded.objectName?.value || columnToBeAdded.objectName
        columnToBeAdded.field = columnToBeAdded.fieldName?.value || columnToBeAdded.fieldName
        if (columnToBeAdded.type) {
            columnToBeAdded.format = columnToBeAdded.type
        }
        delete columnToBeAdded.objectName
        delete columnToBeAdded.fieldName

        makeRequest.post(`${URLS.main}/risk-columns`, {
            ...columnToBeAdded,
            relationshipName: Object.keys(standardRelationshipNames).includes(columnToBeAdded.object) ? standardRelationshipNames[columnToBeAdded.object] : relationshipNames[columnToBeAdded.object],
            comparisons,
            columnIndex: columnToBeAddedIndex,
        })
            .then(requestUpdate)
        setShowColumnToBeAddedModal(false)
        setColumnToBeAddedIndex(undefined)
    }

    const deleteColumn = (columnIndex, data) => {
        const parsable = data || tableData
        const newData = parsable.map(row => {
            return row.filter((_el, i) => i !== columnIndex)
        })
        setTableData(newData)
        makeRequest.delete(`${URLS.main}/risk-columns/${parsable[0][columnIndex]?.customColumnId}`)
    }

    const startEditColumn = (columnIndex) => {
        makeRequest.get(`${URLS.main}/risk-columns/${tableData[0][columnIndex]?.customColumnId}`)
            .then(res => {
                setColumnToBeEditted(res.data)
                setShowColumnToBeAddedModal(true)
            })
    }

    const updateTableEditsDebounce = useCallback(
        debounce(updateTableEdits, 250),
        []
    );

    const getFactors = () => {
        setShowRiskFactorsLoader(true)
        makeRequest.get(`${URLS.main}/describe/Account`)
            .then(res => {
                const accountPotentiallyRelevantFields = [
                    { value: "Industry", correlation: 0.13476, common: ['SaaS'], churned: randInRange(3, 42), potential: randInRange(0, 7) },
                    { value: "AnnualRevenue", correlation: 0.43, common: 500000, churned: randInRange(3, 42), potential: randInRange(0, 7) },
                    { value: "NumberOfEmployees", correlation: 0.7, common: 237, churned: randInRange(3, 42), potential: randInRange(0, 7) },
                    { value: "Rating", correlation: 0.12, common: 3, churned: randInRange(3, 42), potential: randInRange(0, 7) },
                    { value: "OwnerId", correlation: 0.313, common: ['Kristiyan Ivanov', 'Michael Tuso'], churned: randInRange(3, 42), potential: randInRange(0, 7) },
                    { value: "LastActivityDate", correlation: 0.83, common: '3+ months', churned: randInRange(3, 42), potential: randInRange(0, 7) },
                    { value: "CustomerPriority__c", correlation: 0.3, common: 1, churned: randInRange(3, 42), potential: randInRange(0, 7) },
                    { value: "NumberofLocations__c", correlation: 0.1, common: 1, churned: randInRange(3, 42), potential: randInRange(0, 7) },
                    { value: "SLASerialNumber__c", correlation: 0, churned: randInRange(3, 42), potential: randInRange(0, 7) },
                    { value: "SLAExpirationDate__c", correlation: 0, churned: randInRange(3, 42), potential: randInRange(0, 7) },
                    { value: "CustomNumberField__c", correlation: 0.89, common: randInRange(0, 673) / 10, churned: randInRange(3, 42), potential: randInRange(0, 7) },
                    { value: "CustomTextFieldByKris__c", correlation: 0.76, common: ['Test 1', 'Test 3'], churned: randInRange(3, 42), potential: randInRange(0, 7) },
                    { value: "GHR_Implementation_Status__c", correlation: 0.81, common: 'false', churned: randInRange(3, 42), potential: randInRange(0, 7) },
                    { value: "Paid_On_Time__c", correlation: 0.913, common: ['Pending', 'Not Paid'], churned: randInRange(3, 42), potential: randInRange(0, 7) },
                    { value: "Number_of_Hires_90_Days__c", correlation: 0.659, common: 3, churned: randInRange(3, 42), potential: randInRange(0, 7) },
                    { value: "Number_of_Hires_12_Months__c", correlation: 0.4578, common: 12, churned: randInRange(3, 42), potential: randInRange(0, 7) },
                    { value: "Customer_Success_Manager__c", correlation: 0.152, common: ['Kristiyan Ivanov'], churned: randInRange(3, 42), potential: randInRange(0, 7) },
                    { value: "Onboarding_Manager__c", correlation: 0.149, common: ['Micahel Tuso'], churned: randInRange(3, 42), potential: randInRange(0, 7) },
                    { value: "Seats_Bought__c", correlation: 0.45, common: 12, churned: randInRange(3, 42), potential: randInRange(0, 7) },
                ]
                const customObjectsFieldsToIgnore = [
                    "Id",
                    "IsDeleted",
                    "Name",
                    "CreatedDate",
                    "CreatedById",
                    "LastModifiedDate",
                    "LastModifiedById",
                    "SystemModstamp",
                    "LastActivityDate"
                ]
                const { fields, objects } = res.data
                const accountFields = fields.filter(el => accountPotentiallyRelevantFields.map(el => el.value).includes(el.value)).map(el => ({
                    ...el,
                    ...accountPotentiallyRelevantFields.find(field => field.value === el.value)
                })).sort((a, b) => b.correlation - a.correlation)
                const customObjectsAndFields = objects.map(el => {
                    return {
                        ...el,
                        fields: el.fields.filter(el => !customObjectsFieldsToIgnore.includes(el.value))
                    }
                })
                setTimeout(() => {
                    setShowRiskFactorsLoader(false)
                    setRiskFactors({
                        fields: accountFields,
                        objects: customObjectsAndFields
                    })
                }, 2000)
            })
    }

    const loadTable = (page, filter) => {
        setTableData([])
        makeRequest.get(`${URLS.main}/risk/${page}`, {
            headers: {
                filter: JSON.stringify(filter)
            }
        })
            .then(res => {
                const { tableData, historicalData } = res.data
                const remappedData = tableData.map((row, rowIndex) => (
                    row.map((column, columnIndex) => {
                        if (columnIndex === 1 && rowIndex > 0) {
                            column.data.historicalData = historicalData[row[0].externalId]
                        }
                        return column
                    })
                ))
                setRiskBeingUpdatedCurrently(res.data.riskBeingUpdatedCurrently)
                setTableData(remappedData)
                setTotalPages(res.data.totalPages)
                setTotalNumberOfAccounts(res.data.totalNumberOfAccounts)
                setLastCachedDate(res.data.lastCache)
            })
    }

    useEffect(() => {
        if (previousFilter !== undefined) {
            loadTable(page, previousFilter)
        }
    }, [previousFilter])

    const loadUsers = () => {
        makeRequest.get(`${URLS.main}/workspace/users`)
            .then(res => {
                if (res && res.data && res.data.success) {
                    setUserList(res.data.users);
                }
            })
            .catch(() => {});
    }

    const loadTeams = () => {
        makeRequest.get(`${URLS.main}/teams`)
            .then(res => {
                if (res && res.data) {
                    setTeams(res.data.teams);
                }
            })
            .catch(() => {});
    }

    const checkZendeskConnected = () => {
        makeRequest.get(`${URLS.main}/integrations/authenticationSettings/ZENDESK`)
            .then(res => {
                if (res.data && res.data.hasConnection) {
                    setZendeskConnected(true)
                } else {
                    setZendeskConnected(false)
                }
            })
    }

    const checkFreshdeskConnected = () => {
        makeRequest.get(`${URLS.main}/integrations/authenticationSettings/FRESHDESK`)
            .then(res => {
                if (res.data && res.data.hasConnection) {
                    setFreshdeskConnected(true)
                } else {
                    setFreshdeskConnected(false)
                }
            })
    }

    const checkForManualCSVUploads = () => {
        return makeRequest.get(`${URLS.main}/uploaded/csv`)
            .then(res => {
                if (res.data) {
                    setCSVUploadedData(res.data)
                }
            })
    }

    useEffect(() => {
        loadUsers()
        loadTeams()
        getSavedWorkspaceTrendsReports()
        checkZendeskConnected()
        checkFreshdeskConnected()
        checkForManualCSVUploads()
        getAccountDescriptionFields()
    }, [])

    useEffect(() => {
        loadTable(page, previousFilter)
    }, [page])

    useEffect(() => {
        if (tableData?.length) {
            setLoaded(true)
        } else {
            setLoaded(false)
        }
    }, [tableData])

    const additionalStyles = {}


    const getAccountDescriptionFields = () => {
        makeRequest.get(`${URLS.main}/describe/Account`)
            .then(res => {
                setAccountFields(res.data.fields.map(el => ({
                    label: el.label,
                    value: el.value,
                    type: el.type
                })))
            })
    }

    if (tableData && tableData[1]) {
        tableData[1].forEach((el, i) => {
            if (el) {
                if (['Notes'].includes(el.type)) {
                    additionalStyles[i] = {
                        minWidth: '180px',
                        width: '180px',
                        fixedWidth: '180px'
                    }
                } else if (['Dropdown'].includes(el.type)) {
                    additionalStyles[i] = {
                        minWidth: '140px',
                        width: '140px',
                        fixedWidth: '140px',
                        td: {
                            overflow: 'visible',
                            padding: 0,
                        }
                    }
                } else if (['Opportunities_up_sell', 'Opportunities_cross_sell'].includes(el.type)) {
                    additionalStyles[i] = {
                        minWidth: '180px',
                        width: '180px',
                        fixedWidth: '180px',
                        td: {
                            overflow: 'visible'
                        }
                    }
                }
            }
        })
    }

    // useOutsideClickHandler(inputRef, () => {
    //     restoreParentZIndex()
    //     // inputRef?.current.blur()
    // });

    const increaseParentZIndex = () => {
        // inputRef.current.parentNode.parentNode.style.zIndex = 100000
    }

    const restoreParentZIndex = () => {
        // inputRef.current.parentNode.parentNode.style.zIndex = 10
    }

    const headerContextHandler = (index, event) => {
        if (permissionCheck('RISK_MANAGEMENT', 'CREATE') || permissionCheck('RISK_MANAGEMENT', 'UPDATE')) {
            const { show } = useContextMenu({
                id: `${MENU_ID}${index}`,
                index,
            });
            // const inputRef = useRef(null);

            event.preventDefault();
            show(event)
            increaseParentZIndex()
        }
    }

    const filterOnUserSelection = data => {
        setPreviousFilter(data)
        hideFilterModal()
    }

    const showFilterModal = () => setFilterModalShown(true)
    const hideFilterModal = () => setFilterModalShown(false)

    const downloadRiskCSV = () => {
        window.open(`${URLS.main}/risk/download-csv`, '_blank')
    }

    const resetTrendModal = () => {
        setTrendUserId({ value: 'COMPANY_WIDE', label: 'Company Wide' })
        setTrendTimePeriod(TREND_TIME_PERIODS[0])
        setTrendColumnId(undefined)
        setTrendFormat([])
        setTrendData(undefined)
        setWorkspaceTrendsShown(false)
        setTrendAggregate(false)
    }

    const showWorkspaceTrends = (
        columnId = trendColumnId?.map(el => el.id).join(','),
        timePeriod = trendTimePeriod?.value,
        format = trendFormat,
        aggregate = trendAggregate,
        filters = trendFilters,
        timeFormat = trendTimeFormat?.value
    ) => {
        setWorkspaceTrendsShown(true)
        setReportGenerationRequestSent(true)
        makeRequest.get(`${URLS.main}/risk/workspace-trends/${columnId}/${trendUserId?.value}/${timePeriod}/${JSON.stringify(format)}/${aggregate}/${JSON.stringify(filters)}/${timeFormat}`)
            .then(res => {
                setTrendData(res.data)
                setReportGenerationRequestSent(false)
            })
    }

    const saveWorkspaceTrendsReport = () => {
        setReportGenerationRequestSent(true)
        makeRequest.post(`${URLS.main}/risk/workspace-trends/${trendColumnId?.map(el => el.id).join(',')}/${trendUserId?.value}/${trendTimePeriod?.value}/${JSON.stringify(trendFormat)}/${trendAggregate}/${trendTimeFormat?.value}/${JSON.stringify(trendFilters)}/${trendLabel}`)
            .then(() => {
                getSavedWorkspaceTrendsReports()
                setReportGenerationRequestSent(false)
            })
    }

    const deleteSavedWorkspaceTrendsReport = (id) => {
        setReportGenerationRequestSent(true)
        makeRequest.delete(`${URLS.main}/risk/workspace-trends/${id}`)
            .then(() => {
                setReportGenerationRequestSent(false)
                setSelectedRiskTrendTabs(0)
                setSavedTrendReports(savedTrendReports.filter(el => el.id !== id))
            })
    }

    const getSavedWorkspaceTrendsReports = () => {
        makeRequest.get(`${URLS.main}/risk/workspace-trends`)
            .then(res => {
                setSavedTrendReports(res.data.data)
            })
    }

    const missingRiskTrendSetting = !trendColumnId?.length || !!trendFormat?.find(el => !el.comparison || el.value === undefined)

    return (
        <>
            <ViewContainer className={cn.wrapper}>
                <UsageGraph
                    shown={!!showUsage}
                    onClose={() => setShowUsage(false)}
                    accountId={showUsage}
                />
                {loaded ? (
                    <>
                        <Row className={cn.selectableConfigSticky} wrap spaceBetween>
                            <Row noMargin className={cn.accountNumberWrapper}>
                                <div>
                                    {totalNumberOfAccounts}
                                </div>
                                <div>
                                    Accounts
                                </div>
                                <div
                                    className={`${cn.cachedInfo} ${riskBeingUpdatedCurrently ? cn.inactive : ''}`}
                                    data-tooltip-id='default'
                                    data-tooltip-content={
                                        riskBeingUpdatedCurrently ? 'Data is currently being updated. Try again in a few minutes'
                                            : 'This indicates how old the information used for analysis of the risk is. It is refreshed every day. You can also click on it to request immediate update'
                                    }
                                    onClick={() => {
                                        if (!riskBeingUpdatedCurrently) {
                                            setShowRequestedUpdateWarning(true)
                                        }
                                    }}
                                >
                                    <b>Last Updated Date</b>
                                    {' '}
                                    <u>{moment(lastCachedDate).format('HH:mm - Do MMMM YYYY')}</u>
                                </div>
                            </Row>
                            <Row noMargin>
                                <Button
                                    type={BUTTON_TYPES.ICON}
                                    onClick={() => showWorkspaceTrends()}
                                    icon={faChartColumn}
                                    data-tooltip-id='default'
                                    data-tooltip-content='Risk/Health score trend reportrs'
                                />
                                <Nbsp />
                                {environmentCheck(['LOCAL', 'DEV']) ? (
                                    <>
                                        <Button
                                            type={BUTTON_TYPES.ICON}
                                            onClick={downloadRiskCSV}
                                            icon={faDownload}
                                            data-tooltip-id='default'
                                            data-tooltip-content='Download CSV'
                                        />
                                        <Nbsp />
                                    </>
                                ) : null }
                                {environmentCheck(['LOCAL', 'DEV']) ? (
                                    <>
                                        <Nbsp />
                                        <Button
                                            type={BUTTON_TYPES.ICON}
                                            onClick={getFactors}
                                            icon={faQuestion}
                                            data-tooltip-id='default'
                                            data-tooltip-content='Statistical Analysis'
                                        />
                                    </>
                                ) : null}
                                <Nbsp />
                                <Button
                                    type={BUTTON_TYPES.ICON}
                                    onClick={showFilterModal}
                                    customIcon={<Filter />}
                                    data-tooltip-id='default'
                                    data-tooltip-content='Filter'
                                />
                            </Row>
                        </Row>
                        <div className={cn.tableWrapper}>
                            {tableData[0]?.map((el, i) => {
                                if (el.data ==='Actions') {
                                    return null
                                }
                                return (
                                    <Menu id={`${MENU_ID}${i}`} className={cn.contextMenu}>
                                        {!!el.deletable && (
                                            <>
                                                <Item
                                                    onClick={() => {
                                                        restoreParentZIndex()
                                                        deleteColumn(i)
                                                    }}
                                                >
                                                    Delete column
                                                </Item>
                                                <Separator />
                                                <Item
                                                    onClick={() => {
                                                        restoreParentZIndex()
                                                        startEditColumn(i)
                                                    }}
                                                >
                                                    Edit column
                                                </Item>
                                                <Separator />
                                            </>
                                        )}
                                        <Item
                                            onClick={() => {
                                                setShowColumnToBeAddedModal(true)
                                                setColumnToBeAddedIndex(i)
                                                setColumnToBeEditted(null)
                                                restoreParentZIndex()
                                            }}
                                        >
                                            Add Colum to the right
                                        </Item>
                                    </Menu>
                                )
                            })}
                            <Table
                                noPadding
                                featureName={'risk'}
                                resizible
                                saveSizeUpdates
                                className={cn.whitespacingTable}
                                headerContextHandler={headerContextHandler}
                                headers={(tableData[0] || []).map((el, i) => {
                                    if (el.type === 'EmptyTableElement' && i !== 0) {
                                        return <EmptyTableElement
                                            value={el?.data}
                                            columnIndex={i}
                                            rowIndex={0}
                                            onChange={(data, column, row) => {
                                                const updatedTableData = tableData.slice(0)
                                                updatedTableData[row][column].data = data
                                                updateTableEditsDebounce(updatedTableData)
                                                setTableData(updatedTableData)
                                            }}
                                        />
                                    }
                                    return el.data?.split('<br/>')
                                })}
                                minWidth={'100px'}
                                customStyles={{
                                    0: {
                                        position: 'sticky',
                                        left: 0,
                                        top: 0,
                                        zIndex: 10
                                    },
                                    [!!tableData[1]?.length ? tableData[0].length - 1 : -1]: {
                                        minWidth: '200px',
                                        maxWidth: '200px',
                                        width: '200px',
                                        fixedWidth: '200px'
                                    },
                                    ...additionalStyles
                                }}
                                data={tableData.slice(1).map((row, rowIndex) => row.map(({ data, type, externalId, dataTip }, columnIndex) => {
                                    const accountDetails = {
                                        id: row[0].externalId,
                                        name: row[0].data,
                                        riskData: {
                                            color: row.find(el => el.type === 'HealthScore')?.data?.color,
                                            additionalData: []
                                        }
                                    }
                                    row.forEach((el, i) => {
                                        if (!['HealthScore', 'Account', 'Actions'].includes(el.type)) {
                                            accountDetails.riskData.additionalData.push({
                                                label: tableData[0][i]?.data?.replace('<br/>', ' '),
                                                data: typeof el.data === 'object' ? el.data?.value : el.data,
                                                color: el.data?.color ? el.data?.color : null,
                                                columnId: el.data?.columnId
                                            })
                                        }
                                    })
                                    switch (type) {
                                    case 'Account':
                                        return <Link
                                            key={`${rowIndex} ${columnIndex} ${externalId}`}
                                            to={`/account/${externalId}`}
                                        >
                                            {data}
                                        </Link>
                                    case 'PRODUCT_USAGE':
                                        return <ProductUsageElement dataTip={dataTip} data={parseInt(data?.value)} />
                                    case 'Actions':
                                        return <AccountActions
                                            noTooltip
                                            onDelete={requestUpdate}
                                            account={accountDetails}
                                            onShowUsage={() => setShowUsage(externalId)}
                                        />
                                    case 'HealthScore':
                                        return <HealthScoreConnected data={data} accountName={row[0].data} />
                                    case 'DATE':
                                    case 'DATE_TIME':
                                    case 'MEETINGS':
                                        return <DateTime data={data} dataTip={dataTip} />
                                    case 'DisabledBoolean':
                                        return <DisabledBoolean data={data} />
                                    case 'YES_NO':
                                    case 'MULTIPLE':
                                        return <DisabledBoolean data={data.value} color={data.color} />
                                    case 'CONTACT_LEFT':
                                    case 'EXPIRING_OPPORTUNITY':
                                    case 'STALLED_OPPORTUNITY':
                                        return <NoteCell key={`${rowIndex} ${columnIndex} ${externalId}`} problems={data?.value || []} />
                                    case 'Checkbox':
                                        return <WhiteSpaceCheckbox
                                            // eslint-disable-next-line eqeqeq
                                            checked={data?.value == 1}
                                            onClick={() => {
                                                if (permissionCheck('RISK_MANAGEMENT', 'UPDATE')) {
                                                    const updatedTableData = tableData.slice(0)
                                                    updatedTableData[rowIndex + 1][columnIndex].data.value = ![1, '1', true].includes(updatedTableData[rowIndex + 1][columnIndex].data?.value)
                                                    updateTableEditsDebounce({ ...updatedTableData[rowIndex + 1][columnIndex], externalId })
                                                    setTableData(updatedTableData)
                                                }
                                            }}
                                        />
                                    case 'Dropdown':
                                        return <WhiteSpaceDropdown
                                            options={data.options}
                                            value={data.value}
                                            onChange={(newValue) => {
                                                if (permissionCheck('RISK_MANAGEMENT', 'UPDATE')) {
                                                    const updatedTableData = tableData.slice(0)
                                                    updatedTableData[rowIndex + 1][columnIndex].data.value = newValue
                                                    updateTableEditsDebounce({ ...updatedTableData[rowIndex + 1][columnIndex], externalId })
                                                    setTableData(updatedTableData)
                                                }
                                            }}
                                        />
                                    case 'SUM':
                                    case 'currency':
                                        return <div className={cn.simpleText}>{formatLargeSums('$', data?.value)}</div>
                                    case 'number':
                                    case 'NUMBER':
                                    case 'COUNT':
                                    case 'ZENDESK':
                                    case 'FRESHDESK':
                                    case 'CSV_UPLOAD':
                                        return <div className={cn.simpleText}>{data?.value || 0}</div>
                                    case 'TEXT':
                                    case 'CSV':
                                    case 'text':
                                    case 'ANY':
                                    case 'NONE':
                                        return <div className={cn.simpleText}>{data.value}</div>
                                    case 'percentage':
                                    case 'PERCENTAGE':
                                        return <div className={cn.simpleText}>{`${data.value || 0}%`}</div>
                                    case EMPTY_TABLE_ELEMENT_TYPE:
                                        return <EmptyTableElement
                                            value={data?.value}
                                            columnIndex={columnIndex}
                                            rowIndex={rowIndex + 1}
                                            addColumn={() => {
                                                setShowColumnToBeAddedModal(true)
                                                setColumnToBeAddedIndex(columnIndex)
                                            }}
                                            deleteColumn={() => deleteColumn(tableData.slice(0), columnIndex)}
                                            onChange={(data, column, row) => {
                                                const updatedTableData = tableData.slice(0)
                                                updatedTableData[row][column].data = data
                                                updateTableEditsDebounce(updatedTableData)
                                                setTableData(updatedTableData)
                                            }}
                                        />
                                    default:
                                        return data?.data || data
                                    }
                                }))}
                            />
                        </div>
                    </>
                ) : (
                    <Loader type={LOADER_TYPES.SPINNER} size={LOADER_SIZES.BIG} />
                )}
            </ViewContainer>
            {loaded && <Pagination pages={totalPages} />}
            {filterModalShown && (
                <FilterModal
                    columns={tableData && tableData[0] && tableData[0].slice(1, tableData[0].length - 1).map(el => ({
                        data: el.data,
                        customColumnId: el.customColumnId || el.type
                    }))}
                    previousFilter={previousFilter}
                    onClose={hideFilterModal}
                    userList={userList}
                    teams={teams}
                    onSave={filterOnUserSelection}
                />
            )}
            {showColumnToBeAddedModal && (
                <AddColumnModal
                    onClose={() => {
                        setShowColumnToBeAddedModal(false)
                        setColumnToBeAddedIndex(undefined)
                    }}
                    edit={columnToBeEditted}
                    onAdd={addColumn}
                    onEdit={editColumn}
                    zendeskConnected={zendeskConnected}
                    freshdeskConnected={freshdeskConnected}
                    csvUploadedData={csvUploadedData}
                    checkForManualCSVUploads={checkForManualCSVUploads}
                />
            )}
            {showRequestedUpdateWarning && (
                <Modal
                    onClose={() => setShowRequestedUpdateWarning(false)}
                    header="Request immediate update"
                    buttons={(
                        <Row spaceBetween columnFlex>
                            <Button
                                fullWidth
                                onClick={requestUpdate}
                            >
                                Yes
                            </Button>
                            <br />
                            <Button
                                fullWidth
                                onClick={() => setShowRequestedUpdateWarning(false)}
                            >
                                No
                            </Button>
                        </Row>
                    )}
                >
                    Are you sure you want to request immediate update?
                    <br /><br />
                    If this is done too many time per day, it might use up the API requests to your CRM, which could lead to deteriorated performance and problems.
                </Modal>
            )}
            {(!!riskFactors || showRiskFactorsLoader) && (
                <Modal
                    minWidth
                    size={MODAL_SIZES.BIG}
                    header='Risk Data Breakdown'
                    onClose={() => setRiskFactors(undefined)}
                >
                    {showRiskFactorsLoader ? (
                        <Loader type={LOADER_TYPES.SPINNER} size={LOADER_SIZES.EXTRA_BIG} />
                    ) : (
                        <>
                            <ViewContainer collapsible title='Account Fields' initiallyCollapsed={false}>
                                <Table
                                    noPaddingHeader
                                    headers={['Name', 'Correlation', 'Common/Average Values', [`# of Churned`, `Accounts`], ['# of Affected', 'Accounts']]}
                                    data={riskFactors.fields.map(el => {
                                        return [el.label, `${el.correlation * 100}%`, Array.isArray(el.common) ? el.common.join(', ') : el.common, el.churned, el.potential]
                                    })}
                                />
                            </ViewContainer>
                            <SubHeader>Standard Objects</SubHeader>
                            <ViewContainer collapsible title={`Opportunities`}>
                                <Row>
                                    <Label># of Opportunities (average):</Label>
                                    <div className={cn.data}>{randInRange(0, 7)}</div>
                                </Row>
                                <Row>
                                    <Label># Of Accounts bellow that:</Label>
                                    <div className={cn.data}>{randInRange(0, 37)}</div>
                                </Row>
                                <Table
                                    noPaddingHeader
                                    headers={['Name', 'Correlation', 'Common/Average Values', [`# of Churned`, `Accounts`], ['# of Affected', 'Accounts']]}
                                    data={[
                                        { label: 'Associated Products', correlation: 0.8629, common: ['Product A'], churned: randInRange(4, 23), potential: randInRange(0, 12) },
                                        { label: 'Time in Stage', correlation: 0.589, common: '4+ months', churned: randInRange(4, 23), potential: randInRange(0, 12) },
                                    ].map(el => {
                                        return [el.label, `${el.correlation * 100}%`, Array.isArray(el.common) ? el.common.join(', ') : el.common, el.churned, el.potential]
                                    })}
                                />
                            </ViewContainer>

                            <ViewContainer collapsible title={`Contacts`}>
                                <Row>
                                    <Label># of Contacts (average):</Label>
                                    <div className={cn.data}>{randInRange(1, 9)}</div>
                                </Row>
                                <Row>
                                    <Label># Of Accounts bellow that:</Label>
                                    <div className={cn.data}>{randInRange(0, 27)}</div>
                                </Row>
                                <Table
                                    noPaddingHeader
                                    headers={['Name', 'Correlation', 'Common/Average Values', [`# of Churned`, `Accounts`], ['# of Affected', 'Accounts']]}
                                    data={[
                                        { label: 'Level', correlation: 0.8629, common: ['Tertiary', 'Secondary'], churned: randInRange(4, 23), potential: randInRange(0, 12) },
                                        { label: 'Power User', correlation: 0.7526 + 0.18756, common: false, churned: randInRange(4, 57), potential: randInRange(0, 47) },
                                        { label: 'User Type', correlation: 0.7526, common: ['Super', 'Admin'], churned: randInRange(4, 57), potential: 51 },
                                        { label: 'Languages', correlation: 0.03267, common: 'English', churned: randInRange(4, 57), potential: 57 },
                                    ].map(el => {
                                        return [el.label, `${el.correlation * 100}%`, Array.isArray(el.common) ? el.common.join(', ') : el.common, el.churned, el.potential]
                                    })}
                                />
                            </ViewContainer>
                            <SubHeader>Custom Objects</SubHeader>
                            {riskFactors.objects.map((object) => (
                                <ViewContainer collapsible title={`${object.label}`}>
                                    <Row>
                                        <Label># of {object.label} (average):</Label>
                                        <div className={cn.data}>{randInRange(1, 4)}</div>
                                    </Row>
                                    <Row>
                                        <Label># Of Accounts bellow that:</Label>
                                        <div className={cn.data}>{randInRange(15, 57)}</div>
                                    </Row>
                                    <Table
                                        noPaddingHeader
                                        headers={['Name', 'Correlation', 'Common/Average Values', [`# of Churned`, `Accounts`], ['# of Affected', 'Accounts']]}
                                        data={object.fields.map(field => {
                                            return [
                                                field.label,
                                                `${randInRange(0, 64)}%`,
                                                'N/A',
                                                randInRange(4, 27),
                                                randInRange(0, 18)
                                            ]
                                        }).sort((a, b) => parseFloat(b[1]) - parseFloat(a[1]))}
                                    />
                                </ViewContainer>
                            ))}
                            <SubHeader>External Systems</SubHeader>
                            <ViewContainer collapsible title={`Ticketing (Zendesk)`}>
                                <Table
                                    noPaddingHeader
                                    headers={['Name', 'Correlation', 'Common/Average Values', [`# of Churned`, `Accounts`], ['# of Affected', 'Accounts']]}
                                    data={[
                                        { label: '# of opened tickets', correlation: 0.8927, common: randInRange(1, 34), churned: randInRange(4, 23), potential: randInRange(0, 12) },
                                        { label: 'Average time to close tickets', correlation: 0.7526 + 0.1176, common: '1+ weeks', churned: randInRange(4, 57), potential: randInRange(0, 47) },
                                        { label: '# of comments on the tickets', correlation: 0.2526, common: 7, churned: randInRange(4, 57), potential: 51 },
                                        { label: '# of still opened tickets', correlation: 0.03267, common: 1, churned: randInRange(1, 4), potential: randInRange(2, 12) },
                                    ].map(el => {
                                        return [el.label, `${el.correlation * 100}%`, Array.isArray(el.common) ? el.common.join(', ') : el.common, el.churned, el.potential]
                                    })}
                                />
                            </ViewContainer>
                        </>
                    )}
                </Modal>
            )}
            {workspaceTrendsShown && (
                <Modal
                    minHeight
                    minWidth
                    size={MODAL_SIZES.BIG}
                    header='Risk Trend Report'
                    onClose={() => {
                        setWorkspaceTrendsShown(false)
                        resetTrendModal()
                    }}
                    buttons={
                        <Row columnFlex>
                            <Button
                                fullWidth
                                onClick={() => showWorkspaceTrends()}
                                disabled={reportGenerationRequestSent || missingRiskTrendSetting}
                            >
                                Generate Report
                            </Button>
                            <br />
                            <Button
                                fullWidth
                                onClick={saveWorkspaceTrendsReport}
                                disabled={reportGenerationRequestSent || missingRiskTrendSetting}
                            >
                                Save Report
                            </Button>
                        </Row>
                    }
                >
                    {!!trendData ? (
                        <Row fullWidth className={cn.trendModalWrapper}>
                            {!!trendData.data ? (
                                <div style={{ minWidth: 200, height: '100%', flexGrow: 1 }}>
                                    <RiskWorkspaceTrend trendData={trendData} />
                                </div>
                            ) : (
                                <Row noMargin flexGrow>
                                    Please select a column, filter and time period from the right first.
                                </Row>
                            )}
                            <Row columnFlex className={cn.trendRightPart}>
                                {!!savedTrendReports?.length ? (
                                    <Tabs
                                        onChange={(index) => setSelectedRiskTrendTabs(index)}
                                        selected={selectedRiskTrendTabs}
                                        options={['New Report', 'Saved Reports'].filter(el => !!el)}
                                    />
                                ) : null}
                                {selectedRiskTrendTabs === 1 ? (
                                    <>
                                        {savedTrendReports.map(savedReport => {
                                            return (
                                                <Row fullWidth spaceBetween>
                                                    <Label>
                                                        {savedReport.label}
                                                    </Label>
                                                    <Row noMargin>
                                                        <Nbsp size={NBSP_SIZES.BIG} />
                                                        <Button
                                                            type={BUTTON_TYPES.SMALLER_ICON}
                                                            icon={faCheckToSlot}
                                                            data-tooltip-id='default'
                                                            data-tooltip-content={'Use Report'}
                                                            className='green'
                                                            onClick={() => {
                                                                const aggregate = savedReport.aggregatedResult === 'true'
                                                                const timePeriod = TREND_TIME_PERIODS.find(el => el.value === savedReport.timePeriod)
                                                                const timeFormat = TIME_FORMATS.find(el => el.value === savedReport.filter)
                                                                const columnId = trendData.columns.map(el => ({ ...el, value: el.id })).filter(el => savedReport.columnId.split(',').includes(el.id.toString()))
                                                                setTrendAggregate(aggregate)
                                                                setTrendTimePeriod(timePeriod)
                                                                setTrendColumnId(columnId)
                                                                setTrendFormat(JSON.parse(savedReport.formatting))
                                                                setTrendFilters(JSON.parse(savedReport.timeFormat))
                                                                setTrendLabel(savedReport.label)
                                                                setTrendTimeFormat(timeFormat)
                                                                setSelectedRiskTrendTabs(0)
                                                                showWorkspaceTrends(savedReport.columnId, timePeriod?.value, JSON.parse(savedReport.formatting), aggregate, JSON.parse(savedReport.timeFormat), timeFormat?.value)
                                                            }}
                                                        />
                                                        <Nbsp />
                                                        <Button
                                                            type={BUTTON_TYPES.SMALLER_ICON}
                                                            icon={faTrash}
                                                            data-tooltip-id='default'
                                                            data-tooltip-content={'Delete Report'}
                                                            className='red'
                                                            onClick={() => deleteSavedWorkspaceTrendsReport(savedReport.id)}
                                                        />
                                                    </Row>
                                                </Row>
                                            )
                                        })}
                                    </>
                                ) : (
                                    <>
                                        <Label fullWidth>
                                            Label
                                        </Label>
                                        <Input
                                            fullWidth
                                            value={trendLabel}
                                            onChange={e => setTrendLabel(e.target.value)}
                                        />
                                        <Label fullWidth>
                                            Column
                                        </Label>
                                        <SelectWrapperConnected
                                            isMulti
                                            maxWidth={384}
                                            options={[
                                                { value: 'ALL_COLUMNS', label: 'All Columns' },
                                                ...trendData.columns.map(el => ({
                                                    ...el,
                                                    value: el.id
                                                }))
                                            ]}
                                            value={trendColumnId}
                                            onChange={(data, { action, removedValue }) => {
                                                if (action === 'remove-value') {
                                                    const removedIndex = trendColumnId.findIndex(el => el.value === removedValue.value)
                                                    setTrendFormat(trendFormat.filter((_el, i) => i !== removedIndex))
                                                    setTrendColumnId(data)
                                                } else if (!!data.find(el => el.value === 'ALL_COLUMNS')) {
                                                    setTrendFormat(new Array(trendData.columns.length).fill({}))
                                                    setTrendColumnId(trendData.columns.map(el => ({
                                                        ...el,
                                                        value: el.id
                                                    })))
                                                } else {
                                                    setTrendFormat([...trendFormat, {}])
                                                    setTrendColumnId(data)
                                                }
                                            }}
                                        />
                                        <br />

                                        <Label fullWidth>
                                            User
                                        </Label>
                                        <SelectWrapperConnected
                                            options={[
                                                { value: 'COMPANY_WIDE', label: 'Company Wide' },
                                                ...userList.filter(el => !!el.sfUserId || !!el.hubspotUserId).map(el => ({
                                                    value: el.sfUserId || el.hubspotUserId,
                                                    label: el.fullName || el.email
                                                }))
                                            ]}
                                            value={trendUserId}
                                            onChange={setTrendUserId}
                                        />
                                        <br />

                                        <Label fullWidth>
                                            Time Format
                                        </Label>
                                        <SelectWrapperConnected
                                            options={TIME_FORMATS}
                                            value={trendTimeFormat}
                                            onChange={setTrendTimeFormat}
                                        />
                                        <br />

                                        <Label fullWidth>
                                            Time Period
                                        </Label>
                                        <SelectWrapperConnected
                                            options={TREND_TIME_PERIODS}
                                            value={trendTimePeriod}
                                            onChange={setTrendTimePeriod}
                                        />

                                        {trendColumnId?.map(({ value: columnId }, index) => {
                                            const columnData = trendData.columns.find(el => el.id * 1 === columnId * 1)
                                            const includesSpecialCases = ['AVERAGE', 'TOTAL'].includes(trendFormat[index]?.comparison?.value)
                                            return (
                                                <>
                                                    <br />
                                                    <Label fullWidth>
                                                        Count {columnData.label} if
                                                    </Label>
                                                    <Label fullWidth>
                                                        {includesSpecialCases ? 'The' : 'If the'} value of the column
                                                    </Label>
                                                    <Row noMargin>
                                                        <SelectWrapperConnected
                                                            smaller={!includesSpecialCases}
                                                            options={[
                                                                ['NUMBER', 'PERCENTAGE', 'COUNT', 'ZENDESK', 'FRESHDESK'].includes(columnData.internalFormat?.toUpperCase()) ? { value: 'AVERAGE', label: 'Average' } : null,
                                                                ['NUMBER', 'PERCENTAGE', 'COUNT', 'ZENDESK', 'FRESHDESK'].includes(columnData.internalFormat?.toUpperCase()) ? { value: 'TOTAL', label: 'Total' } : null,
                                                                { value: 'EQUALS', label: 'Equals' },
                                                                { value: 'DOES_NOT_EQUAL', label: 'Doesn\'t Equal' },
                                                                { value: 'INCLUDES', label: 'Includes' },
                                                                { value: 'DOES_NOT_INCLUDE', label: 'Doesn\'t Include' },
                                                                { value: 'BIGGER', label: 'Bigger Than' },
                                                                { value: 'SMALLER', label: 'Lower Than' },
                                                            ].filter(el => !!el)}
                                                            value={trendFormat[index]?.comparison}
                                                            onChange={data => {
                                                                setTrendFormat(trendFormat.map((el, i) => {
                                                                    if (i === index) {
                                                                        return {
                                                                            ...el,
                                                                            comparison: data
                                                                        }
                                                                    }
                                                                    return el
                                                                }))
                                                            }}
                                                        />

                                                        {includesSpecialCases ? null : (
                                                            <>
                                                                <Nbsp />
                                                                <Input
                                                                    className={cn.smallerTrendInput}
                                                                    value={trendFormat[index]?.value}
                                                                    onChange={e => {
                                                                        setTrendFormat(trendFormat.map((el, i) => {
                                                                            if (i === index) {
                                                                                return {
                                                                                    ...el,
                                                                                    value: e.target.value
                                                                                }
                                                                            }
                                                                            return el
                                                                        }))
                                                                    }}
                                                                />
                                                            </>
                                                        )}
                                                    </Row>
                                                </>
                                            )
                                        })}
                                        {trendColumnId?.length > 1 ? (
                                            <>
                                                <br />
                                                <Checkbox
                                                    checked={trendAggregate}
                                                    onClick={() => setTrendAggregate(!trendAggregate)}
                                                    label='Aggregate Results'
                                                />
                                            </>
                                        ) : null}

                                        <br />

                                        <Label fullWidth>
                                            Filter On
                                        </Label>
                                        {trendFilters.map((trendFilter, trendFilterIndex) => (
                                            <>
                                                {trendFilterIndex === 0 ? (
                                                    <SelectWrapperConnected
                                                        options={[{ label: ' - ', value: null }, ...accountFields]}
                                                        value={trendFilter?.field}
                                                        onChange={data => {
                                                            setTrendFilters(trendFilters.map((el, i) => {
                                                                if (i === trendFilterIndex) {
                                                                    return {
                                                                        ...el,
                                                                        field: data
                                                                    }
                                                                }
                                                                return el
                                                            }))
                                                        }}
                                                    />
                                                ) : <Row fullWidth noMargin>And</Row>}
                                                <br />
                                                <SelectWrapperConnected
                                                    options={[
                                                        ['date', 'datetime'].includes(trendFilters[0]?.field?.type) ? null : { value: 'EQUALS', label: 'Equals' },
                                                        ['date', 'datetime'].includes(trendFilters[0]?.field?.type) ? null : { value: 'DOES_NOT_EQUAL', label: 'Doesn\'t Equal' },
                                                        { value: 'BIGGER', label: 'Bigger Than' },
                                                        { value: 'SMALLER', label: 'Lower Than' },
                                                    ].filter(el => !!el)}
                                                    value={trendFilter?.comparison}
                                                    onChange={data => {
                                                        setTrendFilters(trendFilters.map((el, i) => {
                                                            if (i === trendFilterIndex) {
                                                                return {
                                                                    ...el,
                                                                    comparison: data
                                                                }
                                                            }
                                                            return el
                                                        }))
                                                    }}
                                                />
                                                <br />
                                                <Input
                                                    value={trendFilter?.value}
                                                    onChange={e => {
                                                        setTrendFilters(trendFilters.map((el, i) => {
                                                            if (i === trendFilterIndex) {
                                                                return {
                                                                    ...el,
                                                                    value: e.target.value
                                                                }
                                                            }
                                                            return el
                                                        }))
                                                    }}
                                                />
                                                {trendFilterIndex > 0 ? (
                                                    <>
                                                        <br />
                                                        <Button
                                                            onClick={() => setTrendFilters(trendFilters.filter((_el, i) => i !== trendFilterIndex))}
                                                            disabled={reportGenerationRequestSent || missingRiskTrendSetting}
                                                            fullWidth
                                                            type={BUTTON_TYPES.TRANSPARENT}
                                                        >
                                                            Delete Filter Condition
                                                        </Button>
                                                        <br />
                                                    </>)
                                                    : null}
                                                <br />
                                            </>
                                        ))}
                                        <br />
                                        <Button
                                            fullWidth
                                            disabled={reportGenerationRequestSent || missingRiskTrendSetting}
                                            onClick={() => setTrendFilters([...trendFilters, {}])}
                                        >
                                            Add another filter condition
                                        </Button>
                                    </>
                                )}
                            </Row>
                        </Row>
                    ) : (
                        <Loader type={LOADER_TYPES.SPINNER} size={LOADER_SIZES.BIG} />
                    )}
                </Modal>
            )}
        </>
    )
}

export const mapStateToProps = (state) => {
    return {
        user: state.user,
    }
}

export const RiskConnected = connect(mapStateToProps)(Risk)
