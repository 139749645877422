/* eslint-disable eqeqeq */
import React, { useEffect, useState } from 'react'
import { faTrash } from '@fortawesome/free-solid-svg-icons'
import { ViewContainer } from '../../../components/ViewContainer'
import { Row } from '../../../components/Row'
import { Dropdown } from '../../../components/Dropdown'
import { BUTTON_TYPES, Button } from '../../../components/Button'
import { Nbsp, NBSP_SIZES } from '../../../components/Nbsp'
import { Loader, LOADER_SIZES, LOADER_TYPES } from '../../../components/Loader'
import { Input } from '../../../components/Input'
import { Label } from '../../../components/Label'
import { SelectWrapperConnected } from '../../../components/SelectWrapper';
import { makeRequest } from '../../../utilities/endpoints'
import { URLS } from '../../../config'
import cn from './Risk.module.css'
import { Hr } from '../../../components/Hr'
import { Checkbox } from '../../../components/Checkbox'
import { translateCRMLabel } from '../../../utilities/translateCRMLabels'

const ADDITIONAL_COMPARISON_REQUIRED = ['SALESFORCE', 'MANUAL', 'ZENDESK', 'FRESHDESK']

const FormulaGroups = ({
    customColumns,
}) => {
    const [loading, setLoading] = useState(true)
    const [groups, setGroups] = useState([])
    const [newGroupName, setNewGroupName] = useState()
    const [newGroupValueOverLimit, setNewGroupValueOverLimit] = useState()
    const [newGroupValueUnderLimit, setNewGroupValueUnderLimit] = useState()
    const [newGroupValueOverValue, setNewGroupValueOverValue] = useState()
    const [newGroupValueUnderValue, setNewGroupValueUnderValue] = useState()
    const [newGroupValueOtherwise, setNewGroupValueOtherwise] = useState()
    const [newGroupColumns, setNewGroupColumns] = useState()

    const getColumnGroups = () => {
        setLoading(true)
        makeRequest.get(`${URLS.main}/risk/column-groups`)
            .then(res => {
                setGroups(res.data)
                setLoading(false)
            })
    }

    const deleteColumnGroup = id => {
        setLoading(true)
        makeRequest.delete(`${URLS.main}/risk/column-groups/${id}`)
            .then(getColumnGroups)
    }

    const addColumnGroup = data => {
        setLoading(true)
        makeRequest.post(`${URLS.main}/risk/column-groups`, data)
            .then(getColumnGroups)
    }

    useEffect(() => {
        getColumnGroups()
    }, [])

    if (loading) {
        return <Loader type={LOADER_TYPES.SPINNER} size={LOADER_SIZES.BIG} />
    }

    const disableNewButton = !newGroupName?.length || !newGroupColumns?.length || newGroupValueOverLimit === undefined || newGroupValueUnderLimit === undefined || newGroupValueOverValue === undefined || newGroupValueUnderValue === undefined
    return (
        <>
            <br/>
            <Row noMargin fullWidth>
                <Label>
                    Risk Column Groups
                </Label>
                <Nbsp size={NBSP_SIZES.BIG} />
                <i>
                    You can group up risk columns for more complex evaluations. Adding columns to a group.
                </i>
            </Row>
            {groups.map((group) => {
                const columns = group.columns.map(el => customColumns?.find(column => column.id === el)?.label).filter(el => !!el).join(', ')
                return (
                    <Row fullWidth spaceBetween>
                        <Row fullWidth noMargin className={cn.groupList}>
                            {group.name}
                            <Nbsp size={NBSP_SIZES.BIG} />
                            <div className={'clip'} data-tooltip-id='default' data-tooltip-content={columns}>
                                {columns}
                            </div>
                            <Nbsp size={NBSP_SIZES.BIG} />
                            If the value is<Nbsp/><u>over</u><Nbsp/>{group.valueOverLimit} the value will become {group.valueOverValue}.
                            <Nbsp size={NBSP_SIZES.BIG} />
                            If the value is<Nbsp/><u>under</u><Nbsp/>{group.valueUnderLimit} the value will become {group.valueUnderValue}.
                            <Nbsp size={NBSP_SIZES.BIG} />
                            Otherwise the value will become {group.otherwise}.
                        </Row>
                        <Button
                            type={BUTTON_TYPES.PADDED_ICON}
                            icon={faTrash}
                            onClick={() => deleteColumnGroup(group.id)}
                        />
                    </Row>
                )
            })}
            <Row fullWidth spaceBetween>
                <Row noMargin fullWidth>
                    <Row columnFlex fullWidth noAligning>
                        <Row fullWidth>
                            <Input
                                placeholder='Group Name'
                                value={newGroupName}
                                onChange={e => setNewGroupName(e.target.value)}
                            />
                            <Nbsp />
                            <SelectWrapperConnected
                                placeholder={'Select Columns'}
                                fullWidth
                                isMulti
                                options={customColumns.map(el => ({
                                    value: el.id,
                                    label: el.label,
                                }))}
                                value={newGroupColumns}
                                onChange={setNewGroupColumns}
                            />
                        </Row>
                        <Row>
                            <Label className={cn.groupTreshholdLabel1}>
                                If the group risk & health score value is <u>over</u>
                            </Label>
                            <Input
                                className={cn.groupTreshhold}
                                value={newGroupValueOverLimit}
                                onChange={e => setNewGroupValueOverLimit(e.target.value)}
                                type={'number'}
                            />
                            <Label>
                                update the valuation with
                            </Label>
                            <Input
                                className={cn.groupTreshhold}
                                value={newGroupValueOverValue}
                                onChange={e => setNewGroupValueOverValue(e.target.value)}
                                type={'number'}
                            />
                        </Row>
                        <Row>
                            <Label className={cn.groupTreshholdLabel1}>
                                If the group risk & health score value is <u>under</u>
                            </Label>
                            <Input
                                className={cn.groupTreshhold}
                                value={newGroupValueUnderLimit}
                                onChange={e => setNewGroupValueUnderLimit(e.target.value)}
                                type={'number'}
                            />
                            <Label>
                                update the valuation with
                            </Label>
                            <Input
                                className={cn.groupTreshhold}
                                value={newGroupValueUnderValue}
                                onChange={e => setNewGroupValueUnderValue(e.target.value)}
                                type={'number'}
                            />
                        </Row>
                        <Row>
                            <Label className={cn.groupTreshholdLabel1}>
                                Otherwise update the score with
                            </Label>
                            <Input
                                className={cn.groupTreshhold}
                                value={newGroupValueOtherwise}
                                onChange={e => setNewGroupValueOtherwise(e.target.value)}
                                type={'number'}
                            />
                        </Row>
                        <Row noMargin>
                            <Button
                                disabled={disableNewButton}
                                onClick={() => {
                                    addColumnGroup({
                                        name: newGroupName,
                                        columnIds: newGroupColumns.map(el => el.value).join(','),
                                        valueOverLimit: newGroupValueOverLimit * 1,
                                        valueOverValue: newGroupValueOverValue * 1,
                                        valueUnderLimit: newGroupValueUnderLimit * 1,
                                        valueUnderValue: newGroupValueUnderValue * 1,
                                        otherwise: newGroupValueOtherwise * 1,
                                    })
                                    setNewGroupName(undefined)
                                    setNewGroupColumns(undefined)
                                    setNewGroupValueOverLimit(undefined)
                                    setNewGroupValueUnderLimit(undefined)
                                    setNewGroupValueOverValue(undefined)
                                    setNewGroupValueUnderValue(undefined)
                                }}
                            >
                                Add
                            </Button>
                            {disableNewButton && <>
                                <Nbsp/>
                                <i>Please fill out all options first</i>
                            </>}
                        </Row>
                    </Row>
                </Row>
            </Row>
        </>
    )
}

const FormulaSection = ({
    formulaParts,
    index,
    formulaPart,
    updateDisableSaveButton,
    updateData,
    deleteData,
    salesforceObjectDescriptions,
    customColumns,
    customColumnSource,
    customColumnFormat,
    overridePart
}) => (
    <Row fullWidth className={cn.alignEnd} >
        <div className={cn.fullWidth}>
            <Row noMargin className={!overridePart ? cn.negativeMarginBottom : cn.smallerMarginBottom}>
                <Label>
                    Column
                </Label>
                {!overridePart && (
                    <Checkbox
                        checked={formulaPart.multiple}
                        label='Multiple'
                        onClick={() => {
                            const parts = [...formulaParts]
                            const newValue = !parts[index].multiple
                            parts[index] = {
                                ...parts[index],
                                multiple: newValue
                            }
                            if (newValue) {
                                parts[index].mathOperator = '*'
                            }
                            updateData(parts)
                            updateDisableSaveButton(false)
                        }}
                    />
                )}
            </Row>
            {!!formulaPart.multiple ? (
                <div>
                    <Dropdown
                        className={cn.marginBottom}
                        fullWidth
                        values={[
                            { value: undefined, label: '-' },
                            ...customColumns.map(el => ({
                                value: el.id,
                                label: el.label
                            }))
                        ]}
                        value={formulaPart?.columnId}
                        onChange={e => {
                            const parts = [...formulaParts]
                            parts[index] = {
                                ...parts[index],
                                columnId: e.target.value
                            }
                            updateData(parts)
                            updateDisableSaveButton(false)
                        }}
                    />
                    <br />
                    <Dropdown
                        className={cn.marginBottom}
                        fullWidth
                        value={formulaPart.mathOperator}
                        values={[
                            {
                                value: '*', label: '*',
                            },
                            {
                                value: '/', label: '/',
                            },
                            {
                                value: '+', label: '+',
                            },
                            {
                                value: '-', label: '-',
                            }
                        ]}
                        onChange={e => {
                            const parts = [...formulaParts]
                            parts[index] = {
                                ...parts[index],
                                mathOperator: e.target.value
                            }
                            updateData(parts)
                            updateDisableSaveButton(false)
                        }}
                    />
                    <br />
                    <Dropdown
                        fullWidth
                        values={[
                            { value: undefined, label: '-' },
                            ...customColumns.map(el => ({
                                value: el.id,
                                label: el.label
                            }))
                        ]}
                        value={formulaPart?.secondColumnId}
                        onChange={e => {
                            const parts = [...formulaParts]
                            parts[index] = {
                                ...parts[index],
                                secondColumnId: e.target.value
                            }
                            updateData(parts)
                            updateDisableSaveButton(false)
                        }}
                    />
                </div>
            ) : (
                <Dropdown
                    fullWidth
                    values={[
                        { value: undefined, label: '-' },
                        ...customColumns.map(el => ({
                            value: el.id,
                            label: el.label
                        }))
                    ]}
                    value={formulaPart?.columnId}
                    onChange={e => {
                        const parts = [...formulaParts]
                        parts[index] = {
                            ...parts[index],
                            columnId: e.target.value
                        }
                        updateData(parts)
                        updateDisableSaveButton(false)
                    }}
                />
            )}
        </div>
        <Nbsp />
        {ADDITIONAL_COMPARISON_REQUIRED.includes(customColumnSource) || customColumnFormat === 'CSV_UPLOAD' ? (
            <div className={cn.fullWidth}>
                {customColumnFormat === 'Checkbox' ? (
                    <div>
                        <Label className={cn.smallerMarginBottom}>
                            If this is true
                        </Label>
                        <Row noMargin>
                            <Dropdown
                                fullWidth
                                onChange={e => {
                                    const parts = [...formulaParts]
                                    parts[index] = {
                                        ...parts[index],
                                        comparisor: e.target.value
                                    }
                                    updateData(parts)
                                    updateDisableSaveButton(false)
                                }}
                                value={formulaPart?.comparisor}
                                values={[
                                    { label: '' },
                                    { label: 'Is Checked', value: 'CHECKED' },
                                    { label: 'Is Not Checked', value: 'NOT_CHECKED' },
                                ]}
                            />
                        </Row>
                    </div>
                ) : (
                    <div>
                        <Label className={cn.smallerMarginBottom}>
                            If these are true
                        </Label>
                        <Row noMargin>
                            <Dropdown
                                fullWidth
                                onChange={e => {
                                    const parts = [...formulaParts]
                                    parts[index] = {
                                        ...parts[index],
                                        comparisor: e.target.value
                                    }
                                    updateData(parts)
                                    updateDisableSaveButton(false)
                                }}
                                value={formulaPart?.comparisor}
                                values={[
                                    { label: '' },
                                    { label: 'Equals', value: 'EQUALS' },
                                    { label: 'Includes', value: 'INCLUDES' },
                                    { label: 'Starts With', value: 'STARTS_WITH' },
                                    { label: 'Ends With', value: 'ENDS_WITH' },
                                    { label: 'Between', value: 'BETWEEN' },
                                    { label: '>', value: 'BIGGER_THAN' },
                                    { label: '<', value: 'LESSER_THAN' },
                                ]}
                            />
                            <Nbsp />
                            <Input
                                fullWidth
                                onChange={e => {
                                    const parts = [...formulaParts]
                                    parts[index] = {
                                        ...parts[index],
                                        compareValue: e.target.value
                                    }
                                    updateData(parts)
                                    updateDisableSaveButton(false)
                                }}
                                value={formulaPart?.compareValue}
                            />
                        </Row>
                    </div>
                )}
            </div>
        ) : customColumns.find(el => el.id === parseInt(formulaPart.columnId))?.source === 'PRODUCT_USAGE' ? (
            <div className={cn.fullWidth}>
                <Label className={cn.smallerMarginBottom}>
                    If this is true
                </Label>
                <Row noMargin>
                    <Dropdown
                        fullWidth
                        onChange={e => {
                            const parts = [...formulaParts]
                            parts[index] = {
                                ...parts[index],
                                comparisor: e.target.value
                            }
                            updateData(parts)
                            updateDisableSaveButton(false)
                        }}
                        value={formulaPart?.comparisor}
                        values={[
                            { label: 'Equals', value: 'EQUALS' },
                            { label: '>', value: 'BIGGER_THAN' },
                            { label: '<', value: 'LESSER_THAN' },
                        ]}
                    />
                    <Nbsp />
                    <Input
                        fullWidth
                        onChange={e => {
                            const parts = [...formulaParts]
                            parts[index] = {
                                ...parts[index],
                                compareValue: e.target.value
                            }
                            updateData(parts)
                            updateDisableSaveButton(false)
                        }}
                        value={formulaPart?.compareValue}
                    />
                </Row>
            </div>
        ) : (
            <div className={cn.fullWidth}>
                <Label className={cn.smallerMarginBottom}>
                    If this is true
                </Label>
                <SecondPart
                    selectedFirstOption={formulaPart?.columnId}
                    customColumns={customColumns}
                    onChange={e => {
                        const parts = [...formulaParts]
                        const key = customColumnSource === 'CALLYPSO' ? 'comparisor' : 'compareValue'
                        parts[index] = {
                            ...parts[index],
                            [key]: e.target.value,
                        }
                        updateData(parts)
                        updateDisableSaveButton(false)
                    }}
                    value={formulaPart?.compareValue}
                    salesforceOptions={salesforceObjectDescriptions.Account}
                />
            </div>
        )}
        {!overridePart && (
            <>
                <Nbsp />
                <div className={cn.fullWidth}>
                    <Label className={cn.smallerMarginBottom}>
                        Update score with
                    </Label>
                    <Input
                        fullWidth
                        type='number'
                        onChange={e => {
                            const parts = [...formulaParts]
                            parts[index] = {
                                ...parts[index],
                                score: e.target.value
                            }
                            updateData(parts)
                            updateDisableSaveButton(false)
                        }}
                        value={formulaPart?.score}
                    />
                </div>
            </>
        )}
        <Nbsp />
        <Button
            type={BUTTON_TYPES.ICON}
            icon={faTrash}
            onClick={() => deleteData(index)}
            className={cn.deleteButton}
        />
    </Row>
)

const SecondPart = ({ selectedFirstOption, customColumns, onChange, value, salesforceOptions }) => {
    const selectedFirstOptionDetails = customColumns.find(el => el.id === parseInt(selectedFirstOption))

    switch (selectedFirstOptionDetails?.source) {
    case 'CALLYPSO':
        switch (selectedFirstOptionDetails.label) {
        case 'Meetings':
            return <Dropdown
                fullWidth
                values={[
                    { value: undefined, label: '-' },
                    {
                        value: 'NO_MEETINGS',
                        label: 'No Meetings Scheduled',
                    },
                    {
                        value: 'EVERYONE_HAS_CANCELLED',
                        label: 'All Guests Have Cancelled'
                    }
                ]}
                value={value}
                onChange={onChange}
            />
        case 'Contact(s) Left':
            return <Dropdown
                fullWidth
                values={[
                    { value: undefined, label: '-' },
                    {
                        value: 'CONTACT_LEFT',
                        label: 'Contact(s) Left',
                    },
                ]}
                value={value}
                onChange={onChange}
            />
        case 'Expiring Opportunities':
            return <Dropdown
                fullWidth
                values={[
                    { value: undefined, label: '-' },
                    {
                        value: 'EXPIRING_OPPORTUNITY',
                        label: `Expiring ${translateCRMLabel('Opportunities')}`,
                    },
                ]}
                value={value}
                onChange={onChange}
            />
        case 'Stalled Opportunities':
            return <Dropdown
                fullWidth
                values={[
                    { value: undefined, label: '-' },
                    {
                        value: 'STALLED_OPPORTUNITY',
                        label: `Stalled ${translateCRMLabel('Opportunities')}`,
                    },
                ]}
                value={value}
                onChange={onChange}
            />
        }
        break;
    case 'SALESFORCE':
        return <Dropdown
            fullWidth
            values={salesforceOptions}
            value={value}
            onChange={onChange}
        />
    default:
        if (selectedFirstOptionDetails?.format === 'CSV_UPLOAD') {
            return <Dropdown
                fullWidth
                values={salesforceOptions}
                value={value}
                onChange={onChange}
            />
        }
        return <Dropdown
            fullWidth
            values={[
                {
                    label: 'Please pick a columnId first',
                    value: null,

                },
            ]}
            disabled
            onChange={() => {}}
        />
    }
}

export const Risk = ({ divideSetting, onDivideSettingChange }) => {
    const basicOverrideParts = [{ color: 'green' }, { color: 'yellow' }, { color: 'red' }]
    const [formulaParts, setFormulaParts] = useState([{ score: 1 }])
    const [overrideParts, setOverrideParts] = useState(basicOverrideParts.slice(0))
    const [greenOverall, setGreenOverall] = useState()
    const [redOverall, setRedOverall] = useState()
    const [salesforceObjectDescriptions, setSalesforceObjectDescriptions] = useState({})
    const [startingNumber, setStartingNumber] = useState(0)
    const [customColumns, setCustomColumns] = useState([])
    const [disableSaveButton, setDisableSaveButton] = useState(true)

    const saveFormulaParts = () => {
        setDisableSaveButton(true)
        makeRequest.post(`${URLS.main}/risk/formula-parts`, {
            greenOverall,
            redOverall,
            startingNumber,
            formulaParts,
            overrideParts: overrideParts.filter(el => !!el.columnId && !!el.comparisor)
        })
    }

    const getSalesForceObjectDescription = objectName => {
        if (!salesforceObjectDescriptions[objectName]) {
            makeRequest.get(`${URLS.main}/describe/${objectName}`)
                .then(res => {
                    setSalesforceObjectDescriptions({
                        ...salesforceObjectDescriptions,
                        [objectName]: res.data?.fields
                    })
                })
        }
    }

    const getRiskSettings = () => {
        makeRequest.get(`${URLS.main}/risk/formula-parts`)
            .then(res => {
                setFormulaParts(res.data?.formulaParts.map(el => {
                    const obj = { ...el }
                    if (el.secondColumnId !== null && el.mathOperator !== null) {
                        obj.multiple = true
                    }
                    return obj
                }))
                const updatedOverrideParts = basicOverrideParts.slice(0)
                basicOverrideParts.forEach((el, i) => {
                    const override = res.data?.overrideParts.find(resData => resData.color === el.color)
                    if (override) {
                        updatedOverrideParts[i] = {
                            ...override,
                            ...basicOverrideParts[i]
                        }
                    }
                })
                setOverrideParts(updatedOverrideParts)
                setGreenOverall(res.data?.formulaSettings?.green)
                setRedOverall(res.data?.formulaSettings?.red)
                setStartingNumber(res.data?.formulaSettings?.startingNumber);
            })
    }

    const getCustomColumns = () => {
        makeRequest.get(`${URLS.main}/risk/custom-columns`)
            .then(res => {
                setCustomColumns(res.data)
            })
    }

    useEffect(() => {
        getSalesForceObjectDescription('Account')
        getCustomColumns()
        getRiskSettings()
    }, [])

    const addOption = () => {
        setDisableSaveButton(false)
        setFormulaParts([...formulaParts, { score: 1 }])
    }

    const deleteFormulaPart = index => {
        setDisableSaveButton(false)
        setFormulaParts(formulaParts.filter((_el, i) => i !== index))
    }

    const deleteOverridePart = index => {
        setDisableSaveButton(false)
        setOverrideParts(overrideParts.map((el, i) => {
            if (i !== index) {
                return el
            }
            return {
                color: el.color,
                columnId: undefined
            }
        }))
    }

    return <ViewContainer collapsible title='Risk'>
        {formulaParts.map((formulaPart, i) => {
            const column = customColumns.find(el => el.id === parseInt(formulaPart.columnId))
            const { source: customColumnSource, format: customColumnFormat } = column || {}
            return (
                <FormulaSection
                    formulaPart={formulaPart}
                    formulaParts={formulaParts}
                    index={i}
                    salesforceObjectDescriptions={salesforceObjectDescriptions}
                    updateData={setFormulaParts}
                    deleteData={() => deleteFormulaPart(i)}
                    updateDisableSaveButton={setDisableSaveButton}
                    customColumns={customColumns}
                    customColumnSource={customColumnSource}
                    customColumnFormat={customColumnFormat}
                />
            )
        })}
        <Hr />
        <FormulaGroups
            customColumns={customColumns}
        />
        <Hr />
        <Row fullWidth>
            <div className={cn.fullWidth}>
                <Label>
                    Overall risk is green when over
                </Label>
                <Input
                    fullWidth
                    onChange={e => {
                        setGreenOverall(e.target.value)
                        setDisableSaveButton(false)
                    }}
                    value={greenOverall}
                />
            </div>
            <Nbsp />
            <div className={cn.fullWidth}>
                <Label>
                    Overall risk is red when under
                </Label>
                <Input
                    fullWidth
                    onChange={e => {
                        setRedOverall(e.target.value)
                        setDisableSaveButton(false)
                    }}
                    value={redOverall}
                />
            </div>
            <Nbsp />
            <div className={cn.fullWidth}>
                <Label>
                    Risk starting number
                </Label>
                <Input
                    fullWidth
                    onChange={e => {
                        setStartingNumber(e.target.value)
                        setDisableSaveButton(false)
                    }}
                    value={startingNumber}
                />
            </div>
        </Row>
        <Row>
            <Label>
                Divide the calculated healthscore by:
            </Label>
            <Nbsp />
            <Input
                type='number'
                onChange={onDivideSettingChange}
                value={divideSetting}
            />
            <Nbsp />
            <Button
                type={BUTTON_TYPES.ICON}
                icon={faTrash}
                onClick={() => onDivideSettingChange('')}
            />
        </Row>
        <Hr />
        {overrideParts.map((overridePart, i) => {
            const column = customColumns.find(el => el.id === parseInt(overridePart.columnId))
            const { source: customColumnSource, format: customColumnFormat } = column || {}
            return (
                <Row fullWidth noMargin>
                    <div className={cn.overrideLabel}>
                        Override to<br/>{overridePart.color} if
                    </div>
                    <FormulaSection
                        overridePart
                        formulaPart={overridePart}
                        formulaParts={overrideParts}
                        index={i}
                        salesforceObjectDescriptions={salesforceObjectDescriptions}
                        updateData={setOverrideParts}
                        deleteData={() => deleteOverridePart(i)}
                        updateDisableSaveButton={setDisableSaveButton}
                        customColumns={customColumns}
                        customColumnSource={customColumnSource}
                        customColumnFormat={customColumnFormat}
                    />
                </Row>
            )
        })}
        <Hr/>
        <Row spaceBetween fullWidth>
            <Button
                onClick={addOption}
            >
                Add Formula Part
            </Button>
            <Button
                disabled={disableSaveButton}
                onClick={saveFormulaParts}
            >
                {disableSaveButton ? 'Saved' : 'Save'}
            </Button>
        </Row>
    </ViewContainer>
}