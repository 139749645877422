import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { faCalculator, faTrash } from '@fortawesome/free-solid-svg-icons'
import { Dropdown } from '../../components/Dropdown';
import { BUTTON_TYPES, Button } from '../../components/Button';
import { Label } from '../../components/Label';
import { Row } from '../../components/Row';
import { Input } from '../../components/Input';
// import { CHECKBOX_SIZE, Checkbox } from '../../components/Checkbox';
import { SubHeader } from '../../components/SubHeader';
import { Checkbox } from '../../components/Checkbox';
import { ViewContainer } from '../../components/ViewContainer';
import cn from './CreateRules.module.css'
import { makeRequest } from '../../utilities/endpoints'
import { URLS } from '../../config'
import { comparisonOperators } from '../../enums/comparisonOperators';
import { getUserPermissions } from '../../utilities/permissionCheck';
import { workspaceTypeCheck } from '../../utilities/workspaceType'
import { translateCRMLabel } from '../../utilities/translateCRMLabels';
import { SelectWrapperConnected } from '../../components/SelectWrapper';
import { Nbsp } from '../../components/Nbsp';
// import { Nbsp } from '../../components/Nbsp';

const RULE_OPTIONS = {
    INACTIVE_FOR: 'INACTIVE_FOR',
    RISK_DETERIORATED: 'RISK_DETERIORATED',
    RISK_IMPROVED: 'RISK_IMPROVED',
    DAY_OF_THE_MONTH: 'DAY_OF_THE_MONTH',
    // NO_EMAIL_RECEIVED: 'NO_EMAIL_RECEIVED',
    // NO_EMAIL_SENT: 'NO_EMAIL_SENT',
    COMPANY_SIZE_BIGGER_THAN: 'COMPANY_SIZE_BIGGER_THAN',
    COMPANY_SIZE_LESSER_THAN: 'COMPANY_SIZE_LESSER_THAN',
    OPPORTUNITY_STATE: 'OPPORTUNITY_STATE',
    PRE_CLOSE_DATE_CHECK: 'PRE_CLOSE_DATE_CHECK',
    KEY_CONTACT_LEFT: 'KEY_CONTACT_LEFT',
}

const RowInputPart = ({
    rule, rulesBlockIndex, i, ruleChanged, opportunityStages, contactRoles
}) => {
    switch (rule.rule) {
    case RULE_OPTIONS.OPPORTUNITY_STATE:
        return (
            <Dropdown
                className={cn.rowInputDropdown}
                fullWidth
                onChange={e => ruleChanged(rulesBlockIndex, i, { value: e.target.value })}
                value={rule.value}
                values={opportunityStages.stages}
            />
        )
    case RULE_OPTIONS.KEY_CONTACT_LEFT:
        return (
            <Dropdown
                className={cn.rowInputDropdown}
                fullWidth
                onChange={e => ruleChanged(rulesBlockIndex, i, { value: e.target.value })}
                value={rule.value}
                values={contactRoles}
            />
        )
    case RULE_OPTIONS.RISK_DETERIORATED:
    case RULE_OPTIONS.RISK_IMPROVED:
        return (
            <Dropdown
                className={cn.rowInputDropdown}
                fullWidth
                onChange={e => ruleChanged(rulesBlockIndex, i, { value: e.target.value })}
                value={rule.value}
                values={[
                    { label: 'Select a color', value: undefined },
                    { label: 'Red', value: 'RED' },
                    { label: 'Yellow', value: 'YELLOW' },
                    { label: 'Green', value: 'GREEN' },
                ]}
            />
        )
    default:
        const ruleParts = rule?.rule?.split('---')
        const customObj = ruleParts?.length === 2 ? ruleParts[0] : null

        return (
            <>
                { ![
                    RULE_OPTIONS.PRE_CLOSE_DATE_CHECK,
                    RULE_OPTIONS.COMPANY_SIZE_BIGGER_THAN,
                    RULE_OPTIONS.COMPANY_SIZE_LESSER_THAN,
                ].includes(rule.rule)
                    && rule.rule !== RULE_OPTIONS.INACTIVE_FOR && (
                    <Dropdown
                        className={cn.comparidonDropdown}
                        onChange={e => ruleChanged(rulesBlockIndex, i, { secondValue: e.target.value })}
                        value={rule.secondValue}
                        values={[
                            { label: 'Is exactly', value: comparisonOperators.EXACT_MATCH },
                            { label: 'Any Change Of This Property', value: comparisonOperators.ANY_CHANGE },
                            { label: 'Includes', value: comparisonOperators.INCLUDES },
                            { label: 'Doesn\t Include', value: comparisonOperators.DOESNT_INCLUDE },
                            { label: 'Starts With', value: comparisonOperators.STARTS_WITH },
                            { label: 'Ends With', value: comparisonOperators.ENDS_WITH },
                            { label: '>', value: comparisonOperators.BIGGER },
                            { label: '>=', value: comparisonOperators.BIGGER_OR_EQUAL },
                            { label: '<', value: comparisonOperators.SMALLER },
                            { label: '<=', value: comparisonOperators.SMALLER_OR_EQUAL },
                            ...(customObj && !['Account', 'Opportunity', 'Contact', 'deal', 'company', 'contact'].includes(customObj) ? [
                                { label: 'Count for the last active day is equal to', value: 'LAST_ACTIVE_COUNT_DAY_EXACT_MATCH' },
                                { label: 'Count for the last active day is bigger to', value: 'LAST_ACTIVE_COUNT_DAY_BIGGER' },
                                { label: 'Count for the last active day is less than', value: 'LAST_ACTIVE_COUNT_DAY_SMALLER' },
                                { label: 'Count for this week is equal to', value: 'THIS_WEEK_COUNT_EXACT_MATCH' },
                                { label: 'Count for this week is bigger to', value: 'THIS_WEEK_COUNT_BIGGER' },
                                { label: 'Count for this week is less than', value: 'THIS_WEEK_COUNT_SMALLER' },
                                { label: 'Count for last 30 days is equal to', value: 'LAST_30_DAYS_COUNT_EXACT_MATCH' },
                                { label: 'Count for last 30 days is bigger to', value: 'LAST_30_DAYS_COUNT_BIGGER' },
                                { label: 'Count for last 30 days is less than', value: 'LAST_30_DAYS_COUNT_SMALLER' },
                                { label: 'Count for this month is equal to', value: 'THIS_MONTH_COUNT_EXACT_MATCH' },
                                { label: 'Count for this month is bigger to', value: 'THIS_MONTH_COUNT_BIGGER' },
                                { label: 'Count for this month is less than', value: 'THIS_MONTH_COUNT_SMALLER' },
                                { label: 'Count for the first 5 days of this month is equal to', value: 'FIRST_5_DAYS_THIS_MONTH_COUNT_EXACT_MATCH' },
                                { label: 'Count for the first 5 days of this month is bigger to', value: 'FIRST_5_DAYS_THIS_MONTH_COUNT_BIGGER' },
                                { label: 'Count for the first 5 days of this month is less than', value: 'FIRST_5_DAYS_THIS_MONTH_COUNT_SMALLER' },
                                { label: 'Count for the first 15 days of this month is equal to', value: 'FIRST_15_DAYS_THIS_MONTH_COUNT_EXACT_MATCH' },
                                { label: 'Count for the first 15 days of this month is bigger to', value: 'FIRST_15_DAYS_THIS_MONTH_COUNT_BIGGER' },
                                { label: 'Count for the first 15 days of this month is less than', value: 'FIRST_15_DAYS_THIS_MONTH_COUNT_SMALLER' },
                                { label: 'Count for the first 15 days of this month is X% lower than last month', value: 'FIRST_15_DAYS_THIS_MONTH_COUNT_LESS_THAN_X_OF_LAST_MONTH' },
                            ] : [])
                        ]}
                    />
                )}
                {rule.secondValue === 'ANY_CHANGE' ? null : (
                    <Input
                        value={rule.value || ''}
                        data-tooltip-id='default'
                        data-tooltip-content={rule.rule !== RULE_OPTIONS.PRE_CLOSE_DATE_CHECK && !rule.lastDayOnly
                            ? 'You can leave this field empty to get notified for all changes to it'
                            : 'Do not leave the field empty in this scenario'}
                        placeHolder={rule.rule !== RULE_OPTIONS.PRE_CLOSE_DATE_CHECK && !rule.lastDayOnly
                            ? 'You can leave this field empty to get notified for all changes to it'
                            : 'Do not leave the field empty in this scenario'}
                        onChange={e => ruleChanged(rulesBlockIndex, i, { value: e.target.value })}
                    />
                )}
            </>
        )
    }
}

const RowPostInputPart = ({
    selected, rule, rulesBlockIndex, i, ruleChanged, opportunityStages
}) => {
    switch (selected) {
    case RULE_OPTIONS.INACTIVE_FOR:
        return <Label className={cn.clarificationOffset}>days</Label>
        // case RULE_OPTIONS.NO_EMAIL_RECEIVED:
        // case RULE_OPTIONS.NO_EMAIL_SENT:
        //     return <Label className={cn.clarificationOffset}>days</Label>
    case RULE_OPTIONS.COMPANY_SIZE_BIGGER_THAN:
    case RULE_OPTIONS.COMPANY_SIZE_LESSER_THAN:
        return <Label className={cn.clarificationOffset}>people</Label>
    case RULE_OPTIONS.OPPORTUNITY_STATE:
        return (
            <Dropdown
                className={cn.clarificationOffset}
                fullWidth
                onChange={e => ruleChanged(rulesBlockIndex, i, { secondValue: e.target.value })}
                value={rule.secondValue}
                values={[
                    { label: 'all', value: null },
                    ...opportunityStages.types
                ]}
            />
        )
    default:
        return null
    }
}

export const CreateRules = () => {
    getUserPermissions()
    const history = useHistory()
    const emptyRulesBlock = { combination: 'all', rules: [{}] }
    const [rulesBlocks, setRulesBlocks] = useState([emptyRulesBlock])
    const [mainCombiningRule, setMainCombiningRule] = useState('all')
    const [requestSent, setRequestSent] = useState(false)
    const [customFields, setCustomFields] = useState([])
    const [customObjects, setCustomObjects] = useState([])
    const [opportunityStages, setOpportunityStages] = useState({ types: [], stages: [] })
    const [contactRoles, setContactRoles] = useState([])
    const [playbookId, setPlaybookId] = useState()
    const [playbooks, setPlaybooks] = useState([])
    const [cooldown, setCooldown] = useState()
    const [retroactiveCheck, setRetroactiveCheck] = useState()
    const { id } = useParams();

    const getPlaybooks = () => {
        makeRequest.get(`${URLS.main}/playbooks`)
            .then(res => {
                setPlaybooks(res.data?.playbooks || [])
                if (!id) {
                    setPlaybookId(res.data?.playbooks && res.data?.playbooks.length && res.data?.playbooks[0]?.id)
                }
            })
    }

    useEffect(() => {
        makeRequest.get(`${URLS.main}/customFields`)
            .then(res => {
                if (res && res.status === 200) {
                    setCustomFields(res.data)
                }
            })
        makeRequest.get(`${URLS.main}/customObjects`)
            .then(res => {
                if (res && res.status === 200) {
                    setCustomObjects(res.data)
                }
            })
        makeRequest.get(`${URLS.main}/opportunities/stages`)
            .then(res => {
                if (res && res.status === 200) {
                    setOpportunityStages(res.data)
                }
            })
        makeRequest.get(`${URLS.main}/accountMapContactRoles`)
            .then(res => {
                if (res && res.status === 200) {
                    setContactRoles(res.data.map(el => ({
                        value: el.id,
                        label: el.name
                    })))
                }
            })
        if (id !== undefined) {
            makeRequest.get(`${URLS.main}/rule/${id}`)
                .then(res => {
                    const blocks = []
                    res.data.rulesDetails.forEach(ruleBlock => {
                        if (!blocks[ruleBlock.rulesBlockId]) {
                            blocks[ruleBlock.rulesBlockId] = {
                                rules: []
                            }
                        }
                        blocks[ruleBlock.rulesBlockId].combination = ruleBlock.combiningOption
                        blocks[ruleBlock.rulesBlockId].rules.push({
                            rule: ruleBlock.rule,
                            value: ruleBlock.value,
                            secondValue: ruleBlock.secondValue,
                            multiple: ruleBlock.multiple,
                            mathOperation: ruleBlock.mathOperation,
                            lastDayOnly: ruleBlock.lastDayOnly
                        })
                    })
                    setMainCombining(res.data.mainDetails.combiningOption)
                    setRulesBlocks(blocks.filter(el => !!el))
                    setPlaybookId(res.data.mainDetails.playbookId)
                    setCooldown(res.data.mainDetails.cooldown)
                })
        }
        getPlaybooks()
    }, [])

    const create = () => {
        setRequestSent(true)
        makeRequest.post(`${URLS.main}/rule`, {
            combiningOption: mainCombiningRule,
            playbookId: playbookId,
            cooldown,
            retroactiveCheck,
            rulesBlocks,
            id,
        })
            .then(res => {
                if (res && res.status === 200) {
                    history.push('/rules/0/null')
                }
            })
    }

    const addRulesBlock = () => {
        setRulesBlocks([...rulesBlocks, emptyRulesBlock])
    }

    const addRule = (rulesBlockIndex, ruleIndex, multiple) => {
        setRulesBlocks(rulesBlocks.map((el, i) => {
            if (i === rulesBlockIndex) {
                // just adding a new one at the end of the stack
                if (ruleIndex === undefined) {
                    return {
                        ...el,
                        rules: [...el.rules, {}]
                    }
                }
                const newRules = el.rules.toSpliced(ruleIndex, 0, {
                    multiple
                })
                return {
                    ...el,
                    rules: newRules
                }
            }
            return el
        }))
    }

    const setMainCombining = (value) => setMainCombiningRule(value)

    const addMultiple = (rulesBlockIndex, ruleIndex) => {
        addRule(rulesBlockIndex, ruleIndex + 1, true)
    }

    const removeRule = (rulesBlockIndex, ruleIndex) => {
        const newRulesBlocks = rulesBlocks.map((el, i) => {
            if (i === rulesBlockIndex) {
                const newRules = el.rules.filter((_el, i) => i !== ruleIndex)
                if (el.rules[ruleIndex] && el.rules[ruleIndex].multiple) {
                    delete newRules[ruleIndex].multiple
                }
                return {
                    ...el,
                    rules: newRules
                }
            }
            return el
        })
        setRulesBlocks(newRulesBlocks.filter(block => !!block.rules.length))
    }

    const ruleChanged = (rulesBlockIndex, index, data) => {
        setRulesBlocks(rulesBlocks.map((el, i) => {
            if (i === rulesBlockIndex) {
                return {
                    ...el,
                    rules: el.rules.map((rule, indexInner) => {
                        if (index === indexInner) {
                            if (data.rule) {
                                return ({
                                    ...rule,
                                    rule: data.rule,
                                    secondValue: undefined,
                                    value: undefined
                                })
                            }
                            if (data.secondValue) {
                                return ({
                                    ...rule,
                                    secondValue: data.secondValue
                                })
                            }
                            if (data.lastDayOnly) {
                                return ({
                                    ...rule,
                                    lastDayOnly: true,
                                })
                            }
                            if (data.mathOperation) {
                                return ({
                                    ...rule,
                                    mathOperation: data.mathOperation
                                })
                            }
                            return ({
                                ...rule,
                                value: data.value
                            })
                        }
                        return rule
                    })
                }
            }
            return el
        }))
    }

    const setBlockCombination = (rulesBlockIndex, value) => {
        setRulesBlocks(rulesBlocks.map((rulesBlock, index) => {
            if (index === rulesBlockIndex) {
                return {
                    ...rulesBlock,
                    combination: value
                }
            }
            return rulesBlock
        }))
    }

    const unFilledRules = () => {
        return !rulesBlocks.length || !!rulesBlocks.flat().map(el => el.rules).flat().find(el => !el.rule)
    }

    const getOptions = () => {
        const options = [
            (workspaceTypeCheck(['SFDC', 'SFDC_SANDBOX']) ? {
                label: "Time Based",
                options: [
                    { label: "No Activity for over", value: RULE_OPTIONS.INACTIVE_FOR },
                    { label: "Day of the month is", value: RULE_OPTIONS.DAY_OF_THE_MONTH },
                    // { label: "No Email received for over", value: RULE_OPTIONS.NO_EMAIL_RECEIVED },
                    // { label: "No Email sent for over", value: RULE_OPTIONS.NO_EMAIL_RECEIVED },
                ]
            } : null),
            {
                label: "Risk Based",
                options: [
                    { label: "Risk deteriorated ", value: RULE_OPTIONS.RISK_DETERIORATED },
                    { label: "Risk improved ", value: RULE_OPTIONS.RISK_IMPROVED },
                ]
            },
            {
                label: `${translateCRMLabel('Opportunity')} Based`,
                options: [
                    { label: `${translateCRMLabel('Opportunity')} has reached following stage`, value: RULE_OPTIONS.OPPORTUNITY_STATE },
                    { label: "Days before the Close Date is reached", value: RULE_OPTIONS.PRE_CLOSE_DATE_CHECK },
                ]
            },
            (workspaceTypeCheck(['SFDC', 'SFDC_SANDBOX', 'HUBSPOT']) ? {
                label: "Company Size Based",
                options: [
                    { label: "Company size is BIGGER than", value: RULE_OPTIONS.COMPANY_SIZE_BIGGER_THAN },
                    { label: "Company size is LESSER than", value: RULE_OPTIONS.COMPANY_SIZE_LESSER_THAN }
                ]
            } : null),
        ].filter(el => !!el)

        if (customFields) {
            Object.keys(customFields).forEach(key => {
                options.push({
                    label: key,
                    options: customFields[key]
                })
            })
        }

        if (customObjects) {
            Object.values(customObjects).forEach(value => {
                options.push({
                    label: value.label,
                    options: value.fields
                })
            })
        }

        return options
    }

    const getSelectedValueForReactSelect = value => {
        const options = getOptions()
        for (const el of options) {
            for (const childOption of el.options) {
                if (childOption.value === value) return childOption
            }
        }
        return null
    }

    return (
        <div>
            <ViewContainer>
                <SubHeader>
                    Playbook
                </SubHeader>
                <br />
                <Dropdown
                    fullWidth
                    value={playbookId}
                    values={playbooks.map(el => ({
                        value: el.id,
                        label: el.name
                    }))}
                    onChange={e => setPlaybookId(e.target.value)}
                />
                <br />
                <br />
                <Input
                    placeholder='Repeat task if conditions are matched after X days. Ignore if you need it for one off events.'
                    fullWidth
                    value={cooldown}
                    onChange={e => setCooldown(e.target.value)}
                    type='number'
                />
                <br />
                <Checkbox
                    label='Check for these rules and triggers retroactively'
                    fullWidth
                    checked={!!retroactiveCheck}
                    onClick={() => setRetroactiveCheck(!retroactiveCheck)}
                />
                <i>
                    This field is only valid on creation. Afterwards Callypso will always monitor for changes
                </i>
            </ViewContainer>
            {rulesBlocks.length > 1 && (
                <ViewContainer>
                    <Row>
                        <Label>
                            Trigger the associated Playbook if
                        </Label>
                        <Nbsp />
                        <Dropdown
                            className={cn.smallDropdown}
                            value={mainCombiningRule || 'all'}
                            values={[
                                {
                                    label: 'all',
                                    value: 'all'
                                },
                                {
                                    label: 'any',
                                    value: 'any'
                                }
                            ]}
                            onChange={e => setMainCombining(e.target.value)}
                        />
                        <Label>
                            of the rules blocks are matched
                        </Label>
                    </Row>
                </ViewContainer>
            )}
            {rulesBlocks.map((rulesBlock, rulesBlockIndex) => (
                <ViewContainer key={rulesBlockIndex}>
                    <SubHeader>
                        RULES BLOCK
                    </SubHeader>
                    {rulesBlock.rules.length > 1 && (
                        <Row>
                            <Label>
                                Combine rules bellow if
                            </Label>
                            <Dropdown
                                value={rulesBlock.combination || 'all'}
                                className={cn.smallDropdown}
                                values={[
                                    {
                                        label: 'all',
                                        value: 'all'
                                    },
                                    {
                                        label: 'any',
                                        value: 'any'
                                    }
                                ]}
                                onChange={e => setBlockCombination(rulesBlockIndex, e.target.value)}
                            />
                            <Label>
                                of them are matched
                            </Label>
                        </Row>
                    )}
                    {rulesBlock.rules.map((rule, i) => (
                        <>
                            {rule.multiple && (
                                <Row>
                                    <Label required>
                                        Math Operation
                                    </Label>
                                    <Dropdown
                                        value={rule.mathOperation}
                                        values={[
                                            { label: '*', value: '*' },
                                            { label: '/', value: '/' },
                                            { label: '+', value: '+' },
                                            { label: '-', value: '-' },
                                        ]}
                                        onChange={e => {
                                            ruleChanged(rulesBlockIndex, i, { mathOperation: e.target.value })
                                        }}
                                    />
                                </Row>
                            )}
                            <Row key={i}>
                                <Label required>
                                    Rule
                                </Label>
                                <Nbsp />
                                <SelectWrapperConnected
                                    className={cn.ruleDropdown}
                                    onChange={data => ruleChanged(rulesBlockIndex, i, { rule: data.value })}
                                    options={getOptions()}
                                    value={getSelectedValueForReactSelect(rule.rule)}
                                />
                                <Nbsp />
                                {!rule.multiple && !rulesBlock.rules[i + 1]?.multiple && (
                                    <>
                                        <RowInputPart rule={rule} i={i} rulesBlockIndex={rulesBlockIndex} ruleChanged={ruleChanged} opportunityStages={opportunityStages} contactRoles={contactRoles} />
                                        <RowPostInputPart selected={rule.rule} rule={rule} i={i} rulesBlockIndex={rulesBlockIndex} ruleChanged={ruleChanged} opportunityStages={opportunityStages} contactRoles={contactRoles} />
                                        <Nbsp />
                                    </>
                                )}
                                {rule.lastDayOnly && (
                                    <>
                                        <Button
                                            type={BUTTON_TYPES.ICON}
                                            icon={faCalculator}
                                            onClick={() => addMultiple(rulesBlockIndex, i)}
                                        />
                                        <Nbsp />
                                    </>
                                )}
                                <Button
                                    type={BUTTON_TYPES.ICON}
                                    icon={faTrash}
                                    onClick={() => removeRule(rulesBlockIndex, i)}
                                />
                            </Row>
                            {rule.multiple && !rulesBlock.rules[i + 1]?.multiple && (
                                <Row>
                                    <Label required>
                                        Math evaluation result
                                    </Label>
                                    <RowInputPart rule={rule} i={i} rulesBlockIndex={rulesBlockIndex} ruleChanged={ruleChanged} opportunityStages={opportunityStages} contactRoles={contactRoles} />
                                    <RowPostInputPart selected={rule.rule} rule={rule} i={i} rulesBlockIndex={rulesBlockIndex} ruleChanged={ruleChanged} opportunityStages={opportunityStages} contactRoles={contactRoles} />
                                </Row>
                            )}
                        </>
                    ))}
                    <Row spaceBetween>
                        <div className={cn.buttonWrapper}>
                            <Button onClick={() => addRule(rulesBlockIndex)}>
                                + ADD RULE
                            </Button>
                        </div>
                    </Row>
                </ViewContainer>
            ))}
            <ViewContainer>
                <Row spaceBetween>
                    <div className={cn.buttonWrapper}>
                        <Button onClick={addRulesBlock}>
                            + ADD RULE BLOCK
                        </Button>
                    </div>
                    <div className={cn.buttonWrapper}>
                        <Button
                            disabled={unFilledRules() || requestSent}
                            onClick={create}
                        >
                            {id ? 'SAVE' : 'CREATE'}
                        </Button>
                    </div>
                </Row>
            </ViewContainer>
        </div>
    );
}

export const mapStateToProps = () => {
    return {
    }
}

export const CreateRulesConnected = connect(mapStateToProps)(CreateRules)
