import moment from 'moment';

export const dateFormat = (dateStr, type='date') => {
    if (dateStr === null || dateStr === 0) {
        return "None";
    }

    const dt = moment(dateStr).locale('en-us');

    if (type === 'datetime') {
        return `${dt.format('MMMM DD, YYYY hh:mm:ss a')}`;
    }
    return `${dt.format('MMMM DD, YYYY')}`;
}
