import React, { useEffect, useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import cn from './Playbook.module.css'
import { ViewContainer } from '../../../components/ViewContainer';
import { Heading } from '../../../components/Heading';
import { Row } from '../../../components/Row';
import { Label } from '../../../components/Label';
import { Input } from '../../../components/Input';
import { makeRequest } from '../../../utilities/endpoints';
import { URLS } from '../../../config';
import { SlateEditor } from '../../../components/SlateEditor';
import { SubHeader } from '../../../components/SubHeader';
import { BUTTON_TYPES, Button } from '../../../components/Button';
import { NBSP_SIZES, Nbsp } from '../../../components/Nbsp';
import { PopoverGithubPicker } from '../../../components/PopoverGithubPicker';


export const Playbook = () => {
    const { id } = useParams()
    const history = useHistory()
    const [name, setName] = useState()
    const [description, setDescription] = useState({})
    const [plays, setPlays] = useState([])
    const [requestSent, setRequestSent] = useState(false)

    const creating = id === 'create'

    const getDetails = id => {
        makeRequest.get(`${URLS.main}/playbook/${id}`)
            .then(res => {
                setName(res.data?.name)
                setPlays(res.data?.plays.sort((a, b) => a.position - b.position))
                setDescription(res.data?.description)
            })
    }

    const submit = async () => {
        setRequestSent(true)
        if (creating) {
            await makeRequest.post(`${URLS.main}/playbooks`, {
                name,
                description,
                plays
            })
        } else {
            await makeRequest.put(`${URLS.main}/playbook/${id}`, {
                name,
                description,
                plays
            })
        }
        history.push('/playbooks/0')
    }

    useEffect(() => {
        if (!creating) {
            getDetails(id)
        }
    }, [id])

    const onDragEnd = ({ destination, source }) => {
        const movedPlay = plays[source.index]
        let playsCopy = plays.toSpliced(source.index, 1)
        playsCopy.splice(destination.index, 0, movedPlay)
        playsCopy = playsCopy.map((el, i) => ({
            ...el,
            position: i
        }))
        setPlays(playsCopy)
    }

    const filledOutPlays = plays.filter(el => !!el.name && !!el.description && !!el.color && !!el.dueDateDays).length

    const disableCreate = requestSent || !name || !name.length || !description || !description.length || !filledOutPlays || filledOutPlays !== plays?.length;

    return (
        <>
            <Row className={cn.heading} spaceBetween>
                <Heading>{creating ? 'Create' : `Edit "${name}"`} Playbook</Heading>
                <Row noMargin>
                    <Button
                        disabled={disableCreate}
                        onClick={submit}
                    >
                        {creating ? 'Create' : 'Update'}
                    </Button>
                    <Nbsp size={NBSP_SIZES.BIG} />
                </Row>
            </Row>
            <ViewContainer noGrow>
                <SubHeader>Playbook Details</SubHeader>
                <Row fullWidth noAligning columnFlex>
                    <Label>Name</Label>
                    <Input fullWidth onChange={e => setName(e.target.value)} value={name} />
                </Row>
                <Label>Description</Label>
                {
                    creating ? (
                        <SlateEditor
                            fullWidth
                            value={description}
                            key={'main-description-slate'}
                            onChange={setDescription}
                        />
                    ) : (
                        <SlateEditor
                            fullWidth
                            value={!!description ? description : ''}
                            key={'main-description-slate'}
                            onChange={setDescription}
                        />
                    )
                }
            </ViewContainer>
            <ViewContainer>
                <SubHeader>Plays</SubHeader>
                <DragDropContext
                    onDragEnd={onDragEnd}
                >
                    <div className={cn.column}>
                        <Droppable
                            droppableId={'1'}
                            ignoreContainerClipping={true}
                        >
                            {(provided, snapshot) => {
                                return (
                                    <div
                                        {...provided.droppableProps}
                                        ref={provided.innerRef}
                                        className={`${cn.columnContainer} ${snapshot.isDraggingOver && cn.draggingOver}`}
                                    >
                                        {plays.map((play, index) => {
                                            return (
                                                <Draggable
                                                    key={play.id || -index}
                                                    draggableId={`${play.id || -index}`}
                                                    index={index}
                                                >
                                                    {(provided/* , snapshot */) => {
                                                        return (
                                                            <div
                                                                className={cn.draggablePlay}
                                                                ref={provided.innerRef}
                                                                {...provided.draggableProps}
                                                                {...provided.dragHandleProps}
                                                                style={provided.draggableProps.style}
                                                            >
                                                                <Row>
                                                                    <Label required preventBreaks>Play Name</Label>
                                                                    <Nbsp />
                                                                    <Input
                                                                        fullWidth
                                                                        value={play.name}
                                                                        onChange={e => setPlays(plays.map((_el, innerI) => {
                                                                            if (innerI === index) {
                                                                                return {
                                                                                    ..._el,
                                                                                    name: e.target.value
                                                                                }
                                                                            }
                                                                            return _el
                                                                        }))}
                                                                    />
                                                                    <Nbsp size={NBSP_SIZES.BIG} />
                                                                    <Button
                                                                        type={BUTTON_TYPES.ICON}
                                                                        icon={faTrashAlt}
                                                                        onClick={() => setPlays(plays.filter((_el, innerI) => index !== innerI))}
                                                                    />
                                                                </Row>
                                                                <Row noMargin>
                                                                    <Label required noMargin>
                                                                        Color
                                                                    </Label>
                                                                    <Nbsp />
                                                                    <PopoverGithubPicker
                                                                        color={play.color || '#A0FFBB'}
                                                                        onChange={e => setPlays(plays.map((_el, innerI) => {
                                                                            if (innerI === index) {
                                                                                return {
                                                                                    ..._el,
                                                                                    color: e
                                                                                }
                                                                            }
                                                                            return _el
                                                                        }))}
                                                                    />
                                                                </Row>
                                                                <br />
                                                                <Label required>Play Description</Label>
                                                                <Nbsp />
                                                                <SlateEditor
                                                                    className={cn.slateEditor}
                                                                    fullWidth
                                                                    value={play.description}
                                                                    key={`play-${play.id || play.name || -index}-description`}
                                                                    onChange={e => setPlays(plays.map((_el, innerI) => {
                                                                        if (innerI === index) {
                                                                            return {
                                                                                ..._el,
                                                                                description: e
                                                                            }
                                                                        }
                                                                        return _el
                                                                    }))}
                                                                />
                                                                <Row>
                                                                    <Label
                                                                        required
                                                                        className={cn.dueDateLabel}
                                                                    >
                                                                        Number of days until this play is due<br/>{index === 0 ? 'after the playbook is triggered' : 'after the previous play is completed'}
                                                                    </Label>
                                                                    <Nbsp />
                                                                    <Input
                                                                        fullWidth
                                                                        type={'number'}
                                                                        value={play.dueDateDays}
                                                                        min='1'
                                                                        onChange={e => setPlays(plays.map((_el, innerI) => {
                                                                            if (innerI === index) {
                                                                                return {
                                                                                    ..._el,
                                                                                    dueDateDays: Math.abs(e.target.value)
                                                                                }
                                                                            }
                                                                            return _el
                                                                        }))}
                                                                    />
                                                                </Row>
                                                            </div>
                                                        );
                                                    }}
                                                </Draggable>
                                            );
                                        })}
                                        {provided.placeholder}
                                    </div>
                                );
                            }}
                        </Droppable>
                    </div>
                </DragDropContext>
                <Row endAlign>
                    <Button
                        onClick={() => setPlays([...plays, {}])}
                    >
                        Add Another Play
                    </Button>
                </Row>
            </ViewContainer>
        </>
    )
}
