import React, { useEffect, useState } from 'react'
import { Line } from 'react-chartjs-2'
import moment from 'moment'
import cn from './UsageGraph.module.css'
import { makeRequest } from '../../../../utilities/endpoints'
import { URLS } from '../../../../config'
import { MODAL_SIZES, Modal } from '../../../../components/Modal'
import { LOADER_SIZES, LOADER_TYPES, Loader } from '../../../../components/Loader'

// taken directly from the source code -> https://github.com/casesandberg/react-color/blob/bc9a0e1dc5d11b06c511a8e02a95bd85c7129f4b/src/components/github/Github.js#L121
// last one has been replaced with white to reset the filter
const PICKER_COLORS = [
    '#B80000',
    '#DB3E00',
    '#FCCB00',
    '#008B02',
    '#006B76',
    '#1273DE',
    '#004DCF',
    '#5300EB',
    '#EB9694',
    '#FAD0C3',
    '#FEF3BD',
    '#C1E1C5',
    '#BEDADC',
    '#C4DEF6',
    '#BED3F3',
    '#FFFFFF'
]

const graphConfig = {
    labels: null,
    datasets: [{
        data: [65, 59, 80, 81, 56, 55, 40],
        fill: false,
        borderColor: 'rgb(75, 192, 192)',
        tension: 0.1
    }]
}

const graphOptions = {
    type: 'line',
    indexAxis: 'x',
    maintainAspectRatio: false,
    plugins: {
        tooltip: {
            enabled: true
        }
    },
    scales: {
        yAxes: {
            stacked: false,
        },
    },
};

export const UsageGraph = ({ accountId, shown, onClose }) => {
    const [usageGraphData, setUsageGraphData] = useState([])
    const [products, setProducts] = useState([])
    const [previousAccountId, setPreviousAccountId] = useState()
    const [loading, setLoading] = useState(true)

    const getProducts = () => {
        makeRequest.get(`${URLS.main}/products`)
            .then(res => {
                setProducts(res.data)
            })
    }

    const getGraphData = (accountId) => {
        setLoading(true)
        makeRequest.get(`${URLS.main}/integrations/product-usage-graph/${accountId}`)
            .then(res => {
                setUsageGraphData(res.data)
                setLoading(false)
            })
    }

    useEffect(() => {
        if (accountId && shown && previousAccountId !== accountId) {
            setPreviousAccountId(accountId)
            getGraphData(accountId)
        }
    }, [accountId, shown])

    useEffect(() => {
        getProducts()
    }, [])


    if (!shown) return null

    return (
        <Modal
            minHeight
            minWidth
            header={'Usage Graph'}
            onClose={onClose}
            size={MODAL_SIZES.BIG}
        >
            {usageGraphData?.message === 'No Product Usage Graph Settings were set.' ? usageGraphData?.message : (
                <div className={cn.lineWrapper}>
                    {loading ? <Loader size={LOADER_SIZES.BIG} type={LOADER_TYPES.SPINNER} /> : <Line
                        data={{
                            ...graphConfig,
                            labels: [...new Set(usageGraphData?.map(el => moment(el.timestamp).format('DD MM, YYYY')))],
                            datasets: products.map((product, i) => ({
                                label: product.Name,
                                data: usageGraphData ? usageGraphData.filter(el => {
                                    return product.Id.includes(el.productId)
                                }).map(el => el.usage) : [],
                                fill: false,
                                borderColor: PICKER_COLORS[i],
                                tension: 0.1,
                            }))
                        }}
                        options={graphOptions}
                    />}
                </div>
            )}
        </Modal>
    )
}