import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLongArrowAltUp } from '@fortawesome/free-solid-svg-icons'
import cn from './StatsSummaryRow.module.css'

const getColor = (value, oldValue, inverted) => {
    const positiveTrend = value / oldValue > 1
    if (value !== oldValue) {
        return positiveTrend && !inverted ? cn.green : cn.red
    }
}

const getDifference = (value, oldValue) => {
    if (!value || !oldValue) {
        return '100.00'
    }
    return (value * 100 / oldValue).toFixed(2)
}

export const StatsSummaryRow = ({ value, oldValue, oldPeriodLabel = 'month', skipFortmatting, inverted, onPeriodClick }) => {
    return (
        <div className={cn.statsSummaryRow}>
            <div className={`${cn.percentage} ${getColor(value, oldValue, inverted)}`}>
                {skipFortmatting ? (
                    <span>{Math.abs(value - oldValue)}</span>
                ) : (
                    <span>{getDifference(value, oldValue)}%</span>
                )}
                {value !== oldValue && (
                    <FontAwesomeIcon icon={faLongArrowAltUp} transform={{ rotate: value > oldValue ? 45 : 135 }} />
                )}
            </div>
            <div className={`${cn.oldLabel} ${!!onPeriodClick ? cn.clickable : ''}`} onClick={onPeriodClick}>
                FROM {oldPeriodLabel}
            </div>
        </div>
    )
}