import { connect } from 'react-redux'
const IdentityProviderTypeCheckComponent = ({ user, children, requiredTypes, requiredType }) => {
    if ((!requiredType && !requiredTypes) || user?.identityProviderType === requiredType || requiredTypes?.includes(user.identityProviderType)) {
        return children
    }
    return null
}


export const mapStateToProps = (state, ownProps) => {
    return {
        user: state.user,
        ...ownProps
    }
}

export const IdentityProviderTypeCheck = connect(mapStateToProps)(IdentityProviderTypeCheckComponent)
