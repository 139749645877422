import React, { useEffect, useState, useCallback } from 'react'
import moment from 'moment'
import { faPlusCircle, faEdit, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { connect } from 'react-redux'
import debounce from 'lodash.debounce'
import { Row } from '../../components/Row';
import { ViewContainer } from '../../components/ViewContainer'
import { Loader, LOADER_TYPES, LOADER_SIZES } from '../../components/Loader';
import { Modal } from '../../components/Modal';
import { Button, BUTTON_TYPES } from '../../components/Button';
import { Pagination } from '../../components/Pagination'
import { Table } from '../../components/Table'
import { TableSearchInput } from '../../components/TableSearchInput'
import { Dropdown } from '../../components/Dropdown';
import { ComplexDropdown } from '../../components/ComplexDropdown';
import { SFDCObjSelector } from '../../components/SFDCObjSelector'
import { makeRequest } from '../../utilities/endpoints';
import { Label } from '../../components/Label';
import { URLS } from '../../config';
import cn from './RenewalsManagement.module.css'
import { OpportunitiesCRUDModalWrapper } from '../../components/OpportunitiesCRUDModalWrapper';
import { SalesForceInputComponent } from '../../components/SalesForceInputComponent'
import { formatLargeSums } from '../../utilities/currency';
import { workspaceTypeCheck } from '../../utilities/workspaceType';
import { translateCRMLabel } from '../../utilities/translateCRMLabels';
import { SelectWrapperConnected } from '../../components/SelectWrapper';
import { getUserCrmId } from '../../utilities/userCrmId';

const defaultDate = { label: ' - ' }

const fieldOptionsToIgnore = ['ASSOCIATION_REMAP_COMPANY_ID']

export const RenewalsManagement = () => {
    const sfHeaders = [
        { 'label': 'NAME', 'value': 'Name', 'object': 'Account' },
        { 'label': 'NAME', 'value': 'Name', 'object': 'Opportunity' },
        { 'label': 'Amount', 'value': 'Amount', 'object': 'Opportunity' },
        { 'label': 'CLOSE DATE', 'value': 'CloseDate', 'object': 'Opportunity' },
    ];

    const callypsoHeaders = [
        { 'label': 'NAME', 'value': 'name', 'object': 'Account' },
        { 'label': 'NAME', 'value': 'name', 'object': 'Opportunity' },
        { 'label': 'Amount', 'value': 'amount', 'object': 'Opportunity' },
        { 'label': 'CLOSE DATE', 'value': 'closeDate', 'object': 'Opportunity' },
    ];


    const hubspotHeaders = [
        { 'label': 'NAME', 'value': 'name', 'object': 'Company' },
        { 'label': 'NAME', 'value': 'dealname', 'object': 'Deal' },
        { 'label': 'Amount', 'value': 'amount', 'object': 'Deal', formatSum: true },
        { 'label': 'CLOSE DATE', 'value': 'closedate', 'object': 'Deal' },
    ];

    const perPage = 10;
    const [sortDir, setSortDir] = useState(null);
    const [sortedCol, setSortedCol] = useState(null);
    const [headers, setHeaders] = useState([]);
    const [opportunityToMany, setOpportunityToMany] = useState([]);
    const [personalHeaders, setPersonalHeaders] = useState(null);
    const [delOppModal, setDelOppModal] = useState(null);
    const [addColModal, setAddColModal] = useState(false);
    const [addColModalLoaded, setAddColModalLoaded] = useState(true);
    const [addColObj, setAddColObj] = useState(null);
    const [addColProp, setAddColProp] = useState(null);
    const [addColFormat, setAddColFormat] = useState(null);
    const [colAdded, setColAdded] = useState(false);
    const [objPropertyCache, setObjPropertyCache] = useState({});
    const [connectedObjectsOptions, setConnectedObjectsOptions] = useState({});
    const [connectedObjectsOptionsLoaded, setConnectedObjectsOptionsLoaded] = useState(false);
    const [objects, setObjects] = useState([]);
    const [newColPropsLoaded, setNewColPropsLoaded] = useState(false);
    const [objPropLoaded, setObjPropLoaded] = useState(false);
    const [settings, setSettings] = useState(null);
    const [errorMsg, setErrorMsg] = useState(null);
    const [opportunities, setOpportunities] = useState(null);
    const [toManyOpportunities, setToManyOpportunities] = useState(null);
    const [table, setTable] = useState([[]]);
    const [totalOpportunities, setTotalOpportunities] = useState(null);
    const [page, setPage] = useState(0);
    const [tableLoaded, setTableLoaded] = useState(false);
    const [user, setUser] = useState(false);
    const [userList, setUserList] = useState([]);
    const [teams, setTeams] = useState([]);
    const [filterVal, setFilterVal] = useState(null);
    const [opportunityToEdit, setOpportunityToEdit] = useState(null);
    const [creatingNewOpportunity, setCreatingNewOpportunity] = useState(null);
    const [cellEditId, setCellEditId] = useState(null);
    const [cellEditCol, setCellEditCol] = useState(null);
    const [filterModal, setFilterModal] = useState(null);
    const [filterTime, setFilterTime] = useState(null);
    const [stagesDropdownData, setStagesDropdownData] = useState([]);
    const [stagesToDisplayTemp, setStagesToDisplayTemp] = useState([]);
    const [selectedCreateDatePeriod, setSelectedCreateDatePeriod] = useState({ label: ' - ' })
    const [timeInStageFilter, setTimeInStageFilter] = useState(null)
    const [defaultHeaders, setDefaultHeaders] = useState([]);
    const [hubspotStage, setHubspotStage] = useState({})

    const cacheKey = workspaceTypeCheck(['HUBSPOT']) ? 'Deal' : 'Opportunity'

    const actionCol = (oppId) => (
        <div className={cn.opportunityActions} >
            <Button
                data-tooltip-id='default'
                data-tooltip-content={`Edit ${translateCRMLabel('Opportunity')}`}
                type={BUTTON_TYPES.SMALLER_ICON}
                icon={faEdit}
                onClick={() => setOpportunityToEdit(oppId)}
                className={'green'}
            />
            <Button
                data-tooltip-id='default'
                data-tooltip-content={`Delete ${translateCRMLabel('Opportunity')}`}
                type={BUTTON_TYPES.SMALLER_ICON}
                icon={faTrashAlt}
                onClick={() => setDelOppModal(oppId)}
                className={'red'}
            />
        </div>
    )

    const getToMany = () => {
        makeRequest.get(`${URLS.main}/renewalManagement/getToMany`)
            .then(res => {
                if (res && res.data) {
                    setOpportunityToMany(res.data);
                }
            });
    }

    const getConnectedObjectsFromFields = fields => [...new Set(fields.filter(el => el.type === 'reference').map(el => el.referenceTo).flat())]

    const getConnectedObjects = (fields, headers) => {
        setConnectedObjectsOptionsLoaded(false);
        const newConnectedObjects = getConnectedObjectsFromFields(fields).filter(el => !Object.keys(connectedObjectsOptions).includes(el))
        if (!newConnectedObjects || !newConnectedObjects.length) {
            setConnectedObjectsOptionsLoaded(true);
        } else {
            const objectNames = newConnectedObjects.filter(el => !Object.keys(connectedObjectsOptions).includes(el));
            const objectNameProps = [...objectNames];
            for (const col of headers) {
                let index = objectNames.indexOf(col.object);
                if (index >= 0) {
                    objectNameProps[index] = `${objectNameProps[index]}.${col.value}`;
                } else {
                    index = objectNames.indexOf(fields.find(el => el.relationshipName === col.object)?.referenceTo);
                    if (index >= 0) {
                        objectNameProps[index] = `${objectNameProps[index]}.${col.value}`;
                    }
                }
            }
            makeRequest.get(`${URLS.main}/sobject-dropdown-options/${objectNameProps.join(',')}`)
                .then(res => {
                    if (res && res.data) {
                        setConnectedObjectsOptions(res.data)
                        setConnectedObjectsOptionsLoaded(true);
                    }
                })
        }
    }


    const getUsers = () => {
        makeRequest.get(`${URLS.main}/workspace/users`, 2)
            .then(res => {
                if (res && res.data && res.data.success) {
                    setUserList(res.data.users);
                }
            })
            .catch((error) => {
                setErrorMsg(`Getting list of users failed: ${error}`);
            });
    }

    const getTeams = () => {
        makeRequest.get(`${URLS.main}/teams`)
            .then(res => {
                if (res && res.data) {
                    setTeams(res.data.teams);
                }
            })
            .catch((error) => {
                setErrorMsg(`Getting list of users failed: ${error}`);
            });
    }


    const cleanOpportunity = (str) => {
        const stringToLookFor = cacheKey
        if (!str || str === stringToLookFor) {
            return str;
        }
        const pos = str.indexOf(stringToLookFor);
        if (pos !== 0) {
            return str;
        }
        return str.replace(stringToLookFor, "");
    }

    const getSettings = () => {
        makeRequest.get(`${URLS.main}/settings`)
            .then(res => {
                if (res && res.data) {
                    const obj = {}
                    res.data.forEach(setting => {
                        obj[setting.name] = setting.value
                        if (setting.name === 'useBoth') {
                            obj[setting.name] = setting.value === 'true'
                        }
                    })
                    setSettings(obj);
                }
            })
    }

    const keyUnify = (word) => {
        if (workspaceTypeCheck(['HUBSPOT'])) {
            return word
        }
        const unify = {
            'stage': 'StageId',
            'type': 'TypeId',
        }
        const nowWord = Object.prototype.hasOwnProperty.call(unify, word.toLocaleLowerCase()) ? unify[word.toLocaleLowerCase()] : word;
        const first = nowWord.charAt(0).toLocaleUpperCase();
        if (first === nowWord.charAt(0)) {
            return nowWord;
        }
        return `${first}${word.slice(1)}`;
    }

    const updateOpportunity = (oppId, obj, prop, value) => {
        if (!oppId || !prop) {
            return null;
        }

        const cleanedUpValues = {}
        const opp = opportunities.find((el) => el.Id === oppId);

        opp[prop] = value;

        Object.keys(opp).forEach(key => {
            if (objPropertyCache && objPropertyCache[cacheKey]
                && (objPropertyCache[cacheKey].find(el => el.internalValue === key) || objPropertyCache[cacheKey].find(el => el.value === key)?.updateable === true)
            ) {
                if (
                    (opp[key] === null || typeof opp[key] !== 'object')
                    && !Object.prototype.hasOwnProperty.call(opp, `${key}Id`)
                ) {
                    cleanedUpValues[keyUnify(key)] = opp[key]
                } else if (
                    (typeof opp[key] === 'object' && opp[key] instanceof Date)
                ) {
                    cleanedUpValues[key] = moment(opp[key]).format('MM/DD/YYYY')
                }
            }
        })

        makeRequest.put(`${URLS.main}/opportunities/${oppId}`, cleanedUpValues)
            .then((res) => {
                if (res.status === 200) {
                    setCellEditId(null);
                    setCellEditCol(null);
                    const curOppIndex = opportunities?.findIndex(el => el.Id === oppId);
                    if (curOppIndex >= 0
                        && opportunities
                        && opportunities[curOppIndex]
                        && typeof opportunities[curOppIndex][obj] === "object"
                        && objPropertyCache
                        && objPropertyCache[cacheKey]
                        && connectedObjectsOptions
                    ) {
                        const curOpp = { ...opportunities[curOppIndex] };
                        const oppProp = objPropertyCache[cacheKey].find(el => el.relationshipName === obj);
                        const connectedObjName = oppProp.referenceTo;
                        curOpp[obj] = { ...connectedObjectsOptions[connectedObjName].find(el => el.Id === value) };
                        const tmpOpportunities = [...opportunities];
                        tmpOpportunities[curOppIndex] = { ...curOpp };
                        setOpportunities(tmpOpportunities);
                    }
                    getOpportunities(defaultHeaders, personalHeaders || [], objPropertyCache, user, filterVal);
                }
            })
            .catch((error) => {
                if (typeof error.response.data === "object") {
                    setErrorMsg(error?.response?.data?.message);
                } else {
                    setErrorMsg(error?.response?.data||error?.response||error);
                }
                opportunitiesToTable();
            })
    }

    const updateOpportunitiesCache = (oppId, obj, prop, value) => {
        if (!oppId || !prop) {
            return null;
        }

        const oppIndex = opportunities.findIndex((el) => el.Id === oppId);

        if (oppIndex < 0) {
            return null;
        }

        const verifyProp = objPropertyCache[cacheKey].find(el => el.internalValue === prop) || objPropertyCache[cacheKey].find(el => el.value === prop);
        if (!verifyProp || (Object.prototype.hasOwnProperty.call(verifyProp, 'updateable') && !verifyProp.updateable)) {
            return false;
        }

        const curOpp = { ...opportunities[oppIndex] };

        curOpp[prop] = value;

        const tmpOpportunities = [...opportunities];
        tmpOpportunities[oppIndex] = { ...curOpp };
        setOpportunities(tmpOpportunities);
    }

    const deFocus = () => {
        setCellEditId(null);
        setCellEditCol(null);
    }

    const idSwitch = (row, obj, prop, val, field) => {
        if (!row || !obj || !prop) {
            return val;
        }
        if (field.picklistValues?.length && field.value) {
            const valueFromDropdown = field.picklistValues.find(el => el.value === val)
            return valueFromDropdown ? valueFromDropdown.label : val
        }
        if (typeof val === 'number' && Object.prototype.hasOwnProperty.call(row, `${prop}Name`)) {
            return row[`${prop}Name`];
        }
        return val;
    }

    const salesForceInputEval = (objPropCache, field, objectOptions, row, col, value, updateBackEnd, updateCache, blur, formatSum) => {
        if (!objPropCache || !objectOptions || !row || !col) {
            return '';
        }
        let outElement = '';
        const descrip = field;
        let type = null;
        let picklistValues = null; // this is always from objPropCache[object name].find( el => el.value === property), the question is which one?
        let val = value || '';

        if (!descrip) {
            return val;
        }
        // at this point, descrip should be whatever it needs to be
        type = !!descrip.referenceTo ? 'picklist' : descrip.type;

        picklistValues = !!descrip.referenceTo ? (objectOptions[descrip.referenceTo]?.map(el => ({ value: el.Id, label: el[col.value]||el.Name })) || []) : descrip.picklistValues;
        // unlike previous implementation, will need to filter to {value: el.Id, label: el[col.value]} format
        if (field.type === 'date') {
            if (!value) {
                value = moment(new Date()).format('MM/DD/YYYY')
            } else {
                value = moment(value).format('MM/DD/YYYY')
            }
        } else if (field.type === 'datetime') {
            if (!value) {
                value = moment(new Date()).format('HH:mm MM/DD/YYYY')
            } else {
                value = moment(value).format('HH:mm MM/DD/YYYY')
            }
        }
        val = row[col.object] ? row[col.object][col.value] : value || '';
        if (field.type === 'currency' || formatSum) {
            val = val ? formatLargeSums('$', val) : formatLargeSums('$', 0);
        }
        outElement = (
            <div
                tabIndex={`${row.Id}.${col.object}.${col.value}`}
                onBlur={data => {
                    if (!data?.relatedTarget?.className || !data.relatedTarget.className.includes('react-datepicker')) {
                        if (!data?.relatedTarget || data.relatedTarget.getAttribute('tabindex') !== `${row.Id}.${col.object}.${col.value}`) {
                            setCellEditId(null);
                            setCellEditCol(null);
                        }
                    }
                }}
                onClick={() => {
                    setCellEditId(row.Id);
                    setCellEditCol(col);
                }}
            >
                {(!cellEditId
                || cellEditId !== row.Id
                || cellEditCol?.object !== col.object
                || cellEditCol?.value !== col.value)
                && (
                    <div className={cn.tableCellOverflowHide}>
                        {idSwitch(row, col.object, col.value, val, field)}
                    </div>
                )}
                {connectedObjectsOptionsLoaded
                && cellEditId
                && cellEditCol
                && cellEditId === row.Id
                && cellEditCol?.object === col.object
                && cellEditCol?.value === col.value
                && (
                    <SalesForceInputComponent
                        tabIndex={`${row.Id}.${col.object}.${col.value}`}
                        disabled={false}
                        // labelClassName={className}
                        inputClassName={cn.tableInputDropDown}
                        value={value}
                        type={type}
                        picklistValues={picklistValues}
                        controlMinWidth={150}
                        controlHeight={25}
                        onChange={data => {
                            if (type === 'picklist') {
                                if (col.object === cacheKey) {
                                    updateBackEnd(row.Id, col.object, col.value, data);
                                } else {
                                    updateBackEnd(row.Id, col.object, `${col.object}Id`, data);
                                }
                                deFocus();
                            } else if (type === 'datetime') {
                                updateBackEnd(row.Id, col.object, col.value, moment(data).format('YYYY-MM-DD HH:mm'));
                                deFocus();
                            } else if (type === 'date') {
                                updateBackEnd(row.Id, col.object, col.value, moment(data).format('YYYY-MM-DD'));
                                deFocus();
                            } else {
                                updateCache(row.Id, col.object, col.value, data);
                            }
                        }}
                        onKeyDown={data => {
                            if (type !== 'picklist') {
                                if (data.key === "Enter" && data.shiftKey === false) {
                                    const opp = opportunities.find(el => el.Id === row.Id);
                                    updateBackEnd(row.Id, col.object, col.value, opp[col.value]);
                                    deFocus();
                                }
                            }
                        }}
                        onBlur={() => {
                            if (type !== 'picklist') {
                                const opp = opportunities.find(el => el.Id === row.Id);
                                updateBackEnd(row.Id, col.object, col.value, opp[col.value]);
                                deFocus();
                            }
                        }}
                    />
                )}
                {!connectedObjectsOptionsLoaded
                && cellEditId
                && cellEditCol
                && cellEditId === row.Id
                && cellEditCol?.object === col.object
                && cellEditCol?.value === col.value
                && (
                    <Loader type={LOADER_TYPES.SPINNER} className={cn.loader} />
                )}
            </div>
        );
        return outElement;
    }



    const opportunitiesToTable = () => {
        if (!opportunities) {
            setTable([[]]);
            return null;
        }
        const buff = [];
        for (const opportunity of opportunities) {
            const el = [];
            for (const column of headers) {
                let field = null;
                switch (column.object) {
                case 'Opportunity':
                case 'Deal':
                    field = (objPropertyCache && objPropertyCache[cacheKey]?.find(el => el.internalValue === column.value)) || (objPropertyCache && objPropertyCache[cacheKey]?.find(el => el.value === column.value)) || null;
                    if (field?.value === 'dealstage') {
                        field.picklistValues = hubspotStage[opportunity.pipeline]
                    }
                    const sfInput = salesForceInputEval(objPropertyCache, field, connectedObjectsOptions, opportunity, column, opportunity[column.value], updateOpportunity, updateOpportunitiesCache, deFocus, column?.formatSum);
                    el.push(sfInput);
                    break;
                case 'None':
                    el.push(actionCol(opportunity.Id));
                    break;
                default:
                    if (opportunityToMany.find(el => el.object === column.object)) {
                        if (toManyOpportunities
                            && toManyOpportunities[`${column.object}.${column.value}`]
                            && Object.prototype.hasOwnProperty.call(toManyOpportunities[`${column.object}.${column.value}`], opportunity.Id)
                        ) {
                            el.push(toManyOpportunities[`${column.object}.${column.value}`][opportunity.Id]);
                            break;
                        }
                    }
                    if (typeof opportunity[column.object] === 'object' && objPropertyCache && objPropertyCache[cacheKey]) {
                        field = objPropertyCache[cacheKey].find(el => el.value === `${column.object}Id`) || null;
                        if ((column.object === 'Account' || column.object === 'Company')
                            && opportunity[column.object]
                            && opportunity[column.object][column.value]
                            && ['name', 'id'].find(prop => column.value.toLocaleLowerCase().includes(prop))
                        ) {
                            el.push(<a href={`/account/${opportunity[column.object]?.Id}`} >{opportunity[column.object][column.value]}</a>);
                            break;
                        }
                        if (field && field.referenceTo && column.value.toLocaleLowerCase().includes('name')) {
                            el.push(salesForceInputEval(objPropertyCache, field, connectedObjectsOptions, opportunity, column, opportunity[column.object]?.Id, updateOpportunity, updateOpportunitiesCache, deFocus));
                            break;
                        }
                        el.push(opportunity[column.object] ? opportunity[column.object][column.value] : '');
                        break;
                    }
                    // el.push(row[col.value]);
                    el.push('');
                    break;
                }
            }
            buff.push(el);
        }
        setTable(buff);
    }

    const getOpportunities = (defaultH, pHeaders=[], objCache={}, filterUser=null, filterStr=null) => {
        setTableLoaded(false);

        const arr = [
            ...defaultH.map(el => {
                return { object: el.object, prop: el.value, relation: null };
            }),
            ...pHeaders.map(el => {
                const opp = opportunityToMany.find(opm => opm.object === el.object);
                if (opp === undefined) {
                    return {
                        object: el.object,
                        prop: el.value,
                        relation: null
                    }
                }
                return {
                    object: el.object,
                    prop: el.value,
                    relation: opp.relation
                };
            })
        ];

        let evaledSortCol = null;

        if (headers[sortedCol]?.value) {
            if (headers[sortedCol].object !== cacheKey && headers[sortedCol].object !== "None") {
                evaledSortCol = `${headers[sortedCol].object}.${headers[sortedCol].value}`;
            } else {
                evaledSortCol = headers[sortedCol].value;
            }
        }

        // generate filter string
        // const fullFilterStr = genFilterString(pHeaders, objParse, filterStr);
        const body = {
            props: arr,
            userId: filterUser,
            page: page * perPage,
            limit: perPage,
            sortCol: evaledSortCol,
            sortDir: sortDir,
            filter: filterStr,
            createDate: selectedCreateDatePeriod,
            closeDate: filterTime,
            timeInStage: timeInStageFilter,
            stage: stagesToDisplayTemp,
            propsCache: { ...objCache }
        }
        makeRequest.post(`${URLS.main}/renewalManagement/opportunities`, body).then(res => {
            if (res && res.status === 200) {
                if (res.data?.records) {
                    setTotalOpportunities(res.data.total);
                    setOpportunities([...res.data.records]);
                    setToManyOpportunities({ ...res.data.toMany });
                }
            }
            setTableLoaded(true);
        })
            .catch((error) => {
                setErrorMsg(`Fetching opportunities failed: ${error}`);
            });
    }

    const getOpportunitiesDebounce = useCallback(
        debounce(getOpportunities, 250),
        []
    );

    const deleteOpportunity = () => {
        const idToRemove = delOppModal
        makeRequest.delete(`${URLS.main}/opportunities/${idToRemove}`)
            .then(() => {
                // setOpportunities(opportunities.filter(el => el.Id !== idToRemove));
                getOpportunities(defaultHeaders, personalHeaders, objPropertyCache || {}, user, filterVal);
                setDelOppModal(null);
            })
            .catch((error) => {
                setErrorMsg(`Deleteing opportunity failed: ${error}`);
            });
    }

    const getObjectsPropertyPromiseAll = () => {
        setAddColModalLoaded(false);
        const allGetObjectsProperty = objects.map(obj => {
            if (obj.object) {
                return getObjectsProperty(obj.object);
            }
            return new Promise((resolve) => { resolve(true); });
        });
        if (allGetObjectsProperty.length > 0) {
            Promise.all(allGetObjectsProperty).then(() => {
                setAddColModalLoaded(true);
            });
        } else {
            setAddColModalLoaded(true);
        }
    }

    const getObjectsProperty = (objName) => {
        return new Promise((resolve, reject) => {
            if (!objPropertyCache[objName]) {
                makeRequest.get(`${URLS.main}/describe/${objName}`)
                    .then(res => {
                        const tmpLabel = objPropertyCache;
                        if (objName === 'Account' && res?.data?.fields.length > 0) {
                            tmpLabel[objName] = [...res.data.fields];
                        } else if (res?.data?.fields) {
                            if (res.data.fields.length > 0) {
                                tmpLabel[objName] = [...res.data.fields];
                            }
                        } else if (res?.data) {
                            if (res?.data.length > 0) {
                                tmpLabel[objName] = [...res.data];
                            }
                        }
                        setObjPropertyCache({ ...tmpLabel });
                        resolve();
                    })
                    .catch(error => {
                        reject(error);
                    });
            } else {
                resolve();
            }
        });
    }


    const getOpportunityProperty = () => {
        if (objects.length > 0) {
            return null;
        }
        const getConnectedObjectsFromFields = fields => [...new Set(fields.filter(el => el.type === 'reference').map(el => {
            return { 'label': el.relationshipName||el.referenceTo, 'value': el.relationshipName, 'object': el.referenceTo }
        }).flat())];
        setObjPropLoaded(false);
        makeRequest.get(`${URLS.main}/opportunity-details/create`).then(res => {
            if (res && res.data) {
                if (res.data.stages) {
                    setHubspotStage(res.data.stages)
                }
                if (res.data.fields) {
                    setObjPropertyCache({ [cacheKey]: [...res.data.fields] });
                    // setObjPropertyCacheProbabilities(res.data.probabilities);
                    const connected = [...getConnectedObjectsFromFields(res.data.fields)];
                    const toMany = [...opportunityToMany.map(el => {
                        return { 'label': cleanOpportunity(el.object), 'value': el.object, 'object': el.object }
                    })];
                    setObjects([...connected, ...toMany]);
                }
            }
            setObjPropLoaded(true);
        });
    }

    const delCol = (objName=null, objProperty=null, id=null) => {
        if (!objName || !objProperty || id === null || id<=0) {
            return null;
        }
        makeRequest.delete(`${URLS.main}/renewalManagement/${objName}/${objProperty}`).then(res => {
            if (res.status === 200) {
                // success
                const tmp = [...personalHeaders]
                tmp.splice(id - defaultHeaders.length, 1);
                setPersonalHeaders(tmp);
            } else {
                // error
                setErrorMsg();
            }
        })
            .catch((error) => {
                setErrorMsg(`Column deletion failed: ${error}`);
            });
    }

    const addNewCol = () => {
        const obj = {
            label: addColProp.label,
            internalValue: addColProp.internalValue || null,
            value: addColProp.internalValue || addColProp.value,
            object: addColObj.value,
        }
        setColAdded(false);
        const tmpheaders = personalHeaders ? [...personalHeaders] : [];
        const colNum = tmpheaders.length <= 0 ? defaultHeaders.length + tmpheaders.length : tmpheaders[tmpheaders.length - 1].colOrder + 1;
        makeRequest.post(`${URLS.main}/renewalManagement/${encodeURIComponent(addColProp.label)}/${colNum}/${addColObj.value}/${addColFormat}`, obj)
            .then(res => {
                if (res.status === 200) {
                    // success
                    tmpheaders.push({
                        label: addColProp.label,
                        colName: addColProp.label,
                        colOrder: colNum,
                        object: addColObj.value,
                        objName: addColObj.label,
                        value: addColProp.internalValue || addColProp.value,
                        objProperty: addColProp.value,
                        objFormat: addColFormat,
                        id: res.data.insertId
                    });
                    setPersonalHeaders(tmpheaders);
                }
                setColAdded(true);
            })
            .catch((error) => {
                setColAdded(true);
                setErrorMsg(`Adding column failed: ${error}`);
            });
    }

    const getCols = () => {
        makeRequest.get(`${URLS.main}/renewalManagement/columns`).then(res => {
            if (res.status === 200) {
                if (res.data) {
                    setPersonalHeaders(res.data.map(el => {
                        return {
                            label: el.colName,
                            colName: el.colName,
                            colOrder: el.colOrder,
                            object: el.objName,
                            objName: el.objName,
                            value: el.objProperty,
                            objProperty: el.objProperty,
                            id: el.id
                        }
                    }));
                }
            }
        })
            .catch((error) => {
                setErrorMsg(`Getting column failed: ${error}`);
            });
    }

    const baseHeadersToHeaders = () => {
        // const tmpPHeaders = consolPersonalHeaderAndObjProp();
        const tmpheaders = [...defaultHeaders, ...personalHeaders];
        const action = (
            <div className={cn.tableHeaderAction}>
                <div>Actions</div>
                <Button
                    data-tooltip-id='default'
                    data-tooltip-content={'Add Column'}
                    type={BUTTON_TYPES.SMALLER_ICON}
                    icon={faPlusCircle}
                    onClick={() => { setAddColModal(true); }}
                />
            </div>
        );
        tmpheaders.push({ 'label': action, 'value': 'Actions', 'object': 'None' });
        setHeaders(tmpheaders);
    }

    const getOpportunityStages = () => {
        makeRequest.get(`${URLS.main}/opportunities/stages`)
            .then(res => {
                if (res && res.data) {
                    setStagesDropdownData(res.data.stages)
                }
            })
    }

    const setFilters = () => {
        setFilterModal(null);
    }

    const clearFilters = () => {
        setFilterTime(defaultDate);
        setStagesToDisplayTemp([]);
        setSelectedCreateDatePeriod({ label: ' - ' })
        setTimeInStageFilter(null)
        setFilterModal(null);
    }

    useEffect(() => {
        if (filterModal) {
            getOpportunityStages();
        } else {
            getOpportunities(defaultHeaders, personalHeaders || [], objPropertyCache || {}, user, filterVal);
        }
    }, [filterModal]);

    useEffect(() => {
        if (colAdded) {
            getOpportunities(defaultHeaders, personalHeaders || [], objPropertyCache || {}, user, filterVal);
        }
    }, [colAdded]);

    useEffect(() => {
        if (objPropertyCache[cacheKey]
            && !connectedObjectsOptionsLoaded
            && cellEditId
            && cellEditCol
        ) {
            getConnectedObjects(objPropertyCache[cacheKey], [cellEditCol]);
        }
        opportunitiesToTable();
    }, [cellEditId, cellEditCol, connectedObjectsOptionsLoaded]);

    useEffect(() => {
        if (filterVal !== null) {
            getOpportunitiesDebounce(defaultHeaders, personalHeaders, objPropertyCache || {}, user, filterVal);
        }
    }, [filterVal]);

    useEffect(() => {
        opportunitiesToTable();
    }, [opportunities, headers, toManyOpportunities]);

    useEffect(() => {
        if (Object.keys(objPropertyCache).length >= 0
            && personalHeaders
            && !addColModal
            && addColModalLoaded
        ) {
            getOpportunities(defaultHeaders, personalHeaders, objPropertyCache || {}, user, filterVal);
        }
    }, [objPropertyCache, page, user, sortedCol, sortDir]);

    useEffect(() => {
        if (headers.length <= 0) {
            setNewColPropsLoaded(false);
            for (const obj of headers) {
                if (obj.object) {
                    getObjectsProperty(obj.object)
                }
            }
            setNewColPropsLoaded(true);
        }
    }, [headers, toManyOpportunities]);

    useEffect(() => {
        if (personalHeaders) {
            baseHeadersToHeaders();
            getOpportunityProperty();
        }
    }, [personalHeaders, opportunityToMany]);

    useEffect(() => {
        if (addColModal) {
            getObjectsPropertyPromiseAll();
        }
    }, [objects]);


    useEffect(() => {
        if (addColModal) {
            getOpportunityProperty();
            if (objects.length) {
                getObjectsPropertyPromiseAll();
            }
        }
    }, [addColModal]);

    useEffect(() => {
        getToMany();
        getCols();
        getSettings();
        getUsers();
        getTeams()
        if (workspaceTypeCheck(['SFDC', 'SFDC_SANDBOX'])) {
            setDefaultHeaders([...sfHeaders]);
        } else if (workspaceTypeCheck(['HUBSPOT'])) {
            setDefaultHeaders([...hubspotHeaders])
        } else {
            setDefaultHeaders([...callypsoHeaders]);
        }
    }, []);

    return (
        <>
            {(!settings?.renewalType || settings.renewalType === '') && (
                <ViewContainer>
                    <div className={cn.colNotice}>
                        {`Please configure your renewal type for opportunities so we can filter them here. `}
                        <a href='/settings/opportunityOptions'>Go to Settings page.</a>
                    </div>
                </ViewContainer>
            )}
            {settings?.renewalType && settings.renewalType !== '' && (
                <>
                    <ViewContainer>
                        {!objPropLoaded && (
                            <div className={cn.colNotice}>
                                <div>Loading custom columns</div>
                                <Loader type={LOADER_TYPES.SPINNER} className={cn.loader} />
                            </div>
                        )}
                        {errorMsg && (
                            <div className={cn.colNotice}>
                                {errorMsg}
                            </div>
                        )}
                        {headers.length > 0 && personalHeaders !== null && (
                            <>
                                <div className={cn.tableRow}>
                                    <div className={cn.tableFilterDiv}>
                                        <Dropdown
                                            disabled={false}
                                            value={user}
                                            onChange={e => setUser(e.target.value)}
                                            values={[
                                                {
                                                    value: 'null',
                                                    label: 'Company Wide'
                                                },
                                                ...teams.map(team => ({
                                                    value: team.users.filter(el => !!getUserCrmId(el)).map(el => getUserCrmId(el)).join(','),
                                                    label: team.name,
                                                })).filter(el => !!el.value),
                                                ...userList.map(el => ({
                                                    value: getUserCrmId(el),
                                                    label: el.fullName || el.email
                                                })).filter(el => !!el.value)
                                            ]}
                                            className={cn.userNameFilter}
                                        />
                                        <Button
                                            onClick={() => setFilterModal(true)}
                                        >
                                            Filters
                                        </Button>
                                        <TableSearchInput
                                            placeHolder={`Filter By ${translateCRMLabel('Opportunity')} Name Or Properties...`}
                                            className={cn.searchInput}
                                            filters={filterVal}
                                            filterCallBack={(val) => {
                                                setFilterVal(val);
                                            }}
                                        />
                                    </div>
                                    <Button onClick={() => setCreatingNewOpportunity(true)}>
                                        Create {translateCRMLabel('Opportunity')}
                                    </Button>
                                </div>
                                <div className={cn.tableMinHeight}>
                                    <Table
                                        headers={headers.map((el, i) => {
                                            if (el.object === 'None') {
                                                return el.label;
                                            }
                                            const label = el.label.indexOf(el.object) === 0 ? el.label : `${el.object} ${el.label}`;
                                            return defaultHeaders.length > i ? label : (
                                                <div className={cn.tableHeaderAction}>
                                                    <div className={cn.tableHeaderActionLabel}>{label}</div>
                                                    <Button
                                                        type={BUTTON_TYPES.SMALLER_ICON}
                                                        icon={faTrashAlt}
                                                        className={'red'}
                                                        onClick={() => delCol(el.object, el.value, i)}
                                                    />
                                                </div>
                                            );
                                        })}
                                        data={(tableLoaded && personalHeaders !== null)? table : [[]]}
                                        customStyles={{
                                            [headers.length - 1]: { width: 170 }
                                        }}
                                        allowCellOverflow={true}
                                        sorted={true}
                                        sortDir={sortDir}
                                        sortedCol={sortedCol}
                                        sortMask={headers.map((header) => {
                                            if (header.object === 'None') {
                                                return true
                                            }
                                            if (workspaceTypeCheck(['CALLYPSO']) && header.object === "Account" && header.value !== "name") {
                                                return true
                                            }
                                            if (workspaceTypeCheck(['HUBSPOT']) && header.object !== 'Deal') {
                                                return true
                                            }
                                            return opportunityToMany.find(opp => header.object === opp.object)
                                        })}
                                        sortCallBack={(colName, colNum, dir) => { setSortedCol(colNum); setSortDir(dir); setPage(0); }}
                                    />
                                    {(!tableLoaded || !personalHeaders)&& (
                                        <Loader type={LOADER_TYPES.SPINNER} size={LOADER_SIZES.BIG} className={cn.tableLoader} />
                                    )}
                                </div>
                            </>
                        )}
                    </ViewContainer>
                    <br />
                    <Pagination pages={Math.ceil(totalOpportunities/perPage)} onClick={(e) => { setPage(e); }} index={page} />
                </>
            )}
            {addColModal && (
                <Modal
                    minHeight={false}
                    onClose={() => { setAddColModal(false); }}
                    header={`Add Column`}
                    buttons={(
                        <>
                            <Button fullWidth disabled={!(addColObj && addColProp)} onClick={() => { addNewCol(); setAddColModal(false); }}>
                                Add Column
                            </Button>
                            <Button fullWidth onClick={() => { setAddColModal(false); }}>
                                Close
                            </Button>
                        </>

                    )}
                >
                    <div className={cn.colModalWrapper}>
                        {(!objPropLoaded || !newColPropsLoaded || !addColModalLoaded) && (
                            <div className={cn.modalLoader}>
                                <Loader type={LOADER_TYPES.SPINNER} size={LOADER_SIZES.BIG} className={cn.modalLoader} />
                            </div>
                        )}
                        {addColModalLoaded && objPropLoaded && newColPropsLoaded && (
                            <SFDCObjSelector
                                paramsObj={{
                                    obj: {
                                        select: {
                                            options: [...new Set([
                                                cacheKey,
                                                ...Object.keys(objPropertyCache).sort()
                                            ])].map(el => ({ label: el, value: el, object: el }))
                                        }
                                    },
                                    field: {
                                        select: {
                                            options: addColObj ? objPropertyCache[addColObj.object].filter(el => !fieldOptionsToIgnore.includes(el.value)) : []
                                        }
                                    }
                                }}
                                objValue={addColObj}
                                objOnChange={data => {
                                    setAddColProp(null);
                                    setAddColFormat((addColObj && opportunityToMany.find(el => el.object === addColObj.value)) !== undefined ? 'SUM' : 'number');
                                    setAddColObj(data);
                                }}
                                fieldValue={addColProp}
                                fieldOnChange={data => {
                                    setAddColProp(data);
                                }}
                                formatValues={(addColObj && opportunityToMany.find(el => el.object === addColObj.value)) === undefined}
                                formatValue={addColFormat}
                                formatOnChange={data => {
                                    setAddColFormat(data.target.value);
                                }}
                            />
                        )}
                    </div>
                </Modal>
            )}
            {(creatingNewOpportunity || opportunityToEdit) && (
                <OpportunitiesCRUDModalWrapper
                    shown={opportunityToEdit || creatingNewOpportunity}
                    create={creatingNewOpportunity}
                    edit={opportunityToEdit}
                    onClose={() => {
                        setOpportunityToEdit(null)
                        setCreatingNewOpportunity(null)
                    }}
                    opportunityId={opportunityToEdit}
                    header={creatingNewOpportunity ? `Creating new ${translateCRMLabel('Opportunity')}` : `Edit ${opportunities.find(el => el.Id === opportunityToEdit)?.Name || opportunities.find(el => el.Id === opportunityToEdit)?.dealname}`}
                    editCallback={() => {
                        getOpportunities(defaultHeaders, personalHeaders, objPropertyCache || {}, user, filterVal);
                        setOpportunityToEdit(null)
                        setCreatingNewOpportunity(null)
                    }}
                    createCallback={() => {
                        setCreatingNewOpportunity(null);
                        setOpportunityToEdit(null)
                        if (workspaceTypeCheck(['HUBSPOT'])) {
                            setTableLoaded(false)
                            setTimeout(() => {
                                getOpportunities(defaultHeaders, personalHeaders, objPropertyCache || {}, user, filterVal);
                            }, 6000)
                        } else {
                            getOpportunities(defaultHeaders, personalHeaders, objPropertyCache || {}, user, filterVal);
                        }
                    }}
                />
            )}
            {delOppModal && (
                <Modal
                    minHeight={false}
                    onClose={() => { setDelOppModal(false); }}
                    header={`Delete ${translateCRMLabel('Opportunity')}`}
                    buttons={(
                        <>
                            <Button
                                fullWidth
                                onClick={deleteOpportunity}
                            >
                                Yes, Delete it
                            </Button>
                            <Button
                                fullWidth
                                onClick={() => setDelOppModal(null)}
                            >
                                No, Cancel
                            </Button>
                        </>
                    )}
                >
                    Are you sure you'd want to delete this {translateCRMLabel('Opportunity')} from your CRM?
                    <br /><br />
                    This action might be irreversible! Please consider it carefully.
                </Modal>
            )}
            {filterModal && (
                <Modal
                    header='Filters'
                    minHeight
                    onClose={() => { setFilterModal(null); }}
                    buttons={
                        <>
                            <Button
                                fullWidth
                                onClick={clearFilters}
                            >
                                Clear filters
                            </Button>
                            <Button
                                fullWidth
                                onClick={setFilters}
                            >
                                Set filters
                            </Button>

                        </>
                    }
                >
                    <Row fullWidth>
                        <Label className={cn.filtersLabel}>
                            Filter on Close Date
                        </Label>
                        <ComplexDropdown
                            value={filterTime||defaultDate}
                            onChange={setFilterTime}
                        />
                    </Row>
                    <Row fullWidth>
                        <Label className={cn.filtersLabel}>
                            Filter on Created Date
                        </Label>
                        <ComplexDropdown
                            value={selectedCreateDatePeriod}
                            onChange={setSelectedCreateDatePeriod}
                        />
                    </Row>
                    <Row fullWidth>
                        <Label className={cn.filtersLabel}>
                            Time in stage Filter
                        </Label>
                        <Dropdown
                            fullWidth
                            className={cn.topNavigationDropdown}
                            value={timeInStageFilter}
                            onChange={e => setTimeInStageFilter(e.target.value)}
                            values={[
                                {
                                    value: 0,
                                    label: 'None'
                                },
                                {
                                    value: 7,
                                    label: 'Over a Week'
                                },
                                {
                                    value: 31,
                                    label: 'Over a Month'
                                },
                                {
                                    value: 90,
                                    label: 'Over 90 Days'
                                },
                                {
                                    value: 365,
                                    label: 'Over a Year'
                                },
                            ]}
                        />
                    </Row>
                    <Row fullWidth>
                        <Label className={cn.filtersLabel}>
                            Select specific stages
                        </Label>
                        <SelectWrapperConnected
                            placeholder="Quick Filter"
                            closeMenuOnSelect={false}
                            isMulti
                            options={stagesDropdownData}
                            onChange={setStagesToDisplayTemp}
                            value={stagesToDisplayTemp}
                        />
                    </Row>
                </Modal>
            )}
        </>
    )
}

export const mapStateToProps = (state) => {
    return {
        user: state.user,
    }
}

export const RenewalsManagementConnected = connect(mapStateToProps)(RenewalsManagement)
