import React, { useState } from 'react'
import { connect } from 'react-redux';
import Select from 'react-select';

export const SelectWrapper = ({ colorScheme, maxWidth, fullWidth, smaller, ...rest }) => {
    const [focused, setFocused] = useState(false)
    const borderColorActive = colorScheme === 'DARK' ? '#A0FFBB' : '#609970'
    const borderColorInActive = colorScheme === 'DARK' ? '#424944' : '#DCE0DD'
    const color = colorScheme === 'DARK' ? '#FFFFFF' : '#111311'
    const background = colorScheme === 'DARK' ? '#151916' : '#FFFFFF'
    const highlight = colorScheme === 'DARK' ? `rgba(160, 255, 187, 0.5)` : `rgba(96, 153, 112, 0.5)`;
    const highlightLighter = colorScheme === 'DARK' ? `rgba(160, 255, 187, 0.25)` : `rgba(96, 153, 112, 0.25)`;

    return (
        <Select
            menuPosition={'fixed'}
            {...rest}
            onFocus={() => setFocused(true)}
            onBlur={() => setFocused(false)}
            theme={(theme) => ({
                ...theme,
                borderRadius: 4,
                colors: {
                    ...theme.colors,
                    neutral10: highlight,
                    // after select dropdown option
                    primary50: color,
                    // Border and Background dropdown color
                    primary: highlightLighter,
                    // Background hover dropdown color
                    primary25: highlight,
                    // Background color
                    neutral0: background,
                    // Border before select
                    neutral20: color,
                    // Hover border
                    neutral30: "#82FFE7",
                    // No options color
                    neutral40: "#CAFFCA",
                    // Select color
                    neutral50: "#F4FFFD",
                    // arrow icon when click select
                    neutral60: "#42FFDD",
                    // Text color
                    neutral80: color,
                },
            })}
            styles={{
                container: provided => ({
                    ...provided,
                    flexGrow: fullWidth ? 1 : 'unset',
                }),
                control: provided => ({
                    ...provided,
                    minWidth: smaller ? 192 : 384,
                    maxWidth: maxWidth,
                    height: 48,
                    borderWidth: 1,
                    borderColor: focused ? `${borderColorActive} !important` : `${borderColorInActive} !important`,
                    boxShadow: 'none',
                    borderRadius: 4,
                    fontSize: 16,
                    color,
                    background,
                    overflowY: 'auto',
                    // zIndex: 10000,
                }),
                menu: provided => ({
                    ...provided,
                    border: `1px solid ${borderColorActive} !important`,
                    zIndex: 10001,
                }),
                indicatorsContainer: provided => ({
                    ...provided,
                    height: 46,
                    position: 'sticky',
                    top: 0,
                    right: 0,
                })

            }}
        />
    )
}

export const mapStateToProps = (state, ownProps) => {
    return {
        colorScheme: state.colorScheme,
        ...ownProps
    }
}

export const SelectWrapperConnected = connect(mapStateToProps)(SelectWrapper)
