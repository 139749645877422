import React, { useEffect, useState } from 'react'
import { ViewContainer } from '../../../components/ViewContainer'
import { makeRequest } from '../../../utilities/endpoints'
import { URLS } from '../../../config'
import { Loader, LOADER_SIZES, LOADER_TYPES } from '../../../components/Loader'
import { Row } from '../../../components/Row'
import { Label } from '../../../components/Label'
import { Input } from '../../../components/Input'
import { Button, BUTTON_TYPES } from '../../../components/Button'
import { Nbsp } from '../../../components/Nbsp'
import { SelectWrapperConnected } from '../../../components/SelectWrapper'
import { Hr } from '../../../components/Hr'

export const Teams = ({ users }) => {
    const [newTeamName, setNewTeamName] = useState()
    const [teams, setTeams] = useState()
    const [newUserToTeam, setNewUserToTeam] = useState()
    const [loaded, setLoaded] = useState(false)

    const getTeams = () => {
        makeRequest.get(`${URLS.main}/teams`)
            .then(res => {
                setTeams(res.data?.teams)
                setLoaded(true)
            })
    }

    const addTeam = () => {
        setNewTeamName(undefined)
        makeRequest.post(`${URLS.main}/teams`, {
            name: newTeamName
        }).then(getTeams)
    }

    const addPersonToTeam = (teamId, userId) => {
        makeRequest.post(`${URLS.main}/teams/${teamId}/${userId}`)
            .then(getTeams)
    }

    const removePersonFromTeam = (teamId, userId) => {
        makeRequest.delete(`${URLS.main}/teams/${teamId}/${userId}`)
            .then(getTeams)
    }

    const removeTeam = (teamId) => {
        makeRequest.delete(`${URLS.main}/teams/${teamId}`)
            .then(getTeams)
    }

    useEffect(() => {
        getTeams()
    }, [])
    return <ViewContainer collapsible title={'Teams'}>
        {loaded ? (
            <>
                {teams.map(team => (
                    <>
                        <Row fullWidth spaceBetween>
                            <Label>
                                {team.name}
                            </Label>
                            <Button
                                type={BUTTON_TYPES.ICON_AND_TEXT}
                                onClick={() => removeTeam(team.id)}
                            >
                                Delete this team
                            </Button>
                        </Row>
                        {team.users?.map(person => (
                            <Row fullWidth spaceBetween>
                                <Row noMargin>
                                    {person.fullName}
                                    <Nbsp />
                                    {person.email}
                                </Row>
                                <Button
                                    type={BUTTON_TYPES.ICON_AND_TEXT}
                                    onClick={() => removePersonFromTeam(team.id, person.id)}
                                >
                                    Remove this person from the team
                                </Button>
                            </Row>
                        ))}
                        <Row fullWidth spaceBetween>
                            <SelectWrapperConnected
                                options={[
                                    { value: undefined, label: 'Select User To Add' },
                                    ...users.map(el => ({
                                        value: el.id,
                                        label: el.fullName || el.email
                                    }))
                                ]}
                                onChange={setNewUserToTeam}
                                value={newUserToTeam}
                            />
                            <Button
                                type={BUTTON_TYPES.ICON_AND_TEXT}
                                onClick={() => addPersonToTeam(team.id, newUserToTeam.value)}
                            >
                                Add this person to this team
                            </Button>
                        </Row>
                        <Hr />
                        <br />
                    </>
                ))}
                <Row fullWidth spaceBetween>
                    <Row noMargin>
                        <Label>
                            Team Name
                        </Label>
                        <Nbsp />
                        <Input
                            placeholder={'Team Name'}
                            value={newTeamName}
                            onChange={e => setNewTeamName(e.target.value)}
                        />
                    </Row>
                    <Button
                        onClick={addTeam}
                    >
                        Add This New Team
                    </Button>
                </Row>
            </>
        ) : <Loader type={LOADER_TYPES.SPINNER} size={LOADER_SIZES.BIG} />}
    </ViewContainer>
}