import React from 'react'
import { useLocation, Link } from 'react-router-dom'
import { connect } from 'react-redux'
import { faFilePdf, faMicrochip, faUsersViewfinder } from '@fortawesome/free-solid-svg-icons';
import logo from '../assets/images/favicon-128x128.png'
import { Logout } from '../assets/icons/Logout'
import { Home } from '../assets/icons/Home'
import { TaskList } from '../assets/icons/TaskList'
import { Accounts } from '../assets/icons/Accounts'
import { Opportunities } from '../assets/icons/Opportunities'
import { Settings } from '../assets/icons/Settings'
import { Renewals } from '../assets/icons/Renewals'
import cn from './Sidebar.module.css'
import { setUser } from '../store/actions'
import { makeRequest } from '../utilities/endpoints'
import { PermissionCheck } from './PermissionCheck'
import { WorkspaceTypeCheck } from './WorkspaceTypeCheck'
import { URLS } from '../config'
import { Callypso } from '../assets/icons/Callypso';
import { IdentityProviderTypeCheck } from './IdentityProviderType';
import { translateCRMLabel } from '../utilities/translateCRMLabels';
import { environmentCheck } from '../utilities/environmentCheck';
import { BUTTON_TYPES, Button } from './Button';
import { TrendingLine } from '../assets/icons/TrendingLine';
import { Playbooks } from '../assets/icons/Playbooks';
import { Expansions } from '../assets/icons/Expansions';
import { Risk } from '../assets/icons/Risk';
import { RulesAndTriggers } from '../assets/icons/RulesAndTriggers';

const MenuEntry = ({
    feature,
    workspaceType,
    workspaceTypes,
    Icon,
    label,
    location,
    fontAwesomeIcon,
    locationForSelectedComparison,
    identityType,
    identityTypes,
    onClick
}) => {
    const { pathname } = useLocation()

    if (!locationForSelectedComparison) {
        locationForSelectedComparison = location
    }

    const selected = locationForSelectedComparison?.length > 1 ? pathname.includes(locationForSelectedComparison) : pathname === locationForSelectedComparison

    return (
        <WorkspaceTypeCheck requiredType={workspaceType} requiredTypes={workspaceTypes}>
            <IdentityProviderTypeCheck requiredType={identityType} requiredTypes={identityTypes}>
                <PermissionCheck requiredPermissions={feature ? [[feature, 'READ']] : null}>
                    <Link to={location} className={cn.menuEntry} data-tooltip-id='default' data-tooltip-content={label}>
                        <Button
                            onClick={onClick}
                            type={BUTTON_TYPES.TRANSPARENT_ICON}
                            customIcon={!!Icon && <Icon fill={'currentColor'} />}
                            icon={!!fontAwesomeIcon && fontAwesomeIcon}
                            className={selected ? cn.selected : ''}
                        />
                    </Link>
                </PermissionCheck>
            </IdentityProviderTypeCheck>
        </WorkspaceTypeCheck>
    )
}

export const Sidebar = ({ dispatch, user }) => {
    const signOut = () => {
        makeRequest.get(`${URLS.main}/signOut`)
        localStorage.clear()
        dispatch(setUser(null))
    }
    const reduced = localStorage.getItem('reduced') === 'true'

    const userInitials = user?.email && user?.email[0] ? user.email[0].toUpperCase() : ''

    const menuEntries = [
        <MenuEntry Icon={Home} label='Home' location='/' />,
        reduced ? null : <MenuEntry feature={'TASKS'} label='Plays' Icon={TaskList} location="/tasks/Today/0/null/null/null" locationForSelectedComparison='tasks' />,
        <MenuEntry feature={'ACCOUNTS'} Icon={Accounts} label={translateCRMLabel('Accounts')} location="/accounts/0/null" locationForSelectedComparison='account' />,
        <MenuEntry feature={'GLOBAL_WHITE_SPACE_PLANNING'} Icon={Expansions} label="Expansion Tracker" location="/global-white-space-planning/0" locationForSelectedComparison='global-white-space-planning' />,
        <MenuEntry feature={'RISK_MANAGEMENT'} Icon={Risk} label="Risk Management" location="/risk/0" />,
        reduced ? null : <MenuEntry feature={'PORTFOLIO_VIEWS'} fontAwesomeIcon={faUsersViewfinder} label="Portfolio Views" location="/portfolio-views/0" locationForSelectedComparison='portfolio-views' />,
        <MenuEntry feature={'OPPORTUNITIES'} Icon={Opportunities} label={translateCRMLabel('Opportunities')} location="/opportunities-management" />,
        reduced ? null : <MenuEntry feature={'RENEWALS_MANAGEMENT'} Icon={Renewals} label="Renewals Management" location="/renewals-management" />,
        <MenuEntry feature={'METRICS'} Icon={TrendingLine} label="Metrics" location="/metrics" />,
        reduced ? null : <MenuEntry feature={'RULES'} Icon={Playbooks} label="Playbooks" location="/playbooks/0" locationForSelectedComparison='playbook' />,
        reduced ? null : <MenuEntry feature={'RULES'} Icon={RulesAndTriggers} label="Rules And Triggers" location="/rules/0/null" locationForSelectedComparison='rule' />,
        <MenuEntry feature={'GENERATIVE_PDF'} workspaceTypes={['SFDC', 'SFDC_SANDBOX']} fontAwesomeIcon={faFilePdf} label={'Generative Pdf'} location="/generative-pdf" />,
        <MenuEntry feature={'SETTINGS'} workspaceType={'CALLYPSO'} Icon={Callypso} label="Callypso Core" location="/callypso-crm" />,
        <MenuEntry feature={'SETTINGS'} Icon={Settings} label="Settings" location="/settings" />,
        environmentCheck(['LOCAL', 'DEV']) ? <MenuEntry fontAwesomeIcon={faMicrochip} beta label="Chat" location="/chat-with-your-data" /> : null,
    ].filter(el => !!el)


    return (
        <div className={cn.sidebar}>
            <img className={`${cn.logo} ${cn.logoPlacement}`} src={logo} alt="Workflow" />
            <div className={cn.topPart}>
                {menuEntries}
            </div>
            <div className={cn.bottomPart}>
                <MenuEntry Icon={Logout} label="Log Out" onClick={signOut} />
                <div className={cn.userWrapper}>
                    {user ? user.identityProviderType === 'MS' ? (
                        <div className={cn.msInitials}>
                            {userInitials}
                        </div>
                    ) : (
                        <img className={cn.userImg} src={user.picture} alt="" />
                    ) : null }
                </div>
            </div>
        </div>
    )
}

export const mapStateToProps = (state) => {
    return {
        user: state.user,
    }
}

export const SidebarConnected = connect(mapStateToProps)(Sidebar)
