import React from 'react'
import cn from './ProductUsageElement.module.css'

export const getColorClass = (data) => {
    switch (data) {
    case 1:
    case 'RED':
        return cn.red
    case 2:
    case 'YELLOW':
        return cn.yellow
    case 3:
    case 'GREEN':
        return cn.green
    default:
        return cn.gray
    }
}
export const ProductUsageElement = ({ data, dataTip }) => {
    return <div className={`${cn.productUsage} ${getColorClass(data)}`} data-tooltip-id='default' data-tooltip-content={dataTip} />
}