import React, { useState } from 'react'
import { connect } from 'react-redux';
import { Checkbox } from '../../../components/Checkbox'
import { Row } from '../../../components/Row'
import { Table } from '../../../components/Table'
import { SubHeader } from '../../../components/SubHeader';
import { Button } from '../../../components/Button';
import { Input } from '../../../components/Input';
import cn from './PermissionsPerGroup.module.css'
import { permissionCheck } from '../../../utilities/permissionCheck';
import { SelectWrapperConnected } from '../../../components/SelectWrapper';
import { Nbsp } from '../../../components/Nbsp';

const userRoleDropdown = (userId, value, dropdownOptions, onChange) => (
    <SelectWrapperConnected
        smaller
        placeholder="Permission Group for the User"
        options={dropdownOptions}
        isDisabled={!permissionCheck('SETTINGS', 'UPDATE')}
        value={dropdownOptions.find(el => el.value === value)}
        onChange={data => onChange(userId, data.value)}
    />
)

const checkboxRow = (groupId, feature, levels, checkedData, onGroupPermissionChange, disabled) => (
    <Row>
        <div>
            <div data-tooltip-id='default' data-tooltip-content='Read/See the feature'>R</div>
            <Checkbox
                disabled={disabled}
                checked={checkedData.includes(Object.values(levels)[0])}
                onClick={(e) => onGroupPermissionChange(groupId, feature, Object.keys(levels)[0], e.target.checked)}
            />
        </div>
        <Nbsp />
        <div>
            <div data-tooltip-id='default' data-tooltip-content='Create in the feature'>C</div>
            <Checkbox
                disabled={disabled}
                checked={checkedData.includes(Object.values(levels)[1])}
                onClick={(e) => onGroupPermissionChange(groupId, feature, Object.keys(levels)[1], e.target.checked)}
            />
        </div>
        <Nbsp />
        <div>
            <div data-tooltip-id='default' data-tooltip-content='Edit and Delete in the feature'>U</div>
            <Checkbox
                disabled={disabled}
                checked={checkedData.includes(Object.values(levels)[2])}
                onClick={(e) => onGroupPermissionChange(groupId, feature, Object.keys(levels)[2], e.target.checked)}
            />
        </div>
    </Row>
)

export const PermissionsPerGroup = ({
    groups,
    features,
    levels,
    onGroupPermissionChange,
    onGroupAdded,
    onRemoveGroup,
    users,
    onUserGroupChange,
    onRemoveUser,
    user,
}) => {
    const [newGroupName, setNewGroupName] = useState()
    const [featuresFiltering, setFeaturesFiltering] = useState([])

    const getFilteredFeaturesPerWorkspace = () => {
        const featuresToShow = {}
        const NOT_IMPLEMENTED_NON_SFDC_FEATURES = [
            'ACCOUNT_PLAN_TEMPLATES',
            'QBR',
            'RENEWALS_MANAGEMENT',
            'RISK_MANAGEMENT',
        ]
        Object.keys(features || {}).forEach(key => {
            if (!(user.workspaceType === 'CALLYPSO' && NOT_IMPLEMENTED_NON_SFDC_FEATURES.includes(key))) {
                featuresToShow[key] = features[key]
            }
        })
        return featuresToShow
    }

    const getFilteredFeatures = (features_ = features) => {
        if (!featuresFiltering.length) {
            return features_ || {}
        }
        const showOnly = featuresFiltering.map(el => el.value)
        const featuresToShow = {}
        showOnly.forEach(key => {
            featuresToShow[key] = features_[key]
        })
        return featuresToShow
    }
    const breakHeadersToMultiline = (headers) => {
        return headers.map(header => {
            const numberOfSpaces = header.split('').filter(el => el === ' ').length
            if (numberOfSpaces >= 1) {
                return header.replace(' ', '<br/>').split('<br/>')
            }
            return header
        })
    }

    const customStyles = {}

    const headers = [
        'Group',
        ...breakHeadersToMultiline(Object.values(getFilteredFeatures(getFilteredFeaturesPerWorkspace()))),
        'Actions'
    ]

    for (let i = 0; i < headers.length; i++) {
        const charMultiplier = 3;
        const widthComp = 90
        if (headers[i].length === 2) {
            const biggerChild = headers[i][0].length > headers[i][1].length ? headers[i][0] : headers[i][1]
            customStyles[i] = {
                width: biggerChild.length * charMultiplier + widthComp, // pure try and check magic.
            }
        } else {
            customStyles[i] = {
                width: headers[i].length * charMultiplier + widthComp, // pure try and check magic.
            }
        }
    }

    return (
        <>
            <SubHeader>Users and their groups</SubHeader>
            <Row />
            <Table
                sticky={false}
                headers={[
                    'User',
                    'Group',
                    'Last Activity',
                    'Actions',
                ]}
                customStyles={{
                    2: {
                        width: 150
                    },
                    3: {
                        width: 105
                    }
                }}
                data={
                    users.map(el => ([
                        (el.fullName || el.email),
                        userRoleDropdown(
                            el.id,
                            el.permissionGroup,
                            groups.map(el => ({
                                value: el.id,
                                label: el.name
                            })),
                            onUserGroupChange
                        ),
                        el.lastAction || 'N/A',
                        <Button
                            disabled={el.id === user.id || !permissionCheck('SETTINGS', 'UPDATE')}
                            onClick={() => onRemoveUser(el.id)}
                        >
                            Delete
                        </Button>
                    ]))
                }
                allowCellOverflow={true}
            />
            <Row />
            <Row />
            <Row />
            <Row spaceBetween>
                <SubHeader noBackground>Permission Groups</SubHeader>
                <Nbsp />
                <SelectWrapperConnected
                    placeholder="Features to Show"
                    closeMenuOnSelect={false}
                    isMulti
                    smaller
                    options={
                        Object.keys(features || {}).map(el => ({
                            label: features[el],
                            value: el
                        }))
                    }
                    onChange={data => {
                        setFeaturesFiltering(data)
                    }}
                    value={featuresFiltering}
                />
            </Row>
            <Row />
            <div className={cn.horizontalScroll}>
                <Table
                    noPaddingHeader
                    noPaddingRightHeader
                    className={cn.groupsTable}
                    customStyles={customStyles}
                    sticky={false}
                    headers={headers}
                    data={
                        groups.map(el => {
                            return [
                                el.name,
                                ...Object.keys(getFilteredFeatures(getFilteredFeaturesPerWorkspace()))
                                    .map(featureKey => (
                                        checkboxRow(
                                            el.id,
                                            featureKey,
                                            levels,
                                            el.features.filter(el => el.feature === featureKey).map(el => el.level),
                                            onGroupPermissionChange,
                                            !permissionCheck('SETTINGS', 'UPDATE') || (el.defaultGroup && el.name === 'Admins')
                                        )
                                    )),
                                <Button
                                    onClick={() => onRemoveGroup(el.id)}
                                    disabled={!!el.defaultGroup || !permissionCheck('SETTINGS', 'UPDATE')}
                                >
                                    DELETE
                                </Button>
                            ]
                        })
                    }
                />
            </div>
            <Row spaceBetween>
                <Input
                    disabled={!permissionCheck('SETTINGS', 'UPDATE')}
                    placeholder='New Group Name'
                    onChange={e => setNewGroupName(e.target.value)}
                    value={newGroupName}
                />
                <Button
                    disabled={!permissionCheck('SETTINGS', 'UPDATE')}
                    onClick={() => {
                        onGroupAdded(newGroupName)
                        setNewGroupName('')
                    }}
                >
                    Add a new group
                </Button>
            </Row>
        </>
    )
}

export const mapStateToProps = (state, ownProps) => {
    return {
        user: state.user,
        ...ownProps
    }
}

export const PermissionsPerGroupConnected = connect(mapStateToProps)(PermissionsPerGroup)
