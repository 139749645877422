import { faBullhorn, faCalendarCheck, faCalendarXmark, faCheck, faCircleExclamation, faCircleQuestion, faCircleXmark, faComment, faEnvelope, faGear, faGem, faMoneyCheckDollar, faPeopleArrows, faPeopleGroup, faShieldHalved, faUserCheck, faUserGear, faUserLock, faUserSlash, faUserTie, faUsers } from '@fortawesome/free-solid-svg-icons'
export const accountMapContactRoleIcons = [
    faMoneyCheckDollar,
    faEnvelope,
    faShieldHalved,
    faCircleXmark,
    faCircleQuestion,
    faCircleExclamation,
    faComment,
    faUsers,
    faUserTie,
    faPeopleArrows,
    faUserSlash,
    faUserLock,
    faUserGear,
    faUserCheck,
    faCalendarCheck,
    faCalendarXmark,
    faBullhorn,
    faGem,
    faGear,
    faPeopleGroup,
    faGear,
    faCheck,
]

export default accountMapContactRoleIcons