/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import { ViewContainer } from '../../../../components/ViewContainer'
import { Button } from '../../../../components/Button'
import { Row } from '../../../../components/Row'
import { Label } from '../../../../components/Label'
import { Input } from '../../../../components/Input'
import { Dropdown } from '../../../../components/Dropdown'
import { NBSP_SIZES, Nbsp } from '../../../../components/Nbsp'
import { makeRequest } from '../../../../utilities/endpoints'
import { URLS } from '../../../../config'
import { SelectWrapperConnected } from '../../../../components/SelectWrapper';
import { LOADER_SIZES, LOADER_TYPES, Loader } from '../../../../components/Loader';

export const Freshdesk = ({ fields }) => {
    const [isConnected, setIsConnected] = useState(false)
    const [isOwn, setIsOwn] = useState()
    const [subdomain, setSubdomain] = useState()
    const [connectionStarted, setConnectionStart] = useState(false)

    const [apiKey, setApiKey] = useState()

    const [customFields, setCustomFields] = useState()
    const [selectedCustomField, setSelectedCustomField] = useState()
    const [salesforceField, setSalesforceField] = useState()
    const [salesforceFieldTemp, setSalesforceFieldTemp] = useState()
    const [disabledMappingButton, setDisabledMappingButton] = useState(true)
    const [loaded, setLoaded] = useState(false)

    const getCustomFieldOptions = () => {
        makeRequest.get(`${URLS.main}/freshdesk-tickets-custom-fields`)
            .then(res => setCustomFields(res.data))
    }

    const saveMappingFields = () => {
        makeRequest.post(`${URLS.main}/freshdesk-tickets-custom-fields`, {
            field: selectedCustomField,
            salesforceField: salesforceField.value
        })
        setDisabledMappingButton(true)
    }

    useEffect(() => {
        getCustomFieldOptions()
    }, [])

    useEffect(() => {
        if (!isConnected) {
            makeRequest.get(`${URLS.main}/integrations/authenticationSettings/FRESHDESK`)
                .then(res => {
                    if (res.data) {
                        setIsConnected(true)
                        setIsOwn(res.data.own)
                        setSubdomain(res.data.subdomain)
                        setApiKey(res.data.own ? res.data.apiKey : null)
                        setSelectedCustomField(res.data.field)
                        setSalesforceFieldTemp(res.data.salesforceField)
                        if (!!fields.length) {
                            setLoaded(true)
                        }
                    }
                })
        }
    }, [isConnected])

    useEffect(() => {
        if (!!fields?.length && !!salesforceFieldTemp) {
            setSalesforceField(fields.find(el => el.value === salesforceFieldTemp))
            setLoaded(true)
        }
    }, [salesforceFieldTemp, fields])

    const connect = () => {
        setConnectionStart(true)
        makeRequest.post(`${URLS.main}/integrations`, {
            apiKey,
            subdomain,
            type: 'FRESHDESK'
        }).then(() => setConnectionStart(false))
    }

    return (
        <ViewContainer
            collapsible
            title={(
                <Row noMargin>
                    <div>
                        Freshdesk
                    </div>
                    {isConnected && (
                        <>
                            <Nbsp size={NBSP_SIZES.BIG} />
                            <FontAwesomeIcon icon={faCheckCircle} color='#3EB984' />
                        </>
                    )}
                </Row>
            )}
        >
            {!loaded ? (
                <Loader type={LOADER_TYPES.SPINNER} size={LOADER_SIZES.SMALL} />
            ) : (
                <>
                    {!!isConnected && !isOwn ? (
                        <i className={'font-size-smaller'}>You have not established your own connection to Freshdesk, but someone in your workspace has. This will limit you to be able to only read the data from Freshdesk.</i>
                    ) : null}
                    <Row fullWidth spaceBetween>
                        <Row noMargin>
                            <Label>Subdomain</Label>
                            <Nbsp />
                            <Input onChange={e => setSubdomain(e.target.value)} value={subdomain} />
                            <Nbsp size={NBSP_SIZES.EXTRA_BIG} />

                            <Label>API Key</Label>
                            <Nbsp />
                            <Input onChange={e => setApiKey(e.target.value)} value={apiKey} />
                        </Row>
                        <Button onClick={connect} disabled={connectionStarted || (isConnected && isOwn) || !apiKey?.length || !subdomain}>
                            {(isConnected && isOwn) ? 'Connected' : 'Connect'}
                        </Button>
                    </Row>
                    <Row fullWidth spaceBetween>
                        <Row noMargin>
                            <Label>
                                Mapping Field
                            </Label>
                            <Nbsp />
                            <Dropdown
                                values={!customFields?.length ? [] : customFields?.map(el => ({
                                    ...el,
                                    value: el.name
                                }))}
                                value={selectedCustomField}
                                onChange={e => {
                                    setSelectedCustomField(e.target.value)
                                    setDisabledMappingButton(false)
                                }}
                            />
                            <Nbsp size={NBSP_SIZES.BIGGER} />
                            <Label>
                                Salesforce Field
                            </Label>
                            <Nbsp />
                            <SelectWrapperConnected
                                onChange={data => {
                                    setSalesforceField(data)
                                    setDisabledMappingButton(false)
                                }}
                                options={fields}
                                value={salesforceField}
                            />
                        </Row>
                        <Button onClick={saveMappingFields} disabled={disabledMappingButton}>
                            Save
                        </Button>
                    </Row>
                </>
            )}
        </ViewContainer>
    )
}