import React from 'react'
import cn from './DetailsRow.module.css'
import { Label } from '../../../../components/Label'

export const DetailsRow = ({
    label,
    value,
    additionalLabelClass,
    additionalValueClass,
    dataTip,
    postValue
}) => (
    <div
        className={cn.detailsRow}
        data-tooltip-id='default'
        data-tooltip-content={dataTip}
    >
        <Label className={additionalLabelClass}>
            {label}
        </Label>
        <div className={`${cn.value} ${additionalValueClass}`}>
            {value}{postValue}
        </div>
    </div>
)