import React, { useState } from 'react'
import cn from './FocusedNote.module.css'
import { Modal } from '../../../../components/Modal'
import { Button } from '../../../../components/Button'
import { Row } from '../../../../components/Row'
import { NoteAttachments } from '../../../../components/Notes/NoteAttachments'
import { SlateEditor } from '../../../../components/SlateEditor'

export const FocusedNote = ({ note, onRemoveAttachment }) => {
    const [showDeleteAttachment, setShowDeleteAttachment] = useState(false)
    const [removedAttachment, setRemovedAttachment] = useState(false)

    if (!note) return <div className={cn.focusedNote} />

    return (
        <div className={cn.focusedNote}>
            <div className={cn.noteTitle}>
                {note.title}
            </div>
            {note.rich ? (
                <SlateEditor
                    key={note.id}
                    fullWidth
                    viewOnly
                    smallFont
                    noMinHeight
                    className={cn.noteBody}
                    value={note.body}
                />
            ) : (
                <div className={cn.noteBody}>{note.body}</div>
            )}
            <NoteAttachments
                note={note}
                removedAttachment={removedAttachment}
                onRemoveAttachment={onRemoveAttachment}
                setShowDeleteAttachment={setShowDeleteAttachment}
            />
            {!!showDeleteAttachment && (
                <Modal
                    header={`Remove ${showDeleteAttachment.attachment.name}?`}
                    onClose={() => setShowDeleteAttachment(null)}
                    buttons={(
                        <Row spaceBetween fullWidth>
                            <Button
                                onClick={() => {
                                    onRemoveAttachment(showDeleteAttachment)
                                    setRemovedAttachment(showDeleteAttachment.attachment.id)
                                    setShowDeleteAttachment(null)
                                }}
                            >
                                Remove
                            </Button>
                            <Button
                                onClick={() => setShowDeleteAttachment(null)}
                            >
                                Cancel
                            </Button>
                        </Row>
                    )}
                >
                    Are you sure you want to delete this attahment? This will permanently delete this file from your CRM.
                </Modal>
            )}
        </div>
    )
}