import React, { useEffect, useState } from 'react'
import { SelectWrapperConnected } from './SelectWrapper';
import cn from './ReactSelectDynamic.module.css'


const MenuListOverride = ({ innerRef, children, getValue, options }) => {
    const [additions, setAdditions] = useState(1)
    const [previousOptionsLength, setPreviousOptionsLength] = useState()

    useEffect(() => {
        if (previousOptionsLength !== children?.length) {
            setPreviousOptionsLength(children?.length)
            setAdditions(1)
        }
    }, [previousOptionsLength, children?.length])

    const maximumAllowedEntries = 200;
    const value = getValue() && getValue()[0]
    let selectedOptionIndex = options.findIndex(el => el.label === value?.label && el.value === value?.value)
    if (selectedOptionIndex === -1) {
        selectedOptionIndex = 0
    }
    const numberOfAddedOptions = maximumAllowedEntries * additions
    const hasMore = options.length >= numberOfAddedOptions
    let childrenCopy;

    if (children && !!children.splice && !!children.unshift) {
        if (selectedOptionIndex !== 0) {
            const selectedElement = children[selectedOptionIndex]
            childrenCopy = children.toSpliced(selectedOptionIndex, 1)
            childrenCopy.unshift(selectedElement)
        }
        childrenCopy = children.slice(0, hasMore ? numberOfAddedOptions : options.length)
    }
    return (
        <div
            ref={innerRef}
            className={cn.menuListOverride}
            onScroll={(e) => {
                const dropdownHeight = e.target.scrollHeight
                const visualHeight = e.target.clientHeight
                const scrolled = e.target.scrollTop
                const reachedBottomEnd = scrolled + visualHeight >= dropdownHeight - visualHeight
                if (reachedBottomEnd && hasMore) {
                    setAdditions(additions + 1)
                }
            }}
        >
            {childrenCopy}
        </div>
    )
}

export const ReactSelectDynamic = ({ isMulti, ...rest }) => {
    if (isMulti) {
        return <SelectWrapperConnected
            isMulti
            {...rest}
        />
    }

    return <SelectWrapperConnected
        {...rest}
        components={{
            MenuList: MenuListOverride
        }}
    />
}