import React, { useRef, useState } from 'react'
import { faRotateRight } from '@fortawesome/free-solid-svg-icons'
import cn from './ChatWithAssistant.module.css'
import { ViewContainer } from '../../components/ViewContainer'
import { SubHeader } from '../../components/SubHeader'
import { TextArea } from '../../components/TextArea'
import { BUTTON_TYPES, Button } from '../../components/Button'
import { LOADER_SIZES, LOADER_TYPES, Loader } from '../../components/Loader'
import { makeRequest } from '../../utilities/endpoints'
import { URLS } from '../../config'
import { useInterval } from '../../customHooks/useInterval'
import { Row } from '../../components/Row'
import { Nbsp } from '../../components/Nbsp'

export const ChatWithAssistant = () => {
    const [response, setResponse] = useState()
    const [askText, setAskText] = useState()
    const [asking, setAsking] = useState(false)
    const [checkAgain, setCheckAgain] = useState(false)
    const [streamData, setStreamData] = useState()
    const [streamDataQuestion, setStreamDataQuestion] = useState()
    const [numberOfDots, setNumberOfDots] = useState(1)
    const [scrollAuto, setScrollAuto] = useState(false)

    const messagesEndRef = useRef(null)

    const updateNumberOfDots = () => setNumberOfDots(numberOfDots >= 3 ? 1 : numberOfDots + 1)

    const check = () => {
        makeRequest.get(`${URLS.main}/chatWithAssistantTemp`)
            .then(res => {
                if (res && res.data) {
                    setStreamData(res.data.temp)
                    setStreamDataQuestion(res.data.question)
                    scrollToBottom()
                }
            })
    }

    const ask = () => {
        setCheckAgain(true)
        setScrollAuto(true)
        makeRequest.post(`${URLS.main}/chatWithAssistant`, {
            message: askText
        })
            .then(res => {
                if (res && res.data) {
                    setResponse(res.data.messages)
                    setCheckAgain(false)

                    setStreamData(undefined)
                    setStreamDataQuestion(undefined)
                    setScrollAuto(false)
                    setTimeout(() => scrollToBottom(), 1)
                }
                setAskText(undefined)
                setAsking(false)
            })
        setAsking(true)
    }

    const reset = () => {
        makeRequest.get(`${URLS.main}/resetChatWithAssistant`)
        setResponse(undefined)
    }

    const scrollToBottom = () => {
        if (scrollAuto) {
            messagesEndRef.current?.scrollIntoView({ behavior: "smooth" })
        }
    }

    useInterval(() => {
        check()
        updateNumberOfDots()
    }, checkAgain ? 750 : null)

    return (
        <ViewContainer grow className={cn.wrapper}>
            <SubHeader>Chat with your data</SubHeader>
            <div className={cn.responses} onScroll={() => setScrollAuto(false)}>
                {response?.map(message => (
                    <div className={cn.message}>
                        <div className={cn.role}>{message.role}:</div>
                        <div className={cn.content}>{message?.content[0]?.text?.value?.split('\n').map(str => <p>{str}</p>)}</div>
                    </div>
                ))}
                {checkAgain ? (
                    <>
                        <div className={cn.message}>
                            <div className={cn.role}>{streamDataQuestion?.role}:</div>
                            <div className={cn.content}>{streamDataQuestion?.question?.split('\n').map(str => <p>{str}</p>)}</div>
                        </div>
                        <div className={cn.message}>
                            <div className={cn.role}>{streamData?.role}:</div>
                            <div className={cn.content}>
                                {streamData?.value?.split('\n').map(str => <p>{str}</p>)}
                                <b>
                                    {new Array(numberOfDots).fill('.').join('')}
                                </b>
                            </div>
                        </div>
                    </>
                ) : null}
                <div ref={messagesEndRef} />
            </div>
            <div className={`${cn.bottomPart} ${asking ? cn.loaderWrapper : ''}`}>
                {asking ? (
                    <Loader type={LOADER_TYPES.SPINNER} size={LOADER_SIZES.BIG} />
                ) : (
                    <>
                        <TextArea
                            value={askText}
                            onKeyDown={e => {
                                if (e.key === 'Enter' && (e.ctrlKey || e.metaKey)) {
                                    ask()
                                }
                            }}
                            onChange={e => setAskText(e.target.value)}
                        />
                        <Row fullWidth noMargin>
                            <Button
                                className={cn.askButton}
                                onClick={ask}
                            >
                                Send
                            </Button>
                            <Nbsp />
                            <div data-tooltip-id='default' data-tooltip-content='Start a new conversation'>
                                <Button
                                    icon={faRotateRight}
                                    type={BUTTON_TYPES.ICON}
                                    className={cn.resetButton}
                                    onClick={reset}
                                >
                                    New Conversation
                                </Button>
                            </div>
                        </Row>
                    </>
                )}
            </div>
        </ViewContainer>
    )
}