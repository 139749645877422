import axios from "axios"
import { URLS } from '../config'
import store from '../store';
import { addGlobalError, setUser } from "../store/actions";

const CryptoJS = require("crypto-js");

const getAccessToken = () => {
    return store.getState()?.user?.id
}

const shouldEncrypt = () => {
    return !!getAccessToken() && !!process.env.REACT_APP_ENCRYPT_KEY && process.env.REACT_APP_ENCRYPT_KEY !== 'false'
}

const generateKey = auth => {
    return process.env.REACT_APP_ENCRYPT_KEY + auth
}

const encrypt = ((auth, data) => {
    if (shouldEncrypt()) {
        return CryptoJS.AES.encrypt(JSON.stringify(data), generateKey(auth)).toString();
    }
    return data
});

const decrypt = ((auth, { data, ...rest }) => {
    if (shouldEncrypt()) {
        try {
            const bytes = CryptoJS.AES.decrypt(data, generateKey(auth));
            const decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
            return {
                ...rest,
                data: decryptedData
            }
        } catch (e) {
            return {
                ...rest,
                data
            }
        }
    }
    return {
        ...rest,
        data,
    }
});

const encryptUrl = async (url) => {
    if (shouldEncrypt()) {
        const token = await getAccessToken()
        const urlParts = url.replace(URLS.main, '').split('/').filter(el => !!el)
        const newUrl = `${URLS.main}/${urlParts.map(el => encodeURIComponent(encrypt(token, el).replaceAll('/', '\\'))).join('/')}`
        return newUrl[newUrl.length - 1] === '/' ? newUrl.slice(0, newUrl.length - 1) : newUrl
    }
    return url
}

const hasSFDCError = response => {
    if (response?.data?.name === 'invalid_grant') return true
    if (response?.data?.errorMessage === 'NO_ADMIN') return true
}

const hasPermissionError = response => response?.data?.error === 'INSUFFICIENT_PERMISSIONS'

const addPermissionError = () => {
    const homepage = '/'
    if (window.location.pathname !== homepage) {
        window.location.href = `${window.location.origin}${homepage}`
    }
    store.dispatch(addGlobalError({
        message: 'You have insufficient permissions to view this resource. If you think it is a mistake, please contact your administrator.',
    }))
}

const addSFDCError = () => {
    store.dispatch(addGlobalError({
        message: 'Salesforce connection error: expired access/refresh token',
        button: {
            path: '/settings',
            label: 'Try to reestablish the connection'
        }
    }))
}

const checkGlobalErrors = (response, skipPermissionError) => {
    if (hasSFDCError(response)) {
        addSFDCError()
    }
    if (hasPermissionError(response) && !skipPermissionError) {
        addPermissionError()
    }
    if (response && response?.data?.error === 'WRONG_USER_CREDENTIALS') {
        store.dispatch(setUser(null))
    }
    if (response && (response?.data?.errorCode || response?.data?.name)) {
        const error = response?.data?.errorCode || response?.data?.name || null;
        switch (error) {
        case 'ENTITY_IS_DELETED':
            store.dispatch(addGlobalError({
                message: 'We are sorry, but this resource can not be retrieved',
            }))
            break;
        default:
            break;
        }
    }
}

export const makeRequest = {
    get: (url, data = {}, skipPermissionError) => {
        console.info('make request get!', document.cookie)
        const controller = new AbortController();
        const promise = new Promise(async (resolve, reject) => {
            const token = await getAccessToken()
            const updatedUrl = await encryptUrl(url)
            axios.get(updatedUrl, {
                signal: controller.signal,
                ...data
            })
                .then(res => {
                    console.info('get result', res)
                    resolve(res && res.data ? decrypt(token, res) : res)
                })
                .catch(error => {
                    if (error && ['CanceledError', 'AbortError'].includes(error.name)) {
                        // do absolutely nothing. It simply means the request has been cancelled
                    } else {
                        if (error?.response) {
                            error.response = decrypt(token, error.response)
                        }
                        checkGlobalErrors(error?.response, skipPermissionError)
                        reject(error)
                    }
                })
        })
        promise.cancel = () => controller.abort()
        return promise
    },
    post: (url, data, headers) => {
        const controller = new AbortController();
        const promise = new Promise(async (resolve, reject) => {
            const token = await getAccessToken()
            const updatedUrl = await encryptUrl(url)
            axios.post(updatedUrl, shouldEncrypt() ? { data: encrypt(token, data) } : data, headers)
                .then(res => {
                    resolve(res && res.data ? decrypt(token, res) : res)
                })
                .catch(error => {
                    if (error && ['CanceledError', 'AbortError'].includes(error.name)) {
                        // do absolutely nothing. It simply means the request has been cancelled
                    } else {
                        if (error?.response) {
                            error.response = decrypt(token, error.response)
                        }
                        checkGlobalErrors(error?.response)
                        reject(error)
                    }
                })
        })
        promise.cancel = () => controller.abort()
        return promise
    },
    delete: (url, data) => {
        const controller = new AbortController();
        const promise = new Promise(async (resolve, reject) => {
            const token = await getAccessToken()
            const updatedUrl = await encryptUrl(url)
            axios.delete(updatedUrl, data)
                .then(res => {
                    resolve(res && res.data ? decrypt(token, res) : res)
                })
                .catch(error => {
                    if (error && ['CanceledError', 'AbortError'].includes(error.name)) {
                        // do absolutely nothing. It simply means the request has been cancelled
                    } else {
                        if (error?.response) {
                            error.response = decrypt(token, error.response)
                        }
                        checkGlobalErrors(error?.response)
                        reject(error)
                    }
                })
        })
        promise.cancel = () => controller.abort()
        return promise
    },
    put: async (url, data, headers) => {
        const controller = new AbortController();
        const promise = new Promise(async (resolve, reject) => {
            const token = await getAccessToken()
            const updatedUrl = await encryptUrl(url)
            axios.put(updatedUrl, shouldEncrypt() ? { data: encrypt(token, data) } : data, headers)
                .then(res => {
                    resolve(res && res.data ? decrypt(token, res) : res)
                })
                .catch(error => {
                    if (error && ['CanceledError', 'AbortError'].includes(error.name)) {
                        // do absolutely nothing. It simply means the request has been cancelled
                    } else {
                        if (error?.response) {
                            error.response = decrypt(token, error.response)
                        }
                        checkGlobalErrors(error?.response)
                        reject(error)
                    }
                })
        })
        promise.cancel = () => controller.abort()
        return promise
    }
}
