import React from 'react';
import cn from './PrivacyPolicy.module.css'

export const PrivacyPolicy = () => (
    <div className={`${cn.privacyPolicy} text-gray-800 mx-8 lg:mx-96 py-8`}>
        <h2 className="text-3xl font-bold leading-7 sm:text-3xl sm:truncate mb-4" style={{ color: '#F08178' }}>
            Callypso Privacy Statement
        </h2>
        <p>
            We are committed to protecting the privacy of individuals who register to use our Services (“Users”), individuals who visit our Web sites (“Visitors”), and individuals whom our Customers are contacting to do business with (“Contacts”). This Privacy Policy describes what data we collect, why we collect it, and how we use it as well as your options to control, access and correct your personal information.
        </p>
        <p>
            Callypso’s use of information received from Google APIs will adhere to Google API Services User Data Policy, including the Limited Use requirements.
        </p>
        <p>
            App’s use of information received, and App’s transfer of information to any other app, from Google APIs will adhere to Google’s Limited Use Requirements.
        </p>
        <h4 className="text-xl my-4 ml-4 text-gray-600">
            1. User Information
        </h4>
        <p>
            When registering to use our Services, Callypso does NOT requires you to provide us with personal information, all of the “Required User Information” is being retrieved via Google API Services. When purchasing the Services, Callypso may also require you to provide Callypso with financial qualification and billing information, such as billing name and address, credit card number, and the number of employees within the organization that will be using the Services (“Billing Information”). Callypso may also ask you to provide additional information, such as company annual revenues, number of employees, or industry (“Optional Information”). Required User Information, Billing Information, Optional Information and any other information you submit to Callypso to or through the Services are referred to collectively as “User Data.”
        </p>
        <p>
            Callypso may also receive information about Users from other sources, including third parties from whom we have purchased data, and combine this information with Data we already have about you.  This helps us to update, expand and analyze our records, identify new Users, and provide products and services that may be of interest to you.
        </p>
        <p>
            Callypso acknowledges that you have the right to access your personal information.  If personal information pertaining to you as an individual has been submitted to us by a Callypso Customer (typically your company) and you wish to exercise any rights you may have to access, correct, amend, or delete such data, please inquire with our customer directly.
        </p>
        <h4 className="text-xl my-4 ml-4 text-gray-600">
            2. Visitor Information
        </h4>
        <h4 className="text-lg my-4 ml-4 text-gray-600">
            Cookies
        </h4>
        <p>
            Callypso uses cookies that are persistent-based. Persistent cookies remain on your computer after you close your browser or turn off your computer. You can control the use of cookies at the individual browser level, but if you choose to disable cookies, it may limit your use of certain features or functions on our Web sites or Services.
        </p>
        <p>
            Callypso may partner with third parties to manage our advertising on other sites. Our third-party partners may use cookies or similar technologies to provide advertising based upon your browsing activities and interests.
        </p>
        <p>
            Callypso posts a list of Users and testimonials on Callypso’s Web sites that contain information such as Customer names and titles. Callypso obtains the consent of each Customer prior to posting any information on such a list or posting testimonials.
        </p>
        <h4 className="text-xl my-4 ml-4 text-gray-600">
            3. Contact Information
        </h4>
        <p>
            In order to provide our Services, we may need to access data about your Contacts. For example to list accounts and their contact information. We access this information in real time from the system of record you gave us access to, such as your CRM, and only use it as part of the transaction and might store it only temporarily to reduce the number of requests we make to your CRM.
        </p>
        <h4 className="text-xl my-4 ml-4 text-gray-600">
            4. Sharing of information collected
        </h4>
        <p>
            Callypso does not share, sell, rent, or trade any information with third parties for their promotional purposes.
        </p>
        <h4 className="text-lg my-4 ml-4 text-gray-600">
            Billing
        </h4>
        <p>
            Callypso uses a third-party service provider to manage credit card processing. This service provider is not permitted to store, retain, or use Billing Information except for the sole purpose of credit card processing on Callypso’s behalf.
        </p>
        <h4 className="text-lg my-4 ml-4 text-gray-600">
            Compelled Disclosure
        </h4>
        <p>
            Callypso reserves the right to use or disclose information provided if required by law or if Callypso reasonably believes that use or disclosure is necessary to protect Callypso’s rights and/or to comply with a judicial proceeding, court order, or legal process.
        </p>
        <h4 className="text-xl my-4 ml-4 text-gray-600">
            5. International transfer of information collected
        </h4>
        <p>
            Callypso currently stores Data in the United States. To facilitate Callypso’s global operations, Callypso may transfer and access such information from around the world, including from other countries in which Callypso has operations. This Privacy Statement shall apply even if Callypso transfers Data from other countries.
        </p>
        <h4 className="text-xl my-4 ml-4 text-gray-600">
            6. Data Storage & Retention
        </h4>
        <p>
            Callypso retains your information during the time in which you have an account. You can choose to have us keep this information for a longer or shorter time by contacting us. We also may retain your information during the period of time needed for Callypso to pursue our legitimate business interests, conduct audits, comply with our legal obligations, resolve disputes and enforce our agreements.
        </p>
        <h4 className="text-xl my-4 ml-4 text-gray-600">
            7. Security
        </h4>
        <p>
            Callypso uses robust security measures to protect Data about Callypso Users, Visitors and Contacts.
        </p>
        <h4 className="text-xl my-4 ml-4 text-gray-600">
            8. Changes to this Privacy Statement
        </h4>
        <p>
            Callypso reserves the right to change this Privacy Statement. Callypso will provide notification of the material changes to this Privacy Statement through Callypso’s Web sites or by email at least thirty (30) business days prior to the change taking effect.
        </p>
        <h4 className="text-xl my-4 ml-4 text-gray-600">
            9. Contacting us
        </h4>
        <p>
            Questions regarding this Privacy Statement or the information practices of Callypso’s Web sites and Services should be directed to Callypso Privacy by filling by mailing us at <a style={{ color: '#F08178' }} href="mailto:support@callypso.co">support@callypso.co</a>.
        </p>


    </div>
);
