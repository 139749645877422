/* eslint eqeqeq: 0 */
import React, { useEffect, useState, useRef } from 'react';
import { connect } from 'react-redux';
import debounce from 'lodash.debounce'
import { domToPng } from 'modern-screenshot'
import { useParams } from 'react-router-dom'
import { faAlignLeft, faMagicWandSparkles, faMagnifyingGlass, faMagnifyingGlassLocation } from '@fortawesome/free-solid-svg-icons';
import cn from './AccountMapping.module.css'
import { Loader, LOADER_SIZES, LOADER_TYPES } from '../../../components/Loader';
import { makeRequest } from '../../../utilities/endpoints'
import { Sidebar } from './components/Sidebar';
import { Row } from '../../../components/Row';
import { Modal } from '../../../components/Modal';
import { Button, BUTTON_TYPES } from '../../../components/Button';
import { URLS } from '../../../config';
import { CreateContactModal } from './components/CreateContactModal';
import { Heading } from '../../../components/Heading';
import { NBSP_SIZES, Nbsp } from '../../../components/Nbsp';
import { Grid } from './components/Grid';
import { workspaceTypeCheck } from '../../../utilities/workspaceType';
import { NOTES_TYPES, Notes } from '../../../components/Notes/Notes';

const addedFieldsToBeRemoved = [
    'id',
    'shown',
    'firstName',
    'lastName',
    'email',
    'buyer',
    'champion',
    'decisionMaker',
    'positive',
    'reachedOutTo',
    'x',
    'y',
    'email',
    'title',
    'contactId',
    'accountId',
    'role',
    'roleId',
    'Account',
    'PhotoUrl',
]

export const AccountMapping = ({ user }) => {
    const { accountId } = useParams();
    const printRef = useRef();
    const gridRef = useRef()

    const [generatingImage, setGeneratingImage] = useState(false)
    const [accountMapContacts, setAccountMapContacts] = useState(null)
    const [initialMergeDone, setInitialMergeDone] = useState(false)
    const [accountDetails, setAccountDetails] = useState(null)
    const [contactToBeEditted, setContactToBeEditted] = useState(false)
    const [contactsSavedMapData, setContactsSavedMapData] = useState(null)
    const [removeShowConfirmOfContact, setRemoveShowConfirmOfContact] = useState(false)

    const [showLoader, setShowLoader] = useState(true)

    const [contactIdToBeRemoved, setContactIdToBeRemoved] = useState(null)
    const [contactIdToBeEditted, setContactIdToBeEditted] = useState(null)

    const [customContactFields, setCustomContactFields] = useState([])
    const [contactToBeAdded, setContactToBeAdded] = useState(null)

    const [addContactRequested, setAddContactRequested] = useState()
    const [editContactRequested, setEditContactRequested] = useState()

    const [contactToRemoveFromSfdc, setContactToRemoveFromSfdc] = useState(null)

    const [contactCreateOrUpdateError, setContactCreateOrUpdateError] = useState(null)
    const [contactsLinkedInDetails, setContactsLinkedInDetails] = useState(null)
    const [notesContactId, setNotesContactId] = useState(null)
    const [connectedAccounts, setConnectedAccounts] = useState(null)
    const [loadedAccountId, setLoadedAccountId] = useState(accountId)
    const [contactRoles, setContactRoles] = useState([])
    const [showAutoMapWarning, setShowAutoMapWarning] = useState(false)
    const [newAccountCreated, setNewAccountCreated] = useState(false)
    const [skipNumberOfContactsCheck, setSkipNumberOfContactsCheck] = useState(false)

    const onEdit = contactId => setContactIdToBeEditted(contactId)

    const onRemoveFromMap = contactId => {
        setAccountMapContacts(accountMapContacts.map(el => {
            if (el.id == contactId) {
                return {
                    ...el,
                    shown: false
                }
            }
            return el
        }))
    }

    const onRemoveFromSFDC = contactId => {
        setContactToRemoveFromSfdc(contactId)
        setRemoveShowConfirmOfContact(contactId)
    }

    const getConnectedAccounts = () => {
        makeRequest.get(`${URLS.main}/connected-accounts/${accountId}`)
            .then(res => {
                setConnectedAccounts(res.data)
            })
    }

    const deleteContact = () => {
        setRemoveShowConfirmOfContact(false)
        makeRequest.delete(`${URLS.main}/contacts/${contactToRemoveFromSfdc}`)
            .then(() => {
                setAccountMapContacts(accountMapContacts.filter(el => el.id !== contactToRemoveFromSfdc))
                setContactToRemoveFromSfdc(null)
            }).catch(e => {
                if (e?.response?.status === 404) {
                    switch (e?.response?.data?.errorCode) {
                    case 'ENTITY_IS_DELETED':
                        setAccountMapContacts(accountMapContacts.filter(el => el.id !== contactToRemoveFromSfdc))
                        break;
                    default:
                        break;
                    }
                }
                setContactToRemoveFromSfdc(null)
            });
    }

    const getCustomContactFields = () => {
        makeRequest.get(`${URLS.main}/describe/Contact`)
            .then(res => {
                setCustomContactFields(res.data.filter(el => el.value.includes('__c')))
            })
    }

    const getDivisionDetails = () => {
        makeRequest.get(`${URLS.main}/account-mapping/${getAllConnectedAccountIds()}/contactsAtCompany`)
            .then(res => {
                if (res && res.data) {
                    setContactsLinkedInDetails(res.data)
                }
            })
    }

    const getAccountDetails = () => {
        makeRequest.get(`${URLS.main}/account/${accountId}`)
            .then(res => {
                if (res && res.data) {
                    setAccountDetails(res.data)
                }
            })
    }

    const onChangeColorForContact = ({ hex }, contactId) => {
        const updatedContactsData = accountMapContacts.map(contact => ({
            ...contact,
            color: contactId == contact.id ? hex : contact.color
        }))
        setAccountMapContacts(updatedContactsData)
        serializeMapData(updatedContactsData)

        makeRequest.put(`${URLS.main}/contacts/color/${contactId}`, { color: hex })
    }

    const onUpdatedReachedOutTo = (contactId, state) => {
        const updatedContactsData = accountMapContacts.map(contact => ({
            ...contact,
            reachedOutTo: contact.id == contactId ? state : contact.reachedOutTo
        }))
        setAccountMapContacts(updatedContactsData)
        serializeMapData(updatedContactsData)
    }

    useEffect(() => {
        if (contactIdToBeRemoved && contactIdToBeRemoved.contactId) {
            const updatedContactsData = accountMapContacts.map(contact => ({
                ...contact,
                shown: contact.id == contactIdToBeRemoved.contactId ? false : contact.shown
            }))
            setAccountMapContacts(updatedContactsData)
            setContactIdToBeRemoved(null)
            serializeMapData(updatedContactsData)
        }
    }, [contactIdToBeRemoved])

    useEffect(() => {
        if (contactIdToBeEditted) {
            setContactToBeEditted(accountMapContacts.find(el => el.id == contactIdToBeEditted))
            setContactIdToBeEditted(null)
        }
    }, [contactIdToBeEditted])

    const onContactMarkedAs = (contactId, roleId) => {
        const updatedContactsData = accountMapContacts.map(contact => {
            if (contact.id == contactId) {
                return {
                    ...contact,
                    roleId
                }
            }
            return contact
        })

        setAccountMapContacts(updatedContactsData)

        makeRequest.post(`${URLS.main}/accountMapContactRoles/setRole`, {
            contactId,
            roleId
        })
    }

    const onUpdatePositive = (contactId, positive) => {
        const updatedContactsData = accountMapContacts.map(contact => ({
            ...contact,
            positive: contact.id == contactId ? positive : contact.positive
        }))

        setAccountMapContacts(updatedContactsData)
        serializeMapData(updatedContactsData)
    }

    const onOpenNotes = contactId => {
        setNotesContactId(contactId)
    }

    useEffect(() => {
        if (accountDetails && accountMapContacts && contactsLinkedInDetails && contactsSavedMapData && (!initialMergeDone || newAccountCreated)) {
            const mergedContacts = accountMapContacts.map(contact => {
                const internalDataContact = contactsSavedMapData.contacts.find(innerEl => innerEl.contactId == contact.id)
                const linkedInContactDetails = contactsLinkedInDetails.find(el => el.contactId == contact.id)
                let additionalProps = {}
                if (workspaceTypeCheck(['CALLYPSO'])) {
                    const childAccount = accountDetails.childAccounts.find(el => el.id == internalDataContact?.accountId)
                    additionalProps = {
                        Account: {
                            ...(!childAccount
                                ? { Name: accountDetails.name, Id: accountDetails.id }
                                : { Name: childAccount.name, Id: childAccount.id }
                            )
                        }
                    }
                }
                return {
                    ...internalDataContact,
                    ...contact,
                    shown: internalDataContact?.shown !== 0,
                    linkedInUrl: linkedInContactDetails?.linkedInUrl,
                    linkedInProfileImageUrl: linkedInContactDetails?.linkedInProfileImageUrl,
                    ...additionalProps
                }
            })

            // mergedContacts.filter(contact => profileImages)
            // makeRequest.post(`${URLS.main}/upload-photo`, {
            //     imageUrl: 'https://media.licdn.com/dms/image/C4D03AQFOFLM74NBJ6w/profile-displayphoto-shrink_800_800/0/1645722082978?e=1721865600&v=beta&t=mkKpJFyZr0BnCdQcCPLVCduyei9NNyLLKQ2APofqhrU',
            //     contactId: "0030900001PO7zkAAD"
            // })

            setAccountMapContacts(mergedContacts)

            setShowLoader(false)

            setInitialMergeDone(true)
            setNewAccountCreated(false)
            setTimeout(() => setSkipNumberOfContactsCheck(false), 0)
        }
    }, [accountDetails, accountMapContacts, contactsLinkedInDetails, contactsSavedMapData, initialMergeDone, newAccountCreated])

    useEffect(() => {
        if (!connectedAccounts) {
            getConnectedAccounts()
        } else {
            getCustomContactFields()
            loadContacts()
            loadMapData()
            loadContactRoles()
            getAccountDetails()
            getDivisionDetails()
        }
    }, [connectedAccounts])

    useEffect(() => {
        if (loadedAccountId !== accountId) {
            setShowLoader(true)
            setAccountMapContacts(null)
            setLoadedAccountId(accountId)
            setInitialMergeDone(false)
            setAccountDetails(null)
            setContactsLinkedInDetails(null)
            setConnectedAccounts(null)
        }
    }, [accountId])

    const downloadImage = async () => {
        setGeneratingImage(true)
        const element = printRef.current;

        const node = element

        const mapHandlingButtons = node.querySelector(`#mapButtonsNav`)
        mapHandlingButtons.style.visibility = 'hidden'

        domToPng(element).then(dataUrl => {
            const link = document.createElement('a')
            link.download = `${accountDetails?.name}_map.png`;
            link.href = dataUrl
            link.click()
            setGeneratingImage(false)
            mapHandlingButtons.style.visibility = 'visible'
        })
    }

    const userCheckedFromSidebar = (contact, forcedState) => {
        let updatedContactsData;
        if (Array.isArray(contact)) {
            const contactIds = contact.map(el => el.id)
            updatedContactsData = accountMapContacts.map(el => {
                return {
                    ...el,
                    shown: contactIds.includes(el.id) ? forcedState : el.shown
                }
            })
        } else {
            updatedContactsData = accountMapContacts.map(el => {
                return {
                    ...el,
                    shown: el.id === contact.id ? !el.shown : el.shown
                }
            })
        }

        setAccountMapContacts(updatedContactsData)
        if (!contact.shown) {
            // we need to serialize the data only if the contact is going from unchecked to checked
            // when unchecking a contact, we remove all lines (if any) connected to them and the grid triggers the serialization afterwards
            serializeMapData(updatedContactsData)
        }
    }

    const loadMapData = () => {
        makeRequest.get(`${URLS.main}/accountMaps/${getAllConnectedAccountIds()}`)
            .then(res => {
                if (res.status == 200) {
                    setContactsSavedMapData(res.data)
                }
            })
    }

    const loadContactRoles = () => {
        makeRequest.get(`${URLS.main}/accountMapContactRoles`)
            .then(res => {
                setContactRoles(res.data)
            })
    }

    const getAllConnectedAccountIds = () => [accountId, ...connectedAccounts.childrenAccounts].filter(el => !!el).join(',')

    const loadContacts = (createdANewOne) => {
        makeRequest.get(`${URLS.main}/contacts/departments/${getAllConnectedAccountIds()}`)
            .then(res => {
                const newContactsData = res.data.map(el => ({
                    email: el.Email,
                    firstName: el.FirstName,
                    lastName: el.LastName,
                    id: el.Id,
                    title: el.Title,
                    ...el
                }))
                setAccountMapContacts(newContactsData)
                if (createdANewOne) {
                    setNewAccountCreated(true);
                }
            })
    }

    const addContact = () => {
        const copiedForSubmission = { ...contactToBeAdded }
        addedFieldsToBeRemoved.forEach(el => delete copiedForSubmission[el])
        setAddContactRequested(true)
        // setShowLoader(true)
        makeRequest.post(`${URLS.main}/contacts`, {
            ...copiedForSubmission,
            AccountId: accountId,
            FirstName: contactToBeAdded.firstName || '',
            LastName: contactToBeAdded.lastName || '',
            Email: contactToBeAdded.email || '',
        })
            .then(res => {
                if (res && res.status == 200) {
                    setSkipNumberOfContactsCheck(true)
                    loadContacts(true)
                }
                setContactToBeAdded(null)
                setAddContactRequested(false)
                setContactCreateOrUpdateError(null)
            })
            .catch(({ response }) => {
                setAddContactRequested(false)
                setContactCreateOrUpdateError(response.data)
            })
    }

    const updateContact = () => {
        const copiedForSubmission = { ...contactToBeEditted }
        addedFieldsToBeRemoved.forEach(el => delete copiedForSubmission[el])

        setEditContactRequested(true)

        const internalProperties = ['columnIndex', 'rowIndex', 'lastUpdate', 'color', 'divisionName', 'divisionColor', 'linkedInUrl', 'linkedInProfileImageUrl', 'newColumnIndex', 'newRowIndex']

        const data = {
            ...copiedForSubmission,
            AccountId: accountId,
            Id: contactToBeEditted.id,
            FirstName: contactToBeEditted.firstName,
            LastName: contactToBeEditted.lastName,
            Email: contactToBeEditted.email,
            Title: contactToBeEditted.title,
        }
        internalProperties.forEach(prop => delete data[prop])


        if (workspaceTypeCheck(['CALLYPSO'])) {
            data.AccountId = accountId
            data.title = data.Title
        }

        makeRequest.put(`${URLS.main}/contacts`, data)
            .then(res => {
                if (res && res.status == 200) {
                    setAccountMapContacts(accountMapContacts.map(el => ({
                        ...el,
                        ...(el.id == contactToBeEditted.id ? contactToBeEditted : {})
                    })))
                }
                setContactToBeEditted(null)
                setEditContactRequested(false)
                setContactCreateOrUpdateError(null)
            })
            .catch(({ response }) => {
                setContactCreateOrUpdateError(response.data)
                setEditContactRequested(false)
            })
    }

    const serializeMapData = debounce((contacts = accountMapContacts) => {
        const { contacts: gridContacts, lines: gridLines } = gridRef.current.serialize()
        const remappedContacts = contacts.map(contact => {
            const { column, row } = (gridContacts.find(el => el.id == contact.id) || { column: 0, row: 0 })
            return {
                shown: contact.shown,
                positive: contact.positive,
                reachedOutTo: contact.reachedOutTo,
                id: contact.id,
                row: row,
                column: column,
                accountId: contact.accountId || accountId,
                color: contact.color
            }
        })

        makeRequest.post(`${URLS.main}/accountMaps`, {
            lines: gridLines,
            contacts: remappedContacts
        })
    }, 250)

    const autoMap = () => {
        gridRef.current?.autoMap()
        setTimeout(() => {
            serializeMapData()
            setShowAutoMapWarning(false)
        }, 100)
    }

    const contactModalData = contactToBeAdded || contactToBeEditted
    const contactModalSetStateFunction = contactToBeAdded ? setContactToBeAdded : setContactToBeEditted

    if (showLoader) {
        return <Loader size={LOADER_SIZES.EXTRA_BIG} type={LOADER_TYPES.SPINNER} className={cn.loader} />
    }

    return (
        <Row fullHeight noMargin className={cn.mappingWrapper}>
            <div className={cn.exportableContent} ref={printRef}>
                <Row spaceBetween className={cn.onTop}>
                    <Row noMargin>
                        <Heading>{accountDetails?.name}</Heading>
                        <Nbsp size={NBSP_SIZES.BIG} />
                        <Row
                            noMargin
                            id='mapButtonsNav'
                        >
                            <Button
                                data-tooltip-id='default'
                                data-tooltip-content={'Zoom to fit'}
                                onClick={gridRef.current?.zoomToFit}
                                icon={faMagnifyingGlassLocation}
                                type={BUTTON_TYPES.SMALL_ICON}
                            />
                            <Nbsp />
                            <Button
                                data-tooltip-id='default'
                                data-tooltip-content={'Reset zoom'}
                                onClick={gridRef.current?.resetZoom}
                                icon={faMagnifyingGlass}
                                type={BUTTON_TYPES.SMALL_ICON}
                            />
                            <Nbsp />
                            <Button
                                data-tooltip-id='default'
                                data-tooltip-content={'Align Map'}
                                onClick={gridRef.current?.alignLeft}
                                icon={faAlignLeft}
                                type={BUTTON_TYPES.SMALL_ICON}
                            />
                            <Nbsp />
                            <Button
                                data-tooltip-id='default'
                                data-tooltip-content={'Auto Generate Map'}
                                onClick={() => setShowAutoMapWarning(true)}
                                icon={faMagicWandSparkles}
                                type={BUTTON_TYPES.SMALL_ICON}
                            />
                        </Row>
                    </Row>
                </Row>
                <Grid
                    ref={gridRef}
                    sfUrl={user.sfUrl}
                    accountId={accountId}
                    skipNumberOfContactsCheck={skipNumberOfContactsCheck}
                    contactsSavedMapData={contactsSavedMapData}
                    notifyChange={serializeMapData}
                    contactRoles={contactRoles}
                    contacts={accountMapContacts}
                    onEdit={onEdit}
                    onUpdatePositive={onUpdatePositive}
                    onRemoveFromMap={onRemoveFromMap}
                    onRemoveFromSFDC={onRemoveFromSFDC}
                    onUpdatedReachedOutTo={onUpdatedReachedOutTo}
                    onContactMarkedAs={onContactMarkedAs}
                    onChangeColorForContact={onChangeColorForContact}
                    onOpenNotes={onOpenNotes}
                    isChildAccount={!!accountDetails?.parentAccounts && !!accountDetails?.parentAccounts[0]}
                />
            </div>
            <div className={cn.exportButtonWrapper}>
                <Button
                    onClick={downloadImage}
                    disabled={generatingImage}
                >
                    {!generatingImage ? 'Export as .png' : 'Processing'}
                </Button>
            </div>
            {!!accountMapContacts && (
                <Sidebar
                    parentAccount={accountDetails?.parentAccounts && accountDetails?.parentAccounts[0]}
                    accountId={accountId}
                    accountName={accountDetails?.name}
                    contacts={accountMapContacts}
                    onChange={userCheckedFromSidebar}
                    onAddNewContactClicked={() => setContactToBeAdded({})}
                />
            )}
            {contactModalData && (
                <CreateContactModal
                    error={contactCreateOrUpdateError}
                    edit={!contactToBeAdded}
                    contactModalData={contactModalData}
                    customContactFields={customContactFields}
                    onChange={data => {
                        contactModalSetStateFunction({
                            ...contactModalData,
                            ...data,
                        })
                    }}
                    disableButtons={addContactRequested || editContactRequested}
                    onClose={() => {
                        contactModalSetStateFunction(false)
                        setContactCreateOrUpdateError(null)
                    }}
                    onSave={contactToBeAdded ? addContact : updateContact}
                />
            )}
            {showAutoMapWarning && (
                <Modal
                    onClose={() => setShowAutoMapWarning(false)}
                    header={`Automatically generate the Map for ${accountDetails.name}`}
                    buttons={(
                        <Row spaceBetween fullWidth>
                            <Button
                                onClick={autoMap}
                            >
                                Yes
                            </Button>
                            <Button
                                onClick={() => setShowAutoMapWarning(false)}
                            >
                                Cancel
                            </Button>
                        </Row>
                    )}
                >
                    Choosing this option would automatically position all contacts from this account on the map and connected the people from their respective departments.
                    <br />
                    The automatic map generation is based on common company structures that Callypso has observed and information comming from your CRM, as well as Linked In.
                    <br />
                    However, because each company has an individual structure dependant on any number of factors, please consider this simply as a starting point.
                </Modal>
            )}
            {removeShowConfirmOfContact && (
                <Modal
                    onClose={() => setRemoveShowConfirmOfContact(false)}
                    header="Delete Contact"
                    buttons={(
                        <Row spaceBetween fullWidth>
                            <Button
                                onClick={deleteContact}
                            >
                                Yes, Delete it
                            </Button>
                            <Button
                                onClick={() => setRemoveShowConfirmOfContact(false)}
                            >
                                No, Cancel
                            </Button>
                        </Row>
                    )}
                >
                    Are you sure you'd want to delete this Contact from your Sales Force instance?
                    <br /><br />
                    This action might be irrevirsible! Please consider it carefully.
                </Modal>
            )}
            {notesContactId && (
                <Modal
                    minHeight
                    minWidth
                    onClose={() => setNotesContactId(false)}
                    header={`Notes on ${accountMapContacts.find(el => el.id == notesContactId)?.FirstName} ${accountMapContacts.find(el => el.id == notesContactId)?.LastName}`}
                >
                    <Notes
                        id={notesContactId}
                        accountName={accountDetails?.name}
                        type={NOTES_TYPES.CONTACT}
                        contacts={accountMapContacts}
                    />
                </Modal>
            )}
        </Row>
    );
}

export const mapStateToProps = (state) => {
    return {
        user: state.user,
    }
}

export const AccountMappingConnected = connect(mapStateToProps)(AccountMapping)