import React from 'react'
export const ArrowDiagonalUp = ({ fill='currentColor' }) => (
    <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0_96_713)">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M6.42841 4.28024C6.19233 4.28024 5.96593 4.18646 5.799 4.01953C5.63207 3.8526 5.53829 3.6262 5.53829 3.39012C5.53829 3.15405 5.63207 2.92764 5.799 2.76071C5.96593 2.59378 6.19233 2.5 6.42841 2.5H17.1099C17.346 2.5 17.5724 2.59378 17.7393 2.76071C17.9062 2.92764 18 3.15405 18 3.39012V14.0716C18 14.3077 17.9062 14.5341 17.7393 14.701C17.5724 14.8679 17.346 14.9617 17.1099 14.9617C16.8738 14.9617 16.6474 14.8679 16.4805 14.701C16.3135 14.5341 16.2198 14.3077 16.2198 14.0716V5.53828L3.49694 18.2611C3.3282 18.4183 3.10502 18.5039 2.87442 18.4999C2.64382 18.4958 2.4238 18.4024 2.26071 18.2393C2.09762 18.0762 2.00421 17.8562 2.00014 17.6256C1.99607 17.395 2.08167 17.1718 2.2389 17.0031L14.9617 4.28024H6.42841Z" fill={fill} />
        </g>
        <defs>
            <clipPath id="clip0_96_713">
                <rect width="16" height="16" fill={fill} transform="translate(2 2.5)"/>
            </clipPath>
        </defs>
    </svg>
)
