import React from 'react';
import cn from './Row.module.css'

export const ROW_SPACINGS = {
    SMALL: cn.small,
    NORMAL: cn.normal,
    LARGE: cn.large,
    LARGE_2: cn.large_2,
    LARGE_3: cn.large_3,
}

export const ROW_END_SPACINGS = {
    SMALL: cn.endSmall,
    NORMAL: cn.endNormal,
    LARGE: cn.endLarge,
    LARGE_2: cn.endLarge_2,
    LARGE_3: cn.endLarge_3,
}

export const Row = ({
    children,
    columnFlex,
    spaceBetween,
    fullWidth,
    fullHeight,
    className,
    noAligning,
    noMargin,
    wrap,
    rowSpacing,
    endAlign,
    id,
    onClick,
    title,
    onMouseDown,
    onMouseUp,
    flexGrow,
    dataTip,
    ...rest
}) => (
    <div
        {...rest}
        id={id}
        onClick={onClick}
        data-tooltip-id='default'
        data-tooltip-content={dataTip}
        onMouseDown={onMouseDown}
        onMouseUp={onMouseUp}
        title={title}
        className={`
            ${cn.row}
            ${wrap ? cn.wrap : cn.noWrap}
            ${fullWidth ? cn.fullWidth : ''}
            ${fullHeight ? cn.fullHeight : ''}
            ${spaceBetween ? cn.spaceBetween : ''}
            ${className}
            ${noAligning ? cn.noAligning : ''}
            ${rowSpacing}
            ${endAlign ? cn.endAlign : null}
            ${noMargin ? cn.noMargin : ''}
            ${columnFlex ? cn.columnFlex : ''}
            ${flexGrow ? cn.flexGrow : ''}
        `}
    >
        {children}
    </div>
)