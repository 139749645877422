import React from 'react';
import cn from './Dropdown.module.css'

export const Dropdown = ({ onChange, value, values, fullWidth, className, disabled, smallerWidth, testId, ...rest }) => {
    return (
        <select
            disabled={disabled}
            className={`${cn.dropdown} ${fullWidth ? cn.fullWidth : ''} ${className} ${smallerWidth ? cn.smallerWidth : null}`}
            onChange={onChange}
            value={value}
            data-test-id={testId || `DROPDOWN`}
            {...rest}
        >
            {values.map(el => (
                <option
                    key={el.label+(el.value || '')}
                    value={el.value}
                    disabled={el.onlyLabel}
                    hidden={el.hidden || false}
                    className={`${el.onlyLabel ? cn.labelOption : ''}`}
                >
                    {el.label}
                </option>
            ))}
        </select>
    )
}
