/* eslint-disable react/no-danger */
import React, { useEffect, useRef, useState } from "react";
import { slateToHtml, htmlToSlate } from '@slate-serializers/html'
import moment from "moment";
import { faFileCircleQuestion, faFilePdf, faInfoCircle, faLink, faPen, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import cn from './ProductTickets.module.css'
import { makeRequest } from "../../../../utilities/endpoints";
import { URLS } from "../../../../config";
import { Table } from "../../../../components/Table";
import { Row } from "../../../../components/Row";
import { BUTTON_TYPES, Button } from "../../../../components/Button";
import { MODAL_SIZES, Modal } from "../../../../components/Modal";
import { LOADER_SIZES, LOADER_TYPES, Loader } from "../../../../components/Loader";
import { Nbsp } from "../../../../components/Nbsp";
import { SlateEditor } from '../../../../components/SlateEditor'
import { Hr } from "../../../../components/Hr";
import { Label } from "../../../../components/Label";
import { Input } from "../../../../components/Input";
import { Checkbox } from "../../../../components/Checkbox";
import { SubHeader } from "../../../../components/SubHeader";
import { Pagination } from "../../../../components/Pagination";
import { isFreshdeskConnected } from "../../../../utilities/isFreshdeskConnected";
import { Error } from "../../../../components/Error";
import { SelectWrapperConnected } from "../../../../components/SelectWrapper";

const defaultFilterValue = -1

const ZendeskUser = ({ photo, id, baseUrl, name, email, shared_phone_number }) => (
    <Row noMargin className={cn.user}>
        {photo && (
            <>
                <img src={photo.content_url || photo.avatar_url} alt='profile img' />
                <Nbsp />
            </>
        )}
        <Row noMargin className={cn.author} spaceBetween>
            <Row noMargin>
                <div>
                    <a href={`${baseUrl}/agent/users/${id}`} target='_blank' rel="noreferrer">
                        {name}
                    </a>
                </div>
                <Nbsp />
                <div>
                    <a
                        href={`mailto: ${email}`}
                        target='_blank'
                        rel="noreferrer"
                    >
                        {email}
                    </a>
                </div>
                <Nbsp />
            </Row>
            {shared_phone_number && (
                <>
                    <div>
                        {shared_phone_number}
                    </div>
                    <Nbsp />
                </>
            )}
        </Row>
    </Row>
)

export const ProductTickets = ({ contacts, accountId }) => {
    const [tickets, setTickets] = useState([])
    const [ticketingSystem, setTicketingSystem] = useState()
    const [labels, setLabels] = useState()
    const [viewTicketDetails, setViewTicketDetails] = useState(null)
    const [ticketDetails, setTicketDetails] = useState(null)
    const [initialTicketsLoaded, setInitialTicketsLoaded] = useState(false)
    const [noteToBeEditted, setNoteToBeEditted] = useState()
    const [noteChangeContent, setNoteChangeContent] = useState()
    const [noteToBeAdded, setNoteToBeAdded] = useState()
    const [isOwnFreshdesk, setIsOwnFreshdesk] = useState(false)
    const [freshdeskConnected, setFreshdeskConnected] = useState(false)
    const [ticketErrorMessage, setTicketErrorMessage] = useState(null)
    const [page, setPage] = useState(null)
    const [totalPages, setTotalPages] = useState(null)

    const [statusOptionsToFilterOn, setStatusOptionsToFilterOn] = useState([])
    const [priorityOptionsToFilterOn, setPriorityOptionsToFilterOn] = useState([])
    const [typeOptionsToFilterOn, setTypeOptionsToFilterOn] = useState([])
    const [statusToFilterOn, setStatusToFilterOn] = useState({ label: 'Status', value: defaultFilterValue })
    const [priorityToFilterOn, setPriorityToFilterOn] = useState({ label: 'Priority', value: defaultFilterValue })
    const [typeToFilterOn, setTypeToFilterOn] = useState({ label: 'Type', value: defaultFilterValue })

    const bottomElementRef = useRef(null)

    const ticket = tickets.find(el => el.id === viewTicketDetails)

    useEffect(() => {
        isFreshdeskConnected().then(data => {
            setFreshdeskConnected(data.isConnected)
            setIsOwnFreshdesk(data.isOwn)
        })
        getStatusesToFilterOn()
        getPrioritiesToFilterOn()
        getTypesToFilterOn()
    }, [])

    useEffect(() => {
        if (noteToBeAdded) {
            bottomElementRef?.current?.scrollIntoView({ behavior: "smooth" })
        }
    }, [noteToBeAdded])

    const getStatusesToFilterOn = () => {
        makeRequest.get(`${URLS.main}/ticket-statuses`)
            .then(res => {
                setStatusOptionsToFilterOn(res.data.statuses)
            })
    }

    const getTypesToFilterOn = () => {
        makeRequest.get(`${URLS.main}/ticket-types`)
            .then(res => {
                setTypeOptionsToFilterOn(res.data.types)
            })
    }

    const getPrioritiesToFilterOn = () => {
        makeRequest.get(`${URLS.main}/ticket-priorities`)
            .then(res => {
                setPriorityOptionsToFilterOn(res.data.priorities)
            })
    }

    const getTickets = ({
        _page = page,
        priority = priorityToFilterOn,
        type = typeToFilterOn,
        status = statusToFilterOn,
    } = {}) => {
        setInitialTicketsLoaded(false)

        const headers = {
            emails: JSON.stringify(contacts.filter(el => !!el.Email).map(el => el.Email)),
            accountid: accountId,
        }
        if (_page !== undefined && _page !== null) {
            headers.page = _page + 1
        }
        if (priority?.value !== defaultFilterValue && priority !== defaultFilterValue) {
            headers.priority = priority?.value || priority
        }
        if (type?.value !== defaultFilterValue && type !== defaultFilterValue) {
            headers.type = type?.value || type
        }
        if (status?.value !== defaultFilterValue && status !== defaultFilterValue) {
            headers.status = status?.value || status
        }
        makeRequest.get(`${URLS.main}/ticket-account`, {
            headers
        })
            .then(res => {
                setTickets(res.data?.tickets)
                setLabels(res.data?.labels)
                setTicketingSystem(res.data?.ticketingSystem)
                setInitialTicketsLoaded(true)
                setTotalPages(res.data.pages)
                if (!!res.data.pages && res.data.pages > 1 && page === null) {
                    setPage(0)
                }
            })
    }

    useEffect(() => {
        if ((contacts && !!contacts.length) || freshdeskConnected) {
            getTickets()
        }
    }, [contacts, freshdeskConnected])

    useEffect(() => {
        if (viewTicketDetails) {
            getTicketDetails()
            setTicketErrorMessage(null)
        }
    }, [viewTicketDetails])

    const changeTicketPage = page => {
        setPage(page)
        getTickets({ page })
    }

    const getTicketDetails = () => {
        makeRequest.get(`${URLS.main}/ticket/${viewTicketDetails}`)
            .then(res => {
                setTicketDetails(res.data)
            })
    }

    const deleteNote = (id) => {
        setTicketDetails(null)
        makeRequest.delete(`${URLS.main}/freshdesk/note/${id}`)
            .then(getTicketDetails)
    }

    const addNote = data => {
        makeRequest.post(
            `${URLS.main}/freshdesk/note/${viewTicketDetails}`,
            {
                ...data,
                body: `<div>${slateToHtml(JSON.parse(data.body))}</div>`.replaceAll(`\n`, '<br />'),
                notify_emails: data.notify_emails?.split(',').map(el => el.trim())
            }
        )
            .then(res => {
                setTicketDetails(null)
                getTicketDetails()
                setTicketErrorMessage(res?.data?.errorMessage)
            })
    }

    const updateNote = id => {
        makeRequest.put(`${URLS.main}/freshdesk/note/${id}`, {
            body: `<div>${slateToHtml(JSON.parse(noteChangeContent))}</div>`.replaceAll(`\n`, '<br />')
        })
            .then(() => {
                setTicketDetails(null)
                getTicketDetails()
            })
    }

    if (!initialTicketsLoaded) {
        return <Loader type={LOADER_TYPES.SPINNER} size={LOADER_SIZES.BIG} />
    }
    return (
        <div>
            <Row>
                <Label>
                    Filters
                </Label>
                <Nbsp/>

                <SelectWrapperConnected
                    smaller
                    options={[
                        { label: 'Status', value: defaultFilterValue },
                        ...statusOptionsToFilterOn
                    ]}
                    onChange={data => {
                        getTickets({
                            status: data?.value,
                        })
                        setStatusToFilterOn(data)
                    }}
                    value={statusToFilterOn}
                />
                {!!typeOptionsToFilterOn?.length && (
                    <>
                        <Nbsp/>
                        <SelectWrapperConnected
                            smaller
                            options={[
                                { label: 'Type', value: defaultFilterValue },
                                ...typeOptionsToFilterOn
                            ]}
                            onChange={data => {
                                getTickets({
                                    type: data?.value,
                                })
                                setTypeToFilterOn(data)
                            }}
                            value={typeToFilterOn}
                        />
                    </>
                )}
                <Nbsp/>
                <SelectWrapperConnected
                    smaller
                    options={[
                        { label: 'Priority', value: defaultFilterValue },
                        ...priorityOptionsToFilterOn
                    ]}
                    onChange={data => {
                        getTickets({
                            priority: data?.value,
                        })
                        setPriorityToFilterOn(data)
                    }}
                    value={priorityToFilterOn}
                />
                <Nbsp/>
            </Row>
            <Table
                sticky={false}
                noPaddingHeader
                customStyles={{
                    0: {
                        minWidth: 92,
                        width: 92,
                        maxWidth: 92,
                    },
                    1: {
                        minWidth: 88,
                        width: 88,
                        maxWidth: 88,
                    },
                    3: {
                        minWidth: 180,
                    },
                    4: {
                        minWidth: 150,
                        width: 150,
                        maxWidth: 150,
                    },
                    5: {
                        minWidth: 82,
                        width: 82,
                        maxWidth: 82,
                    }
                }}
                headers={[
                    'Status',
                    'Priority',
                    'Subject',
                    'Description',
                    'Created At',
                    'Actions',
                ]}
                data={tickets.map(el => ([
                    el.status,
                    el.priority,
                    <div data-tooltip-id='default' data-tooltip-content={el.subject} className={cn.wrappedTextContent}>{el.subject}</div>,
                    <div data-tooltip-id='default' data-tooltip-content={el.description} className={cn.wrappedTextContent}>{el.description}</div>,
                    <div className={cn.wrappedTextContent}>{moment(el.created_at).format('MM.DD.YYYY HH:mm')}</div>,
                    <Row noMargin>
                        <Button
                            type={BUTTON_TYPES.SMALL_ICON}
                            onClick={() => setViewTicketDetails(el.id)}
                            icon={faInfoCircle}
                        />
                        <Nbsp />
                        <Button
                            type={BUTTON_TYPES.SMALL_ICON}
                            onClick={() => {
                                const baseUrl = el.url.split('/api') && el.url.split('/api')[0]
                                window.open(ticketingSystem === 'FRESHDESK' ? `${el.url}/a/tickets/${el.id}` : `${baseUrl}/agent/tickets/${el.id}`, '_blank')
                            }}
                            icon={faLink}
                        />
                    </Row>
                ]))}
            />

            {viewTicketDetails && (
                <Modal
                    className={cn.detailsWrapper}
                    size={MODAL_SIZES.BIG}
                    onClose={() => {
                        setViewTicketDetails(null)
                        setTicketDetails(null)
                    }}
                    header={`Details about ${ticket.subject}`}
                >
                    {!!ticketErrorMessage ? (
                        <Error>{ticketErrorMessage}</Error>
                    ) : (
                        null
                    )}
                    {ticketDetails ? (
                        <>
                            {ticketingSystem === 'FRESHDESK' ? (
                                <Row columnFlex fullWidth>
                                    <Row fullWidth>
                                        <ZendeskUser
                                            id={ticket.requester_id}
                                            photo={ticketDetails?.contacts.find(contact => contact.id === ticket.requester_id)?.avatar}
                                            name={ticketDetails?.contacts.find(contact => contact.id === ticket.requester_id)?.name}
                                            email={ticketDetails?.contacts.find(contact => contact.id === ticket.requester_id)?.email}
                                            baseUrl={ticket?.url?.split('/api')[0]}
                                        />
                                    </Row>
                                    <div dangerouslySetInnerHTML={{ __html: ticket.description_html }} />
                                </Row>
                            ) : null}
                            <div className={cn.subHeaders}>Details</div>
                            {!!ticket.assignee && (
                                <Row fullWidth className={cn.ticketDetails}>
                                    <div className={cn.detaislLabel}>
                                        Assignee:
                                    </div>
                                    <Nbsp />
                                    <ZendeskUser
                                        id={ticket.assignee.id}
                                        photo={ticket.assignee.photo}
                                        name={ticket.assignee?.name}
                                        email={ticket.assignee?.email}
                                        baseUrl={ticket.url.split('/api')[0]}
                                    />
                                </Row>
                            )}

                            <Row className={cn.ticketDetails}>
                                <Row noMargin>
                                    <div className={cn.detaislLabel}>Updated at:</div>
                                    <div className={cn.detailsData}>{moment(ticket.updated_at).format('MMM-DD-YYYY hh:mm A')}</div>
                                </Row>
                                {!!ticket.tags?.length ? (
                                    <>
                                        <Nbsp />
                                        <Row noMargin>
                                            <div className={cn.detaislLabel}>Tags:</div>
                                            <div className={cn.detailsData}>{ticket.tags.join(', ')}</div>
                                        </Row>
                                    </>
                                ) : null}

                                {!!ticket.created_at ? (
                                    <>
                                        <Nbsp />
                                        <Row noMargin>
                                            <div className={cn.detaislLabel}>Created:</div>
                                            <div className={cn.detailsData}>{moment(ticket.created_at).format('MMM-DD-YYYY hh:mm A')}</div>
                                        </Row>
                                    </>
                                ) : null}

                                {!!ticket.due_at ? (
                                    <>
                                        <Nbsp />
                                        <Row noMargin>
                                            <div className={cn.detaislLabel}>Due date:</div>
                                            <div className={cn.detailsData}>{moment(ticket.due_at).format('MMM-DD-YYYY hh:mm A')}</div>
                                        </Row>
                                    </>
                                ) : null}
                            </Row>

                            {ticket.followers && !!ticket.followers.length ? (
                                <Row noMargin className={cn.followers}>
                                    <div className={cn.detaislLabel}>Followers:</div>
                                    <div className={cn.detailsData}>{
                                        <Row noMargin wrap>
                                            {ticket.followers.map((el, i) => (
                                                <>
                                                    <ZendeskUser {...el} />
                                                    {i < ticket.followers.length - 1 && (
                                                        <div className={cn.commaSeparator}>,</div>
                                                    )}
                                                </>
                                            ))}
                                        </Row>
                                    }</div>
                                </Row>
                            ) : null}
                            <div className={cn.smallOffset} />
                            <Hr />
                            <br />
                            {!!ticket.custom_fields.filter(el => el.value !== null).length && (
                                <>
                                    <div className={cn.subHeaders}>Custom Fields:</div>
                                    {ticket.custom_fields.filter(el => el.value !== null).map(({ id, value }) => (
                                        <Row className={cn.ticketDetails}>
                                            <div className={cn.detaislLabel}>{labels?.customFields?.find(el => el.value === id)?.label}:</div>
                                            <div className={cn.detailsData}>{value}</div>
                                        </Row>
                                    ))}
                                    <div className={cn.smallOffset} />
                                    <Hr />
                                    <br />
                                </>
                            )}
                            <div className={cn.subHeaders}>{ticketingSystem === 'FRESHDESK' ? 'Conversation' : 'Comments'}:</div>
                            {ticketDetails.comments.map((comment, i) => (
                                <div key={comment.id} className={`${cn.comment} ${i === ticketDetails.comments.length - 1 ? cn.last : ''}`}>
                                    <Row fullWidth>
                                        <div className={cn.date}>
                                            {moment(comment.created_at).format('MMM-DD-YYYY hh:mm A')}
                                        </div>
                                        <Nbsp />
                                        <Row noMargin className={cn.author} spaceBetween>
                                            <Row noMargin>
                                                <ZendeskUser
                                                    id={comment?.id}
                                                    photo={comment?.author_id?.photo}
                                                    name={comment?.author_id?.name}
                                                    email={comment?.author_id?.email}
                                                    baseUrl={ticket?.url?.split('/api')[0]}
                                                    shared_phone_number={comment?.author_id?.shared_phone_number}
                                                />
                                                {comment.type && (
                                                    <>
                                                        <Nbsp />
                                                        <div className={cn.type}>
                                                            {comment.private ? 'Private   ' : ''}
                                                            {comment.type}
                                                        </div>
                                                    </>
                                                )}
                                            </Row>
                                            <Row noMargin>
                                                {!!comment.via && <div className={cn.via}>
                                                    Via: {comment.via}
                                                </div>}
                                                <Row noMargin>
                                                    {comment.isOwn && comment.type === 'Note' && !noteToBeEditted ? (
                                                        <>
                                                            <Button
                                                                type={BUTTON_TYPES.SMALL_ICON}
                                                                icon={faPen}
                                                                className={cn.commentButton}
                                                                onClick={() => {
                                                                    setNoteToBeEditted(comment.id)
                                                                    setNoteToBeAdded(null)
                                                                    setTicketErrorMessage(null)
                                                                }}
                                                            />
                                                            <Button
                                                                type={BUTTON_TYPES.SMALL_ICON}
                                                                icon={faTrash}
                                                                className={cn.commentButton}
                                                                onClick={() => deleteNote(comment.id)}
                                                            />
                                                        </>
                                                    ) : null}
                                                    <Button
                                                        className={cn.commentButton}
                                                        type={BUTTON_TYPES.SMALL_ICON}
                                                        icon={faLink}
                                                        onClick={() => {
                                                            window.open(ticketingSystem === 'FRESHDESK'
                                                                ? `${ticket.url}/a/tickets/${ticket.id}`
                                                                : `${ticket.url.split('/api')[0]}/agent/tickets/${ticket.id}`, '_blank')
                                                        }}
                                                    />
                                                </Row>
                                            </Row>
                                        </Row>
                                    </Row>
                                    {ticketingSystem === 'FRESHDESK' ? (
                                        <div className={cn.body} dangerouslySetInnerHTML={{ __html: comment.body_html }} />
                                    ) : (
                                        <div className={cn.body}>
                                            {comment.body}
                                        </div>
                                    )}
                                    {!!comment.attachments.length && (
                                        <>
                                            <br />
                                            <div className={cn.detaislLabel}>Attachments:</div>
                                            <Row wrap>
                                                {comment.attachments.map(attachment => {
                                                    if (attachment.content_type.includes('image')) {
                                                        return (
                                                            <div
                                                                key={attachment.id}
                                                                className={cn.filePreview}
                                                                onClick={() => window.open(attachment.content_url)}
                                                                data-tooltip-id='default'
                                                                data-tooltip-content='Click to open full size'
                                                            >
                                                                <img src={attachment.content_url} alt={attachment.file_name} />
                                                                <span>{attachment.file_name}</span>
                                                            </div>
                                                        )
                                                    }
                                                    if (attachment.content_type.includes('audio')) {
                                                        return (
                                                            <div
                                                                key={attachment.id}
                                                                className={`${cn.filePreview} ${cn.bigger}`}
                                                                onClick={() => window.open(attachment.content_url)}
                                                                data-tooltip-id='default'
                                                                data-tooltip-content='Click to download'
                                                            >
                                                                {/* eslint-disable-next-line jsx-a11y/media-has-caption */}
                                                                <audio controls preload="auto" src={attachment.content_url}>
                                                                    Your browser does not support the audio tag.
                                                                </audio>
                                                                <span>{attachment.file_name}</span>
                                                            </div>
                                                        )
                                                    }
                                                    if (attachment.content_type.includes('pdf')) {
                                                        return (
                                                            <div
                                                                key={attachment.id}
                                                                className={cn.filePreview}
                                                                onClick={() => window.open(attachment.content_url)}
                                                                data-tooltip-id='default'
                                                                data-tooltip-content='Click to open in a new tab'
                                                            >
                                                                <FontAwesomeIcon className={cn.mainColor} icon={faFilePdf} size="3x" />
                                                                <span>{attachment.file_name}</span>
                                                            </div>
                                                        )
                                                    }
                                                    return (
                                                        <div
                                                            key={attachment.id}
                                                            className={cn.filePreview}
                                                            onClick={() => window.open(attachment.content_url)}
                                                            data-tooltip-id='default'
                                                            data-tooltip-content='Click to open in a new tab'
                                                        >
                                                            <FontAwesomeIcon className={cn.mainColor} icon={faFileCircleQuestion} size="3x" />
                                                            <span>{attachment.file_name}</span>
                                                        </div>
                                                    )
                                                })}
                                            </Row>
                                        </>
                                    )}
                                </div>
                            ))}

                            {noteToBeEditted ? (
                                <Row fullWidth columnFlex>
                                    <Label>
                                        Editing a note
                                    </Label>
                                    <Row noMargin fullWidth>
                                        <SlateEditor
                                            fullWidth
                                            smallFont
                                            key={noteToBeEditted}
                                            value={JSON.stringify(htmlToSlate(ticketDetails.comments.find(el => el.id === noteToBeEditted)?.body_html))}
                                            onChange={setNoteChangeContent}
                                        />
                                    </Row>
                                    <br />
                                    <Button
                                        onClick={() => {
                                            updateNote(noteToBeEditted)
                                            setNoteToBeEditted(null)
                                            setNoteToBeAdded(null)
                                        }}
                                        fullWidth
                                    >
                                        Update
                                    </Button>
                                    <br />
                                    <Button
                                        onClick={() => {
                                            setNoteToBeEditted(null)
                                            setNoteToBeAdded(null)
                                        }}
                                        fullWidth
                                    >
                                        Cancel
                                    </Button>
                                </Row>
                            ) : null}

                            {noteToBeAdded ? (
                                <Row fullWidth columnFlex>
                                    <SubHeader>
                                        Adding a note
                                    </SubHeader>
                                    <br />
                                    <Label fullWidth>
                                        Notify Emails
                                    </Label>
                                    <Input
                                        placeholder='Comma separated'
                                        fullWidth
                                        value={noteToBeAdded?.notify_email}
                                        onChange={e => {
                                            setNoteToBeAdded({
                                                ...noteToBeAdded,
                                                notify_emails: e.target.value
                                            })
                                        }}
                                    />
                                    <br />
                                    <Checkbox
                                        label={'Private'}
                                        checked={noteToBeAdded?.private}
                                        onClick={() => setNoteToBeAdded({
                                            ...noteToBeAdded,
                                            private: !noteToBeAdded?.private
                                        })}
                                    />
                                    <br />
                                    <Label fullWidth>
                                        Body
                                    </Label>
                                    <Row noMargin fullWidth>
                                        <SlateEditor
                                            fullWidth
                                            smallFont
                                            key={noteToBeEditted}
                                            value={''}
                                            onChange={e => {
                                                setNoteToBeAdded({
                                                    ...noteToBeAdded,
                                                    body: e
                                                })
                                            }}
                                        />
                                    </Row>
                                    <br />
                                    <Button
                                        onClick={() => {
                                            addNote(noteToBeAdded)
                                            setNoteToBeEditted(null)
                                            setNoteToBeAdded(null)
                                        }}
                                        fullWidth
                                    >
                                        Add
                                    </Button>
                                    <br />
                                    <Button
                                        onClick={() => {
                                            setNoteToBeEditted(null)
                                            setNoteToBeAdded(null)
                                        }}
                                        fullWidth
                                    >
                                        Cancel
                                    </Button>
                                </Row>
                            ) : !!isOwnFreshdesk ? (
                                <>
                                    <br />
                                    <Button
                                        fullWidth
                                        onClick={() => {
                                            setTicketErrorMessage(null)
                                            setNoteToBeAdded(true)
                                            setNoteToBeEditted(false)
                                        }}
                                    >
                                        Add new note
                                    </Button>
                                </>
                            ) : null }
                            <div ref={bottomElementRef} />
                        </>
                    ) : (
                        <Loader type={LOADER_TYPES.SPINNER} size={LOADER_SIZES.BIG} />
                    )}
                </Modal>
            )}
            {!!totalPages && (
                <>
                    <br/>
                    <Pagination pages={totalPages} onClick={changeTicketPage} index={page} />
                </>
            )}
        </div>
    )
}