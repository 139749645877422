import React, { useEffect } from 'react'
import cn from './TableExpand.module.css'


export const TableExpand = ({ headers, data=[], customStyles={}, headCustomStyles={}, sticky=true, extData=[], extHeaders=[], tab='', user={}, type='', accountInfo='' }) => {
    const openUrl = (e, str) => {
        window.open(`${user.sfUrl}/${str.split('/').at(-1)}`);
        e.stopPropagation();
    }


    const hideRow = (e) => {
        let el=e.target?e.target:null;
        if (el && el.tagName.toLocaleLowerCase() === 'td') {
            el = el.parentElement;
        }
        const sibEl = el.nextElementSibling;
        if (sibEl && sibEl.getAttribute("label") === "extendRow") {
            el.classList.toggle(cn.expanded);
            sibEl.classList.toggle(cn.dataTableExtendHide);
        }
    }

    const processSubTable = (data, headers, dataIndex) => {
        const valHeaders = [];
        data.forEach((row) => {
            headers.forEach(col => {
                if (row[col] !== null && !valHeaders.includes(col)) {
                    valHeaders.push(col);
                }
            });
        });
        return (
            <table key={`tableSub_${dataIndex}`} className={cn.dataTableExtendTable}>
                <thead>
                    <tr key={`${dataIndex}_sub`}>
                        {valHeaders.map((extHead, i) => (
                            <th key={i}>{extHead}</th>
                        ))}
                    </tr>
                </thead>
                <tbody>
                    {
                        data.map((row, i) => (
                            <tr key={`tableSubRow_${dataIndex}+${i}`}>
                                {type === accountInfo && valHeaders.map((col) => (<td key={`${dataIndex}+${i}+${col}`} className={`${cn.dataTableExtendTableTd} ${cn.pointer}`} onClick={(e) => openUrl(e, row.sfUrlPath)}>{row[col]}</td>))}
                                {type !== accountInfo && valHeaders.map((col) => (<td key={`${dataIndex}+${i}+${col}`} className={cn.dataTableExtendTableTd}>{row[col]}</td>))}
                            </tr>
                        ))
                    }
                </tbody>
            </table>
        )
    }

    useEffect(() => {
        const trs = document.getElementsByClassName(cn.dataTable)[0].getElementsByTagName('tr');
        if (trs) {
            for (const el of trs) {
                el.classList.remove(cn.expanded);
                if (el.hasAttribute('label') && el.getAttribute('label') === 'extendRow') {
                    el.classList.add(cn.dataTableExtendHide);
                }
            }
        }
    }, [data, tab]);

    return (
        <table className={`${cn.dataTable} ${sticky? cn.dataTableSticky : ''}`}>
            <thead>
                <tr>
                    {headers.map((el, i) => (
                        <th key={i} style={headCustomStyles[i]}>{el}</th>
                    ))}
                </tr>
            </thead>
            <tbody>
                {Object.keys(data).map((dataIndex) => (
                    <>
                        <tr key={dataIndex} onClick={hideRow}>
                            {headers.map((col, i) => (
                                <td key={`${dataIndex}+${col}`} style={customStyles[i]}>
                                    {data[dataIndex][col]}
                                </td>
                            ))}
                        </tr>
                        { extData[dataIndex] && (
                            <tr label="extendRow" className={`${cn.dataTableExtend} ${cn.dataTableExtendHide}`} >
                                <td colSpan={headers.length}>
                                    {type !== accountInfo && (
                                        <div className={`${cn.dataTableExtendInnerWrap} ${cn.pointer}`} onClick={(e) => openUrl(e, data[dataIndex].sfUrlPath)}>
                                            {processSubTable(extData[dataIndex], extHeaders, dataIndex)}
                                        </div>
                                    )}
                                    {type === accountInfo && (
                                        <div className={cn.dataTableExtendInnerWrap}>
                                            {processSubTable(extData[dataIndex], extHeaders, dataIndex)}
                                        </div>
                                    )}
                                </td>
                            </tr>
                        )}
                    </>
                ))}
            </tbody>
        </table>
    )
}
