import React, { useEffect, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import { ViewContainer } from '../../../../components/ViewContainer'
import { Button } from '../../../../components/Button'
import { Row } from '../../../../components/Row'
import { Label } from '../../../../components/Label'
import { Input } from '../../../../components/Input'
import { NBSP_SIZES, Nbsp } from '../../../../components/Nbsp'
import { makeRequest } from '../../../../utilities/endpoints'
import { URLS } from '../../../../config'
import { isZendeskConnected } from '../../../../utilities/isZendeskConnected';
import { SelectWrapperConnected } from '../../../../components/SelectWrapper';

export const Zendesk = () => {
    const [isConnected, setIsConnected] = useState(false)
    const [subdomain, setSubdomain] = useState()
    const [connectionStarted, setConnectionStart] = useState(false)

    const [statusesToFilterOn, setStatusesToFilterOn] = useState()
    const [selectedStatusesToFilterOn, setSelectedStatusesToFilterOn] = useState([])

    const [prioritiesToFilterOn, setPrioritiesToFilterOn] = useState()
    const [selectedPrioritiesToFilterOn, setSelectedPrioritiesToFilterOn] = useState([])

    const getAuthenticationSettings = () => {
        isZendeskConnected().then(data => {
            setIsConnected(data.isConnected)
            setSubdomain(data.subdomain)
        })
    }

    const getStatusesToFilterOn = () => {
        makeRequest.get(`${URLS.main}/ticket-statuses`)
            .then(res => {
                setStatusesToFilterOn(res.data.statuses)
            })
    }

    const getPrioritiesToFilterOn = () => {
        makeRequest.get(`${URLS.main}/ticket-priorities`)
            .then(res => {
                setPrioritiesToFilterOn(res.data.priorities)
            })
    }

    useEffect(() => {
        getAuthenticationSettings()
    }, [])

    useEffect(() => {
        if (!!statusesToFilterOn?.length) {
            makeRequest.get(`${URLS.main}/filter-ticket-statuses`)
                .then(res => {
                    if (!!res.data?.statuses?.length) {
                        setSelectedStatusesToFilterOn(statusesToFilterOn.filter(el => res.data.statuses.includes(`${el.value}`)))
                    }
                })
        }
    }, [statusesToFilterOn])

    useEffect(() => {
        if (!!prioritiesToFilterOn?.length) {
            makeRequest.get(`${URLS.main}/filter-ticket-priorities`)
                .then(res => {
                    if (!!res.data?.priorities?.length) {
                        setSelectedPrioritiesToFilterOn(prioritiesToFilterOn.filter(el => res.data.priorities.includes(`${el.value}`)))
                    }
                })
        }
    }, [prioritiesToFilterOn])

    useEffect(() => {
        if (isConnected) {
            getStatusesToFilterOn()
            getPrioritiesToFilterOn()
        }
    }, [isConnected])

    const connect = () => {
        setConnectionStart(true)
        window.location.href = `${URLS.main}/oauth2callback-zendesk/${subdomain}`
    }

    const saveSelectedStatusesToFilterOn = (data) => {
        setSelectedStatusesToFilterOn(data)
        makeRequest.post(`${URLS.main}/filter-ticket-statuses`, { statuses: data.map(el => el.value) })
    }

    const saveSelectedPrioritiesToFilterOn = data => {
        setSelectedPrioritiesToFilterOn(data)
        makeRequest.post(`${URLS.main}/filter-ticket-priorities`, { priorities: data.map(el => el.value) })
    }

    return (
        <ViewContainer
            collapsible
            title={(
                <Row noMargin>
                    <div>
                        Zendesk
                    </div>
                    {isConnected && (
                        <>
                            <Nbsp size={NBSP_SIZES.BIG} />
                            <FontAwesomeIcon icon={faCheckCircle} color='#3EB984' />
                        </>
                    )}
                </Row>
            )}
        >
            <Row fullWidth spaceBetween>
                <Row noMargin>
                    <Label>Subdomain</Label>
                    <Input onChange={e => setSubdomain(e.target.value)} value={subdomain} />
                </Row>
                <Button onClick={connect} disabled={connectionStarted}>
                    Connect
                </Button>
            </Row>
            <Row>
                <Label>Filter on specific statuses</Label>
                <SelectWrapperConnected
                    isMulti
                    onChange={saveSelectedStatusesToFilterOn}
                    options={statusesToFilterOn}
                    value={selectedStatusesToFilterOn}
                />
            </Row>
            <Row>
                <Label>Filter on specific priorities</Label>
                <SelectWrapperConnected
                    isMulti
                    onChange={saveSelectedPrioritiesToFilterOn}
                    options={prioritiesToFilterOn}
                    value={selectedPrioritiesToFilterOn}
                />
            </Row>
        </ViewContainer>
    )
}