import React from 'react'
import { useParams, useHistory, useLocation } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleDoubleLeft, faAngleDoubleRight, faAngleLeft, faAngleRight } from '@fortawesome/free-solid-svg-icons'
import { Button, BUTTON_TYPES } from './Button'
import cn from './Pagination.module.css'

export const Pagination = ({ pages, index, onClick }) => {
    if (!pages) return null
    const { page } = useParams();
    const history = useHistory()
    const location = useLocation()
    if (index === undefined) {
        index = parseInt(page)
    }
    const maxPages = 5
    const pagesOffset = Math.round(maxPages / 2)
    const tooManyPages = pages > maxPages
    const end = Math.min(index + pagesOffset, pages)
    const start = end !== pages ? Math.max(0, index - pagesOffset) : end - maxPages
    const availableIndexes = Array.apply(null, Array(maxPages)).map((el, i) => i + start)

    const goToPage = newIndex => {
        if (onClick) {
            onClick(newIndex)
        } else {
            history.push(location.pathname.replace(`/${index}`, `/${newIndex}`))
        }
    }
    return (
        <div className={cn.pagination}>
            <div className={cn.indexation}>
                PAGE {index + 1} of {pages}
            </div>
            <div className={cn.navigationCenter}>
                <div className={cn.navigation}>
                    {tooManyPages && (
                        <Button
                            className={cn.endButton}
                            type={BUTTON_TYPES.SEMI_TRANSPARENT}
                            onClick={() => goToPage(0)}
                        >
                            <FontAwesomeIcon icon={faAngleDoubleLeft} color='#f08178' />
                        </Button>
                    )}
                    <Button
                        className={cn.endButton}
                        type={BUTTON_TYPES.SEMI_TRANSPARENT}
                        onClick={() => goToPage(Math.max(index - 1, 0))}
                    >
                        <FontAwesomeIcon icon={faAngleLeft} color='#f08178' />
                    </Button>
                    {Array.apply(null, Array(pages)).map((el, i) => {
                        if (tooManyPages && !availableIndexes.includes(i)) {
                            return null
                        }
                        return (
                            <div
                                key={i}
                                onClick={() => goToPage(i)}
                                className={`${cn.pageIndex} ${i === parseInt(index) ? cn.active : ''}`}
                            >
                                {i + 1}
                            </div>
                        )
                    })}
                    <Button
                        className={`${cn.endButton} ${tooManyPages ? cn.extraBorderLeft : ''}`}
                        type={BUTTON_TYPES.SEMI_TRANSPARENT}
                        onClick={() => goToPage(Math.min(index + 1, pages - 1))}
                    >
                        <FontAwesomeIcon icon={faAngleRight} color='#f08178' />
                    </Button>
                    {tooManyPages && (
                        <Button
                            className={cn.endButton}
                            type={BUTTON_TYPES.SEMI_TRANSPARENT}
                            onClick={() => goToPage(pages - 1)}
                        >
                            <FontAwesomeIcon icon={faAngleDoubleRight} color='#f08178' />
                        </Button>
                    )}
                </div>
            </div>
        </div>
    )
}
