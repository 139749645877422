import React, { useEffect, useState } from 'react'
import cn from './SeatsUsedConfig.module.css'
import { makeRequest } from '../../../utilities/endpoints'
import { URLS } from '../../../config'
import { ViewContainer } from '../../../components/ViewContainer'
import { Row } from '../../../components/Row'
import { Label } from '../../../components/Label'
import { Dropdown } from '../../../components/Dropdown'
import { Nbsp } from '../../../components/Nbsp'
import { Button } from '../../../components/Button'
import { filterCustomFields } from '../../../utilities/filterCustomFields'
import { translateCRMLabel } from '../../../utilities/translateCRMLabels'

export const SeatsUsedConfig = ({ tables = [], type }) => {
    const [timestamp, setTimestamp] = useState()
    const [accountId, setAccountId] = useState()
    const [tableIndex, setTableIndex] = useState()
    const [seatsUsed, setSeatsUsed] = useState()
    const [accountSeatsBoughtField, setAccountSeatsBoughtField] = useState()
    const [accountCustomFields, setAccountCustomFields] = useState([])
    const [saved, setSaved] = useState(true)

    const getAccountFields = () => {
        makeRequest.get(`${URLS.main}/describe/Account`)
            .then(res => {
                setAccountCustomFields(filterCustomFields(res.data?.fields))
            })
    }

    const getSeatsBoughtField = () => {
        makeRequest.get(`${URLS.main}/settings/seatsBoughtField`)
            .then(res => {
                setAccountSeatsBoughtField(res.data)
            })
    }

    const saveSeatsBoughtFields = value => {
        makeRequest.post(`${URLS.main}/settings/seatsBoughtField`, { value })
    }

    useEffect(() => {
        if (tables && !!tables.length) {
            setTableIndex(0)
            getSettings()
            getAccountFields()
            getSeatsBoughtField()
        }
    }, [tables])

    const getSettings = () => {
        makeRequest.get(`${URLS.main}/integrations/seats-bought`)
            .then(res => {
                setTimestamp(res.data.settings.timestampColumn)
                setAccountId(res.data.settings.accountIdColumn)
                setSeatsUsed(res.data.settings.seatsUsedColumn)
                setTableIndex(tables.findIndex(el => el.name === res.data.settings.tableName))
            })
    }

    const save = () => {
        makeRequest.post(`${URLS.main}/integrations/seats-bought`, {
            type,
            timestamp,
            accountId,
            seatsUsed,
            table: tables[tableIndex]?.name
        })
        saveSeatsBoughtFields(accountSeatsBoughtField)
    }

    return (
        <ViewContainer collapsible title={'Seats Used'}>
            <Row>
                <div>
                    <Label>
                        Table
                    </Label>
                    <Nbsp />
                    <Dropdown
                        fullWidth
                        values={tables.map((el, i) => ({
                            label: el?.name,
                            value: i,
                        }))}
                        value={tableIndex}
                        onChange={e => {
                            setSaved(false)
                            setTableIndex(e.target.value)
                        }}
                    />
                </div>
                <Nbsp />
                <div>
                    <Label>
                        {`${translateCRMLabel('Account')} Column/Field`}
                    </Label>
                    <Nbsp />
                    <Dropdown
                        fullWidth
                        values={tableIndex !== undefined ? [{ label: '', value: undefined }, ...tables[tableIndex].fields.map(el => ({
                            label: el,
                            value: el,
                        }))] : [{ label: 'Please Pick a Table First', value: undefined }]}
                        value={accountId}
                        onChange={e => {
                            setSaved(false)
                            setAccountId(e.target.value)
                        }}
                    />
                </div>
                <Nbsp />
                <div>
                    <Label>
                        Seats Used Column/Field
                    </Label>
                    <Nbsp />
                    <Dropdown
                        fullWidth
                        values={tableIndex !== undefined ? [{ label: '', value: undefined }, ...tables[tableIndex].fields.map(el => ({
                            label: el,
                            value: el,
                        }))] : [{ label: 'Please Pick a Table First', value: undefined }]}
                        value={seatsUsed}
                        onChange={e => {
                            setSaved(false)
                            setSeatsUsed(e.target.value)
                        }}
                    />
                </div>
                <Nbsp />
                <div>
                    <Label>
                        Seats Bought Column/Field
                    </Label>
                    <Nbsp />
                    <Dropdown
                        fullWidth
                        values={accountCustomFields.length ? [{ label: '', value: undefined }, ...accountCustomFields] : [{ label: '', value: undefined }]}
                        value={accountSeatsBoughtField}
                        onChange={e => {
                            setSaved(false)
                            setAccountSeatsBoughtField(e.target.value)
                        }}
                    />
                </div>
                <Nbsp />
                <Button
                    disabled={saved}
                    className={cn.additionaFieldButton}
                    onClick={save}
                >
                    {saved ? 'Saved' : 'Save'}
                </Button>
            </Row>
        </ViewContainer>
    )
}
