import React from 'react'
import cn from './DateTime.module.css'

export const DateTime = ({ data, dataTip }) => (
    <div
        className={cn.dateTime}
        data-tooltip-id='default'
        data-tooltip-content={dataTip}
    >
        {data?.value?.link ? (
            <a href={data?.link} rel="noreferrer" target='_blank'>{data?.value || 'Not Scheduled'}</a>
        ) : (
            data?.value || 'Not Scheduled'
        )}
    </div>
)