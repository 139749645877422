import React from "react";
import {
    BrowserRouter as Router,
    Switch,
    Route,
    useLocation,
    useHistory,
} from "react-router-dom";
import { connect, Provider } from 'react-redux'
import createCache from '@emotion/cache';
import { CacheProvider } from '@emotion/react';
import { PersistGate } from 'redux-persist/integration/react'
import { Tooltip } from "react-tooltip";
import { TopNavBarConnected } from "./components/TopNavBar";
import { AuthConnected } from "./views/auth/Auth";
import { WorkspaceConnected } from "./views/workspace/Workspace";
import { SidebarConnected } from "./components/Sidebar";
import cn from './Routing.module.css'
import { TasksConnected } from "./views/tasks/Tasks";
import { AccountsConnected } from "./views/accounts/Accounts";
import { AccountConnected } from "./views/accounts/account/Account";
import { CreateRulesConnected } from "./views/rules/CreateRules";
import { PrivacyPolicy } from "./views/privacy-policy/PrivacyPolicy";
import { RulesAndTriggersConnected } from "./views/rules-and-triggers/RulesAndTriggers";
import { MetricsConnected } from "./views/metrics/Metrics";
import { AccountMappingConnected } from "./views/accounts/account-mapping/AccountMapping";
import { Board } from "./views/opportunities-management/OpportunitiesManagement";
import { RenewalsManagement } from "./views/renewals-management/RenewalsManagement";
import { SearchResults } from "./views/search-results/SearchResults";
import { CallypsoAdmins } from "./views/callypso-admins/CallypsoAdmins";
import { WhitespacingConnected } from "./views/accounts/whitespace-planning/Whitespacing";
import { CallypsoCRM } from "./views/callypso-crm/CallypsoCRM";
import { GlobalWhitespacePlanning } from "./views/global-whitespace-planning/GlobalWhitespacePlanning";
import { RiskConnected } from "./views/risk/Risk";
import { Home2Connected } from "./views/home/Home2";
import { Playbook } from "./views/playbooks/playbook/Playbook"
import { Playbooks } from "./views/playbooks/Playbooks";
import { URLS } from "./config";
import { makeRequest } from "./utilities/endpoints";
import { isCRMEmbedded } from "./utilities/crmEmbed";
import { setPageAfter } from "./store/actions";
import { Playbook2 } from "./views/playbooks/playbook/Playbook2";
import { ChatWithAssistant } from "./views/chat-with-assistant/ChatWithAssistant";
import { Playbook3 } from "./views/playbooks/playbook/Playbook3";
import { GenerativePDF } from "./views/generative-pdf/GenerativePDF";
import { GenerativePDFFile } from "./views/generative-pdf/GenerativePDFFile";
import { GenerativePDFUse } from "./views/generative-pdf/GenerativePDFUse";
import { GenerativePDFQueue } from "./views/generative-pdf/GenerativePDFQueue";

const defaultLabels = {
    "/metrics": 'Metrics',
    "/settings": 'Settings',
    "/tasks": 'Plays',
    "/accounts": 'Active Accounts',
    "/account": 'Accounts/{{PLACEHOLDER}}',
    "/rule/create": 'Create Rule',
    "/rule/edit": 'Edit Rule',
    "/rules": 'Rules and Triggers',
    "/opportunities-management": 'Opportunities Management',
    "/renewals-management": 'Renewals Management',
    "/search-results": 'Search Results',
    '/global-white-space-planning': 'Expansion Trackers',
    '/risk': 'Risk',
    "/playbooks": 'Playbooks',
    'playbook-demo': 'Customer Journey',
    "/playbook": 'Playbook{{PLACEHOLDER}}',
    "/chat-with-your-data": 'Chat With Your Data',
    '/generative-pdf': 'Generative PDF',
    "/": 'Welcome back{{PLACEHOLDER}}'
}

const Wrapper = ({ dispatch, children, user, colorScheme, pageAfter: pageAfterFromStore }) => {
    const { pathname } = useLocation()
    const history = useHistory()

    document.getElementById('root').className = colorScheme === 'DARK' ? 'darkTheme' : 'lightTheme'

    if (pathname === '/privacy-policy') {
        return (
            <div>{children}</div>
        )
    }

    const urlParams = new URLSearchParams(window.location.search);
    const userId = urlParams.get('userId');
    const userEmail = urlParams.get('email');
    const pageAfter = urlParams.get('pageAfter')

    if (isCRMEmbedded()) {
        if (!!pageAfter) {
            if (!user) {
                dispatch(setPageAfter(pageAfter))
            } else if (pageAfterFromStore !== 'handled') {
                dispatch(setPageAfter('handled'))
                history.push(pageAfter)
                return null;
            }
        } else if (pageAfterFromStore === 'handled') {
            dispatch(setPageAfter(null))
        }
    }

    if (!user && !!userEmail && !!userId && isCRMEmbedded()) {
        // window.location.href = `${URLS.main}/salesforce-auto-sign-in/${encodeURIComponent(userEmail)}/${userId}`
        makeRequest.get(`${URLS.main}/salesforce-auto-sign-in/${encodeURIComponent(userEmail)}/${userId}`)
            .then(res => {
                if (res && res.data) {
                    history.push(`/auth/cookie/${res.data.cookie}`)
                }
            })
    } else {
        if (user) {
            const labelKey = Object.keys(defaultLabels).find(key => pathname.includes(key))
            const label = defaultLabels[labelKey]
            return (
                <div className={cn.wrapper}>
                    {pathname.includes('embedded') ? null : (
                        <SidebarConnected />
                    )}
                    <div className={cn.content}>
                        {pathname.includes('embedded') ? null : (
                            <TopNavBarConnected pathLabel={label} />
                        )}
                        {children}
                    </div>
                </div>
            )
        }
        if (pathname !== '/auth') history.push('/auth')

        return (
            <div>{children}</div>
        )
    }
}

const ReducedFeatures = ({ reduced }) => {
    localStorage.setItem('reduced', reduced)
    window.location.href = `${window.location.origin}/global-white-space-planning/0`
}


export const mapStateToProps = (state) => {
    return {
        user: state.user,
        pageAfter: state.pageAfter,
        colorScheme: state.colorScheme,
    }
}

export const WrapperConnected = connect(mapStateToProps)(Wrapper)


export const Routing = ({ store, persistor }) => {
    const cache = createCache({
        key: 'global-cache',
        nonce: 'ktnxybzbsm'
    });
    return (
        <CacheProvider value={cache}>

            <Provider store={store}>
                <PersistGate loading={null} persistor={persistor}>
                    <Router>
                        <WrapperConnected>
                            <Tooltip id='default' disableStyleInjection multiline className="reactTooltipWrapper" />
                            <Switch>
                                <Route path='/auth/:token/:cookie'>
                                    <AuthConnected />
                                </Route>
                                <Route path='/auth/:token'>
                                    <AuthConnected />
                                </Route>
                                <Route path="/auth">
                                    <AuthConnected />
                                </Route>
                                <Route path="/metrics">
                                    <MetricsConnected />
                                </Route>
                                <Route path="/settings/:scrollSection">
                                    <WorkspaceConnected />
                                </Route>
                                <Route path="/settings">
                                    <WorkspaceConnected />
                                </Route>
                                <Route path="/tasks/:tab/:page/:color/:name/:level">
                                    <TasksConnected />
                                </Route>
                                <Route path="/account/:accountId/whitespace-planning">
                                    <WhitespacingConnected />
                                </Route>
                                <Route path="/account/:id/contact/:contactId">
                                    <AccountConnected />
                                </Route>
                                <Route path="/account/:id/notes/:noteId">
                                    <AccountConnected />
                                </Route>
                                <Route path="/account/:accountId/map/">
                                    <AccountMappingConnected />
                                </Route>
                                <Route path="/account/:id">
                                    <AccountConnected />
                                </Route>
                                <Route path="/accounts/:page/:accountName">
                                    <AccountsConnected />
                                </Route>
                                <Route path="/rule/create">
                                    <CreateRulesConnected />
                                </Route>
                                <Route path="/rule/edit/:id">
                                    <CreateRulesConnected />
                                </Route>
                                <Route path="/rules/:page/:ruleName">
                                    <RulesAndTriggersConnected />
                                </Route>
                                <Route path="/privacy-policy">
                                    <PrivacyPolicy />
                                </Route>
                                <Route path="/opportunities-management/edit/:id">
                                    <Board />
                                </Route>
                                <Route path="/opportunities-management">
                                    <Board />
                                </Route>
                                <Route path="/renewals-management">
                                    <RenewalsManagement />
                                </Route>
                                <Route path="/search-results/:searchTerm">
                                    <SearchResults />
                                </Route>
                                <Route path='/callypso-admins'>
                                    <CallypsoAdmins />
                                </Route>
                                <Route path='/callypso-crm'>
                                    <CallypsoCRM />
                                </Route>
                                <Route path='/global-white-space-planning/:page'>
                                    <GlobalWhitespacePlanning />
                                </Route>
                                <Route path='/risk/:page'>
                                    <RiskConnected />
                                </Route>
                                <Route path='/embedded/risk/:page'>
                                    <RiskConnected />
                                </Route>
                                <Route path='/reduced-features'>
                                    <ReducedFeatures reduced={true} />
                                </Route>
                                <Route path='/all-features'>
                                    <ReducedFeatures reduced={false} />
                                </Route>
                                <Route path="/playbooks/:page">
                                    <Playbooks />
                                </Route>
                                <Route path="/playbook/:id">
                                    <Playbook />
                                </Route>
                                <Route path="/playbook-demo">
                                    <Playbook2 />
                                </Route>
                                <Route path="/playbook-test">
                                    <Playbook3 />
                                </Route>
                                <Route path="/generative-pdf/edit/:id/:name">
                                    <GenerativePDFFile />
                                </Route>
                                <Route path="/generative-pdf/use/:id/:name">
                                    <GenerativePDFUse />
                                </Route>
                                <Route path="/generative-pdf/generating">
                                    <GenerativePDFQueue />
                                </Route>
                                <Route path="/generative-pdf">
                                    <GenerativePDF />
                                </Route>
                                <Route path="/chat-with-your-data">
                                    <ChatWithAssistant />
                                </Route>
                                <Route path="/embedded/chat-with-your-data">
                                    <ChatWithAssistant />
                                </Route>
                                <Route path="/">
                                    <Home2Connected />
                                </Route>
                            </Switch>
                        </WrapperConnected>
                    </Router>
                </PersistGate>
            </Provider>
        </CacheProvider>
    );
}
