import React from 'react';
import cn from './Nbsp.module.css'

export const NBSP_SIZES = {
    SMALL: -1,
    DEFAULT: 0,
    BIG: 1,
    BIGGER: 2,
    EXTRA_BIG: 3,
}

const getSizeClassName = size => {
    switch (size) {
    case NBSP_SIZES.SMALL:
        return cn.small
    case NBSP_SIZES.BIG:
        return cn.big
    case NBSP_SIZES.BIGGER:
        return cn.bigger
    case NBSP_SIZES.EXTRA_BIG:
        return cn.extraBig
    default:
        return cn.default
    }
}

export const Nbsp = ({ size }) => (
    <div className={`${getSizeClassName(size)} ${cn.nbsp}`} />
)