import React, { useEffect, useState } from 'react'
import moment from 'moment'
import { URLS } from '../config'
import { makeRequest } from '../utilities/endpoints'
import { SalesforceCRUDModal } from './SalesforceCRUDModal'
import { WorkspaceTypeCheck } from './WorkspaceTypeCheck'
import { SubHeader } from './SubHeader';
import { Row } from './Row'
import { Button } from './Button'
import { Hr } from './Hr'
import { Dropdown } from './Dropdown'
import { NBSP_SIZES, Nbsp } from './Nbsp'
import { Input } from './Input'
import { workspaceTypeCheck } from '../utilities/workspaceType'
import { filterCustomFields } from '../utilities/filterCustomFields'
import { Checkbox } from './Checkbox'
import { SalesForceInputComponent } from './SalesForceInputComponent'
import cn from './OpportunitiesCRUDModalWrapper.module.css'
import { Label } from './Label'
import { MODAL_SIZES } from './Modal'
import { translateCRMLabel } from '../utilities/translateCRMLabels'

export const OpportunitiesCRUDModalWrapper = ({
    shown,
    create,
    edit,
    opportunityId,
    editCallback,
    createCallback,
    onClose,
    header,
    externalError,
    predefinedData={},
}) => {
    const [showEditLoader, setShowEditLoader] = useState(false)
    const [values, setValues] = useState([])
    const [fields, setFields] = useState([])
    const [probabilities, setProbabilities] = useState([])
    const [connectedObjectsOptions, setConnectedObjectsOptions] = useState({})
    const [createEditError, setCreateEditError] = useState(false)
    const [createOrUpdateRequestSent, setCreateOrUpdateRequestSent] = useState(false)
    const [products, setProducts] = useState(null)
    const [addedProducts, setAddedProducts] = useState(null)
    const [productToBeAddedId, setProductToBeAddedId] = useState()
    const [productToBeAddedQuantity, setProductToBeAddedQuantity] = useState()
    const [opportunityLineItemFields, setOpportunityLineItemFields] = useState([])
    const [loadingFields, setLoadingFields] = useState([])
    const [tooManyEntriesObjects, setTooManyEntriesObjects] = useState(['Account', 'Contact'])
    const [hubspotStageNames, setHubspotStageNames] = useState()
    const [onlyChangedValues, setOnlyChangedValues] = useState({})

    useEffect(() => {
        if (shown && create) {
            setTooManyEntriesObjects([])
            loadProducts()
            getCreateOpportunityFields()
            if (!workspaceTypeCheck(['HUBSPOT'])) {
                loadOpportunityLineItemsDescription()
            }
        }
    }, [create, shown])

    useEffect(() => {
        if (shown && edit) {
            setShowEditLoader(true)
            loadProducts()
            if (!workspaceTypeCheck(['HUBSPOT'])) {
                loadOpportunityLineItemsDescription()
            }
            if (workspaceTypeCheck(['CALLYPSO']) || workspaceTypeCheck(['HUBSPOT'])) {
                editOpportunity(opportunityId)
            }
        }
    }, [edit, shown])

    useEffect(() => {
        if (workspaceTypeCheck(['SFDC', 'SFDC_SANDBOX'])) {
            if (edit && shown && products && addedProducts && !fields.length) {
                editOpportunity(opportunityId)
            }
        }
    }, [edit, shown, products, addedProducts, fields])

    useEffect(() => {
        if (externalError) {
            setCreateEditError(externalError)
        }
    }, [externalError])

    // useEffect(() => {
    //     if (products && addedProducts && edit) {
    //         setAddedProducts()
    //     }
    // }, [products, addedProducts])

    const getConnectedObjectsFromFields = fields => [...new Set(fields.filter(el => el.type === 'reference').map(el => el.referenceTo).flat())]

    const editOpportunity = opportunityId => {
        setShowEditLoader(true)
        makeRequest.get(`${URLS.main}/opportunity-details/${opportunityId}`)
            .then(res => {
                if (res && res.data) {
                    const { fields, values, probabilities, products, stages, lineItems } = res.data
                    if (!values) {
                        setCreateEditError({
                            'errorCode': 'OPPORTUNITY_GONE'
                        });
                    }
                    setValues(values || {})
                    if (!workspaceTypeCheck(['HUBSPOT']) || !values.pipeline || !values.dealstage) {
                        setFields(fields)
                    }
                    setProbabilities(probabilities)
                    setHubspotStageNames(stages)
                    getConnectedObjects(fields)
                    if (workspaceTypeCheck(['SFDC', 'SFDC_SANDBOX'])) {
                        setAddedProducts(addedProducts.map(addedProduct => {
                            const correspondingLineItem = (res.data.values.OpportunityLineItems?.records || []).find(lineItem => lineItem.PricebookEntryId === addedProduct.PricebookEntryId)
                            if (correspondingLineItem) {
                                const customFields = filterCustomFields(Object.keys(correspondingLineItem).map(el => ({ value: el }))).map(el => el.value)
                                const additionalData = {}
                                customFields.forEach(field => {
                                    additionalData[field] = correspondingLineItem[field]
                                })

                                return {
                                    ...addedProduct,
                                    ...additionalData,
                                    Quantity: correspondingLineItem.Quantity,
                                    UnitPrice: correspondingLineItem.UnitPrice,
                                    active: true,
                                }
                            }
                            return addedProduct
                        }))
                    } else if (workspaceTypeCheck(['HUBSPOT'])) {
                        if (lineItems) {
                            setAddedProducts(lineItems.map(el => ({
                                id: el.properties.hs_product_id,
                                quantity: el.properties.quantity
                            })))
                        }
                        if (values.pipeline) {
                            setFields(fields.map(el => {
                                if (el.value === 'dealstage') {
                                    return {
                                        ...el,
                                        picklistValues: stages[values.pipeline]
                                    }
                                }
                                return el
                            }))
                        }
                    } else {
                        setAddedProducts(products?.map(el => ({
                            id: el.productId,
                            quantity: el.productQuantity
                        })))
                    }
                }
            })
    }

    const loadProducts = async () => {
        await makeRequest.get(`${URLS.main}/products`)
            .then(res => {
                setProducts(res.data)
                if (workspaceTypeCheck(['SFDC', 'SFDC_SANDBOX'])) {
                    setAddedProducts(res.data.map(el => ({
                        PricebookEntryId: !!el.PricebookEntries?.records && el.PricebookEntries?.records[0]?.Id,
                        Quantity: 1,
                        UnitPrice: !!el.PricebookEntries?.records && el.PricebookEntries?.records[0]?.UnitPrice,
                    })))
                }
            })
    }

    const loadOpportunityLineItemsDescription = () => {
        makeRequest.get(`${URLS.main}/describe/OpportunityLineItem`)
            .then(res => {
                setOpportunityLineItemFields(filterCustomFields(res.data))
            })
    }

    const getCreateOpportunityFields = () => {
        setShowEditLoader(true)
        makeRequest.get(`${URLS.main}/opportunity-details/create`)
            .then(res => {
                if (res && res.data) {
                    const { fields, probabilities, stages } = res.data
                    setFields(fields)
                    setProbabilities(probabilities)
                    getConnectedObjects(fields, true)
                    setHubspotStageNames(stages)
                }
            })
    }

    const getConnectedObjects = (fields, dontSkipLargeObjects) => {
        const newConnectedObjects = getConnectedObjectsFromFields(fields).filter(el => !Object.keys(connectedObjectsOptions).includes(el))
        const objectsToRetrieve = newConnectedObjects.filter(el => !(dontSkipLargeObjects ? [] : tooManyEntriesObjects).includes(el) && !Object.keys(connectedObjectsOptions).includes(el)).join(',')
        if (!newConnectedObjects || !newConnectedObjects.length || !objectsToRetrieve?.length) {
            setShowEditLoader(false)
        } else {
            makeRequest.get(`${URLS.main}/sobject-dropdown-options/${objectsToRetrieve}`)
                .then(res => {
                    if (res && res.data) {
                        const objects = { ...connectedObjectsOptions }
                        Object.keys(res.data).forEach(el => {
                            objects[el] = res.data[el].map(el => ({
                                value: el.Id,
                                label: el.Name
                            }))
                            if (fields.length === 1) {
                                setTooManyEntriesObjects(tooManyEntriesObjects.filter(obj => obj !== el))
                            }
                        })
                        setConnectedObjectsOptions(objects)
                        setShowEditLoader(false)
                        setLoadingFields([])
                    }
                })
        }
    }

    const createNewOpportunity = () => {
        setCreateOrUpdateRequestSent(true)
        const cleanedUpValues = {}
        Object.keys(predefinedData).forEach(key => {
            cleanedUpValues[key] = predefinedData[key].data
        })
        Object.keys(values).forEach(key => {
            cleanedUpValues[key] = values[key]
        })
        if (addedProducts && !!addedProducts?.length) {
            cleanedUpValues.products = addedProducts
        }
        if (!cleanedUpValues.CloseDate) {
            cleanedUpValues.CloseDate = moment().format('YYYY-MM-DDThh:mm:ssZ')
        }
        if (workspaceTypeCheck(['HUBSPOT'])) {
            cleanedUpValues.hubspotStageNames = hubspotStageNames
        }
        makeRequest.post(`${URLS.main}/opportunities`, cleanedUpValues)
            .then((res) => {
                createCallback(res.data)
                closeCreateEditOpportunityModal()
            })
            .catch((error) => {
                setCreateEditError(error.response.data)
                setCreateOrUpdateRequestSent(false)
            })
    }

    const closeCreateEditOpportunityModal = () => {
        setShowEditLoader(false)
        setValues([])
        setFields([])
        setAddedProducts(null)
        setCreateEditError(null)
        setCreateOrUpdateRequestSent(false)
    }

    const getErrorMessage = () => {
        switch (createEditError.errorCode) {
        case 'REQUIRED_FIELD_MISSING':
            return `Please fill out all of the required fields: ${fields.filter(el => !el.nillable && !el.defaultedOnCreate).map(el => el.label).join(', ')}`
        case 'INACTIVE_OWNER_OR_USER':
            return `Please make sure the new ${translateCRMLabel('Account')} and/or Owner of this opportunity are still active in your Salesforce instance.`
        case 'FIELD_CUSTOM_VALIDATION_EXCEPTION':
            return createEditError.message
        case 'OPPORTUNITY_GONE':
            return `${translateCRMLabel('Opportunity')} is no longer available/exists.`
        default:
            if (createEditError?.errorCode?.indexOf('CALLYPSO_') === 0) {
                return createEditError.message
            }
            return `We are sorry, but there was an error with the Opportunity data you tried to submit: ${createEditError.name}`
        }
    }

    const updateOpportunityEdit = () => {
        setCreateOrUpdateRequestSent(true)
        let cleanedUpValues = {}
        if (workspaceTypeCheck(['HUBSPOT'])) {
            cleanedUpValues = { ...onlyChangedValues }
            cleanedUpValues.products = (addedProducts || [])
            cleanedUpValues.hubspotStageNames = hubspotStageNames
        } else {
            Object.keys(predefinedData).forEach(key => {
                cleanedUpValues[key] = predefinedData[key].data
            })

            Object.keys(values).forEach(key => {
                if (
                    (values[key] === null || typeof values[key] !== 'object')
                    && !Object.prototype.hasOwnProperty.call(values, `${key}Id`)
                ) {
                    cleanedUpValues[key] = values[key]
                } else if (
                    (typeof values[key] === 'object' && values[key] instanceof Date)
                ) {
                    if (fields.find(el => el.value === key)?.type === 'datetime') {
                        cleanedUpValues[key] = moment(values[key]).format('YYYY-MM-DDThh:mm:ssZ')
                    } else {
                        cleanedUpValues[key] = moment(values[key]).format('YYYY-MM-DD')
                    }
                }
            })

            delete cleanedUpValues.OpportunityLineItems
            cleanedUpValues.products = (addedProducts || [])
        }

        setShowEditLoader(true)
        makeRequest.put(`${URLS.main}/opportunities/${opportunityId}`, cleanedUpValues)
            .then((res) => {
                editCallback && editCallback({ connectedObjectsOptions, opportunityId, values: cleanedUpValues, response: res?.data })
                closeCreateEditOpportunityModal()
            })
            .catch((error) => {
                setShowEditLoader(false)
                setCreateOrUpdateRequestSent(false)
                setCreateEditError(error.response.data)
            })
    }

    const getTotalAmount = (productsToBeAdded = addedProducts) => {
        let totalAmount = 0
        productsToBeAdded.forEach(product => {
            // eslint-disable-next-line eqeqeq
            totalAmount += (products?.find(el => el.Id == product.id).price || 0) * product.quantity
        })
        return totalAmount
    }

    const resetProductsToBeAdded = () => {
        setAddedProducts(null)
        setProductToBeAddedId(null)
        setProductToBeAddedQuantity(null)
    }

    let disabledButton = !!createOrUpdateRequestSent

    if (workspaceTypeCheck(['SFDC', 'SFDC_SANDBOX'])) {
        const requiredCustomFields = opportunityLineItemFields.filter(field => !field.nillable && !field.calculated && !field.defaultedOnCreate).map(field => field.value)
        disabledButton = disabledButton || (addedProducts || []).filter(el => !!el.active).find(addedProduct => {
            for (const requiredField of requiredCustomFields) {
                if (addedProduct[requiredField] === undefined || !(addedProduct.Quantity > 0) || !addedProduct.UnitPrice) {
                    return true
                }
            }
            return false
        })
    }

    if (!shown) return null
    return (
        <SalesforceCRUDModal
            inactiveByDefault={tooManyEntriesObjects}
            onInactiveByDefaultClick={object => {
                if (!!tooManyEntriesObjects.length) {
                    getConnectedObjects([object], true)
                    setLoadingFields([...loadingFields, object])
                }
            }}
            loadingFields={loadingFields}
            size={MODAL_SIZES.SLIGHTLY_BIGGER}
            fieldLabelClass={cn.sfdcOppFieldLabel}
            header={header}
            disabledButton={disabledButton}
            errorMessage={createEditError && getErrorMessage()}
            showLoader={showEditLoader}
            fields={fields}
            values={values}
            predefinedData={predefinedData}
            connectedObjectsOptions={connectedObjectsOptions}
            onClose={() => {
                closeCreateEditOpportunityModal()
                onClose()
                resetProductsToBeAdded()
            }}
            onClick={() => {
                if (edit) {
                    updateOpportunityEdit()
                }
                if (create) {
                    createNewOpportunity()
                }
                if (workspaceTypeCheck(['CALLYPSO', 'HUBSPOT'])) {
                    resetProductsToBeAdded()
                }
            }}
            onChange={(data, field) => {
                if (field.value === 'StageName') {
                    const foundProbability = probabilities.find(el => el.ApiName === data || el.MasterLabel === data)
                    setValues({
                        ...values,
                        [field.value]: data,
                        Probability: foundProbability?.DefaultProbability
                    })
                } else if (workspaceTypeCheck(['HUBSPOT'])) {
                    if (field.value === 'pipeline') {
                        setFields(fields.map(el => {
                            if (el.value === 'dealstage') {
                                return {
                                    ...el,
                                    picklistValues: hubspotStageNames[data]
                                }
                            }
                            return el
                        }))
                        if (hubspotStageNames[data] && hubspotStageNames[data][0] && hubspotStageNames[data][0].value) {
                            setValues({
                                ...values,
                                [field.value]: data,
                                dealstage: hubspotStageNames[data][0].value
                            })
                            setOnlyChangedValues({
                                ...onlyChangedValues,
                                [field.value]: data,
                                dealstage: hubspotStageNames[data][0].value
                            })
                        } else {
                            setValues({
                                ...values,
                                [field.value]: data
                            })
                            setOnlyChangedValues({
                                ...onlyChangedValues,
                                [field.value]: data,
                            })
                        }
                    } else {
                        setValues({
                            ...values,
                            [field.value]: data
                        })
                        setOnlyChangedValues({
                            ...onlyChangedValues,
                            [field.value]: data,
                        })
                    }
                } else {
                    setValues({
                        ...values,
                        [field.value]: data
                    })
                }
            }}
        >
            <SubHeader className={cn.subHeader} >
                Products / Services
            </SubHeader>
            <br />

            <WorkspaceTypeCheck requiredTypes={['SFDC', 'SFDC_SANDBOX']}>
                {(products && addedProducts ? products : []).map(product => (
                    <>
                        <Row className={cn.sfdcProductRow}>
                            <Checkbox
                                className={cn.sfdcProductCheckbox}
                                label={product.Name}
                                checked={!!addedProducts.filter(el => !!el.active).map(addedProduct => addedProduct.PricebookEntryId).includes(product.PricebookEntries?.records[0].Id)}
                                onClick={() => {
                                    if (addedProducts.filter(el => !!el.active).map(addedProduct => addedProduct.PricebookEntryId).includes(product.PricebookEntries.records[0].Id)) {
                                        setAddedProducts(addedProducts.map(addedProduct => ({
                                            ...addedProduct,
                                            active: addedProduct.PricebookEntryId === product.PricebookEntries.records[0].Id ? false : addedProduct.active
                                        })))
                                    } else {
                                        setAddedProducts(addedProducts.map(addedProduct => ({
                                            ...addedProduct,
                                            active: addedProduct.PricebookEntryId === product.PricebookEntries.records[0].Id ? true : addedProduct.active
                                        })))
                                    }
                                }}
                            />
                            <Nbsp size={NBSP_SIZES.EXTRA_BIG} />
                            <div className={cn.sfdcProductsWrapper}>
                                <Row noMargin columnFlex>
                                    <Label required className={cn.sfdcProductFieldLabel}>Quantity</Label>
                                    <Input
                                        fullWidth
                                        type='number'
                                        value={addedProducts.find(el => el.PricebookEntryId === product.PricebookEntries?.records[0].Id)?.Quantity}
                                        onChange={(e) => {
                                            setAddedProducts(addedProducts.map(addedProduct => ({
                                                ...addedProduct,
                                                Quantity: addedProduct.PricebookEntryId === product.PricebookEntries.records[0].Id ? e.target.value : addedProduct?.Quantity
                                            })))
                                        }}
                                    />
                                </Row>
                                <br />
                                <Row noMargin columnFlex>
                                    <Label required className={cn.sfdcProductFieldLabel}>Unit Price</Label>
                                    <Input
                                        fullWidth
                                        type='number'
                                        value={addedProducts.find(el => el.PricebookEntryId === product.PricebookEntries?.records[0].Id)?.UnitPrice}
                                        onChange={(e) => {
                                            setAddedProducts(addedProducts.map(addedProduct => ({
                                                ...addedProduct,
                                                UnitPrice: addedProduct.PricebookEntryId === product.PricebookEntries?.records[0].Id ? e.target.value : addedProduct?.UnitPrice
                                            })))
                                        }}
                                    />
                                </Row>
                                <br />
                                <i>Custom fields</i>
                                {opportunityLineItemFields.map(field => {
                                    return (
                                        <>
                                            <Nbsp />
                                            <SalesForceInputComponent
                                                {...field}
                                                labelRequired={!field.nillable && !field.calculated && !field.defaultedOnCreate}
                                                value={!!product.PricebookEntries?.records && addedProducts.find(addedProduct => addedProduct.PricebookEntryId === product.PricebookEntries.records[0].Id)[field.value]}
                                                onChange={(e) => {
                                                    setAddedProducts(addedProducts.map(addedProduct => ({
                                                        ...addedProduct,
                                                        [field.value]: addedProduct.PricebookEntryId === product.PricebookEntries.records[0].Id ? e : addedProduct[field.value]
                                                    })))
                                                }}
                                            />
                                        </>
                                    )
                                })}
                            </div>
                        </Row>
                        <Hr />
                    </>
                ))}
            </WorkspaceTypeCheck>

            <WorkspaceTypeCheck requiredTypes={['CALLYPSO', 'HUBSPOT']}>
                {addedProducts?.map((el, i) => (
                    <Row spaceBetween>
                        <span>
                            {products?.find(product => {
                                // eslint-disable-next-line eqeqeq
                                return product.Id == el.id
                            })?.Name}
                        </span>
                        <span>-</span>
                        <span>
                            {el.quantity}
                        </span>
                        <Button
                            onClick={() => {
                                const updatedProducts = addedProducts?.filter((_el, innerIndex) => innerIndex !== i)
                                setAddedProducts(updatedProducts)
                                if (workspaceTypeCheck(['HUBSPOT'])) {
                                    setValues({
                                        ...values,
                                        amount: getTotalAmount(updatedProducts)
                                    })
                                    setOnlyChangedValues({
                                        ...onlyChangedValues,
                                        amount: getTotalAmount(updatedProducts)
                                    })
                                } else {
                                    setValues({
                                        ...values,
                                        Amount: getTotalAmount(updatedProducts)
                                    })
                                }
                            }}
                        >
                            Delete
                        </Button>
                    </Row>
                ))}
                <Row noMargin fullWidth>
                    <Dropdown
                        values={(products || []).map(el => ({
                            label: el.Name,
                            value: el.Id
                        }))}
                        value={productToBeAddedId || (products && products[0]?.Id)}
                        onChange={e => setProductToBeAddedId(e.target.value)}
                    />
                    <Nbsp />
                    <Input
                        type='number'
                        placeholder='Quantity'
                        value={productToBeAddedQuantity}
                        onChange={e => setProductToBeAddedQuantity(e.target.value)}
                    />
                    <Nbsp />
                    <Button
                        onClick={() => {
                            const updatedProducts = [
                                ...(addedProducts || []),
                                {
                                    id: productToBeAddedId || products[0]?.Id,
                                    quantity: productToBeAddedQuantity || 1
                                }
                            ]
                            setAddedProducts(updatedProducts)
                            if (workspaceTypeCheck(['HUBSPOT'])) {
                                setValues({
                                    ...values,
                                    amount: getTotalAmount(updatedProducts)
                                })
                                setOnlyChangedValues({
                                    ...onlyChangedValues,
                                    amount: getTotalAmount(updatedProducts)
                                })
                            } else {
                                setValues({
                                    ...values,
                                    Amount: getTotalAmount(updatedProducts)
                                })
                            }
                        }}
                    >
                        Add
                    </Button>
                </Row>
            </WorkspaceTypeCheck>
        </SalesforceCRUDModal>
    )
}
