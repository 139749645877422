import React from 'react'
import cn from './EmptyTableElement.module.css'
export const EmptyTableElement = ({ value, columnIndex, rowIndex, onChange }) => {
    const inactive = !columnIndex && !rowIndex

    return <div className={cn.tableElement} key={`${rowIndex}${columnIndex}`}>
        <input
            className={`${cn.tableInputElement} ${inactive ? cn.inactive : ''}`}
            value={value}
            onChange={e => {
                onChange(e.target.value, columnIndex, rowIndex)
            }}
        />
    </div>
}