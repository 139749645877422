import React, { useEffect, useState, useCallback } from 'react';
import { connect } from 'react-redux';
import debounce from 'lodash.debounce';
import { useHistory, useParams } from 'react-router-dom';
import { faPen, faPlus, faTrash } from '@fortawesome/free-solid-svg-icons';
import { Button, BUTTON_TYPES } from '../../components/Button';
import { Pagination } from '../../components/Pagination';
import { PermissionCheck } from '../../components/PermissionCheck';
import { Table } from '../../components/Table';
import { TableSearchInput } from '../../components/TableSearchInput';
import { ViewContainer } from '../../components/ViewContainer';
import { URLS } from '../../config';
import { makeRequest } from '../../utilities/endpoints';
import { getUserPermissions, permissionCheck } from '../../utilities/permissionCheck';
import cn from './RulesAndTriggers.module.css'
import { Row } from '../../components/Row';

export const RulesAndTriggers = () => {
    getUserPermissions()
    const history = useHistory()
    const { page: routePage, ruleName: routeRuleName } = useParams()
    const page = parseInt(routePage) || 0;
    const ruleName = routeRuleName === 'null' ? null : routeRuleName
    const [rules, setRules] = useState([])
    const [currentPage, setCurrentPage] = useState(page)
    const [count, setCount] = useState(false)
    const [rulesRequestSent, setRuelsRequestSent] = useState(false)
    const [sortDir, setSortDir] = useState(null);
    const [sortedCol, setSortedCol] = useState(null);

    const columnProps = [
        'taskNaming',
        null,
        'defaultDueDate',
        null
    ];

    const colMask = [false, true, false, true, true];


    useEffect(() => {
        if (page === currentPage) return
        loadRules(page, sortedCol, columnProps, sortDir, ruleName);
        setCurrentPage(page)
    }, [currentPage, page])

    const addRule = () => {
        history.push('/rule/create')
    }

    const loadRules = (page, colNum=null, columnProps=null, sortedDir=null, filter=null) => {
        if (typeof colNum === 'number' || filter) {
            const name = columnProps[colNum] || null;
            const filterStr = filter || null;

            makeRequest.get(`${URLS.main}/rules/sorted/${filterStr}/${name}/${sortedDir}/${10}/${page}`)
                .then(res => {
                    setSortDir(sortedDir);
                    setSortedCol(colNum);
                    setRules(res.data.rules.map(rule => ({
                        id: rule.id,
                        playBookName: rule.playBookName,
                        conditions: rule.rulesBlocks.flat().length,
                        coolDownPeriod: rule.cooldown,
                        createdTasks: rule.createdTasks,
                        actions: false
                    })))
                    setCount(res.data.count)
                })
                .catch(console.error)
        } else {
            makeRequest.get(`${URLS.main}/rules/${10}/${page}`)
                .then(res => {
                    setRules(res.data.rules.map(rule => ({
                        id: rule.id,
                        playBookName: rule.playBookName,
                        conditions: rule.rulesBlocks.flat().length,
                        coolDownPeriod: rule.cooldown,
                        createdTasks: rule.createdTasks,
                        actions: false
                    })))
                    setCount(res.data.count)
                })
                .catch(console.error)
        }
    }

    const loadRulesDebounce = useCallback(
        debounce(loadRules, 250),
        []
    );

    const edit = (id) => {
        history.push(`/rule/edit/${id}`)
    }

    const reload = () => {
        loadRules(0, sortedCol, columnProps, sortDir, ruleName);
        setCurrentPage(0)
        history.push(`/rules/0/${ruleName|| 'null'}`);
    }

    const remove = id => {
        makeRequest.delete(`${URLS.main}/rule/${id}`)
            .then(reload)
            .catch(reload)
    }

    useEffect(() => {
        if (!rulesRequestSent) {
            loadRules(page)
            setRuelsRequestSent(true)
        }
    }, [rulesRequestSent])

    useEffect(() => {
        loadRulesDebounce(page || 0, sortedCol, columnProps, sortDir, ruleName);
    }, [ruleName]);

    return (
        <>
            <ViewContainer>
                <Row spaceBetween noMargin>
                    {permissionCheck('RULES', 'CREATE') ? (
                        <Button
                            onClick={addRule}
                            type={BUTTON_TYPES.ICON_AND_TEXT}
                            icon={faPlus}
                        >
                            ADD RULE
                        </Button>
                    ) : null}
                    <TableSearchInput
                        placeHolder={'Filter By Play Naming...'}
                        filters={ruleName}
                        filterCallBack={(filterVal) => {
                            history.push(`/rules/0/${filterVal|| 'null'}`);
                        }}
                    />
                </Row>
                <Table
                    headers={[
                        "Play NAMING",
                        "CONDITIONS",
                        // "COOLDOWN",
                        "CREATED Playbooks",
                        (permissionCheck('RULES', 'UPDATE') && "ACTIONS")
                    ]}
                    data={
                        rules.map(rule => (
                            Object.keys(rule).filter(el => el !== 'id' && el !== 'coolDownPeriod').map(key => {
                                if (key === 'actions') {
                                    return (
                                        <PermissionCheck requiredPermissions={[['RULES', 'UPDATE']]}>
                                            <div className={cn.actions}>
                                                <Button
                                                    type={BUTTON_TYPES.SMALL_ICON}
                                                    icon={faPen}
                                                    className={'green'}
                                                    onClick={() => edit(rule.id)}
                                                />
                                                <Button
                                                    type={BUTTON_TYPES.SMALL_ICON}
                                                    icon={faTrash}
                                                    className={'red'}
                                                    onClick={() => remove(rule.id)}
                                                />
                                            </div>
                                        </PermissionCheck>
                                    )
                                }
                                return (
                                    <div className={cn.tableContent}>{rule[key]}</div>
                                )
                            })
                        ))
                    }
                    customStyles={{
                        3: { width: 130 },
                    }}
                    sorted={true}
                    sortDir={sortDir}
                    sortedCol={sortedCol}
                    sortMask={colMask}
                    sortCallBack={(colName, colNum, sortedDir) => { loadRules(currentPage, colNum, columnProps, sortedDir, ruleName); }}
                />
            </ViewContainer>
            <Pagination pages={Math.ceil(count / 10)} />
        </>
    )
}

export const mapStateToProps = (state) => {
    return {
        user: state.user,
    }
}

export const RulesAndTriggersConnected = connect(mapStateToProps)(RulesAndTriggers)
