import React, { useEffect, useState } from 'react'
import { faTrash } from '@fortawesome/free-solid-svg-icons'
import moment from 'moment'
import cn from './Emails.module.css'
import { Row } from '../../../../components/Row'
import { Heading } from '../../../../components/Heading'
import { LOADER_SIZES, LOADER_TYPES, Loader } from '../../../../components/Loader'
import { makeRequest } from '../../../../utilities/endpoints'
import { URLS } from '../../../../config'
import { SelectWrapperConnected } from '../../../../components/SelectWrapper'
import { BUTTON_TYPES, Button } from '../../../../components/Button'
import { Star } from '../../../../assets/icons/Star'
import { Nbsp } from '../../../../components/Nbsp'
import { DropdownToggleIndicator } from '../../../../components/DropdownToggleIndicator'

const Thread = ({
    onClick,
    selected,
    emails
}) => {
    const {
        subject,
        sender,
        date,
        snippet,
    } = emails[0]

    return (
        <div className={`${cn.thread} ${selected ? cn.selected : ''}`} onClick={onClick}>
            <Row noMargin fullWidth noAligning>
                <Image sender={sender} />
                <div className={cn.rightPart}>
                    <Row noMargin spaceBetween className={cn.tileWithDate}>
                        <div className={cn.title}>
                            {subject}
                        </div>
                        <div className={cn.timestamp}>
                            {moment(date).format('MMM DD YYYY')}
                        </div>
                    </Row>
                    <div className={cn.content}>
                        {snippet}
                    </div>
                </div>
            </Row>
        </div>
    )
}

const Image = ({ sender }) => (
    <div className={cn.image}>
        {sender.split(' ')[0]?.slice(0, 1)}
    </div>
)

const Email = ({
    body,
    sender,
    receiver,
    snippet,
    date
}) => (
    <div className={cn.email}>
        <Row noMargin className={cn.titleRow} fullWidth spaceBetween>
            <Row noMargin>
                <Image sender={sender} />
                <div>
                    <Row noMargin className={cn.senderDetails}>
                        <div>
                            {sender.split(' <').slice(0, 1).join(' <')}
                        </div>
                        <div>
                            {sender.split(' <')[sender.split(' <').length - 1].split('').slice(0, -1)}
                        </div>
                    </Row>
                    <Row noMargin>
                        <div className={cn.receiver}>
                            To{' '}{receiver}
                        </div>
                        <Button
                            type={BUTTON_TYPES.SMALL_ICON}
                            customIcon={<DropdownToggleIndicator />}
                            className={cn.dropdownIndicator}
                        />
                    </Row>
                </div>
            </Row>
            <Row noMargin>
                <div className={cn.timestamp}>
                    {moment(date).format('MMM DD YYYY')}
                </div>
                <Nbsp />
                <Button
                    type={BUTTON_TYPES.SMALL_ICON}
                    customIcon={<Star />}
                />
            </Row>
        </Row>
        <div className={cn.emailContent}>
            {body || snippet}
        </div>
    </div>
)

const FocusedThread = ({ emails }) => (
    <div className={`${cn.scrollableParent} ${cn.focusedThreadWrapper}`}>
        <Row noMargin className={cn.top} spaceBetween>
            <div className={cn.threadTitle}>
                {emails[0].subject}
            </div>
            <Button
                type={BUTTON_TYPES.SMALL_ICON}
                icon={faTrash}
            />
        </Row>
        {emails.map(email => <Email {...email} />)}
    </div>
)

export const Emails = ({ accountId }) => {
    const [emails, setEmails] = useState({})
    const [selectedEmailLabels, setSelectedEmailLabels] = useState([])
    const [showLoader, setShowLoader] = useState(true)
    const [focusedThread, setFocusedThread] = useState()

    const getEmails = (id) => {
        if (id !== '0010900000URsSrAAL') return

        makeRequest.get(`${URLS.main}/account/${id}/emails/${selectedEmailLabels?.length ? selectedEmailLabels.map(el => el.value).join(',') : null}`)
            .then(res => {
                setEmails(res.data)
                setShowLoader(false)
            })
    }

    useEffect(() => {
        setShowLoader(true)
        getEmails(accountId)
    }, [accountId, selectedEmailLabels])

    if (showLoader) {
        return <Loader type={LOADER_TYPES.SPINNER} size={LOADER_SIZES.BIG} />
    }

    return (
        <>
            <Row noMargin spaceBetween className={cn.headingWrapper}>
                <Heading>
                    Emails
                </Heading>
                <SelectWrapperConnected
                    isMulti
                    onChange={setSelectedEmailLabels}
                    options={[
                        { value: 'IMPORTANT', label: 'Important' },
                        { value: 'INBOX', label: 'Inbox' },
                        { value: 'SENT', label: 'Sent' },
                        { value: 'UPDATES', label: 'Updates' },
                    ]}
                    value={selectedEmailLabels}
                />
            </Row>
            <Row noMargin fullHeight className={cn.contentWrapper}>
                <div className={`${cn.scrollableParent} ${cn.threadsWrapper}`}>
                    {Object.values(emails).map(emailThread => (
                        <Thread
                            emails={emailThread}
                            onClick={() => setFocusedThread(emailThread[0].threadId)}
                            selected={focusedThread === emailThread[0].threadId}
                        />
                    ))}
                </div>
                {focusedThread && (
                    <FocusedThread emails={emails[focusedThread]} />
                )}
            </Row>
        </>
    )
}