import React from 'react'
import moment from 'moment'
import { Button } from './Button'
import { Error } from './Error'
import { Loader, LOADER_SIZES, LOADER_TYPES } from './Loader'
import { Modal } from './Modal'
import { Row } from './Row'
import { SalesForceInputComponent } from './SalesForceInputComponent'
import cn from './SalesforceCRUDModal.module.css'

export const SalesforceCRUDModal = ({
    errorMessage,
    size,
    fieldLabelClass,
    header,
    fields,
    values,
    onClick,
    onClose,
    connectedObjectsOptions,
    disabledButton,
    onChange,
    showLoader,
    predefinedData={},
    children, // useful for edge cases where we need custom details inside
    inactiveByDefault,
    onInactiveByDefaultClick,
    loadingFields
}) => (
    <Modal
        size={size}
        header={header}
        buttons={(
            <Button
                fullWidth
                disabled={disabledButton}
                onClick={onClick}
            >
                Save
            </Button>
        )}
        onClose={onClose}
    >
        {!!errorMessage && (
            <Error>{errorMessage}</Error>
        )}
        {
            showLoader ? (
                <Loader type={LOADER_TYPES.SPINNER} size={LOADER_SIZES.BIG} />
            ) : (
                <>
                    {
                        fields.map((field) => {
                            let overrideValue = null
                            let overridePicklistValue = null
                            const inactiveByDefaultMatched = inactiveByDefault?.includes(field.value) || inactiveByDefault?.includes(field.relationshipName) || inactiveByDefault?.includes(field.referenceTo)
                            if (inactiveByDefault?.includes(field.value) && values[field.value]?.Id) {
                                overrideValue = values[field.value].Id
                                overridePicklistValue = [{ label: values[field.value].Name, value: overrideValue }]
                            } else if (inactiveByDefault?.includes(field.relationshipName) && values[field.relationshipName]?.Id) {
                                overrideValue = values[field.relationshipName].Id
                                overridePicklistValue = [{ label: values[field.relationshipName].Name, value: overrideValue }]
                            } else if (inactiveByDefault?.includes(field.referenceTo) && values[field.referenceTo]?.Id) {
                                overrideValue = values[field.referenceTo].Id
                                overridePicklistValue = [{ label: values[field.referenceTo].Name, value: overrideValue }]
                            }
                            const referenceTo = values[field.referenceTo] || !!connectedObjectsOptions[field.referenceTo]
                            let type = !!referenceTo ? 'picklist' : field.type
                            const picklistValues = !!referenceTo ? (connectedObjectsOptions[field.referenceTo] || []) : field.picklistValues
                            let value = predefinedData[field.value]?.data || values[field.value]

                            if (!!loadingFields?.find(el => field.value === el.value)) {
                                type = 'loader'
                            }

                            if (field.type === 'date') {
                                if (!value) {
                                    value = moment(new Date()).format('MM/DD/YYYY')
                                } else {
                                    value = moment(value).format('MM/DD/YYYY')
                                }
                            } else if (field.type === 'datetime') {
                                if (!value) {
                                    value = moment(new Date()).format('HH:mm MM/DD/YYYY')
                                } else {
                                    value = moment(value).format('HH:mm MM/DD/YYYY')
                                }
                            }

                            return (
                                <Row key={`${field.value}`} spaceBetween>
                                    <SalesForceInputComponent
                                        {...field}
                                        onClick={inactiveByDefaultMatched ? () => onInactiveByDefaultClick(field) : () => {}}
                                        disabled={predefinedData[field.value]?.disabled || field.disabled}
                                        labelClassName={fieldLabelClass || cn.editModalLabel}
                                        value={overrideValue || value}
                                        type={type}
                                        picklistValues={overridePicklistValue || picklistValues}
                                        onChange={data => onChange(data, field)}
                                    />
                                </Row>
                            )
                        })
                    }
                    { children }
                </>
            )
        }
    </Modal>
)
