import React, { useEffect, useRef, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons'
import cn from './WhiteSpaceDropdown.module.css'
import { useOutsideClickHandler } from '../../../customHooks/useOutsideClickHandler'

export const WhiteSpaceDropdown = ({ options, value, onChange, skipMainComponent }) => {
    const [opened, setOpened] = useState(false)
    const [openedDefaultOnce, setOpenedDefaultOnce] = useState(false)
    const wrapperRef = useRef(null)
    useOutsideClickHandler(wrapperRef, () => {
        setOpened(false)
    });

    useEffect(() => {
        if (skipMainComponent && !openedDefaultOnce) {
            setOpened(true)
            setOpenedDefaultOnce(true)
        }
    }, [skipMainComponent, openedDefaultOnce])

    const content = <div className={cn.options}>
        {options.map(el => (
            <div
                className={`${cn.option} ${el.value === value ? cn.selected : ''}`}
                key={el.value+el.label}
                onClick={(e) => {
                    e.preventDefault()
                    e.stopPropagation()
                    setOpened(false)
                    onChange && onChange(el.value)
                }}
            >
                {el.label}
            </div>
        ))}
    </div>

    if (skipMainComponent) {
        return content
    }

    return <div className={`${cn.dropdownCell} ${cn.closed}`} ref={wrapperRef}>
        {<div
            className={cn.dropdownValue}
            onClick={() => {
                setOpened(!opened)
            }}
        >
            <span>{options.find(el => el.value === value)?.label}</span>
            {opened ? <FontAwesomeIcon icon={faChevronUp} /> : <FontAwesomeIcon icon={faChevronDown} />}
        </div>}
        {opened && content}
    </div>
}