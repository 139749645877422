import React, { useEffect, useState } from 'react'
import cn from './Notes.module.css'
import { URLS } from '../../config'
import { makeRequest } from '../../utilities/endpoints'
import { Note } from './Note'
import { convertNoteText } from '../../utilities/slateUtils'
import { permissionCheck } from '../../utilities/permissionCheck'
import { Modal } from '../Modal'
import { Row } from '../Row'
import { Button } from '../Button'
import { FocusedNote } from '../../views/accounts/account/components/FocusedNote'
import { NotesCRUDWrapper } from './NotesCRUDWrapper'
import { PermissionCheck } from '../PermissionCheck'
import { LOADER_SIZES, LOADER_TYPES, Loader } from '../Loader'

export const NOTES_TYPES = {
    CONTACTS: 0,
    ACCOUNT: 1,
    CONTACT: 2,
}

const getContactLabel = contact => {
    if (contact?.FirstName || contact?.LastName) {
        return `${contact?.FirstName} ${contact?.LastName}`
    }
    return contact?.Email
}


export const Notes = ({ contacts, type, id, onEdit, accountName }) => {
    const [loaded, setLoaded] = useState(false)
    const [notes, setNotes] = useState([])
    const [noteToBeDeleted, setNoteToBeDeleted] = useState(null)
    const [noteToBeEditted, setNoteToBeEditted] = useState(null)
    const [noteData, setNoteData] = useState({})
    const [selectedNote, setSelectedNote] = useState()
    const [newNoteShown, setNewNoteShown] = useState(false)

    const showNewNote = () => {
        setNewNoteShown(true)
        if (type === NOTES_TYPES.CONTACTS) {
            setNoteData({
                title: 'Note for a Contact'
            })
        } else {
            setNoteData({
                title: accountName ? `Note on: "${accountName}"` : 'New Note'
            })
        }
    }

    const removeNoteAttachment = ({ attachment, noteId }) => {
        const dataToSearch = Array.isArray(notes) ? notes : Object.values(notes).map(el => el.notes).flat()
        const note = dataToSearch.find(el => el.id === noteId)

        const noteData = {
            ...note,
            deleteAttachmentId: attachment.id,
            richTextDescription: note.body,
            description: convertNoteText(note.body)
        }

        makeRequest.put(`${URLS.main}/notes/${noteData.id}`, noteData)
            .then(() => {
                loadNotes()
            })
    }

    const deleteNote = () => {
        makeRequest.delete(`${URLS.main}/notes/${noteToBeDeleted.id}`)
            .then(() => loadNotes())
            .catch(e => {
                if (e?.response?.status === 404) {
                    switch (e?.response?.data?.errorCode) {
                    case 'ENTITY_IS_DELETED':
                        loadNotes();
                        break;
                    default:
                        break;
                    }
                }
            });
        setNoteToBeDeleted(null)
    }

    const loadNotes = () => {
        setLoaded(false)
        let url = `${URLS.main}/notes/${id}`
        if (type === NOTES_TYPES.CONTACTS) {
            url = `${URLS.main}/notes/contacts/${id}`
        } else if (type === NOTES_TYPES.CONTACT) {
            url = `${URLS.main}/notes/contact/${id}`
        }
        makeRequest.get(url)
            .then(res => {
                if (res && res.data) {
                    if (type === NOTES_TYPES.CONTACTS) {
                        const notesPerContact = {}
                        res.data.notes.forEach(note => {
                            if (!notesPerContact[note.parentId]) {
                                const ownerContact = contacts.find(el => el.Id === note.parentId)
                                notesPerContact[note.parentId] = {
                                    name: getContactLabel(ownerContact),
                                    notes: []
                                }
                            }
                            notesPerContact[note.parentId].notes.push(note)
                        })
                        setLoaded(true)
                        setNotes(notesPerContact)
                        setSelectedNote(notesPerContact[0]?.id)
                    } else {
                        setLoaded(true)
                        setNotes(res.data.notes)
                        setSelectedNote(res.data.notes[0]?.id)
                    }
                }
            })
    }

    useEffect(() => {
        if (!!id && type !== undefined) {
            setNotes([])
            loadNotes()
        }
    }, [type, id])

    const searchibleNotes = Array.isArray(notes) ? notes : Object.values(notes).map(el => el.notes).flat()

    if (!loaded) {
        return <Loader type={LOADER_TYPES.SPINNER} size={LOADER_SIZES.BIG} />
    }

    return (
        <div className={cn.notesWrapper}>
            <div className={cn.notesList}>
                <PermissionCheck requiredPermissions={[type === NOTES_TYPES.CONTACTS ? ['ACCOUNT_CONTACTS', 'UPDATE'] : ['ACCOUNTS', 'UPDATE']]}>
                    <Button
                        fullWidth
                        onClick={showNewNote}
                    >
                        New Note
                    </Button>
                </PermissionCheck>
                {(type === NOTES_TYPES.ACCOUNT || type === NOTES_TYPES.CONTACT) ? (Array.isArray(notes) ? notes : []).map(note => (
                    <Note
                        accountId={id}
                        key={note.id}
                        note={note}
                        onRemoveAttachment={removeNoteAttachment}
                        onClick={id => setSelectedNote(id)}
                        selected={note.id === selectedNote}
                        onEdit={permissionCheck('ACCOUNTS', 'UPDATE') ? () => {
                            setNoteToBeEditted(note)
                            setNoteData({
                                ...note,
                                description: note.body
                            })
                            scrollTo()
                        } : null}
                        onDelete={permissionCheck('ACCOUNTS', 'UPDATE') ? () => setNoteToBeDeleted(note) : null}
                    />
                )) : Object.values(Array.isArray(notes) ? {} : notes).map(({ name, notes }) => (
                    <>
                        <div className={cn.contactName}>
                            {name}
                        </div>
                        {notes.map(note => (
                            <Note
                                key={note.id}
                                note={note}
                                onRemoveAttachment={removeNoteAttachment}
                                onClick={id => setSelectedNote(id)}
                                selected={note.id === selectedNote}
                                onEdit={permissionCheck('ACCOUNTS', 'UPDATE') ? () => {
                                    setNoteToBeEditted(note)
                                    setNoteData({
                                        ...note,
                                        description: note.body
                                    })
                                    scrollTo()
                                } : null}
                                onDelete={permissionCheck('ACCOUNTS', 'UPDATE') ? () => setNoteToBeDeleted(note) : null}
                            />
                        ))}
                    </>
                ))}
            </div>
            <div className={cn.noteDetails}>
                {(newNoteShown || noteToBeEditted) ? (
                    <NotesCRUDWrapper
                        accountId={id}
                        contacts={contacts}
                        noteData={newNoteShown ? noteData : noteToBeEditted}
                        // selectedNoteContact: passedSelectedNtoeContact,
                        parentType={type === NOTES_TYPES.ACCOUNT ? 'account' : 'contact'}
                        onSavedNewNote={() => setTimeout(loadNotes, 1000)}
                        getContactLabel={getContactLabel}
                        noteToBeEditted={noteToBeEditted}
                        onCancelNewNote={() => {
                            setNoteToBeEditted(null)
                            setNewNoteShown(null)
                            setSelectedNote(noteToBeEditted?.id || notes[0]?.id)
                        }}
                    />
                ) : (
                    <FocusedNote
                        note={searchibleNotes.find(el => el.id === selectedNote)}
                        onEdit={onEdit}
                    />
                )}
            </div>

            {noteToBeDeleted && (
                <Modal
                    onClose={() => setNoteToBeDeleted(null)}
                    header="Delete Note"
                    buttons={(
                        <Row spaceBetween fullWidth>
                            <Button
                                onClick={deleteNote}
                            >
                                Yes, Delete it
                            </Button>
                            <Button
                                onClick={() => setNoteToBeDeleted(null)}
                            >
                                No, Cancel
                            </Button>
                        </Row>
                    )}
                >
                    Are you sure you'd want to delete this note?
                </Modal>
            )}
        </div>
    )
}