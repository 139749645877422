import React, { useCallback, useEffect, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import debounce from 'lodash.debounce'
import { ViewContainer } from '../../../../components/ViewContainer'
import { Button } from '../../../../components/Button'
// import cn from './Integrations.module.css'
import { Row } from '../../../../components/Row'
import { Label } from '../../../../components/Label'
import { Input } from '../../../../components/Input'
import { NBSP_SIZES, Nbsp } from '../../../../components/Nbsp'
import { makeRequest } from '../../../../utilities/endpoints'
import { URLS } from '../../../../config'
import { Hr } from '../../../../components/Hr';
import { Checkbox } from '../../../../components/Checkbox';
import { Tabs } from '../../../../components/Tabs';
import { SeatsUsedConfig } from '../SeatsUsedConfig';
import { translateCRMLabel } from '../../../../utilities/translateCRMLabels';
import { SelectWrapperConnected } from '../../../../components/SelectWrapper';

export const Redshift = ({ products }) => {
    const [databaseName, setDatabaseName] = useState()
    const [workgroup, setWorkgroup] = useState()
    const [region, setRegion] = useState()
    const [accessKeyId, setAccessKeyId] = useState()
    const [accessKeySecret, setAccessKeySecret] = useState()

    const [selectedType, setSelectedType] = useState(0)

    const [tables, setTables] = useState([])
    const [fields, setFields] = useState({})
    const [useSameIds, setUseSameIds] = useState(false)
    const [selectedFields, setSelectedFields] = useState({})
    const [loadedInitialData, setLoadedInitialData] = useState(false)

    const getTables = () => {
        makeRequest.get(`${URLS.main}/integrations/views/REDSHIFT`)
            .then(res => {
                if (res && res.data) {
                    setTables(res.data)
                }
            })
    }

    useEffect(() => {
        if (!!tables.length) {
            getUsageSettings()
        }
    }, [tables])

    const getUsageSettings = () => {
        makeRequest.get(`${URLS.main}/integrations/settings/REDSHIFT`)
            .then(res => {
                if (res && res.data) {
                    const ids = [...new Set(res.data.map(el => el.viewId))]
                    ids.map(el => getFields(el))
                    setLoadedInitialData(res.data)
                }
            })
    }

    const getAuthenticationSettings = () => {
        makeRequest.get(`${URLS.main}/integrations/authenticationSettings/REDSHIFT`)
            .then(res => {
                if (res && res.data && res.data.databaseName && res.data.workgroup && res.data.accessKeyId && res.data.accessKeySecret && res.data.region) {
                    setDatabaseName(res.data.databaseName)
                    setWorkgroup(res.data.workgroup)
                    setAccessKeyId(res.data.accessKeyId)
                    setAccessKeySecret(res.data.accessKeySecret)
                    setRegion(res.data.region)
                    getTables()
                }
            })
    }

    const saveSettings = () => {
        makeRequest.post(`${URLS.main}/integrations`, {
            databaseName,
            workgroup,
            accessKeyId,
            accessKeySecret,
            region,
            type: 'REDSHIFT'
        })
            .then(() => {
                getTables()
            })
    }

    const getFields = (viewId, updateAll) => {
        const fieldsCopy = { ...fields }
        delete fieldsCopy[viewId]
        setFields(fieldsCopy)
        // makeRequest.get(`${URLS.main}/integrations/fields/TABLEAU/${viewId}`)
        if (updateAll) {
            const obj = {}
            products.forEach(el => {
                obj[el.Id] = tables.find(table => table.name === viewId)?.fields.map(el => ({
                    label: el,
                    value: el,
                }))
            })
            setFields(obj)
        } else {
            setFields({
                ...fields,
                [viewId]: tables.find(table => table.name === viewId)?.fields.map(el => ({
                    label: el,
                    value: el,
                }))
            })
        }
    }

    const saveUsageSettings = (productId, data) => {
        if (data && data[productId]) {
            const { field, view, account, thresholdGreen, thresholdRed } = data[productId]
            if (field && view && account && thresholdGreen && thresholdRed) {
                makeRequest.post(`${URLS.main}/integrations/settings`, {
                    productId: productId,
                    viewId: data[productId].view.value,
                    thresholdRed: data[productId].thresholdRed,
                    thresholdGreen: data[productId].thresholdGreen,
                    accountId: data[productId].account.value,
                    usageId: data[productId].field.value,
                    productIdColumn: data[productId].productIdColumn?.value,
                    timestampColumn: data[productId].timestampColumn?.value,
                    type: 'REDSHIFT'
                })
            }
        }
    }

    const checkFieldsForSaving = useCallback(
        debounce(saveUsageSettings, 250),
        []
    );

    useEffect(() => {
        getAuthenticationSettings()
    }, [])

    useEffect(() => {
        if (!!loadedInitialData && !!tables?.length && !!Object.keys(fields).length) {
            const obj = {}
            loadedInitialData.forEach(el => {
                obj[el.productId] = {
                    view: { label: el.viewId, value: el.viewId },
                    account: fields[el.viewId]?.find(field => field.value === el.accountId),
                    field: fields[el.viewId]?.find(field => field.value === el.usageId),
                    productIdColumn: fields[el.viewId]?.find(field => field.value === el.productIdColumn),
                    timestampColumn: fields[el.viewId]?.find(field => field.value === el.timestampColumn),
                    thresholdGreen: el.thresholdGreen,
                    thresholdRed: el.thresholdRed,
                }
            })
            setSelectedFields(obj)
        }
    }, [loadedInitialData, tables])

    return (
        <ViewContainer
            collapsible
            title={(
                <Row noMargin>
                    <div>
                        Redshift
                    </div>
                    {workgroup && databaseName && accessKeyId && accessKeySecret && (
                        <>
                            <Nbsp size={NBSP_SIZES.BIG} />
                            <FontAwesomeIcon icon={faCheckCircle} color='#3EB984' />
                        </>
                    )}
                </Row>
            )}
        >
            <Row spaceBetween>
                <Row noMargin>

                    <Label>Database</Label>
                    <Input fullWidth value={databaseName} onChange={e => setDatabaseName(e.target.value)} />
                </Row>
                <Nbsp />
                <Row noMargin>
                    <Label>Workgroup</Label>
                    <Input fullWidth value={workgroup} onChange={e => setWorkgroup(e.target.value)} />
                </Row>
                <Row noMargin>
                    <Label>Region</Label>
                    <Input fullWidth value={region} onChange={e => setRegion(e.target.value)} />
                </Row>
            </Row>
            <Row>
                <Row noMargin>
                    <Label>AccessKeyId</Label>
                    <Input fullWidth value={accessKeyId} onChange={e => setAccessKeyId(e.target.value)} />
                </Row>
                <Nbsp size={NBSP_SIZES.BIG} />
                <Row noMargin>
                    <Label>AccessKeySecret</Label>
                    <Input fullWidth value={accessKeySecret} type={'password'} onChange={e => setAccessKeySecret(e.target.value)} />
                </Row>
                <Nbsp />
                <Button
                    disabled={!databaseName || !workgroup || !accessKeySecret || !accessKeyId || !region}
                    onClick={saveSettings}
                >
                    Connect
                </Button>
            </Row>
            <Tabs
                selected={selectedType}
                options={[
                    'Everything on a single line',
                    'Broken down'
                ]}
                onChange={index => {
                    if (selectedType === 1 && index === 0) {
                        const obj = { ...selectedFields }
                        products.forEach(product => {
                            obj[product.Id] = {
                                ...obj[product.Id],
                                productIdColumn: null
                            }
                            saveUsageSettings(product.Id, obj)
                        })
                    }
                    setSelectedType(index)
                }}
            />
            {products.map((el, i) => (
                <>
                    <Label>
                        Product Settings
                    </Label>
                    <br />
                    <Hr />
                    <br />
                    <Label>
                        Settings for <b>{el.name || el.Name}</b>
                    </Label>
                    <Row>
                        <Label>
                            Table
                        </Label>

                        <SelectWrapperConnected
                            onChange={data => {
                                if (!fields[data.value]) {
                                    getFields(data.value, useSameIds && i === 0)
                                }
                                const obj = { ...selectedFields }
                                if (useSameIds && i === 0) {
                                    obj[el.Id] = {
                                        ...obj[el.Id],
                                        view: data
                                    }
                                    products.forEach(product => {
                                        obj[product.Id] = {
                                            ...obj[product.Id],
                                            view: data
                                        }
                                        checkFieldsForSaving(product.Id, obj)
                                    })
                                } else {
                                    obj[el.Id] = {
                                        ...obj[el.Id],
                                        view: data
                                    }
                                    checkFieldsForSaving(el.Id, obj)
                                }
                                setSelectedFields(obj)
                            }}
                            options={tables.map(el => ({
                                label: el.name,
                                value: el.name
                            }))}
                            value={selectedFields && selectedFields[el.Id] && selectedFields[el.Id].view}
                        />
                        <Nbsp />
                        <Label>{`Column indicating ${translateCRMLabel('Account')}'s Id`}</Label>
                        <SelectWrapperConnected
                            onChange={data => {
                                const obj = { ...selectedFields }
                                if (useSameIds && i === 0) {
                                    obj[el.Id] = {
                                        ...obj[el.Id],
                                        account: data
                                    }
                                    products.forEach(product => {
                                        obj[product.Id] = {
                                            ...obj[product.Id],
                                            account: data
                                        }
                                        checkFieldsForSaving(product.Id, obj)
                                    })
                                } else {
                                    obj[el.Id] = {
                                        ...obj[el.Id],
                                        account: data
                                    }
                                    checkFieldsForSaving(el.Id, obj)
                                }
                                setSelectedFields(obj)
                            }}
                            isDisabled={!fields || !fields[selectedFields[el.Id]?.view?.value]}
                            options={fields && fields[selectedFields[el.Id]?.view?.value]}
                            value={selectedFields && selectedFields[el.Id] && selectedFields[el.Id].account}
                        />
                        <Nbsp />
                        <Label>
                            Field Indicating Product's usage
                        </Label>
                        <SelectWrapperConnected
                            onChange={data => {
                                const obj = { ...selectedFields }
                                if (useSameIds && i === 0) {
                                    obj[el.Id] = {
                                        ...obj[el.Id],
                                        field: data
                                    }
                                    products.forEach(product => {
                                        obj[product.Id] = {
                                            ...obj[product.Id],
                                            field: data
                                        }
                                        checkFieldsForSaving(product.Id, obj)
                                    })
                                } else {
                                    obj[el.Id] = {
                                        ...obj[el.Id],
                                        field: data
                                    }
                                    checkFieldsForSaving(el.Id, obj)
                                }
                                setSelectedFields(obj)
                            }}
                            isDisabled={!fields || !fields[selectedFields[el.Id]?.view?.value]}
                            options={fields && fields[selectedFields[el.Id]?.view?.value]}
                            value={selectedFields && selectedFields[el.Id] && selectedFields[el.Id].field}
                        />

                        <Nbsp />
                        <Label>
                            Field Indicating Timestamp
                        </Label>

                        <SelectWrapperConnected
                            onChange={data => {
                                const obj = { ...selectedFields }
                                if (useSameIds && i === 0) {
                                    obj[el.Id] = {
                                        ...obj[el.Id],
                                        timestampColumn: data
                                    }
                                    products.forEach(product => {
                                        obj[product.Id] = {
                                            ...obj[product.Id],
                                            timestampColumn: data
                                        }
                                        checkFieldsForSaving(product.Id, obj)
                                    })
                                } else {
                                    obj[el.Id] = {
                                        ...obj[el.Id],
                                        timestampColumn: data
                                    }
                                    checkFieldsForSaving(el.Id, obj)
                                }
                                setSelectedFields(obj)
                            }}
                            isDisabled={!fields || !fields[selectedFields[el.Id]?.view?.value]}
                            options={fields && fields[selectedFields[el.Id]?.view?.value]}
                            value={selectedFields && selectedFields[el.Id] && selectedFields[el.Id].timestampColumn}
                        />

                        {selectedType === 1 && (
                            <>
                                <Nbsp />
                                <Label>
                                    Column Indicating Product's id
                                </Label>
                                <SelectWrapperConnected
                                    onChange={data => {
                                        const obj = { ...selectedFields }
                                        if (useSameIds && i === 0) {
                                            obj[el.Id] = {
                                                ...obj[el.Id],
                                                productIdColumn: data
                                            }
                                            products.forEach(product => {
                                                obj[product.Id] = {
                                                    ...obj[product.Id],
                                                    productIdColumn: data
                                                }
                                                checkFieldsForSaving(product.Id, obj)
                                            })
                                        } else {
                                            obj[el.Id] = {
                                                ...obj[el.Id],
                                                productIdColumn: data
                                            }
                                            checkFieldsForSaving(el.Id, obj)
                                        }
                                        setSelectedFields(obj)
                                    }}
                                    isDisabled={!fields || !fields[selectedFields[el.Id]?.view?.value]}
                                    options={fields && fields[selectedFields[el.Id]?.view?.value]}
                                    value={selectedFields && selectedFields[el.Id] && selectedFields[el.Id].productIdColumn}
                                />
                            </>
                        )}
                    </Row>
                    {i === 0 && (
                        <Checkbox
                            checked={useSameIds}
                            label='Use the same settings for the view, field id and account id for all products'
                            onClick={() => {
                                setUseSameIds(!useSameIds)
                                if (!useSameIds) {
                                    const obj = { ...selectedFields }
                                    products.forEach(product => {
                                        obj[product.Id] = {
                                            ...obj[product.Id],
                                            account: obj[products[0].Id]?.account,
                                            field: obj[products[0].Id]?.field,
                                            view: obj[products[0].Id]?.view,
                                            productIdColumn: obj[products[0].Id]?.productIdColumn,
                                            timestampColumn: obj[products[0].Id]?.timestampColumn,
                                        }
                                        fields[obj[product.Id]?.view] = fields[obj[products[0].Id]?.view]
                                        checkFieldsForSaving(product.Id, obj)
                                    })
                                    setSelectedFields(obj)
                                }
                            }}
                        />
                    )}
                    <br />
                    <Row>
                        <Label>
                            Threshold for Red - bellow
                        </Label>
                        <Input
                            type='number'
                            value={selectedFields && selectedFields[el.Id] && selectedFields[el.Id].thresholdRed}
                            onChange={e => {
                                const obj = {
                                    ...selectedFields,
                                    [el.Id]: {
                                        ...selectedFields[el.Id],
                                        thresholdRed: e.target.value
                                    }
                                }
                                setSelectedFields(obj)
                                checkFieldsForSaving(el.Id, obj)
                            }}
                        />
                        <Nbsp />
                        <Nbsp />
                        <Label>
                            Threshold for Green - above
                        </Label>
                        <Input
                            type='number'
                            value={selectedFields && selectedFields[el.Id] && selectedFields[el.Id].thresholdGreen}
                            onChange={e => {
                                const obj = {
                                    ...selectedFields,
                                    [el.Id]: {
                                        ...selectedFields[el.Id],
                                        thresholdGreen: e.target.value
                                    }
                                }
                                setSelectedFields(obj)
                                checkFieldsForSaving(el.Id, obj)
                            }}
                        />
                    </Row>
                    <Hr />
                    <br />
                </>
            ))}
            <SeatsUsedConfig tables={tables} type={'REDSHIFT'} />
        </ViewContainer>
    )
}