import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { faPen, faPlus, faTrash } from '@fortawesome/free-solid-svg-icons';
import { Modal } from '../../../../components/Modal';
import { Button, BUTTON_TYPES } from '../../../../components/Button'
import { Heading } from '../../../../components/Heading'
import { Row } from '../../../../components/Row'
import { Table } from '../../../../components/Table'
import { URLS } from '../../../../config'
import { makeRequest } from '../../../../utilities/endpoints'
import { CreateContactModal } from '../../account-mapping/components/CreateContactModal'
import cn from './Contacts.module.css'
import { SendEmailButton } from './SendEmailButton'
import { PermissionCheck } from '../../../../components/PermissionCheck';
import { workspaceTypeCheck } from '../../../../utilities/workspaceType';
import { translateCRMLabel } from '../../../../utilities/translateCRMLabels';
import { Nbsp } from '../../../../components/Nbsp';

const addedFieldsToBeRemoved = [
    'id',
    'firstName',
    'lastName',
    'email',
    'PhotoUrl',
];

export const Contacts = ({ contacts, accountId, onContactAdded = () => {}, contactId }) => {
    const history = useHistory()
    const [showAddContactModal, setShowAddContactModal] = useState(false)
    const [removeShowConfirmOfContact, setRemoveShowConfirmOfContact] = useState(false)
    const [contactForEdit, setContactForEdit] = useState(false)
    const [contactModalData, setContactModalData] = useState({})
    const [requestSent, setRequestSent] = useState(false)
    const [customContactFields, setCustomContactFields] = useState([])

    const [contactCreateOrUpdateError, setContactCreateOrUpdateError] = useState(null)

    const getCustomContactFields = () => {
        makeRequest.get(`${URLS.main}/describe/Contact`)
            .then(res => {
                setCustomContactFields(res.data.filter(el => el.value?.includes('__c') || el.custom))
            })
    }

    const createContact = () => {
        const copiedForSubmission = { ...contactModalData }
        addedFieldsToBeRemoved.forEach(el => delete copiedForSubmission[el])

        setRequestSent(true)
        // setShowLoader(true)
        makeRequest.post(`${URLS.main}/contacts`, {
            ...copiedForSubmission,
            AccountId: accountId,
            FirstName: contactModalData.firstName || '',
            LastName: contactModalData.lastName || '',
            Email: contactModalData.email || '',
        })
            .then(() => {
                resetContactAdding()
                onContactAdded()
                setContactCreateOrUpdateError(null)
            })
            .catch(({ response }) => {
                setContactCreateOrUpdateError(response.data)
                setRequestSent(false)
            })
    }

    const updateContact = () => {
        const copiedForSubmission = { ...contactModalData }
        addedFieldsToBeRemoved.forEach(el => delete copiedForSubmission[el])
        Object.keys(copiedForSubmission).forEach(key => {
            if (copiedForSubmission[key] === null) {
                delete copiedForSubmission[key]
            }
        })

        setRequestSent(true)
        // setShowLoader(true)
        const data = {
            ...copiedForSubmission,
            AccountId: accountId,
            FirstName: contactModalData.firstName || '',
            LastName: contactModalData.lastName || '',
            Email: contactModalData.email || '',
            Title: contactModalData.title,
        }

        if (workspaceTypeCheck(['CALLYPSO'])) {
            data.title = data.Title
        }
        makeRequest.put(`${URLS.main}/contacts`, data)
            .then(() => {
                resetContactAdding()
                onContactAdded()
            })
            .catch(({ response }) => {
                setContactCreateOrUpdateError(response.data)
                setRequestSent(false)
            })
    }

    const resetContactAdding = () => {
        setRequestSent(false)
        setContactForEdit(null)
        setContactModalData({})
        setShowAddContactModal(false)
        setContactCreateOrUpdateError(null)
    }

    const deleteContact = (contactId) => {
        setRemoveShowConfirmOfContact(false)
        makeRequest.delete(`${URLS.main}/contacts/${contactId}`)
            .then(() => {
                onContactAdded()
            })
            .catch(e => {
                if (e?.response?.status === 404) {
                    switch (e?.response?.data?.errorCode) {
                    case 'ENTITY_IS_DELETED':
                        onContactAdded();
                        break;
                    default:
                        break;
                    }
                }
            });
    }

    useEffect(() => {
        if ((showAddContactModal || contactForEdit)) {
            if (!customContactFields.length) {
                getCustomContactFields()
            }
        }
    }, [showAddContactModal, contactForEdit], customContactFields)

    const startEdit = contactId => {
        // eslint-disable-next-line eqeqeq
        const contact = contacts.find(el => el.Id == contactId)
        setContactModalData({
            firstName: contact?.FirstName ? contact?.FirstName : '',
            lastName: contact?.LastName ? contact?.LastName : '',
            title: contact?.Title,
            email: contact?.Email,
            ...contact
        })
        setContactForEdit(contactId)
    }

    useEffect(() => {
        if (contactId && contacts.length) {
            startEdit(contactId)
        }
    }, [contactId, contacts])

    return (
        <div className={cn.contacts}>
            {(showAddContactModal || contactForEdit) && (
                <CreateContactModal
                    edit={!!contactForEdit}
                    error={contactCreateOrUpdateError}
                    contactModalData={contactModalData}
                    customContactFields={customContactFields}
                    onChange={data => {
                        setContactModalData({
                            ...contactModalData,
                            ...data,
                        })
                    }}
                    disableButtons={requestSent}
                    onClose={resetContactAdding}
                    onSave={contactForEdit ? updateContact : createContact}
                />
            )}
            <div className={cn.contactsHeadingWrapper}>
                <Heading noOffset>Contacts</Heading>
                <Row className={cn.oppButtons}>
                    <Button
                        onClick={() => {
                            history.push(`/account/${accountId}/map`)
                        }}
                        type={BUTTON_TYPES.TEXT}
                    >
                        {`${translateCRMLabel('Account')} Map`}
                    </Button>
                    <Nbsp />
                    <PermissionCheck requiredPermissions={[['ACCOUNT_CONTACTS', 'CREATE']]}>
                        <Button
                            onClick={() => setShowAddContactModal(true)}
                            type={BUTTON_TYPES.ICON_AND_TEXT}
                            icon={faPlus}
                        >
                            Add New
                        </Button>
                    </PermissionCheck>
                </Row>
            </div>
            <div className={cn.scrollableParent}>
                <div className={cn.scroll}>
                    <Table
                        sticky={false}
                        borderless
                        headers={[
                            'Name',
                            'Title',
                            'Phone',
                            'Actions'
                        ]}
                        customStyles={{
                            2: {
                                width: 200
                            },
                            3: {
                                width: 100,
                            }
                        }}
                        data={
                            contacts.map(el => [
                                `${el.FirstName ? `${el.FirstName} ` : ''}${el.LastName || ''}`,
                                el.Title,
                                el.Phone || 'N/A',
                                <Row noMargin>
                                    <PermissionCheck requiredPermissions={[['ACCOUNT_CONTACTS', 'UPDATE']]}>
                                        <>
                                            <Button
                                                className={'green'}
                                                icon={faPen}
                                                onClick={() => startEdit(el.Id)}
                                                type={BUTTON_TYPES.SMALL_ICON}
                                            />
                                            <Button
                                                className={'red'}
                                                icon={faTrash}
                                                onClick={() => setRemoveShowConfirmOfContact(el.Id)}
                                                type={BUTTON_TYPES.SMALL_ICON}
                                            />
                                        </>
                                    </PermissionCheck>
                                    <SendEmailButton
                                        email={el.Email}
                                    />
                                </Row>
                            ])
                        }
                    />
                </div>
            </div>
            {removeShowConfirmOfContact && (
                <Modal
                    onClose={() => setRemoveShowConfirmOfContact(false)}
                    header="Delete Contact"
                    buttons={(
                        <Row spaceBetween fullWidth>
                            <Button
                                onClick={() => deleteContact(removeShowConfirmOfContact)}
                            >
                                Yes, Delete it
                            </Button>
                            <Button
                                onClick={() => setRemoveShowConfirmOfContact(false)}
                            >
                                No, Cancel
                            </Button>
                        </Row>
                    )}
                >
                    Are you sure you'd want to delete this Contact from your Sales Force instance?
                    <br /><br />
                    This action might be irrevirsible! Please consider it carefully.
                </Modal>
            )}
        </div>
    )
}

export const mapStateToProps = (state, ownProps) => {
    return {
        ...state,
        ...ownProps
    }
}

export const ContactsConnected = connect(mapStateToProps)(Contacts)
