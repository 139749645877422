import React, { useState } from 'react'
import moment from 'moment';
import { Modal } from '../../components/Modal';
import { CalendarInput } from '../../components/CalendarInput';
import { TextArea } from '../../components/TextArea';
import { Input } from '../../components/Input';
import { Dropdown } from '../../components/Dropdown';
import { Button } from '../../components/Button';
import cn from './AddTask.module.css'

export const AddTask = ({ onClose, disabledButton, onCreate, availableAccounts, predefinedData }) => {
    const [newTaskData, setNewTaskData] = useState(predefinedData ? { ...predefinedData } : {})

    return (
        <Modal
            onClose={onClose}
            header="Add Play"
            buttons={<Button fullWidth disabled={disabledButton} onClick={() => onCreate(newTaskData)} testId={'ADD_NEW_TASK'}>ADD NEW PLAY</Button>}
        >
            <div className={cn.taskInputsWrapper}>
                <Input
                    placeholder="Enter Play Name"
                    onChange={e => setNewTaskData({ ...newTaskData, name: e.target.value })}
                    fullWidth
                    testId={'NAME_INPUT'}
                />
                <TextArea
                    placeholder="Enter Play Description"
                    onChange={e => setNewTaskData({ ...newTaskData, description: e.target.value })}
                    testId={'DESCRIPTION_INPUT'}
                />
                {/* <Input placeholder="Choose type Of Action" onChange={e => setNewTaskData({...newTaskData, action: e.target.value})} /> */}
                <CalendarInput
                    value={moment(newTaskData.dueDate || new Date()).format('MMMM D, YYYY')}
                    placeholder="Select Date"
                    onChange={e => {
                        const now = new Date();
                        setNewTaskData({
                            ...newTaskData,
                            dueDate: moment(e).hours(now.getHours()).minutes(now.getMinutes()).seconds(now.getSeconds())
                                .valueOf()
                        })
                    }}
                />
                <Dropdown
                    testId={`ACCOUNT_DROPDOWN`}
                    values={availableAccounts.map(el => ({
                        label: el.name,
                        value: el.id
                    }))}
                    fullWidth
                    value={newTaskData?.accountId}
                    onChange={e => setNewTaskData({ ...newTaskData, accountId: e.target.value })}
                />
            </div>
        </Modal>
    )
}