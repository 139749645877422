import { randHash } from "../utilities/random"
import {
    setUserType,
    systemMessageType,
    enableBackButtonType,
    addGlobalErrorType,
    removeGlobalErrorType,
    setStartOfSearchType,
    addNotificationType,
    removeNotificationType,
    setPageAfterType,
    changeColorSchemeType,
    changeCustomRouteLabelType,
    setHomeFilterDataType,
} from "./actions"

const rootReducer = (state = {
    user: null,
    filterByUserId: undefined,
    enabledBackButton: false,
    systemMessage: null,
    accountName: null,
    errors: [],
    notifications: [],
    colorScheme: 'LIGHT',
}, action) => {
    switch (action.type) {
    case setUserType:
        if (state.filterByUserId === undefined) {
            return {
                ...state,
                user: action.user,
                filterByUserId: action.user?.id
            }
        }
        return {
            ...state,
            user: action.user
        }
    case enableBackButtonType:
        return {
            ...state,
            enableBackButton: action.enabled
        }
    case systemMessageType:
        return {
            ...state,
            systemMessage: action.systemMessage
        }
    case addGlobalErrorType:
        return {
            ...state,
            errors: [...state.errors, action.newError]
        }
    case removeGlobalErrorType:
        const newErrors = state.errors.slice(0)
        newErrors.splice(action.errorIndex, 1)
        return {
            ...state,
            errors: newErrors
        }
    case setStartOfSearchType: {
        return {
            ...state,
            startUrl: action.startUrl
        }
    }
    case addNotificationType:
        return {
            ...state,
            notifications: [
                ...state.notifications,
                {
                    id: randHash(),
                    ...action.notification
                }
            ]
        }
    case removeNotificationType:
        return {
            ...state,
            notifications: state.notifications.filter(el => el.id !== action.notificationId)
        }
    case setPageAfterType:
        console.info('settingPageAfter', state.pageAfter, action.pageAfter)
        return {
            ...state,
            pageAfter: action.pageAfter
        }
    case changeColorSchemeType:
        return {
            ...state,
            colorScheme: action.colorScheme
        }
    case changeCustomRouteLabelType:
        return {
            ...state,
            customPathLabel: action.customPathLabel
        }
    case setHomeFilterDataType:
        return {
            ...state,
            filterByUserId: action.filterByUserId
        }
    default:
        break
    }
    return state
}

export default rootReducer
