import React, { useState } from 'react'
import { faPen, faTrashAlt } from '@fortawesome/free-solid-svg-icons'
import { PermissionCheck } from './PermissionCheck'
import { Row } from './Row'
import { BUTTON_TYPES, Button } from './Button'
import { Modal } from './Modal'
import { OpportunitiesCRUDModalWrapper } from './OpportunitiesCRUDModalWrapper'
import { makeRequest } from '../utilities/endpoints'
import { URLS } from '../config'
import { translateCRMLabel } from '../utilities/translateCRMLabels'
import { Nbsp } from './Nbsp'

export const OpportunityActions = ({ onEdit, onRemove, opportunity }) => {
    const [opportunityToRemove, setOpportunityToRemove] = useState(null)
    const [opportunityToEdit, setOpportunityToEdit] = useState(null)

    const deleteOpportunity = () => {
        makeRequest.delete(`${URLS.main}/opportunities/${opportunityToRemove}`)
            .then(() => {
                onRemove()
            }).catch(e => {
                if (e?.response?.status === 404) {
                    switch (e?.response?.data?.errorCode) {
                    case 'ENTITY_IS_DELETED':
                        onRemove();
                        break;
                    default:
                        break;
                    }
                }
            });
        setOpportunityToRemove(null)
    }

    return (
        <>
            <PermissionCheck requiredPermissions={[['OPPORTUNITIES', 'UPDATE']]}>
                <Row noMargin fullWidth>
                    <Button
                        data-tooltip-id='default'
                        data-tooltip-content='Delete'
                        className={'red'}
                        type={BUTTON_TYPES.SMALL_ICON}
                        onClick={() => setOpportunityToRemove(opportunity.id)}
                        icon={faTrashAlt}
                    />
                    <Nbsp />
                    <Button
                        data-tooltip-id='default'
                        data-tooltip-content='Edit'
                        className={'green'}
                        type={BUTTON_TYPES.SMALL_ICON}
                        onClick={() => setOpportunityToEdit(opportunity.id)}
                        icon={faPen}
                    />
                </Row>
            </PermissionCheck>
            {opportunityToRemove && (
                <Modal
                    onClose={() => setOpportunityToRemove(null)}
                    header={`Delete ${translateCRMLabel('Opportunity')}`}
                    buttons={(
                        <Row spaceBetween fullWidth>
                            <Button
                                onClick={deleteOpportunity}
                            >
                                Yes, Delete it
                            </Button>
                            <Button
                                onClick={() => setOpportunityToRemove(null)}
                            >
                                No, Cancel
                            </Button>
                        </Row>
                    )}
                >
                    Are you sure you'd want to delete this {translateCRMLabel('Opportunity')} from your CRM?
                    <br /><br />
                    This action might be irrevirsible! Please consider it carefully.
                </Modal>
            )}
            <OpportunitiesCRUDModalWrapper
                shown={!!opportunityToEdit}
                edit={true}
                opportunityId={opportunityToEdit}
                editCallback={() => {
                    setOpportunityToEdit(null)
                    onEdit()
                }}
                onClose={() => setOpportunityToEdit(null)}
                header={`Edit ${opportunity.name}`}
            />
        </>
    )
}