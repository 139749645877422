import React, { useCallback, useEffect, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import debounce from 'lodash.debounce'
import { ViewContainer } from '../../../../components/ViewContainer'
import { Button } from '../../../../components/Button'
// import cn from './Integrations.module.css'
import { Row } from '../../../../components/Row'
import { Label } from '../../../../components/Label'
import { Input } from '../../../../components/Input'
import { NBSP_SIZES, Nbsp } from '../../../../components/Nbsp'
import { makeRequest } from '../../../../utilities/endpoints'
import { URLS } from '../../../../config'
import { Hr } from '../../../../components/Hr';
import { Checkbox } from '../../../../components/Checkbox';
import { SeatsUsedConfig } from '../SeatsUsedConfig';
import { translateCRMLabel } from '../../../../utilities/translateCRMLabels';
import { SelectWrapperConnected } from '../../../../components/SelectWrapper';

export const Tableau = ({ products }) => {
    const [patName, setPatName] = useState()
    const [patSecret, setPatSecret] = useState()
    const [site, setSite] = useState()
    const [views, setViews] = useState([])
    const [fields, setFields] = useState({})
    const [useSameIds, setUseSameIds] = useState(false)
    const [selectedFields, setSelectedFields] = useState({})
    const [loadedInitialData, setLoadedInitialData] = useState(false)

    const getViews = () => {
        makeRequest.get(`${URLS.main}/integrations/views/TABLEAU`)
            .then(res => {
                if (res && res.data) {
                    setViews(res.data)
                    getUsageSettings()
                }
            })
    }

    const getUsageSettings = () => {
        makeRequest.get(`${URLS.main}/integrations/settings/TABLEAU`)
            .then(res => {
                if (res && res.data) {
                    const ids = [...new Set(res.data.map(el => el.viewId))]
                    Promise.all(ids.map(el => getFields(el)))
                        .then(() => {
                            setLoadedInitialData(res.data)
                        })
                }
            })
    }

    const getAuthenticationSettings = () => {
        makeRequest.get(`${URLS.main}/integrations/authenticationSettings/TABLEAU`)
            .then(res => {
                if (res && res.data && res.data.patName && res.data.patSecret && res.data.site) {
                    setPatName(res.data.patName)
                    setPatSecret(res.data.patSecret)
                    setSite(res.data.site)
                    getViews()
                }
            })
    }

    const saveSettings = () => {
        makeRequest.post(`${URLS.main}/integrations`, {
            patSecret: patSecret,
            patName: patName,
            site: site,
            type: 'TABLEAU'
        })
            .then(() => {
                getViews()
            })
    }

    const getFields = (viewId, updateAll) => {
        return new Promise((resolve, reject) => {
            const fieldsCopy = { ...fields }
            delete fieldsCopy[viewId]
            setFields(fieldsCopy)
            makeRequest.get(`${URLS.main}/integrations/fields/TABLEAU/${viewId}`)
                .then(res => {
                    if (res && res.data) {
                        if (updateAll) {
                            const obj = {}
                            products.forEach(el => {
                                obj[el.Id] = res.data
                            })
                            setFields(obj)
                        } else {
                            setFields({
                                ...fields,
                                [viewId]: res.data
                            })
                        }
                    }
                    resolve()
                })
                .catch(reject)
        })
    }

    const checkFieldsForSaving = useCallback(
        debounce((productId, data) => {
            if (data && data[productId]) {
                const { field, view, account, thresholdGreen, thresholdRed } = data[productId]
                if (field && view && account && thresholdGreen && thresholdRed) {
                    makeRequest.post(`${URLS.main}/integrations/settings`, {
                        productId: productId,
                        viewId: data[productId].view.value,
                        thresholdRed: data[productId].thresholdRed,
                        thresholdGreen: data[productId].thresholdGreen,
                        accountId: data[productId].account.value,
                        usageId: data[productId].field.value,
                        timestampColumn: data[productId].timestampColumn?.value,
                        type: 'TABLEAU'
                    })
                }
            }
        }, 250),
        []
    );

    useEffect(() => {
        getAuthenticationSettings()
    }, [])

    useEffect(() => {
        if (!!loadedInitialData) {
            const obj = {}
            loadedInitialData.forEach(el => {
                obj[el.productId] = {
                    view: views.find(view => view.value === el.viewId),
                    account: fields[el.viewId].find(field => field.value === el.accountId),
                    timestampColumn: fields[el.viewId].find(field => field.value === el.timestampColumn),
                    field: fields[el.viewId].find(field => field.value === el.usageId),
                    thresholdGreen: el.thresholdGreen,
                    thresholdRed: el.thresholdRed,
                }
            })
            setSelectedFields(obj)
        }
    }, [loadedInitialData])

    return (
        <ViewContainer
            collapsible
            title={(
                <Row noMargin>
                    <div>
                        Tableau
                    </div>
                    {patName && patSecret && site && (
                        <>
                            <Nbsp size={NBSP_SIZES.BIG} />
                            <FontAwesomeIcon icon={faCheckCircle} color='#3EB984' />
                        </>
                    )}
                </Row>
            )}
        >
            <Row spaceBetween>
                <Row noMargin>
                    <Label>PAT Name</Label>
                    <Input fullWidth value={patName} onChange={e => setPatName(e.target.value)} />
                </Row>
                <Nbsp />
                <Row noMargin>
                    <Label>PAT Secret</Label>
                    <Input fullWidth value={patSecret} type={'password'} onChange={e => setPatSecret(e.target.value)} />
                </Row>
                <Nbsp />
                <Row noMargin>
                    <Label>Site</Label>
                    <Input fullWidth value={site} onChange={e => setSite(e.target.value)} />
                </Row>
                <Nbsp />
                <Button
                    disabled={!patName || !patSecret || !site}
                    onClick={saveSettings}
                >
                    Connect
                </Button>
            </Row>
            {products.map((el, i) => (
                <>
                    <Label>
                        Product Settings
                    </Label>
                    <br />
                    <Hr />
                    <br />
                    <Label>
                        Settings for <b>{el.name || el.Name}</b>
                    </Label>
                    <Row>
                        <Label>
                            View
                        </Label>

                        <SelectWrapperConnected
                            onChange={data => {
                                if (!fields[data.value]) {
                                    getFields(data.value, useSameIds && i === 0)
                                }
                                const obj = { ...selectedFields }
                                if (useSameIds && i === 0) {
                                    obj[el.Id] = {
                                        ...obj[el.Id],
                                        view: data
                                    }
                                    products.forEach(product => {
                                        obj[product.Id] = {
                                            ...obj[product.Id],
                                            view: data
                                        }
                                        checkFieldsForSaving(product.Id, obj)
                                    })
                                } else {
                                    obj[el.Id] = {
                                        ...obj[el.Id],
                                        view: data
                                    }
                                    checkFieldsForSaving(el.Id, obj)
                                }
                                setSelectedFields(obj)
                            }}
                            options={views}
                            value={selectedFields && selectedFields[el.Id] && selectedFields[el.Id].view}
                        />
                        <Nbsp />
                        <Label>{`Field indicating ${translateCRMLabel('Account')}'s Id`}</Label>
                        <SelectWrapperConnected
                            onChange={data => {
                                const obj = { ...selectedFields }
                                if (useSameIds && i === 0) {
                                    obj[el.Id] = {
                                        ...obj[el.Id],
                                        account: data
                                    }
                                    products.forEach(product => {
                                        obj[product.Id] = {
                                            ...obj[product.Id],
                                            account: data
                                        }
                                        checkFieldsForSaving(product.Id, obj)
                                    })
                                } else {
                                    obj[el.Id] = {
                                        ...obj[el.Id],
                                        account: data
                                    }
                                    checkFieldsForSaving(el.Id, obj)
                                }
                                setSelectedFields(obj)
                            }}
                            isDisabled={!fields || !fields[selectedFields[el.Id]?.view?.value]}
                            options={fields && fields[selectedFields[el.Id]?.view?.value]}
                            value={selectedFields && selectedFields[el.Id] && selectedFields[el.Id].account}
                        />
                        <Nbsp />
                        <Label>
                            Field Indicating Product's usage
                        </Label>
                        <SelectWrapperConnected
                            onChange={data => {
                                const obj = { ...selectedFields }
                                if (useSameIds && i === 0) {
                                    obj[el.Id] = {
                                        ...obj[el.Id],
                                        field: data
                                    }
                                    products.forEach(product => {
                                        obj[product.Id] = {
                                            ...obj[product.Id],
                                            field: data
                                        }
                                        checkFieldsForSaving(product.Id, obj)
                                    })
                                } else {
                                    obj[el.Id] = {
                                        ...obj[el.Id],
                                        field: data
                                    }
                                    checkFieldsForSaving(el.Id, obj)
                                }
                                setSelectedFields(obj)
                            }}
                            isDisabled={!fields || !fields[selectedFields[el.Id]?.view?.value]}
                            options={fields && fields[selectedFields[el.Id]?.view?.value]}
                            value={selectedFields && selectedFields[el.Id] && selectedFields[el.Id].field}
                        />
                        <Nbsp />
                        <Label>
                            Field Indicating Timestamp
                        </Label>
                        <SelectWrapperConnected
                            onChange={data => {
                                const obj = { ...selectedFields }
                                if (useSameIds && i === 0) {
                                    obj[el.Id] = {
                                        ...obj[el.Id],
                                        timestampColumn: data
                                    }
                                    products.forEach(product => {
                                        obj[product.Id] = {
                                            ...obj[product.Id],
                                            timestampColumn: data
                                        }
                                        checkFieldsForSaving(product.Id, obj)
                                    })
                                } else {
                                    obj[el.Id] = {
                                        ...obj[el.Id],
                                        timestampColumn: data
                                    }
                                    checkFieldsForSaving(el.Id, obj)
                                }
                                setSelectedFields(obj)
                            }}
                            isDisabled={!fields || !fields[selectedFields[el.Id]?.view?.value]}
                            options={fields && fields[selectedFields[el.Id]?.view?.value]}
                            value={selectedFields && selectedFields[el.Id] && selectedFields[el.Id].timestampColumn}
                        />
                    </Row>
                    {i === 0 && (
                        <Checkbox
                            checked={useSameIds}
                            label='Use the same settings for the view, field id and account id for all products'
                            onClick={() => {
                                setUseSameIds(!useSameIds)
                                if (!useSameIds) {
                                    const obj = { ...selectedFields }
                                    products.forEach(product => {
                                        obj[product.Id] = {
                                            ...obj[product.Id],
                                            account: obj[products[0].Id]?.account,
                                            field: obj[products[0].Id]?.field,
                                            view: obj[products[0].Id]?.view,
                                            timestampColumn: obj[products[0].Id]?.timestampColumn,
                                        }
                                        fields[obj[product.Id]?.view] = fields[obj[products[0].Id]?.view]
                                        checkFieldsForSaving(product.Id, obj)
                                    })
                                    setSelectedFields(obj)
                                }
                            }}
                        />
                    )}
                    <br />
                    <Row>
                        <Label>
                            Threshold for Red - bellow
                        </Label>
                        <Input
                            type='number'
                            value={selectedFields && selectedFields[el.Id] && selectedFields[el.Id].thresholdRed}
                            onChange={e => {
                                const obj = {
                                    ...selectedFields,
                                    [el.Id]: {
                                        ...selectedFields[el.Id],
                                        thresholdRed: e.target.value
                                    }
                                }
                                setSelectedFields(obj)
                                checkFieldsForSaving(el.Id, obj)
                            }}
                        />
                        <Nbsp />
                        <Nbsp />
                        <Label>
                            Threshold for Green - above
                        </Label>
                        <Input
                            type='number'
                            value={selectedFields && selectedFields[el.Id] && selectedFields[el.Id].thresholdGreen}
                            onChange={e => {
                                const obj = {
                                    ...selectedFields,
                                    [el.Id]: {
                                        ...selectedFields[el.Id],
                                        thresholdGreen: e.target.value
                                    }
                                }
                                setSelectedFields(obj)
                                checkFieldsForSaving(el.Id, obj)
                            }}
                        />
                    </Row>
                    <Hr />
                    <br />
                </>
            ))}
            <SeatsUsedConfig tables={views} type={'TABLEAU'} />
        </ViewContainer>
    )
}