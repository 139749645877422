import { connect } from 'react-redux'

const PermissionCheckComponent = ({ user, children, requiredPermissions }) => {
    const permissions = user?.permissions || {}
    let found = false;

    if (!requiredPermissions) {
        return children
    }

    requiredPermissions.forEach(permission => {
        const [feature, level] = permission

        if (permissions[feature]?.includes(level)) {
            found = true
        }
    })

    if (found) {
        return children
    }
    return null
}


export const mapStateToProps = (state, ownProps) => {
    return {
        user: state.user,
        ...ownProps
    }
}

export const PermissionCheck = connect(mapStateToProps)(PermissionCheckComponent)
