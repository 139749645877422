import React from 'react'
import moment from 'moment'
import { faEdit, faShareAlt, faTrashAlt } from '@fortawesome/free-solid-svg-icons'
import cn from './Note.module.css'
import { SlateEditor } from '../SlateEditor'
import store from '../../store'
import { Row } from '../Row'
import { addNotification } from '../../store/actions'
import { BUTTON_TYPES, Button } from '../Button'

export const Note = ({
    note,
    onEdit,
    onDelete,
    scrollRef,
    accountId,
    onClick,
    selected,
}) => {
    const daysSince = moment().diff(moment(note.created), 'days')
    const daysSinceFormatted = daysSince === 0 ? 'Today' : daysSince === 1 ? 'Yesterday' : `${daysSince} days ago`

    const share = () => {
        try {
            navigator.clipboard.writeText(`${window.location.protocol}//${window.location.host}/account/${accountId}/notes/${note?.id}`)
            store.dispatch(addNotification({
                message: 'URL to the note has been copied.',
            }))
        } catch (e) {
            store.dispatch(addNotification({
                message: 'There was an issue copying the url.',
            }))
        }
    }

    return (
        <div
            className={`${cn.noteWrapper} ${selected ? cn.selected : ''}`}
            ref={scrollRef}
            onClick={() => onClick && onClick(note.id)}
        >
            <div className={cn.noteHeader}>
                {!!note.title ? <div className={cn.noteTitle}>{note.title}</div> : <div />}
                <div className={cn.noteCreatedSince}>Last Edit {daysSinceFormatted}</div>
            </div>
            {note.rich ? (
                <SlateEditor
                    fullWidth
                    viewOnly
                    smallFont
                    noMinHeight
                    className={cn.noteBody}
                    value={note.body}
                />
            ) : (
                <div className={cn.noteBody}>{note.body}</div>
            )}
            <Row noMargin spaceBetween className={cn.bottomPart}>
                <div className={cn.noteCreator}>{note.ownerName}</div>
                <Row noMargin>
                    {onDelete && (
                        <Button
                            type={BUTTON_TYPES.SMALL_ICON}
                            icon={faTrashAlt}
                            className={cn.actionButton}
                            onClick={onDelete}
                        />
                    )}
                    {onEdit && (
                        <Button
                            type={BUTTON_TYPES.SMALL_ICON}
                            icon={faEdit}
                            className={cn.actionButton}
                            onClick={onEdit}
                        />
                    )}
                    <Button
                        type={BUTTON_TYPES.SMALL_ICON}
                        icon={faShareAlt}
                        className={cn.actionButton}
                        onClick={share}
                    />
                </Row>
            </Row>
        </div>
    )
}
