import React, { useEffect, useRef, useState } from 'react';
import cn from './MoneyStatistic.module.css';
import { formatLargeSums } from '../../../utilities/currency';
import { StatsSummaryRow } from '../../../components/StatsSummaryRow';

/* taken from the module.css file */
const startingFontSize = 36
const rightSideBuffer = 18
let totalResizes = 0

export const MoneyStatistic = ({ label, value = '', oldPeriodLabel, oldValue = '', skipFortmatting, onClick }) => {
    const textWrapperRef = useRef(null)
    const [oldPropValue, setOldPropValue] = useState()

    useEffect(() => {
        if (oldPropValue !== value) {
            setOldPropValue(value)
            resizeToFit(startingFontSize)
        }
    }, [value, oldPropValue, textWrapperRef?.current])

    const resizeToFit = (currentSize) => {
        if (textWrapperRef?.current) {
            if (textWrapperRef.current.parentNode.getBoundingClientRect().width < textWrapperRef.current.scrollWidth + rightSideBuffer) {
                if (totalResizes++ < 100) {
                    textWrapperRef.current.style.fontSize = `${currentSize - 1}px`
                    resizeToFit(currentSize - 1)
                }
            }
        }
    }

    return (

        <div className={cn.moneyStatistic} onClick={onClick}>
            <div className={cn.label}>
                {label}
            </div>
            <div className={cn.grayWrapper}>
                <div className={cn.value} ref={textWrapperRef}>
                    {skipFortmatting ? value : formatLargeSums('$', value)}
                </div>
                <StatsSummaryRow
                    oldValue={oldValue}
                    value={value}
                    skipFortmatting={skipFortmatting}
                    oldPeriodLabel={oldPeriodLabel}
                />
            </div>
        </div>
    )
}
