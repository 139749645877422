import React, { useState } from 'react'
import cn from './Input.module.css'

export const INPUT_SIZES = {
    DEFAULT: 0,
    SMALLER: 1,
}

const getSize = size => {
    switch (size) {
    case INPUT_SIZES.SMALLER:
        return cn.smaller
    default:
        return cn.defaultSize
    }
}

export const Input = ({
    size,
    icon,
    disabled,
    fullWidth,
    wrapStyle,
    inputStyle,
    className,
    rightIcon,
    testId,
    ...rest
}) => {
    const [focused, setFocused] = useState(false)
    return (
        <div
            className={
                `${cn.inputWrapper}
                ${fullWidth ? cn.fullWidth : ''}
                ${getSize(size)}
                ${icon && cn.hasIcon}
                ${rightIcon && cn.hasRightIcon}
                ${focused ? cn.focused : ''}
                ${disabled ? cn.disabled : ''}
                ${className}`
            }
            style={wrapStyle}
        >
            {icon && <div className={cn.icon}>{icon}</div>}
            <input
                onBlur={() => setFocused(false)}
                onFocus={() => setFocused(true)}
                className={`${cn.input} ${disabled ? cn.disabled : ''}`}
                style={inputStyle}
                data-test-id={testId || `INPUT`}
                {...rest}
            />
            {rightIcon && <div className={cn.rightIcon}>{rightIcon}</div>}
        </div>
    )
}
