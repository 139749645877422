import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch } from '@fortawesome/free-solid-svg-icons'
import cn from './TableSearchInput.module.css'
import { Input } from './Input'


export const TableSearchInput = ({ placeHolder='', className, filters=null, filterCallBack=null, testId, skipMargin }) => {
    return (
        <div className={`${cn.dataTableSearch} ${className} ${skipMargin ? cn.skipMargin : ''}`}>
            <Input
                placeholder={placeHolder}
                testId={testId}
                value={filters||''}
                onChange={(e) => { filterCallBack(e.target.value); }}
                icon={<FontAwesomeIcon icon={faSearch} />}
            />
        </div>
    )
}
