import { connect } from 'react-redux'

const WorkspaceTypeCheckComponent = ({ user, children, requiredTypes, requiredType }) => {
    if ((!requiredType && !requiredTypes) || user?.workspaceType === requiredType || requiredTypes?.includes(user.workspaceType)) {
        return children
    }
    return null
}


export const mapStateToProps = (state, ownProps) => {
    return {
        user: state.user,
        ...ownProps
    }
}

export const WorkspaceTypeCheck = connect(mapStateToProps)(WorkspaceTypeCheckComponent)
