/* eslint eqeqeq: 0 */
import React, { useRef, useState, useEffect, useImperativeHandle, forwardRef } from 'react'
import debounce from 'lodash.debounce'
import cn from './Grid.module.css'
import { ContactConnected } from './Contact';

const ZOOM_SCALE_STEP = 0.01;
const LINE_WIDTH = 2;
const CELL_WIDTH = 254;
const CELL_HEIGHT = 143;
const CELL_TOP_OFFSET = 82;
const CELL_LEFT_OFFSET = 50;

let currentScale = 1
let scaleInversed = 1

let lines = []

const cells = []

let moved = false

let boundingLeftStart = 0
let boundingTopStart = 0

let draggingStartX = 0;
let draggingStartY = 0

let draggingX = 0;
let draggingY = 0;

let cellsOutsideOfScreenLeft = 0;
let cellsOutsideOfScreenTop = 0;

let width = 0;
let height = 0;

let previousParents = []

let rowsToBeAddedAtTheStart = 0
let rowsToBeAddedAtTheEnd = 0
let columnsToBeAddedAtTheStart = 0
let columnsToBeAddedAtTheEnd = 0

const getCustomScale = () => {
    if (document.body.style.zoom !== 'unset' && !isNaN(parseFloat(document.body.style.zoom))) {
        return 1 / parseFloat(document.body.style.zoom)
    }
    return 1
}

export const Grid = forwardRef((props, ref) => {
    const {
        contacts,
        onEdit,
        onRemoveFromMap,
        onRemoveFromSFDC,
        onOpenNotes,
        onUpdatedReachedOutTo,
        onContactMarkedAs,
        onUpdatePositive,
        onChangeColorForContact,
        notifyChange,
        contactsSavedMapData,
        accountId,
        isChildAccount,
        contactRoles,
        skipNumberOfContactsCheck,
        sfUrl,
    } = props

    const gridRef = useRef()
    const canvasRef = useRef()
    const gridDomRef = useRef()

    const [contextMenuShown, setContextMenuShown] = useState(false)
    const [previousContacts, setPreviousContacts] = useState(contacts.filter(el => !!el.shown))
    const [firstSelectedCell, setFirstSelectedCell] = useState(null)
    const [draggingElement, setDraggingElement] = useState(null)
    const [draggingStartPoint, setDraggingStartPoint] = useState(null)
    const [draggedElementStartingPoint, setDraggedElementStartingPoint] = useState(null)
    const [dragEndingAnimation, setDragEndingAnimation] = useState(false)
    const [previousDraggingElementParent, setPreviousDraggingElementParent] = useState(null)
    const [previousAccountId, setPreviousAccountId] = useState(null)

    useEffect(() => {
        if (!contacts || skipNumberOfContactsCheck) return

        const contactIds = contacts.filter(el => !!el.shown).map(el => el.id)
        if (contactIds.length !== previousContacts.length) {
            const previousContactIds = previousContacts.map(el => el.id)
            const difference = previousContactIds.filter(el => !contactIds.includes(el))
            if (contactIds.length < previousContacts.length) {
                for (const id of difference) {
                    if (firstSelectedCell?.contactId === id) {
                        clearSelectedFirstCell()
                    }
                    removeLinesToContact(id)
                }
            }
        }
        setPreviousContacts(contacts.filter(el => !!el.shown))
    }, [contacts, skipNumberOfContactsCheck])

    const resetZoom = (skipLineUpdates) => updateZoom(1, skipLineUpdates === true)

    const updateZoom = (zoom, skipLineUpdates = false) => {
        currentScale = zoom
        scaleInversed = 1 / zoom

        gridDomRef.current.style.width = `${100 * scaleInversed * getCustomScale()}%`
        gridDomRef.current.style.height = `${100 * scaleInversed * getCustomScale()}%`

        gridDomRef.current.style.transform = `scale(${zoom})`
        updateBoundings()

        positionCellsForZoom()
        if (!skipLineUpdates) {
            updateLinesForZoom()
        }
    }

    const center = () => {
        previousParents = getOccupiedCellIds()
        alignEverythingLeft()
        alignEverythingTop()

        handleDragEndedForAllLines()
    }

    const autoMap = () => {
        const contactsCopy = previousContacts.slice(0).sort()
        contacts.forEach((contact, i) => {
            contacts[i].rowIndex = 1
            contacts[i].columnIndex = 1
        })
        cellsOutsideOfScreenLeft = 0
        cellsOutsideOfScreenTop = 0

        positionCells()
        updateCells()
        positionContacts()
        lines = []
        positionLines()
        center()

        const positionIndexes = [['CEO', 'CO-FOUNDER'], ['SENIOR DIRECTOR'], ['DIRECTOR'], ['PRESIDENT'], ['SVP'], ['VP', 'VICE PRESIDENT'], ['SENIOR']]

        // eslint-disable-next-line guard-for-in
        for (const positionIndex in positionIndexes) {
            const position = positionIndexes[positionIndex]
            contactsCopy.forEach((contact) => {
                const titleWords = contact.Title?.split(' ')?.map(el => el.toUpperCase().trim())
                const matchedPosition = position.find(el => {
                    if (el.includes(' ')) {
                        return contact.Title?.toUpperCase().includes(el)
                    }
                    return titleWords?.find(word => word.includes(el))
                })
                if (!contact.newRowIndex && !!titleWords && matchedPosition) {
                    contact.newRowIndex = parseInt(positionIndex)
                }
            })
        }

        contactsCopy.forEach((el, i) => {
            contactsCopy[i].newRowIndex = el.newRowIndex !== undefined ? el.newRowIndex : positionIndexes.length + 1
        })

        const findLastFilledDepartmentRowIndexes = (rowIndex, department, departmentIndex, skipDepartmentsCheck) => {
            if (skipDepartmentsCheck && departmentIndex) {
                return departmentIndex + 1
            }
            if (rowIndex > positionIndexes.length + 1) {
                return departmentIndex
            }
            const previousRowContacts = findPreviousRowContactsForTheDepartment(rowIndex + 1, department, true, skipDepartmentsCheck)
            if (!previousRowContacts.length) {
                return findLastFilledDepartmentRowIndexes(rowIndex + 1, department, departmentIndex, skipDepartmentsCheck)
            }
            const startIndex = previousRowContacts.sort((a, b) => a.newColumnIndex - b.newColumnIndex)[0]?.newColumnIndex
            const endIndex = previousRowContacts.sort((a, b) => b.newColumnIndex - a.newColumnIndex)[0]?.newColumnIndex

            if (startIndex !== undefined && endIndex !== undefined) {
                return (Math.floor((endIndex - startIndex) / 2) + startIndex)
            }
        }

        const findPreviousRowContactsForTheDepartment = (rowIndex, department, singleLevelCheck, skipDepartmentsCheck) => {
            if (rowIndex > positionIndexes.length + 1) {
                return []
            }
            const previousRowContacts = contactsCopy.filter(el => {
                if (skipDepartmentsCheck) {
                    return el.newRowIndex === rowIndex
                }
                return el.newRowIndex === rowIndex && el.divisionName === department
            })
            if (!previousRowContacts.length && !singleLevelCheck) {
                return findPreviousRowContactsForTheDepartment(rowIndex + 1, department, singleLevelCheck, skipDepartmentsCheck)
            }

            return previousRowContacts
        }


        const findNextRowContactsForTheDepartment = (rowIndex, department) => {
            if (rowIndex < 0) {
                return []
            }
            const nextRowContacts = contactsCopy.filter(el => {
                if (rowIndex === 0) {
                    return el.newRowIndex === rowIndex
                }
                return el.newRowIndex === rowIndex && el.divisionName === department
            })
            if (!nextRowContacts.length) {
                return findNextRowContactsForTheDepartment(rowIndex - 1, department)
            }

            return nextRowContacts
        }

        const newLines = []

        const departments = [...new Set(contactsCopy.map(el => el.divisionName))]
        for (let rowIndex = positionIndexes.length + 1; rowIndex >= 0; rowIndex--) {
            let departmentIndex = 0
            departments.forEach(department => {
                departmentIndex = findLastFilledDepartmentRowIndexes(rowIndex + 1, department, departmentIndex, rowIndex === 0)
                contactsCopy.forEach((contact, i) => {
                    if (contact.divisionName === department && contact.newRowIndex === rowIndex) {
                        contactsCopy[i].newColumnIndex = departmentIndex
                        if (rowIndex !== 0) {
                            departmentIndex++;
                        }
                    }
                })
                if (rowIndex !== 0) {
                    departmentIndex++;
                }
            })
        }

        for (let rowIndex = positionIndexes.length + 1; rowIndex > 0; rowIndex--) {
            contactsCopy.forEach((contact) => {
                if (contact.rowIndex === rowIndex) {
                    const nextRowDepartmentContacts = findNextRowContactsForTheDepartment(rowIndex - 1, contact.divisionName)
                    nextRowDepartmentContacts.forEach(el => {
                        newLines.push([
                            {
                                column: el.newColumnIndex,
                                row: el.newRowIndex,
                            }, {
                                column: contact.newColumnIndex,
                                row: contact.newRowIndex,
                            }
                        ])
                    })
                }
            })
        }

        contactsCopy.forEach((contactCopy) => {
            const contactsIndex = contacts.findIndex(el => el.id === contactCopy.id)
            contacts[contactsIndex].rowIndex = parseInt(contactCopy.newRowIndex)
            contacts[contactsIndex].columnIndex = parseInt(contactCopy.newColumnIndex)
        })

        cellsOutsideOfScreenLeft = 0
        cellsOutsideOfScreenTop = 0

        // resetZoom(true)
        lines = []

        positionCells()
        updateCells()
        positionContacts()
        positionLines()
        center()

        newLines.forEach(line => {
            calculateLine(
                getCells().find(cell => cell.row === line[0].row && cell.column === line[0].column),
                getCells().find(cell => cell.row === line[1].row && cell.column === line[1].column)
            )
        })
    }

    // EVENT LISTENERS
    useImperativeHandle(ref, () => ({
        zoomToFit: () => {
            const requiredZoom = getZoomToFitMap()
            if (requiredZoom !== 1) {
                updateZoom(requiredZoom)
            }
            center()
        },
        resetZoom: resetZoom,
        alignLeft: () => {
            center()
        },
        autoMap: () => {
            autoMap()
        },
        serialize: () => {
            const domNodes = getContactDomNodes()
            const contactsData = domNodes.map(el => {
                const id = el.id
                const cellParent = cells[getClosestCell(el)]
                return {
                    row: cellParent.row,
                    column: cellParent.column,
                    id
                }
            })

            return {
                contacts: contactsData,
                lines: lines.map(el => {
                    const contactA = contactsData.find(contact => contact.row == el.startingCellId.split('-')[0] && contact.column == el.startingCellId.split('-')[1]);
                    const contactB = contactsData.find(contact => contact.row == el.endingCellId.split('-')[0] && contact.column == el.endingCellId.split('-')[1])
                    const accountIdA = contacts.find(el => el.id == contactA.id)?.accountId
                    const accountIdB = contacts.find(el => el.id == contactB.id)?.accountId

                    let account = accountId
                    if (!!accountIdA && !!accountIdB && accountIdA == accountIdB) {
                        account = accountIdA
                    }
                    return {
                        startingCellId: el.startingCellId,
                        endingCellId: el.endingCellId,
                        shortRoute: el.shortRoute,
                        accountId: account
                    }
                })
            }
        }
    }))

    const onMapDragged = e => {
        if (contextMenuShown) {
            return
        }
        if (draggingElement?.id) {
            draggingElement.style.top = `${draggedElementStartingPoint.top + (e.clientY - draggingStartPoint.y) * scaleInversed * getCustomScale()}px`
            draggingElement.style.left = `${draggedElementStartingPoint.left + (e.clientX - draggingStartPoint.x) * scaleInversed * getCustomScale()}px`
            updateLinesToTheNearestParent(draggingElement, true)
            moved = true
        } else if (!!draggingStartX && !!draggingStartY) {
            const newDraggingX = e.clientX - draggingStartX
            const newDraggingY = e.clientY - draggingStartY
            updateDragPositions(newDraggingX, newDraggingY, draggingX, draggingY)

            const cellWidth = CELL_WIDTH * currentScale
            const cellWidthAndOffset = (CELL_WIDTH + CELL_LEFT_OFFSET) * currentScale

            const cellHeight = CELL_HEIGHT * currentScale
            const cellHeightAndOffset = (CELL_HEIGHT + CELL_TOP_OFFSET) * currentScale

            if (newDraggingX > 0) {
                const rightEnd = cells[width - 1].x + cellWidth
                columnsToBeAddedAtTheEnd = Math.max(
                    0,
                    Math.ceil(
                        (getContactDomNodes().map(el => {
                            return parseInt(el.style.left) + parseInt(el.getBoundingClientRect().width)
                        })
                            .sort((a, b) => b - a)[0] * currentScale - rightEnd) / cellWidthAndOffset
                    )
                )
            } else {
                const leftEnd = cells[0].x
                columnsToBeAddedAtTheStart = Math.max(
                    0,
                    Math.ceil(
                        (leftEnd - getContactDomNodes().map(el => parseInt(el.style.left)).sort((a, b) => a - b)[0] * currentScale) / cellWidthAndOffset
                    )
                )
            }

            if (newDraggingY > 0) {
                const bottomEnd = cells[cells.length - 1].y + cellHeight
                rowsToBeAddedAtTheEnd = Math.max(
                    0,
                    Math.ceil(
                        (getContactDomNodes().map(el => {
                            return parseInt(el.style.top) + parseInt(el.getBoundingClientRect().height)
                        })
                            .sort((a, b) => b - a)[0] * currentScale - bottomEnd) / cellHeightAndOffset
                    )
                )
            } else {
                const topEnd = cells[0].y
                rowsToBeAddedAtTheStart = Math.max(
                    0,
                    Math.ceil(
                        (topEnd - getContactDomNodes().map(el => parseInt(el.style.top)).sort((a, b) => a - b)[0] * currentScale) / cellHeightAndOffset
                    )
                )
            }

            draggingX = newDraggingX
            draggingY = newDraggingY
        }
    }

    const onMapMouseUp = e => {
        if (contextMenuShown) {
            return
        }
        e?.preventDefault()
        e?.stopPropagation()

        if (!!draggingX || !!draggingY) {
            width += columnsToBeAddedAtTheStart + columnsToBeAddedAtTheEnd
            cellsOutsideOfScreenLeft += columnsToBeAddedAtTheStart

            height += rowsToBeAddedAtTheStart + rowsToBeAddedAtTheEnd
            cellsOutsideOfScreenTop += rowsToBeAddedAtTheStart

            updateCells()

            columnsToBeAddedAtTheEnd = 0
            columnsToBeAddedAtTheStart = 0
            rowsToBeAddedAtTheEnd = 0
            rowsToBeAddedAtTheStart = 0

            handleDragEndedForAllContacts()
            handleDragEndedForAllLines()
        }

        draggingX = 0
        draggingY = 0

        draggingStartX = 0
        draggingStartY = 0
    }

    const onMapMouseDown = e => {
        if (e.nativeEvent.button !== 0 || contextMenuShown) {
            return
        }

        e.preventDefault()
        e.stopPropagation()

        const cells = getCells()
        previousParents = [...new Set(getContactDomNodes().map(contact => cells[getClosestCell(contact)]?.id))]

        draggingStartX = e.clientX
        draggingStartY = e.clientY
    }

    const onDraggableMouseDown = (e, contact) => {
        if (e.nativeEvent.button !== 0 || contextMenuShown) {
            return
        }
        e.preventDefault()
        e.stopPropagation()

        const element = document.getElementById(contact.id)
        const parentIndex = getClosestCell(element)
        const currentParent = getCells()[parentIndex]?.id

        setPreviousDraggingElementParent(currentParent)

        setDraggedElementStartingPoint({
            top: parseInt(element.style.top || 20),
            left: parseInt(element.style.left || 0)
        })
        setDraggingStartPoint({
            x: e.clientX,
            y: e.clientY
        })

        setDraggingElement(element)
        lines = lines.map(line => {
            if (line.startingCellId === currentParent) {
                return {
                    ...line,
                    notChangingParent: line.endingCellId
                }
            }
            if (line.endingCellId === currentParent) {
                return {
                    ...line,
                    notChangingParent: line.startingCellId
                }
            }
            return line
        })
    }

    const onDraggableClick = (e, contactId) => {
        if (contextMenuShown) {
            return
        }

        if (!moved) {
            e.preventDefault();
            e.stopPropagation();

            setDraggingElement(null)
            setDraggingStartPoint(null)
            setDraggedElementStartingPoint(null)
            cellClick(getClosestCell(e.target.parentNode), e.shiftKey, contactId)
        }
        moved = false
    }

    const onDraggableMouseUp = e => {
        if (contextMenuShown) {
            return
        }
        if (!moved) return

        e?.preventDefault();
        e?.stopPropagation();

        handleDragEnded(draggingElement, true)
        setDraggingElement(null)
        setDraggingStartPoint(null)
        setDraggedElementStartingPoint(null)
        notifyChange && notifyChange()
    }

    const zoom = ({ nativeEvent }) => {
        if (draggingElement || (draggingStartX !== 0 && draggingStartY !== 0) || dragEndingAnimation) {
            return
        }
        const zoomOut = nativeEvent.wheelDelta > 0
        if (gridRef && gridRef.current) {
            const oldScale = parseFloat(gridDomRef.current.style.transform.replace('scale(', ''))

            if (zoomOut) {
                updateZoom((isNaN(oldScale) ? 1 : oldScale) + ZOOM_SCALE_STEP)
            } else {
                updateZoom((isNaN(oldScale) ? 1 : oldScale) - ZOOM_SCALE_STEP)
            }
        }
    }

    const cellClick = (cellId, shiftPressed, contactId) => {
        const cell = getCells()[cellId]
        if (!firstSelectedCell) {
            setFirstSelectedCell({
                ...cell,
                contactId
            })
        } else if (cell.id !== firstSelectedCell.id) {
            removePreexistingLine(firstSelectedCell, cell)
            calculateLine(firstSelectedCell, cell, false, shiftPressed)
            clearSelectedFirstCell()
            notifyChange && notifyChange()
        }
    }

    const clearSelectedFirstCell = () => setFirstSelectedCell(null)

    const handleDragEndedForAllContacts = () => {
        const contacts = getContactDomNodes()
        for (const contact of contacts) {
            handleDragEnded(contact, false, true)
        }
        notifyChange && notifyChange()
    }

    const handleDragEnded = (draggedElement, onlyFree, skipLineUpdates) => {
        onlyFree = onlyFree === true
        clearSelectedFirstCell()

        const newParentIndex = getClosestCell(draggedElement, onlyFree)
        let newParent = onlyFree ? getFreeCells()[newParentIndex] : getCells()[newParentIndex]

        // all of the cells that we have are filled out. Can be achieved by adding a LOT of contacts on a tiny viewport
        if (!newParent) {
            width += 1
            updateCells()

            newParent = getFreeCells()[getClosestCell(draggedElement, onlyFree)]
        }

        if (!skipLineUpdates) {
            updateLinesToTheNearestParent(draggedElement, onlyFree)
        }

        setDragEndingAnimation(true)
        setTimeout(() => {
            setDragEndingAnimation(false)
        }, 101) /* same duration as the animation! */

        lines = lines.filter(line => !line.skipLine)

        draggedElement.style.top = `${newParent.y * scaleInversed}px`
        draggedElement.style.left = `${newParent.x * scaleInversed}px`
    }

    // CALCULATIONS
    const getClosestCell = (element, freeOnly) => {
        const { x, y } = getElementCenter(element)
        const distances = getCellCenters(freeOnly).map((el, i) => ({
            distance: distanceSquared(el, { x, y }),
            index: i
        }))

        const closest = distances.sort((a, b) => a.distance - b.distance)[0]?.index
        return closest
    }

    const resizeCanvas = () => {
        canvasRef.current.width = gridRef.current.getBoundingClientRect().width
        canvasRef.current.height = gridRef.current.getBoundingClientRect().height

        drawLines(lines)
    }

    const debouncedResize = debounce(resizeCanvas, 250)

    // SETUPS
    useEffect(() => {
        if (gridRef && gridRef.current && contacts && accountId !== previousAccountId) {
            cellsOutsideOfScreenLeft = 0
            cellsOutsideOfScreenTop = 0

            resetZoom(true)
            setPreviousAccountId(accountId)
            lines = []

            updateBoundings()
            resizeCanvas()

            updateCells()

            window.addEventListener('resize', () => {
                updateBoundings()
                debouncedResize()
                updateZoom(currentScale)
            })

            positionCells()
            positionContacts()
            positionLines()
            center()
        }
    }, [gridRef, contacts, previousAccountId, accountId])

    const updateCells = () => {
        cells.length = 0
        const startingX = ((CELL_WIDTH + CELL_LEFT_OFFSET) * currentScale)
        const startingY = ((CELL_HEIGHT + CELL_TOP_OFFSET) * currentScale)

        new Array(height || 0).fill(0).forEach((_el, rowIndex) => {
            new Array(width || 0).fill(0).forEach((_el, columnIndex) => {
                cells.push({
                    x: (columnIndex - cellsOutsideOfScreenLeft) * startingX,
                    y: (rowIndex - cellsOutsideOfScreenTop) * startingY,
                    width: CELL_WIDTH * currentScale,
                    height: CELL_HEIGHT * currentScale,
                    row: rowIndex,
                    column: columnIndex,
                    id: `${rowIndex}-${columnIndex}`
                })
            })
        })

        return cells // for easier chaining
    }

    // utilities
    const getCells = () => cells

    const getOccupiedCells = () => [...new Set(getContactDomNodes().filter(el => el.id !== draggingElement?.id).map(getClosestCell).map(el => cells[el]))]

    const getOccupiedCellIds = () => getOccupiedCells().map(el => el?.id)

    const getFreeCells = () => {
        const occupiedCells = getOccupiedCellIds()
        return getCells().filter(el => !occupiedCells.includes(el.id))
    }

    const findCellByColumnAndRow = (column, row) => {
        const index = getCells().findIndex(cell => cell.row === row && cell.column === column)
        return index === -1 ? 0 : index
    }

    const updateDragPositions = (x, y, previousX, previousY) => {
        const xDiff = x - previousX
        const yDiff = y - previousY

        getContactDomNodes().forEach(el => {
            el.style.left = `${parseInt(el.style.left || 0) + xDiff}px`
            el.style.top = `${parseInt(el.style.top || 0) + yDiff}px`
        })

        lines = lines.map(line => {
            const { lineData } = line
            const newLineData = lineData.map(el => ({
                x: el.x + xDiff * currentScale,
                y: el.y + yDiff * currentScale,
            }))
            return {
                ...line,
                lineData: newLineData
            }
        })

        clearLines()
        drawLines(lines)
    }

    const getVisibleSpaceMaximumNumberOfCellsPerRow = dontRound => {
        const width = gridRef.current.getBoundingClientRect().width
        const maxWidth = width * scaleInversed * getCustomScale()

        if (dontRound) {
            return maxWidth / (CELL_WIDTH + CELL_LEFT_OFFSET)
        }
        return Math.ceil(maxWidth / (CELL_WIDTH + CELL_LEFT_OFFSET))
    }

    const getVisibleSpaceMaximumNumberOfCellsPerColumn = dontRound => {
        const height = gridRef.current.getBoundingClientRect().height
        const maxHeight = height * scaleInversed * getCustomScale()
        if (dontRound) {
            return maxHeight / (CELL_HEIGHT + CELL_TOP_OFFSET)
        }
        return Math.ceil(maxHeight / (CELL_HEIGHT + CELL_TOP_OFFSET))
    }

    const updateBoundings = () => {
        boundingLeftStart = gridRef.current.getBoundingClientRect().x
        boundingTopStart = gridRef.current.getBoundingClientRect().y
    }

    const convertDomCoordinatesToCanvas = ({ x, y }) => ({
        x: (x - boundingLeftStart),
        y: (y - boundingTopStart),
    })

    const isCellOnTopOfAnother = (a, b) => a?.row < b?.row

    const getIntermediaryPoints = (start, end) => ([
        {
            x: start.x,
            y: start.y + CELL_HEIGHT * currentScale / 2 + (CELL_TOP_OFFSET * currentScale / 2)
        },
        {
            x: end.x,
            y: start.y + CELL_HEIGHT * currentScale / 2 + (CELL_TOP_OFFSET * currentScale / 2)
        },
    ])

    const getContactDomNodes = () => [...gridDomRef.current.querySelectorAll(`.${cn.draggable}`)]

    const getMinimalRequiredGridSize = () => {
        const contactPositions = [...new Set(getContactDomNodes().map(el => ({ x: parseInt(el.style.left), y: parseInt(el.style.top) })))]

        const alignedOnX = contactPositions.map(el => el.x).sort((a, b) => a - b)
        const alignedOnY = contactPositions.map(el => el.y).sort((a, b) => a - b)

        const farLeft = alignedOnX[0]
        const farRight = alignedOnX[alignedOnX.length - 1] + CELL_WIDTH

        const farTop = alignedOnY[0]
        const farBottom = alignedOnY[alignedOnY.length - 1] + CELL_HEIGHT

        return {
            requiredColumns: Math.ceil((farRight - farLeft) / (CELL_WIDTH + CELL_LEFT_OFFSET)),
            requiredRows: Math.ceil((farBottom - farTop) / (CELL_HEIGHT + CELL_TOP_OFFSET)),
        }
    }

    const getZoomToFitMap = () => {
        const { requiredColumns, requiredRows } = getMinimalRequiredGridSize()

        const requiredScale = currentScale * Math.min(
            getVisibleSpaceMaximumNumberOfCellsPerRow(true) / (requiredColumns),
            getVisibleSpaceMaximumNumberOfCellsPerColumn(true) / (requiredRows)
        )

        return Math.round(requiredScale * 100) / 100
    }

    const alignEverythingLeft = () => {
        const cells = getCells()
        const filledCells = getOccupiedCells()

        const farLeft = filledCells.map(el => el.column).sort((a, b) => a - b)[0] || 0

        const difference = -cells[farLeft].x / ((CELL_WIDTH + CELL_LEFT_OFFSET) * currentScale)

        if (difference === 0) {
            return
        }

        if (isChildAccount) {
            getContactDomNodes().forEach(contact => {
                const parentIndex = getClosestCell(contact)
                contact.style.top = `${cells[parentIndex + difference].y * scaleInversed * getCustomScale()}px`
                contact.style.left = `${cells[parentIndex + difference].x * scaleInversed * getCustomScale()}px`
            })

            width -= cellsOutsideOfScreenLeft
            cellsOutsideOfScreenLeft = 0
            updateCells()
        } else {
            cellsOutsideOfScreenLeft = -difference
            width -= difference

            getContactDomNodes().forEach(contact => {
                const parentIndex = getClosestCell(contact)
                contact.style.top = `${cells[parentIndex + difference].y * scaleInversed * getCustomScale()}px`
                contact.style.left = `${cells[parentIndex + difference].x * scaleInversed * getCustomScale()}px`
            })

            updateCells()
        }
    }

    const alignEverythingTop = () => {
        const cells = getCells()
        const filledCells = getOccupiedCells()

        const farTop = filledCells.map(el => el.row).sort((a, b) => a - b)[0] || 0

        const difference = -cells[farTop * width].y / ((CELL_HEIGHT + CELL_TOP_OFFSET) * currentScale)

        if (difference === 0) {
            return
        }

        if (isChildAccount) {
            cellsOutsideOfScreenTop = -difference
            height -= difference

            updateCells()

            getContactDomNodes().forEach(contact => {
                const parentIndex = getClosestCell(contact)
                contact.style.top = `${cells[(parentIndex + difference * width)].y * scaleInversed * getCustomScale()}px`
                contact.style.left = `${cells[(parentIndex)].x * scaleInversed * getCustomScale()}px`
            })
        } else {
            getContactDomNodes().forEach(contact => {
                const parentIndex = getClosestCell(contact)
                contact.style.top = `${cells[(parentIndex + difference * width)].y * scaleInversed * getCustomScale()}px`
                contact.style.left = `${cells[(parentIndex + difference * width)].x * scaleInversed * getCustomScale()}px`
            })

            height -= cellsOutsideOfScreenTop
            cellsOutsideOfScreenTop = 0
            updateCells()
        }
    }

    const positionCells = () => {
        const farRight = contacts.sort((a, b) => b.columnIndex - a.columnIndex)[0]?.columnIndex
        const farBottom = contacts.sort((a, b) => b.rowIndex - a.rowIndex)[0]?.rowIndex

        if (farRight || farBottom) {
            width = Math.max(farRight + 2, getVisibleSpaceMaximumNumberOfCellsPerRow());
            height = Math.max(farBottom + 2, getVisibleSpaceMaximumNumberOfCellsPerColumn());
        } else {
            width = getVisibleSpaceMaximumNumberOfCellsPerRow()
            height = getVisibleSpaceMaximumNumberOfCellsPerColumn()
        }

        updateCells()
    }

    const positionContacts = () => {
        const domContacts = getContactDomNodes()

        for (let i = 0; i < contacts.length; i++) {
            const contact = contacts[i]
            const cellIndex = findCellByColumnAndRow(contact.columnIndex, contact.rowIndex)

            const domContact = domContacts.find(el => el.id === contact.Id)

            if (domContact) {
                domContact.style.top = `${getCells()[cellIndex].y * scaleInversed * getCustomScale()}px`
                domContact.style.left = `${getCells()[cellIndex].x * scaleInversed * getCustomScale()}px`
            }
        }
    }

    const positionLines = () => {
        const cells = getCells()
        for (const line of contactsSavedMapData.lines) {
            calculateLine(
                cells.find(el => el.id === line.startingCellId),
                cells.find(el => el.id === line.endingCellId),
                false,
                line.shortRoute
            )
        }
    }

    // lines
    const updateLinesToTheNearestParent = (draggedElement, freeOnly) => {
        // const newParentIndex = getClosestCell(draggedElement)
        let somethingChanged = false
        lines = lines.map((line) => {
            // const previousDraggingElementParentRow = document.getElementById(previousDraggingElementParent).getAttribute('row')
            const changingParent = line.startingCellId === line.notChangingParent ? line.endingCellId : line.startingCellId
            if (line.notChangingParent && changingParent === previousDraggingElementParent) {
                const newParentIndex = getClosestCell(draggedElement, freeOnly)
                const newDraggingElementParentElement = freeOnly ? getFreeCells()[newParentIndex] : getCells()[newParentIndex]
                const newDraggingElementParent = newDraggingElementParentElement.id

                const newDraggingElementRow = newDraggingElementParentElement.row
                const notChangingElement = getCells().find(el => el.id === line.notChangingParent)
                const notChangingElementRow = notChangingElement.row

                if (newDraggingElementParent !== previousDraggingElementParent) {
                    setPreviousDraggingElementParent(newDraggingElementParent)
                    somethingChanged = true
                    const newLine = calculateLine(newDraggingElementParentElement, notChangingElement, true)

                    return {
                        ...newLine,
                        shortRoute: line.shortRoute,
                        notChangingParent: line.notChangingParent,
                        skipLine: newDraggingElementRow === notChangingElementRow
                    }
                }
            }
            return line
        })
        if (somethingChanged) {
            clearLines()
            drawLines(lines)
        }
    }

    const handleDragEndedForAllLines = () => {
        const cells = getCells()
        const newParents = getOccupiedCellIds()
        // eslint-disable-next-line
        lines = lines.map((line, _i) => {
            const replacedStartingCellId = newParents[previousParents.indexOf(line.startingCellId)]
            const replacedEndingCellId = newParents[previousParents.indexOf(line.endingCellId)]
            return calculateLine(
                cells.find(el => el.id === replacedStartingCellId),
                cells.find(el => el.id === replacedEndingCellId),
                true,
                line.shortRoute
            )
        }).filter(el => !!el)
        clearLines()
        drawLines(lines)
    }

    const positionCellsForZoom = () => {
        const cells = getCells()
        const farRight = getContactDomNodes().map(contact => cells[getClosestCell(contact)]).sort((a, b) => b.column - a.column)[0]?.column

        const farBottom = getContactDomNodes().map(contact => cells[getClosestCell(contact)]).sort((a, b) => b.row - a.row)[0]?.row

        if (farRight && farBottom) {
            width = Math.max(farRight + 1, getVisibleSpaceMaximumNumberOfCellsPerRow());
            height = Math.max(farBottom + 1, getVisibleSpaceMaximumNumberOfCellsPerColumn());
        } else {
            width = getVisibleSpaceMaximumNumberOfCellsPerRow()
            height = getVisibleSpaceMaximumNumberOfCellsPerColumn()
        }

        updateCells()
    }

    const updateLinesForZoom = () => {
        const cells = getCells()
        lines = lines.map(line => {
            return calculateLine(
                cells.find(el => el.id === line.startingCellId),
                cells.find(el => el.id === line.endingCellId),
                true,
                line.shortRoute
            )
        })

        clearLines()
        drawLines(lines)
    }

    const removePreexistingLine = (startingCell, endingCell) => {
        lines = lines.filter(el => {
            if (el.startingCellId === startingCell.id && el.endingCellId === endingCell.id) {
                return false
            }
            if (el.startingCellId === endingCell.id && el.endingCellId === startingCell.id) {
                return false
            }
            return true
        })
    }

    const calculateLine = (a, b, simplyReturn, shiftPressed) => {
        const aPoint = getCellCenter(a)
        const bPoint = getCellCenter(b)
        const isATop = isCellOnTopOfAnother(a, b)

        if (!a || !b || (a.row === b.row && !moved)) {
            return
        }

        const startingPoint = isATop ? aPoint : bPoint
        const endingPoint = isATop ? bPoint : aPoint

        const startingCellId = isATop ? a.id : b.id
        const endingCellId = isATop ? b.id : a.id

        const { x: startX, y: startY } = startingPoint
        const { x: endX, y: endY } = endingPoint
        const [intermediaryPointA, intermediaryPointB] = getIntermediaryPoints(startingPoint, endingPoint)

        const newLine = {
            startingCellId: startingCellId,
            endingCellId: endingCellId,
            shortRoute: shiftPressed,
            startingCellColumn: isATop ? a.column : b.column,
            endingCellColumn: isATop ? b.column : a.column,
            lineData: [
                { x: startX, y: startY },
                { x: intermediaryPointA.x, y: intermediaryPointA.y },
                { x: intermediaryPointB.x, y: intermediaryPointB.y },
                { x: endX, y: endY },
            ]
        }

        if (simplyReturn) {
            return newLine
        }

        saveLine(newLine)
        clearLines()
        drawLines(lines)
    }

    const drawLines = (lines) => {
        const context = canvasRef.current.getContext("2d");

        lines.forEach((line, lineIndex) => {
            if (line.skipLine) return

            const { lineData } = line
            context.beginPath();
            // context.lineCap = 'round'
            // context.lineJoin = 'round'

            const visiblePath = new window.Path2D()
            const hitAreaPath = new window.Path2D()

            const paths = [visiblePath, hitAreaPath]
            const radius = (CELL_TOP_OFFSET * currentScale) / 3

            paths.forEach((path, i) => {
                if (i === 0) {
                    context.lineWidth = LINE_WIDTH * currentScale;
                    context.strokeStyle = line.shortRoute ? '#33b679' : '#A0FFBB';
                } else {
                    context.lineWidth = LINE_WIDTH * currentScale * 10;
                    context.strokeStyle = 'rgba(0,0,0,0)'
                }
                path.moveTo(lineData[0].x, lineData[0].y)
                if (line.startingCellColumn === line.endingCellColumn) {
                    // offsets to make the right angle
                    path.lineTo(lineData[1].x, lineData[1].y)
                    path.lineTo(lineData[2].x, lineData[2].y)

                    // end target
                    path.lineTo(lineData[3].x, lineData[3].y)
                } else if (line.startingCellColumn < line.endingCellColumn) {
                    // offsets to make the right angle
                    path.lineTo(lineData[1].x, (lineData[1].y - radius))
                    path.arc(
                        (lineData[1].x + radius),
                        (lineData[1].y - radius),
                        radius,
                        (Math.PI / 180) * 180,
                        (Math.PI / 180) * 90,
                        true
                    )
                    path.lineTo((lineData[2].x - radius), lineData[2].y)
                    path.arc(
                        (lineData[2].x - radius),
                        (lineData[2].y + radius),
                        radius,
                        (Math.PI / 180) * -90,
                        (Math.PI / 180) * 0,
                        false
                    )

                    // end target
                    path.lineTo(lineData[3].x, lineData[3].y)
                } else {
                    // offsets to make the right angle
                    path.lineTo(lineData[1].x, (lineData[1].y - radius))
                    path.arc(
                        (lineData[1].x - radius),
                        (lineData[1].y - radius),
                        radius,
                        0,
                        (Math.PI / 180) * 90,
                        false
                    )
                    path.lineTo((lineData[2].x + radius), lineData[2].y)
                    path.arc(
                        (lineData[2].x + radius),
                        (lineData[2].y + radius),
                        radius,
                        (Math.PI / 180) * 270,
                        (Math.PI / 180) * 180,
                        true
                    )

                    // end target
                    path.lineTo(lineData[3].x, lineData[3].y)
                }
                context.stroke(path)
                path.closePath()
            })
            lines[lineIndex].path = hitAreaPath
        })

        if (window.callypsoDebug) {
            setTimeout(drawCellsForDebugging, 100)
        }
    }

    const drawCellsForDebugging = () => {
        const context = canvasRef.current.getContext("2d");
        getCells().forEach(cell => {
            context.beginPath();
            context.rect(cell.x, cell.y, cell.width, cell.height);
            context.stroke();
        })
    }

    const clearLines = () => {
        // eslint-disable-next-line
        canvasRef.current.width = canvasRef.current.width
    }

    const removeLinesToContact = id => {
        const domElement = document.getElementById(id)
        removeLinesStartingHere(getCells()[getClosestCell(domElement)].id)
        removeLinesEndingHere(getCells()[getClosestCell(domElement)].id)
        notifyChange && notifyChange()
    }

    const removeLinesStartingHere = cellId => {
        lines = lines.filter(el => {
            return el.startingCellId !== cellId
        })
        clearLines()
        drawLines(lines)
    }

    const removeLinesEndingHere = cellId => {
        lines = lines.filter(el => {
            return el.endingCellId !== cellId
        })
        clearLines()
        drawLines(lines)
    }

    const saveLine = linePath => {
        lines.push(linePath)
    }

    const getCellCenters = (freeOnly) => {
        if (freeOnly === true) {
            return [...getFreeCells()].map(getCellCenter)
        }
        return [...getCells()].map(getCellCenter)
    }

    const getCellCenter = cell => {
        if (!cell) {
            return {
                x: cells[0].x + cells[0].width / 2,
                y: cells[0].y + cells[0].height / 2,
                cellId: cells[0].id
            }
        }
        return {
            x: cell.x + cell.width / 2,
            y: cell.y + cell.height / 2,
            cellId: cell.id,
        }
    }

    const getElementCenter = element => convertDomCoordinatesToCanvas({
        x: (element.getBoundingClientRect().x + element.getBoundingClientRect().width / 2),
        y: (element.getBoundingClientRect().y + element.getBoundingClientRect().height / 2),
        width: element.getBoundingClientRect().width,
        height: element.getBoundingClientRect().height
    })

    const distanceSquared = (a, b) => {
        const diffX = a.x - b.x;
        const diffY = a.y - b.y;
        return diffX * diffX + diffY * diffY
    }

    const checkPointInPath = (e, line) => {
        const { x, y } = convertDomCoordinatesToCanvas({ x: e.clientX, y: e.clientY })
        try {
            return canvasRef.current.getContext('2d').isPointInStroke(line.path, x, y)
        } catch {
            return false
        }
    }

    return (
        <div
            className={cn.grid}
            onWheel={zoom}
            ref={gridRef}
            onMouseLeave={() => {
                if (!!draggingStartX && !!draggingStartY) {
                    onMapMouseUp()
                } else if (draggingElement) {
                    onDraggableMouseUp()
                }
            }}
            onMouseMove={e => {
                const updateCursor = lines.reverse().find(line => checkPointInPath(e, line))
                if (updateCursor) {
                    gridRef.current.style.cursor = 'pointer'
                } else {
                    gridRef.current.style.cursor = 'default'
                }
            }}
            onDoubleClick={e => {
                const lineToDelete = lines.reverse().find(line => {
                    if (checkPointInPath(e, line)) {
                        return true
                    }
                    return false
                })

                if (!!lineToDelete) {
                    lines = lines.filter(el => el.startingCellId !== lineToDelete.startingCellId || el.endingCellId !== lineToDelete.endingCellId)

                    clearLines()
                    drawLines(lines)
                    notifyChange && notifyChange()
                }
            }}
        >
            <canvas
                ref={canvasRef}
            />
            <div
                ref={gridDomRef}
                className={`${cn.draggedElementsContainer} ${draggingElement ? cn.captureEvents : ''}`}
                onMouseMove={onMapDragged}
                onMouseUp={onMapMouseUp}
                onMouseDown={onMapMouseDown}
            >
                {previousContacts.map(contact => (
                    <div
                        id={contact.id}
                        key={contact.id}
                        className={`
                            ${cn.draggable}
                            ${draggingElement ? cn.dragging : ''}
                            ${dragEndingAnimation ? cn.dragAnimation : ''}
                        `}
                        onMouseDown={e => onDraggableMouseDown(e, contact)}
                        onClick={e => onDraggableClick(e, contact.id)}
                        onMouseUp={onDraggableMouseUp}
                    >
                        <ContactConnected
                            {...contact}
                            linkedInProfileImageUrl={/* contact.linkedInProfileImageUrl ||  */`${sfUrl}${contact.PhotoUrl}`}
                            accountId={accountId}
                            key={contact.id}
                            onEdit={() => onEdit(contact.id)}
                            onRemoveFromMap={() => {
                                removeLinesToContact(contact.id)
                                onRemoveFromMap(contact.id)
                            }}
                            firstCellSelected={firstSelectedCell?.contactId === contact.id}
                            onUpdatedReachedOutTo={onUpdatedReachedOutTo}
                            onContactMarkedAs={onContactMarkedAs}
                            onUpdatePositive={onUpdatePositive}
                            onRemoveFromSFDC={() => onRemoveFromSFDC(contact.id)}
                            onRemoveConnections={() => removeLinesToContact(contact.id)}
                            onOpenNotes={() => onOpenNotes(contact.id)}
                            onChangeColorForContact={e => onChangeColorForContact(e, contact.id)}
                            notifyContextMenuShown={() => setContextMenuShown(true)}
                            notifyContextMenuHidden={() => setContextMenuShown(false)}
                            contactRoles={contactRoles}
                        />
                    </div>
                ))}
            </div>
        </div>
    )
})