import React, { useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import ReactHtmlParser from 'react-html-parser';
import { connect } from 'react-redux';
import { Error } from '../../components/Error'
import googleLogo from '../../assets/images/google.svg'
import outlookLogo from '../../assets/images/outlook.svg'
import logo from '../../assets/images/logo.svg'
import { setUser, setSystemMessage, setPageAfter } from '../../store/actions';
import { URLS } from '../../config'
import { makeRequest } from '../../utilities/endpoints'
import store from '../../store';
import cn from './Auth.module.css'


export const Auth = ({ dispatch, user, pageAfter }) => {
    const history = useHistory()
    const { token, cookie } = useParams();
    const [signInError, setSignInError] = useState()
    const [buttonClicked, setButtonClicked] = useState()
    useEffect(() => {
        if (!!token) {
            console.info('auth token?', token, cookie)

            if (token === 'true') {
                console.info('auth token? in the if')
                makeRequest.get(`${URLS.main}/session`)
                    .then(res => {
                        console.info('session res after the token and redirect?', res)
                        if (res && res.data && res.data.cookie) {
                            document.cookie = `login=${res.data.cookie}`
                            dispatch(setUser(res.data))
                        }
                    })
            } else if (token === 'cookie') {
                console.info('setting a cookie!', cookie)
                // document.cookie = `login=${cookie}; SameSite=None; Secure`
                console.info('post cookie set!', document.cookie)

                makeRequest.get(`${URLS.main}/session`)
                    .then(res => {
                        console.info('session res after the token and redirect?', res)
                        if (res && res.data && res.data.cookie) {
                            document.cookie = `login=${res.data.cookie}; SameSite=None; Secure`
                            dispatch(setUser(res.data))
                        }
                    })
            } else {
                setButtonClicked(false)
                dispatch(setUser(null))
                if (token === 'NOT_INVITED') {
                    setSignInError('We are sorry, but it seems like you are not invited to any workspace yet.<br>If you are sure you are logging with correct email address, please contact our support at <a href="mailto:support@callypso.com">support@callypso.com</a>')
                } else {
                    setSignInError(`We are sorry, but there was an error while signing in, please contact our support at <a href="mailto:support@callypso.com">support@callypso.com</a> for assistance. </br></br> ${token}`)
                }
            }
            dispatch(setUser(null))
        }
    }, [token])
    useEffect(() => {
        if (user && user.email) {
            console.info('Auth user check', pageAfter)
            if (pageAfter) {
                console.info('pageAfter', pageAfter)
                dispatch(setPageAfter('handled'))
                history.push(pageAfter)
            } else {
                history.push('/')
            }
        }
    }, [user, history])

    const signIn = () => {
        if (!!buttonClicked) return
        setButtonClicked(true)
        dispatch(setSystemMessage(null))
        makeRequest.get(`${URLS.main}/signInUrl`)
            .then(res => {
                if (res && res.data) {
                    window.location.href = res.data
                }
            })
    }

    const signInMs = () => {
        if (!!buttonClicked) return
        setButtonClicked(true)
        dispatch(setSystemMessage(null))
        makeRequest.get(`${URLS.main}/signInUrlMS`)
            .then(res => {
                if (res && res.data) {
                    window.location.href = res.data
                }
            })
    }

    return (
        <div className={cn.loginAuth}>
            <div>
                <div className={cn.loginAuthBoxLayout}>
                    <img className={cn.loginAuthLogo} src={logo} alt="Workflow" />
                    <h1>Welcome to Callypso</h1>
                    <p className={cn.loginAuthBoxLayoutP}>Please sign in with your Google or Microsoft account to access your dashboard.</p>
                    {signInError && <div className={cn.loginAuthBoxLayoutErrorWrapper}><Error>{ReactHtmlParser(signInError)}</Error></div>}
                    {store.getState().systemMessage && <div className={cn.loginAuthBoxLayoutErrorWrapper}><Error>{store.getState().systemMessage}</Error></div>}
                    <form className={cn.loginAuthBoxLayoutForm} action="#" method="POST">
                        <div
                            className={`${cn.loginAuthBoxLayoutLoginButtonWrapper} ${!buttonClicked ? 'pointer' : 'not-allowed'}`}
                            onClick={signIn}
                        >
                            <div className={cn.loginAuthBoxLayoutIcon}>
                                <img src={googleLogo} width="18" height="18" alt="Google Logo" />
                            </div>
                            <div className={cn.loginAuthBoxLayoutText}>
                                Sign in with Google
                            </div>
                        </div>
                    </form>
                    <form className={`${cn.loginAuthBoxLayoutForm} ${cn.msSignIn}`} action="#" method="POST">
                        <div
                            className={`${cn.loginAuthBoxLayoutLoginButtonWrapper} ${!buttonClicked ? 'pointer' : 'not-allowed'}`}
                            onClick={signInMs}
                        >
                            <div className={cn.loginAuthBoxLayoutIcon}>
                                <img src={outlookLogo} width="18" height="18" alt="Google Logo" />
                            </div>
                            <div className={cn.loginAuthBoxLayoutText}>
                                Sign in with Microsoft
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}


export const mapStateToProps = (state, ownProps) => {
    return {
        user: state.user,
        pageAfter: state.pageAfter,
        ...ownProps,
    }
}

export const AuthConnected = connect(mapStateToProps)(Auth)
