import React, { useEffect, useState } from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { Checkbox } from '../../components/Checkbox';
import { Hr } from '../../components/Hr';
import { Input } from '../../components/Input';
import { Row } from '../../components/Row';
import { Table } from '../../components/Table';
import { Nbsp, NBSP_SIZES } from '../../components/Nbsp';
import { ViewContainer } from '../../components/ViewContainer';
import { URLS } from '../../config';
import { makeRequest } from '../../utilities/endpoints';
import { formatSum } from '../../utilities/currency';
import { Button, BUTTON_TYPES } from '../../components/Button';
import { SubHeader } from '../../components/SubHeader';
import cn from './CallypsoCRM.module.css'
import { Dropdown } from '../../components/Dropdown';
import { TextArea } from '../../components/TextArea';
import { translateCRMLabel } from '../../utilities/translateCRMLabels';
import { SelectWrapperConnected } from '../../components/SelectWrapper';

const OpportunityStages = ({ stages = [], reloadOpportunityStages, updateStages }) => {
    const [isWon, setIsWon] = useState(false)
    const [isLost, setIsLost] = useState(false)

    const [newStageName, setNewStageName] = useState('')
    const [newStagePercentage, setNewStagePercentage] = useState('')


    const toggleIsWon = () => {
        if (!isWon) {
            setIsWon(true)
            setIsLost(false)
        } else {
            setIsWon(false)
        }
    }

    const toggleIsLost = () => {
        if (!isLost) {
            setIsWon(false)
            setIsLost(true)
        } else {
            setIsLost(false)
        }
    }

    const deleteStage = stageId => {
        makeRequest.delete(`${URLS.main}/opportunities/stages/${stageId}`)
            .then(reloadOpportunityStages)
    }

    const addStage = () => {
        makeRequest.post(`${URLS.main}/opportunities/stages`, {
            name: newStageName,
            percentage: newStagePercentage,
            isWon,
            isLost
        })
            .then(reloadOpportunityStages)

        setIsLost(false)
        setIsWon(false)
        setNewStageName('')
        setNewStagePercentage('')
    }

    const onStageDragEnd = ({ source, destination }) => {
        const newStages = stages.slice(0)
        const copiedElement = stages[source.index]
        newStages.splice(source.index, 1)
        newStages.splice(destination.index, 0, copiedElement)
        updateStages(newStages.map((el, i) => ({
            ...el,
            orderIndex: i,
        })))
    }

    return (
        <div>
            <Row className={`${cn.stageRow} ${cn.header}`}>
                <div>Stage Name</div>
                <div>Stage Percentage</div>
                <div>IsWon</div>
                <div>IsLost</div>
                <div>Actions</div>
            </Row>
            <div className={cn.boardContainer}>
                <DragDropContext
                    onDragEnd={onStageDragEnd}
                >
                    <div className={cn.column}>
                        <Droppable
                            droppableId={'OPPORTUNITY_STAGES'}
                            ignoreContainerClipping={true}
                        >
                            {(provided, snapshot) => {
                                return (
                                    <div
                                        {...provided.droppableProps}
                                        ref={provided.innerRef}
                                        className={`${cn.columnContainer} ${snapshot.isDraggingOver && cn.draggingOver}`}
                                    >
                                        {stages.map((stage, index) => {
                                            return (
                                                <Draggable
                                                    key={stage.id}
                                                    draggableId={`${stage.id}`}
                                                    index={index}
                                                >
                                                    {(provided, snapshot) => {
                                                        return (
                                                            <div
                                                                className={`${cn.stage} ${snapshot.isDragging && cn.beingDragged}`}
                                                                ref={provided.innerRef}
                                                                {...provided.draggableProps}
                                                                {...provided.dragHandleProps}
                                                                style={provided.draggableProps.style}
                                                                data-tooltip-id='default'
                                                                data-tooltip-content='You can Drag and Drop stages to re-arrange them'
                                                            >
                                                                <Row
                                                                    className={cn.stageRow}
                                                                    {...provided.draggableProps}
                                                                    {...provided.dragHandleProps}
                                                                    ref={provided.innerRef}
                                                                    style={provided.draggableProps.style}
                                                                >
                                                                    <div>{stage.name}</div>
                                                                    <div>{stage.percentage}%</div>
                                                                    <div><Checkbox label={'Won'} checked={!!stage.isWon} disabled /></div>
                                                                    <div><Checkbox label={'Lost'} checked={!!stage.isLost} disabled /></div>
                                                                    <div>
                                                                        <Row noMargin><Button onClick={() => deleteStage(stage.id)}>Delete</Button></Row>
                                                                    </div>
                                                                </Row>
                                                            </div>
                                                        );
                                                    }}
                                                </Draggable>
                                            );
                                        })}
                                        {provided.placeholder}
                                    </div>
                                );
                            }}
                        </Droppable>
                    </div>
                </DragDropContext>
            </div>
            <Hr />
            <Row>
                <Input placeholder={`${translateCRMLabel('Opportunity')} Stage Name`} fullWidth value={newStageName} onChange={e => setNewStageName(e.target.value)} />
                <Nbsp size={NBSP_SIZES.EXTRA_BIG} />
                <Input placeholder={`${translateCRMLabel('Opportunity')} Percentage`} fullWidth type='number' value={newStagePercentage} onChange={e => setNewStagePercentage(e.target.value)} />
                <Nbsp size={NBSP_SIZES.EXTRA_BIG} />
                <Checkbox label={'Won'} checked={isWon} onClick={toggleIsWon} />
                <Nbsp size={NBSP_SIZES.EXTRA_BIG} />
                <Checkbox label={'Lost'} checked={isLost} onClick={toggleIsLost} />
                <Nbsp size={NBSP_SIZES.EXTRA_BIG} />
                <Button
                    disabled={!newStageName || !newStagePercentage}
                    onClick={addStage}
                    type={BUTTON_TYPES.PRIMARY}
                >
                    Add
                </Button>
            </Row>
        </div>
    )
}

const OpportunityTypes = ({ types = [], reloadOpportunityTypes }) => {
    const [newTypeName, setNewTypeName] = useState('')

    const deleteType = typeId => {
        makeRequest.delete(`${URLS.main}/opportunities/types/${typeId}`)
            .then(reloadOpportunityTypes)
    }

    const addType = () => {
        makeRequest.post(`${URLS.main}/opportunities/types`, {
            name: newTypeName,
        })
            .then(reloadOpportunityTypes)

        setNewTypeName('')
    }

    return (
        <div>
            <Table
                headers={['Type Name', 'Actions']}
                data={types.map(el => {
                    // eslint-disable-next-line array-callback-return
                    return ['Type Name', 'Actions'].map((key) => {
                        switch (key) {
                        case 'Type Name':
                            return el.name
                        case 'Actions':
                            return <Row><Button onClick={() => deleteType(el.id)}>Delete</Button></Row>
                        }
                    })
                })}
            />
            <Hr />
            <Row>
                <Input placeholder={`${translateCRMLabel('Opportunity')} Type Name`} fullWidth value={newTypeName} onChange={e => setNewTypeName(e.target.value)} />
                <Nbsp size={NBSP_SIZES.EXTRA_BIG} />
                <Button
                    disabled={!newTypeName}
                    onClick={addType}
                    type={BUTTON_TYPES.PRIMARY}
                >
                    Add
                </Button>
            </Row>
        </div>
    )
}

const FIELD_TYPES = [
    {
        label: 'Text',
        value: 'text'
    },
    {
        label: 'Checkbox',
        value: 'boolean'
    },
    {
        label: 'Dropdown',
        value: 'picklist'
    },
    {
        label: 'Date Time',
        value: 'datetime'
    },
    {
        label: 'Date',
        value: 'date'
    },
    {
        label: 'Currency',
        value: 'currency'
    },
    {
        label: 'URL',
        value: 'url'
    },
]

const CustomFieldSecondPart = ({ type, onChange, value }) => {
    switch (type) {
    case 'picklist':
        return <>
            <TextArea value={value} onChange={onChange} placeholder='Add each option on a new line' />
            <Nbsp size={NBSP_SIZES.EXTRA_BIG} />
        </>
    default:
        return null
    }
}

const Products = ({ products, reloadProducts }) => {
    const [name, setName] = useState('')
    const [price, setPrice] = useState('')
    const [duration, setDuration] = useState('1 year')

    const deleteProduct = productId => {
        makeRequest.delete(`${URLS.main}/products/${productId}`)
            .then(reloadProducts)
    }

    const reactivateProduct = productId => {
        makeRequest.put(`${URLS.main}/products/${productId}/activate`)
            .then(reloadProducts)
    }

    const deactivateProduct = productId => {
        makeRequest.put(`${URLS.main}/products/${productId}/deactivate`)
            .then(reloadProducts)
    }

    const addProduct = () => {
        makeRequest.post(`${URLS.main}/products`, {
            name,
            price,
            duration
        })
            .then(reloadProducts)

        setName('')
        setPrice('')
        setDuration('1 year')
    }

    return (
        <div>
            <Table
                headers={['Name', 'Price', 'Duration', 'Active', 'Actions']}
                data={products.map(el => {
                    // eslint-disable-next-line array-callback-return
                    return ['Name', 'Price', 'Duration', 'Active', 'Actions'].map((key) => {
                        switch (key) {
                        case 'Name':
                            return el.Name
                        case 'Price':
                            return formatSum('$', el.price)
                        case 'Duration':
                            return el.duration
                        case 'Active':
                            return <Checkbox
                                label={'Active'}
                                checked={!!el.active}
                                onClick={() => {
                                    if (!!el.active) {
                                        deactivateProduct(el.Id)
                                    } else {
                                        reactivateProduct(el.Id)
                                    }
                                }}
                            />
                        case 'Actions':
                            return <Row><Button onClick={() => deleteProduct(el.Id)}>Delete</Button></Row>
                        }
                    })
                })}
            />
            <Hr />
            <Row>
                <Input placeholder='Product/Service Name' fullWidth value={name} onChange={e => setName(e.target.value)} />
                <Nbsp size={NBSP_SIZES.EXTRA_BIG} />
                <Row noMargin>
                    $
                    <Input placeholder='Price' fullWidth value={price} onChange={e => setPrice(e.target.value)} />
                </Row>
                <Nbsp size={NBSP_SIZES.EXTRA_BIG} />
                <Input placeholder='Duration of the product/services' fullWidth value={duration} onChange={e => setDuration(e.target.value)} />
                <Nbsp size={NBSP_SIZES.EXTRA_BIG} />
                <Button
                    disabled={!name || !price}
                    onClick={addProduct}
                    type={BUTTON_TYPES.PRIMARY}
                >
                    Add
                </Button>
            </Row>
        </div>
    )
}


export const CallypsoCRM = () => {
    const standardObjects = [
        { label: translateCRMLabel('Account'), value: 'ACCOUNTS' },
        { label: 'Opportunities', value: 'OPPORTUNITIES' },
        { label: 'Contacts', value: 'CONTACTS' },
        { label: 'Products', value: 'PRODUCTS' },
    ]
    const [selectedObject, setSelectedObject] = useState(standardObjects[0])
    const [customObjects, setCustomObjects] = useState([])
    const [opportunityStages, setOpportunityStages] = useState([])
    const [opportunityTypes, setOpportunityTypes] = useState([])
    const [fields, setFields] = useState([])

    const [newFieldName, setNewFieldName] = useState('')
    const [newFieldType, setNewFieldType] = useState(FIELD_TYPES[0].value)
    const [newFieldSecondPart, setNewFieldSecondPart] = useState()
    const [products, setProducts] = useState([])


    useEffect(() => {
        // TODO: add loading for custom objects. For now it is a workaround for eslint
        setCustomObjects([])
        loadOpportunityStages()
        loadOpportunityTypes()
        loadProducts()
    }, [])

    useEffect(() => {
        setFields([])
        loadFields(selectedObject)
    }, [selectedObject])

    const loadProducts = () => {
        makeRequest.get(`${URLS.main}/products`)
            .then(res => {
                setProducts(res.data)
            })
    }

    const loadOpportunityStages = () => {
        makeRequest.get(`${URLS.main}/opportunities/stages-settings`)
            .then(res => {
                setOpportunityStages(res.data)
            })
    }

    const loadOpportunityTypes = () => {
        makeRequest.get(`${URLS.main}/opportunities/types`)
            .then(res => {
                setOpportunityTypes(res.data)
            })
    }


    const deleteField = id => {
        makeRequest.delete(`${URLS.main}/custom-fields/${id}`)
            .then(() => loadFields(selectedObject))
    }

    const loadFields = parentObject => {
        makeRequest.get(`${URLS.main}/custom-fields/${parentObject?.value}`)
            .then(res => setFields(res.data))
    }

    const addField = () => {
        makeRequest.post(`${URLS.main}/custom-fields`, {
            parentObject: selectedObject.value,
            name: newFieldName,
            type: newFieldType,
            dropdownOptions: newFieldSecondPart
        }).then(() => loadFields(selectedObject))
    }

    const updateStages = (stages) => {
        setOpportunityStages(stages)
        makeRequest.post(`${URLS.main}/opportunities/stages-settings`, { stages })
    }

    return (
        <ViewContainer>
            <SelectWrapperConnected
                onChange={setSelectedObject}
                options={[...standardObjects, ...customObjects]}
                value={selectedObject}
            />

            <br />
            {selectedObject?.value !== 'PRODUCTS' && (
                <>
                    <div className={cn.subHeaderWrapper}>
                        <SubHeader noBackground>Custom fields</SubHeader>
                    </div>

                    <Table
                        sticky={false}
                        headers={['Field', 'Type', 'Options', 'Actions']}
                        data={fields.map(el => {
                            // eslint-disable-next-line array-callback-return
                            return ['Field', 'Type', 'Options', 'Actions'].map((key) => {
                                switch (key) {
                                case 'Field':
                                    return el.name
                                case 'Type':
                                    return el.type
                                case 'Options':
                                    return el.dropdownOptions || 'N/A'
                                case 'Actions':
                                    return <Row><Button onClick={() => deleteField(el.id)}>Delete</Button></Row>
                                }
                            })
                        })}
                    />

                    <Hr />

                    <Row noAligning>
                        <Input placeholder='Field Name' fullWidth value={newFieldName} onChange={e => setNewFieldName(e.target.value)} />
                        <Nbsp size={NBSP_SIZES.EXTRA_BIG} />
                        <Dropdown
                            values={FIELD_TYPES}
                            value={newFieldType}
                            onChange={e => {
                                setNewFieldType(e.target.value)
                                setNewFieldSecondPart('')
                            }}
                            className={cn.newTypeDropdown}
                        />
                        <Nbsp size={NBSP_SIZES.EXTRA_BIG} />
                        <CustomFieldSecondPart type={newFieldType} value={newFieldSecondPart} onChange={e => setNewFieldSecondPart(e.target.value)} />
                        <Button
                            disabled={!newFieldName || !newFieldType}
                            onClick={addField}
                            type={BUTTON_TYPES.PRIMARY}
                        >
                            Add
                        </Button>
                    </Row>
                </>
            )}

            {selectedObject?.value === 'OPPORTUNITIES' && (
                <>
                    <div className={cn.subHeaderWrapper}>
                        <SubHeader noBackground>{translateCRMLabel('Opportunity')} Stages</SubHeader>
                    </div>
                    <OpportunityStages stages={opportunityStages} reloadOpportunityStages={loadOpportunityStages} updateStages={updateStages} />
                    <Hr />
                    <div className={cn.subHeaderWrapper}>
                        <SubHeader noBackground>{translateCRMLabel('Opportunity')} Types</SubHeader>
                    </div>
                    <OpportunityTypes types={opportunityTypes} reloadOpportunityTypes={loadOpportunityTypes} />
                </>
            )}
            {selectedObject?.value === 'PRODUCTS' && (
                <>
                    <div className={cn.subHeaderWrapper}>
                        <SubHeader noBackground>Products</SubHeader>
                    </div>
                    <Products products={products} reloadProducts={loadProducts} />
                </>
            )}
        </ViewContainer>
    )
}