import React, { useEffect, useState } from 'react'
import moment from 'moment'
import { URLS } from '../config'
import { makeRequest } from '../utilities/endpoints'
import { SalesforceCRUDModal } from './SalesforceCRUDModal'
import { workspaceTypeCheck } from '../utilities/workspaceType'
import { translateCRMLabel } from '../utilities/translateCRMLabels'

export const AccountsCRUDModalWrapper = ({
    shown,
    create,
    edit,
    accountId,
    editCallback,
    createCallback,
    onClose,
    header,
    externalError,
    predefinedData={},
}) => {
    const [showEditLoader, setShowEditLoader] = useState(false)
    const [values, setValues] = useState([])
    const [fields, setFields] = useState([])
    const [connectedObjectsOptions, setConnectedObjectsOptions] = useState({})
    const [createEditError, setCreateEditError] = useState(false)
    const [createOrUpdateRequestSent, setCreateOrUpdateRequestSent] = useState(false)
    const [onlyChangedValues, setOnlyChangedValues] = useState({})

    useEffect(() => {
        if (shown && create) {
            getCreateAccountFields()
        }
    }, [create, shown])

    useEffect(() => {
        if (shown && edit) {
            editAccount(accountId)
        }
    }, [edit, shown])

    useEffect(() => {
        if (externalError) {
            setCreateEditError(externalError)
        }
    }, [externalError])

    const getConnectedObjectsFromFields = fields => [...new Set(fields.filter(el => el.type === 'reference').map(el => el.referenceTo).flat())]

    const editAccount = accountId => {
        setShowEditLoader(true)
        makeRequest.get(`${URLS.main}/account/details/${accountId}`)
            .then(res => {
                if (res && res.data) {
                    const { fields, values } = res.data
                    if (!values || Object.keys(values).length <= 0) {
                        setCreateEditError({
                            'errorCode': 'ACCOUNT_GONE'
                        })
                    }
                    const valuesCopy = values || {}
                    Object.keys(valuesCopy).forEach(key => {
                        if (!fields.find(el => el.value === key)) {
                            delete valuesCopy[key]
                        }
                    })
                    setValues(valuesCopy)
                    setFields(fields)
                    getConnectedObjects(fields)
                }
            })
    }

    const getCreateAccountFields = () => {
        setShowEditLoader(true)
        makeRequest.get(`${URLS.main}/account/details/create`)
            .then(res => {
                if (res && res.data) {
                    const { fields } = res.data
                    setFields(fields)
                    getConnectedObjects(fields)
                }
            })
    }

    const getConnectedObjects = (fields) => {
        if (!fields) {
            setShowEditLoader(false)
            return
        }
        const newConnectedObjects = getConnectedObjectsFromFields(fields).filter(el => !Object.keys(connectedObjectsOptions).includes(el))
        if (!newConnectedObjects || !newConnectedObjects.length) {
            setShowEditLoader(false)
        } else {
            makeRequest.get(`${URLS.main}/sobject-dropdown-options/${newConnectedObjects.filter(el => !Object.keys(connectedObjectsOptions).includes(el)).join(',')}`)
                .then(res => {
                    if (res && res.data) {
                        const objects = { ...connectedObjectsOptions }
                        Object.keys(res.data).forEach(el => {
                            objects[el] = res.data[el].map(el => ({
                                value: el.Id,
                                label: el.Name
                            }))
                        })
                        setConnectedObjectsOptions(objects)
                        setShowEditLoader(false)
                    }
                })
        }
    }

    const createNewAccount = () => {
        setCreateOrUpdateRequestSent(true)
        let cleanedUpValues = {}
        if (workspaceTypeCheck(['HUBSPOT'])) {
            cleanedUpValues = { ...onlyChangedValues }
        } else {
            Object.keys(predefinedData).forEach(key => {
                cleanedUpValues[key] = predefinedData[key].data
            })
            Object.keys(values).forEach(key => {
                cleanedUpValues[key] = values[key]
            })
        }
        makeRequest.post(`${URLS.main}/accounts`, cleanedUpValues)
            .then((res) => {
                const updatedConnectedObjects = { ...connectedObjectsOptions }
                delete updatedConnectedObjects.Account
                setConnectedObjectsOptions(updatedConnectedObjects)
                createCallback(res.data)
                closeCreateEditAccountModal()
            })
            .catch((error) => {
                setCreateEditError(error.response.data)
                setCreateOrUpdateRequestSent(false)
            })
    }

    const closeCreateEditAccountModal = () => {
        setShowEditLoader(false)
        setValues([])
        setCreateEditError(null)
        setCreateOrUpdateRequestSent(false)
    }

    const getErrorMessage = () => {
        switch (createEditError.errorCode) {
        case 'REQUIRED_FIELD_MISSING':
            return `Please fill out all of the required fields: ${fields.filter(el => !el.nillable && !el.defaultedOnCreate).map(el => el.label).join(', ')}`
        case 'INACTIVE_OWNER_OR_USER':
            return `Please make sure the new Account and/or Owner of this account are still active in your Salesforce instance.`
        case 'FIELD_CUSTOM_VALIDATION_EXCEPTION':
            return createEditError.message
        case 'ACCOUNT_GONE':
            return `${translateCRMLabel('Account')} is no longer available/exists.`
        default:
            return `We are sorry, but there was an error with the ${translateCRMLabel('Account')} data you tried to submit: ${createEditError.name}`
        }
    }

    const updateAccount = () => {
        setCreateOrUpdateRequestSent(true)
        let cleanedUpValues = {}
        if (workspaceTypeCheck(['HUBSPOT'])) {
            cleanedUpValues = { ...onlyChangedValues }
        } else {
            Object.keys(predefinedData).forEach(key => {
                cleanedUpValues[key] = predefinedData[key].data
            })
            Object.keys(values).forEach(key => {
                if (
                    (values[key] === null || typeof values[key] !== 'object')
                    && !Object.prototype.hasOwnProperty.call(values, `${key}Id`)
                ) {
                    cleanedUpValues[key] = values[key]
                } else if (
                    (typeof values[key] === 'object' && values[key] instanceof Date)
                ) {
                    if (fields.find(el => el.value === key)?.type === 'datetime') {
                        cleanedUpValues[key] = moment(values[key]).format('YYYY-MM-DDThh:mm:ssZ')
                    } else {
                        cleanedUpValues[key] = moment(values[key]).format('YYYY-MM-DD')
                    }
                }
            })
        }

        setShowEditLoader(true)
        makeRequest.put(`${URLS.main}/accounts/${accountId}`, cleanedUpValues)
            .then((res) => {
                editCallback && editCallback({ connectedObjectsOptions, accountId, values: cleanedUpValues, response: res?.data })
                closeCreateEditAccountModal()
            })
            .catch((error) => {
                setShowEditLoader(false)
                setCreateOrUpdateRequestSent(false)
                setCreateEditError(error.response.data)
            })
    }

    if (!shown) return null
    return (
        <SalesforceCRUDModal
            header={header}
            disabledButton={!!createOrUpdateRequestSent}
            errorMessage={createEditError && getErrorMessage()}
            showLoader={showEditLoader}
            fields={fields}
            values={values}
            predefinedData={predefinedData}
            connectedObjectsOptions={connectedObjectsOptions}
            onClose={() => {
                closeCreateEditAccountModal()
                onClose()
            }}
            onClick={() => {
                if (edit) {
                    updateAccount()
                }
                if (create) {
                    createNewAccount()
                }
            }}
            onChange={(data, field) => {
                setOnlyChangedValues({
                    ...onlyChangedValues,
                    [field.value]: data
                })
                setValues({
                    ...values,
                    [field.value]: data
                })
            }}
        />
    )
}
