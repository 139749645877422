import React, { useEffect, useState } from 'react'
import moment from 'moment'
import { URLS } from '../../../../config'
import { makeRequest } from '../../../../utilities/endpoints'
import cn from './Details.module.css'
import { Row } from '../../../../components/Row'
import { Loader, LOADER_TYPES } from '../../../../components/Loader';
import { WorkspaceTypeCheck } from '../../../../components/WorkspaceTypeCheck';
import { DetailsRow } from './DetailsRow'
import { SalesForceReadOnlyInputComponent } from '../../../../components/SalesForceReadOnlyInputComponent'
import { NBSP_SIZES, Nbsp } from '../../../../components/Nbsp'
import { formatLargeSums } from '../../../../utilities/currency'
import { randInRange } from '../../../../utilities/random'
import { TrendingLine } from '../../../../assets/icons/TrendingLine'

export const Details = ({ accountDetails, numberOfPeopleWorking, inTasks }) => {
    // eslint-disable-next-line no-unused-vars
    const [usageSeatsData, setUsageSeatsData] = useState({
        seats: randInRange(100, 250),
        usedSeats: randInRange(80, 240)
    })

    const [expansionPotential, setExpansionPotential] = useState(null)
    const [seatsUsed, setSeatsUsed] = useState()

    const getExpansionPotential = id => {
        makeRequest.get(`${URLS.main}/account/${id}/expansion-potential`)
            .then(res => setExpansionPotential(res.data))
    }

    const getSeatsUsed = (id) => {
        makeRequest.get(`${URLS.main}/integrations/seats-used/${id}`)
            .then(res => {
                setSeatsUsed(res.data?.data)
            })
    }

    useEffect(() => {
        if (accountDetails?.id) {
            getExpansionPotential(accountDetails?.id)
            getSeatsUsed(accountDetails?.id)
        }
    }, [accountDetails?.id])

    return (
        <div className={cn.details}>
            <div className={cn.column}>
                <DetailsRow
                    label={'Owner'}
                    value={accountDetails?.owner?.name}
                />
                <WorkspaceTypeCheck requiredTypes={['SFDC', 'SFDC_SANDBOX']}>
                    <DetailsRow
                        label='Created by'
                        value={`${accountDetails?.createdBy}, ${moment(accountDetails?.createdDate).format('DD MMM, YYYY')}`}
                    />
                </WorkspaceTypeCheck>
                {!!numberOfPeopleWorking && !!numberOfPeopleWorking.length && (
                    <DetailsRow
                        label={'Employees'}
                        value={numberOfPeopleWorking[0]?.numberOfPeople}
                    />
                )}
                <WorkspaceTypeCheck requiredTypes={['CALLYPSO']}>
                    {(accountDetails?.customFields || []).map(el => (
                        <DetailsRow
                            label={el.label}
                            value={<SalesForceReadOnlyInputComponent {...el} />}
                        />
                    ))}
                </WorkspaceTypeCheck>
            </div>
            <div className={cn.column}>
                {!!accountDetails?.arr && (
                    <DetailsRow
                        label={'Annual Revenue'}
                        value={formatLargeSums('$', accountDetails.arr)}
                    />
                )}
                {expansionPotential && !!expansionPotential?.totalCrossSellPotential && (
                    <DetailsRow
                        label={'Cross Sell Potential'}
                        value={formatLargeSums('$', expansionPotential.totalCrossSellPotential)}
                        dataTip={expansionPotential.crossSellPotential.map(el => `${el.product} - ${formatLargeSums('$', el.potential)}`).join('<br/>')}
                    />
                )}
                {expansionPotential && !!expansionPotential?.totalUpSellPotential && (
                    <DetailsRow
                        label={'Up Sell Potential'}
                        value={formatLargeSums('$', expansionPotential.totalUpSellPotential)}
                        dataTip={expansionPotential.upSellPotential.map(el => `${el.product} - ${formatLargeSums('$', el.potential)}`).join('<br/>')}
                    />
                )}
                {usageSeatsData && !!accountDetails && accountDetails.seatsBoughtSetting !== -1 ? (
                    <DetailsRow
                        label={'Active Users vs Seats Bought'}
                        value={seatsUsed !== undefined ? (
                            `${seatsUsed} Users / ${accountDetails.seatsBoughtSetting} Seats =`
                        ) : <Loader type={LOADER_TYPES.SPINNER} />}
                        dataTip={!seatsUsed ? 'Missing Seats Used Data' : undefined}
                        postValue={
                            <>
                                <Nbsp size={NBSP_SIZES.SMALL}/>
                                {seatsUsed !== undefined && (
                                    <div className={cn.green}> {
                                        Math.round(seatsUsed / accountDetails.seatsBoughtSetting * 100)
                                    }%
                                    </div>
                                )}
                            </>
                        }
                    />
                ) : null}
            </div>
            {accountDetails?.id === '0010900000URsSrAAL' ? (
                <div className={cn.column}>
                    <DetailsRow
                        label={'Overall Usage Events this week'}
                        dataTip={'Down by 74 events compared to last week'}
                        postValue={(
                            <Row noMargin className={cn.red}>
                                231 -<Nbsp/> <span style={{ fontSize: 14 }}>Down by 74 events compared to last week</span>
                            </Row>
                        )}
                    />
                    <DetailsRow
                        label={'Overall Usage Events this month'}
                        dataTip={'Up by 12 events compared to last month'}
                        postValue={(
                            <Row noMargin className={cn.green}>
                                1039 -<Nbsp/> <span style={{ fontSize: 14 }}>Up by 12 events compared to last month</span>
                            </Row>
                        )}
                    />
                    <DetailsRow
                        label={'Percent of used licenses overall'}
                        value={(
                            <Row noMargin className={cn.green}>
                                100%
                            </Row>
                        )}
                    />
                    <DetailsRow
                        label={'Percent of used licenses last week.'}
                        dataTip={'10% less than last week. Trending downward'}
                        value={(
                            <Row columnFlex>
                                <Row noMargin className={cn.red} fullWidth>
                                    <div>
                                        80% -
                                    </div>
                                    <Nbsp/>
                                    <span style={{ fontSize: 14 }}>8 out of 10 active users</span>
                                    <Nbsp />
                                    <TrendingLine fill={'#CB3D2C'} />
                                </Row>
                                <i style={{ fontSize: 12, fontWeight: 300, textAlign: 'left', width: '100%' }}>Julien is moving to product management,<br/>so he will be using Callypso less.<br/>Slight reduction in usage over<br/>the next month is not indicative of risk!</i>
                            </Row>
                        )}
                    />
                    <DetailsRow
                        label={'Percent of used licenses last month'}
                        dataTip={'10% less than last month'}
                        value={(
                            <Row noMargin className={cn.red}>
                                90% -<Nbsp/> <span style={{ fontSize: 14 }}>9 out of 10 active users</span>
                            </Row>
                        )}
                    />
                </div>
            ) : null}
            {inTasks ? <br /> : null}
            <Row columnFlex wrap className={`${cn.rowColumn} ${inTasks ? cn.inTasks : ''}`}>
                {accountDetails?.additionalFields?.map(el => (
                    <>
                        <DetailsRow
                            label={el.label}
                            value={el.value || '-'}
                        />
                        <br />
                    </>
                ))}
            </Row>
        </div>
    )
}
