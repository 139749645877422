import React from 'react'
import cn from './DisabledBoolean.module.css'
import { getColorClass } from '../../../components/ProductUsageElement'

export const DisabledBoolean = ({ data, color }) => {
    const convertColorToIndex = (color) => {
        switch (color?.toUpperCase()) {
        case 'GREEN':
        case 'YES':
            return 3
        case 'YELLOW':
            return 2
        case 'RED':
        case 'NO':
            return 1
        default:
            return 0
        }
    }

    return <div className={cn.paidOnTimeWrapper}>
        <div className={`${cn.colorWrapper} ${getColorClass(convertColorToIndex(color))}`}>
            {data}
        </div>
    </div>
}