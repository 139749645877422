import React, { useMemo, useCallback } from 'react'
import { Editable, useSlate, Slate, withReact } from 'slate-react'
import {
    Editor,
    Transforms,
    Descendant,
    Element as SlateElement,
    createEditor,
} from 'slate'
import { withHistory } from 'slate-history'
import isHotkey from 'is-hotkey'
import { Button, Icon, Toolbar } from './SlateEditorComponents'
import cn from './SlateEditor.module.css'
import { isJSON } from '../utilities/isJSON'


const HOTKEYS = {
    'mod+b': 'bold',
    'mod+i': 'italic',
    'mod+u': 'underline',
    'mod+`': 'code',
}

const LIST_TYPES = ['numbered-list', 'bulleted-list']
const TEXT_ALIGN_TYPES = ['left', 'center', 'right', 'justify']


export const SlateEditor = ({ className, menuClassName, value, fullWidth, viewOnly, noMinHeight, smallFont, forwardedRef, onChange=() => {}, style }) => {
    const editor = useMemo(() => withHistory(withReact(createEditor())), [])
    const renderElement = useCallback(props => <Element {...props} />, [])
    const renderLeaf = useCallback(props => <Leaf {...props} />, [])

    const toggleMark = (editor, format) => {
        const isActive = isMarkActive(editor, format)
        if (isActive) {
            Editor.removeMark(editor, format)
        } else {
            Editor.addMark(editor, format, true)
        }
    }

    return (
        <div
            className={`
                ${cn.slateEditor}
                ${className}
                ${fullWidth ? cn.fullWidth : ''}
                ${viewOnly ? cn.viewOnly : ''}
                ${noMinHeight ? cn.noMinHeight : ''}
                ${smallFont ? cn.smallFont : ''}
            `}
            style={style}
            ref={forwardedRef}
        >
            <Slate
                editor={editor}
                value={isJSON(value) || initialValue}
                onChange={value => {
                    const isAstChange = editor.operations.some(
                        op => op.type !== 'set_selection'
                    )
                    if (isAstChange) {
                        const content = JSON.stringify(value)
                        onChange(content);
                    }
                }}
            >
                {!viewOnly && (
                    <Toolbar className={`${cn.slateToolbar} ${menuClassName}`}>
                        <MarkButton iconClassName={cn.slateButtons} format="bold" icon="faBold" />
                        <MarkButton iconClassName={cn.slateButtons} format="italic" icon="faItalic" />
                        <MarkButton iconClassName={cn.slateButtons} format="underline" icon="faUnderline" />
                        <MarkButton iconClassName={cn.slateButtons} format="code" icon="faCode" />
                        <BlockButton iconClassName={cn.slateButtons} format="block-quote" icon="faQuoteLeft" />
                        <BlockButton iconClassName={cn.slateButtons} format="heading-one" icon="faHeading" />
                        <BlockButton iconClassName={cn.slateButtons} format="numbered-list" icon="faListOl" />
                        <BlockButton iconClassName={cn.slateButtons} format="bulleted-list" icon="faList" />
                        <BlockButton iconClassName={cn.slateButtons} format="left" icon="faAlignLeft" />
                        <BlockButton iconClassName={cn.slateButtons} format="center" icon="faAlignCenter" />
                        <BlockButton iconClassName={cn.slateButtons} format="right" icon="faAlignRight" />
                        <BlockButton iconClassName={cn.slateButtons} format="justify" icon="faAlignJustify" />
                    </Toolbar>
                )}
                <Editable
                    className={cn.slateEditable}
                    renderElement={renderElement}
                    renderLeaf={renderLeaf}
                    spellCheck
                    style={{ 'position': 'inherit' }}
                    onKeyDown={event => {
                        for (const hotkey in HOTKEYS) {
                            if (isHotkey(hotkey, event)) {
                                event.preventDefault()
                                const mark = HOTKEYS[hotkey]
                                toggleMark(editor, mark)
                            } else if (event.key === 'Tab') {
                                event.preventDefault()
                            }
                        }
                    }}
                />
            </Slate>
        </div>
    )
}

const toggleBlock = (editor, format) => {
    const isActive = isBlockActive(
        editor,
        format,
        TEXT_ALIGN_TYPES.includes(format) ? 'align' : 'type'
    )
    const isList = LIST_TYPES.includes(format)

    Transforms.unwrapNodes(editor, {
        match: n => !Editor.isEditor(n) && SlateElement.isElement(n) && LIST_TYPES.includes(n.type) && !TEXT_ALIGN_TYPES.includes(format),
        split: true
    })
    let newProperties = {};
    if (TEXT_ALIGN_TYPES.includes(format)) {
        newProperties = {
            align: isActive ? undefined : format,
        }
    } else {
        newProperties = {
            type: isActive ? 'paragraph' : isList ? 'list-item' : format,
        }
    }
    Transforms.setNodes(editor, newProperties)

    if (!isActive && isList) {
        const block = { type: format, children: [] }
        Transforms.wrapNodes(editor, block)
    }
}

const toggleMark = (editor, format) => {
    const isActive = isMarkActive(editor, format)

    if (isActive) {
        Editor.removeMark(editor, format)
    } else {
        Editor.addMark(editor, format, true)
    }
}

const isBlockActive = (editor, format, blockType = 'type') => {
    const { selection } = editor
    if (!selection) return false

    const [match] = Array.from(
        Editor.nodes(editor, {
            at: Editor.unhangRange(editor, selection),
            match: n => !Editor.isEditor(n) && SlateElement.isElement(n) && n[blockType] === format,
        })
    )

    return !!match
}

const isMarkActive = (editor, format) => {
    const marks = Editor.marks(editor)
    return marks ? marks[format] === true : false
}

const Element = ({ attributes, children, element }) => {
    const style = { textAlign: element.align }
    switch (element.type) {
    case 'block-quote':
        return (
            <blockquote style={style} {...attributes}>
                {children}
            </blockquote>
        )
    case 'bulleted-list':
        return (
            <ul style={style} {...attributes}>
                {children}
            </ul>
        )
    case 'heading-one':
        return (
            <h1 style={style} {...attributes}>
                {children}
            </h1>
        )
    case 'heading-two':
        return (
            <h2 style={style} {...attributes}>
                {children}
            </h2>
        )
    case 'list-item':
        return (
            <li style={style} {...attributes}>
                {children}
            </li>
        )
    case 'numbered-list':
        return (
            <ol style={style} {...attributes}>
                {children}
            </ol>
        )
    case 'table':
        /*
        This was something I attempted to develop prior making my own table element.
        Given that it would have less functionality and take more time than even I had
        spent on my own table (Slate is good but… kind bare bones unless you know it
        REALLY well. Which I don’t at the moment). I thought I would leave this here
        because it’s *some* progress though no where near finished in case I want to
        take another crack at it in the future.
        */
        return (
            <table className={cn.slateTable} style={style} {...attributes}>
                <tbody {...attributes}>{children}</tbody>
            </table>
        )
    case 'table-row':
        return <tr {...attributes}>{children}</tr>
    case 'table-cell':
        return <td {...attributes}>{children}</td>
    default:
        return (
            <p style={style} {...attributes}>
                {children}
            </p>
        )
    }
}

const Leaf = ({ attributes, children, leaf }) => {
    if (leaf.bold) {
        children = <strong>{children}</strong>
    }

    if (leaf.code) {
        children = <code>{children}</code>
    }

    if (leaf.italic) {
        children = <em>{children}</em>
    }

    if (leaf.underline) {
        children = <u>{children}</u>
    }

    return <span {...attributes}>{children}</span>
}

const BlockButton = ({ format, icon, iconClassName }) => {
    const editor = useSlate()
    return (
        <Button
            active={isBlockActive(
                editor,
                format,
                TEXT_ALIGN_TYPES.includes(format) ? 'align' : 'type'
            )}
            onMouseDown={event => {
                event.preventDefault()
                toggleBlock(editor, format)
            }}
        >
            <Icon className={iconClassName} icon={icon} />
        </Button>
    )
}

const MarkButton = ({ format, icon, iconClassName }) => {
    const editor = useSlate()
    return (
        <Button
            active={isMarkActive(editor, format)}
            onMouseDown={event => {
                event.preventDefault()
                toggleMark(editor, format)
            }}
        >
            <Icon className={iconClassName} icon={icon} />
        </Button>
    )
}

const initialValue: Descendant[] = [
    {
        type: 'paragraph',
        children: [
            { text: '' },
        ],
    },
]
