import React from 'react'
import cn from './Div.module.css'

export const DIV_ELEMENT_TYPE = 'div'

export const Div = ({ children, missingProduct, isOpportunity, ...rest }) => (
    <div
        className={`${cn.inactiveTableElement} ${missingProduct ? cn.missingProduct : ''}`}
        data-tooltip-id='default'
        data-tooltip-content={missingProduct ? 'This Product is no longer active in your Price Book (in your SaleSforce instance)' : null}
        {...rest}
    >
        {children}
    </div>
)
