import React, { useRef } from 'react'
import 'react-contexify/dist/ReactContexify.css';
import { Item, Menu, Separator, useContextMenu } from 'react-contexify';
import { useOutsideClickHandler } from '../../../../customHooks/useOutsideClickHandler';
import cn from './EmptyTableElement.module.css'

export const EMPTY_TABLE_ELEMENT_TYPE = 'EmptyTableElement'

export const EmptyTableElement = ({ value, addColumn, addRow, deleteColumn, deleteRow, columnIndex, rowIndex, onChange }) => {
    const MENU_ID = `MENU_ID_${columnIndex}_${rowIndex}`
    const { show } = useContextMenu({
        id: MENU_ID,
    });
    const inputRef = useRef(null);

    useOutsideClickHandler(inputRef, () => {
        restoreParentZIndex()
        inputRef?.current.blur()
    });

    const increaseParentZIndex = () => {
        inputRef.current.parentNode.parentNode.style.zIndex = 100
    }

    const restoreParentZIndex = () => {
        inputRef.current.parentNode.parentNode.style.zIndex = 10
    }

    function handleContextMenu(event) {
        event.preventDefault();
        show(event)
        increaseParentZIndex()
    }

    return <div className={cn.tableElement}>
        <input
            onContextMenu={handleContextMenu}
            className={cn.tableInputElement}
            ref={inputRef}
            value={value}
            onChange={e => onChange(e.target.value, columnIndex, rowIndex)}
        />
        <Menu id={MENU_ID} className={cn.contextMenu}>
            <Item
                disabled={!deleteColumn}
                onClick={() => {
                    restoreParentZIndex()
                    deleteColumn()
                }}
            >
                Delete column
            </Item>

            <Separator />
            <Item
                onClick={() => {
                    restoreParentZIndex()
                    addColumn()
                }}
            >
                Add Colum to the right
            </Item>

            <Separator />
            <Item
                disabled={!deleteRow}
                onClick={() => {
                    restoreParentZIndex()
                    deleteRow()
                }}
            >
                Delete row
            </Item>

            <Separator />
            <Item
                onClick={() => {
                    restoreParentZIndex()
                    addRow()
                }}
            >
                Add Row below
            </Item>
        </Menu>
    </div>
}
