import React from 'react'
import cn from './Tabs.module.css'

export const Tabs = ({ options, selected, onChange, useIds }) => (
    <div className={cn.tabs}>
        {options.map((option, index) => {
            if (typeof option === 'string') {
                return (
                    <div
                        data-test-id={`TAB_${option}`}
                        key={option+index}
                        onClick={() => onChange(index)}
                        className={`${cn.option} ${index === selected ? cn.selected : ''}`}
                    >
                        {option}
                    </div>
                )
            }
            return (
                <div
                    data-test-id={`TAB_${option}`}
                    key={`${option?.label}+${index}`}
                    onClick={() => onChange(useIds ? option.id : index)}
                    className={`${cn.option} ${cn.row} ${(useIds ? option.id === selected : index === selected) ? cn.selected : ''}`}
                >
                    <div>
                        {option.icon && option.icon}
                    </div>
                    <div>
                        {option.label && option.label}
                    </div>
                </div>
            )
        })}
    </div>
)