import React, { useState } from 'react'
import { faTrashAlt } from '@fortawesome/free-solid-svg-icons'
import { ViewContainer } from '../../../components/ViewContainer'
import { Row } from '../../../components/Row'
import { SelectWrapperConnected } from '../../../components/SelectWrapper'
import { BUTTON_TYPES, Button } from '../../../components/Button'
import { URLS } from '../../../config'
import { makeRequest } from '../../../utilities/endpoints'
import { LOADER_SIZES, LOADER_TYPES, Loader } from '../../../components/Loader'

export const Reports = ({ settings = {}, onSettingChange }) => {
    const [reports, setReports] = useState([])
    const [loaded, setLoaded] = useState(false)
    const [newlySelectedReport, setNewlySelectedReport] = useState()

    let selectedReports = []
    try {
        selectedReports = JSON.parse(settings?.selectedHomePageReports)
    // eslint-disable-next-line no-empty
    } catch (e) {}

    const addReport = (name, id) => {
        if (selectedReports.map(el => el.id).includes(id)) return
        onSettingChange({
            ...settings,
            selectedHomePageReports: JSON.stringify([...selectedReports, { name, id }])
        })
    }

    const deleteReport = (id) => {
        onSettingChange({
            ...settings,
            selectedHomePageReports: JSON.stringify(selectedReports.filter(el => el.id !== id))
        })
    }

    const getReports = () => {
        makeRequest.get(`${URLS.main}/reports`)
            .then(res => {
                setReports(res.data.reports)
                setLoaded(true)
            })
    }

    return (
        <ViewContainer
            collapsible
            title={'Home Page Reports'}
            collapsedCallback={collapsed => {
                if (!collapsed && !reports?.length) {
                    getReports()
                }
            }}
        >
            {!loaded ? (
                <Loader type={LOADER_TYPES.SPINNER} size={LOADER_SIZES.BIG} />
            ) : (
                <>
                    {selectedReports.map(({ name, id }) => (
                        <Row spaceBetween>
                            <div>
                                {name}
                            </div>
                            <Button
                                type={BUTTON_TYPES.ICON}
                                icon={faTrashAlt}
                                className={'red'}
                                onClick={() => deleteReport(id)}
                            />
                        </Row>
                    ))}
                    <Row spaceBetween>
                        <SelectWrapperConnected
                            options={reports.map(el => ({
                                value: el.Id,
                                label: el.Name
                            }))}
                            value={newlySelectedReport}
                            onChange={setNewlySelectedReport}
                        />
                        <Button
                            disabled={!newlySelectedReport}
                            onClick={() => {
                                addReport(newlySelectedReport?.label, newlySelectedReport?.value)
                                setNewlySelectedReport(undefined)
                            }}
                        >
                            Add this report
                        </Button>
                    </Row>
                </>
            )}
        </ViewContainer>
    )
}