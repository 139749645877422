import React, { useCallback, useEffect, useState } from 'react'
import debounce from 'lodash.debounce'
import { faPlusSquare, faTrashAlt } from '@fortawesome/free-solid-svg-icons'
import cn from './GeneralSettings.module.css'
import { ViewContainer } from '../../../components/ViewContainer'
import { Row } from '../../../components/Row'
import { Label } from '../../../components/Label'
import { SalesForceInputComponent } from '../../../components/SalesForceInputComponent'
import { BUTTON_TYPES, Button } from '../../../components/Button'
import { Hr } from '../../../components/Hr'
import { TextArea } from '../../../components/TextArea'
import { translateCRMLabel } from '../../../utilities/translateCRMLabels'
import { SelectWrapperConnected } from '../../../components/SelectWrapper'
import { Nbsp } from '../../../components/Nbsp'


export const GeneralSettings = ({ customAccountFields, settings, onSettingChange }) => {
    const [activeAccountProperty, setActiveAccountProperty] = useState()
    const [activeAccountValue, setActiveAccountValue] = useState()
    const [accountOwnerProperty, setAccountOwnerProperty] = useState()
    const [accountARRProperty, setAccountARRProperty] = useState()
    const [additionalFiltering, setAdditionalFiltering] = useState([])
    const [emailsToBeIgnored, setEmailsToBeIgnored] = useState()

    useEffect(() => {
        if (!activeAccountProperty && !!settings && !!settings?.activeAccounts) {
            setActiveAccountProperty(customAccountFields.find(el => el.value === settings?.activeAccounts))
        }
    }, [settings, activeAccountProperty, customAccountFields])

    useEffect(() => {
        if (!activeAccountValue && !!settings && !!settings?.activeAccountsValue) {
            setActiveAccountValue(settings?.activeAccountsValue)
        }
    }, [settings, activeAccountValue, customAccountFields])

    useEffect(() => {
        if (!accountOwnerProperty && !!settings && !!settings?.accountOwner) {
            setAccountOwnerProperty(customAccountFields.find(el => el.value === settings?.accountOwner))
        }
    }, [settings, accountOwnerProperty, customAccountFields])

    useEffect(() => {
        if (!accountARRProperty && !!settings && !!settings?.accountARR) {
            setAccountARRProperty(customAccountFields.find(el => el.value === settings?.accountARR))
        }
    }, [settings, accountARRProperty, customAccountFields])

    useEffect(() => {
        if (!emailsToBeIgnored && !!settings && !!settings?.emailsToBeIgnored) {
            setEmailsToBeIgnored(settings.emailsToBeIgnored)
        }
    }, [settings, emailsToBeIgnored])

    useEffect(() => {
        try {
            if (settings && !!settings.additionalFiltering && JSON.parse(settings.additionalFiltering)?.length && !additionalFiltering.length && !!customAccountFields.length) {
                setAdditionalFiltering(JSON.parse(settings.additionalFiltering).map(el => ({
                    ...el,
                    object: customAccountFields.find(innerEl => innerEl.value === el.object)
                })))
            }
        } catch (e) {
            console.info('error found on general Settings', e)
        }
    }, [settings, additionalFiltering, customAccountFields])

    const addAdditionalFiltering = () => {
        setAdditionalFiltering([...additionalFiltering, {}])
    }


    const updateEmailsToIgnore = (string) => {
        onSettingChange({
            ...settings,
            emailsToBeIgnored: string
        })
    }

    const updateSizeDebounce = useCallback(
        debounce(updateEmailsToIgnore, 250),
        []
    );


    return <ViewContainer collapsible title='General Settings'>
        <Row>
            <Label className={cn.label}>Active {translateCRMLabel('Accounts')} Property</Label>
            <Nbsp />
            <SelectWrapperConnected
                onChange={data => {
                    setActiveAccountProperty(data)
                    onSettingChange({
                        ...settings,
                        activeAccounts: data.value
                    })
                }}
                options={customAccountFields}
                value={activeAccountProperty}
            />
            {activeAccountProperty?.value && (
                <>
                    <Nbsp />
                    <SalesForceInputComponent
                        {...customAccountFields.find(el => el.value === activeAccountProperty?.value)}
                        picklistValues={[
                            { label: 'Not NULL', value: 'NOT_NULL' },
                            ...(customAccountFields.find(el => el.value === activeAccountProperty?.value)?.picklistValues || [])
                        ]}
                        labelClassName={cn.hideLabel}
                        value={activeAccountValue}
                        onChange={data => {
                            setActiveAccountValue(data)
                            onSettingChange({
                                ...settings,
                                activeAccountsValue: data
                            })
                        }}
                    />
                </>
            )}
            {!!activeAccountProperty && (
                <>
                    <Nbsp />
                    <Button
                        type={BUTTON_TYPES.ICON}
                        icon={faTrashAlt}
                        onClick={() => {
                            setActiveAccountProperty(null)
                            onSettingChange({
                                ...settings,
                                activeAccounts: null
                            })
                        }}
                    />
                </>
            )}
        </Row>

        <Row>
            <Label className={cn.label}>{`${translateCRMLabel('Account')} Owner Property`}</Label>
            <Nbsp />
            <SelectWrapperConnected
                onChange={data => {
                    setAccountOwnerProperty(data)
                    onSettingChange({
                        ...settings,
                        accountOwner: data.value
                    })
                }}
                options={customAccountFields}
                value={accountOwnerProperty}
            />
            {!!accountOwnerProperty && (
                <>
                    <Nbsp />
                    <Button
                        type={BUTTON_TYPES.ICON}
                        icon={faTrashAlt}
                        onClick={() => {
                            setAccountOwnerProperty(null)
                            onSettingChange({
                                ...settings,
                                accountOwner: null
                            })
                        }}
                    />
                </>
            )}
        </Row>

        <Row>
            <Label className={cn.label}>Account ARR</Label>
            <Nbsp />
            <SelectWrapperConnected
                onChange={data => {
                    setAccountARRProperty(data)
                    onSettingChange({
                        ...settings,
                        accountARR: data.value
                    })
                }}
                options={customAccountFields}
                value={accountARRProperty}
            />
            {!!accountARRProperty && (
                <>
                    <Nbsp />
                    <Button
                        type={BUTTON_TYPES.ICON}
                        icon={faTrashAlt}
                        onClick={() => {
                            setAccountARRProperty(null)
                            onSettingChange({
                                ...settings,
                                accountARR: null
                            })
                        }}
                    />
                </>
            )}
        </Row>

        <Hr />
        <br />
        <Row noMargin>
            <Label className={cn.label}>Additional Filtering of {translateCRMLabel('Accounts')}</Label>
            <Nbsp />
            <Button
                onClick={addAdditionalFiltering}
                icon={faPlusSquare}
                type={BUTTON_TYPES.ICON}
            />
        </Row>
        {additionalFiltering?.map((el, i) => (
            <Row key={el.object + el.value}>
                <Label className={cn.label}>Active {translateCRMLabel('Accounts')} Property</Label>
                <Nbsp />
                <SelectWrapperConnected
                    onChange={data => {
                        setAdditionalFiltering(additionalFiltering.map((innerEl, innerI) => {
                            if (i === innerI) {
                                return {
                                    ...innerEl,
                                    object: data
                                }
                            }
                            return {
                                ...innerEl,
                            }
                        }))
                    }}
                    options={customAccountFields}
                    value={el.object}
                />
                {el?.object?.value && (
                    <>
                        <Nbsp />
                        <SalesForceInputComponent
                            {...customAccountFields.find(customAccountField => customAccountField.value === el?.object?.value)}
                            labelClassName={cn.hideLabel}
                            value={el.value}
                            onChange={data => {
                                const remapped = additionalFiltering.map((innerEl, innerI) => {
                                    if (i === innerI) {
                                        return {
                                            ...innerEl,
                                            value: data
                                        }
                                    }
                                    return {
                                        ...innerEl,
                                    }
                                })
                                setAdditionalFiltering(remapped)
                                onSettingChange({
                                    ...settings,
                                    additionalFiltering: JSON.stringify(remapped.map(el => ({
                                        ...el,
                                        object: el.object.value
                                    })))
                                })
                            }}
                        />
                    </>
                )}
                <Nbsp />
                <Button
                    type={BUTTON_TYPES.ICON}
                    icon={faTrashAlt}
                    onClick={() => {
                        const remapped = additionalFiltering.filter((_el, innerI) => i !== innerI)
                        setAdditionalFiltering(remapped)
                        onSettingChange({
                            ...settings,
                            additionalFiltering: JSON.stringify(remapped)
                        })
                    }}
                />
            </Row>
        ))}
        <Row>
            <Label className={cn.label}>
                Ignore test emails when matching meetings
            </Label>
            <Nbsp />
            <TextArea
                value={emailsToBeIgnored}
                onChange={(e => {
                    setEmailsToBeIgnored(e.target.value)
                    updateSizeDebounce(e.target.value)
                })}
            />
        </Row>
    </ViewContainer>
}