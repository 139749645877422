import React, { useEffect, useState } from 'react'
import { useHistory } from "react-router-dom";
import { faChartLine, faClipboard, faMapLocation, faMicrochip, faPeopleArrows } from '@fortawesome/free-solid-svg-icons'
import cn from './AccountActions.module.css'
import { BUTTON_TYPES, Button } from './Button'
import { PermissionCheck } from './PermissionCheck'
import { AccountsCRUDModalWrapper } from './AccountsCRUDModalWrapper'
import { MODAL_SIZES, Modal } from './Modal';
import { URLS } from '../config';
import { makeRequest } from '../utilities/endpoints';
import { workspaceTypeCheck } from '../utilities/workspaceType';
import { Row } from './Row';
import { Checkbox } from './Checkbox';
import { PopoverGithubPicker } from './PopoverGithubPicker';
import { NBSP_SIZES, Nbsp } from './Nbsp';
import store from '../store';
import { addNotification } from '../store/actions';
import { LOADER_SIZES, LOADER_TYPES, Loader } from './Loader';
import { AddTask } from '../views/tasks/AddTask';
import { translateCRMLabel } from '../utilities/translateCRMLabels';
import { Trash } from '../assets/icons/Trash';
import { Pen } from '../assets/icons/Pen';
import { SelectWrapperConnected } from './SelectWrapper';

export const AccountActions = ({ account, onDelete, onEdit, onReassign, onShowUsage }) => {
    const [accountToBeEditted, setAccountToBeEditted] = useState(null)
    const [accountToBeDeleted, setAccountToBeDeleted] = useState(null)
    const [accountToBeReassigned, setAccountToBeReassigned] = useState(null)
    const [users, setUsers] = useState(null)
    const [newAccountOwner, setNewAccountOwner] = useState(null)
    const [potentialTasks, setPotentialTasks] = useState([])
    const [selectedTasks, setSelectedTasks] = useState([])
    const [helpLoader, setHelpLoader] = useState(false)
    const [completed, setCompleted] = useState(false)
    const [showAddNewTask, setShowAddNewTask] = useState(false)
    const [predefinedNewTaskData, setPredefinedNewTaskData] = useState(false)
    const [taskCreationSent, setTaskCreationSent] = useState(false)
    const [expansionChoiceShown, setExpansionChoiceShown] = useState(false)
    const [expansionType, setExpansionType] = useState(null)
    const [helpRequestReference, setHelpRequestReference] = useState(null)
    const [helpRequestInterval, setHelpRequestInterval] = useState(null)
    const [helpId, setHelpId] = useState(null)
    const [convertRequestSent, setConvertRequestSent] = useState(false)

    const history = useHistory()

    const loadUsers = () => {
        makeRequest.get(`${URLS.main}/workspace/users`, 2)
            .then(res => setUsers(res.data?.users))
    }


    const getUsersForReassignment = () => {
        if (workspaceTypeCheck(['HUBSPOT'])) {
            return (users || []).filter(el => !!el.hubspotUserId).map(el => ({
                value: el.hubspotUserId,
                label: el.fullName || el.email
            }))
        }
        return (users || []).map(el => ({
            value: el.sfUserId || el.id,
            label: el.fullName || el.email
        }))
    }

    useEffect(() => {
        if (accountToBeReassigned && !users) {
            loadUsers()
        }
    }, [users, accountToBeReassigned])

    const deleteAccount = () => {
        makeRequest.delete(`${URLS.main}/account/${accountToBeDeleted}`)
            .then(() => {
                setAccountToBeDeleted(null);
                onDelete();
            })
            .catch(error => {
                setAccountToBeDeleted(null);
                onDelete();
                console.error(error);
            });
    }

    const reassignAccount = () => {
        let ownerId = users.find(el => el.id === newAccountOwner?.value)?.sfUserId
        if (workspaceTypeCheck(['CALLYPSO']) || workspaceTypeCheck(['HUBSPOT'])) {
            ownerId = newAccountOwner?.value
        }
        makeRequest.put(`${URLS.main}/account/changeOwner`, {
            accountId: accountToBeReassigned.accountId,
            ownerId: ownerId
        })

        onReassign(workspaceTypeCheck(['CALLYPSO']) || workspaceTypeCheck(['HUBSPOT']) ? newAccountOwner?.value : users.find(el => el.id === newAccountOwner?.value)?.sfUserId)
        setAccountToBeReassigned(null)
        setNewAccountOwner(null)
    }

    const getHelpDetails = id => {
        const interval = setInterval(() => {
            const request = makeRequest.get(`${URLS.main}/suggested-plays/${id}`)
            setHelpRequestReference(request)
            request.then(res => {
                if (!!res.data.completed) {
                    clearInterval(interval)
                    setCompleted(true)
                }
                const tasks = res.data?.data
                setSelectedTasks(new Array(tasks.length).fill(1))
                setPotentialTasks(tasks)
            })
        }, 1500)
        setHelpRequestInterval(interval)
    }

    const getHelp = () => {
        setHelpLoader(true)
        setCompleted(false)
        if (account.expansionData && expansionType) {
            account.expansionData.expansionType = expansionType
        }
        makeRequest.post(`${URLS.main}/suggested-plays`, {
            ...account,
            name: account.account,
        })
            .then(res => {
                setHelpId(res.data.id)
                getHelpDetails(res.data.id)
            })
    }

    const hideAddTaskModal = () => setShowAddNewTask(false)

    const createManualTask = (newTaskData) => {
        setTaskCreationSent(true)
        makeRequest.post(`${URLS.main}/tasks`, {
            ...newTaskData,
            dueDate: (newTaskData.dueDate ? new Date(newTaskData.dueDate) : new Date()).getTime(),
            accountId: newTaskData.accountId
        })
            .then(() => {
                setTaskCreationSent(false)
                hideAddTaskModal()
                store.dispatch(addNotification({
                    message: 'The manual play has been added',
                }))
            })
    }

    const convertToPlaybook = () => {
        setConvertRequestSent(true)
        makeRequest.post(`${URLS.main}/suggested-plays/convert/playbook`, {
            id: helpId,
            selectedTasks
        })
            .then(res => {
                history.push(`/playbook/${res.data.id}`)
            })
    }

    const resetSuggestedTasks = () => {
        setPotentialTasks([])
        setHelpLoader(false)
        setExpansionChoiceShown(false)
        setExpansionType(null)
        setConvertRequestSent(false)
        helpRequestReference?.cancel()
        clearInterval(helpRequestInterval)
    }

    useEffect(() => {
        if (!expansionChoiceShown && expansionType) {
            getHelp()
        }
    }, [expansionChoiceShown, expansionType])

    const preChoiceShown = !expansionChoiceShown && !!helpLoader

    const potentialCrossSell = (account?.expansionData?.products || []).map(el => el.potentialCrossSell).reduce((a, b) => a + b, 0)
    const potentialUpsell = (account?.expansionData?.products || []).map(el => el.potentialUpsell).reduce((a, b) => a + b, 0)

    return (
        <>
            <div className={cn.actions}>
                <PermissionCheck requiredPermissions={[['ACCOUNTS', 'UPDATE']]}>
                    <Button
                        data-tooltip-id='default'
                        data-tooltip-content={`Edit ${translateCRMLabel('Account')}`}
                        type={BUTTON_TYPES.SMALL_ICON}
                        customIcon={<Pen />}
                        onClick={() => setAccountToBeEditted(account)}
                    />
                    <Button
                        data-tooltip-id='default'
                        data-tooltip-content={`Delete ${translateCRMLabel('Account')}`}
                        type={BUTTON_TYPES.SMALL_ICON}
                        customIcon={<Trash />}
                        onClick={() => setAccountToBeDeleted(account.id)}
                    />
                    {!!account?.ownerId || workspaceTypeCheck(['HUBSPOT']) ? (
                        <Button
                            data-tooltip-id='default'
                            data-tooltip-content={`Change ${translateCRMLabel('Account')} Owner`}
                            type={BUTTON_TYPES.SMALL_ICON}
                            icon={faPeopleArrows}
                            onClick={() => {
                                setAccountToBeReassigned({
                                    accountId: account.id,
                                    ownerId: account.ownerId || account.hubspot_owner_id
                                })
                            }}
                        />
                    ) : null}
                    <Button
                        data-tooltip-id='default'
                        data-tooltip-content='See product usage trends'
                        icon={faChartLine}
                        type={BUTTON_TYPES.SMALL_ICON}
                        onClick={onShowUsage}
                    />
                </PermissionCheck>
                <Button
                    data-tooltip-id='default'
                    data-tooltip-content={`${translateCRMLabel('Account')} Map`}
                    type={BUTTON_TYPES.SMALL_ICON}
                    icon={faMapLocation}
                    onClick={() => history.push(`/account/${account.id}/map`)}
                />
                {!!account.expansionData || !!account.riskData ? (
                    <Button
                        data-tooltip-id='default'
                        data-tooltip-content='Suggested Plays'
                        type={BUTTON_TYPES.SMALL_ICON}
                        disabled={!!account.expansionData && !potentialCrossSell && !potentialUpsell}
                        icon={faMicrochip}
                        onClick={() => {
                            if (!!account.expansionData) {
                                if (!!potentialCrossSell && !!potentialUpsell) {
                                    setExpansionChoiceShown(true)
                                } else if (potentialUpsell) {
                                    setExpansionType('UP_SELL')
                                    setExpansionChoiceShown(false)
                                } else if (potentialCrossSell) {
                                    setExpansionType('CROSS_SELL')
                                    setExpansionChoiceShown(false)
                                }
                            } else {
                                getHelp()
                            }
                        }}
                    />
                ) : null}
                <Button
                    data-tooltip-id='default'
                    data-tooltip-content={`Add a Play to this ${translateCRMLabel('Account')}`}
                    type={BUTTON_TYPES.SMALL_ICON}
                    icon={faClipboard}
                    onClick={e => {
                        resetSuggestedTasks()
                        setShowAddNewTask(true)
                        setPredefinedNewTaskData({
                            accountId: account.id
                        })
                        e.preventDefault()
                        e.stopPropagation()
                    }}
                />
            </div>
            <AccountsCRUDModalWrapper
                header={`Editing ${translateCRMLabel('Account')}`}
                shown={!!accountToBeEditted}
                accountId={accountToBeEditted?.id}
                edit={true}
                editCallback={() => {
                    setAccountToBeEditted(null)
                    onEdit()
                }}
                onClose={() => setAccountToBeEditted(null)}
            />
            {accountToBeDeleted && (
                <Modal
                    onClose={() => setAccountToBeDeleted(null)}
                    header={`Delete ${translateCRMLabel('Account')}`}
                    buttons={(
                        <Row spaceBetween fullWidth>
                            <Button
                                onClick={deleteAccount}
                            >
                                Yes, Delete it
                            </Button>
                            <Button
                                onClick={() => setAccountToBeDeleted(null)}
                            >
                                No, Cancel
                            </Button>
                        </Row>
                    )}
                >
                    Are you sure you'd want to delete this Account from your Sales Force instance?
                    <br /><br />
                    This action might be irrevirsible! Please consider it carefully.
                </Modal>
            )}
            {accountToBeReassigned && (<Modal
                minHeightSmall
                onClose={() => setAccountToBeReassigned(null)}
                header={`Change ${translateCRMLabel('Account')} Owner`}
                buttons={(
                    <Button
                        disabled={!newAccountOwner?.value}
                        onClick={reassignAccount}
                    >
                        Set new owner
                    </Button>
                )}
            >
                <div className={cn.accountReassignWrapper}>
                    <SelectWrapperConnected
                        onChange={setNewAccountOwner}
                        options={getUsersForReassignment()}
                        value={newAccountOwner || getUsersForReassignment().find(el => {
                            return el.value === accountToBeReassigned.ownerId || el.value === accountToBeReassigned.hubspot_owner_id
                        })}
                    />
                </div>
            </Modal>
            )}
            {(!!helpLoader || !!expansionChoiceShown) && (
                <Modal
                    header={'Suggested Tasks'}
                    onClose={resetSuggestedTasks}
                    size={MODAL_SIZES.BIGGER}
                    sizerClassName={cn.suggestedTasksModal}
                    buttons={preChoiceShown ? (<Row fullWidth endAlign>
                        <Button
                            fullWidth
                            disabled={!completed || convertRequestSent}
                            onClick={convertToPlaybook}
                        >
                            Convert Selected Plays to a Playbook
                        </Button>
                    </Row>
                    ) : null}
                >
                    {!!potentialTasks.length ? potentialTasks.map((el, i) => (
                        <div>
                            <Row>
                                <Checkbox
                                    checked={selectedTasks[i]}
                                    onClick={() => {
                                        const updated = [...selectedTasks]
                                        updated[i] = !updated[i]
                                        setSelectedTasks(updated)
                                    }}
                                />
                                <PopoverGithubPicker color={el.color} />
                                <Nbsp />
                                {el.name}
                            </Row>
                            <div className={cn.description}>
                                {el.description}
                            </div>
                        </div>
                    )) : preChoiceShown ? <Loader type={LOADER_TYPES.SPINNER} smallerMargin size={LOADER_SIZES.BIG} /> : (
                        <div className={cn.chooseHelp}>
                            <span>Get Help with:</span>
                            <Row spaceBetween>
                                <Button
                                    onClick={() => {
                                        setExpansionType('UP_SELL')
                                        setExpansionChoiceShown(false)
                                    }}
                                >
                                    Up Selling into the account
                                </Button>
                                <Nbsp size={NBSP_SIZES.BIGGER} />
                                <Button
                                    onClick={() => {
                                        setExpansionType('CROSS_SELL')
                                        setExpansionChoiceShown(false)
                                    }}
                                >
                                    Cross Selling into the account
                                </Button>
                            </Row>
                        </div>
                    )}
                    <Row noMargin spaceBetween>
                        <div className={cn.openAiDisclaimer}>Powered by OpenAI's ChatGPT</div>
                        {preChoiceShown && (
                            <div className={cn.openAiDisclaimer}>{completed ? 'Done' : 'Processing the information and generating Play suggestions'}</div>
                        )}
                    </Row>
                </Modal>
            )}
            {showAddNewTask && (
                <AddTask
                    onClose={hideAddTaskModal}
                    disabledButton={taskCreationSent}
                    onCreate={createManualTask}
                    availableAccounts={[account]}
                    predefinedData={predefinedNewTaskData}
                />
            )}
        </>
    )
}