import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { useParams } from 'react-router-dom';
import { faPencilAlt, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { ViewContainer } from '../../components/ViewContainer'
import { Loader, LOADER_SIZES, LOADER_TYPES } from '../../components/Loader';
import { Row } from '../../components/Row';
import { URLS } from '../../config';
import { makeRequest } from '../../utilities/endpoints';
import { formatLargeSums } from '../../utilities/currency';
import cn from './OpportunitiesManagement.module.css'
import { Dropdown } from '../../components/Dropdown';
import { Label } from '../../components/Label';
import { Modal } from '../../components/Modal';
import { BUTTON_TYPES, Button } from '../../components/Button';
import { PopoverGithubPicker } from '../../components/PopoverGithubPicker';
import { PermissionCheck } from '../../components/PermissionCheck';
import { getUserPermissions, permissionCheck } from '../../utilities/permissionCheck';
import { OpportunitiesCRUDModalWrapper } from '../../components/OpportunitiesCRUDModalWrapper';
import { ComplexDropdown } from '../../components/ComplexDropdown';
import { Input } from '../../components/Input';
import { workspaceTypeCheck } from '../../utilities/workspaceType';
import { WorkspaceTypeCheck } from '../../components/WorkspaceTypeCheck';
import { translateCRMLabel } from '../../utilities/translateCRMLabels';
import { SelectWrapperConnected } from '../../components/SelectWrapper';
import { getUserCrmId } from '../../utilities/userCrmId';

const colors = [
    '#039be5',
    '#7986cb',
    '#33b679',
    '#8e24aa',
    '#e67c73',
    '#f6c026',
    '#f5511d',
    '#039be5',
    '#616161',
    '#3f51b5',
    '#3651F0',
    '#3EB984',
    '#BB4ADF',
    '#F57E34',
    '#0b8043',
    '#d60000',
]

const defaultDate = {
    start: moment().startOf('month').valueOf(),
    end: moment().endOf('month').valueOf(),
    label: 'This Month'
}

export const Board = () => {
    getUserPermissions()
    const remappedColumns = {}
    const { id } = useParams()
    const [opportunityToEdit, setOpportunityToEdit] = useState(null)
    const [selectedUser, setSelectedUser] = useState(null)
    const [selectedCloseDatePeriod, setSelectedCloseDatePeriod] = useState()
    const [selectedCreateDatePeriod, setSelectedCreateDatePeriod] = useState({ label: ' - ' })
    const [selectedTypeFilter, setSelectedTypeFilter] = useState()
    const [defaultOpportunityManagementType, setDefaultOpportunityManagementType] = useState(-1)
    const [usersInTheWorkspace, setUsersInTheWorkspace] = useState([])
    const [teams, setTeams] = useState([])
    const [opportunities, setOpportunities] = useState(null)
    const [allOpportunities, setAllOpportunities] = useState(null)
    const [columnsNames, setColumnsNames] = useState(null)
    const [columns, setColumns] = useState(remappedColumns);
    const [opportunityTypes, setOpportunityTypes] = useState([])
    const [loaded, setLoaded] = useState(false)
    const [shouldshowColorPicker, setShouldShowColorPicker] = useState(false)
    const [pipelines, setPipelines] = useState(null)
    const [selectedPipeline, setSelectedPipeline] = useState()

    const [stagesDropdownData, setStagesDropdownData] = useState([])
    const [stagesToDisplay, setStagesToDisplay] = useState([])
    const [stagesToDisplayTemp, setStagesToDisplayTemp] = useState([])

    const [creatingNewOpportunity, setCreatingNewOpportunity] = useState(false)
    const [createEditError, setCreateEditError] = useState(false)

    const [pushCloseDateNumber, setPushCloseDateNumber] = useState(1)
    const [pushCloseDatePeriod, setPushCloseDatePeriod] = useState('months')

    const [opportunityToRemove, setOpportunityToRemove] = useState(null)
    const [timeInStageFilter, setTimeInStageFilter] = useState(null)

    const [showFilters, setShowFilters] = useState(false)

    const showColorPicker = index => setShouldShowColorPicker(index)
    const hideColorPicker = () => setShouldShowColorPicker(false)

    const onDragEnd = (result) => {
        if (!result.destination) return;
        const { source, destination, draggableId } = result;

        if (source.droppableId !== destination.droppableId) {
            const sourceColumn = columns[source.droppableId];
            const destColumn = columns[destination.droppableId];
            const sourceItems = [...sourceColumn.items];
            const destItems = [...destColumn.items];
            const [removed] = sourceItems.splice(source.index, 1);
            destItems.splice(destination.index, 0, removed);
            setColumns({
                ...columns,
                [source.droppableId]: {
                    ...sourceColumn,
                    items: sourceItems
                },
                [destination.droppableId]: {
                    ...destColumn,
                    items: destItems
                }
            });
            updateOpportunityStage(draggableId, destColumn.id, sourceColumn.id)
        } else {
            const column = columns[source.droppableId];
            const copiedItems = [...column.items];
            const [removed] = copiedItems.splice(source.index, 1);
            copiedItems.splice(destination.index, 0, removed);
            setColumns({
                ...columns,
                [source.droppableId]: {
                    ...column,
                    items: copiedItems
                }
            });
        }
    };

    useEffect(() => {
        if (pipelines && defaultOpportunityManagementType !== -1) {
            getOpportunities({
                selectedUser,
                selectedCloseDatePeriod,
                selectedCreateDatePeriod,
                timeInStageFilter,
                pipelineId: pipelines[0]?.id,
                selectedTypeFilter: selectedTypeFilter || defaultOpportunityManagementType
            })
        }
    }, [pipelines, defaultOpportunityManagementType])

    useEffect(() => {
        if (defaultOpportunityManagementType !== -1) {
            if (workspaceTypeCheck(['HUBSPOT'])) {
                getPipelines()
                // for Hubspot we need to get the pipelines before we can get the stages and from there on the opps/deals
                // The rest of the retrieval is done in the useEffect above
            } else {
                getOpportunities({
                    selectedUser,
                    selectedCloseDatePeriod,
                    selectedCreateDatePeriod,
                    timeInStageFilter,
                    selectedTypeFilter: selectedTypeFilter || defaultOpportunityManagementType
                })
            }
            getOpportunityStages()
            getUsers()
            getTeams()
            if (id) {
                console.info('about to edit', id)
                editOpportunity(id)
            }
        } else {
            getDefaultType()
        }
    }, [defaultOpportunityManagementType])

    useEffect(() => {
        const columnsFromBackend = []
        if (columnsNames && opportunities) {
            columnsNames.forEach((el, i) => {
                columnsFromBackend.push({
                    name: el,
                    items: [],
                    id: el,
                    color: colors[i % colors.length]
                })
            })

            for (let i = 0; i < columnsFromBackend.length; i++) {
                columnsFromBackend[i].items = opportunities.filter(el => el.StageName === columnsFromBackend[i].name)
            }

            columnsFromBackend.forEach(({ id, ...rest }) => {
                remappedColumns[id] = rest
            })
            setColumns(columnsFromBackend)
            setLoaded(true)
        }
    }, [columnsNames, opportunities])

    const getUsers = () => {
        makeRequest.get(`${URLS.main}/workspace/users`)
            .then(res => {
                if (res && res.data && res.data.success) {
                    setUsersInTheWorkspace(res.data.users)
                }
            })
    }

    const getTeams = () => {
        makeRequest.get(`${URLS.main}/teams`)
            .then(res => {
                if (res && res.data) {
                    setTeams(res.data.teams)
                }
            })
    }

    const getOpportunityStages = () => {
        makeRequest.get(`${URLS.main}/opportunities/stages`)
            .then(res => {
                if (res && res.data) {
                    setStagesDropdownData(res.data.stages)
                    setColumnsNames(res.data.stages?.map(el => el.label) || [])
                    setOpportunityTypes(res.data.types)
                }
            })
    }

    const getPipelines = () => {
        makeRequest.get(`${URLS.main}/pipelines`)
            .then(res => {
                setPipelines(res.data.map(el => ({
                    ...el,
                    value: el.id
                })))
                setSelectedPipeline(res.data[0]?.id)
                if (res.data[0]) {
                    setStagesDropdownData(res.data[0].stages)
                    setColumnsNames(res.data[0].stages?.map(el => el.label) || [])
                }
            })
    }

    const getOpportunities = ({ selectedUser, selectedCloseDatePeriod, selectedCreateDatePeriod, timeInStageFilter, pipelineId, selectedTypeFilter }, hideLoader) => {
        makeRequest.get(
            `${URLS.main}/opportunities/${selectedUser}/${selectedCloseDatePeriod?.start}/${selectedCloseDatePeriod?.end}/${selectedCreateDatePeriod?.start}/${selectedCreateDatePeriod?.end}/${timeInStageFilter}/${pipelineId}/${selectedTypeFilter}`
        ).then(res => {
            if (res && res.data) {
                setOpportunities(res.data || [])
                setAllOpportunities(res.data || [])
            }
            if (hideLoader) {
                setLoaded(true)
            }
        })
        setOpportunities([])
        setAllOpportunities([])
    }

    const updateSelectedPipeline = id => {
        setSelectedPipeline(id)
        getOpportunities({
            selectedUser,
            selectedCloseDatePeriod,
            selectedCreateDatePeriod,
            timeInStageFilter,
            pipelineId: id,
            selectedTypeFilter: selectedTypeFilter || defaultOpportunityManagementType
        })
    }

    const updateOpportunityStage = (id, stage, oldStage) => {
        const dataToSubmit = {}
        const additionalData = {}
        if (workspaceTypeCheck(['HUBSPOT'])) {
            const activePipeline = pipelines.find(el => el.id === selectedPipeline)
            const stageData = activePipeline.stages.find(el => el.label === stage)
            const stageId = stageData?.id
            dataToSubmit.dealstage = stageId

            if (stageData) {
                additionalData.StageName = stageData.label
                additionalData.Probability = stageData.metadata.probability * 100
            }
        } else {
            dataToSubmit.StageName = stage
        }
        makeRequest.put(`${URLS.main}/opportunities/${id}`, dataToSubmit)
            .then(res => {
                if (res && res.data) {
                    const updateOpportunities = opps => {
                        return opps.map(el => {
                            // eslint-disable-next-line eqeqeq
                            if (el.Id == id) {
                                return {
                                    ...el,
                                    ...res.data,
                                    ...additionalData
                                }
                            }
                            return el
                        })
                    }
                    setOpportunities(updateOpportunities(opportunities))
                    setAllOpportunities(updateOpportunities(allOpportunities))
                }
            })
            .catch(({ response }) => {
                setCreateEditError(response.data)
                const updateOpportunities = opps => {
                    return opps.map(el => {
                        if (el.Id === id) {
                            return ({
                                ...el,
                                StageName: oldStage
                            })
                        }
                        return el
                    })
                }
                setOpportunities(updateOpportunities(opportunities))
                setAllOpportunities(updateOpportunities(allOpportunities))
                editOpportunity(id)
            })
    }

    const updateSelectedUser = userId => {
        if (userId !== selectedUser) {
            setSelectedUser(userId)
            setOpportunities(null)
            setAllOpportunities(null)
            setLoaded(false)
            getOpportunities({
                selectedUser: userId,
                selectedCloseDatePeriod,
                selectedCreateDatePeriod,
                timeInStageFilter,
                pipelineId: selectedPipeline,
                selectedTypeFilter: selectedTypeFilter || defaultOpportunityManagementType
            })
        }
    }

    const editOpportunity = id => {
        setOpportunityToEdit(id)
    }

    const getCreateOpportunityFields = () => {
        setCreatingNewOpportunity(true)
    }

    const deleteOpportunity = () => {
        const idToRemove = opportunityToRemove
        makeRequest.delete(`${URLS.main}/opportunities/${idToRemove}`)
            .then(() => {
                setOpportunities(opportunities.filter(el => el.Id !== idToRemove))
                setAllOpportunities(allOpportunities.filter(el => el.Id !== idToRemove))
            })
            .catch(() => {
            });
        setOpportunityToRemove(null)
    }

    const closeFilterModal = () => setShowFilters(false)

    const setFilters = () => {
        closeFilterModal()
        setStagesToDisplay(stagesToDisplayTemp)
        getOpportunities({
            selectedUser,
            selectedCloseDatePeriod,
            selectedCreateDatePeriod,
            timeInStageFilter,
            pipelineId: selectedPipeline,
            selectedTypeFilter: selectedTypeFilter || defaultOpportunityManagementType
        })
    }

    const clearFilters = () => {
        setSelectedCloseDatePeriod(undefined)
        setSelectedCreateDatePeriod({ label: ' - ' })
        setTimeInStageFilter(null)
        setStagesToDisplay([])
        setStagesToDisplayTemp([])
        getOpportunities({
            selectedUser,
            selectedCloseDatePeriod: undefined,
            pipelineId: selectedPipeline,
        })

        closeFilterModal()
    }

    const getDefaultType = () => {
        makeRequest.get(`${URLS.main}/settings/defaultOpportunityManagementType`)
            .then(res => {
                setDefaultOpportunityManagementType(res.data && res.data.length ? res.data : undefined)
            })
    }

    const pushCloseDate = async () => {
        setLoaded(false)
        await makeRequest.put(`${URLS.main}/opportunities/pushCloseDate/${pushCloseDateNumber}/${pushCloseDatePeriod}`, {
            opportunities: opportunities.map(el => ({
                Id: el.Id,
                CloseDate: el.CloseDate
            }))
        })
        getOpportunities({
            selectedUser,
            selectedCloseDatePeriod,
            selectedCreateDatePeriod,
            timeInStageFilter,
            pipelineId: selectedPipeline,
            selectedTypeFilter: selectedTypeFilter || defaultOpportunityManagementType
        }, true)
    }

    if (!loaded) {
        return (
            <ViewContainer className={cn.opportunitiesManagementWrapper}>
                <Loader size={LOADER_SIZES.EXTRA_BIG} type={LOADER_TYPES.SPINNER} className={cn.loader} />
            </ViewContainer>
        )
    }


    const getOppTimeInStage = opp => (
        opp.LastStageChangeDate ? moment().diff(moment(opp.LastStageChangeDate), 'days') : moment().diff(moment(opp.CreatedDate), 'days')
    )

    return (
        <ViewContainer className={cn.opportunitiesManagementWrapper}>
            <Row spaceBetween>
                <Row className={cn.topNav}>
                    <Row noMargin>
                        <Label>
                            Level
                        </Label>
                        <div className={cn.dropdownWrapper}>
                            <Dropdown
                                fullWidth
                                className={cn.topNavigationDropdown}
                                value={selectedUser}
                                onChange={e => updateSelectedUser(e.target.value)}
                                values={[
                                    {
                                        value: 'null',
                                        label: 'Company Wide'
                                    },
                                    ...teams.map(team => ({
                                        value: team.users.map(el => getUserCrmId(el)).filter(el => !!el).join(','),
                                        label: team.name
                                    })).filter(el => !!el.value),
                                    ...usersInTheWorkspace.map(el => ({
                                        value: workspaceTypeCheck(['CALLYPSO']) ? el.id : workspaceTypeCheck(['HUBSPOT']) ? el.hubspotUserId : el.sfUserId,
                                        label: el.fullName || el.email
                                    })).filter(el => !!el.value)
                                ]}
                            />
                        </div>
                    </Row>
                    <WorkspaceTypeCheck requiredType={'HUBSPOT'}>
                        <Row noMargin>
                            <Label>Pipeline</Label>
                            <div className={cn.dropdownWrapper}>
                                <Dropdown
                                    fullWidth
                                    className={cn.topNavigationDropdown}
                                    value={selectedPipeline}
                                    onChange={e => updateSelectedPipeline(e.target.value)}
                                    values={pipelines}
                                />
                            </div>
                        </Row>
                    </WorkspaceTypeCheck>
                    <Button
                        onClick={() => setShowFilters(true)}
                    >
                        Filters
                    </Button>
                    <WorkspaceTypeCheck requiredTypes={['CALLYPSO', 'SFDC', 'SFDC_SANDBOX']}>
                        <Row noMargin className={cn.pushCloseDateWrapper}>
                            <Input
                                value={pushCloseDateNumber}
                                onChange={e => setPushCloseDateNumber(e.target.value)}
                                type='number'
                                className={cn.smallInput}
                            />
                            <Dropdown
                                value={pushCloseDatePeriod}
                                onChange={e => setPushCloseDatePeriod(e.target.value)}
                                className={cn.smallDropdown}
                                values={[
                                    {
                                        label: 'Day(s)',
                                        value: 'days'
                                    },
                                    {
                                        label: 'Month(s)',
                                        value: 'months'
                                    },
                                    {
                                        label: 'Quarter(s)',
                                        value: 'quartes'
                                    },
                                ]}
                            />
                            <Button
                                onClick={pushCloseDate}
                            >
                                Push Close Date
                            </Button>
                        </Row>
                    </WorkspaceTypeCheck>
                </Row>
                <PermissionCheck requiredPermissions={[['OPPORTUNITIES', 'CREATE']]}>
                    <Button
                        onClick={getCreateOpportunityFields}
                    >
                        Create {translateCRMLabel('Opportunity')}
                    </Button>
                </PermissionCheck>
                {
                    showFilters && (
                        <Modal
                            header='Filters'
                            minHeight
                            onClose={closeFilterModal}
                            buttons={
                                <Row spaceBetween fullWidth>
                                    <Button
                                        onClick={clearFilters}
                                    >
                                        Clear filters
                                    </Button>
                                    <Button
                                        onClick={setFilters}
                                    >
                                        Set filters
                                    </Button>
                                </Row>
                            }
                        >
                            <Row fullWidth>
                                <Label className={cn.filtersLabel}>
                                    Filter on Close Date
                                </Label>
                                <ComplexDropdown
                                    value={selectedCloseDatePeriod || defaultDate}
                                    onChange={setSelectedCloseDatePeriod}
                                />
                            </Row>
                            <Row fullWidth>
                                <Label className={cn.filtersLabel}>
                                    Filter on Created Date
                                </Label>
                                <ComplexDropdown
                                    value={selectedCreateDatePeriod}
                                    onChange={setSelectedCreateDatePeriod}
                                />
                            </Row>
                            <Row fullWidth>
                                <Label className={cn.filtersLabel}>
                                    Filter on type
                                </Label>
                                <Dropdown
                                    values={[
                                        { label: 'All Types', value: 'undefined' },
                                        ...opportunityTypes
                                    ]}
                                    value={selectedTypeFilter || defaultOpportunityManagementType}
                                    onChange={e => setSelectedTypeFilter(e.target.value)}
                                />
                            </Row>
                            <Row fullWidth>
                                <Label className={cn.filtersLabel}>
                                    Time in stage Filter
                                </Label>
                                <Dropdown
                                    fullWidth
                                    className={cn.topNavigationDropdown}
                                    value={timeInStageFilter}
                                    onChange={e => setTimeInStageFilter(e.target.value)}
                                    values={[
                                        {
                                            value: 0,
                                            label: 'None'
                                        },
                                        {
                                            value: 7,
                                            label: 'Over a Week'
                                        },
                                        {
                                            value: 31,
                                            label: 'Over a Month'
                                        },
                                        {
                                            value: 90,
                                            label: 'Over 90 Days'
                                        },
                                        {
                                            value: 365,
                                            label: 'Over a Year'
                                        },
                                    ]}
                                />
                            </Row>
                            <Row fullWidth>
                                <Label className={cn.filtersLabel}>
                                    Select specific stages
                                </Label>
                                <SelectWrapperConnected
                                    placeholder="Quick Filter"
                                    closeMenuOnSelect={false}
                                    isMulti
                                    options={stagesDropdownData}
                                    onChange={setStagesToDisplayTemp}
                                    value={stagesToDisplayTemp}
                                />
                            </Row>
                        </Modal>
                    )
                }
            </Row>
            <div className={cn.boardContainer}>
                <DragDropContext
                    onDragEnd={onDragEnd}
                >
                    {/* eslint-disable-next-line */}
                    {Object.entries(columns).filter(([_columnId, column]) => {
                        if (!stagesToDisplay.length) return true
                        return stagesToDisplay.map(el => el.value || el.label).includes(column.id)
                    }).map(([columnId, column], index) => {
                        return (
                            <div className={cn.column} key={columnId} style={{ color: column.color }}>
                                <div className={cn.topDraggableColumn} />
                                <div className={cn.headerWrapper}>
                                    <div
                                        className={cn.columnHeader}
                                        data-tooltip-id='default'
                                        data-tooltip-content={'Click to edit the color'}
                                        onClick={() => showColorPicker(index)}
                                    >
                                        {column.name}
                                    </div>
                                    <Row spaceBetween fullWidth noMargin className={cn.dataDetails}>
                                        <div>{column.items.length}</div>
                                        <div>{formatLargeSums('$', column.items.reduce((partialSum, a) => partialSum + (a.Amount || 0) * (a.Probability / 100), 0))}</div>
                                    </Row>
                                </div>
                                {shouldshowColorPicker === index && (
                                    <div className={cn.colorPickerWrapper}>
                                        <PopoverGithubPicker
                                            color={column.color || undefined}
                                            onClose={hideColorPicker}
                                            onChange={color => {
                                                setColumns({
                                                    ...columns,
                                                    [columnId]: {
                                                        ...column,
                                                        color: color
                                                    }
                                                })
                                            }}
                                        />
                                    </div>
                                )}

                                <Droppable
                                    droppableId={columnId}
                                    key={columnId}
                                    ignoreContainerClipping={true}
                                    isDropDisabled={!permissionCheck('OPPORTUNITIES', 'UPDATE')}
                                >
                                    {(provided, snapshot) => {
                                        return (
                                            <div
                                                {...provided.droppableProps}
                                                ref={provided.innerRef}
                                                className={`${cn.columnContainer} ${snapshot.isDraggingOver && cn.draggingOver}`}
                                            >
                                                {column.items.map((item, index) => {
                                                    return (
                                                        <Draggable
                                                            key={item.Id}
                                                            draggableId={`${item.Id}`}
                                                            index={index}
                                                        >
                                                            {(provided, snapshot) => {
                                                                return (
                                                                    <div
                                                                        className={`${cn.opportunity} ${snapshot.isDragging && cn.beingDragged}`}
                                                                        ref={provided.innerRef}
                                                                        {...provided.draggableProps}
                                                                        {...provided.dragHandleProps}
                                                                        style={provided.draggableProps.style}
                                                                    >
                                                                        <Row spaceBetween noMargin>
                                                                            <div className={cn.title}>
                                                                                {item.Name}
                                                                            </div>
                                                                            <Row noMargin>
                                                                                <PermissionCheck requiredPermissions={[['OPPORTUNITIES', 'UPDATE']]}>
                                                                                    <>
                                                                                        <Button
                                                                                            type={BUTTON_TYPES.SMALL_ICON}
                                                                                            icon={faPencilAlt}
                                                                                            className={cn.edit}
                                                                                            onClick={() => editOpportunity(item.Id)}
                                                                                        />
                                                                                        <Button
                                                                                            type={BUTTON_TYPES.SMALL_ICON}
                                                                                            icon={faTrashAlt}
                                                                                            className={cn.trash}
                                                                                            onClick={() => setOpportunityToRemove(item.Id)}
                                                                                        />
                                                                                    </>
                                                                                </PermissionCheck>
                                                                            </Row>
                                                                        </Row>
                                                                        <Row spaceBetween noMargin>
                                                                            <div className={cn.account}>
                                                                                {item.Account ? item.Account.Name : `No ${translateCRMLabel('Account')}`}
                                                                            </div>
                                                                            <div className={cn.dates}>
                                                                                <div className={cn.date}>
                                                                                    {moment(item.CreatedDate).format('l')}
                                                                                </div>
                                                                                <div className={cn.date}>
                                                                                    {moment(item.CloseDate).format('l')}
                                                                                </div>
                                                                                <div className={cn.date}>
                                                                                    Days in stage <b>{getOppTimeInStage(item)}</b>
                                                                                </div>
                                                                            </div>
                                                                        </Row>
                                                                        <Row spaceBetween noMargin>
                                                                            <div className={cn.am}>
                                                                                {item.Owner?.Name}
                                                                            </div>
                                                                            <div className={cn.amount}>
                                                                                {formatLargeSums('$', item.Amount * item.Probability / 100)}
                                                                            </div>
                                                                        </Row>
                                                                    </div>
                                                                );
                                                            }}
                                                        </Draggable>
                                                    );
                                                })}
                                                {provided.placeholder}
                                            </div>
                                        );
                                    }}
                                </Droppable>
                            </div>
                        );
                    })}
                </DragDropContext>
            </div>
            <OpportunitiesCRUDModalWrapper
                shown={opportunityToEdit || creatingNewOpportunity}
                create={creatingNewOpportunity}
                edit={opportunityToEdit}
                onClose={() => {
                    setOpportunityToEdit(null)
                    setCreatingNewOpportunity(null)
                }}
                opportunityId={opportunityToEdit}
                externalError={createEditError}
                // eslint-disable-next-line eqeqeq
                header={creatingNewOpportunity ? `Creating new ${translateCRMLabel('Opportunity')}` : `Edit ${opportunities.find(el => el.Id == opportunityToEdit || el.id == opportunityToEdit)?.Name}`}
                editCallback={({ connectedObjectsOptions, opportunityId, values, response }) => {
                    const oldOpportunityData = opportunities.find(el => el.Id === opportunityId)
                    const newAccount = connectedObjectsOptions?.Account?.find(account => account.value === values.AccountId)
                    const newOwner = connectedObjectsOptions?.User?.find(user => user.value === values.OwnerId)
                    const updatedOpportunity = {
                        ...oldOpportunityData,
                        ...values,
                        Owner: {
                            ...oldOpportunityData.Owner,
                            Name: newOwner?.label,
                            Id: newOwner?.value,
                        },
                        ...response
                    }
                    if (newAccount) {
                        updatedOpportunity.Account = {
                            ...oldOpportunityData.Account,
                            Name: newAccount?.label,
                            Id: newAccount?.value,
                        }
                    }
                    const updatedOpportunities = opps => {
                        return opps.map(el => {
                            if (el.Id !== opportunityId) {
                                return el
                            }
                            return updatedOpportunity;
                        })
                    }
                    setOpportunities(updatedOpportunities(opportunities))
                    setAllOpportunities(updatedOpportunities(allOpportunities))
                    setOpportunityToEdit(null)
                    setCreatingNewOpportunity(false)
                    setCreateEditError(null)
                }}
                createCallback={data => {
                    if (workspaceTypeCheck(['HUBSPOT'])) {
                        const probability = stagesDropdownData.find(el => el.label === data.StageName)?.metadata?.probability
                        if (probability) {
                            data.Probability = probability * 100
                        }
                    }
                    setOpportunities([...opportunities, data])
                    setAllOpportunities([...allOpportunities, data])
                    setOpportunityToEdit(null)
                    setCreatingNewOpportunity(false)
                    setCreateEditError(null)
                }}
            />
            {opportunityToRemove && (<Modal
                onClose={() => setOpportunityToRemove(null)}
                header="Delete Opportunity"
                buttons={(
                    <Row spaceBetween fullWidth>
                        <Button
                            onClick={deleteOpportunity}
                        >
                            Yes, Delete it
                        </Button>
                        <Button
                            onClick={() => setOpportunityToRemove(null)}
                        >
                            No, Cancel
                        </Button>
                    </Row>
                )}
            >
                Are you sure you'd want to delete this {translateCRMLabel('Opportunity')} from your CRM?
                <br /><br />
                This action might be irrevirsible! Please consider it carefully.
            </Modal>)}
        </ViewContainer>
    );
}
