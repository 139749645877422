import { faAngleDown, faAngleUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import cn from './CreateContactModal.module.css'
import { Button } from "../../../../components/Button";
import { Input } from "../../../../components/Input";
import { Label } from "../../../../components/Label";
import { Modal } from "../../../../components/Modal";
import { Row } from "../../../../components/Row";
import { SalesForceInputComponent } from "../../../../components/SalesForceInputComponent";
import { Error } from '../../../../components/Error'
import { workspaceTypeCheck } from "../../../../utilities/workspaceType";
import { translateCRMLabel } from "../../../../utilities/translateCRMLabels";
import { WorkspaceTypeCheck } from "../../../../components/WorkspaceTypeCheck";

export const CreateContactModal = ({
    contactModalData,
    onChange,
    customContactFields,
    onClose,
    onSave,
    edit,
    disableButtons,
    error
}) => {
    if (workspaceTypeCheck(['HUBSPOT'])) {
        customContactFields = customContactFields.filter(el => !['title', 'email', 'lastName', 'firstName'].includes(el))
    }
    const [expandedCustomFields, setExpandedCustomFields] = useState(false)
    const getError = () => {
        switch (error.errorCode) {
        case 'REQUIRED_FIELD_MISSING':
            return 'Please fill out user\'s first and last name'
        case 'INVALID_EMAIL_ADDRESS':
            return 'The email you\'ve added is invalid'
        case 'DUPLICATES_DETECTED':
            return `This Contact already exists as part of this ${translateCRMLabel('Account')}`
        default:
            return `We are sorry, but there was an error with this contact ${error.name}`
        }
    }
    return (
        <Modal
            header={!edit ? `Add New Contact` : `Update ${contactModalData.firstName} ${contactModalData.lastName}`}
            onClose={onClose}
            buttons={(
                <>
                    <Button
                        fullWidth
                        disabled={disableButtons}
                        onClick={onSave}
                    >
                        {!edit ? 'Add' : 'Update'}
                    </Button>
                    <Button
                        fullWidth
                        disabled={disableButtons}
                        onClick={onClose}
                    >
                        Cancel
                    </Button>
                </>
            )}
        >
            <Row columnFlex fullWidth noAligning>
                <Label className={cn.userEditLabel}>First Name</Label>
                <Input
                    fullWidth
                    value={contactModalData.firstName}
                    onChange={e => onChange({ firstName: e.target.value })}
                />
            </Row>
            <Row columnFlex fullWidth noAligning>
                <Label className={cn.userEditLabel}>Last Name</Label>
                <Input
                    fullWidth
                    value={contactModalData.lastName}
                    onChange={e => onChange({ lastName: e.target.value })}
                />
            </Row>
            <Row columnFlex fullWidth noAligning>
                <Label className={cn.userEditLabel}>Email</Label>
                <Input
                    fullWidth
                    value={contactModalData.email}
                    onChange={e => onChange({ email: e.target.value })}
                />
            </Row>
            <Row columnFlex fullWidth noAligning>
                <Label className={cn.userEditLabel}>Title</Label>
                <Input
                    fullWidth
                    value={contactModalData.title}
                    onChange={e => onChange({ title: e.target.value })}
                />
            </Row>
            <WorkspaceTypeCheck requiredTypes={['SFDC', 'SFDC_SANDBOX']}>
                <Row columnFlex fullWidth noAligning>
                    <Label className={cn.userEditLabel}>Phone</Label>
                    <Input
                        fullWidth
                        value={contactModalData.Phone}
                        onChange={e => onChange({ Phone: e.target.value })}
                    />
                </Row>
            </WorkspaceTypeCheck>
            <div
                className={`${cn.expandLine} ${expandedCustomFields && cn.expanded}`}
                onClick={() => setExpandedCustomFields(!expandedCustomFields)}
            >
                <FontAwesomeIcon icon={expandedCustomFields ? faAngleUp : faAngleDown} />
            </div>
            {expandedCustomFields && customContactFields.map(field => (
                <SalesForceInputComponent
                    key={field.value}
                    {...field}
                    labelClassName={cn.userEditLabel}
                    value={contactModalData[field.value]}
                    onChange={data => onChange({ [field.value]: data })}
                />
            ))}
            {!!error && (
                <Error>
                    {getError()}
                </Error>
            )}
        </Modal>
    )
}