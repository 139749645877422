import store from "../store"

const sfdcTexts = {
    Account: 'Account',
    account: 'account',
    ACCOUNT: 'Account',
    Accounts: 'Accounts',
    accounts: 'accounts',

    Opportunity: 'Opportunity',
    opportunity: 'opportunity',
    Opportunities: 'Opportunities',
}

const hubspotTexts = {
    Account: 'Company',
    account: 'company',
    Accounts: 'Companies',
    accounts: 'companies',
    ACCOUNT: 'Company',

    Opportunity: 'Deal',
    opportunity: 'deal',
    Opportunities: 'Deals',
}

const callypsoTexts = {
    Account: 'Account',
    Accounts: 'Accounts',
    accounts: 'accounts',
    ACCOUNT: 'Account',
    account: 'account',

    Opportunity: 'Opportunity',
    opportunity: 'opportunity',
    Opportunities: 'Opportunities',
}

const texts = {
    SFDC: sfdcTexts,
    SFDC_SANDBOX: sfdcTexts,
    HUBSPOT: hubspotTexts,
    CALLYPSO: callypsoTexts
}

export const translateCRMLabel = text => {
    const workspaceType = store.getState()?.user?.workspaceType
    if (texts[workspaceType] && texts[workspaceType][text]) {
        return texts[workspaceType][text]
    }
    return `MISSING TRANSLATION FOR ${text}`
}