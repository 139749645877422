import React from 'react'
import cn from './NoteCell.module.css'

export const NoteCell = ({ problems, }) => (
    <div className={cn.noteCell} data-tooltip-id='default' data-tooltip-content={!!problems.length ? problems.join('<br/>') : null}>
        {problems.map(el => (
            <div className={cn.note}>
                {el}
            </div>
        ))}
    </div>
)