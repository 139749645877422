import {
    faEnvelope,
    faEnvelopeOpenText,
    faMinusCircle,
    faNoteSticky,
    faCircleHalfStroke,
    faPlusCircle,
    faUser,
    faTimes,
    faPlus,
    faPersonCircleQuestion,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import 'react-contexify/dist/ReactContexify.css';
import { Item, Menu, Separator, useContextMenu } from 'react-contexify';
import React, { useRef, useState } from 'react';
import { SketchPicker } from "react-color";
import { connect } from 'react-redux';
import { Row } from '../../../../components/Row';
import { accountMapContactRoleIcons } from '../../../../components/accountMapContactRolesSettings'
import { Checkbox } from '../../../../components/Checkbox';
import { Label } from '../../../../components/Label';
import cn from './Contact.module.css'
import { LinkedIn } from '../../../../assets/icons/LinkedIn';
import { useOutsideClickHandler } from '../../../../customHooks/useOutsideClickHandler';
import { workspaceTypeCheck } from '../../../../utilities/workspaceType';
import { Modal } from '../../../../components/Modal';
import { BUTTON_TYPES, Button } from '../../../../components/Button';
import { Input } from '../../../../components/Input';
import { makeRequest } from '../../../../utilities/endpoints';
import { URLS } from '../../../../config';

const ContactPositive = ({ positive, workspaceName }) => {
    switch (positive) {
    case 0:
        return <FontAwesomeIcon
            icon={faMinusCircle}
            color={'#b7001b'}
            data-tooltip-id='default'
            data-tooltip-content={`Click to change this contact's relationship to ${workspaceName}`}
        />
    case 1:
        return <FontAwesomeIcon
            icon={faPlusCircle}
            color={'#00980c'}
            data-tooltip-id='default'
            data-tooltip-content={`Click to change this contact's relationship to ${workspaceName}`}
        />
    default:
        return <FontAwesomeIcon
            icon={faCircleHalfStroke}
            color={'#fff33e'}
            data-tooltip-id='default'
            data-tooltip-content={`Click to change this contact's relationship to ${workspaceName}`}
        />
    }
}

export const Contact = ({
    accountId,
    id,
    user,
    onEdit,
    onRemoveFromMap,
    onRemoveConnections,

    onOpenNotes,
    onRemoveFromSFDC,
    onUpdatedReachedOutTo,
    onContactMarkedAs,
    onUpdatePositive,
    onChangeColorForContact,

    reachedOutTo,
    positive,

    firstName,
    lastName,
    title,
    email,
    linkedInUrl,
    linkedInProfileImageUrl,
    firstCellSelected,
    notifyContextMenuShown,
    notifyContextMenuHidden,
    roleId,
    color,
    divisionColor,
    contactRoles
}) => {
    const MENU_ID = `MENU_ID_${id}`
    const { show } = useContextMenu({
        id: MENU_ID,
    });

    const contactRef = useRef()
    const colorPickerRef = useRef()
    const [relationshipPickerShown, setRelationshipPickerShown] = useState(false)
    const [showMarkContact, setShowMarkContact] = useState(false)
    const [showColorPicker, setShowColorPicker] = useState(false)
    const [showEditContactsLinkedInUrl, setShowEditContactsLinkedInUrl] = useState(false)
    const [newLinkedInUrl, setNewLinkedInUrl] = useState(linkedInUrl)

    const increaseParentZIndex = () => {
        contactRef.current.parentNode.style.zIndex = 100
    }

    const restoreParentZIndex = () => {
        contactRef.current.parentNode.style.zIndex = 10
    }

    const handleContextMenu = e => {
        e.preventDefault();
        show(e, {
            position: getMenuPosition(e)
        })
        increaseParentZIndex()
    }

    const getMenuPosition = () => {
        return {
            x: 80,
            y: 25
        }
    }

    useOutsideClickHandler(contactRef, restoreParentZIndex)

    useOutsideClickHandler(colorPickerRef, () => setShowColorPicker(false))

    const getUserRole = () => contactRoles.find(el => el.id === roleId)

    const getUserIcon = () => {
        if (roleId !== undefined) {
            const iconName = getUserRole()?.icon
            if (iconName) {
                return accountMapContactRoleIcons.find(icon => icon.iconName === iconName)
            }
            return faPersonCircleQuestion
        }
    }

    const onEditContactsLinkedInURL = () => {
        setShowEditContactsLinkedInUrl(true)
    }

    const updateContactLinkedInUrl = () => {
        makeRequest.post(`${URLS.main}/contacts/${accountId}/${id}/setManualLinkedInPage`, { url: newLinkedInUrl })
        setShowEditContactsLinkedInUrl(false)
    }

    const preventDefault = e => {
        e.preventDefault()
        e.stopPropagation()
    }

    return (
        <div
            className={`${cn.contact} ${firstCellSelected ? cn.firstCellSelected : ''}`}
            ref={contactRef}
            onContextMenu={handleContextMenu}
        >
            <Row noMargin className={cn.wrapper}>
                <div className={cn.profileImage}>
                    {linkedInProfileImageUrl
                        ? <img crossOrigin="use-credentials" src={`${URLS.main}/contacts/photo/${id}`} alt={`${firstName} ${lastName}`} />
                        : <FontAwesomeIcon icon={faUser} className={cn.imgPlaceholder} />}
                </div>
            </Row>
            <div className={cn.name}>
                {firstName} {lastName}
            </div>
            {showColorPicker && <div className={cn.colorPickerWrapper}>
                <Row noMargin endAlign fullWidth>
                    <Button
                        type={BUTTON_TYPES.SMALLER_ICON}
                        icon={faTimes}
                        onClick={(e) => {
                            preventDefault(e)
                            setShowColorPicker(false)
                        }}
                    />
                </Row>
                <SketchPicker
                    color={color || divisionColor}
                    onChange={data => {
                        onChangeColorForContact(data)
                        setShowColorPicker(false)
                    }}
                />
            </div>}
            <Row
                noMargin
                className={cn.wrapper}
                onClick={e => {
                    preventDefault(e)
                    setShowColorPicker(true)
                }}
                onMouseDown={preventDefault}
                onMouseMove={preventDefault}
                data-tooltip-id='default'
                data-tooltip-content={showColorPicker ? undefined : 'Change Color for this Contact'}
            >
                <div
                    className={`${cn.position} ${!title?.length ? cn.hidden : ''}`}
                    style={{ cursor: 'pointer', background: color || divisionColor }}
                >
                    {title}
                </div>
            </Row>
            <Row noMargin className={`${cn.wrapper} ${cn.actionsWrapper}`}>
                <Button
                    type={BUTTON_TYPES.SMALLER_ICON}
                    className={cn.noteButton}
                    onMouseDown={preventDefault}
                    onClick={e => {
                        onOpenNotes(id)
                        preventDefault(e)
                    }}
                    data-tooltip-id='default'
                    data-tooltip-content={`Read and take notes for this contact`}
                    customIcon={<FontAwesomeIcon icon={faNoteSticky} />}
                />
                <Button
                    type={BUTTON_TYPES.SMALLER_ICON}
                    className={cn.reachedOutTo}
                    onMouseDown={preventDefault}
                    onClick={e => {
                        preventDefault(e)
                        onUpdatedReachedOutTo(id, !reachedOutTo)
                    }}
                    customIcon={reachedOutTo ? (
                        <FontAwesomeIcon
                            icon={faEnvelopeOpenText}
                            color={'green'}
                            data-tooltip-id='default'
                            data-tooltip-content={`Click to mark this contact as NOT reached out to`}
                        />
                    ) : (
                        <FontAwesomeIcon
                            icon={faEnvelope}
                            color={'rgba(122, 122, 122, 0.4)'}
                            data-tooltip-id='default'
                            data-tooltip-content={`Click to mark this contact as reached out to`}
                        />
                    )}
                />
                <Button
                    className={cn.icon}
                    onMouseDown={preventDefault}
                    onClick={e => {
                        setShowMarkContact(true)
                        increaseParentZIndex()
                        preventDefault(e)
                    }}
                    icon={getUserIcon()}
                    type={BUTTON_TYPES.SMALLER_ICON}
                    data-tooltip-id='default'
                    data-tooltip-content={getUserRole() ? `Current user role: ${getUserRole().name} <br /> Click to update the role.` : 'User has no set role. <br /> Click to set a role.'}
                />
                {showMarkContact && (
                    <div className={cn.contactMarkingWrapper}>
                        <Row noMargin fullWidth endAlign>
                            <Button
                                type={BUTTON_TYPES.SMALLER_ICON}
                                icon={faTimes}
                                onMouseDown={preventDefault}
                                onClick={(e) => {
                                    setShowMarkContact(false)
                                    restoreParentZIndex()
                                    preventDefault(e)
                                }}
                            />
                        </Row>
                        {contactRoles.map(role => (
                            <Checkbox
                                checked={roleId === role.id}
                                onMouseDown={preventDefault}
                                onClick={e => {
                                    onContactMarkedAs(id, role.id)
                                    preventDefault(e)
                                    setShowMarkContact(false)
                                    restoreParentZIndex()
                                }}
                                label={role.name}
                            />
                        ))}
                    </div>
                )}
                <Button
                    type={BUTTON_TYPES.SMALLER_ICON}
                    className={cn.positive}
                    onMouseDown={preventDefault}
                    onClick={e => {
                        preventDefault(e)
                        setRelationshipPickerShown(true)
                    }}
                    customIcon={<ContactPositive positive={positive} workspaceName={user?.workspaceName} />}
                />
                {relationshipPickerShown && <div className={cn.relationshipPicker}>
                    <Row noMargin fullWidth endAlign>
                        <Button
                            onMouseDown={preventDefault}
                            onMouseUp={preventDefault}
                            onClick={e => {
                                preventDefault(e)
                                setRelationshipPickerShown(false)
                            }}
                            type={BUTTON_TYPES.SMALLER_ICON}
                            icon={faTimes}
                        />
                    </Row>
                    <Checkbox
                        label={'Mark as Negative'}
                        checked={positive === 0}
                        onMouseDown={preventDefault}
                        onMouseUp={preventDefault}
                        onClick={(e) => {
                            preventDefault(e)
                            onUpdatePositive(id, 0)
                            setRelationshipPickerShown(false)
                        }}
                    />
                    <Checkbox
                        label={'Mark as Neutral'}
                        checked={[undefined, null].includes(positive)}
                        onMouseDown={preventDefault}
                        onMouseUp={preventDefault}
                        onClick={(e) => {
                            preventDefault(e)
                            onUpdatePositive(id, null)
                            setRelationshipPickerShown(false)
                        }}
                    />
                    <Checkbox
                        label={'Mark as Positive'}
                        checked={positive === 1}
                        onMouseDown={preventDefault}
                        onMouseUp={preventDefault}
                        onClick={(e) => {
                            preventDefault(e)
                            onUpdatePositive(id, 1)
                            setRelationshipPickerShown(false)
                        }}
                    />
                </div>}
                <Button
                    className={cn.linkedInButton}
                    onMouseDown={preventDefault}
                    type={BUTTON_TYPES.SMALLER_ICON}
                    onClick={e => {
                        preventDefault(e)
                        newLinkedInUrl && window.open(newLinkedInUrl)
                    }}
                    customIcon={<LinkedIn
                        fill={newLinkedInUrl ? '#0a66c2' : '#A0A4AA'}
                    />}
                    data-tooltip-id='default'
                    data-tooltip-content={newLinkedInUrl ? 'Open their LinkedIn profile' : 'No LinkedIn profile for this user *yet*'}
                />
            </Row>
            <Menu id={MENU_ID} className={cn.contextMenu} onShown={notifyContextMenuShown} onHidden={notifyContextMenuHidden}>
                <Item
                    onClick={() => {
                        onRemoveFromMap()
                        notifyContextMenuHidden()
                    }}
                >
                    Remove Contact From the Map
                </Item>
                <Separator />

                <Item
                    onClick={() => {
                        onRemoveConnections()
                        notifyContextMenuHidden()
                    }}
                >
                    Remove Connections to the Contact
                </Item>
                <Separator />

                <Item
                    onClick={onEdit}
                >
                    Edit This Contact
                </Item>
                <Separator />

                <Item
                    onClick={() => {
                        onEditContactsLinkedInURL()
                        notifyContextMenuHidden()
                    }}
                >
                    Edit Contact's LinkedIn url
                </Item>
                <Separator />

                <Item
                    onClick={() => {
                        onRemoveFromSFDC()
                        notifyContextMenuHidden()
                    }}
                >
                    Delete Contact From {workspaceTypeCheck(['SFDC', 'SFDC_SANDBOX']) ? 'Sales Force' : 'Callypso'}
                </Item>

                <Separator />
                <Item
                    onClick={notifyContextMenuHidden}
                >
                    <a href={`mailto:${email}`} className={cn.emailContact}>
                        Email this Contact
                    </a>
                </Item>
            </Menu>
            {showEditContactsLinkedInUrl && <Modal
                onClick={preventDefault}
                onMouseDown={preventDefault}
                onMouseUp={preventDefault}
                onMouseMove={preventDefault}
                header={`Edit ${firstName} ${lastName}'s LinkedIn profile URL`}
                onClose={() => {
                    setShowEditContactsLinkedInUrl(false)
                    notifyContextMenuHidden()
                }}
                buttons={(
                    <Row fullWidth spaceBetween>
                        <Button
                            onClick={() => {
                                notifyContextMenuHidden()
                                setShowEditContactsLinkedInUrl(false)
                            }}
                        >
                            Cancel
                        </Button>
                        <Button
                            onClick={updateContactLinkedInUrl}
                        >
                            Update
                        </Button>
                    </Row>
                )}
            >
                <Row fullWidth>
                    <Label>
                        Url
                    </Label>
                    <Input
                        fullWidth
                        onClick={e => e.stopPropagation()}
                        onMouseDown={e => e.stopPropagation()}
                        onMouseUp={e => e.stopPropagation()}
                        value={newLinkedInUrl}
                        onChange={e => setNewLinkedInUrl(e.target.value)}
                    />
                </Row>
            </Modal>}
            <div className={cn.bottomCircle}>
                {firstCellSelected ? <FontAwesomeIcon icon={faPlus} /> : null}
            </div>
        </div>
    )
}

export const mapStateToProps = (state) => {
    return {
        user: state.user,
    }
}

export const ContactConnected = connect(mapStateToProps)(Contact)
