import React from 'react'
export const Accounts = ({ fill='none' }) => (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M15.7031 6.5625C15.5886 8.15117 14.4101 9.375 13.1249 9.375C11.8398 9.375 10.6593 8.15156 10.5468 6.5625C10.4296 4.90977 11.5769 3.75 13.1249 3.75C14.673 3.75 15.8202 4.93984 15.7031 6.5625Z" stroke={fill} stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M13.125 11.875C10.5793 11.875 8.13122 13.1395 7.51794 15.602C7.43669 15.9277 7.64098 16.25 7.97575 16.25H18.2746C18.6093 16.25 18.8125 15.9277 18.7324 15.602C18.1191 13.1 15.6711 11.875 13.125 11.875Z" stroke={fill} stroke-miterlimit="10"/>
        <path d="M7.81251 7.26328C7.7211 8.53203 6.76876 9.53125 5.74219 9.53125C4.71563 9.53125 3.76173 8.53242 3.67188 7.26328C3.57852 5.94336 4.50548 5 5.74219 5C6.97891 5 7.90587 5.96758 7.81251 7.26328Z" stroke={fill} stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M8.04689 11.9531C7.34181 11.6301 6.56525 11.5059 5.7422 11.5059C3.71095 11.5059 1.75392 12.5156 1.26369 14.4824C1.19923 14.7426 1.36252 15 1.6297 15H6.01564" stroke={fill} stroke-miterlimit="10" stroke-linecap="round"/>
    </svg>

)
