import { useEffect } from "react";

/**
 * Hook that alerts clicks outside of the passed ref
 */
export const useOutsideClickHandler = (ref, callback) => {
    useEffect(() => {
        function handleClickOutside(event) {
            if (ref.current && ref.current.contains && !ref.current.contains(event.target)) {
                callback && callback()
            }
        }

        function handleEscapeButtonPress({ key }) {
            if (key.toLowerCase() === 'escape') {
                callback && callback()
            }
        }

        // Bind the event listener
        document.addEventListener("mousedown", handleClickOutside);
        document.addEventListener("keydown", handleEscapeButtonPress);
        return () => {
            // Unbind the event listener on clean up
            document.removeEventListener("mousedown", handleClickOutside);
            document.removeEventListener("keydown", handleEscapeButtonPress);
        };
    }, [ref, callback]);
}