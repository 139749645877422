import React from 'react'
import cn from './Label.module.css'

export const Label = ({ children, required, preventBreaks, className, noMargin, fullWidth }) => (
    <div className={`
        ${cn.label}
        ${required ? cn.required : ''}
        ${preventBreaks ? cn.preventBreaks : ''}
        ${noMargin ? cn.noMargin : ''}
        ${fullWidth ? cn.fullWidth : ''}
        ${className}
    `}
    >
        {children}
    </div>
)
