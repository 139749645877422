import { useEffect } from "react"
import { URLS } from "../config"
import store from "../store"
import { setUser } from "../store/actions"
import { makeRequest } from "./endpoints"

export const permissionCheck = (feature, level) => {
    return store.getState()?.user?.permissions[feature]?.includes(level)
}


export const getUserPermissions = () => {
    useEffect(() => {
        makeRequest.get(`${URLS.main}/session`)
            .then(res => {
                store.dispatch(setUser({
                    ...store.getState()?.user,
                    permissions: res.data.permissions
                }))
            })
    }, [])
}