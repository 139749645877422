import React, { useState } from 'react'
import { faPencilAlt, faPlus, faTrashAlt } from '@fortawesome/free-solid-svg-icons'
import moment from 'moment'
import cn from './BottomRightPart.module.css'
import { ViewContainer } from '../../../../components/ViewContainer'
import { BUTTON_TYPES, Button } from '../../../../components/Button'
import { Row } from '../../../../components/Row'
import { Dropdown } from '../../../../components/Dropdown'
import { randInRange } from '../../../../utilities/random'
import { Table } from '../../../../components/Table'
import { Nbsp } from '../../../../components/Nbsp'
import { Opportunities } from './Opportunities'
import { Heading } from '../../../../components/Heading'
import { Contacts } from './Contacts'
import { Emails } from './Emails'
import { Playbook2 } from '../../../playbooks/playbook/Playbook2'

export const BOTTOM_RIGHT_PART_OPTIONS = {
    'BUSINESS_GOALS': 0,
    'EMAILS': 1,
    'OPPORTUNITIES': 2,
    'CONTACTS': 3,
    'CUSTOMER_JOURNEY': 4
}

export const BottomRightPart = ({ type, accountId, contacts, onContactAdded }) => {
    const [deliverables, setDeliverables] = useState([
        {
            description: 'Ensure churn is bellow 2%.',
            dueDate: moment().add(randInRange(10, 252), 'days').valueOf(),
            track: 1,
            type: 0,
        },
        {
            description: 'Start identifying whitespace potential.',
            dueDate: moment().add(randInRange(10, 252), 'days').valueOf(),
            track: 3,
            type: 0,
        },
        {
            description: 'Find additional Org opportunities.',
            dueDate: moment().add(randInRange(10, 252), 'days').valueOf(),
            track: 1,
            type: 2,
        }
    ])

    let content = null

    switch (type) {
    case BOTTOM_RIGHT_PART_OPTIONS.BUSINESS_GOALS:
        content = (
            <>
                <Row noMargin spaceBetween>
                    <Heading>
                        Business Goals
                    </Heading>
                    <Button
                        type={BUTTON_TYPES.ICON_AND_TEXT}
                        icon={faPlus}
                    >
                        Add New
                    </Button>
                </Row>
                <Table
                    className={cn.businessGoalsTable}
                    borderless
                    headers={[
                        'Objective',
                        'Due Date',
                        'Status',
                        'Type',
                        'Actions'
                    ]}
                    customStyles={{
                        1: {
                            width: 120
                        },
                        2: {
                            width: 175,
                            textOverflow: 'unset'
                        },
                        3: {
                            width: 150,
                            textOverflow: 'unset'
                        },
                        4: {
                            width: 90,
                            textOverflow: 'unset'
                        }
                    }}
                    data={deliverables.map(({ description, dueDate, track, type }, i) => ([
                        <div className={cn.deliverableDescription}>
                            {description}
                        </div>,
                        moment(dueDate).format('DD MMM YYYY'),
                        <Dropdown
                            fullWidth
                            value={track}
                            values={[
                                { label: 'On Track', value: 0 },
                                { label: 'Not Started', value: 1 },
                                { label: 'Delayed!', value: 2 },
                                { label: 'Completed', value: 3 },
                            ]}
                            onChange={e => {
                                setDeliverables(deliverables.map((el, innerI) => {
                                    if (innerI === i) {
                                        return {
                                            ...el,
                                            track: e.target.value
                                        }
                                    }
                                    return el
                                }))
                            }}
                        />,
                        <Dropdown
                            value={type}
                            fullWidth
                            values={[
                                { label: 'External', value: 0 },
                                { label: 'Internal', value: 1 },
                                { label: 'Mutual', value: 2 },
                            ]}
                            onChange={e => {
                                setDeliverables(deliverables.map((el, innerI) => {
                                    if (innerI === i) {
                                        return {
                                            ...el,
                                            type: e.target.value
                                        }
                                    }
                                    return el
                                }))
                            }}
                        />,
                        <Row noMargin>
                            <Button
                                className='red'
                                type={BUTTON_TYPES.SMALL_ICON}
                                onClick={() => {}}
                                icon={faTrashAlt}
                            />
                            <Nbsp />
                            <Button
                                className='green'
                                type={BUTTON_TYPES.SMALL_ICON}
                                onClick={() => {}}
                                icon={faPencilAlt}
                            />
                        </Row>
                    ]))}
                />
            </>
        )
        break;
    case BOTTOM_RIGHT_PART_OPTIONS.OPPORTUNITIES:
        content = <Opportunities accountId={accountId} />
        break;
    case BOTTOM_RIGHT_PART_OPTIONS.CONTACTS:
        content = <Contacts
            accountId={accountId}
            contacts={contacts}
            onContactAdded={onContactAdded}
        />
        break
    case BOTTOM_RIGHT_PART_OPTIONS.EMAILS:
        content = <Emails accountId={accountId} />
        break;
    case BOTTOM_RIGHT_PART_OPTIONS.CUSTOMER_JOURNEY:
        content = <Playbook2 step={true} />
        break
    default:
        break;
    }
    return (
        <ViewContainer className={cn.bottomRightPart}>
            {content}
        </ViewContainer>
    )
}