import React, { useCallback, useEffect, useState } from 'react'
import debounce from 'lodash.debounce';
import { faTimesRectangle } from '@fortawesome/free-solid-svg-icons';
import { useParams, useHistory } from 'react-router-dom';
import cn from './GenerativePDFUse.module.css'
import { ViewContainer } from '../../components/ViewContainer'
import { makeRequest } from '../../utilities/endpoints';
import { URLS } from '../../config';
import { Pagination } from '../../components/Pagination';
import { Loader, LOADER_SIZES, LOADER_TYPES } from '../../components/Loader';
import { Label } from '../../components/Label';
import { Checkbox } from '../../components/Checkbox';
import { Input } from '../../components/Input';
import { NBSP_SIZES, Nbsp } from '../../components/Nbsp';
import { Row } from '../../components/Row';
import { BUTTON_TYPES, Button } from '../../components/Button';

export const GenerativePDFUse = () => {
    const { id, name } = useParams()
    const history = useHistory()
    const [disableEverything, setDisableEverything] = useState(false)
    const [accountsLoaded, setAccountsLoaded] = useState(false)
    const [filter, setFilter] = useState()
    const [currentPage, setCurrentPage] = useState(0)
    const [accounts, setAccounts] = useState([])
    const [totalPages, setTotalPages] = useState()
    const [accountListSelected, setAccountListSelected] = useState([])
    const [lastFilter, setLastFilter] = useState()

    const loadAccounts = (currentPage=0, passedFilter=filter) => {
        setAccountsLoaded(false);
        const filterString = passedFilter || 'null';
        makeRequest.get(`${URLS.main}/accounts/sorted/${filterString}/null/null/${currentPage}`)
            .then(res => {
                if (res && res.data && res.data.accounts) {
                    setAccounts(res.data.accounts);
                    setTotalPages(res.data.pages);
                    setCurrentPage(currentPage);
                }
                setAccountsLoaded(true);
            })
            .catch(error => {
                console.error(error)
                setAccountsLoaded(true);
            })
    }
    const loadAccountsDebounce = useCallback(
        debounce(loadAccounts, 250),
        []
    );

    useEffect(loadAccounts, [])

    useEffect(() => {
        if (filter !== lastFilter) {
            loadAccountsDebounce(0, filter)
            setLastFilter(filter)
        }
    }, [filter, lastFilter])

    const startGeneration = () => {
        setDisableEverything(true)
        makeRequest.post(`${URLS.main}/generative-pdf`, {
            documentId: id,
            documentName: name,
            accountIds: accountListSelected
        })
            .then(() => {
                history.push(`/generative-pdf/generating`)
            })
    }


    return <ViewContainer>
        <Row spaceBetween>
            <div>
                Selected file is <i>{name}</i>
            </div>
            <Button
                disabled={!accountListSelected?.length || disableEverything}
                onClick={startGeneration}
            >
                Start Generation
            </Button>
        </Row>
        <Row>
            <Input
                disabled={disableEverything}
                fullWidth
                value={filter}
                placeHolder={'Filter By Account Name...'}
                onChange={e => {
                    setFilter(e.target.value)
                }}
            />
            <Nbsp size={NBSP_SIZES.BIGGER} />
            <Row noMargin>
                <Label className={cn.numberOfSelectedAccounts}>
                    # Selected Accounts
                </Label>
                {accountListSelected?.length}
            </Row>
            <Nbsp size={NBSP_SIZES.BIG} />
            <Button
                disabled={disableEverything}
                type={BUTTON_TYPES.TRANSPARENT}
                icon={faTimesRectangle}
                data-tooltip-id='default'
                data-tooltip-content='Unsellect all'
                onClick={() => setAccountListSelected([])}
            />
        </Row>
        {accountsLoaded ? (
            <>
                {accounts.map(account => (
                    <Checkbox
                        disabled={disableEverything}
                        key={account.id}
                        label={account.name}
                        checked={accountListSelected.includes(account.id)}
                        onClick={() => {
                            if (accountListSelected.includes(account.id)) {
                                setAccountListSelected(accountListSelected.filter(el => el !== account.id))
                            } else {
                                setAccountListSelected([...accountListSelected, account.id])
                            }
                        }}
                    />
                ))}
                <Pagination
                    pages={totalPages}
                    index={currentPage}
                    noOffset={true}
                    onClick={page => {
                        if (!disableEverything) {
                            setCurrentPage(page)
                            loadAccounts(page)
                        }
                    }}
                />
            </>
        ) : (
            <Loader size={LOADER_SIZES.EXTRA_BIG} type={LOADER_TYPES.SPINNER} />
        )}
    </ViewContainer>
}