import React from 'react'
import { Row } from './Row'
import { Input } from './Input'
import { Checkbox } from './Checkbox'
import { CalendarInput } from './CalendarInput'
import { Label } from './Label'
import { ReactSelectDynamic } from './ReactSelectDynamic';
import { workspaceTypeCheck } from '../utilities/workspaceType'
import cn from './SalesForceInputComponent.module.css'

const InputComponent = ({
    tabIndex,
    value,
    onChange,
    onBlur=() => {},
    onKeyDown=() => {},
    placeholder,
    type,
    picklistValues,
    disabled,
    onClick,
    label
}) => {
    switch (type) {
    case 'boolean':
        return <Checkbox
            label={label}
            tabIndex={tabIndex}
            checked={!!value}
            disabled={disabled}
            onClick={() => setTimeout(() => onChange(!value), 0)}
        />
    case 'loader':
    case 'picklist':
    case 'reference':
    case 'multiple-picklist':
        return <ReactSelectDynamic
            isMulti={type === 'multiple-picklist'}
            onFocus={e => {
                e.preventDefault()
                e.stopPropagation()
                setTimeout(() => {
                    onClick && onClick()
                }, 0)
            }}
            isLoading={type === 'loader'}
            tabIndex={tabIndex}
            isDisabled={disabled}
            onBlur={data => {
                onBlur(data);
            }}
            onChange={data => {
                onChange(data.value)
            }}
            options={picklistValues}
            // eslint-disable-next-line eqeqeq
            value={picklistValues?.find(el => el.value == value)}
        />
    case 'datetime':
        return <CalendarInput
            tabIndex={tabIndex}
            disabled={disabled}
            value={!workspaceTypeCheck(['HUBSPOT']) ? value || new Date() : value || null}
            onChange={onChange}
        />
    case 'date':
        return <CalendarInput
            tabIndex={tabIndex}
            disabled={disabled}
            value={!workspaceTypeCheck(['HUBSPOT']) ? value || new Date() : value || null}
            onChange={onChange}
        />
    case 'currency':
    case 'int':
        return <Input
            fullWidth
            tabIndex={tabIndex}
            disabled={disabled}
            value={value}
            type='number'
            onChange={e => onChange(e.target.value)}
            onBlur={data => {
                onBlur(data);
            }}
            onKeyDown={data => {
                onKeyDown(data);
            }}
            placeholder={placeholder}
        />
    case 'url':
        return (
            <Input
                fullWidth
                tabIndex={tabIndex}
                disabled={disabled}
                value={value}
                type='url'
                onChange={e => onChange(e.target.value)}
                onBlur={data => {
                    onBlur(data);
                }}
                onKeyDown={data => {
                    onKeyDown(data);
                }}
                placeholder={placeholder}
                onDoubleClick={e => {
                    e.preventDefault();
                    e.stopPropagation()
                    window.open(value?.indexOf('http://') === 0 || value?.indexOf('https://') === 0 ? value : `https://${value}`, '_blank', 'noopener, noreferrer')
                }}
                data-tooltip-id='default'
                data-tooltip-content='Double click this field to open the url directly'
            />
        )
    default:
        return <Input
            fullWidth
            tabIndex={tabIndex}
            disabled={disabled}
            value={value}
            onChange={e => onChange(e.target.value)}
            onBlur={data => {
                onBlur(data);
            }}
            onKeyDown={data => {
                onKeyDown(data);
            }}
            placeholder={placeholder}
        />
    }
}

export const SalesForceInputComponent = ({
    label,
    className,
    labelClassName,
    inputClassName,
    labelRequired,
    ...rest
}) => {
    return (
        <Row
            fullWidth
            className={`${cn.rowWrapper} ${className}`}
            onClick={e => {
                e.stopPropagation()
                e.preventDefault()
            }}
        >
            {label && rest.type !== 'boolean' && <Label className={`${cn.label} ${labelClassName}`} required={labelRequired}>{label}</Label>}
            <InputComponent label={label} {...rest} className={inputClassName} />
        </Row>
    )
}
