import React from 'react'
import { faTimes } from '@fortawesome/free-solid-svg-icons'
import { Row } from "../Row"
import { LOADER_TYPES, Loader } from '../Loader'
import { fileSizeConverter } from '../../utilities/fileSizeConverter'
import { Button, BUTTON_TYPES } from '../Button'
import cn from './NoteAttachments.module.css'

export const NoteAttachments = ({ note, removedAttachment, onRemoveAttachment, setShowDeleteAttachment }) => {
    return note.attachments?.length ? (
        <Row wrap fullWidth className={cn.attachmentsWrapper}>
            {note.attachments?.map(attachment => (
                <Row noMargin className={`${cn.attachment} ${!onRemoveAttachment ? cn.noDeleteButton : ''}`} onClick={() => window.open(attachment.url, '_blank')} title={attachment.name}>
                    {removedAttachment === attachment.id ? (
                        <Loader type={LOADER_TYPES.SPINNER} />
                    ) : (
                        <>
                            <img src={attachment.url} alt={attachment.name} />
                            <div className={cn.name}>{attachment.name}</div>
                            <div className={cn.size}>({fileSizeConverter(attachment.size)})</div>
                            {!!onRemoveAttachment && (
                                <Button
                                    type={BUTTON_TYPES.ICON}
                                    icon={faTimes}
                                    onClick={(e) => {
                                        setShowDeleteAttachment && setShowDeleteAttachment({ attachment, noteId: note.id })
                                        e.stopPropagation()
                                        e.preventDefault()
                                    }}
                                    title='Delete'
                                />
                            )}
                        </>
                    )}
                </Row>
            ))}
        </Row>
    ) : null
}