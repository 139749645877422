import React, { useEffect, useRef, useState, useCallback } from 'react'
import { connect } from 'react-redux'
import DatePicker from "react-datepicker";
import { faPlus } from '@fortawesome/free-solid-svg-icons'
import "react-datepicker/dist/react-datepicker.css";
import * as moment from 'moment'
import { useHistory, useParams, Link } from 'react-router-dom';
import debounce from 'lodash.debounce';
import cn from './Tasks.module.css'
import { ViewContainer } from '../../components/ViewContainer'
import { Heading } from '../../components/Heading'
import { Row } from '../../components/Row'
import { DropdownToggleIndicator } from '../../components/DropdownToggleIndicator'
import { Button, BUTTON_TYPES } from '../../components/Button'
import { Pagination } from '../../components/Pagination'
import { Modal, MODAL_SIZES } from '../../components/Modal'
import { Dropdown } from '../../components/Dropdown'
import { renderDayContents } from '../../components/CalendarInput'
import { Table } from '../../components/Table'
import { TableSearchInput } from '../../components/TableSearchInput'
import { makeRequest } from '../../utilities/endpoints'
import { URLS } from '../../config'
import { useOutsideClickHandler } from '../../customHooks/useOutsideClickHandler'
import { Details } from '../accounts/account/components/Details';
import { Contacts } from '../accounts/account/components/Contacts';
import { Tabs } from '../../components/Tabs';
import { PopoverGithubPicker } from '../../components/PopoverGithubPicker';
import { Label } from '../../components/Label';
import { getUserPermissions, permissionCheck } from '../../utilities/permissionCheck';
import { PermissionCheck } from '../../components/PermissionCheck'
import { SlateEditor } from '../../components/SlateEditor';
import { convertSimpleToRichText, serialize } from '../../utilities/slateUtils';
import { Loader, LOADER_SIZES, LOADER_TYPES } from '../../components/Loader';
import { workspaceTypeCheck } from '../../utilities/workspaceType';
import { AddTask } from './AddTask';
import { Nbsp } from '../../components/Nbsp';
import { isJSON } from '../../utilities/isJSON';
import { translateCRMLabel } from '../../utilities/translateCRMLabels';
import { NOTES_TYPES, Notes } from '../../components/Notes/Notes';

const tabs = [
    'Today',
    'Future',
    'Completed',
    'Overdue'
]
// taken directly from the source code -> https://github.com/casesandberg/react-color/blob/bc9a0e1dc5d11b06c511a8e02a95bd85c7129f4b/src/components/github/Github.js#L121
// last one has been replaced with white to reset the filter
const PICKER_COLORS = [
    '#B80000',
    '#DB3E00',
    '#FCCB00',
    '#008B02',
    '#006B76',
    '#1273DE',
    '#004DCF',
    '#5300EB',
    '#EB9694',
    '#FAD0C3',
    '#FEF3BD',
    '#C1E1C5',
    '#BEDADC',
    '#C4DEF6',
    '#BED3F3',
    '#FFFFFF'
]

let tasksLoadingRequest = null;

export const Tasks = ({ user, filterByUserId, embedded, accountId, showTodayByDefault }) => {
    getUserPermissions()
    const history = useHistory()
    const { page: routePage, tab, color: routeColor, name: routeName, level: routeLevel } = useParams()
    const page = parseInt(routePage) || 0;
    const color = routeColor === 'null' ? null : routeColor
    const name = routeName === 'null' ? null : routeName
    const [tasks, setTasks] = useState([])
    const [count, setCount] = useState(0)
    const [expandedObjects, setExpandedObjects] = useState([])
    const [currentPage, setCurrentPage] = useState(page || 0)
    const [addTaskModalShown, setAddTaskModalShown] = useState(false)
    const [availableAccounts, setAvailableAccounts] = useState([])
    const [taskCreationSent, setTaskCreationSent] = useState(false)
    const [calendarTask, setCalendarTask] = useState(null)
    const [focusedTask, setFocusedTask] = useState(null)
    const [accountDetails, setAccountDetails] = useState(null)
    const [contacts, setContacts] = useState([])
    const [users, setUsers] = useState([])
    const [teams, setTeams] = useState([])
    const [showModalPicker, setShowModalPicker] = useState(false)
    const [currentTab, setCurrentTab] = useState(showTodayByDefault ? tabs[0] : tab)
    const [showReassign, setShowReassign] = useState(false)
    const [reassigned, setReassigned] = useState(false)
    const [sfObjectsToNames, setSfObjectsToNames] = useState([])
    const [colorFilter, setColorFilter] = useState(color ? `#${color}` : null)
    const [sortedDir, setSortedDir] = useState(null);
    const [sortedCol, setSortedCol] = useState(null);
    const [tabLoaded, setTabLoaded] = useState(false);
    const [addPlaybookModalShown, setAddPlaybookModalShown] = useState(false)
    const [playbooks, setPlaybooks] = useState([])
    const [manualPlayBookAccount, setManualPlayBookAccount] = useState(null)
    const [manualPlayBookPlayBook, setManualPlayBookPlayBook] = useState(null)
    let userFilter = accountId ? 'COMPANY_LEVEL' : routeLevel !== 'null' ? routeLevel : user.id
    const [lastEmbedUserId, setLastEmbedUserId] = useState()
    const [usersInTheWorkspace, setUsersInTheWorkspace] = useState([])
    const [smallerScreen, setSmallerScreen] = useState(window.innerWidth < 900)
    const openedCalendarRef = useRef(null);

    if (embedded) {
        userFilter = filterByUserId
    }

    const columnProps = [
        'name',
        null,
        null,
        null,
        null,
        null,
        'dueDate'
    ];

    useOutsideClickHandler(openedCalendarRef, () => {
        setCalendarTask(null)
        setShowModalPicker(false)
    })

    const showAssignee = userFilter === 'COMPANY_WIDE'

    const changeTab = tabIndex => setCurrentTab(tabs[tabIndex])

    const createUniqueObjectId = object => `${object.objectName}-${object.objectId}`

    const getSfObjectToNames = async object => {
        if (!object.objectId || !object.objectName) return
        const res = await makeRequest.get(`${URLS.main}/objectName/${object.objectId}/${object.objectName}`)
        return res.data
    }

    const completeTask = taskId => {
        const subject = focusedTask.name ? focusedTask.name.split(0, 200) : ''
        const account = getAccountFromTask(focusedTask)
        makeRequest.put(`${URLS.main}/tasks/complete/${taskId}`, {
            StartDateTime: new Date(new Date().toISOString()).getTime(),
            WhatId: account?.objectId,
            Subject: `Callypso play - ${subject} marked as DONE`,
            Description: focusedTask.description,
            DurationInMinutes: 30
        }, 2)
            .then(() => {
                loadTasks(page || 0, color, currentTab, sortedCol, columnProps, sortedDir, name);
                setFocusedTask({
                    ...focusedTask,
                    completed: new Date().valueOf()
                })
            })
            .catch(console.error)
    }

    const loadPlaybooks = () => {
        makeRequest.get(`${URLS.main}/playbooks`)
            .then(res => {
                setPlaybooks(res.data?.playbooks || [])
                setManualPlayBookPlayBook(res.data?.playbooks && res.data?.playbooks[0]?.id)
            })
    }

    useEffect(() => {
        getUsers()
        getTeams()
        if (!availableAccounts.length) {
            loadAccounts()
            loadPlaybooks()
        }
        window.addEventListener('resize', () => setSmallerScreen(window.innerWidth < 900))
    }, [])

    useEffect(() => {
        if (!tab) {
            return // when the tasks are loaded on the home page
        }
        if (currentTab !== tab) {
            setCurrentPage(0)
            const updatedColor = color ? color.replace('#', '') : null
            history.push(`/tasks/${currentTab}/0/${updatedColor}/null/${userFilter}`)
            setSortedCol(null);
            setSortedDir(null);
            loadTasks(0, updatedColor, currentTab, null, columnProps, null, null);
        }
    }, [currentTab, tab])


    useEffect(() => {
        if (page === currentPage || showTodayByDefault) return
        loadTasks(page, color, currentTab, sortedCol, columnProps, sortedDir, name);
        setExpandedObjects([])
        setCurrentPage(page)
    }, [currentPage, page])

    useEffect(() => {
        loadTasksDebounce(page || 0, color, currentTab, sortedCol, columnProps, sortedDir, name);
    }, [name]);

    useEffect(() => {
        if (embedded && userFilter !== lastEmbedUserId && showTodayByDefault) {
            setLastEmbedUserId(userFilter)
        }
    }, [lastEmbedUserId, userFilter, embedded, showTodayByDefault])

    const changePageForHomePage = page => {
        setCurrentPage(page)
        loadTasks(page, color, currentTab, sortedCol, columnProps, sortedDir, name)
    }

    const createManualTask = (newTaskData) => {
        setTaskCreationSent(true)
        makeRequest.post(`${URLS.main}/tasks`, {
            ...newTaskData,
            dueDate: (newTaskData.dueDate ? new Date(newTaskData.dueDate) : new Date()).getTime(),
            accountId: newTaskData.accountId ? newTaskData.accountId : availableAccounts[0] && availableAccounts[0].id
        })
            .then(() => {
                setTaskCreationSent(false)
                setAddTaskModalShown(false)
                loadTasks(page, color, currentTab, sortedCol, columnProps, sortedDir, name);
            })
    }

    const changeDueDate = (taskId, dueDate) => {
        makeRequest.put(`${URLS.main}/tasks/${taskId}`, {
            dueDate
        })
            .then(() => loadTasks(page, color, currentTab, sortedCol, columnProps, sortedDir, name))
        setCalendarTask(false)
        setShowModalPicker(false)
        if (focusedTask) {
            setFocusedTask({
                ...focusedTask,
                dueDate
            })
        }
    }

    const showAddPlaybookModal = () => {
        setAddPlaybookModalShown(true)
    }

    const hideAddPlaybookModal = () => {
        setAddPlaybookModalShown(false)
    }

    const showAddTaskModal = () => {
        setAddTaskModalShown(true)
    }

    const hideAddTaskModal = () => {
        setAddTaskModalShown(false)
    }

    const getUsers = () => {
        makeRequest.get(`${URLS.main}/workspace/users`)
            .then(res => {
                if (res && res.data && res.data.success) {
                    setUsersInTheWorkspace(res.data.users)
                }
            })
    }

    const getTeams = () => {
        makeRequest.get(`${URLS.main}/teams`)
            .then(res => {
                if (res && res.data) {
                    setTeams(res.data.teams)
                }
            })
    }

    const loadTasks = (
        updatedPage=currentPage,
        color=(colorFilter && colorFilter.replace('#', '')),
        curTab=currentTab,
        colNum=null,
        columnProps=null,
        sortedDir=null,
        filter=null,
        filterOnUser=userFilter
    ) => {
        tasksLoadingRequest && tasksLoadingRequest.cancel()
        curTab = curTab.toLowerCase()
        setTabLoaded(false);
        if (typeof colNum === 'number' || filter) {
            const filterStr = filter || 'null';
            const name = columnProps[colNum] || null;
            tasksLoadingRequest = makeRequest.get(`${URLS.main}/tasks/sorted/${filterStr}/${name}/${sortedDir}/${curTab}/10/${updatedPage === undefined ? page : updatedPage}/${color || null}/${filterOnUser}`);
            tasksLoadingRequest.then(res => {
                if (res && res.data) {
                    setSortedDir(sortedDir);
                    setSortedCol(colNum);
                    setTasks(res.data.tasks)
                    setCount(res.data.count)
                }
                setTabLoaded(true);
            }).catch(() => {
                setTabLoaded(true);
            });
        } else {
            const url = !!accountId ? `${URLS.main}/tasks/${accountId}/10/${updatedPage === undefined ? page : updatedPage}` : `${URLS.main}/tasks/${curTab}/10/${updatedPage === undefined ? page : updatedPage}/${color || null}/${filterOnUser}`
            tasksLoadingRequest = makeRequest.get(url);
            tasksLoadingRequest.then(res => {
                if (res && res.data) {
                    setTasks(res.data.tasks)
                    setCount(res.data.count)
                }
                setTabLoaded(true);
            }).catch(() => {
                setTabLoaded(true);
            });
        }
    }

    const loadTasksDebounce = useCallback(
        debounce(loadTasks, 350),
        []
    );


    const focusTask = taskIndex => {
        const account = getAccountFromTask(tasks[taskIndex])
        setFocusedTask(tasks[taskIndex])
        account && getAccountDetails(account.objectId)
    }

    const hideFocusedTaskModal = () => {
        setFocusedTask(null)
        setAccountDetails(null)
        setContacts([])
        setReassigned(false)
        setShowReassign(false)
        setShowModalPicker(false)
    }

    const getContacts = accountId => {
        makeRequest.get(`${URLS.main}/contacts/${accountId}`)
            .then(res => {
                if (res && res.data) {
                    setContacts(res.data)
                }
            })
    }

    const getAccountDetails = accountId => {
        makeRequest.get(`${URLS.main}/account/${accountId}`)
            .then(res => {
                setAccountDetails(res.data)
            })

        getContacts(accountId)

        makeRequest.get(`${URLS.main}/workspace/users`)
            .then(res => {
                if (res && res.data) {
                    setUsers(res.data.users)
                }
            })
    }

    const loadAccounts = () => {
        makeRequest.get(`${URLS.main}/accounts`)
            .then(res => {
                if (res && res.data) {
                    setAvailableAccounts(res.data)
                    setManualPlayBookAccount(res.data && res.data[0]?.id)
                }
            })
    }

    const getTableHeaders = () => {
        if (!!accountId || (embedded && !accountId)) {
            return [
                "Name",
                "Description",
                "Playbook",
                showAssignee ? "Assignee" : null,
                "Due Date",
            ].filter(el => !!el)
        }
        if (currentTab === 'completed') {
            return [
                "Name",
                "Description",
                "Playbook",
                ['Connected', 'Objects'],
                translateCRMLabel('Account'),
                showAssignee ? "Assignee" : null,
                ["Completed", "Date"]
            ].filter(el => !!el)
        }
        return [
            "Name",
            "Description",
            "Playbook",
            ['Connected', 'Objects'],
            translateCRMLabel('Account'),
            showAssignee ? "Assignee" : null,
            "Due Date"
        ].filter(el => !!el)
    }

    const getAccountFromTask = task => task.sfObjects.find(el => el.objectName === 'Account')

    const tasksMapper = task => {
        const account = getAccountFromTask(task)
        const taskObject = {
            name: task.name,
            description: task.description,
            playbook: task.numberOfTemplates !== null && task.templateIndex !== null ? <Link
                data-tooltip-id='default'
                data-tooltip-content={`This is ${task.templateIndex + 1} of ${task.numberOfTemplates} total plays`}
                to={`/playbook/${task.playBookId}`}
            >
                {`${task.playBookName} (${task.templateIndex + 1}/${task.numberOfTemplates})`}
            </Link> : null,
            sfObjects: task.sfObjects,
            // eslint-disable-next-line eqeqeq
            account: account ? availableAccounts.find(el => el.id == account.objectId) : undefined,
            assignee: usersInTheWorkspace.find(el => el.id === task.assignee)?.fullName,
        }

        if (!showAssignee) {
            delete taskObject.assignee
        }

        if ((!!accountId || (embedded && !accountId))) {
            const { account, sfObjects, ...rest } = taskObject
            return {
                ...rest,
                dueDate: moment(task.dueDate).format()
            }
        }

        if (currentTab === 'completed') {
            return {
                ...taskObject,
                completed: moment(task.completed).format()
            }
        }
        return {
            ...taskObject,
            dueDate: moment(task.dueDate).format()
        }
    }

    const reassign = assignee => {
        setFocusedTask({
            ...focusedTask,
            assignee
        })
        makeRequest.put(`${URLS.main}/tasks/reassign/${focusedTask.id}`, {
            assignee
        }).then(() => {
            setShowReassign(false)
            setReassigned(true)
            loadTasks(currentPage, color, currentTab, sortedCol, columnProps, sortedDir, name)
        })
    }

    const changeColor = (index, color) => {
        if (color.toUpperCase() === PICKER_COLORS[PICKER_COLORS.length - 1]) {
            color = null
        }
        setTasks(tasks.map((el, i) => ({
            ...el,
            color: i === index ? color : el.color
        })))
        makeRequest.put(`${URLS.main}/tasks/${tasks[index].id}`, {
            color
        })
    }

    const changeColorFilter = color => {
        if (color.toUpperCase() === PICKER_COLORS[PICKER_COLORS.length - 1]) {
            setColorFilter(null)
            history.push(`/tasks/${currentTab}/0/null/${name}/${userFilter}`)
            loadTasks(0, null, currentTab, sortedCol, columnProps, sortedDir, name)
        } else {
            setColorFilter(color)
            const colorCode = color.replace('#', '')
            history.push(`/tasks/${currentTab}/0/${colorCode}/${name}/${userFilter}`)
            if (page === 0) {
                loadTasks(0, colorCode, currentTab, sortedCol, columnProps, sortedDir, name)
            }
        }
    }

    const addManualPlaybook = () => {
        makeRequest.post(`${URLS.main}/playbooks/manual`, {
            playBookId: manualPlayBookPlayBook,
            accountId: manualPlayBookAccount
        })
            .then(() => {
                loadTasks(page, color, currentTab, sortedCol, columnProps, sortedDir, name);
            })
        setAddPlaybookModalShown(false)
    }

    return (
        <>
            <ViewContainer skipContainer={!!accountId || !!showTodayByDefault}>
                {!embedded && (
                    <Row noMargin spaceBetween fullWidth>
                        {!smallerScreen && <Heading className={cn.grow}>Plays LIST ({count})</Heading>}
                        <Row fullWidth noMargin endAlign={!smallerScreen} wrap={smallerScreen} spaceBetween={smallerScreen}>
                            {smallerScreen && <Heading>Plays LIST ({count})</Heading>}
                            <Row noMargin className={`${cn.filterOnColorWrapper} ${smallerScreen ? cn.noMarginRight : ''}`}>
                                <Label>
                                    Level
                                </Label>
                                <Nbsp />
                                <Dropdown
                                    fullWidth
                                    className={cn.topNavigationDropdown}
                                    value={userFilter}
                                    onChange={e => {
                                        history.push(`/tasks/${currentTab}/0/${!!colorFilter ? colorFilter.replace('#', '') : 'null'}/${name}/${e.target.value}`)
                                        loadTasks(0, colorFilter, currentTab, sortedCol, columnProps, sortedDir, name, e.target.value)
                                    }}
                                    values={!!usersInTheWorkspace?.length ? [
                                        {
                                            value: 'COMPANY_WIDE',
                                            label: 'Company Wide'
                                        },
                                        ...teams.map(team => ({
                                            value: team.users.map(el => el.id).join(','),
                                            label: team.name
                                        })).filter(el => !!el.value),
                                        ...usersInTheWorkspace.map(el => ({
                                            value: el.id,
                                            label: el.fullName || el.email
                                        }))
                                    ] : []}
                                />
                            </Row>
                            <Row noMargin className={`${cn.filterOnColorWrapper} ${smallerScreen ? cn.noMarginRight : ''}`}>
                                <Label noMargin>
                                    Filter on Color
                                </Label>
                                <Nbsp />
                                <PopoverGithubPicker
                                    color={colorFilter || '#A0FFBB'}
                                    colors={PICKER_COLORS}
                                    onChange={changeColorFilter}
                                    alignTowardsLeft
                                />
                            </Row>
                            {smallerScreen && (
                                <>
                                    <div className={cn.break} />
                                    <TableSearchInput
                                        className={`${cn.tableSearch} ${cn.marginBottom} ${cn.smallerScreenInput}`}
                                        placeHolder={'Filter By Play Name Or Description...'}
                                        testId={'TABLE_SEARCH_INPUT'}
                                        filters={name}
                                        filterCallBack={(filterVal) => {
                                            history.push(`/tasks/${currentTab}/0/${color}/${filterVal||'null'}/${userFilter}`)
                                        }}
                                    />
                                </>
                            )}
                            <PermissionCheck requiredPermissions={[['TASKS', 'CREATE']]}>
                                <Button
                                    className={smallerScreen && cn.marginBottom}
                                    onClick={showAddPlaybookModal}
                                    type={BUTTON_TYPES.ICON_AND_TEXT}
                                    icon={faPlus}
                                    testId={'ADD_MANUAL_BUTTON'}
                                >
                                    Playbook
                                </Button>
                                <Nbsp />
                                <Button
                                    className={smallerScreen && cn.marginBottom}
                                    onClick={showAddTaskModal}
                                    type={BUTTON_TYPES.ICON_AND_TEXT}
                                    icon={faPlus}
                                    testId={'ADD_MANUAL_BUTTON'}
                                >
                                    Play
                                </Button>
                            </PermissionCheck>
                            {!smallerScreen && (
                                <>
                                    <Nbsp />
                                    <TableSearchInput
                                        className={cn.tableSearch}
                                        placeHolder={'Filter By Play Name Or Description...'}
                                        testId={'TABLE_SEARCH_INPUT'}
                                        filters={name}
                                        filterCallBack={(filterVal) => {
                                            history.push(`/tasks/${currentTab}/0/${color}/${filterVal||'null'}/${userFilter}`)
                                        }}
                                    />
                                </>
                            )}
                        </Row>
                    </Row>
                )}
                {!embedded && (
                    <Tabs
                        selected={tabs.indexOf(currentTab)}
                        options={tabs}
                        onChange={changeTab}
                    />
                )}
                {!tabLoaded && (
                    <Loader size={LOADER_SIZES.EXTRA_BIG} type={LOADER_TYPES.SPINNER} />
                )}
                {tabLoaded && (
                    <Table
                        borderless={!!embedded}
                        testId={'ALERTS_TABLE'}
                        headers={getTableHeaders()}
                        customStyles={accountId || (embedded && !accountId) ? {
                            1: {
                                overflow: 'hidden',
                                whiteSpace: 'nowrap',
                                textOverflow: 'ellipsis',
                                paddingLeft: '25px'
                            },
                            [showAssignee ? 3 : 100]: {
                                overflow: 'hidden',
                                whiteSpace: 'nowrap',
                                textOverflow: 'ellipsis',
                                paddingLeft: '25px'
                            },
                            [showAssignee ? 4 : 3]: {
                                maxWidth: 120,
                                minWidth: 120,
                                width: 120,
                            }
                        } : {
                            3: {
                                maxWidth: 148,
                                minWidth: 148,
                                width: 148
                            },
                            4: {
                                overflow: 'hidden',
                                whiteSpace: 'nowrap',
                                textOverflow: 'ellipsis',
                            },
                            [showAssignee ? 5 : 100]: {
                                overflow: 'hidden',
                                whiteSpace: 'nowrap',
                                textOverflow: 'ellipsis',
                            },
                            [showAssignee ? 6 : 5]: {
                                maxWidth: 136,
                                minWidth: 136,
                                width: 136
                            }
                        }}
                        sorted={!accountId && !embedded}
                        allowCellOverflow
                        sortDir={sortedDir}
                        sortedCol={sortedCol}
                        sortMask={columnProps.map(el => el === null)}
                        sortCallBack={(colName, colNum, sortedDir) => {
                            loadTasks(currentPage, color, currentTab, colNum, columnProps, sortedDir, name);
                        }}
                        data={
                            tasks.map(tasksMapper).map((task, taskIndex) => (
                                Object.keys(task).map(key => (
                                    key === 'name' ? (
                                        <div className={cn.name} onClick={() => focusTask(taskIndex)}>
                                            <PopoverGithubPicker
                                                color={tasks[taskIndex].color && tasks[taskIndex].color.length ? tasks[taskIndex].color : undefined}
                                                colors={PICKER_COLORS}
                                                onChange={color => changeColor(taskIndex, color)}
                                                disabled={!permissionCheck('TASKS', 'UPDATE')}
                                            />
                                            <span>{task[key]}</span>
                                        </div>
                                    ) : key === 'sfObjects' ? (
                                        <div>
                                            <div
                                                className={cn.sfObjectsCounter}
                                                onClick={async () => {
                                                    if (expandedObjects.includes(taskIndex)) {
                                                        setExpandedObjects(expandedObjects.filter(el => el !== taskIndex))
                                                    } else {
                                                        const existingIds = sfObjectsToNames.map(el => el.id)
                                                        const missingObjects = task.sfObjects.filter(el => !existingIds.includes(createUniqueObjectId(el)))
                                                        const data = [...sfObjectsToNames]
                                                        for (const missingObject of missingObjects) {
                                                            const name = await getSfObjectToNames(missingObject)
                                                            data.push({
                                                                id: createUniqueObjectId(missingObject),
                                                                name: name
                                                            })
                                                        }
                                                        setSfObjectsToNames(data)
                                                        setExpandedObjects([...expandedObjects, taskIndex])
                                                    }
                                                }}
                                            >
                                                {task[key].length}
                                                <DropdownToggleIndicator className={cn.dropdownIndicator} width={8} height={8} />
                                            </div>
                                            {expandedObjects.includes(taskIndex) && (
                                                <div className={cn.sfObjectsList}>
                                                    {task.sfObjects.map(el => {
                                                        // eslint-disable-next-line
                                                        const details = sfObjectsToNames.find(obj => obj.id == createUniqueObjectId(el))
                                                        return (
                                                            <Button
                                                                fullWidth
                                                                type={BUTTON_TYPES.TRANSPARENT}
                                                                clip
                                                                // eslint-disable-next-line
                                                                onClick={() => {
                                                                    if (workspaceTypeCheck(['HUBSPOT'])) {
                                                                        if (['account', 'company'].includes(el.objectName.toLowerCase())) {
                                                                            window.open(`${window.location.protocol}//${window.location.host}/account/${el.objectId}`)
                                                                        } else if (['deal', 'opportunity'].includes(el.objectName.toLowerCase())) {
                                                                            window.open(`${window.location.protocol}//${window.location.host}/opportunities-management/edit/${el.objectId}`)
                                                                        } else if (el.objectName.toLowerCase() === 'contact') {
                                                                            const companyId = task.sfObjects.find(el => ['account', 'company'].includes(el.objectName.toLowerCase()))
                                                                            window.open(`${window.location.protocol}//${window.location.host}/account/${companyId?.objectId}/contact/${el.objectId}`)
                                                                        }
                                                                    } else {
                                                                        window.open(`${user.sfUrl}/${el.objectId}`)
                                                                    }
                                                                }}
                                                            >
                                                                {details && details.name && details.name !== 'undefined' ? details.name : el.objectName}
                                                            </Button>
                                                        )
                                                    })}
                                                </div>
                                            )}
                                        </div>
                                    ) : key === 'completed' ? (
                                        <div className={cn.dueDate}>
                                            {moment(task[key]).format('MMMM D')}
                                        </div>
                                    ) : key === 'dueDate'
                                        ? calendarTask === taskIndex ? (
                                            <div className={cn.dueDate}>
                                                <div className={cn.dueDate}>
                                                    {moment(task[key]).format('MMMM D')}
                                                </div>
                                                <div className={cn.dueDateCalendarWrapper}>
                                                    <DatePicker
                                                        inline
                                                        showPopperArrow={false}
                                                        selected={new Date(task[key])}
                                                        onChange={e => changeDueDate(tasks[taskIndex].id, new Date(e).valueOf())}
                                                        className={cn.taskDueDateCalendar}
                                                        customInput={null}
                                                    />
                                                </div>
                                            </div>
                                        ) : (
                                            <div className={cn.dueDate} onClick={() => setCalendarTask(taskIndex)}>
                                                {moment(task[key]).format('MMMM D')}
                                            </div>
                                        ) : key === 'account' ? (
                                            <Button
                                                className={cn.accountNameButton}
                                                type={BUTTON_TYPES.TRANSPARENT}
                                                onClick={() => {
                                                    history.push(`/account/${(task.account || {}).id}`)
                                                }}
                                            >
                                                {(task.account || {}).name}
                                            </Button>
                                        ) : <div className={cn.tableContent}>{isJSON(task[key]) ? serialize(task[key]) : task[key]}</div>
                                ))
                            ))
                        }
                    />
                )}
            </ViewContainer>
            {embedded ? (
                <>
                    <br />
                    <Pagination pages={Math.ceil(count / 10)} onClick={changePageForHomePage} index={currentPage} />
                </>

            ) : (
                <Pagination pages={Math.ceil(count / 10)} />
            )}
            {addPlaybookModalShown && (
                <Modal
                    onClose={hideAddPlaybookModal}
                    header="Add Manual Playbook"
                    buttons={<Button fullWidth onClick={addManualPlaybook}>
                        ADD Playbook
                    </Button>}
                >
                    <Dropdown
                        fullWidth
                        values={availableAccounts.map(el => ({
                            label: el.name,
                            value: el.id
                        }))}
                        value={manualPlayBookAccount}
                        onChange={e => setManualPlayBookAccount(e.target.value)}
                    />
                    <br />
                    <br />
                    <Dropdown
                        fullWidth
                        value={manualPlayBookPlayBook}
                        values={playbooks.map(el => ({
                            label: el.name,
                            value: el.id
                        }))}
                        onChange={e => setManualPlayBookPlayBook(e.target.value)}
                    />
                </Modal>
            )}
            {addTaskModalShown && (
                <AddTask
                    onClose={hideAddTaskModal}
                    disabledButton={taskCreationSent}
                    onCreate={createManualTask}
                    availableAccounts={availableAccounts}
                />
            )}
            {focusedTask !== null && (
                <Modal
                    onClose={hideFocusedTaskModal}
                    header={`DETAILS FOR: "${focusedTask?.name}"`}
                    size={MODAL_SIZES.BIG}
                    minWidth
                >
                    <div className={cn.taskDetailsWrapper}>
                        <div className={cn.leftPart}>
                            <ViewContainer>
                                <Details
                                    inTasks
                                    accountDetails={{
                                        ...accountDetails,
                                        id: getAccountFromTask(focusedTask)?.objectId
                                    }}
                                    contacts={contacts}
                                />
                                <hr className={cn.separator} />
                                <div className={cn.taskDetailsWrapperContactsWrapper}>
                                    <Contacts
                                        contacts={contacts}
                                        onContactAdded={() => getContacts(getAccountFromTask(focusedTask)?.objectId)}
                                        accountId={getAccountFromTask(focusedTask)?.objectId}
                                    />
                                </div>
                                <hr className={cn.separator} />
                                <div className={cn.descriptionModalWrapper}>
                                    <Heading>Description</Heading>
                                    <span>
                                        <SlateEditor
                                            fullWidth
                                            value={isJSON(focusedTask?.description) ? focusedTask.description : convertSimpleToRichText(focusedTask?.description || 'N/A')}
                                            viewOnly
                                        />
                                        <br />
                                        By
                                        {' '}
                                        {moment(focusedTask?.dueDate).format('MMMM D')}
                                    </span>
                                </div>
                            </ViewContainer>
                        </div>
                        <div className={cn.rightPart}>
                            <ViewContainer>
                                <div className={cn.taskActions}>
                                    {focusedTask.completed ? (
                                        <div>
                                            Completed at{' '}
                                            {moment(focusedTask.completed).format('MMMM D')}
                                        </div>
                                    ) : (
                                        <>
                                            <Button
                                                testId={'MARK_AS_DONE_BUTTON'}
                                                disabled={reassigned || focusedTask?.assignee !== user?.id}
                                                onClick={() => completeTask(focusedTask.id)}
                                            >
                                                Mark as Done
                                            </Button>
                                            <PermissionCheck requiredPermissions={[['TASKS', 'UPDATE']]}>
                                                {showReassign ? (
                                                    <Dropdown
                                                        testId={'REASSIGN_DROPDOWN'}
                                                        className={cn.reassignDropdown}
                                                        values={users.map(el => ({
                                                            label: el.fullName,
                                                            value: el.id
                                                        }))}
                                                        value={focusedTask.assignee}
                                                        onChange={e => reassign(e.target.value)}
                                                    />
                                                ) : (
                                                    <Button
                                                        testId={'RESCHEDULE_BUTTON'}
                                                        disabled={reassigned || focusedTask?.assignee !== user?.id}
                                                        onClick={() => setShowReassign(true)}
                                                    >
                                                        Reassign
                                                    </Button>
                                                )}
                                            </PermissionCheck>
                                            <Button
                                                disabled={reassigned || focusedTask?.assignee !== user?.id}
                                                onClick={() => setShowModalPicker(true)}
                                            >
                                                Reschedule
                                            </Button>
                                        </>
                                    )}
                                    {
                                        showModalPicker && (
                                            <div className={cn.modalCalendarWrapper} ref={openedCalendarRef}>
                                                <DatePicker
                                                    inline
                                                    showPopperArrow={false}
                                                    renderDayContents={renderDayContents}
                                                    selected={new Date(focusedTask?.dueDate)}
                                                    onChange={e => changeDueDate(focusedTask?.id, new Date(e).valueOf())}
                                                    className={cn.taskDueDateCalendar}
                                                    customInput={null}
                                                />
                                            </div>
                                        )
                                    }
                                </div>
                                <hr className={cn.separator} />
                                <Heading>Notes</Heading>
                                <div className={cn.notesWrapper}>
                                    <Notes
                                        type={NOTES_TYPES.ACCOUNT}
                                        id={getAccountFromTask(focusedTask)?.objectId}
                                        accountName={getAccountFromTask(focusedTask)?.objectName}
                                    />
                                </div>
                            </ViewContainer>
                        </div>
                    </div>
                </Modal>
            )}
        </>
    )
}

export const mapStateToProps = (state, ownProps) => {
    return {
        user: state.user,
        ...ownProps
    }
}

export const TasksConnected = connect(mapStateToProps)(Tasks)
