import React, { useEffect, useState } from 'react'
import { createPortal } from 'react-dom'
import { useHistory, useParams } from 'react-router-dom'
import { faPenAlt, faPlus, faTrashAlt } from '@fortawesome/free-solid-svg-icons'
import cn from './Playbooks.module.css'
import { makeRequest } from '../../utilities/endpoints'
import { URLS } from '../../config'
import { LOADER_SIZES, LOADER_TYPES, Loader } from '../../components/Loader'
import { ViewContainer } from '../../components/ViewContainer'
import { PermissionCheck } from '../../components/PermissionCheck'
import { Table } from '../../components/Table'
import { BUTTON_TYPES, Button } from '../../components/Button'
import { Pagination } from '../../components/Pagination'
import { Modal } from '../../components/Modal'
import { permissionCheck } from '../../utilities/permissionCheck'
import { Row } from '../../components/Row'
import { NBSP_SIZES, Nbsp } from '../../components/Nbsp'
import { serialize } from '../../utilities/slateUtils'
import { isJSON } from '../../utilities/isJSON'
import { translateCRMLabel } from '../../utilities/translateCRMLabels'

export const Playbooks = () => {
    const { page } = useParams()
    const history = useHistory()
    const [playbooks, setPlaybooks] = useState([])
    const [loaded, setLoaded] = useState(false)
    const [count, setCount] = useState(0)
    const [connectedRulesAndTriggers, setConnectedRulesAndTriggers] = useState(null)
    const [playbookToBeDeleted, setPlaybookToBeDeleted] = useState(false)

    const getPlaybooks = () => {
        makeRequest.get(`${URLS.main}/playbooks/${page}`)
            .then(res => {
                setPlaybooks(res.data.playbooks.map(el => ({
                    ...el,
                    description: isJSON(el.description) ? serialize(el.description) : el.description
                })))
                setLoaded(true)
                setCount(res.data.count)
            })
    }

    const edit = id => {
        history.push(`/playbook/${id}`)
    }

    const remove = id => {
        makeRequest.get(`${URLS.main}/playbook/${id}/rules`)
            .then(res => setConnectedRulesAndTriggers(res.data?.data))
        setPlaybookToBeDeleted(id)
    }

    const closeDeletionModal = () => {
        setPlaybookToBeDeleted(false)
        setConnectedRulesAndTriggers(null)
    }

    const deletePlaybook = id => {
        makeRequest.delete(`${URLS.main}/playbook/${id}`)
            .then(() => {
                getPlaybooks()
            })
        setPlaybookToBeDeleted(false)
    }

    const addPlaybook = () => {
        history.push('/playbook/create')
    }

    useEffect(() => {
        getPlaybooks()
    }, [page])

    if (!loaded) {
        return <Loader size={LOADER_SIZES.BIG} type={LOADER_TYPES.SPINNER} />
    }

    return (
        <>
            <PermissionCheck requiredPermissions={[['RULES', 'CREATE']]}>
                {document.getElementById('top-nav-bar-portal') && createPortal(
                    <>
                        <Nbsp size={NBSP_SIZES.BIG} />
                        <Button
                            onClick={addPlaybook}
                            icon={faPlus}
                        >
                            ADD PLAYBOOK
                        </Button>
                    </>,
                    document.getElementById('top-nav-bar-portal')
                )}
            </PermissionCheck>
            <ViewContainer>
                <Table
                    headers={[
                        "Playbook Name",
                        "Playbook Description",
                        "Plays Inside",
                        (permissionCheck('RULES', 'UPDATE') && "ACTIONS")
                    ]}
                    data={
                        playbooks.map(playbook => (
                            ['name', 'description', 'plays', 'actions'].map(key => {
                                if (key === 'actions') {
                                    return (
                                        <PermissionCheck requiredPermissions={[['RULES', 'UPDATE']]}>
                                            <Row noMargin spaceBetween>
                                                <Button
                                                    type={BUTTON_TYPES.SMALL_ICON}
                                                    icon={faPenAlt}
                                                    className={'green'}
                                                    onClick={() => edit(playbook.id)}
                                                />
                                                <Button
                                                    type={BUTTON_TYPES.SMALLER_ICON}
                                                    icon={faTrashAlt}
                                                    className={'red'}
                                                    onClick={() => remove(playbook.id)}
                                                />
                                            </Row>
                                        </PermissionCheck>
                                    )
                                }
                                return (
                                    <div className={cn.tableContent}>{playbook[key]}</div>
                                )
                            })
                        ))
                    }
                    customStyles={{
                        2: { width: 130, paddingLeft: 0, paddingRight: 0, textAlign: 'center' },
                        3: { width: 110 },
                    }}
                />
            </ViewContainer>
            <Pagination pages={Math.ceil(count / 10)} />
            {playbookToBeDeleted && connectedRulesAndTriggers !== null && <Modal
                header={`Delete "${playbooks.find(el => el.id === playbookToBeDeleted).name}"`}
                onClose={() => setPlaybookToBeDeleted(false)}
                buttons={
                    <Row fullWidth spaceBetween>
                        <Button
                            onClick={closeDeletionModal}
                        >
                            Cancel
                        </Button>
                        {!connectedRulesAndTriggers.length ? (
                            <Button
                                onClick={() => deletePlaybook(playbookToBeDeleted)}
                            >
                                Delete
                            </Button>
                        ) : (
                            <Button
                                onClick={() => {
                                    connectedRulesAndTriggers.forEach(({ id }, i) => {
                                        const url = `${window.location.protocol}//${window.location.host}/rule/edit/${id}`
                                        if (i === connectedRulesAndTriggers.length) {
                                            window.open(url).focus()
                                        } else {
                                            window.open(url)
                                        }
                                    })
                                    closeDeletionModal()
                                }}
                            >
                                Edit Rules And Triggers
                            </Button>
                        )}
                    </Row>
                }
            >
                {!connectedRulesAndTriggers.length ? (
                    <div>
                        Are you sure you want to delete this playbook?
                        This would not remove the already created plays attached to {translateCRMLabel('accounts')} from this playbook.
                        <br/>
                        This action is irreversible.
                    </div>
                ) : `
                    You have Rules And Triggers associated with this Playbook. Please fix them first to use a different Playbook to avoid issues.
                `}
            </Modal>}
        </>
    )
}
