import { Node } from 'slate'

export const serialize = (nodes) => {
    try {
        return JSON.parse(nodes)?.map(n => Node.string(n))?.join('\n')
    } catch (error) {
        console.error('serialize catch', nodes, error)
        return nodes
    }
}

export const convertSimpleToRichText = text => (JSON.stringify([{
    type: 'paragraph',
    children: [{
        text: text || ''
    }]
}]))

export const convertNoteText = data => {
    return `${serialize(data)}\n\n *Editted in Callypso as a rich text*`
}