import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import * as Icons from '@fortawesome/free-solid-svg-icons'
import cn from './SlateEditorComponents.module.css'

export const Button = ({ className, active, reversed, ref, ...props }) => (
    <span
        {...props}
        ref={ref}
        className={className}
        style={{ 'cursor': 'pointer', 'color': `${reversed ? active ? 'white' : '#aaa' : active ? 'black' : '#ccc'}` }}
    />
)

export const EditorValue = ({ className, value, ref, ...props }) => {
    const textLines = value.document.nodes
        .map(node => node.text)
        .toArray()
        .join('\n')
    return (
        <div
            ref={ref}
            {...props}
            className={className}
            style={{ 'margin': '30px -20px 0' }}
        >
            <div
                className={cn.EditorValueDivDefault}
            >
                Slate's value as text
            </div>
            <div
                className={cn.EditorValueDiv}
            >
                {textLines}
            </div>
        </div>
    )
}

export const Icon = ({ icon, size='1x', className, ...props }) => {
    return (
        <FontAwesomeIcon className={className} icon={Icons[icon]} size={size} {...props} />
    )
}

export const Instruction = ({ className, ref, ...props }) => (
    <div
        {...props}
        ref={ref}
        className={`cn.InstructionDivStyle ${className}`}
    />
)

export const Menu = ({ className, ref, ...props }) => (
    <div
        {...props}
        ref={ref}
        className={`cn.slateMenu ${className}`}
    />
)


export const Toolbar = ({ className, style, ref, ...props }) => (
    <Menu
        {...props}
        ref={ref}
        className={className}
        style={style}
    />
)

