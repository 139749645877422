import React, { useEffect, useState, useCallback } from 'react'
import { useHistory, useParams } from "react-router-dom";
import moment from 'moment'
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { connect } from 'react-redux'
import debounce from 'lodash.debounce'
import cn from './Accounts.module.css'
import { ViewContainer } from '../../components/ViewContainer'
import { Pagination } from '../../components/Pagination'
import { Table } from '../../components/Table'
import { TableSearchInput } from '../../components/TableSearchInput'
import { makeRequest } from '../../utilities/endpoints'
import { URLS } from '../../config'
import { Loader, LOADER_SIZES, LOADER_TYPES } from '../../components/Loader';
import { BUTTON_TYPES, Button } from '../../components/Button';
import { Row } from '../../components/Row';
import { getUserPermissions, permissionCheck } from '../../utilities/permissionCheck';
import { workspaceTypeCheck } from '../../utilities/workspaceType';
import { AccountsCRUDModalWrapper } from '../../components/AccountsCRUDModalWrapper';
import { AccountActions } from '../../components/AccountActions';
import { UsageGraph } from './account/components/UsageGraph';
import { translateCRMLabel } from '../../utilities/translateCRMLabels';

export const Accounts = () => {
    getUserPermissions()
    const history = useHistory()
    const { page: routePage, accountName: routeAccountName } = useParams()
    const page = parseInt(routePage) || 0;
    const accountName = routeAccountName === 'null' ? null : routeAccountName
    const [accounts, setAccounts] = useState([])
    const [accountsRequestSent, setAccountsRequestSent] = useState(false)
    const [currentPage, setCurrentPage] = useState(page)
    const [totalPages, setTotalPages] = useState(null)

    const [addAccountModalShown, setAddAccountModalShown] = useState(false)
    const [accountParentOptions, setAccountParentOptions] = useState([])
    const [sortDir, setSortDir] = useState(null);
    const [sortedCol, setSortedCol] = useState(null);
    const [showUsage, setShowUsage] = useState(false)

    const hideAddAccountModal = () => setAddAccountModalShown(false)
    const showAddAccountModal = () => {
        if (!accountParentOptions || !accountParentOptions.length) {
            getAllAccountDetails()
        }
        setAddAccountModalShown(true)
    }

    const columnProps = [
        'Name',
        'Phone',
        ...(workspaceTypeCheck(['SFDC', 'SFDC_SANDBOX']) ? ['LastActivityDate', 'Industry'] : []),
        translateCRMLabel('Opportunities')
    ];

    // const colMask = workspaceTypeCheck(['CALLYPSO']) ? [false, false, true] : [false, false, false, false, true];

    useEffect(() => {
        if (totalPages === null) return
        if (currentPage >= totalPages) {
            history.push(`/accounts/${totalPages - 1 < 0 ? 0 : totalPages - 1}/${accountName || 'null'}`);
        }
    }, [totalPages]);


    useEffect(() => {
        if (page === currentPage) return
        setCurrentPage(page < 0 ? 0 : page)
        loadAccounts(page, sortedCol, columnProps, sortDir, accountName);
    }, [page, currentPage])

    useEffect(() => {
        loadAccountsDebounce(page || 0, sortedCol, columnProps, sortDir, accountName)
    }, [accountName])

    const reformatAccounts = (el) => {
        return {
            id: el.id,
            account: el.name,
            primaryContact: el.phone || '-',
            lastTouchDate: el.lastActivityDate ? moment(el.lastActivityDate).format('MMMM D, YYYY') : "-",
            primaryProduct: el.industry || '-',
            actions: null,
            automaticRiskEvaluation: el.automaticRiskEvaluation,
            manualRiskEvaluation: el.manualRiskEvaluation,
            ownerId: el.ownerId || el.hubspot_owner_id,
            ...(workspaceTypeCheck(['HUBSPOT']) ? el : {})
        }
    }

    const loadAccounts = (currentPage, columnNum, columnProps, sortedDir, filter = null) => {
        setAccountsRequestSent(true)

        if (typeof columnNum === 'number' || filter) {
            const filterStr = filter || 'null';
            const name = columnProps[columnNum] || null;
            makeRequest.get(`${URLS.main}/accounts/sorted/${filterStr}/${name}/${sortedDir}/${currentPage}`)
                .then(res => {
                    if (res && res.data && res.data.accounts) {
                        setSortDir(sortedDir);
                        setSortedCol(columnNum);
                        setAccounts(res.data.accounts.map(el => (reformatAccounts(el))))
                        setTotalPages(res.data.pages);
                        setAccountsRequestSent(false);
                    }
                })
                .catch(error => {
                    console.error(error)
                })
        } else {
            makeRequest.get(`${URLS.main}/accounts/${currentPage}`)
                .then(res => {
                    if (res && res.data) {
                        setAccounts(res.data.accounts.map(el => reformatAccounts(el)))
                        setTotalPages(res.data.pages)
                        setAccountsRequestSent(false)
                    }
                })
                .catch(error => {
                    console.error(error)
                })
        }
    }

    const loadAccountsDebounce = useCallback(
        debounce(loadAccounts, 250),
        []
    );

    const getAllAccountDetails = () => {
        makeRequest.get(`${URLS.main}/accounts`)
            .then(res => {
                if (res && res.data) {
                    setAccountParentOptions(res.data.map(el => ({
                        value: el.id,
                        label: el.name
                    })))
                }
            })
    }

    const gotoAccountDetails = (id) => {
        history.push(`/account/${id}`)
    }

    let fields = ['account']

    if (workspaceTypeCheck(['SFDC', 'SFDC_SANDBOX'])) {
        fields = [...fields, 'primaryContact', 'lastTouchDate', 'primaryProduct', 'actions']
    } else if (workspaceTypeCheck(['HUBSPOT'])) {
        fields = [...fields, 'industry', 'hs_num_open_deals', 'actions']
    } else {
        fields = [...fields, 'actions']
    }

    return (
        <>
            <ViewContainer>
                <Row spaceBetween noMargin>
                    {permissionCheck('ACCOUNTS', 'CREATE') ? (
                        <Button
                            type={BUTTON_TYPES.ICON_AND_TEXT}
                            icon={faPlus}
                            onClick={showAddAccountModal}
                        >
                            New Account
                        </Button>
                    ) : (
                        <div />
                    )}
                    <TableSearchInput
                        placeHolder={`Filter By ${translateCRMLabel('Account')} Name Or Primary Product...`}
                        filters={accountName}
                        filterCallBack={(filterVal) => {
                            history.push(`/accounts/0/${filterVal || 'null'}`);
                        }}
                    />
                </Row>
                <UsageGraph
                    shown={!!showUsage}
                    onClose={() => setShowUsage(false)}
                    accountId={showUsage}
                />
                {accountsRequestSent ? (
                    <Loader size={LOADER_SIZES.EXTRA_BIG} type={LOADER_TYPES.SPINNER} className={cn.loader} />
                ) : (
                    <div>
                        <Table
                            headers={[
                                translateCRMLabel('Account'),
                                ...(workspaceTypeCheck(['SFDC', 'SFDC_SANDBOX']) ? ['PRIMARY CONTACT', 'LAST TOUCH DATE', 'PRIMARY PRODUCT'] : workspaceTypeCheck(['HUBSPOT']) ? ['Industry', 'Num of Open Deals'] : []),
                                'ACTIONS'
                            ]}
                            customStyles={{
                                4: {
                                    width: 160
                                }
                            }}
                            data={accounts.map(account => (
                                fields.map((key, i) => {
                                    if (key === 'account') {
                                        return (
                                            <div className={cn.accountWrapper} key={i} onClick={() => gotoAccountDetails(account.id)}>
                                                <div className={cn.accountName}>
                                                    {account[key]}
                                                </div>
                                            </div>
                                        )
                                    }
                                    if (key === 'actions') {
                                        return <AccountActions
                                            account={account}
                                            onShowUsage={() => setShowUsage(account.id)}
                                            onEdit={() => loadAccounts(page || 0, sortedCol, columnProps, sortDir, accountName)}
                                            onDelete={() => loadAccounts(currentPage, sortedCol, columnProps, sortDir, accountName)}
                                            onReassign={(newAccountOwner) => {
                                                setAccounts(accounts.map(el => {
                                                    if (el.id === account.id) {
                                                        return {
                                                            ...el,
                                                            ownerId: newAccountOwner
                                                        }
                                                    }
                                                    return el
                                                }))
                                            }}
                                        />
                                    }
                                    return account[key]
                                })
                            ))}
                            sortCallBack={(colName, colNum, sortedDir) => { loadAccounts(currentPage, colNum, columnProps, sortedDir, accountName); }}
                        />
                    </div>
                )}
                <AccountsCRUDModalWrapper
                    header={`Creating new ${translateCRMLabel('Account')}`}
                    shown={!!addAccountModalShown}
                    create={true}
                    createCallback={() => {
                        hideAddAccountModal()
                        loadAccounts(page || 0, sortedCol, columnProps, sortDir, accountName)
                    }}
                    onClose={hideAddAccountModal}
                />
            </ViewContainer>
            <Pagination pages={totalPages} />
        </>
    )
}

export const mapStateToProps = (state) => {
    return {
        user: state.user,
    }
}

export const AccountsConnected = connect(mapStateToProps)(Accounts)
