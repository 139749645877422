import React from 'react'
import moment from 'moment'
import { formatLargeSums } from '../utilities/currency'
import { Checkbox } from './Checkbox'

export const SalesForceReadOnlyInputComponent = ({ value, type }) => {
    switch (type) {
    case 'boolean':
        return <Checkbox
            checked={!!value}
            disabled={true}
        />
    case 'url':
        return <a href={value?.indexOf('http://') === 0 || value?.indexOf('https://') === 0 ? value : `https://${value}`} target='_blank' rel='noreferrer'>{value}</a>
    case 'datetime':
        return !!value ? moment(value).format('HH:mm MM/DD/YYYY') : '-'
    case 'date':
        return !!value ? moment(value).format('MM/DD/YYYY') : '-'
    case 'currency':
        return !!value ? formatLargeSums('$', value) : '-'
    case 'picklist':
    default:
        return value || '-'
    }
}