import React, { useState } from 'react';
import { faHistory, faPenAlt, faSave } from '@fortawesome/free-solid-svg-icons';
import cn from './MissionStatement.module.css'
import { environmentCheck } from '../../../../utilities/environmentCheck';
import { Row } from '../../../../components/Row';
import { BUTTON_TYPES, Button } from '../../../../components/Button';
import { Nbsp } from '../../../../components/Nbsp';
import { SlateEditor } from '../../../../components/SlateEditor';
import { Modal } from '../../../../components/Modal';

export const MissionStatement = () => {
    const [missionStatementEdit, setMissionStatementEdit] = useState(false)
    const [missionStatement, setMissionStatement] = useState(`[{"type":"paragraph","children":[{"text":"Chili Piper wants to "},{"text":"reduce churn","bold":true},{"text":", while also starting to "},{"text":"compensate with expansion","bold":true},{"text":" as well. They also want to use "},{"text":"one tool across all different departments.","bold":true}]}]`)
    const [showOldMissionStatementRevisions, setShowOldMissionStatementRevisions] = useState(false)

    if (environmentCheck(['LOCAL', 'DEV'])) {
        return (
            <div className={cn.missionStatement}>
                <SlateEditor
                    viewOnly={!missionStatementEdit}
                    value={missionStatement}
                    onChange={e => setMissionStatement(e)}
                />
                <div className={cn.buttons}>
                    <Button
                        data-tooltip-id='default'
                        data-tooltip-content={missionStatementEdit ? 'Save' : 'Edit'}
                        type={BUTTON_TYPES.SMALL_ICON}
                        icon={missionStatementEdit ? faSave : faPenAlt}
                        onClick={() => setMissionStatementEdit(!missionStatementEdit)}
                    />
                    <Button
                        data-tooltip-id='default'
                        data-tooltip-content={'History'}
                        type={BUTTON_TYPES.SMALL_ICON}
                        icon={faHistory}
                        onClick={() => setShowOldMissionStatementRevisions(true)}
                    />
                </div>
                {showOldMissionStatementRevisions ? (
                    <Modal
                        header={'Mission Statements Revisions'}
                        onClose={() => setShowOldMissionStatementRevisions(false)}
                    >
                        <Row>
                            <div>
                                Revision saved by Kristiyan Ivanov on the 29th of March 2024.<br/>94% similar.
                            </div>
                            <Nbsp />
                            <Nbsp />
                            <Nbsp />
                            <Button>
                                View
                            </Button>
                            <Nbsp />
                            <Button>
                                Restore
                            </Button>
                        </Row>
                        <Row>
                            <div>
                                Revision saved by Kristiyan Ivanov on the 29th of March 2024.<br/>92% similar.
                            </div>
                            <Nbsp />
                            <Nbsp />
                            <Nbsp />
                            <Button>
                                View
                            </Button>
                            <Nbsp />
                            <Button>
                                Restore
                            </Button>
                        </Row>
                        <Row>
                            <div>
                                Revision saved by Michael Tuso on the 24th of March 2024.<br/>83.2% similar.
                            </div>
                            <Nbsp />
                            <Nbsp />
                            <Nbsp />
                            <Button>
                                View
                            </Button>
                            <Nbsp />
                            <Button>
                                Restore
                            </Button>
                        </Row>
                        <Row>
                            <div>
                                Revision saved by Tsvetan Tsvetkov on the 22th of March 2024.<br/>63.84% similar.
                            </div>
                            <Nbsp />
                            <Nbsp />
                            <Nbsp />
                            <Button>
                                View
                            </Button>
                            <Nbsp />
                            <Button>
                                Restore
                            </Button>
                        </Row>
                    </Modal>
                ) : null}
            </div>
        )
    }
    return null
}