import React, { useEffect, useState } from 'react'
import moment from 'moment'
import { faPlus } from '@fortawesome/free-solid-svg-icons'
import { Table } from '../../../../components/Table'
import cn from '../Account.module.css'
import { permissionCheck } from '../../../../utilities/permissionCheck'
import { OpportunityActions } from '../../../../components/OpportunityActions'
import { makeRequest } from '../../../../utilities/endpoints'
import { URLS } from '../../../../config'
import { Heading } from '../../../../components/Heading'
import { translateCRMLabel } from '../../../../utilities/translateCRMLabels'
import { Row } from '../../../../components/Row'
import { BUTTON_TYPES, Button } from '../../../../components/Button'
import { PermissionCheck } from '../../../../components/PermissionCheck'
import { OpportunitiesCRUDModalWrapper } from '../../../../components/OpportunitiesCRUDModalWrapper'
import { workspaceTypeCheck } from '../../../../utilities/workspaceType'
import { LOADER_SIZES, LOADER_TYPES, Loader } from '../../../../components/Loader'

export const Opportunities = ({ accountId }) => {
    const [showLoader, setShowLoader] = useState(true)
    const [opportunities, setOpportunities] = useState([])
    const [creatingNewOpportunity, setCreatingNewOpportunity] = useState(false)

    const loadOpportunities = () => {
        makeRequest.get(`${URLS.main}/opportunities/${accountId}`)
            .then(res => {
                if (res && res.data) {
                    setOpportunities(res.data)
                    setShowLoader(false)
                }
            })
    }
    useEffect(() => {
        loadOpportunities()
        setShowLoader(true)
    }, [accountId])


    if (showLoader) {
        return <Loader type={LOADER_TYPES.SPINNER} size={LOADER_SIZES.BIG} />
    }

    return (
        <div className={cn.scrollableParent}>
            <Row noMargin spaceBetween>
                <Heading noOffset>
                    {translateCRMLabel('Opportunities')}
                </Heading>
                <Row className={cn.oppButtons}>
                    <PermissionCheck requiredPermissions={[['OPPORTUNITIES', 'CREATE']]}>
                        <Button
                            type={BUTTON_TYPES.ICON_AND_TEXT}
                            icon={faPlus}
                            onClick={() => setCreatingNewOpportunity(true)}
                        >
                            ADD NEW
                        </Button>
                    </PermissionCheck>
                </Row>
            </Row>
            <div className={cn.scroll}>
                <Table
                    sticky={false}
                    borderless
                    customStyles={{
                        3: {
                            width: 170,
                        },
                        4: {
                            width: 100
                        }
                    }}
                    headers={[
                        'Name',
                        'Stage',
                        'Amount',
                        'Close Date',
                        (permissionCheck('OPPORTUNITIES', 'UPDATE') && 'Actions')
                    ]}
                    data={
                        opportunities.map(el => [
                            el.name || el.Name,
                            el.stageName,
                            el.amount || el.Amount,
                            !!el.closeDate ? moment(el.closeDate).format('DD MMM, YYYY') : '',
                            <OpportunityActions
                                opportunity={el}
                                onEdit={loadOpportunities}
                                onRemove={loadOpportunities}
                            />
                        ])
                    }
                />
            </div>
            <OpportunitiesCRUDModalWrapper
                header={`Creating new ${translateCRMLabel('Opportunity')}`}
                predefinedData={workspaceTypeCheck(['HUBSPOT']) ? {
                    'ASSOCIATION_REMAP_COMPANY_ID': {
                        data: accountId,
                        disabled: true
                    },
                    AccountId: {
                        data: accountId,
                        disabled: true
                    }
                } : {
                    AccountId: {
                        data: accountId,
                        disabled: true
                    }
                }}
                shown={!!creatingNewOpportunity}
                create={true}
                createCallback={() => {
                    // loadOpportunities();
                    setCreatingNewOpportunity(false)
                }}
                onClose={() => {
                    setCreatingNewOpportunity(false)
                }}
            />
        </div>
    )
}
