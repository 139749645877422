import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useEffect, useRef, useState } from 'react'
import moment from 'moment'
import cn from './ComplexDropdown.module.css'
import { CalendarInput } from './CalendarInput'
import { useOutsideClickHandler } from '../customHooks/useOutsideClickHandler'

export const ComplexDropdown = ({ value, onChange }) => {
    const [opened, setOpened] = useState(false)
    const [tempValue, setTempValue] = useState({})
    const wrapperRef = useRef(null)

    useOutsideClickHandler(wrapperRef, () => setOpened(false))

    const onSelect = data => {
        setTempValue(null)
        setOpened(false)
        onChange(data)
    }
    useEffect(() => {
        if (tempValue && tempValue.start && tempValue.end) {
            onSelect({
                ...tempValue,
                label: 'Custom'
            })
        }
    }, [tempValue])
    return (
        <div className={cn.wrapper} ref={wrapperRef}>
            <div className={cn.readableValue} onClick={() => setOpened(!opened)}>
                <div className={cn.readable}>{value.label}</div>
                <FontAwesomeIcon icon={opened ? faChevronUp : faChevronDown}/>
            </div>
            {opened && (
                <div className={cn.dropdownContent}>
                    <div
                        className={cn.textValue}
                        onClick={() => onSelect({
                            start: moment().startOf('month').valueOf(),
                            end: moment().endOf('month').valueOf(),
                            label: 'This Month'
                        })}
                    >
                        This Month
                    </div>
                    <div
                        className={cn.textValue}
                        onClick={() => onSelect({
                            start: moment().subtract(1, 'month').startOf('month').valueOf(),
                            end: moment().subtract(1, 'month').endOf('month').valueOf(),
                            label: 'Last Month'
                        })}
                    >
                        Last Month
                    </div>
                    <div
                        className={cn.textValue}
                        onClick={() => onSelect({
                            start: moment().add(1, 'month').startOf('month').valueOf(),
                            end: moment().add(1, 'month').endOf('month').valueOf(),
                            label: 'Next Month'
                        })}
                    >
                        Next Month
                    </div>
                    <hr />

                    <div
                        className={cn.textValue}
                        onClick={() => onSelect({
                            start: moment().startOf('quarter').valueOf(),
                            end: moment().endOf('quarter').valueOf(),
                            label: 'This Quarter'
                        })}
                    >
                        This Quarter
                    </div>
                    <div
                        className={cn.textValue}
                        onClick={() => onSelect({
                            start: moment().subtract(1, 'quarter').startOf('quarter').valueOf(),
                            end: moment().subtract(1, 'quarter').endOf('quarter').valueOf(),
                            label: 'Last Quarter'
                        })}
                    >
                        Last Quarter
                    </div>
                    <div
                        className={cn.textValue}
                        onClick={() => onSelect({
                            start: moment().add(1, 'quarter').startOf('quarter').valueOf(),
                            end: moment().add(1, 'quarter').endOf('quarter').valueOf(),
                            label: 'Next Quarter'
                        })}
                    >
                        Next Quarter
                    </div>
                    <hr />

                    <div
                        className={cn.textValue}
                        onClick={() => onSelect({
                            start: moment().startOf('year').valueOf(),
                            end: moment().endOf('year').valueOf(),
                            label: 'This Year'
                        })}
                    >
                        This Year
                    </div>
                    <div
                        className={cn.textValue}
                        onClick={() => onSelect({
                            start: moment().subtract(1, 'year').startOf('year').valueOf(),
                            end: moment().subtract(1, 'year').endOf('year').valueOf(),
                            label: 'Last Year'
                        })}
                    >
                        Last Year
                    </div>
                    <div
                        className={cn.textValue}
                        onClick={() => onSelect({
                            start: moment().add(1, 'year').startOf('year').valueOf(),
                            end: moment().add(1, 'year').endOf('year').valueOf(),
                            label: 'Next Year'
                        })}
                    >
                        Next Year
                    </div>
                    <hr />
                    <CalendarInput
                        smallerMargin
                        value={moment(tempValue ? tempValue.start : value.start).format('MM/DD/YYYY')}
                        placeholder="Select Start"
                        onChange={e => setTempValue({ ...tempValue, start: moment(e).startOf('day').valueOf() })}
                    />
                    <CalendarInput
                        smallerMargin
                        value={moment(tempValue ? tempValue.end : value.end).format('MM/DD/YYYY')}
                        placeholder="Select End"
                        onChange={e => setTempValue({ ...tempValue, end: moment(e).endOf('day').valueOf() })}
                    />
                </div>
            )}
        </div>
    )
}