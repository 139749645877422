import React from 'react'
import moment from 'moment'
import { faLink } from '@fortawesome/free-solid-svg-icons';
import { Button, BUTTON_TYPES } from '../../../../components/Button';
import { formatLargeSums } from '../../../../utilities/currency';
import cn from './SidebarOpportunity.module.css'

export const SidebarOpportunity = ({
    Name,
    Amount,
    CloseDate,
    Id,
    missingProducts,
    setOpportunityToUpdate,
    sfUrl,
    inactive,
    inactiveProducts
}) => {
    let dataTip = null;
    if (missingProducts) {
        dataTip = 'This opportunity has products associated with it, that are not active in your price book or product families.'
    } else if (inactive) {
        dataTip = 'This opportunity has no active products associated with it'
    }
    if (inactiveProducts) {
        dataTip = `${dataTip || ''}\n\t This opportunity has products associated with it, that are removed from the white space planning.`
    }
    return (
        <div className={cn.opportunity}>
            <div
                className={`${cn.opportunityDataWrapper} ${inactive ? cn.inactive : ''}`}
                onClick={inactive ? () => { } : setOpportunityToUpdate}
                data-tooltip-id='default'
                data-tooltip-content={dataTip}
            >
                <div className={cn.Name}>
                    {Name}
                </div>
                <div className={cn.amount}>
                    {formatLargeSums('$', Amount)} - {moment(CloseDate).format('DD MMMM YYYY')}
                </div>
            </div>
            <Button
                type={BUTTON_TYPES.ICON}
                icon={faLink}
                onClick={() => window.open(`${sfUrl}/${Id}`)}
            />
        </div>
    )
}
