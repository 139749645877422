import React, { useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { makeRequest } from '../../utilities/endpoints';
import { workspaceTypeCheck } from '../../utilities/workspaceType';
import { URLS } from '../../config';
import { ViewContainer } from '../../components/ViewContainer';
import { SubHeader } from '../../components/SubHeader';
import cn from './SearchResults.module.css'
import { Button, BUTTON_TYPES } from '../../components/Button';
import { translateCRMLabel } from '../../utilities/translateCRMLabels';

export const SearchResults = () => {
    const { searchTerm } = useParams()
    const history = useHistory()
    const [lastSearchTerm, setLastSearchTerm] = useState()
    const [resultsByType, setResultsByType] = useState({})

    const search = value => {
        makeRequest.get(`${URLS.main}/search/${value}`)
            .then(res => {
                if (res && res.data) {
                    const results = {}
                    res.data.forEach(result => {
                        if (!results[result.attributes.type]) {
                            results[result.attributes.type] = []
                        }
                        results[result.attributes.type].push(result)
                    })
                    setResultsByType(results)
                }
            })
    }

    useEffect(() => {
        if (lastSearchTerm !== searchTerm) {
            setLastSearchTerm(searchTerm)
            search(searchTerm)
        }
    }, [searchTerm, lastSearchTerm])

    const createOnClick = (result) => {
        switch (result.attributes.type) {
        case 'Account':
            return history.push(`/account/${result.Id}`)
        case 'Opportunity':
            return history.push(`/opportunities-management/edit/${result.Id}`)
        case 'Contact':
            return history.push(`/account/${result.AccountId}/contact/${result.Id}`)
        case 'Note':
            if (workspaceTypeCheck(['HUBSPOT'])) {
                return window.location.href = `${URLS.main}/hubspot-get-account-from-notes/${result.Id}`
            }
            return history.push(`/account/${result.ParentId}/notes/${result.Id}`)
        }
    }

    const richTextSanitize = (str) => {
        if (!str || str === "") {
            return str;
        }

        let json = '';
        const outArr = [];
        try {
            json = JSON.parse(str);
            json.forEach(el => {
                if (Object.prototype.hasOwnProperty.call(el, 'text')) {
                    outArr.push(el.text);
                } else if (Object.prototype.hasOwnProperty.call(el, 'children')) {
                    el.children.forEach(child => {
                        if (Object.prototype.hasOwnProperty.call(child, 'text')) {
                            outArr.push(child.text);
                        }
                    });
                }
            });
            return outArr.join(' ');
        } catch (error) {
            return str;
        }
    }

    const sliceLimit = workspaceTypeCheck(['HUBSPOT']) ? 150 : 120

    if (!Object.keys(resultsByType).length) {
        return (
            <ViewContainer className={cn.searchResultsWrapper} grow>
                <SubHeader>No Results Found</SubHeader>
            </ViewContainer>
        )
    }

    return (
        <ViewContainer className={cn.searchResultsWrapper} grow>
            {Object.keys(resultsByType).map(searchResultType => (
                <>
                    <SubHeader>{['opportunity', 'account'].includes(searchResultType.toLowerCase()) ? translateCRMLabel(searchResultType.toLowerCase()) : searchResultType}</SubHeader>
                    <div className={cn.searchResults}>
                        {resultsByType[searchResultType].map(resultEntry => (
                            <div className={cn.resultEntry}>
                                <Button
                                    type={BUTTON_TYPES.TRANSPARENT}
                                    onClick={() => createOnClick(resultEntry)}
                                >
                                    {searchResultType === 'Note'
                                        ? `${`${resultEntry.Title ? `${resultEntry.Title} - ` : ''}${richTextSanitize(resultEntry.Body)}`.slice(0, sliceLimit)}...`
                                        : resultEntry.Name}
                                </Button>
                            </div>
                        ))}
                    </div>
                </>
            ))}
        </ViewContainer>
    )
}
