import React, { useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons'
import cn from './ViewContainer.module.css'
import { Row } from './Row'
import { Heading } from './Heading'

export const ViewContainer = ({
    children,
    className,
    xScroll,
    grow,
    background,
    noGrow,
    title,
    collapsible,
    initiallyCollapsed = true,
    skipContainer,
    minHeight,
    testId,
    collapsedCallback
}) => {
    const [collapsed, setCollapsed] = useState(initiallyCollapsed)
    if (skipContainer) {
        return children
    }
    if (collapsible && collapsed) {
        return (
            (
                <div
                    data-test-id={testId}
                    className={`
                        ${cn.viewContainer}
                        ${className}
                    `}
                    style={{
                        flexGrow: grow,
                        background: background,
                        minHeight: minHeight
                    }}
                >
                    <Row
                        noMargin
                        className={cn.collapsableHeader}
                        spaceBetween
                        onClick={() => {
                            setCollapsed(false)
                            collapsedCallback && collapsedCallback(false)
                        }}
                    >
                        <Heading noOffset>{title}</Heading>
                        <FontAwesomeIcon icon={faChevronUp} />
                    </Row>
                </div>
            )
        )
    }
    return (
        <div
            data-test-id={testId}
            className={`
                ${cn.viewContainer}
                ${xScroll ? cn.xScroll : ''}
                ${noGrow ? cn.noGrow : ''}
                ${className}
            `}
            style={{
                flexGrow: grow,
                background: background,
                minHeight: minHeight
            }}
        >
            {collapsible && (
                <Row
                    noMargin
                    className={`${cn.collapsableHeader} ${cn.expanded}`}
                    spaceBetween
                    onClick={() => {
                        setCollapsed(true)
                        collapsedCallback && collapsedCallback(false)
                    }}
                >
                    <Heading noOffset>{title}</Heading>
                    <FontAwesomeIcon icon={faChevronDown} />
                </Row>
            )}
            {children}
        </div>
    )
}