export const setUserType = 'SET_USER'
export const setUser = user => ({
    type: setUserType,
    user
})

export const enableBackButtonType = 'ENABLE_BACK_BUTTON'
export const enableBackButton = enabled => ({
    type: enableBackButtonType,
    enabled: enabled
})

export const systemMessageType = 'SYSTEM_MESSAGE'
export const setSystemMessage = systemMessage => ({
    type: systemMessageType,
    systemMessage: systemMessage
})

export const addGlobalErrorType = 'ADD_GLOBAL_ERROR'
export const addGlobalError = newError => ({
    type: addGlobalErrorType,
    newError
})

export const removeGlobalErrorType = 'REMOVE_GLOBAL_ERROR'
export const removeGlobalError = errorIndex => ({
    type: removeGlobalErrorType,
    errorIndex: errorIndex
})

export const setStartOfSearchType = 'SET_START_OF_SEARCH'
export const setStartOfSearch = startUrl => ({
    type: setStartOfSearchType,
    startUrl: startUrl
})

export const addNotificationType = 'ADD_NOTIFICATION'
export const addNotification = notification => ({
    type: addNotificationType,
    notification
})

export const removeNotificationType = 'REMOVE_NOTIFICATION'
export const removeNotification = notificationId => ({
    type: removeNotificationType,
    notificationId
})

export const setPageAfterType = 'SET_PAGE_AFTER'
export const setPageAfter = pageAfter => ({
    type: setPageAfterType,
    pageAfter: pageAfter
})

export const changeColorSchemeType = 'CHANGE_COLOR_SCHEME'
export const changeColorScheme = colorScheme => ({
    type: changeColorSchemeType,
    colorScheme: colorScheme
})

export const changeCustomRouteLabelType = 'CHANGE_CUSTOM_ROUTE_LABEL'
export const changeCustomRouteLabel = customPathLabel => ({
    type: changeCustomRouteLabelType,
    customPathLabel: customPathLabel
})

export const setHomeFilterDataType = 'SET_TOGGLE_HOME_ORG_DATA'
export const setHomeFilterData = filterByUserId => ({
    type: setHomeFilterDataType,
    filterByUserId
})